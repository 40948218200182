import * as React from 'react';
import Dialog from '../Dialog';
import { SaveButton, CancelButton, DeleteButton, ActionButton } from '../shared/Buttons';
import Spinner from '../Spinner';
import { Practice } from '../../store/practice/PracticeModel';
import { usePracticeViewModel, savePractice, deactivatePractice, practiceBaseUrl, practiceDeactivateUrl, practiceSaveUrl } from '../../store/practice/PracticeFetcher';
import { useSpecialtyOptions } from '../../store/specialty/SpecialtyFetcher';
import { getNewGuid } from '../../store/SettingsFetcher';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { StateOptionItems } from '../../functions/option.functions';
import ApiErrorDisplay from '../ApiErrorDisplay';
import { Form, Row, Col, Divider } from 'antd';
import { InputField, DropdownField, DatePickerField, CheckboxField } from '../shared/InputLibrary';
import { TitleError } from '../shared/AntComponents/Typography/Title';
import { BasicFieldWrapper, BasicInputField } from '../shared/BasicInputLibrary';
import { useApiContext } from '../../store/ApiContext';
import CustomIcon, { CustomIconType } from '../shared/AntComponents/CustomIcon';
import { useErrorContext } from '../../store/ErrorContext';

interface IProps {
   isOpen: boolean;
   closeEditor: () => void;
   id?: number;
   isReadOnly: boolean;
};

const dialogContentStyle = {
   height: '500px',
   maxWidth: '90vw',
}

const yupPracticeSchema = yup.object({
   id: yup.number().notRequired(),
   externalTenantId: yup.string()
      .required('External Tenant Id is required')
      .uuid('External Tenant Id must be a valid GUID\\UUID'),
   managedTenantId: yup.string()
      .max(100, 'Max length is 100 characters')
      .notRequired(),
   name: yup.string()
      .required('Practice Name is required')
      .max(240, 'Max length is 240 characters'),
   hasFeed: yup.boolean().notRequired(),
   isRegistered: yup.boolean().notRequired(),
   address1: yup.string()
      .required('Address1 is required')
      .max(200, 'Max length is 200 characters'),
   address2: yup.string().notRequired()
      .max(200, 'Max length is 200 characters'),
   city: yup.string()
      .required('City is required')
      .max(200, 'Max length is 200 characters'),
   //testing validation
   //state: yup.string().required(),
   state: yup.string()
      .required('State is required')
      .max(30, 'Max length is 30 characters'),
   zip: yup.string()
      .required('Zip is required')
      .max(15, 'Max length is 15 characters'),
   phone: yup.string()
      .required('Phone is required')
      .max(15, 'Max length is 15 characters'),
   phoneExt: yup.string().notRequired()
      .max(15, 'Max length is 15 characters'),
   fax: yup.string()
      .required('Fax is required')
      .max(15, 'Max length is 15 characters'),
   npi: yup.string()
      .required('Organization NPI is required')
      .min(10, 'Min length is 10 characters')
      .max(10, 'Max length is 10 characters'),

   taxId: yup.string().notRequired(),
   baaVersion: yup.number().notRequired(),
   website: yup.string()
      .required('Website is required')
      .max(1000, 'Min length is 1000 characters'),

   lastLogin: yup.date().notRequired(),
   createdByUpn: yup.string().notRequired(),
   //created: yup.date().default(minSqlDate).required(),
   created: yup.date().notRequired(),
   modifiedByUserProfileId: yup.number().notRequired(),
   modified: yup.date().notRequired(),
   practiceAccessType: yup.number().notRequired(),
   isSecurityEnabled: yup.boolean().notRequired(),
   eHRName: yup.string().notRequired(),
   eHRFax: yup.string().notRequired(),
   oralPharmacyName: yup.string().notRequired(),
   ePEnabled: yup.boolean().notRequired(),
   deactivated: yup.boolean().notRequired(),
   deactivatedDate: yup.date().notRequired(),
   numberOfMDs: yup.number().notRequired(),
   numberOfProviders: yup.number().notRequired(),
   numberOfExtenders: yup.number().notRequired(),
   oralPharmacyNCPDP: yup.string().notRequired(),
   oralPharmacyNABP: yup.string().notRequired(),
   oralPharmacyNPI: yup.string().notRequired(),
   oralPharmacyTaxId: yup.string().notRequired(),
   eMRSystem: yup.string().notRequired(),
   practiceManagementSystem: yup.string().notRequired(),
   pharmacyManagementSystem: yup.string().notRequired(),
   fTEProviderCount: yup.number().notRequired(),
   specialties: yup.array().min(1, "At least one specialty must be selected").required("required"),
   allowAutoProvision: yup.boolean().default(false).required()
});

interface IPracticeYup extends yup.Asserts<typeof yupPracticeSchema> { }
const __formId = "frmPractice";


const PracticeEditor: React.FC<IProps> = (props) => {
   const { closeEditor, isOpen, isReadOnly, id } = props;
   const _keys = id ? [practiceSaveUrl, practiceDeactivateUrl(id)] : [practiceSaveUrl];
   const { httpGet, httpPost, httpPut } = useApiContext();
   const { removeErrors } = useErrorContext();
   const [isFormSaving, setIsFormSaving] = React.useState<boolean>(false);

   const { specialtyOptions } = useSpecialtyOptions(httpGet);
   const { practiceViewModels } = usePracticeViewModel(httpGet);
   //const editorSchema = yupPracticeSchema.omit(['id'|'hasFeed']);

   const { control, handleSubmit, setValue, formState: { errors }, reset, getValues } = useForm<IPracticeYup>({
      resolver: yupResolver<yup.AnyObject>(yupPracticeSchema),
      shouldFocusError: true,
   });

   React.useEffect(() => {
      let resetVal = {};
      if (id && practiceViewModels?.length > 0) {
         resetVal = practiceViewModels.find(y => y.id === id);
      }
      reset(resetVal);
      // for "reset"
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [id, practiceViewModels]);

   /* the Yup / form object here doesn't contain everything we want to display
    * but the VM does */
   const practiceModel = React.useMemo(() => { 
      if (id && practiceViewModels?.length > 0) {
         return practiceViewModels.find(y => y.id === id);
      }
   }, [id, practiceViewModels])

   const onSubmit = (data: IPracticeYup): void => {
      //console.log('--------------PracticeEditor-------------------------')
      //console.log(errors)

      const practice = data as Practice;
      setIsFormSaving(true);
      savePractice(httpPost, practice)
         .then(handleClose)
         .catch(err => console.error('Error Saving Practice', err))
         .finally(() => {
            setIsFormSaving(false);
         });
   }

   const [isDeactivateDialogOpen, setIsDeactivateDialogOpen] = React.useState<boolean>(false);

   const handleDeactivateClick = () => {
      setIsDeactivateDialogOpen(false);
      deactivatePractice(httpPut, id)
         .then(handleClose)
         .catch(err => console.error('Error Deactivating Practice', err));

   }

   const handleClose = () => {
      setIsFormSaving(false);
      removeErrors({ keys: _keys })
      closeEditor();
   }

   if (isFormSaving) {
      return <Spinner message='Saving Practice...' />
   }

   const html = (
      <Dialog
         scrollingContent={true}
         title={`Practice Editor`}
         open={isOpen}
         size="large"
         contentStyle={{ overflowX: 'hidden' }}
         actionButtons={
            <>
               <CancelButton onClick={() => {
                  handleClose();
               }} />
               {!isReadOnly && <DeleteButton buttonText='Deactivate Practice'
                  hasIcon={false}
                  onClick={() => setIsDeactivateDialogOpen(true)}
                  disabled={!getValues()?.id || getValues()?.deactivated || isDeactivateDialogOpen}
                  loading={isDeactivateDialogOpen} />
               }
               {!isReadOnly && <SaveButton
                  formId={__formId}
                  onClick={() => null}
                  disabled={getValues()?.deactivated ||
                     Object.keys(errors).length > 0
                  }
                  loading={isFormSaving} />
               }
            </>
         }>
         <div style={dialogContentStyle}>

            {errors && Object.keys(errors).length > 0 && <>
               <TitleError
                  text='Please correct the errors below' />
               {console.log('--------------Form Errors: -------------------------')}
               {console.log(errors)}
            </>
            }

            <ApiErrorDisplay
               title='Error saving Practice'
               keys={_keys}
            />

            <Form id={__formId} onFinish={handleSubmit(onSubmit)}>
               <Divider>Practice Information</Divider>
               <Row gutter={[32, 32]} justify="space-between" align="top">
                  <Col span={12}>
                     <Row align='top'>
                        <Col span={22}>
                           <InputField
                              containerStyle={{ width: '100%' }}
                              control={control}
                              name='externalTenantId'
                              label='TenantId (Guid)'
                              placeholder='xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'
                              type='text'
                              error={errors?.externalTenantId}
                              required={true}
                              disabled={isReadOnly}
                           />
                        </Col>
                        <Col span={2}>
                           <div style={{paddingTop: 32}}>
                           <BasicFieldWrapper
                              field={
                              <ActionButton
                                 title='Generate Guid'
                                 style={{ paddingTop: -10 }}
                                 icon={<CustomIcon type={CustomIconType.AntCloudOutlined} />}
                                 disabled={id && !isReadOnly}
                                 onClick={() => {
                                    getNewGuid(httpGet)
                                       .then((response) => {
                                          setValue('externalTenantId', response)
                                       });
                                       }} />
                           } />
                           </div>
                        </Col>
                     </Row>
                  </Col>

                  <Col span={12}>
                     <InputField
                        control={control}
                        name='managedTenantId'
                        label='Managed Tenant Id'
                        type='text'
                        error={errors?.managedTenantId}
                        required={false}
                        disabled={isReadOnly}
                     />
                  </Col>
               </Row>
               <Row gutter={[32, 32]} justify="space-between" align="top" >
                  <Col span={8}>
                     <InputField
                        control={control}
                        name='name'
                        label='Practice Name'
                        type='text'
                        error={errors?.name}
                        maxLength={240}
                        required={true}
                        disabled={isReadOnly}
                     />
                  </Col>
                  <Col span={8}>
                     {/* tooltip='10 digit NPI - i.e. 7777777777'*/}
                     <InputField
                        control={control}
                        name='npi'
                        label='Organization Npi'
                        type='text'
                        error={errors?.npi}
                        maxLength={10}
                        required={true}
                        disabled={isReadOnly}
                     />
                  </Col>
                  <Col span={8}>
                     <DropdownField
                        control={control}
                        name='specialties'
                        label='Specialty'
                        error={errors?.specialties}
                        multiple={true}
                        options={specialtyOptions}
                        required={true}
                        disabled={isReadOnly}
                     />
                  </Col>
               </Row>

               <Row gutter={[32, 32]} justify="space-between" align="top" >
                  <Col span={8}>
                     <InputField
                        control={control}
                        name='website'
                        label='Website'
                        type='text'
                        error={errors?.website}
                        disabled={isReadOnly}
                        required={true}
                     />
                  </Col>
                  <Col span={8} >
                     <CheckboxField
                        control={control}
                        name='allowAutoProvision'
                        label='Allow Auto Provision'
                        error={errors?.allowAutoProvision}
                        disabled={isReadOnly}
                        toggle={true}
                        required={false}
                     />
                  </Col>
                  <Col span={8}>
                     <CheckboxField
                        control={control}
                        name='hasFeed'
                        label='Has Feed'
                        error={errors?.hasFeed}
                        disabled={isReadOnly}
                        toggle={true}
                     />
                  </Col>
               </Row>

               <Divider>Additional Profile Fields Below</Divider>

               <Row gutter={[32, 32]} justify="space-between" align="top" >
                  <Col span={8}>
                     <InputField
                        control={control}
                        name='city'
                        label='City'
                        type='text'
                        error={errors?.city}
                        disabled={isReadOnly}
                        required={true}
                     />
                  </Col>
                  <Col span={8}>
                     <DropdownField
                        control={control}
                        name='state'
                        label='State'
                        error={errors?.state}
                        options={StateOptionItems}
                        disabled={isReadOnly}
                        required={true}
                     />
                  </Col>
                  <Col span={8}>
                     <InputField
                        control={control}
                        name='zip'
                        label='Zip'
                        type='text'
                        error={errors?.zip}
                        disabled={isReadOnly}
                        required={true}
                     />
                  </Col>
               </Row>
               <Row gutter={[32, 32]} justify="space-between" align="top" >
                  <Col span={12}>
                     <InputField
                        control={control}
                        name='address1'
                        label='Address1'
                        type='text'
                        error={errors?.address1}
                        disabled={isReadOnly}
                        required={true}
                     />
                  </Col>
                  <Col span={12}>
                     <InputField
                        control={control}
                        name='address2'
                        label='Address2'
                        type='text'
                        error={errors?.address2}
                        disabled={isReadOnly}
                     />
                  </Col>
               </Row>
               <Row gutter={[32, 32]} justify="space-between" align="top" >
                  <Col span={8}>
                     <InputField
                        control={control}
                        name='phone'
                        label='Phone'
                        type='text'
                        error={errors?.phone}
                        disabled={isReadOnly}
                        required={true}
                     />
                  </Col>
                  <Col span={8}>
                     <InputField
                        control={control}
                        name='phoneExt'
                        label='Phone Extension'
                        type='text'
                        error={errors?.phoneExt}
                        disabled={isReadOnly}
                     />
                  </Col>
                  <Col span={8}>
                     <InputField
                        control={control}
                        name='fax'
                        label='Fax'
                        type='text'
                        error={errors?.fax}
                        disabled={isReadOnly}
                        required={true}
                     />
                  </Col>
               </Row>

               <div className='modal-section-bg'>
                  <Divider>Salesforce Fields</Divider>
                  <Row gutter={[32, 32]} justify="space-between" align="top">
                     <Col span={8}>
                        <BasicInputField
                           name='Provider Organization Status'
                           label='Provider Organization Status'
                           value={practiceModel?.insightlyOrganization?.providerOrganizationStatus ?? "Internal"} // AH-3636
                           disabled={true}
                        />
                     </Col>
                     <Col span={8}>
                        <BasicInputField
                           name='Account Manager'
                           label='Account Manager'
                           value={practiceModel?.insightlyOrganization?.accountManager}
                           disabled={true}
                        />
                     </Col>
                     <Col span={8}>
                        <BasicInputField
                           name='salesforceAccountId'
                           label='Salesforce Account ID'
                           value={practiceModel?.insightlyOrganization?.salesforceAccountId}
                           disabled={true}
                        />
                     </Col>
                  </Row>
               </div>

               <Divider>Readonly Fields Below</Divider>
               <Row gutter={[32, 32]} justify="space-between" align="top" >
                  <Col span={8}>
                     <CheckboxField
                        control={control}
                        name='isRegistered'
                        label='Is Registered'
                        error={errors?.isRegistered}
                        disabled={true}
                     />
                  </Col>
                  <Col span={8}>
                     <InputField
                        control={control}
                        name='taxId'
                        label='Tax Id'
                        type='text'
                        error={errors?.taxId}
                        disabled={true}
                     />
                  </Col>
                  <Col span={8}>
                     <InputField
                        control={control}
                        name='baaVersion'
                        label='BAA Version'
                        type='text'
                        error={errors?.baaVersion}
                        disabled={true}
                     />
                  </Col>
               </Row>
               <Row gutter={[32, 32]} justify="space-between" align="top" >
                  <Col span={8}>
                     <DatePickerField
                        control={control}
                        name='lastLogin'
                        label='Last Login'
                        disabled={true}
                        error={errors?.lastLogin}
                     />
                  </Col>
                  <Col span={8}>
                     <DatePickerField
                        control={control}
                        name='created'
                        label='Created'
                        error={errors?.lastLogin}
                        disabled={true}
                     />
                  </Col>
                  <Col span={8}>
                     <DatePickerField
                        control={control}
                        name='modified'
                        label='Modified'
                        error={errors?.modified}
                        disabled={true}
                     />
                  </Col>
               </Row>
               <Row gutter={[32, 32]} justify="space-between" align="top" >
                  <Col span={12}>
                     <InputField
                        control={control}
                        name='createdByUpn'
                        label='Created By Upn'
                        type='text'
                        error={errors?.createdByUpn}
                        disabled={true}
                     />
                  </Col>
                  <Col span={12}>
                     <InputField
                        control={control}
                        name='modifiedByUserProfileId'
                        label='Modified By UserProfile Id'
                        type='text'
                        error={errors?.modifiedByUserProfileId}
                        disabled={true}
                     />
                  </Col>
               </Row>
               <Row gutter={[32, 32]} justify="space-between" align="top" >
                  <Col span={12}>
                     <InputField
                        control={control}
                        name='practiceAccessType'
                        label='Practice Access Type'
                        type='text'
                        error={errors?.practiceAccessType}
                        disabled={true}
                     />
                  </Col>
                  <Col span={12}>
                     <InputField
                        control={control}
                        name='isSecurityEnabled'
                        label='Is Security Enabled'
                        type='text'
                        error={errors?.isSecurityEnabled}
                        disabled={true}
                     />
                  </Col>
               </Row>
               <Row gutter={[32, 32]} justify="space-between" align="top" >
                  <Col span={8}>
                     <InputField
                        control={control}
                        name='eHRName'
                        label='EHR Name'
                        type='text'
                        error={errors?.eHRName}
                        disabled={true}
                     />
                  </Col>
                  <Col span={8}>
                     <InputField
                        control={control}
                        name='eHRFax'
                        label='EHR Fax'
                        type='text'
                        error={errors?.eHRFax}
                        disabled={true}
                     />
                  </Col>
                  <Col span={8}>
                     <InputField
                        control={control}
                        name='eMRSystem'
                        label='EMR System'
                        type='text'
                        error={errors?.eMRSystem}
                        disabled={true}
                     />
                  </Col>
               </Row>
               <Row gutter={[32, 32]} justify="space-between" align="top" >
                  <Col span={12}>
                     <InputField
                        control={control}
                        name='oralPharmacyName'
                        label='Oral Pharmacy Name'
                        type='text'
                        error={errors?.oralPharmacyName}
                        disabled={true}
                     />
                  </Col>
                  <Col span={12}>
                     <InputField
                        control={control}
                        name='oralPharmacyNPI'
                        label='Oral Pharmacy NPI'
                        type='text'
                        error={errors?.oralPharmacyNPI}
                        disabled={true}
                     />
                  </Col>
               </Row><Row gutter={[32, 32]} justify="space-between" align="top" >
                  <Col span={8}>
                     <InputField
                        control={control}
                        name='oralPharmacyNCPDP'
                        label='Oral Pharmacy NCPDP'
                        type='text'
                        error={errors?.oralPharmacyNCPDP}
                        disabled={true}
                     />
                  </Col>
                  <Col span={8}>
                     <InputField
                        control={control}
                        name='oralPharmacyNABP'
                        label='Oral Pharmacy NABP'
                        type='text'
                        error={errors?.oralPharmacyNABP}
                        disabled={true}
                     />
                  </Col>
                  <Col span={8}>
                     <InputField
                        control={control}
                        name='oralPharmacyTaxId'
                        label='Oral Pharmacy Tax Id'
                        type='text'
                        error={errors?.oralPharmacyTaxId}
                        disabled={true}
                     />
                  </Col>
               </Row>
               <Row gutter={[32, 32]} justify="space-between" align="top" >
                  <Col span={6}>
                     <InputField
                        control={control}
                        name='numberOfMDs'
                        label='Number Of MDs'
                        type='text'
                        error={errors?.numberOfMDs}
                        disabled={true}
                     />
                  </Col>
                  <Col span={6}>
                     <InputField
                        control={control}
                        name='numberOfProviders'
                        label='Number Of Providers'
                        type='text'
                        error={errors?.numberOfProviders}
                        disabled={true}
                     />
                  </Col>
                  <Col span={6}>
                     <InputField
                        control={control}
                        name='numberOfExtenders'
                        label='Number Of Extenders'
                        type='text'
                        error={errors?.numberOfExtenders}
                        disabled={true}
                     />
                  </Col>
                  <Col span={6}>
                     <InputField
                        control={control}
                        name='fTEProviderCount'
                        label='FTE Provider Count'
                        type='text'
                        error={errors?.fTEProviderCount}
                        disabled={true}
                     />
                  </Col>
               </Row>
               <Row gutter={[32, 32]} justify="space-between" align="top" >
                  <Col span={8}>
                     <CheckboxField
                        control={control}
                        name='ePEnabled'
                        label='eP Enabled'
                        error={errors?.ePEnabled}
                        disabled={true}
                     />
                  </Col>
                  <Col span={8}>
                     <CheckboxField
                        control={control}
                        name='deactivated'
                        label='Deactivated'
                        error={errors?.deactivated}
                        disabled={true}
                     />
                  </Col>
                  <Col span={8}>
                     <DatePickerField
                        control={control}
                        name='deactivatedDate'
                        label='Deactivated Date'
                        error={errors?.deactivatedDate}
                        disabled={true}
                     />
                  </Col>
               </Row>
               <Row gutter={[32, 32]} justify="space-between" align="top" >
                  <Col span={12}>
                     <InputField
                        control={control}
                        name='practiceManagementSystem'
                        label='Practice Management System'
                        type='text'
                        error={errors?.practiceManagementSystem}
                        disabled={true}
                     />
                  </Col>
                  <Col span={12}>
                     <InputField
                        control={control}
                        name='pharmacyManagementSystem'
                        label='Pharmacy Management System'
                        type='text'
                        error={errors?.pharmacyManagementSystem}
                        disabled={true}
                     />
                  </Col>
               </Row>
            </Form>
         </div>

         <Dialog
            title='CONFIRM DEACTIVATE'
            open={isDeactivateDialogOpen}
            style={{ maxWidth: '500px' }}
            actionButtons={
               <>
                  <CancelButton onClick={() => setIsDeactivateDialogOpen(false)} />
                  <DeleteButton
                     hasIcon={false}
                     buttonText='Deactivate Practice'
                     disabled={isFormSaving}
                     onClick={() => handleDeactivateClick()} />

               </>
            }
         >
            {`Are you sure you want to Deactvate the Practice (${getValues()?.id}) ${getValues()?.name || ''}?`}
         </Dialog>


      </Dialog>
   )
   return html;
}

export default PracticeEditor;

