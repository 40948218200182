import { Divider } from 'antd';
import * as React from 'react';
import { EmailMessage } from '../../store/fsa/FundStatusAlertModel';
import colorWheel from '../../Theme/ColorWheel';
import Spinner from '../Spinner';

interface Props {
   emailMessage: EmailMessage;
};

//https://webdesign.tutsplus.com/articles/build-an-html-email-template-from-scratch--webdesign-12770
const FundStatusAlertEmail: React.FC<Props> = (props) => {

   const { emailMessage } = props;
   const [showBcc, setShowBcc] = React.useState<boolean>(false);

   if (!emailMessage) {
      return <Spinner />
   }

   return <div>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
         <div style={{width: 100, color: colorWheel.textGrey}}><b>To</b></div>
         <div>
            {emailMessage.to?.map((y, idx) => {
               return <React.Fragment key={idx}>{y.displayName} <span style={{ color: colorWheel.mediumGrey }}>{`<${y.email}>`}</span></React.Fragment>
            })}
         </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
         <div style={{ width: 100, color: colorWheel.textGrey }}><b>Bcc</b></div>
         <div style={{ width: '95%' }} >
            <div style={{paddingLeft: 5}} onClick={()=>setShowBcc(!showBcc) }><a>{`[Insightly Contact FundStatusCom list] - (${emailMessage.bcc.length} recipients)`}</a></div>
            {showBcc &&
            <div style={{ maxHeight: 200, overflow: 'auto', padding: '10px 0', borderTop: `1px solid ${colorWheel.mediumGrey}` }}>
               {emailMessage.bcc?.map((y, idx) => {
                  return <React.Fragment key={idx}>
                     {idx > 0 && (', ')}
                     {y.displayName} <span style={{ color: colorWheel.mediumGrey }}>{`<${y.email}>`}</span>
                     
                  </React.Fragment>
               })}
            </div>
            }
         </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
         <div style={{ width: 100, color: colorWheel.textGrey }}><b>Subject</b></div>
         <div>{emailMessage.subject}</div>
      </div>
      <Divider />
      <div dangerouslySetInnerHTML={{ __html: emailMessage.body }} />
   </div>
}
export default FundStatusAlertEmail;