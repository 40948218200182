import * as React from 'react';
import { useApiContext } from '../../../store/ApiContext';
import { useUserMainViewModel } from '../../../store/auth/UserMainFetcher';
import { useFetchAdparoPractices, useFetchPractice, usePracticeOptions } from '../../../store/practice/PracticeFetcher';
import { formatPracticeName } from '../../../store/practice/PracticeModel';
import { BulkUserProfile, UserProfile } from '../../../store/practice/UserProfileModel';
import { IFilteredInfo } from '../../shared/AntComponents/Filter/FilteredInfo';
import { Col, Row, Form, Divider } from 'antd';
import { ActionButton, AddButton, CancelButton, EditButton, SaveButton } from '../../shared/Buttons';
import Dialog from '../../Dialog';
import { BasicMultiSelect } from '../../shared/MultiSelect/BasicMultiSelectInput';
import { BasicCheckboxField, BasicFieldWrapper, BasicInputField, TValue, TValues } from '../../shared/BasicInputLibrary';
import { ColumnsType } from 'antd/lib/table';
import { numberComparer, stringComparer } from '../../../functions/comparer.functions';
import colorWheel from '../../../Theme/ColorWheel';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { InputField } from '../../shared/InputLibrary';
import MinimalistTable from '../../shared/AntComponents/Table/MinimalistTable';
import { antSortOptions } from '../../shared/AntComponents/Table/table.functions';
import { useFetchCallQueue } from '../../../store/practice/RingCallQueueFetcher';
import { ADPARO_OMEGA_ROLEID } from '../../../store/practice/UserRoleModel';
import { bulkSaveUserProfile, userProfileBulkSaveUrl } from '../../../store/practice/UserProfileFetcher';
import { useErrorContext } from '../../../store/ErrorContext';
import ApiErrorDisplay from '../../ApiErrorDisplay';
import { TitleError } from '../../shared/AntComponents/Typography/Title';
import Spinner from '../../Spinner';
import { validatePhoneOrFax } from '../../../functions/form.validators';

enum BulkCopyAction {
   None = "None",
   Add = "Add",
   Edit = "Edit"
}

interface IBulkCopyUserProfileProps {
   copyFromUserProfileId: number;
   copyFromPracticeId: number;
   userMainId: number;
   onCloseEditor: () => void;
   isEditorOpen: boolean;
}

const emptyProfile: UserProfile = {
   userProfileId: undefined,
   created: undefined,
   disableReason: undefined,
   externalNameIdentifier: undefined,
   externalObjectId: undefined,
   externalUpn: undefined,
   insightlyContactId: undefined,
   isAdmin: undefined,
   isDisabled: undefined,
   isFollowedByAllowed: undefined,
   modified: undefined,
   practiceId: undefined,
   timeZoneName: undefined,
   userRoles: undefined,
   emailAddress: undefined,
   externalCity: undefined,
   externalDirSyncEnabled: undefined,
   externalFirstName: undefined,
   externalFullName: undefined,
   externalLastName: undefined,
   externalState: undefined,
   fax: undefined,
   firstName: undefined,
   fullName: undefined,
   lastLogin: undefined,
   lastName: undefined,
   phone: undefined,
   phoneExt: undefined,
   practiceName: undefined,
   title: undefined,
   touVersion: undefined
}

interface IUserProfileConfirm extends UserProfile {
   action?: BulkCopyAction;
}

interface PotentialProfile {
   practiceId?: number;
   practiceName?: string;
   action?: BulkCopyAction;
   // Yes, UserProfile has the Practice attributes as well.  But the idea is that we won't initalize the userProfile obj here until the User attempts to add/edit
   userProfile: UserProfile;
}

interface EditorModel {
   records: PotentialProfile[];
}

const potentialProfileYupSchema = yup.object({
   records: yup.array(
      yup.object({
         practiceId: yup.number().notRequired(),
         practiceName: yup.string().notRequired(),
         action: yup.mixed<BulkCopyAction>().oneOf(Object.values(BulkCopyAction)).notRequired(),
         userProfile: yup.object({
            userProfileId: yup.number().notRequired(),
            practiceId: yup.number().notRequired(),
            externalObjectId: yup.string().notRequired(),
            externalNameIdentifier: yup.string().notRequired(),
            externalFullName: yup.string().notRequired(),
            externalCity: yup.string().notRequired(),
            externalState: yup.string().notRequired(),
            externalDirSyncEnabled: yup.boolean().notRequired(),

            /* First & Last name yup schema is a bit hacky... Needed to perform conditional validation such that
               First & Last name only need a value when we're Editing/Adding... tried to make this a .when(...) or
               .test(...) but I couldn't figure out how to access the parent object for .action to check its value
               So instead we have this.  PracticeId is only assigned to this object when we are Editing/Adding so: */
            firstName: yup.string()
               .when('practiceId', {
                  is: (val: number) => val > 0,
                  then: () => yup.string()
                     .required('First Name is required')
                     .max(200, 'First Name must not exceed 200 characters')
               }),
            lastName: yup.string()
               .when('practiceId', {
                  is: (val: number) => val > 0,
                  then: () => yup.string()
                     .required('Last Name is required')
                     .max(200, 'Last Name must not exceed 200 characters'),
               }),
            emailAddress: yup.string().notRequired(),
            phone: yup.string()
               .max(10, 'Phone must not exceed 10 characters')
               .test('phone', 'Invalid phone number, must be 10 numeric digits, and not start with 1 or 0',
                  (p) => validatePhoneOrFax(p)),
            phoneExt: yup.string().notRequired(),
            fax: yup.string()
               .max(10, 'Fax must not exceed 10 characters')
               .test('fax', 'Invalid fax number, must be 10 numeric digits, and not start with 1 or 0.',
                  (f) => validatePhoneOrFax(f)),
            isAdmin: yup.boolean().notRequired(),
            isDisabled: yup.boolean().notRequired(),
            isFollowedByAllowed: yup.boolean().notRequired(),
            touVersion: yup.number().notRequired(),
            lastLogin: yup.date().notRequired(),
            created: yup.date().notRequired(),
            modified: yup.date().notRequired(),
            timeZoneName: yup.string().notRequired(),
            disableReason: yup.string().notRequired(),
            userRoles: yup.array().notRequired(),
            externalUpn: yup.string().notRequired(),
            insightlyContactId: yup.number().notRequired()
         })
      })
   )
})

interface IPotentialProfileYup extends yup.Asserts<typeof potentialProfileYupSchema> { }
const __formId = "BulkCopyUserProfile_Form";


type searchFilter = 'practiceId' | 'adparoPracticesOnly' | 'modifedOnly';
const defaultFilter: Record<searchFilter, IFilteredInfo> = {
   practiceId: undefined,
   adparoPracticesOnly: { title: undefined, value: false },
   modifedOnly: { title: undefined, value: false }
};

const _keys: string[] = [userProfileBulkSaveUrl];

const BulkCopyUserProfile: React.FC<IBulkCopyUserProfileProps> = (props) => {
   const {
      copyFromUserProfileId,
      userMainId,
      copyFromPracticeId,
      onCloseEditor,
      isEditorOpen,
   } = props;

   const { httpGet, httpPost } = useApiContext();
   const { userMainViewModel } = useUserMainViewModel(httpGet, userMainId);
   const { practices } = useFetchPractice(httpGet);
   const { practiceOptions } = usePracticeOptions(httpGet);
   const { adparoPractices } = useFetchAdparoPractices(httpGet);
   const { callQueue } = useFetchCallQueue(httpGet);

   const [filteredInfo, setFilteredInfo] = React.useState<Record<searchFilter, IFilteredInfo>>(defaultFilter);
   const [displayAdparoPracticesOnly, setDisplayAdparoPracticesOnly] = React.useState<boolean>(false);
   const [displayModifiedRecordsOnly, setDisplayModifiedRecordsOnly] = React.useState<boolean>(false);
   const [displayConfirm, setDisplayConfirm] = React.useState(false);
   const [isSaving, setIsSaving] = React.useState(false);
   const { removeErrors } = useErrorContext();
   const clearErrors = () => {
      removeErrors({ keys: _keys });
   }

   const { control, formState: { errors }, reset, getValues, setValue } = useForm<IPotentialProfileYup>({
      resolver: yupResolver<yup.AnyObject>(potentialProfileYupSchema),
      shouldFocusError: true,
      mode: 'onChange',
      reValidateMode: 'onChange'
   });


   const adparoPracticeIds = React.useMemo(() => {
      return adparoPractices?.map(y => y.id);
   }, [adparoPractices])

   const copyFromProfile = React.useMemo(() => {
      return userMainViewModel?.userProfiles?.find(y => y.userProfileId === copyFromUserProfileId);
   }, [copyFromUserProfileId, userMainViewModel?.userProfiles]);

   const gridModel = React.useMemo(() => {
      // construct the PotentialProfile collection to feed to reset(...)
      if (practices?.length > 0) {
         const model: EditorModel = { records: [] } as EditorModel;

         // only active practices, and not the practice we're copying from
         practices.filter(y => !y.deactivated && y.id !== copyFromPracticeId).map(practice => {
            const userProfile = userMainViewModel?.userProfiles.find(y =>
               y.practiceId === practice.id &&
               y.userProfileId !== copyFromUserProfileId) // don't re-render the copyFromProfile

            model.records.push({
               practiceId: practice.id,
               practiceName: practice.name,
               action: BulkCopyAction.None,
               userProfile: userProfile
            } as PotentialProfile)
         });

         //console.log('constructed model in useMemo', model);
         return model;
      }
      return undefined;
   }, [practices, userMainViewModel?.userProfiles, copyFromUserProfileId, copyFromPracticeId]);

   React.useEffect(() => {
      reset(gridModel);
   }, [gridModel]);

   const handleClose = () => {
      clearErrors();
      onCloseEditor();
   }

   const getEditedRecords = (): PotentialProfile[] => {
      return getValues<'records'>('records').filter(y => y.action !== BulkCopyAction.None) as PotentialProfile[];
   }

   const saveProfiles = () => {
      const recordsToSave = getEditedRecords()?.map(r => r.userProfile as UserProfile);
      const data: BulkUserProfile = {
         userMainId: userMainId,
         userProfiles: recordsToSave
      };

      setIsSaving(true);
      bulkSaveUserProfile(httpPost, data)
         .then(handleClose)
         .finally(() => {
            setIsSaving(false);
         })
   }
   

   return <Dialog
      scrollingContent={true}
      title={'Bulk Copy User'}
      open={isEditorOpen}
      size="fullscreen"
      actionButtons={<>
         {!displayConfirm && <>
            <CancelButton onClick={() => {
               onCloseEditor();
            }} />
            <SaveButton
               buttonText={'Confirm User Profiles'}
               onClick={() => setDisplayConfirm(true)}
               disabled={!getValues()?.records?.some(y => y.action !== BulkCopyAction.None) || errors?.records?.length > 0}
            />

         </>}
         {displayConfirm && <>
            <CancelButton buttonText={'Return to Editing'} onClick={() => {
               setDisplayConfirm(false);
            }} />
            <SaveButton
               buttonText={'Save User Profiles'}
               onClick={saveProfiles} />
         </>}
      </>}>
      <div>
         <ApiErrorDisplay
            title='Error saving Profiles'
            keys={_keys}
         />

         {isSaving && <Spinner message={'Saving...'} /> }

         {!isSaving && !displayConfirm && <>
            {errors && Object.keys(errors).length > 0 && <>
               <TitleError
                  text='Please correct the errors below' />
               {console.log('--------------Form Errors: -------------------------')}
               {console.log(errors)}
            </>
            }

            <Form id={__formId}>
               <Row>
                  <Col span={6}>
                     <BasicMultiSelect
                        options={practiceOptions}
                        searchPlaceholder={'Filter By Practice'}
                        label={'Filter By Practice'}
                        searchable

                        value={filteredInfo.practiceId?.values?.map(y => y.value)}
                        onChange={(e) => {
                           const newValue = Array.isArray(e) ?
                              practiceOptions.filter(y => (e as TValues[]).some(t => y.value === t))
                              : practiceOptions.filter(y => (e as TValue) === y.value);

                           const newFilteredInfo = { ...filteredInfo };
                           if (!newFilteredInfo.practiceId?.values) { // Undefined on the first filter selection
                              newFilteredInfo.practiceId = {
                                 title: 'PracticeId',
                                 values: [...newValue],
                                 searchValue: filteredInfo.practiceId?.searchValue
                              } as IFilteredInfo;
                           } else {
                              newFilteredInfo.practiceId.values = [...newValue];
                           }
                           setFilteredInfo(newFilteredInfo);
                        }}
                     />
                  </Col>
                  <Col span={1} />
                  <Col span={4}>
                     <BasicCheckboxField
                        style={{ paddingLeft: '5' }}
                        label={'Adparo Practices'}
                        checked={filteredInfo.adparoPracticesOnly.value as boolean}
                        onChange={(e) => {
                           setDisplayAdparoPracticesOnly(!displayAdparoPracticesOnly);
                           setFilteredInfo({
                              ...filteredInfo,
                              adparoPracticesOnly: {
                                 title: undefined,
                                 value: e as boolean
                              }
                           })
                        }} />
                  </Col>
                  <Col span={4}>
                     <BasicCheckboxField
                        label={'Modified'}
                        checked={filteredInfo.modifedOnly.value as boolean}
                        onChange={(e) => {
                           setDisplayModifiedRecordsOnly(!displayModifiedRecordsOnly);
                           setFilteredInfo({
                              ...filteredInfo,
                              modifedOnly: {
                                 title: undefined,
                                 value: e as boolean
                              }
                           })
                        }} />
                  </Col>
               </Row>
               <Divider />
               <Row style={{ paddingRight: '10px' }} gutter={[8, 8]} key={'header'}>
                  <Col span={2}></Col>
                  <Col span={5}>Practice</Col>
                  <Col span={4}>First Name</Col>
                  <Col span={4}>Last Name</Col>
                  <Col span={2}>Phone</Col>
                  <Col span={2}>Fax</Col>
               </Row>
               <Row gutter={[8, 8]} key={copyFromProfile?.userProfileId}>
                  <Col span={2}><BasicFieldWrapper disabled field={'Copy Profile:'} /></Col>
                  <Col span={5}><BasicFieldWrapper disabled field={formatPracticeName(copyFromProfile?.practiceName, copyFromProfile?.practiceId)} /></Col>
                  <Col span={4}><BasicInputField disabled name='firstName' value={copyFromProfile?.firstName} /></Col>
                  <Col span={4}><BasicInputField disabled name='lastName' value={copyFromProfile?.lastName} /></Col>
                  <Col span={2}><BasicInputField disabled name='phone' value={copyFromProfile?.phone} /></Col>
                  <Col span={2}><BasicInputField disabled name='fax' value={copyFromProfile?.fax} /></Col>
               </Row>
               <div style={{
                  maxHeight: '350px',
                  overflowY: 'auto',
                  overflowX: 'hidden',
                  border: `solid 1px ${colorWheel.mediumGrey}`,
                  padding: '2px'
               }}>
                  {getValues('records')?.map((potentialProfile, index: number) => {
                     // filtering
                     if (filteredInfo.modifedOnly.value && potentialProfile.action === BulkCopyAction.None) return;
                     if (filteredInfo.adparoPracticesOnly.value && !adparoPracticeIds?.includes(potentialProfile.practiceId)) return;
                     if (filteredInfo.practiceId?.values?.length > 0 && !filteredInfo.practiceId.values.map(y => y.value as number).includes(potentialProfile.practiceId)) return;

                     const isBeingEdited = potentialProfile.action === BulkCopyAction.Edit;
                     const isBeingAdded = potentialProfile.action === BulkCopyAction.Add;

                     return <Row gutter={[8, 8]} key={`Row_${index}`}>
                        <Col span={2}>
                           {potentialProfile?.userProfile?.userProfileId && <>
                              {isBeingEdited &&
                                 <CancelButton title={'Cancel Editing'} displayNoText onClick={() => {
                                    // Cancel edit existing - reset to profile from server
                                    const originalProfile = userMainViewModel.userProfiles?.find(y => potentialProfile.userProfile.userProfileId === y.userProfileId);
                                    setValue(`records.${index}`,
                                       {
                                          ...potentialProfile,
                                          action: BulkCopyAction.None,

                                          // phone / fax need explicitly assigned when they're null, otherwise the display may get stuck with data that was entered then cancelled
                                          userProfile: {
                                             ...originalProfile,
                                             phone: originalProfile.phone ?? undefined,
                                             fax: originalProfile.fax ?? undefined
                                          }
                                       },
                                       { shouldValidate: true }
                                    );
                                 }} />
                              }
                              {!isBeingEdited &&
                                 <EditButton title={'Edit Existing'} onClick={() => {
                                    setValue(`records.${index}.action`, BulkCopyAction.Edit, { shouldValidate: true });
                                 }} />
                              }
                           </>}
                           {!potentialProfile?.userProfile?.userProfileId && <>
                              {isBeingAdded && <>
                                 <CancelButton title={'Cancel Adding'} displayNoText onClick={() => {
                                    setValue(`records.${index}`, {
                                       ...potentialProfile,
                                       action: BulkCopyAction.None,
                                       userProfile: emptyProfile,
                                    }, { shouldValidate: true });
                                 }} />
                              </>}
                              {!isBeingAdded && <>
                                 <AddButton title={'Add New'} displayNoText onClick={() => {
                                    // Add new - copy from the copyFromProfile, being mindful of "Omega" or not
                                    let thisCopy: UserProfile = copyFromProfile;

                                    if (copyFromProfile.userRoles?.includes(ADPARO_OMEGA_ROLEID)) {
                                       const relatedCallQueueRecord = callQueue.find(y => y.practiceId === potentialProfile.practiceId);
                                       if (relatedCallQueueRecord) {
                                          thisCopy = {
                                             ...copyFromProfile,
                                             fax: relatedCallQueueRecord.fax,
                                             phone: relatedCallQueueRecord.phone,
                                             phoneExt: undefined // no corresponding value in callQueue, but we clear it elsewhere for adparo? (CopyUserProfileEditor)
                                          };
                                       } else {
                                          thisCopy = {
                                             ...copyFromProfile,
                                             fax: undefined,
                                             phone: undefined,
                                             phoneExt: undefined
                                          }
                                       }
                                    }

                                    setValue(`records.${index}`, {
                                       ...potentialProfile,
                                       action: BulkCopyAction.Add,
                                       userProfile: {
                                          ...thisCopy,
                                          userProfileId: undefined,
                                          practiceId: potentialProfile.practiceId
                                       }
                                    }, { shouldValidate: true });
                                 }} />
                              </>}
                           </>}
                        </Col>
                        <Col span={5}><BasicFieldWrapper field={formatPracticeName(potentialProfile.practiceName, potentialProfile.practiceId)} /></Col>
                        <Col span={4}>
                           <InputField
                              disabled={!isBeingEdited && !isBeingAdded}
                              name={`records.${index}.userProfile.firstName`}
                              control={control}
                              error={errors?.records?.[index]?.userProfile?.firstName}
                              required
                           />
                        </Col>
                        <Col span={4}>
                           <InputField
                              disabled={!isBeingEdited && !isBeingAdded}
                              name={`records.${index}.userProfile.lastName`}
                              control={control}
                              error={errors?.records?.[index]?.userProfile?.lastName}
                              required
                           />
                        </Col>
                        <Col span={2}>
                           <InputField
                              disabled={!isBeingEdited && !isBeingAdded}
                              name={`records.${index}.userProfile.phone`}
                              control={control}
                              error={errors?.records?.[index]?.userProfile?.phone}
                           />
                        </Col>
                        <Col span={2}>
                           <InputField
                              disabled={!isBeingEdited && !isBeingAdded}
                              name={`records.${index}.userProfile.fax`}
                              control={control}
                              error={errors?.records?.[index]?.userProfile?.fax}
                           />
                        </Col>
                     </Row>
                  })}
               </div>
            </Form>
         </>}
         {!isSaving && displayConfirm && <>
         <ConfirmContent
               recordsToConfirm={getEditedRecords()?.map(r => {
               return {
                  action: r.action,
                  ...r.userProfile
               } as IUserProfileConfirm
         })} />
      </>}
      </div>


   </Dialog>
}

export default BulkCopyUserProfile;

interface IConfirmContentProps {
   recordsToConfirm: IUserProfileConfirm[];
}


const ConfirmContent: React.FC<IConfirmContentProps> = (props) => {
   const { recordsToConfirm } = props;

   const columns: ColumnsType<IUserProfileConfirm> = [
      {
         title: 'Action',
         dataIndex: 'action',
         sorter: (a, b) => stringComparer(a.action, b.action),
         sortDirections: antSortOptions
      },
      {
         title: 'PracticeId',
         dataIndex: 'practiceId',
         sorter: (a, b) => numberComparer(a.practiceId, b.practiceId),
         sortDirections: antSortOptions,
         render: (text, record) => formatPracticeName(record.practiceName, record.practiceId)
      },
      {
         title: 'First Name',
         dataIndex: 'firstName',
         sorter: (a, b) => stringComparer(a.firstName, b.firstName),
         sortDirections: antSortOptions
      },
      {
         title: 'Last Name',
         dataIndex: 'lastName',
         sorter: (a, b) => stringComparer(a.lastName, b.lastName),
         sortDirections: antSortOptions
      },
      {
         title: 'Phone',
         dataIndex: 'phone',
         sorter: (a, b) => stringComparer(a.phone, b.phone),
         sortDirections: antSortOptions
      },
      {
         title: 'Fax',
         dataIndex: 'fax',
         sorter: (a, b) => stringComparer(a.fax, b.fax),
         sortDirections: antSortOptions
      }
   ]

   const html = (<>

      <MinimalistTable
         columns={columns}
         data={recordsToConfirm}
         rowKey={'practiceId'}
      />

   </>)

   return html;
}