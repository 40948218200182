import * as React from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { InputField, DropdownField, CheckboxField } from '../../shared/InputLibrary';
import Dialog from '../../Dialog';
import { SaveButton, DeleteButton, CancelButton } from '../../shared/Buttons';
import Spinner from '../../Spinner';
import { Form } from 'antd';
import { useManufacturerOptions } from '../../../store/program/ManufacturerFetcher';
import { useBrandOptions } from '../../../store/drug/BrandFetcher';
import { useDrugRouteOptions } from '../../../store/drug/DrugRouteFetcher';
import { useGenericDrugOptions } from '../../../store/drug/GenericDrugFetcher';
import { Drug } from '../../../store/drug/DrugModel';
import { useFetchDrug, saveDrug, deleteDrug, drugSaveUrl, drugDeleteUrl } from '../../../store/drug/DrugFetcher';
import ApiErrorDisplay from '../../ApiErrorDisplay';
import { TitleError } from '../../shared/AntComponents/Typography/Title';
import { ColumnsType } from 'antd/lib/table';
import MinimalistTable from '../../shared/AntComponents/Table/MinimalistTable';
import { numberComparer, stringComparer } from '../../../functions/comparer.functions';
import { useFetchAssistancePrograms } from '../../../store/program/AssistanceProgramFetcher';
import { useFetchAssistanceServices } from '../../../store/program/AssistanceServiceFetcher';
import { useFetchAssistanceServiceToDrug } from '../../../store/program/AssistanceServiceToDrugFetcher';
import { useApiContext } from '../../../store/ApiContext';
import { useErrorContext } from '../../../store/ErrorContext';

interface IProps {
   isOpen: boolean;
   closeEditor: () => void;
   drugId?: number;
};

interface IDrugServicesAndPrograms {
   assistanceServiceId: number,
   assistanceServiceName: string,
   assistanceProgramId: number,
   assistanceProgramName: string
}

const dialogContentStyle = {
   height: '580px',
   maxHeight: '95vh',
}

const yupDrugSchema = yup.object({
   id: yup.number().notRequired(),
   drugName: yup.string()
      .required('Drug Name is required')
      .max(256, 'Max length is 256 characters'),
   manufacturerId: yup.number()
      .required('Manufacturer is required'),
   demoOnly: yup.boolean().notRequired(),
   genericId: yup.number()
      .required('Generic Drug is required'),
   //logoUrl: yup.string()
   //   .url()
   //   .max(1024, 'Max length is 1024 characters')
   //   .notRequired(),
   //description: yup.string()
   //   .required('Description is required')
   //   .max(1024, 'Max length is 1024 characters'),
   brandId: yup.number()
      .required('Brand is required'),
   route: yup.number()
      .required('Route is required'),
   ndcPrefix: yup.string()
      .required('NDC Prefix is required')
      .max(9, 'Max length is 9 characters'),
});

interface IDrugYup extends yup.Asserts<typeof yupDrugSchema> { }
const __formId = "frmDrug";


const DrugEditor: React.FC<IProps> = (props) => {
   const { closeEditor, isOpen, drugId } = props;
   const _keys: string[] = drugId ? [drugSaveUrl, drugDeleteUrl(drugId)] : [drugSaveUrl];

   const { httpGet, httpPost, httpDelete } = useApiContext();
   const { removeErrors } = useErrorContext();
   const { drugs } = useFetchDrug(httpGet);
   const { manufacturerOptions } = useManufacturerOptions(httpGet);
   const { brandOptions } = useBrandOptions(httpGet);
   const { drugRouteOptions } = useDrugRouteOptions(httpGet);
   const { genericDrugOptions } = useGenericDrugOptions(httpGet);
   const { assistancePrograms } = useFetchAssistancePrograms(httpGet);
   const { assistanceServices } = useFetchAssistanceServices(httpGet);
   const { assistanceServiceToDrugs } = useFetchAssistanceServiceToDrug(httpGet);

   const [isFormSaving, setIsFormSaving] = React.useState<boolean>(false);

   const {
      control,
      handleSubmit,
      formState: { errors },
      reset,
      setValue,
      getValues
   } = useForm<IDrugYup>({
      resolver: yupResolver<yup.AnyObject>(yupDrugSchema),
      shouldFocusError: true,
   });

   React.useEffect(() => {
      let resetVal = {};
      if (drugId && drugs?.length > 0) {
         resetVal = drugs.find(y => y.id === drugId);
      }
      reset(resetVal);
      // for "reset()"
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [drugId, drugs]);

   const servicesAndPrograms = React.useMemo(() => {
      if (assistancePrograms && assistanceServices && assistanceServiceToDrugs) {
         const list: IDrugServicesAndPrograms[] = [];

         let serviceLinks = assistanceServiceToDrugs.filter(asd => asd.drugId === drugId);
         if (serviceLinks?.length === 0) return;

         serviceLinks.forEach(link => {
            let service = assistanceServices.find(s => s.id === link.assistanceServiceId);
            let program = assistancePrograms.find(p => p.id === service.assistanceProgramId);

            list.push({
               assistanceServiceId: service.id,
               assistanceServiceName: service.assistanceServiceName,
               assistanceProgramId: program.id,
               assistanceProgramName: program.programName
            });
         });

         return list;
      }

   }, [drugId, assistancePrograms, assistanceServices, assistanceServiceToDrugs])

   const onSubmit = (data: IDrugYup): void => {
      //console.log('--------------AssistanceServiceEditor-------------------------')
      //console.log(errors)

      //ts hoops to convert data with unknown props into assistance programs
      const item = data as unknown as Drug;
      setIsFormSaving(true);
      saveDrug(httpPost, item)
         .then(handleClose)
         .catch(err => console.error('Error Saving Drug', err))
         .finally(() => {
            setIsFormSaving(false);
         });
   }

   const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState<boolean>(false);

   const handleDeleteClick = () => {
      setIsDeleteDialogOpen(false);
      deleteDrug(httpDelete, getValues().id)
         .then(handleClose)
         .catch(err => console.error('Error Deleting Drug', err))
   }

   const handleClose = () => {
      setIsFormSaving(false);
      setIsDeleteDialogOpen(false);
      removeErrors({ keys: _keys });
      closeEditor();
   }

   if (isFormSaving) {
      return <Spinner message='Saving Drug...' />
   }

   //console.log('--------------AssistanceServcieEditor-------------------------')
   //console.log(errors)

   const columns: ColumnsType<IDrugServicesAndPrograms> = [
      {
         title: 'AssistanceServiceId',
         dataIndex: 'assistanceServiceId',
         key: 1,
         sorter: (a, b) => numberComparer(a.assistanceServiceId, b.assistanceServiceId),
         sortDirections: ['ascend', 'descend'],
         defaultSortOrder: 'ascend'
      },
      {
         title: 'AssistanceServiceName',
         dataIndex: 'assistanceServiceName',
         key: 2,
         sorter: (a, b) => stringComparer(a.assistanceServiceName, b.assistanceServiceName),
         sortDirections: ['ascend', 'descend'],
      },
      {
         title: 'AssistanceProgramId',
         dataIndex: 'assistanceProgramId',
         key: 3,
         sorter: (a, b) => numberComparer(a.assistanceProgramId, b.assistanceProgramId),
         sortDirections: ['ascend', 'descend'],
      },
      {
         title: 'AssistanceProgramName',
         dataIndex: 'assistanceProgramName',
         key: 4,
         sorter: (a, b) => stringComparer(a.assistanceProgramName, b.assistanceProgramName),
         sortDirections: ['ascend', 'descend'],
      }
   ]

   const html = (
      <Dialog
         scrollingContent={true}
         title={'Drug Editor'}
         open={isOpen}
         size="large"
         actionButtons={
            <>
               <CancelButton onClick={() => {
                  handleClose();
               }} />
               <DeleteButton
                  onClick={() => setIsDeleteDialogOpen(true)}
                  disabled={!getValues()?.id || isDeleteDialogOpen}
                  loading={isDeleteDialogOpen} />
               <SaveButton
                  formId={__formId}
                  onClick={() => null}
                  disabled={Object.keys(errors).length > 0}
                  loading={isFormSaving} />
            </>
         }>
         <div style={dialogContentStyle}>

            {errors && Object.keys(errors).length > 0 && <>
               <TitleError
                  text='Please correct the errors below' />
               {console.log('--------------Form Errors: -------------------------')}
               {console.log(errors)}
            </>
            }

            <ApiErrorDisplay
               title='Error saving Drug'
               keys={_keys}
            />

            <Form id={__formId} onFinish={handleSubmit(onSubmit)}>
               <InputField
                  control={control}
                  name='drugName'
                  label='Drug Name'
                  type='text'
                  error={errors?.drugName}
                  required={true}
               />
               <DropdownField
                  control={control}
                  name='manufacturerId'
                  label='Manufacturer Id'
                  error={errors?.manufacturerId}
                  multiple={false}
                  options={manufacturerOptions}
                  setValue={setValue}
                  required={true}
                  search={true}
                  clearable={true}
               />
               <CheckboxField
                  control={control}
                  name='demoOnly'
                  label='Demo Only'
                  error={errors?.demoOnly}
                  toggle={true}
                  setValue={setValue}
                  required={false}
               />
               <DropdownField
                  control={control}
                  name='genericId'
                  label='Generic Id'
                  error={errors?.genericId}
                  multiple={false}
                  options={genericDrugOptions}
                  setValue={setValue}
                  required={true}
                  search={true}
                  clearable={true}
               />
               {/*<InputField
                           control={control}
                           name='logoUrl'
                           label='logoUrl'
                           type='text'
                           error={errors?.logoUrl}
                           required={false}
                        />
                        <TextAreaField
                           control={control}
                           name='description'
                           label='Description'
                           error={errors?.description}
                           required={true}
                        />*/}
               <DropdownField
                  control={control}
                  name='brandId'
                  label='Brand Id'
                  error={errors?.brandId}
                  multiple={false}
                  options={brandOptions}
                  setValue={setValue}
                  required={true}
                  search={true}
                  clearable={true}
               />
               <DropdownField
                  control={control}
                  name='route'
                  label='Route'
                  error={errors?.route}
                  multiple={false}
                  options={drugRouteOptions}
                  setValue={setValue}
                  required={true}
                  search={true}
                  clearable={true}
               />
               <InputField
                  control={control}
                  name='ndcPrefix'
                  label='Ndc Prefix'
                  type='text'
                  error={errors?.ndcPrefix}
                  required={true}
               />
            </Form>

            {drugId &&
               <MinimalistTable
                  rowKey='assistanceServiceId'
                  columns={columns}
                  data={servicesAndPrograms}
                  titleText={'Related Assistance Services and Programs'}
                  titleLevel={5}
                  showTitle={true}
                  showHeader={true}
                  bordered={true}
               />
            }
         </div>

         <Dialog
            title='CONFIRM DELETE'
            open={isDeleteDialogOpen}
            style={{ maxWidth: '550px' }}
            actionButtons={
               <>
                  <CancelButton onClick={() => setIsDeleteDialogOpen(false)} />
                  <DeleteButton
                     disabled={isFormSaving}
                     onClick={() => handleDeleteClick()} />
               </>
            }
         >
            {`Are you sure you want to Delete the Drug (${getValues()?.id}) ${getValues()?.drugName || ''}?`}
         </Dialog>

      </Dialog>
   )
   return html;
}

export default DrugEditor;
