import { useMemo } from 'react';
import useSWR from 'swr';
import { HttpError, TGet } from '../../functions/httpClient';
import { IOptionItem } from '../../functions/option.functions';
import { InsuranceClass, InsuranceClassTypes } from './InsuranceClassModel';

export const insuranceClassBaseUrl = `api/program/InsuranceClass`;

const swrOptions = {
   refreshInterval: 0,
   revalidateOnFocus: false,
   dedupingInterval: 60000
};

export const useFetchInsuranceClasses = (httpGet: TGet) => {
   const { data, isLoading, error } = useSWR<InsuranceClass[], HttpError>(
      insuranceClassBaseUrl,
      httpGet,
      { ...swrOptions }
   );

   return {
      insuranceClasses: data,
      isLoading: isLoading,
      error: error
   };
}

export const useFetchInsuranceClassesActive = (httpGet: TGet) => {
   const { insuranceClasses, isLoading, error } = useFetchInsuranceClasses(httpGet);

   return {
      insuranceClasses: insuranceClasses?.filter(y => y.insuranceClassId !== InsuranceClassTypes.FunctionallyUninsured),
      isLoading: isLoading,
      error: error
   };
}

export const getInsuranceClassById = (id: number, insurances: InsuranceClass[]): InsuranceClass => {
   // eslint-disable-next-line no-restricted-globals
   return insurances?.find((f: InsuranceClass) => f.insuranceClassId === id) ?? undefined
};
export const buildInsuranceClassOptions = (insurances: InsuranceClass[]): IOptionItem[] => {
   if (insurances?.length > 0) {
      const sorted = [...insurances];

      const tempOptions = sorted.map((opt): IOptionItem => ({
         key: opt.insuranceClassId,
         label: `[${opt.insuranceClassId}] ${opt.name}`,
         value: opt.insuranceClassId
      } as IOptionItem));

      return tempOptions;
   }
   return [];
}

export function useInsuranceClassOptions(httpGet: TGet, activeClasses: boolean = false): { insuranceClassOptions: IOptionItem[] } {
   const { insuranceClasses } = useFetchInsuranceClasses(httpGet);
   const insuranceClassOptions = useMemo(() => {
      if (insuranceClasses?.length > 0) {
         const source = insuranceClasses.filter(y => !activeClasses || y.insuranceClassId !== InsuranceClassTypes.FunctionallyUninsured);
         const options = buildInsuranceClassOptions(source);
         return options;
      }

      return [];
   }, [insuranceClasses, activeClasses])

   return { insuranceClassOptions };
}
