import * as React from 'react';
import { useLayoutContext } from './LayoutContext';
import SidebarFooter from './SidebarFooter'
import MainMenu from './menu/MainMenu'
import Help from '../components/Help';
import Settings from '../components/Settings';
import './Sidebar.css';
import CustomIcon, { CustomIconType } from '../components/shared/AntComponents/CustomIcon'
import colorWheel from '../Theme/ColorWheel';
import { Divider } from 'antd';

const Sidebar: React.FC = () => {
   const { menuProps } = useLayoutContext();

   const html = (
      <div className={"sidebar " + (menuProps.collapsed ? 'collapsed' : '')}>
         <div className="main-sidebar">
            <div className="ap-sidebar-header">
               <span style={{ paddingTop: 8, paddingLeft: 4 }} onClick={() => menuProps.toggleSidebar()}>
                  <CustomIcon type={menuProps.collapsed ? CustomIconType.MenuUnfoldOutlined : CustomIconType.MenuFoldOutlined}
                     style={{
                        fontWeight: 'bold',
                        fontSize: '1.75em',
                        color: colorWheel.tangerineOrange,
                     }}
                  />
               </span>
               {menuProps.collapsed === false &&
                  <img className="menu-logo" alt="Annexus Health" />
               }
            </div>
            <div className="ap-sidebar-card">
               <div onClick={() => menuProps.showMainMenu()}>
                  <MainMenu />
               </div>
               {menuProps.displayIndex === 2 &&
                  <div className="curtain">
                     <Settings />
                  </div>
               }
            </div>
            {!menuProps.collapsed && menuProps.displayIndex !== 2 && <div className='ap-footer-help'>
               <Divider />
               <Help />
            </div>}
            <SidebarFooter />
         </div>
      </div>
   );
   return html;
}

export default Sidebar;
