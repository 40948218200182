import * as React from 'react';

interface ILayoutContextProps {
   cardName: string;
   setCardName: (name: string) => void;
   cardHeaderTotalCount: number;
   setCardHeaderTotalCount: (count: number) => void;
   cardHeaderFilteredCount: number;
   setCardHeaderFilteredCount: (count: number) => void;
   menuProps: IMenuProps;
}

export interface IMenuProps {
   displayIndex: number;
   setDisplayIndex: (i: number) => void;
   collapsed: boolean;
   showMainMenu: () => void;
   toggleSidebar: () => void;
}

interface ILayoutContextProviderProps {
   children: React.ReactNode;
}

export const LayoutContext = React.createContext<ILayoutContextProps>({} as ILayoutContextProps);
export const useLayoutContext = () => React.useContext(LayoutContext);


export const LayoutContextProvider: React.FC<ILayoutContextProviderProps> = (props) => {
   const [cardHeaderTotalCount, setCardHeaderTotalCount] = React.useState(0);
   const [cardHeaderFilteredCount, setCardHeaderFilteredCount] = React.useState(0);
   const [cardName, setCardName] = React.useState('');

   const [collapsed, setCollapsed] = React.useState<boolean>(false);
   const [displayIndex, setDisplayIndex] = React.useState<number>(-1);

   const toggleSidebar = () => {
      const isCollapsedNext = !collapsed;
      setCollapsed(isCollapsedNext);
      if (isCollapsedNext && displayIndex > -1) {
         setDisplayIndex(-1);
      }
   }

   const showMainMenu = () => {
      setDisplayIndex(-1);
   }

   const _setCardName = (name: string) => {
      if (name !== cardName) setCardName(name);
   }

   const _setCardHeaderTotalCount = (total: number) => {
      if (total !== cardHeaderTotalCount) setCardHeaderTotalCount(total);
   }

   const _setCardHeaderFilteredCount = (total: number) => {
      if (total !== cardHeaderFilteredCount) setCardHeaderFilteredCount(total);
   }

   //console.log('LayoutContext:just above the return for the provider'); // being used to check re-renders after moving around contexts and the like
   return <LayoutContext.Provider
      value={{
         cardName,
         setCardName: _setCardName,
         cardHeaderTotalCount,
         setCardHeaderTotalCount: _setCardHeaderTotalCount,
         cardHeaderFilteredCount,
         setCardHeaderFilteredCount: _setCardHeaderFilteredCount,

         menuProps:
         {
            displayIndex: displayIndex,
            setDisplayIndex: setDisplayIndex,
            collapsed: collapsed,
            showMainMenu: showMainMenu,
            toggleSidebar: toggleSidebar
         }
      }}>
      {props.children}
   </LayoutContext.Provider>
}
