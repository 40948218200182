export interface UserPendingAccessModel {
   id?: number;
   emailAddress: string;
   auth0Id: string;
   firstName: string;
   lastName: string;
   externalFirstName: string;
   externalLastName: string;
   titleOrRole: string;
   requestedAccessOn: Date;
   requestedPracticeName: string;
   status: number;
   statusLastChangedBy?: number;
   statusDate?: Date;
   statusReason: string;
   phone: string;
   upn: string;
   idToken: string;
}

export interface UserPendingAccessDenyModel {
   pendingUserId: number;
   statusReason: string;
}

export enum UserPendingAccessModelStatusType {
   Pending = 1
}