import { replaceWhitespace } from "../functions/common.functions";
import { HttpError } from "../functions/httpClient";

export interface PagedList<T> {
   data: T[];
   page: number;
   pageSize: number;
   totalRecords: number;
   totalPages: number;
}

export interface NameAndId {
   id: number;
   name: string;
}

export interface IStatus {
   isLoading: boolean;
   error?: string;
   isSaving?: boolean;
}

export interface IApiResponse<T> {
   data: T;
   isLoading: boolean;
   isValidating: boolean;
   error?: HttpError;
   isSaving?: boolean;
}

export interface IOption {
   Name: string;
   Value: any; //really int, bool, string...
   IsDefault: boolean;
}

export interface ISelectOption {
   key?: number | string
   text: string;
   value: any;
}

export interface FieldOption {
   formFieldId: number;
   name: string;
   value: string;
   isDefault: boolean;
   sequence: number;
}

export enum EditorAction {
    Add,
    Edit,
    Delete
}

export const getKey = (value: string) => replaceWhitespace((value ?? '').toLowerCase().trim());

export interface KeyValue {
   key: string;
   value: string;
}

export interface KeyValueItem<T> {
   key: string;
   value: string;
   item: T
}

