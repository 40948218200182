import { AssistanceServiceFoundationDefaults, AssistanceServiceFoundationDefaultsViewModel } from './AssistanceServiceFoundationDefaultsModel';
import * as React from 'react';
import useSWR, { mutate } from "swr";
import { HttpError, TGet, TPost, TDelete } from "../../functions/httpClient";
import { IOptionItem } from "../../functions/option.functions";
import { IStatus } from '../models';
import { useAssistanceProgramNameOptions, useFetchAssistancePrograms } from './AssistanceProgramFetcher';
import { useFetchAssistanceServices } from './AssistanceServiceFetcher';
import { useFetchFoundationDiseaseTypeGroups, useFetchFoundationDiseaseTypes } from './FoundationDiseaseType';
import { useFetchFoundations, useFoundationIdOptions } from './FoundationFetcher';
import { numberComparer, stringComparer } from '../../functions/comparer.functions';
import { refreshActiveCohorts } from '../cohort/CohortFetcher';

export const assistanceServiceFoundationDefaultsBaseUrl = 'api/program/AssistanceServiceFoundationDefaults';
const saveAssistanceServiceFoundationDefaultsUrl = `${assistanceServiceFoundationDefaultsBaseUrl}/Save`;
const deleteAssistanceServiceFoundationDefaultUrl = (id: number) => `${assistanceServiceFoundationDefaultsBaseUrl}/Delete/${id}`;

const swrOptions = {
   refreshInterval: 0,
   revalidateOnFocus: false,
   dedupingInterval: 60000
};

export const useFetchFoundationDefaults = (httpGet: TGet): IStatus & { foundationDefaults: AssistanceServiceFoundationDefaults[] } => {
   const { data, isLoading, error } = useSWR<AssistanceServiceFoundationDefaults[], HttpError>(
      assistanceServiceFoundationDefaultsBaseUrl,
      httpGet,
      { ...swrOptions }
   );

   return {
      foundationDefaults: data,
      isLoading: isLoading,
      error: error?.message
   };
}

export const saveFoundationDefaults = async (httpPost: TPost, foundationDefaults: AssistanceServiceFoundationDefaults[], cohortFromDate: Date) => {
   const result = await httpPost(saveAssistanceServiceFoundationDefaultsUrl, foundationDefaults);
   refreshActiveCohorts(httpPost, cohortFromDate);
   mutate(assistanceServiceFoundationDefaultsBaseUrl);
   return result;
}

export const deleteFoundationDefault = async (httpDelete: TDelete, id: number) => {
   const result = await httpDelete(deleteAssistanceServiceFoundationDefaultUrl(id));
   mutate(assistanceServiceFoundationDefaultsBaseUrl);
   return result;
}

export function useFoundationDefaultsViewModel(httpGet: TGet): IStatus & { foundationDefaultsViewModelList: AssistanceServiceFoundationDefaultsViewModel[] } {
   const { foundationDefaults, isLoading, error, isSaving } = useFetchFoundationDefaults(httpGet);
   const { assistancePrograms, isLoading: isLoading_p, error: error_p } = useFetchAssistancePrograms(httpGet);
   const { assistanceServices, isLoading: isLoading_s, error: error_s } = useFetchAssistanceServices(httpGet);
   const { foundations, isLoading: isLoading_f, error: error_f } = useFetchFoundations(httpGet);
   const { foundationDiseaseTypes, isLoading: isLoading_fdt, error: error_fdt } = useFetchFoundationDiseaseTypes(httpGet);
   const { foundationDiseaseTypeGroups, isLoading: isLoading_g, error: error_g } = useFetchFoundationDiseaseTypeGroups(httpGet);

   const viewModel = React.useMemo(() => {
      if (foundationDefaults?.length > 0 &&
         assistancePrograms?.length > 0 &&
         assistanceServices?.length > 0 &&
         foundations?.length > 0 &&
         foundationDiseaseTypes?.length > 0 &&
         foundationDiseaseTypeGroups?.length > 0) {
         let tempList = foundationDefaults.map((item): AssistanceServiceFoundationDefaultsViewModel => {
            const s = assistanceServices.find(y => y.id === item.assistanceServiceId);
            const p = s ? assistancePrograms.find(y => y.id === s?.assistanceProgramId) : undefined;
            const fdt = foundationDiseaseTypes.find(y => y.id === item.foundationDiseaseTypeId);
            const f = fdt ? foundations.find(y => y.id === fdt.foundationId) : undefined;
            const g = fdt ? foundationDiseaseTypeGroups.find(y => y.foundationDiseaseTypeId === item.foundationDiseaseTypeId) : undefined;

            return {
               ...item,
               foundationId: fdt?.foundationId,
               foundationName: f?.foundationName,
               diseaseTypeId: g?.diseaseTypeId ?? fdt?.diseaseTypeId,
               diseaseTypeName: fdt?.diseaseTypeName,
               assistanceServiceName: s?.assistanceServiceName,
               assistanceProgramId: p?.id,
               programName: p?.programName
            } as AssistanceServiceFoundationDefaultsViewModel

         });
         return tempList;
      }
      return [];
   }, [foundationDefaults, assistancePrograms, assistanceServices,
      foundations, foundationDiseaseTypes, foundationDiseaseTypeGroups]);

   return {
      foundationDefaultsViewModelList: viewModel,
      isLoading: isLoading || isLoading_p || isLoading_s || isLoading_f || isLoading_fdt || isLoading_g,
      error: error ?? error_p ?? error_s ?? error_f ?? error_fdt ?? error_g,
      isSaving
   };
}

export function useFoundationDefaultsFoundationOptions(httpGet: TGet): { foundationDefaultsFoundationOptions: IOptionItem[] } {
   const { foundationDefaultsViewModelList } = useFoundationDefaultsViewModel(httpGet);
   const { foundationOptions } = useFoundationIdOptions(httpGet);

   const options = React.useMemo(() => {
      if (foundationDefaultsViewModelList?.length > 0 && foundationDefaultsViewModelList?.length > 0) {

         const options: IOptionItem[] = [];
         foundationOptions.reduce((acc: IOptionItem[], option) => {
            if (foundationDefaultsViewModelList.some(y => y.foundationId === option.value)) {
               options.push(option);
            }
            return acc;
         }, []);

         return options;
      }
      return [];
   }, [foundationDefaultsViewModelList, foundationOptions]);

   return {
      foundationDefaultsFoundationOptions: options
   };
}

export function useFoundationDefaultsFoundationDiseaseTypeOptions(httpGet: TGet): { foundationDefaultsFoundationDiseaseTypeOptions: IOptionItem[] } {
   //chose to build this from scratch off viewmodel because the foundation disease type list didn't contain all the records
   const { foundationDefaultsViewModelList } = useFoundationDefaultsViewModel(httpGet);

   const options = React.useMemo(() => {
      if (foundationDefaultsViewModelList?.length > 0) {

         const sorted = [...foundationDefaultsViewModelList];
         sorted.sort((a: AssistanceServiceFoundationDefaultsViewModel, b: AssistanceServiceFoundationDefaultsViewModel) => numberComparer(a.foundationDiseaseTypeId, b.foundationDiseaseTypeId));
         sorted.sort((a: AssistanceServiceFoundationDefaultsViewModel, b: AssistanceServiceFoundationDefaultsViewModel) => stringComparer(a.diseaseTypeName, b.diseaseTypeName));

         const options: IOptionItem[] = [];
         sorted.reduce((acc, { foundationDiseaseTypeId, diseaseTypeName }) => {
            if (!options.some(y => y.value === foundationDiseaseTypeId)) {
               options.push({
                  label: `[${foundationDiseaseTypeId}]: ${diseaseTypeName ?? 'Not found'}`,
                  value: foundationDiseaseTypeId
               } as IOptionItem);
            }
            return acc;
         }, []);

         return options;
      }
      return [];

   }, [foundationDefaultsViewModelList]);

   return {
      foundationDefaultsFoundationDiseaseTypeOptions: options
   };
}

export function useFoundationDefaultsAssistanceProgramOptions(httpGet: TGet): { foundationDefaultsAssistanceProgramOptions: IOptionItem[] } {
   const { foundationDefaultsViewModelList } = useFoundationDefaultsViewModel(httpGet);
   const { assistanceProgramNameOptions } = useAssistanceProgramNameOptions(httpGet);

   const options = React.useMemo(() => {
      if (foundationDefaultsViewModelList?.length > 0 && assistanceProgramNameOptions?.length > 0) {

         const options: IOptionItem[] = [];
         assistanceProgramNameOptions.reduce((acc: IOptionItem[], option) => {
            if (foundationDefaultsViewModelList.some(y => y.assistanceProgramId === option.value)) {
               options.push(option);
            }
            return acc;
         }, []);

         return options;
      }
      return [];
   }, [foundationDefaultsViewModelList, assistanceProgramNameOptions]);

   return {
      foundationDefaultsAssistanceProgramOptions: options
   };
}
