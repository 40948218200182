import * as React from 'react';
import { Form, Row, Col, Divider } from 'antd';
import CustomIcon, { CustomIconType } from '../../shared/AntComponents/CustomIcon'
import { useForm } from 'react-hook-form';
import { InputField, DropdownField } from '../../shared/InputLibrary';
import { BasicDropdownField, BasicInputField } from '../../shared/BasicInputLibrary';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Dialog from '../../Dialog';
import { SaveButton, CancelButton, ActionButton, AddButton } from '../../shared/Buttons';
import Spinner from '../../Spinner';
import { usePracticeOptions } from '../../../store/practice/PracticeFetcher';
import { useAssistanceProgramNameOptions, useFetchAssistancePrograms } from '../../../store/program/AssistanceProgramFetcher';
import { useBrandOptions } from '../../../store/drug/BrandFetcher';
import { xrefStatusOptions, IntrinsiqPayerToAP } from '../../../store/content-tool/IntrinsiqPayerToAPModel';
import {
   saveIntrinsiqPayerToAP,
   intrinsiqPayerBaseUrl
} from '../../../store/content-tool/IntrinsiqPayerToAPFetcher';
import ApiErrorDisplay from '../../ApiErrorDisplay';
import colorWheel from '../../../Theme/ColorWheel';
import { formatDateTimeString } from '../../../functions/format.functions';
import { TitleError } from '../../shared/AntComponents/Typography/Title';
import { useApiContext } from '../../../store/ApiContext';
import { useErrorContext } from '../../../store/ErrorContext';


interface IProps {
    isOpen: boolean;
    closeEditor: () => void;
    record?: IntrinsiqPayerToAP;
};

const dialogContentStyle = {
    height: '580px',
    maxHeight: '95vh',
}

const yupIntrinsiqPayerToAPSchema = yup.object({
    id: yup.number().notRequired(),
    practiceId: yup.number().notRequired(),
    payeeName: yup.string()
        .max(255, 'Max length is 255 characters')
        .notRequired(),
    payeePCN: yup.string()
        .max(255, 'Max length is 255 characters')
        .notRequired(),
    payeeBin: yup.string()
        .max(255, 'Max length is 255 characters')
        .notRequired(),
    brandId: yup.number()
        .nullable()
        .transform((_, val) => val === '' ? null : Number(val)),
    assistanceProgramId: yup.number()
        .nullable()
        .transform((_, val) => val === '' ? null : Number(val))
        .when('status', {
            is: 'Mapped',
           then: () => yup.number()
                .required('assistanceProgramId is required when selecting "Mapped" status')
        }),
    programName: yup.string()
        .max(255, 'Max length is 255 characters')
        .notRequired(),
    status: yup.string()
        .required('Status is required')
        .max(16, 'Max length is 16 characters'),
    reviewedBy: yup.string().notRequired(),
    reviewedOn: yup.date().notRequired()
});

const __formId = "frmPracticeServiceToApXRef";
const _keysLike: string[] = [intrinsiqPayerBaseUrl];

export interface IAdditionalProgram {
    key: number;
    assistanceProgramId?: number;
    status: string;
    error?: string;
}

interface IIntrinsiqPayerToAPYup extends yup.Asserts<typeof yupIntrinsiqPayerToAPSchema> { }

const IntrinsiqPayerToApXRefEditor: React.FC<IProps> = (props) => {
   const { isOpen, closeEditor, record } = props;

   const { httpGet, httpPost } = useApiContext();
   const { removeErrors } = useErrorContext();
   const [additionalPrograms, setAdditionalPrograms] = React.useState<IAdditionalProgram[]>([]);
   const [isFormSaving, setIsFormSaving] = React.useState<boolean>(false);

   const { practiceOptions } = usePracticeOptions(httpGet);
   const { assistancePrograms } = useFetchAssistancePrograms(httpGet);
   const { assistanceProgramNameOptions } = useAssistanceProgramNameOptions(httpGet);
   const { brandOptions } = useBrandOptions(httpGet);
   const _addNewRef = React.useRef(null);

   const {
      //register,
      control,
      handleSubmit,
      formState: { errors },
      reset,
      setValue,
      getValues,
      trigger,
   } = useForm<IIntrinsiqPayerToAPYup>({
      resolver: yupResolver<yup.AnyObject>(yupIntrinsiqPayerToAPSchema),
      shouldFocusError: true,
   });

   React.useEffect(() => {
      setAdditionalPrograms([]);
      setIsFormSaving(false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [record, isOpen]);


   React.useEffect(() => {
      reset(record ?? { status: 'Mapped' });
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [record, isOpen]);
   
   const onSubmit = (data: IIntrinsiqPayerToAPYup): void => {
      setIsFormSaving(true);

      if (additionalPrograms?.length > 0 && additionalPrograms.some(y => y.error)) {
         console.log('--------------additionalPrograms.errors-------------------------')
         console.log(additionalPrograms.filter(y => y.error));
         console.log('--------------additionalPrograms.errors-------------------------')

         return;
      }

      //ts hoops to convert data with unknown props into assistance programs
      const item = data as unknown as IntrinsiqPayerToAP;

      const itemList = [item];
      if (additionalPrograms?.length > 0) {
         additionalPrograms.map((newProgram) => {

            const p = assistancePrograms.find(y => y.id === newProgram.assistanceProgramId);

            const newItem = {
               practiceId: item.practiceId,
               payeeName: item.payeeName,
               payeePCN: item.payeePCN,
               payeeBin: item.payeeBin,
               brandId: item.brandId,
               assistanceProgramId: newProgram.assistanceProgramId,
               programName: p?.programName,
               status: newProgram.status,
            } as IntrinsiqPayerToAP;

            itemList.push(newItem);
            return newItem;
         });
      }
      saveIntrinsiqPayerToAP(httpPost, itemList)
         .then(handleClose)
         .catch(err => console.error('Error calling saveIntrinsiqPayerToAP', err))
         .finally(() => setIsFormSaving(false));
   }


   const handleClose = () => {
      setIsFormSaving(false);
      removeErrors({ keysLike: _keysLike });
      setAdditionalPrograms([]);
      closeEditor();
   }

   if (isFormSaving && isOpen) {
      return <Spinner message='Saving IntrinsiqPayerToAP...' />
   }

   //console.log('--------------IntrinsiqPayerToAPEditor-------------------------')
   //console.log(errors)


   const html = (
      <Dialog
         scrollingContent={true}
         title={'IntrinsiqPayerToAP Editor'}
         open={isOpen}
         size="large"
         actionButtons={
            <>
               <CancelButton loading={isFormSaving} onClick={() => handleClose()} />
               <SaveButton
                  formId={__formId}
                  onClick={() => null}
                  disabled={Object.keys(errors).length > 0}
                  loading={isFormSaving} />
            </>
         }>
         <div style={dialogContentStyle}>

            {((errors && Object.keys(errors).length > 0) ||
               (additionalPrograms?.some(y => y.error))) && <>
                  <TitleError
                     text='Please correct the errors below' />
                  {console.log('--------------Form Errors: -------------------------')}
                  {console.log(errors)}
               </>
            }

            <ApiErrorDisplay
               title='Error saving IntrinsiqPayerToAP'
               keysLike={_keysLike}
            />
            
            <Form id={__formId} onFinish={handleSubmit(onSubmit)}>
               <Row gutter={[16, 16]}>
                  <Col>
                     <DropdownField
                        control={control}
                        name='practiceId'
                        label='Practice'
                        placeholder={record?.practiceId === undefined ? ' - none -' : `${record?.practiceId}`}
                        error={errors?.practiceId}
                        multiple={false}
                        options={practiceOptions}
                        setValue={setValue}
                        required={false}
                        search={true}
                        clearable={true}
                        disabled={true}
                     />
                  </Col>
               </Row>
               <Row gutter={[16, 16]}>
                  <Col span={8}>
                     <InputField
                        control={control}
                        name='payeeName'
                        label='payeeName'
                        type='text'
                        error={errors?.payeeName}
                        required={false}
                        disabled={true}
                     />
                  </Col>
                  <Col span={8}>
                     <InputField
                        control={control}
                        name='payeePCN'
                        label='payeePCN'
                        type='text'
                        error={errors?.payeePCN}
                        required={false}
                        disabled={true}
                     />
                  </Col>
                  <Col span={8}>
                     <InputField
                        control={control}
                        name='payeeBin'
                        label='payeeBin'
                        type='text'
                        error={errors?.payeeBin}
                        required={false}
                        disabled={true}
                     />
                  </Col>
               </Row>
               <Row gutter={[16, 16]}>
                  <Col>
                     <DropdownField
                        control={control}
                        name='brandId'
                        label='brandId'
                        placeholder={record?.brandId === undefined ? ' - none -' : `${record?.brandId}`}
                        error={errors?.brandId}
                        multiple={false}
                        options={brandOptions}
                        setValue={setValue}
                        required={false}
                        search={true}
                        clearable={true}
                        disabled={true}
                     />
                  </Col>
               </Row>
               <Row gutter={[16, 16]}>
                  <Col span={12}>
                     <DropdownField
                        control={control}
                        name='status'
                        label='Status'
                        error={errors?.status}
                        multiple={false}
                        options={xrefStatusOptions}
                        setValue={setValue}
                        required={true}
                        search={true}
                        onChange={(value) => {
                           trigger('assistanceProgramId');
                        }}
                     />
                  </Col>
                  <Col span={12}>
                     <DropdownField
                        control={control}
                        name='assistanceProgramId'
                        label='Assistance Program'
                        error={errors?.assistanceProgramId}
                        multiple={false}
                        options={assistanceProgramNameOptions}
                        setValue={setValue}
                        required={getValues()?.status === 'Mapped' ? true : false}
                        search={true}
                        clearable={true}
                        onChange={(value) => {
                           const program = assistancePrograms?.find(y => y.id === Number(value));
                           setValue('programName', program?.programName);
                           trigger('assistanceProgramId');
                        }}
                     />
                  </Col>
               </Row>
               <Divider>Additional XRef Records</Divider>

               {additionalPrograms?.length > 0 && additionalPrograms?.map((item, index) => {
                  return <Row gutter={[16, 16]} key={index} align="middle">
                     <Col span={1} />
                     <Col span={6}>
                        <BasicDropdownField
                           name='status'
                           value={additionalPrograms[index]?.status}
                           label='Status'
                           placeholder={' - Select a Practice -'}
                           multiple={false}
                           options={xrefStatusOptions}
                           required={true}
                           search={true}
                           disabled={false}
                           onChange={(value) => {
                              const programs = [...additionalPrograms];
                              programs[index].status = String(value);

                              if (programs[index]?.status === 'Mapped' && !programs[index]?.assistanceProgramId) {
                                 programs[index].error = 'Program is required when Mapped';
                              } else {
                                 programs[index].error = undefined;
                              }

                              setAdditionalPrograms(programs);
                           }}
                        />
                     </Col>
                     <Col span={8}>
                        <BasicDropdownField
                           name='assistanceProgramId'
                           value={additionalPrograms[index]?.assistanceProgramId}
                           label='Assistance Program'
                           multiple={false}
                           options={assistanceProgramNameOptions}
                           required={additionalPrograms[index]?.status === 'Mapped' ? true : false}
                           search={true}
                           clearable={true}
                           onChange={(value) => {
                              const programs = [...additionalPrograms];
                              programs[index].assistanceProgramId = Number(value);

                              if (programs[index]?.status === 'Mapped' && !programs[index]?.assistanceProgramId) {
                                 programs[index].error = 'Program is required when Mapped';
                              } else {
                                 programs[index].error = undefined;
                              }

                              setAdditionalPrograms(programs);
                           }}
                           error={additionalPrograms[index]?.error}
                        />
                     </Col>
                     <Col span={2}>
                        <ActionButton
                           danger={true}
                           icon={<CustomIcon type={CustomIconType.DeleteOutlined} style={{ color: colorWheel.red }} />}
                           onClick={() => {
                              const newArray = [...additionalPrograms];
                              newArray.splice(index, 1);
                              setAdditionalPrograms([...newArray]);
                           }}
                        />
                     </Col>
                     <Col span={2} />
                  </Row>
               })}
               <Row gutter={[16, 16]}>
                  <Col>
                     <AddButton                        
                        buttonText='New Program'
                        onClick={() => {
                           const newProgram = {
                              status: 'Mapped'
                           } as IAdditionalProgram;
                           setAdditionalPrograms([
                              ...additionalPrograms,
                              newProgram as IAdditionalProgram
                           ]);
                           if (_addNewRef?.current) {
                              setTimeout(() => _addNewRef.current.scrollIntoView({ block: "start", behavior: "smooth" }), 100);
                           }
                        }}
                     />
                  </Col>
               </Row>
               <div style={{ height: 0 }} ref={_addNewRef} />
               <Divider></Divider>
               {record?.id && record?.reviewedBy &&
                  <Row gutter={[16, 16]}>
                     <Col span={12}>
                        <BasicInputField
                           name='reviewedBy'
                           label='Reviewed By'
                           value={record.reviewedBy}
                           disabled={true}
                        />
                     </Col>
                     <Col span={12}>
                        <BasicInputField
                           name='reviewedOn'
                           label='Reviewed On'
                           value={formatDateTimeString(record.reviewedOn)}
                           disabled={true}
                        />
                     </Col>
                  </Row>
               }
            </Form>
         </div>

      </Dialog>
   )
   return html;
}

export default IntrinsiqPayerToApXRefEditor;

