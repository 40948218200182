import { numberComparer, stringComparer } from '../../functions/comparer.functions';
import { useFetchManufacturer } from '../program/ManufacturerFetcher';
import { useFetchBrand } from './BrandFetcher';
import { useFetchDrugRoute } from './DrugRouteFetcher';
import { useFetchGenericDrug } from './GenericDrugFetcher';
import { useMemo } from 'react';
import { HttpError, TPost, TDelete, TGet } from '../../functions/httpClient';
import { IOptionItem } from '../../functions/option.functions';
import { IStatus } from '../models';
import useSWR, { mutate } from 'swr';
import { Drug, DrugViewModel } from './DrugModel';

export const drugBaseUrl = `api/drug`;
export const drugSaveUrl = `${drugBaseUrl}/Save`;
export const drugDeleteUrl = (id: number) => `${drugBaseUrl}/Delete/${id}`;

const swrOptions = {
   refreshInterval: 0,
   revalidateOnFocus: false,
   dedupingInterval: 60000
};


export function useFetchDrug(httpGet: TGet): IStatus & { drugs: Drug[] } {
   const { data, isLoading, error } = useSWR<Drug[], HttpError>(
      drugBaseUrl,
      httpGet,
      { ...swrOptions }
   );

   return {
      drugs: data,
      isLoading: isLoading,
      error: error?.message
   };
}

export const saveDrug = async (httpPost: TPost, drug: Drug) => {
   const result = await httpPost(drugSaveUrl, drug)
      .then(() => mutate(drugBaseUrl));
   return result;
}

export const deleteDrug = async (httpDelete: TDelete, id: number) => {
   const result = await httpDelete(drugDeleteUrl(id))
      .then(() => mutate(drugBaseUrl));
   return result;
}

export function useDrugViewModel(httpGet: TGet): IStatus & { drugViewModelList: DrugViewModel[] } {
   const { drugs, isLoading, error, isSaving } = useFetchDrug(httpGet);
   const { manufacturers } = useFetchManufacturer(httpGet);
   const { brands } = useFetchBrand(httpGet);
   const { drugRoutes } = useFetchDrugRoute(httpGet);
   const { genericDrugs } = useFetchGenericDrug(httpGet);

   const viewModel = useMemo(() => {
      if (drugs?.length > 0 &&
         manufacturers?.length > 0 &&
         brands?.length > 0 &&
         drugRoutes?.length > 0 &&
         genericDrugs?.length > 0) {
         const tempList = drugs.map((drug): DrugViewModel => {
            const m = manufacturers.find(y => y.id === drug.manufacturerId);
            const g = genericDrugs.find(y => y.id === drug.genericId);
            const b = brands.find(y => y.id === drug.brandId);
            const r = drugRoutes.find(y => y.id === drug.route);

            return {
               ...drug,
               manufacturerName: m?.manufacturerName,
               genericName: g?.genericName,
               brandName: b?.brandName,
               routeDescription: r?.routeDescription
            } as DrugViewModel
         });
         return tempList;
      }
      return [];
   }, [drugs, manufacturers, brands, drugRoutes, genericDrugs]);

   return {
      drugViewModelList: viewModel,
      isLoading: isLoading || !viewModel || viewModel.length === 0,
      error: error,
      isSaving
   };
}

export function useDrugOptions(httpGet: TGet): { drugOptions: IOptionItem[] } {
   const { drugs } = useFetchDrug(httpGet);

   const drugOptions = useMemo(() => {
      if (drugs?.length > 0) {

         const distinctItems = [...new Set(drugs.map(d => ({ id: d.id, drugName: d.drugName })))];

         const options = distinctItems.map((d): IOptionItem => ({
            key: d.id,
            label: `[${d.id}]: ${d.drugName}`,
            value: d.id,
         } as IOptionItem));
         options.sort((a: IOptionItem, b: IOptionItem) => numberComparer(a.value as number, b.value as number));
         options.sort((a: IOptionItem, b: IOptionItem) => stringComparer(a.label as string, b.label as string));
         return options;
      }
      return [];
   }, [drugs]);

   return {
      drugOptions
   };
}
