import * as React from 'react';
import './Card.css';
import { Popover, Row, Col } from 'antd';
import colorWheel from '../Theme/ColorWheel';
import CustomIcon, { CustomIconType } from '../components/shared/AntComponents/CustomIcon'

interface IProps {
   /** @prop stuff  */
   title: string;
   children?: React.ReactNode;
   closeFunc?: () => void;
   headingCss?: string;
   subText?: string;
   infoText?: React.ReactElement;
}
/**
 * Creates a card with a header and a footer with the child being a route to a component
 * @param title - message to display in the title of the card
 * @param children - component to display within the card
 * @param subText
 * @param infoText - display "i" info icon and component to display in the popup when cursor hovers over "i" icon
 * @param closeFunc - to be called when page is closed
 * @param headingCss
 * @returns 
 */
export default (props: IProps): React.ReactElement<IProps> => (
   <div className="md-card">
      <div className={`md-card-toolbar ${props.headingCss ? props.headingCss : ''}`}>
         <div className="md-card-toolbar-heading-text " title={props.title}>
            <h3>{props.title}</h3>
         </div>
         {props.subText &&
            <div className="md-card-toolbar-heading-subtext">
               <h4>{props.subText}</h4>
            </div>
         }
         {props.infoText &&
            <Row>
               <Col style={{ paddingLeft: 10, paddingTop: 5 }}>
                  <Popover content={props.infoText}
                     title={<Row>
                        <Col span={1}>
                           <CustomIcon type={CustomIconType.InfoCircleFilled} style={{
                              fontWeight: 'bold',
                              fontSize: '1.5em',
                              color: colorWheel.skyBlue,
                              borderRadius: 25
                           }} />
                        </Col>
                        <Col span={23}>
                           {props.title}
                        </Col>
                     </Row>}
                     style={{ maxWidth: 200 }}
                     overlayStyle={{
                        width: "30vw"
                     }}
                  ><CustomIcon type={CustomIconType.InfoCircleFilled} style={{
                     color: colorWheel.white,
                     backgroundColor: colorWheel.skyBlue,
                     borderRadius: 25
                  }} />
                  </Popover>
               </Col>
            </Row>
         }
         {props.closeFunc &&
            <h3><span onClick={props.closeFunc} className="close-button">&times;</span></h3>
         }
      </div>
      <div className="tab-content">
         <div className="user-content">
            {props.children}
         </div>
      </div>
   </div>
);
