import useSWR, { mutate } from 'swr';
import { useMemo } from 'react';
import { HttpError, TPost, TGet } from '../../functions/httpClient';
import { EmailMessage, FundStatusAlert, FundStatusAlertFundEmailRequest } from './FundStatusAlertModel';
import { getFormattedDateForApi } from '../../functions/format.functions';
import { fundStatusFieldFindUrl } from './FundStatusFieldFetcher';
import { getRecentlySentEmailUrl } from './RecentlySentFetcher';

export const fundStatusEmailBaseUrl = `/api/fsa/FundStatusEmail`;
const getFSAEmailHtmlUrl = `${fundStatusEmailBaseUrl}/GetFSAEmailHtml`;
const getFSAEmailRetryHtmlUrl = (uId: string) => `${fundStatusEmailBaseUrl}/GetFSAResendEmailHtml/${uId}`;
export const sendFSAEmailUrl = `${fundStatusEmailBaseUrl}/EnqueueFSAEmail`;

const swrOptions = {
   refreshInterval: 0,
   revalidateOnFocus: false,
   dedupingInterval: 60000     //default 2000
};

export const useGetFSAEmail = (httpPost: TPost, request: FundStatusAlert[]) => {
   const params = useMemo(() => {
      return request
   }, [request]);

   const fetcher = () => httpPost<EmailMessage>(getFSAEmailHtmlUrl, params);

   const { data, isLoading, error } = useSWR<EmailMessage, HttpError>(
      [getFSAEmailHtmlUrl, params],
      fetcher,
      swrOptions
   );

   return {
      emailMessage: data,
      isLoading: isLoading,
      error: error as HttpError
   };
}

export const sendFSAEmail = async (httpPost: TPost,
   request: FundStatusAlertFundEmailRequest,
   fieldChangeFromDate: Date) => {
   await httpPost(sendFSAEmailUrl, request);
   mutate([fundStatusFieldFindUrl, { fromDate: getFormattedDateForApi(fieldChangeFromDate) }]);
   mutate(getRecentlySentEmailUrl);
}

export const useGetFSAEmailRetry = (httpGet: TGet, emailUid: string) => {
   const { data, isLoading, error } = useSWR<EmailMessage, HttpError>(
      getFSAEmailRetryHtmlUrl(emailUid),
      httpGet,
      swrOptions
   );

   return {
      emailMessage: data,
      isLoading: isLoading,
      error: error as HttpError
   };
}

export const sendFSAEmailRetry = async (httpPost: TPost,
   request: FundStatusAlertFundEmailRequest) => {
   await httpPost(sendFSAEmailUrl, request);
   mutate(sendFSAEmailUrl);
   mutate(getRecentlySentEmailUrl);
}
