import * as React from 'react';
import { mutate } from 'swr';
import { useUserContext } from '../../auth/authContext';
import { isInRole } from '../../functions/auth.functions';
import { addDate, deepEqual } from '../../functions/common.functions';
import { getSessionItem, setSessionItem } from '../../functions/storage.functions';
import { useApiContext } from '../../store/ApiContext';
import { findCohortSummaryUrl, useFetchCurrentCohortSummary } from '../../store/cohort/CohortSummaryFetcher';
import { CohortSummary } from '../../store/cohort/CohortSummaryModel';
import { KnownSettings } from '../../store/SettingsModel';
import { IFormError } from "../../functions/form.contracts";

export interface ISortSelection {
   sortName: string;
   sortOrder: 'ascend' | 'descend';
}

interface ISummaryFilterAndSortOptions {
   cohortStatusTypeId: number;
   partnerFilter: string;
   diseaseOrBrandFilter: string;
   assistanceProgramFilter: string;
   assistanceServiceFilter: string;
   columnSortSelection: ISortSelection;
}

interface ICohortContextProps {
   cohortId: number;
   setCohortId: (cohortId: number) => void;
   cardTitle: string;
   setCardTitle: (title: string) => void;
   cardSubText: string;
   setCardSubText: (subText: string) => void;
   selectedProgramSummaryDetail: ISelectedProgramSummaryDetail,
   setSelectedProgramSummaryDetail: (assistanceProgramId: number, assistanceServiceId?: number, diseaseOrBrandId?: number) => void;
   selectedCohortItemStatusIds: number[];
   setSelectedCohortItemStatusIds: (ids: number[]) => void;

   currentCohort: CohortSummary;
   summaryFilterAndSortOptions: ISummaryFilterAndSortOptions;
   setSummaryFilterAndSortOptions: (options: ISummaryFilterAndSortOptions) => void;
   cohortSummaryFromDate: Date;
   setCohortSummaryFromDate: (fromDate: Date) => void;

   formErrors: IFormError[],
   setFormErrors: (errors: IFormError[]) => void;
}

export interface ISelectedProgramSummaryDetail {
   assistanceProgramId: number;
   assistanceServiceId?: number;
   diseaseOrBrandId?: number;
}

const defaultCohortContextProps: ICohortContextProps = {
   cohortId: undefined,
   setCohortId: undefined,
   cardTitle: undefined,
   setCardTitle: undefined,
   cardSubText: undefined,
   setCardSubText: undefined,
   setSelectedProgramSummaryDetail: undefined,
   selectedProgramSummaryDetail: { assistanceProgramId: undefined },
   cohortSummaryFromDate: undefined,
   setCohortSummaryFromDate: undefined,
   summaryFilterAndSortOptions: undefined,
   setSummaryFilterAndSortOptions: undefined,
   currentCohort: undefined,
   selectedCohortItemStatusIds: undefined,
   setSelectedCohortItemStatusIds: undefined,
   formErrors: [],
   setFormErrors: undefined
}

export const CohortContext = React.createContext<ICohortContextProps>(defaultCohortContextProps);
export const useCohortContext = () => React.useContext(CohortContext);

interface ICohortContextProviderProps {
   children: React.ReactNode;
}

//do we need this still?
enum SessionKeysEnum {
   SELECTEDPROGRAMSUMMARYDETAIL = 'cohort-selectedProgramSummaryDetail',
   SELECTEDCOHORTITEMSTATUSIDS = 'cohort-selectedCohortItemStatusIds',
}

const cohortSummarySeedDate = addDate(new Date(), -180);

export const CohortContextProvider: React.FC<ICohortContextProviderProps> = (props) => {
   const [cohortSummaryFromDateParam, setCohortSummaryFromDateParam] = React.useState<Date>(cohortSummarySeedDate);
   const [cohortId, setCohortId] = React.useState<number>(undefined);
   const { userRoles } = useUserContext();
   const { httpPost } = useApiContext();
   const { currentCohort } = useFetchCurrentCohortSummary(httpPost, cohortSummaryFromDateParam, cohortId);

   const [cardTitle, setCardTitle] = React.useState<string>(undefined);
   const [cardSubText, setCardSubText] = React.useState<string>(undefined);
   const [selectedCohortItemStatusIds, setSelectedCohortItemStatusIds] = React.useState<number[]>([]);
   const [selectedProgramSummaryDetail, _setSelectedProgramSummaryDetail] =
      React.useState<ISelectedProgramSummaryDetail>(getSessionItem(SessionKeysEnum.SELECTEDPROGRAMSUMMARYDETAIL) ?? { assistanceProgramId: undefined });
   const [summaryFilterAndSortOptions, setSummaryFilterAndSortOptions] =
      React.useState<ISummaryFilterAndSortOptions>(currentCohort ?
         getSessionItem(`cohort-${currentCohort.cohortTypeId}-summaryFilterAndSortOptions`) : undefined);

   const [formErrors, setFormErrors] = React.useState<IFormError[]>([]);

   const setCohortSummaryFromDate = (fromDate: Date) => {
      setCohortSummaryFromDateParam(fromDate);
      mutate(findCohortSummaryUrl);
   }

   const setSelectedProgramSummaryDetail = (assistanceProgramId: number, assistanceServiceId?: number, diseaseOrBrandId?: number) => {
      _setSelectedProgramSummaryDetail(
         {
            assistanceProgramId: assistanceProgramId,
            assistanceServiceId: assistanceServiceId,
            diseaseOrBrandId: diseaseOrBrandId
         });
   }
   
   // wrapper function used for react-y reason
   const setErrorState = (errors: IFormError[]) => {
      setFormErrors(errors);
   }

   React.useEffect(() => {
      //keep these values in storage in case a user refreshes the program summary detail page.
      setSessionItem(SessionKeysEnum.SELECTEDPROGRAMSUMMARYDETAIL, selectedProgramSummaryDetail);
      setSessionItem(SessionKeysEnum.SELECTEDCOHORTITEMSTATUSIDS, selectedCohortItemStatusIds);
   }, [selectedProgramSummaryDetail, selectedCohortItemStatusIds]);

   React.useEffect(() => {
      setSelectedCohortItemStatusIds(userRoles && isInRole(userRoles, [KnownSettings.CohortAdmin]) ? [1, 2, 3] : [1]);
      setSelectedProgramSummaryDetail(getSessionItem(SessionKeysEnum.SELECTEDPROGRAMSUMMARYDETAIL));
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   React.useEffect(() => {
      if (currentCohort) {
         // check for undefined to avoid clearing out existing selections
         if (summaryFilterAndSortOptions !== undefined && currentCohort.cohortTypeId === summaryFilterAndSortOptions.cohortStatusTypeId) {
            setSessionItem(`cohort-${currentCohort.cohortTypeId}-summaryFilterAndSortOptions`, summaryFilterAndSortOptions);
         }

         // pull in filter & sort selections from session or reset because it's a diffrent cohort type
         const sessionStoredOptions = getSessionItem(`cohort-${currentCohort.cohortTypeId}-summaryFilterAndSortOptions`) as ISummaryFilterAndSortOptions;
         const sessionEqualsLocal = deepEqual(summaryFilterAndSortOptions, sessionStoredOptions);

         if (sessionStoredOptions && currentCohort.cohortTypeId === sessionStoredOptions.cohortStatusTypeId && !sessionEqualsLocal)
            setSummaryFilterAndSortOptions(sessionStoredOptions);
         else if (!sessionEqualsLocal)
            setSummaryFilterAndSortOptions(undefined);
      }
   }, [currentCohort, summaryFilterAndSortOptions]);

   React.useEffect(() => {
      setSelectedCohortItemStatusIds(userRoles && isInRole(userRoles, [KnownSettings.CohortAdmin]) ? [1, 2, 3] : [1]);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   return (
      <CohortContext.Provider
         value={{
            ...defaultCohortContextProps,
            cohortId,
            setCohortId,
            cardTitle,
            setCardTitle,
            cardSubText,
            setCardSubText,
            setSelectedProgramSummaryDetail,
            selectedProgramSummaryDetail,
            cohortSummaryFromDate: cohortSummaryFromDateParam,
            setCohortSummaryFromDate,
            currentCohort,
            summaryFilterAndSortOptions,
            setSummaryFilterAndSortOptions,
            selectedCohortItemStatusIds,
            setSelectedCohortItemStatusIds,
            setFormErrors: setErrorState,
            formErrors
         }}
      >
         {props.children}
      </CohortContext.Provider>
   );
};
