import * as React from 'react';
import { colorWheel } from '../../Theme/ColorWheel';
import { FieldError } from 'react-hook-form';
import { List } from 'antd';


interface IErrorProps {
   error: FieldError[] | FieldError | undefined;
}

export const ErrorDisplay: React.FC<IErrorProps> = (props) => {
   const { error } = props;
   const errArr: FieldError[] = error && Array.isArray(error) ? error as FieldError[] : (error ? [error as FieldError] : []);

   if (errArr.length === 0) return null;

   return <List key='error_lst'>
      {errArr.map((err: FieldError) => <List.Item key={`error_${err.type}`}><div style={{ color: colorWheel.red, fontSize: 12 }}>{err.message}</div></List.Item>)}
   </List>
}
