import { useEffect, useMemo, useState } from 'react';
import useSWR from 'swr';
import { HttpError, TGet, TPost, TPut } from '../../functions/httpClient';
import { createKey, mutateSearch, swrOptions } from '../../functions/swr.functions';
import { getEndOfDayAsDate, getStartOfDayAsDate } from '../../functions/time.functions';
import { IStatus, PagedList } from '../models';
import { FaxLogReview, FaxLogSearchFilter, FaxLogViewModel } from './FaxLogModels';

export const faxLogBaseUrl = `api/FaxLog`;
export const searchFaxesUrl = `${faxLogBaseUrl}/Search`;
const getStatusValuesUrl = `${faxLogBaseUrl}/GetStatusValues`;
const getResultValuesUrl = `${faxLogBaseUrl}/GetResultValues`;
export const saveFaxLogReviewUrl = `${faxLogBaseUrl}/SaveFaxLogReview`;

//keeping last key to trigger revalidate 
export let _lastFetchKey_FaxLog: string;

export function useFaxLogSearch(httpPost: TPost, search: FaxLogSearchFilter): IStatus & { pagedResult: PagedList<FaxLogViewModel>, refreshDate: Date } {
   const [refreshCheck, setRefeshCheck] = useState<Date>(new Date());
   const params = useMemo(() => {
      const utcSearch: FaxLogSearchFilter = {
         ...search,
         fromSentDate: search.fromSentDate ? getStartOfDayAsDate(search.fromSentDate) : undefined,
         toSentDate: search.toSentDate ? getEndOfDayAsDate(search.toSentDate) : undefined,
         lastStatusUpdate: search.lastStatusUpdate ? getStartOfDayAsDate(search.lastStatusUpdate) : undefined,
      }

      return utcSearch
   }, [search]);

   _lastFetchKey_FaxLog = createKey(faxLogBaseUrl, params);

   const fetcher = () => httpPost<PagedList<FaxLogViewModel>>(searchFaxesUrl, params);

   const { data, isLoading, isValidating, error } = useSWR<PagedList<FaxLogViewModel>, HttpError>(
      _lastFetchKey_FaxLog,
      fetcher,
      {
         ...swrOptions,
         refreshInterval: 1000 * 60 * 15,
         dedupingInterval: 0
      }
   );

   useEffect(() => {
      setRefeshCheck(new Date());
   }, [isValidating]);

   return {
      pagedResult: data,
      isLoading: isLoading,
      error: error?.message,
      refreshDate: refreshCheck
   };
}

export const saveFaxLogReview = async (httpPut: TPut, viewModel: FaxLogReview) => {
   const data: any = await httpPut(saveFaxLogReviewUrl, viewModel)
      .then(() => mutateSearch(searchFaxesUrl, _lastFetchKey_FaxLog));
   return data;
}

export const useFetchStatusValues = (httpGet: TGet) => {

   const { data, isLoading, error } = useSWR<string[], HttpError>(
      getStatusValuesUrl,
      httpGet,
      { ...swrOptions }
   );

   return {
      faxStatusList: data,
      isLoading: isLoading,
      error: error
   };
}


export const useFetchResultValues = (httpGet: TGet) => {

   const { data, isLoading, error } = useSWR<string[], HttpError>(
      getResultValuesUrl,
      httpGet,
      { ...swrOptions }
   );

   return {
      faxResultList: data,
      isLoading: isLoading,
      error: error
   };
}