import * as React from 'react';
import Dialog from '../Dialog';
import { CancelButton, SaveButton } from "../shared/Buttons";
import { RingCallQueue } from "../../store/practice/RingCallQueueModel";
import * as yup from "yup";
import { ringCallQueueBaseUrl, saveCallQueue } from "../../store/practice/RingCallQueueFetcher";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Col, Form, Row } from "antd";
import ApiErrorDisplay from "../ApiErrorDisplay";
import { CheckboxField, DropdownField, InputField } from "../shared/InputLibrary";
import { TitleError } from "../shared/AntComponents/Typography/Title";
import { useApiContext } from "../../store/ApiContext";
import { RingCallQueueViewModel } from "../../store/practice/RingCallQueueViewModel";
import { useFetchAdparoPractices, usePracticeOptions } from "../../store/practice/PracticeFetcher";
import { Practice } from "../../store/practice/PracticeModel";
import { BasicFieldWrapper } from "../shared/BasicInputLibrary";
import { ColumnFilterItem } from "antd/lib/table/interface";
import { validatePhoneOrFax } from '../../functions/form.validators';

interface IProps {
   isNew: boolean;
   isOpen: boolean;
   closeEditor: () => void;
   callQueue: RingCallQueue;
   inUsePracticeIds: ColumnFilterItem[];
   userMainId: number;
}

const yupRingCallQueueEditorSchema = yup.object({
   id: yup.number().notRequired(),
   practiceId: yup.number().required(),
   externalAlias: yup.string()
      .max(50)
      .required('External Alias is required'),
   phone: yup.string()
      .required('Phone is required')
      .test('phone', 'Invalid phone number, must be 10 numeric digits, and not start with 1 or 0.',
         (p) => validatePhoneOrFax(p, true)),
   voicemailPin: yup.string()
      .max(10)
      .required('Voicemail Pin is required'),
   fax: yup.string()
      .required('Fax is required')
      .test('fax', 'Invalid fax number, must be 10 numeric digits, and not start with 1 or 0.',
         (f) => validatePhoneOrFax(f, true)),
   isDeleted: yup.boolean().notRequired()
});

interface IRingCallQueueEditorSchema extends yup.Asserts<typeof yupRingCallQueueEditorSchema> { }

const _formId = 'frmRingCallQueue';
const _keysLike: string[] = [ringCallQueueBaseUrl];

const RingCallQueueEditor: React.FC<IProps> = (props) => {
   const { httpGet, httpPost } = useApiContext();
   const { practiceOptions } = usePracticeOptions(httpGet);
   const { adparoPractices } = useFetchAdparoPractices(httpGet);
   const { isNew, isOpen, closeEditor, callQueue, userMainId, inUsePracticeIds } = props;
   const [isFormSaving, setIsFormSaving] = React.useState<boolean>(false);
   const [selectedPractice, setSelectedPractice] = React.useState<Practice>(undefined);

   const {
      control,
      handleSubmit,
      formState: { errors },
      reset
   } = useForm<IRingCallQueueEditorSchema>({
      resolver: yupResolver<yup.AnyObject>(yupRingCallQueueEditorSchema),
      shouldFocusError: true
   });

   const filteredPracticeOptions = React.useMemo(() => {
      // filter on active only
      const activePracticeIds = inUsePracticeIds?.map(p => p.value as number);
      const activeOptions = practiceOptions?.filter(f =>
         !activePracticeIds.includes(f.value as number));
      // filter on adparo only
      return activeOptions?.filter((option) =>
         adparoPractices?.some((s) => s.id === option.value));
   }, [callQueue, practiceOptions, adparoPractices]);

   React.useEffect(() => {
      setSelectedPractice(adparoPractices?.find(p => p.id === callQueue?.practiceId));
   }, [callQueue, adparoPractices]);

   React.useEffect(() => {
      reset(callQueue);
   }, [callQueue]);

   const handleClose = () => {
      reset();
      setIsFormSaving(false);
      closeEditor();
   };

   const handlePracticeChange = (value: any) => {
      const practice = value as number;
      const practiceObject = adparoPractices?.find(f => f.id === practice);
      setSelectedPractice(practiceObject);
   }

   const onSubmit = async (data: IRingCallQueueEditorSchema) => {
      const item = data as unknown as RingCallQueue;
      // apply non-editable practice fields
      item.practiceName = selectedPractice?.name ?? '';
      item.managedTenantId = selectedPractice?.managedTenantId ?? '';

      setIsFormSaving(true);
      const viewModel: RingCallQueueViewModel = {
         callQueue: item,
         userMainId: userMainId
      };

      await saveCallQueue(httpPost, viewModel)
         .then(() => closeEditor())
         .catch((err) => {
            setIsFormSaving(false);
            console.error(err);
         });
   }

   if (!adparoPractices)
      return;

   return (
      <Dialog
         scrollingContent={true}
         title={isNew ? 'Ring Call Queue New' : 'Ring Call Queue Editor'}
         open={isOpen}
         size='small'
         actionButtons={
            <>
               <CancelButton
                  buttonText='Cancel'
                  onClick={handleClose}
                  disabled={isFormSaving}
                  loading={isFormSaving} />
               <SaveButton
                  formId={_formId}
                  onClick={() => { }}
                  disabled={isFormSaving}
                  loading={isFormSaving} />
            </>
         }>

         {errors && Object.keys(errors).length > 0 &&
            <>
               <TitleError text='Please correct the errors below' />
               {console.log('--------------Form Errors: -------------------------')}
               {console.log(errors)}
            </>
         }

         <ApiErrorDisplay
            title='Error saving Ring Call Queue'
            keysLike={_keysLike}
         />

         <Form
            id={_formId}
            onFinish={handleSubmit(onSubmit)}>
            <Row gutter={[32, 32]} justify="space-between" align="top">
               <Col span={12}>
                  <DropdownField
                     control={control}
                     search={true}
                     options={filteredPracticeOptions}
                     onChange={handlePracticeChange}
                     name='practiceId'
                     label='Practice Id'
                     error={errors?.practiceId}
                     required={true}
                  />
               </Col>
               <Col span={12}>
                  <BasicFieldWrapper
                     label='Managed Tenant Id'
                     field={selectedPractice?.managedTenantId ?? 'N/A'}
                  />
               </Col>
            </Row>
            <Row gutter={[32, 32]} justify="space-between" align="top">
               <Col span={12}>
                  <InputField
                     control={control}
                     style={{ order: 1 }}
                     name='externalAlias'
                     label='External Alias'
                     type='text'
                     error={errors?.externalAlias}
                     required={true}
                  />
               </Col>
               <Col span={12}>
                  <InputField
                     control={control}
                     style={{ order: 2 }}
                     name='phone'
                     label='Phone'
                     type='text'
                     error={errors?.phone}
                     required={true}
                  />
               </Col>
            </Row>
            <Row gutter={[32, 32]} justify="space-between" align="top">
               <Col span={12}>
                  <InputField
                     control={control}
                     style={{ order: 3 }}
                     name='voicemailPin'
                     label='Voicemail Pin'
                     type='text'
                     error={errors?.voicemailPin}
                     required={true}
                  />
               </Col>
               <Col span={12}>
                  <InputField
                     control={control}
                     style={{ order: 4 }}
                     name='fax'
                     label='Fax'
                     type='text'
                     error={errors?.fax}
                     required={true}
                  />
               </Col>
            </Row>
            <Row gutter={[32, 32]} justify="space-between" align="top">
               <Col span={12}>
                  <CheckboxField
                     control={control}
                     name='isDeleted'
                     label='Inactive'
                     error={errors?.isDeleted}
                     required={false}
                     disabled={isNew}
                  />
               </Col>
            </Row>
         </Form>
      </Dialog>
   );
}

export default RingCallQueueEditor;