import * as React from 'react';
import {MainItemType} from "../../store/menu/MainMenu";
import {CategoryType, ISubmenu, ISubmenuCategory, ISubmenuCategoryItem, SubMenus} from '../../store/menu/SubMenu';
import {getWorkspaceSet} from '../../store/report/ReportFunctions';
import {PowerBIReport} from '../../store/report/ReportModel';
import {KnownSettings} from '../../store/SettingsModel';
import {CustomIconType} from "../../components/shared/AntComponents/CustomIcon";

interface IFlyoutMenuContextProps {
   flyoutMenus: ISubmenu[],
   loadReportFlyoutMenu: (reportMenu: PowerBIReport[]) => void;
}

export const FlyoutMenuContext = React.createContext<IFlyoutMenuContextProps>({} as IFlyoutMenuContextProps);
export const useFlyoutMenu = () => React.useContext(FlyoutMenuContext);

interface IFlyoutMenuContextProviderProps {
   children: React.ReactNode;
}

export const FlyoutMenuContextProvider: React.FC<IFlyoutMenuContextProviderProps> = (props) => {
   const [subMenus, setSubMenus] = React.useState<ISubmenu[]>(SubMenus);

   const loadReports = (reportMenu: PowerBIReport[]) => {
      const subMenu: ISubmenu[] = [...SubMenus];
      const reports: PowerBIReport[] = reportMenu;

      if (reports && reports.length > 0) {        
         const reportSubMenu = getAnalyticsSubMenu(reports)
         subMenu.push(reportSubMenu);

         const powerBISubItems = getPowerBIContentToolSubMenu(reports);
         if (powerBISubItems?.length > 0) {
            let isPowerBISubDone = false;
            for (let subMenuIdx = 0; subMenuIdx < subMenu.length; subMenuIdx++) {              

               for (let catIdx = 0; catIdx < subMenu[subMenuIdx].categories.length; catIdx++) {
                  if (subMenu[subMenuIdx].categories[catIdx].categoryType === CategoryType.ContentAdminPowerBI) {
                     subMenu[subMenuIdx].categories[catIdx].items = powerBISubItems;
                     break;
                  }
               }
               if (isPowerBISubDone)
                  break;
            }
         }


      }

      setSubMenus(subMenu);
   }

   const getAnalyticsSubMenu = (reports: PowerBIReport[]): ISubmenu => {
      const reportCategories: ISubmenuCategory[] = [];
      let archiveItem;

      const section: string[] = [...new Set(reports.map(item => item.reportPageGroup))];
      section.forEach((group: string) => {
         const reportItems: PowerBIReport[] = reports.filter(y => y.reportPageGroup === group);
         const items: ISubmenuCategoryItem[] = [];

         reportItems.forEach((y: PowerBIReport) => {
            const report: ISubmenuCategoryItem = {
               label: y.reportName,
               allowedRoles: [KnownSettings.ReportViewer, KnownSettings.OmegaManager],
               hoverText: y.reportId,
               route: `/report/${y.workspaceId}/${y.reportId}`,
            };
            items.push(report);
         });

         if (items.length > 0) {
            const reportCategory: ISubmenuCategory = {
               label: group,
               labelIconName: CustomIconType.BarChartOutlined,
               allowedRoles: [KnownSettings.ReportViewer, KnownSettings.OmegaManager],
               categoryType: CategoryType.ContentAdminAnalytics,
               items: items
            };

            // store ~archive item separately to add in last per AH-7810
            if (reportCategory.label === '~ARCHIVE')
               archiveItem = reportCategory;
            else
               reportCategories.push(reportCategory);
         }

      });

      // move ~ARCHIVE to last position per AH-7810
      if (archiveItem)
         reportCategories.push(archiveItem);

      const reportSubMenu: ISubmenu = {
         categories: reportCategories,
         itemType: MainItemType.Analytics
      }
      return reportSubMenu;
   }

   const getPowerBIContentToolSubMenu = (reports: PowerBIReport[]): ISubmenuCategoryItem[] => {

      //get workspaces as ISubmenuCategoryItem
      const items: ISubmenuCategoryItem[] = [];

      //get case insenstive list of distict workspaces
      const workspaceSet = getWorkspaceSet(reports);

      workspaceSet.map(workspace => {        
         const ws: ISubmenuCategoryItem = {
            label: workspace.value,
            allowedRoles: [KnownSettings.BIReportManager],
            hoverText: workspace.value,
            route: `/content-tool/REPORT_MANAGEMENT/?ws=${encodeURI(workspace.key)}`,
         };
         items.push(ws);

      });

      return items;
   }
   //console.log('SubMenuContext:just above the return'); // Being used to investigate our renders in our app
   return (
      <FlyoutMenuContext.Provider
         value={{
            flyoutMenus: subMenus,
            loadReportFlyoutMenu: loadReports
         }}
      >
         {props.children}
      </FlyoutMenuContext.Provider>
   )
}