import * as React from 'react';
import { HttpError } from '../functions/httpClient';
import GlobalErrorReducer, { GlobalErrorActionTypes, GlobalErrorState } from './GlobalErrorReducer';

export interface IApiErrorMessage {
   key: string;
   error: HttpError;
   verb: HttpVerb;
}

interface IErrorKeys {
   keys?: string[], keysLike?: string[], keysWithVerb?: KeyWithVerb[]
}

export interface KeyWithVerb {
   key: string;
   verb: HttpVerb
}

export enum HttpVerb {
   GET,
   POST,
   PUT,
   DELETE,
   PATCH
}

interface IErrorContextProps {
   addError: (key: string, error: HttpError, verb: HttpVerb) => void;
   removeError: (key: string) => void;
   removeErrors: (errorKeys: IErrorKeys) => void;

   apiErrors: IApiErrorMessage[];
}

interface IErrorContextProviderProps {
   children: React.ReactNode;
}

export const ErrorContext = React.createContext<IErrorContextProps>({} as IErrorContextProps);
export const useErrorContext = () => React.useContext(ErrorContext);

const initialGlobalErrorState: GlobalErrorState = { errors: [] };

export const ErrorContextProvider: React.FC<IErrorContextProviderProps> = (props) => {

   const [globalErrorState, globalErrorDispatch] = React.useReducer(GlobalErrorReducer, initialGlobalErrorState);

   const addGlobalError = (key: string, error: HttpError, verb: HttpVerb) => {
      globalErrorDispatch({
         type: GlobalErrorActionTypes.ADD_ERROR,
         payload: { key: key.toLowerCase().trim(), error: error, verb: verb }
      });
   }

   const removeGlobalError = (key: string, verb?: HttpVerb) => {
      globalErrorDispatch({
         type: GlobalErrorActionTypes.REMOVE_ERROR,
         payload: { key: key.toLowerCase().trim(), error: undefined, verb: verb }
      });
   }
  
   const removeErrors = (errorKeys: IErrorKeys) => {
      globalErrorDispatch({
         type: GlobalErrorActionTypes.BULK_REMOVE_ERROR,
         payload: {
            key: undefined,
            error: undefined,
            keys: errorKeys.keys?.map(y => y.toLowerCase().trim()),
            keysLike: errorKeys.keysLike?.map(y => y.toLowerCase().trim()),
            keysWithVerb: errorKeys.keysWithVerb,
            verb: undefined // its "required" but I don't think we can have this OR use it... 
         }
      });
   }

   return <ErrorContext.Provider
      value={{
         addError: addGlobalError,
         removeError: removeGlobalError,
         removeErrors,
         apiErrors: globalErrorState?.errors ?? []
      }}>
      {props.children}
   </ErrorContext.Provider>;
}
