import { IOptionItem } from "../../functions/option.functions";

export const ENROLLMENT_BENEFIT_TYPE_ID = 1;
export const CALENDAR_BENEFIT_TYPE_ID = 2;

export interface BenefitPeriodType {
   id: number;
   benefitPeriodDescription: string;
}

export const dayOptions = (): IOptionItem[] => {
   let day: number = 1;
   const options: IOptionItem[] = [];
   do {
      options.push({
         label: day.toString(),
         value: day,
         key: day
      } as IOptionItem);
      day++;
   } while (day < 32);
   return options;
}

export const monthOptions: IOptionItem[] = [
   {
      value: 1,
      label: 'January',
      key: 1
   } as IOptionItem,
   {
      value: 2,
      label: 'February',
      key: 2
   } as IOptionItem,
   {
      value: 3,
      label: 'March',
      key: 3
   } as IOptionItem,
   {
      value: 4,
      label: 'April',
      key: 4
   } as IOptionItem,
   {
      value: 5,
      label: 'May',
      key: 5
   } as IOptionItem,
   {
      value: 6,
      label: 'June',
      key: 6
   } as IOptionItem,
   {
      value: 7,
      label: 'July',
      key: 7
   } as IOptionItem,
   {
      value: 8,
      label: 'August',
      key: 8
   } as IOptionItem,
   {
      value: 9,
      label: 'September',
      key: 9
   } as IOptionItem,
   {
      value: 10,
      label: 'October',
      key: 10
   } as IOptionItem,
   {
      value: 11,
      label: 'November',
      key: 11
   } as IOptionItem,
   {
      value: 12,
      label: 'December',
      key: 12
   } as IOptionItem,
]