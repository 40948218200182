import * as React from 'react';
import { Button, Dropdown, MenuProps } from 'antd';
import { IOptionItem } from '../../functions/option.functions';
import CustomIcon, { CustomIconType } from './AntComponents/CustomIcon';
import { ItemType } from 'antd/lib/menu/hooks/useItems';

interface IProps {
   buttonText: string;
   onClick: (selectedOption: IOptionItem) => void;
   disabled?: boolean;
   loading?: boolean;
   style?: React.CSSProperties;
   title?: string;
   danger?: boolean,
   filled?: boolean,

   options: IOptionItem[];
};

const DropDownButton: React.FC<IProps> = (props) => {

   const { onClick, buttonText, danger, disabled, filled, loading, style, title, options } = props;
   const [dropdownMenu, setDropdownMenu] = React.useState<MenuProps>({});

   React.useEffect(() => {
      const optionEffect = () => {
         if (options?.length > 0) {
            const items = options.map((option, idx) => {
               const menuProp: ItemType = {
                  key: idx,
                  label: (
                     <a target="_blank" rel="noopener"
                        onClick={(e) => {
                           //initial testing proved inclusive
                           //e.preventDefault();
                           //e.stopPropagation();
                           onClick(option);
                        }}>
                        {option.label}
                     </a>
                  )
               };
               return menuProp;
            });
            setDropdownMenu({ items } as MenuProps);
         }
      }
      optionEffect();

      // eslint-disable-next-line react-hooks/exhaustive-deps      
   }, [options]);

   return <Dropdown disabled={disabled} menu={dropdownMenu} trigger={['click']}>
      <Button
         ghost={!filled}
         danger={danger}
         type='primary'
         icon={<CustomIcon type={dropdownMenu ? CustomIconType.CaretDownFilled : CustomIconType.CaretUpFilled} />}
         loading={loading ?? false}
         disabled={disabled}
         style={{ ...style }}
         title={title}
      >
         <b>
            <span style={{ paddingRight: 10 }} />
            {buttonText}
         </b>
      </Button>
   </Dropdown>
}

export default DropDownButton;