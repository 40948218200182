import { HttpError, TGet } from '../../functions/httpClient';
import { IOptionItem } from '../../functions/option.functions';
import { IStatus } from '../models';
import useSWR from 'swr';
import { UserRole } from './UserRoleModel';
import { useMemo } from 'react';

export const userRoleBaseUrl = `api/practice/UserRole`;

const swrOptions = {
   refreshInterval: 0,
   revalidateOnFocus: false,
   dedupingInterval: 60000
};


export function useFetchUserRoles(httpGet: TGet): IStatus & { userRoles: UserRole[] } {
   const { data, isValidating, error } = useSWR<UserRole[], HttpError>(
      userRoleBaseUrl,
      httpGet,
      { ...swrOptions }
   );

   return {
      userRoles: data,
      isLoading: !error && !data && isValidating,
      error: error?.message
   };
}

export function useUserRoleOptions(httpGet: TGet, includeNullOption: boolean = false): { userRoleOptions: IOptionItem[] } {
   const { userRoles } = useFetchUserRoles(httpGet);

   const userRoleOptions = useMemo(() => {
      if (userRoles?.length > 0) {
         const options = userRoles.map((r): IOptionItem => ({
            key: r.userRoleId,
            label: r.roleName,
            value: r.userRoleId
         } as IOptionItem));

         if (includeNullOption) {
            options.push({
               key: 0,
               label: '[NULL]',
               value: 0
            } as IOptionItem);
         }

         return options;
      }
   }, [userRoles, includeNullOption])

   return { userRoleOptions }
}