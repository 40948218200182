import { useMemo } from 'react';
import { HttpError, TPost, TDelete, TGet } from '../../functions/httpClient';
import { IOptionItem } from '../../functions/option.functions';
import { IStatus } from '../models';
import useSWR, { mutate } from 'swr';
import {  AssistanceProgramResource, AssistanceProgramResourceCategory } from './AssistanceProgramResourceModel';

export const assistanceProgramResourceBaseUrl = `api/program/AssistanceProgramResource`;
const assistanceProgramResourceCategoriesBaseUrl = `api/program/AssistanceProgramResourceCategory`
const assistanceProgramResourceSaveUrl = `${assistanceProgramResourceBaseUrl}/Save`;
const assistanceProgramResourceDeleteUrl = (id: number) => `${assistanceProgramResourceBaseUrl}/Delete/${id}`;

const swrOptions = {
   refreshInterval: 0,
   revalidateOnFocus: false,
   dedupingInterval: 60000
};


export function useFetchAssistanceProgramResources(httpGet: TGet): IStatus & { assistanceProgramResources: AssistanceProgramResource[] } {
   const { data, isLoading, error } = useSWR<AssistanceProgramResource[], HttpError>(
      assistanceProgramResourceBaseUrl,
      httpGet,
      { ...swrOptions }
   );

   return {
      assistanceProgramResources: data,
      isLoading: isLoading,
      error: error?.message
   };
}

export function useFetchAssistanceProgramResourceCategories(httpGet: TGet): IStatus & { assistanceProgramResourceCategories: AssistanceProgramResourceCategory[] } {
   const { data, isLoading, error } = useSWR<AssistanceProgramResourceCategory[], HttpError>(
      assistanceProgramResourceCategoriesBaseUrl,
      httpGet,
      { ...swrOptions }
   );

   return {
      assistanceProgramResourceCategories: data,
      isLoading: isLoading,
      error: error?.message
   };
}

export const saveAssistanceProgramResource = async (httpPost: TPost, assistanceProgramResource: AssistanceProgramResource) => {
   const result = await httpPost(assistanceProgramResourceSaveUrl, assistanceProgramResource)
      .then(() => mutate(assistanceProgramResourceBaseUrl));
   return result;
}

export const deleteAssistanceProgramResource = async (httpDelete: TDelete, id: number) => {
   const result = await httpDelete(assistanceProgramResourceDeleteUrl(id))
      .then(() => mutate(assistanceProgramResourceBaseUrl));
   return result;
}

export function useAssistanceProgramResourceCategoryOptions(httpGet: TGet): { categoryOptions: IOptionItem[] } {
   const { assistanceProgramResourceCategories } = useFetchAssistanceProgramResourceCategories(httpGet);

   const categoryOptions = useMemo(() => {
      if (assistanceProgramResourceCategories?.length > 0) {
         return assistanceProgramResourceCategories.map((category): IOptionItem => ({
            key: category.id,
            label: `[${category.id}] ${category.category}`,
            value: category.id
         } as IOptionItem));
      }
      return [];
   }, [assistanceProgramResourceCategories]);

   return {
      categoryOptions
   };
}