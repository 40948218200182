import * as React from 'react';
import { ColumnsType } from "antd/lib/table";
import { numberComparer, stringComparer } from "../../functions/comparer.functions";
import { RingCallQueue } from "../../store/practice/RingCallQueueModel";
import SearchResultsTable from "../shared/AntComponents/Table/SearchResultsTable";
import { useFetchCallQueue } from "../../store/practice/RingCallQueueFetcher";
import { useApiContext } from "../../store/ApiContext";
import { ActionButton, IconButton } from "../shared/Buttons";
import CustomIcon, { CustomIconType } from "../shared/AntComponents/CustomIcon";
import RingCallQueueEditor from "./RingCallQueueEditor";
import { useUserContext } from "../../auth/authContext";
import { Space } from "antd";
import RingCallQueueDeletePrompt from "./RingCallQueueDeletePrompt";
import { formatPhone } from "../../functions/format.functions";
import { BasicCheckboxField } from "../shared/BasicInputLibrary";
import colorWheel from "../../Theme/ColorWheel";
import Spinner from "../Spinner";
import { isInRole } from "../../functions/auth.functions";
import { KnownSettings } from "../../store/SettingsModel";
import { antSortOptions } from "../shared/AntComponents/Table/table.functions";
import { ColumnFilterItem } from "antd/lib/table/interface";

const RingCallQueueIndex: React.FC = () => {
   const {currentUserMain} = useUserContext();
   const {userRoles} = useUserContext();
   const {httpGet} = useApiContext();
   const {isLoading, callQueue} = useFetchCallQueue(httpGet);
   const [filteredData, setFilteredData] =
      React.useState<RingCallQueue[]>([]);
   const [isNew, setIsNew] =
      React.useState<boolean>(false);
   const [editorOpen, setEditorOpen] =
      React.useState<boolean>(false);
   const [deletePromptOpen, setDeletePromptOpen] =
      React.useState<boolean>(false);
   const [queueToEdit, setQueueToEdit] =
      React.useState<RingCallQueue>(undefined);
   const [showActive, setShowActive] =
      React.useState<boolean>(true);

   const activeData = React.useMemo(() => {
      return callQueue?.filter((f) => f.isDeleted === false);
   }, [callQueue]);

   const removeDuplicateColumnFilters = (options: ColumnFilterItem[]) => {
      return [...new Map(options.map(item => [item['value'], item])).values()];
   }

   // set the column filters
   const {
      practiceIdFilters,
      practiceNameFilters,
      managedTenantIdFilters,
      externalAliasFilters,
      phoneFilters,
      voicemailPinFilters,
      faxFilters
   } = React.useMemo(() => {
      let practiceIdFilters: ColumnFilterItem[] = [];
      let practiceNameFilters: ColumnFilterItem[] = [];
      let managedTenantIdFilters: ColumnFilterItem[] = [];
      let externalAliasFilters: ColumnFilterItem[] = [];
      let phoneFilters: ColumnFilterItem[] = [];
      let voicemailPinFilters: ColumnFilterItem[] = [];
      let faxFilters: ColumnFilterItem[] = [];

      callQueue?.map((q, i) => {
         practiceIdFilters.push({text: q.practiceId, value: q.practiceId});
         practiceNameFilters.push({text: q.practiceName, value: q.practiceName});
         managedTenantIdFilters.push({text: q.managedTenantId, value: q.managedTenantId});
         externalAliasFilters.push({text: q.externalAlias, value: q.externalAlias});
         phoneFilters.push({text: q.phone, value: q.phone});
         voicemailPinFilters.push({text: q.voicemailPin, value: q.voicemailPin});
         faxFilters.push({text: q.fax, value: q.fax});
      });

      practiceIdFilters = removeDuplicateColumnFilters(practiceIdFilters);
      practiceNameFilters = removeDuplicateColumnFilters(practiceNameFilters);
      managedTenantIdFilters = removeDuplicateColumnFilters(managedTenantIdFilters);
      externalAliasFilters = removeDuplicateColumnFilters(externalAliasFilters);
      phoneFilters = removeDuplicateColumnFilters(phoneFilters);
      voicemailPinFilters = removeDuplicateColumnFilters(voicemailPinFilters);
      faxFilters = removeDuplicateColumnFilters(faxFilters);

      return {
         practiceIdFilters,
         practiceNameFilters,
         managedTenantIdFilters,
         externalAliasFilters,
         phoneFilters,
         voicemailPinFilters,
         faxFilters
      };
   }, [callQueue]);

   React.useEffect(() => {
      if (showActive)
         setFilteredData(activeData);
      else
         setFilteredData(callQueue);
   }, [activeData, callQueue, showActive]);

   const handleNewQueueButton = () => {
      setIsNew(true);
      setQueueToEdit(undefined);
      setEditorOpen(true);
   }

   const handleCloseEditor = () => {
      setQueueToEdit(undefined);
      setEditorOpen(false);
      setDeletePromptOpen(false);
      setIsNew(false);
   }

   const handleEditButton = (callQueue: RingCallQueue) => {
      setQueueToEdit(callQueue);
      setEditorOpen(true);
   }

   const handleDeleteButton = (callQueue: RingCallQueue) => {
      setQueueToEdit(callQueue);
      setDeletePromptOpen(true);
   }

   const handleInactiveToggle = () => {
      setShowActive(showActive !== true);
      if (showActive)
         setFilteredData(callQueue);
      else
         setFilteredData(activeData);
   }

   const columns: ColumnsType<RingCallQueue> = [
      {
         title: 'Practice Id',
         dataIndex: 'practiceId',
         sorter: (a, b) => numberComparer(a.practiceId, b.practiceId),
         sortDirections: antSortOptions,
         width: 125,
         render: (text, record) => {
            return record?.practiceId ? record.practiceId : ' ';
         },
         filters: practiceIdFilters,
         onFilter: (value, record) => record.practiceId === value,
         filterSearch: true
      },
      {
         title: 'Practice Name',
         dataIndex: 'practiceName',
         sorter: (a, b) => stringComparer(a.practiceName, b.practiceName),
         sortDirections: antSortOptions,
         filters: practiceNameFilters,
         onFilter: (value, record) =>
            record.practiceName.indexOf(value as string) !== -1,
         filterSearch: true,
      },
      {
         title: 'Managed Tenant Id',
         dataIndex: 'managedTenantId',
         sorter: (a, b) => stringComparer(a.managedTenantId, b.managedTenantId),
         sortDirections: antSortOptions,
         filters: managedTenantIdFilters,
         onFilter: (value, record) =>
            record.managedTenantId.indexOf(value as string) !== -1,
         filterSearch: true
      },
      {
         title: 'External Alias',
         dataIndex: 'externalAlias',
         sorter: (a, b) => stringComparer(a.externalAlias, b.externalAlias),
         sortDirections: antSortOptions,
         width: 140,
         filters: externalAliasFilters,
         onFilter: (value, record) =>
            record.externalAlias.indexOf(value as string) !== -1,
         filterSearch: true
      },
      {
         title: 'Phone',
         dataIndex: 'phone',
         sorter: (a, b) => stringComparer(a.phone, b.phone),
         sortDirections: antSortOptions,
         width: 140,
         filters: phoneFilters,
         onFilter: (value, record) =>
            record.phone.indexOf(value as string) !== -1,
         filterSearch: true,
         render: (value, record) => {
            return formatPhone(record.phone);
         }
      },
      {
         title: 'Voicemail Pin',
         dataIndex: 'voicemailPin',
         sorter: (a, b) => stringComparer(a.voicemailPin, b.voicemailPin),
         sortDirections: antSortOptions,
         width: 140,
         filters: voicemailPinFilters,
         onFilter: (value, record) =>
            record.voicemailPin.indexOf(value as string) !== -1,
         filterSearch: true
      },
      {
         title: 'Fax',
         dataIndex: 'fax',
         sorter: (a, b) => stringComparer(a.fax, b.fax),
         sortDirections: antSortOptions,
         width: 140,
         filters: faxFilters,
         onFilter: (value, record) =>
            record.fax.indexOf(value as string) !== -1,
         filterSearch: true,
         render: (value, record) => {
            return formatPhone(record.fax);
         }
      },
      {
         title: 'Active',
         dataIndex: 'isDeleted',
         width: 55,
         render: (value, record) => {
            if (record.isDeleted)
               return <CustomIcon style={{color: colorWheel.red4}} type={CustomIconType.CloseCircleOutlined}/>
            else
               return <CustomIcon style={{color: colorWheel.green4}} type={CustomIconType.CheckOutlined}/>
         }
      },
      {
         title: () => <ActionButton icon={<CustomIcon type={CustomIconType.PlusOutlined}/>}
                                    buttonText='New'
                                    onClick={handleNewQueueButton}/>,
         dataIndex: 'actions',
         width: 105,
         render: (value, record) => {
            return (
               <Space>
                  <IconButton
                     title='Edit Call Queue'
                     icon={<CustomIcon type={CustomIconType.EditOutlined}/>}
                     onClick={() => handleEditButton(record)}
                  />
                  {isInRole(userRoles, [KnownSettings.ContentAdmin]) &&
                     <IconButton
                        title='Delete Call Queue'
                        icon={<CustomIcon type={CustomIconType.DeleteOutlined}/>}
                        onClick={() => handleDeleteButton(record)}
                     />
                  }
               </Space>
            );
         }
      }
   ];

   if (isLoading)
      return <Spinner/>

   return (
      <>
         <div style={{margin: 15}}>
            <BasicCheckboxField
               label='Show Active'
               checked={showActive}
               onChange={handleInactiveToggle}
               tooltip='When not selected, both Active and Inactive will be shown.'/>
         </div>

         <SearchResultsTable
            rowkey={'id'}
            columns={columns}
            data={filteredData}
            titleText='Ring Call Queue'
            scrollY='calc(100vh - 175px)'
            fixedHeader={true}
            size='small'
         />

         {editorOpen &&
            <RingCallQueueEditor
               isNew={isNew}
               isOpen={editorOpen}
               closeEditor={handleCloseEditor}
               callQueue={queueToEdit}
               inUsePracticeIds={practiceIdFilters}
               userMainId={currentUserMain.id}/>
         }

         {deletePromptOpen &&
            <RingCallQueueDeletePrompt
               isOpen={deletePromptOpen}
               closeEditor={handleCloseEditor}
               callQueue={queueToEdit}/>}
      </>
   );
}

export default RingCallQueueIndex;