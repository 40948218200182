import * as React from 'react';
import useSWR from "swr";
import { HttpError, TGet } from "../../functions/httpClient";
import { IOptionItem } from "../../functions/option.functions";
import { AccessRole } from './AccessRoleModel';

export const accessRoleBaseUrl = 'api/auth/accessrole';

const swrOptions = {
   refreshInterval: 0,
   revalidateOnFocus: false,
   dedupingInterval: 60000
};

export const useFetchAccessRole = (httpGet: TGet) => {
   const { data, isValidating, error } = useSWR<AccessRole[], HttpError>(
      accessRoleBaseUrl,
      httpGet,
      { ...swrOptions }
   );

   return {
      accessRoles: data,
      isLoading: isValidating,
      error: error
   };
}

export function useAccessRoleOptions(httpGet: TGet, includeNullOption: boolean = false): { accessRoleOptions: IOptionItem[] } {
   const { accessRoles } = useFetchAccessRole(httpGet);

   const accessRoleOptions = React.useMemo(() => {
      if (accessRoles?.length > 0) {
         const options = accessRoles.map((r): IOptionItem => ({
            key: r.id,
            label: r.roleName,
            value: r.id
         } as IOptionItem));

         if (includeNullOption) {
            options.push({
               key: 0,
               label: '[NULL]',
               value: 0
            } as IOptionItem);
         }

         return options;
      }
   }, [accessRoles, includeNullOption])

   return { accessRoleOptions }
}