import { IOptionItem } from '../../functions/option.functions';

export interface FundingStatusState {
    isLoading: boolean;
    error?: string;
    isSaving: boolean;
    fundingStatusList: FundingStatus[];
}

export interface FundingStatus {
    id?: number;
    assistanceProgramId: number; // not in FundingStatus table; was joined from AssistanceService in the API
    assistanceServiceId: number;
    foundationDiseaseTypeId: number;
    insuranceClassId: number;
    status: FundStatusType;
    modifiedOn: Date;
    modifiedBy: number;
    statusModifiedOn: Date;
    lastChecked: Date;
    externalId: string;
    //   externalFundingData: string;
    isAPOnly: boolean;
    modifiedByDisplay: string;
}

//used to support 1..x when creating a new item.
export interface FundingStatusWithInsuranceClasses extends FundingStatus {
    insuranceClasses: number[];
}

//used in initial foundation path
export interface FundingStatusWithNames extends FundingStatus {
    assistanceProgramName: string;
    assistanceServiceName: string;
    serviceDescription: string;
    insuranceClassName: string;
}

//similar to the above one, but with more fields
export interface FundingStatusViewModel extends FundingStatus {
    foundationId: number;
    foundationName: string;
    foundationScrapingStatus: string;
    diseaseTypeId: number;
    diseaseTypeName: string;
    assistanceServiceName: string;
    assistanceProgramId: number;
    programName: string;
    insuranceClassName: string;
    statusType: string;
}

export enum FundStatusType {
    Delete = -2,
    NoRecord = -1,
    Unknown = 0,
    Closed = 1,
    Open = 2,
    ReenrollmentOnly = 3
}


export const buildFundStatusTypeOptions = (includeNoRecord: boolean = false): IOptionItem[] => {
    const returnVal = [
        {
            key: FundStatusType.Unknown,
            label: '0 - Unknown',
            value: FundStatusType.Unknown
        } as IOptionItem,
        {
            key: FundStatusType.Closed,
            label: '1 - Closed',
            value: FundStatusType.Closed
        } as IOptionItem,
        {
            key: FundStatusType.Open,
            label: '2 - Open',
            value: FundStatusType.Open
        } as IOptionItem,
        {
            key: FundStatusType.ReenrollmentOnly,
            label: '3 - Open for Re-Enrollment Only',
            value: FundStatusType.ReenrollmentOnly
        } as IOptionItem
    ];

    if (includeNoRecord) {
        returnVal.unshift({ key: FundStatusType.NoRecord, label: '-1 - No Record', value: FundStatusType.NoRecord } as IOptionItem)
    };

    return returnVal;
}
