import { useState, useEffect, useMemo } from 'react';
import { HttpError, TPost, TGet } from '../../functions/httpClient';
import { IOptionItem } from '../../functions/option.functions';
import { IStatus } from '../models';
import useSWR, { mutate } from 'swr';
import { Foundation } from './FoundationModel';
import { stringComparer } from '../../functions/comparer.functions';

export const foundationBaseUrl = `api/program/Foundation`;
const foundationSaveUrl = `${foundationBaseUrl}/Save`;
const foundationSummaryUrl = (id: number) => `${foundationBaseUrl}/${id}`;

const swrOptions = {
   refreshInterval: 0,
   revalidateOnFocus: false,
   dedupingInterval: 60000
};


export function useFetchFoundations(httpGet: TGet): IStatus & { foundations: Foundation[] } {
   const { data, isLoading, error } = useSWR<Foundation[], HttpError>(
      foundationBaseUrl,
      httpGet,
      { ...swrOptions }
   );

   return {
      foundations: data,
      isLoading: isLoading,
      error: error?.message
   };
}

export function useFetchFoundationSummary(httpGet: TGet, foundationId: number): IStatus & { foundation: Foundation} {
   const { data, isLoading, error } = useSWR<Foundation, HttpError>(
      foundationSummaryUrl(foundationId),
      httpGet,
      { ...swrOptions }
   );

   return {
      foundation: data,
      isLoading: isLoading,
      error: error?.message
   };
}

export const saveFoundation = async (httpPost: TPost, foundation: Foundation) => {
   const result = await httpPost(foundationSaveUrl, foundation)
      .then(() => mutate(foundationBaseUrl));
   return result;
}

export function useFoundationNameOptions(httpGet: TGet): { foundationOptions: IOptionItem[] } {
   const { foundations } = useFetchFoundations(httpGet);

   const foundationOptions = useMemo(() => {
      if (foundations?.length > 0) {
         const sorted = [...foundations];

         sorted.sort((a: Foundation, b: Foundation) => stringComparer(String(a.foundationName), String(b.foundationName)));

         const foundationOptions = sorted.map((foundation): IOptionItem => ({
            key: foundation.id,
            label: `[${foundation.id}] ${foundation.foundationName}`,
            value: `${foundation.foundationName}`
         } as IOptionItem));
         return foundationOptions;
      }
      return [];
   }, [foundations]);

   return {
      foundationOptions
   };
}

export const buildFoundationOptions = (foundations: Foundation[]): IOptionItem[] => {
   if (foundations?.length > 0) {
      const sorted = [...foundations];

      sorted.sort((a: Foundation, b: Foundation) => stringComparer(String(a.foundationName), String(b.foundationName)));

      const foundationOptions = sorted.map((foundation): IOptionItem => ({
         key: foundation.id,
         label: `[${foundation.id}] ${foundation.foundationName}`,
         value: foundation.id
      } as IOptionItem));

      return foundationOptions;
   }
   return [];
}

export function useFoundationIdOptions(httpGet: TGet): { foundationOptions: IOptionItem[] } {
   const { foundations } = useFetchFoundations(httpGet);

   const foundationOptions = useMemo(() => buildFoundationOptions(foundations)
   , [foundations]);

   return {
      foundationOptions
   };
}

export const useFoundationById = (httpGet: TGet, id: number): IStatus & Foundation => {
   const { foundations, isLoading, error } = useFetchFoundations(httpGet);

   return {
      isLoading,
      error,
      // eslint-disable-next-line no-restricted-globals
      ...foundations?.find((f: Foundation) => f.id === id) ?? undefined,
   }
};

