export interface ContentClassificationXRefChangeLog {
   id: number;
   practiceId: number;
   practiceName: string;
   facilityGrouping: string;
   insuranceGroup: string;
   payer: string;
   insuranceClassOld: string;
   insuranceClassNew: string;
   modifiedOn?: Date;
   modifiedBy?: string;
}

export interface ContentClassificationXRefChangeLogSearchFilter {
   page: number;
   pageSize: number;
   sortField: string;
   sortDirection: string;

   insuranceGroup?: string;
   payer?: string;
   facilityGrouping?: string;
   practiceIds?: number[];
   practiceName?: string;
   newInsuranceClasses?: string[];
   oldInsuranceClasses?: string[];
   modifiedFrom?: Date;
   modifiedTo?: Date;
   modifiedBy?: string;
}

export interface ContentClassificationChangeLogSummary {
   thisWeek: number;
   lastWeek: number;
   lastMonth: number;
}

export enum modifiedRanges {
   thisWeek = 'thisWeek',
   lastWeek = 'lastWeek',
   lastMonth = 'lastMonth'
};
 