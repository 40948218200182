import * as React from 'react';
import { IFormError } from '../../../functions/form.contracts';
import FormInputSelector from './FormInputSelector';
import { CohortItem } from '../../../store/cohort/CohortItemModel';
import ItemStatusIndicator from '../ItemStatusIndicator';
import { Col, Row } from 'antd';

interface Props {
   dataName: string;
   cohortItem: CohortItem;
   onInputChange: (val: any) => void;
   errors: IFormError[];
   isTouched: boolean;
   onInputBlur: () => void;
   isValid: boolean;
   errMessage: string;
   inputIndex: number;
}

//component to manage creating the correct component based on the supplied question.FieldType.
const FormRow1Col: React.FC<Props> = (props) => {
   const {
      dataName,
      cohortItem,
      onInputChange,
      errors,
      onInputBlur,
      isValid,
      inputIndex
   } = props;

   return (
      <Row>
         <Col style={{paddingRight: 10}}>
            <ItemStatusIndicator itemStatusArray={[cohortItem.cohortItemStatusId]}/>
         </Col>
         <Col>
            <FormInputSelector
               dataName={dataName}
               cohortItem={cohortItem}
               onInputChange={onInputChange}
               errors={errors}
               onInputBlur={onInputBlur}
               isValid={isValid}
               inputIndex={inputIndex}
            />
         </Col>
      </Row>
   );
}

export default FormRow1Col;
