import * as React from 'react';
import { PowerBIReportVM } from '../../store/report/ReportModel';
import { setSessionItem, getSessionItem } from '../../functions/storage.functions';

interface IRecentlyViewedReportsContextProps {
   recentReports: PowerBIReportVM[];
   addReport: (report: PowerBIReportVM) => void;
   removeReport: (report: PowerBIReportVM) => void;
   removeAllReports: () => void;
}

export const RecentlyViewedReportsContext = React.createContext<IRecentlyViewedReportsContextProps>({} as IRecentlyViewedReportsContextProps);
export const useRecentlyViewedReports = () => React.useContext(RecentlyViewedReportsContext);

const CONTEXT_SESSION_KEY = 'Recently_Viewed_Reports_Context'

interface IContextProviderProps {
   children: React.ReactNode;
}

export const RecentlyViewedReportsContextProvider: React.FC<IContextProviderProps> = (props) => {
   const [sessionToggle, setSessionToggle] = React.useState(false);

   const addReport = (report: PowerBIReportVM) => {

      if (!report) return;

      const storedReports = getSessionItem<PowerBIReportVM[]>(CONTEXT_SESSION_KEY);
      if (storedReports?.length > 0) {
         if (!storedReports.some(y => y.id === report.id)) {
            if (storedReports.length < 5) {
               setSessionItem(CONTEXT_SESSION_KEY, [report, ...storedReports]);
            } else {
               const newCollection = [report, ...storedReports.slice(0, -1)];
               setSessionItem(CONTEXT_SESSION_KEY, newCollection);
            }
         }
      } else {
         setSessionItem(CONTEXT_SESSION_KEY, [report]);
      }
      setSessionToggle(!sessionToggle);
   }

   const removeReport = (report: PowerBIReportVM) => {
      if (!report) return;

      const storedReports = getSessionItem<PowerBIReportVM[]>(CONTEXT_SESSION_KEY);
      if (storedReports?.length > 0) {
         setSessionItem(CONTEXT_SESSION_KEY, [
            ...storedReports.filter(y => y.id !== report.id)
         ]);
      }
      setSessionToggle(!sessionToggle);
   }

   const removeAllReports = () => {
      setSessionItem(CONTEXT_SESSION_KEY, []);
      setSessionToggle(!sessionToggle);
   }

   const recentReports = React.useMemo(() => {
      return getSessionItem<PowerBIReportVM[]>(CONTEXT_SESSION_KEY);
   }, [sessionToggle])

   

   return (
      <RecentlyViewedReportsContext.Provider
         value={{
            recentReports,
            addReport,
            removeReport,
            removeAllReports
         }}>
         {props.children}
      </RecentlyViewedReportsContext.Provider>
   )

}