import * as React from 'react';
import './EmptyModal.css';

interface IProps {
   targetString?: string,
   searchString?: string
}

interface ISearchTerms {
   idx: number,
   length: number
};

const HighlightSearchText: React.FC<IProps> = (props: IProps) => {

   const { targetString, searchString } = props;

   //ensure targetString and searchString have values...
   if (!targetString || !searchString) {
      return <>{targetString === undefined ? null : targetString}</>;
   }

   //highlight match in yellow
   var matchStyle = { backgroundColor: '#FFFF00' };

   var renderArray: React.ReactNode[]  = [];       //array of JSX to render at the end
   var matchedIndices: ISearchTerms[] = [];    //list of indexes of where match occurs along with the length of the match
   var i = -1;                 //keep track of where in the targetString I'm looking for matches
   var searchTerms = searchString.split(' ');  //need to get all the search terms in the search string separated by spaces

   //For each search term grab all the indexes it is found in the targetString and record the length of that match
   searchTerms.forEach(st => {
      if (st !== "") {
         while ((i = targetString.toLowerCase().indexOf(st.toLowerCase(), i + 1)) !== -1) {
            matchedIndices.push({ idx: i, length: st.length });
         }
      }
   });

   //Sort the matched indeces so that I can iterate through them from the start of the targetString straight through to the end
   //Additionally, if more than one search term matches on the same index, I prioritize the longest match
   matchedIndices = matchedIndices.sort((a, b) => {
      if (a.idx === b.idx) return a.length > b.length ? -1 : 1;
      return a.idx < b.idx ? -1 : 1;
   });

   //Loop through the matched indeces and render the matched sections of the targetString wrapped in a span with 'bold' applied
   //Because the longer matches are prioritized, not every value in matchedIndeces will be utilized because a longer match may make a shorter match useless if they are at near indeces. 
   i = 0;
   matchedIndices.forEach(match => {
      var j = match.idx;
      if (j >= i) {
         if (j > i) { //unmatched section
            renderArray.push(targetString.slice(i, j));
         }
         //matched section
         i = j + match.length;
         renderArray.push(<span style={matchStyle} key={j}>{targetString.slice(j, i)}</span>); //need to format this section here
      }
   });
   //need to grab unmatched ending section if there is one
   if (i < targetString.length) {
      renderArray.push(targetString.slice(i));
   }

   return <span>{renderArray.map(i => i)}</span>;  //maybe could leave off the <span> wrapper? not sure what the best approach is here...
};

export default HighlightSearchText;
