import * as React from 'react';
import { KnownSettings } from '../store/SettingsModel';
import { useFetchSetting } from '../store/SettingsFetcher';

//Settings state is loaded before splash screen displays, no need to pull in actionCreators
const VersionDisplay: React.FC = () => {

   const settingString: string = useFetchSetting(KnownSettings.Version);
   const versionString = settingString ? `version ${settingString}` : '';

   return <>{versionString}</>;
};

export default VersionDisplay;

