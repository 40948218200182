import { NumberedTitle } from '../Typography/Title';
import { Table } from 'antd';
import type { ColumnsType, TableProps } from 'antd/lib/table';
import { ExpandableConfig, GetRowKey, TableRowSelection } from 'antd/lib/table/interface';
import CustomIcon, { CustomIconType } from '../CustomIcon';
import * as React from 'react';

interface IProps {
   className?: string;
   onChange?: TableProps<any>['onChange'];
   columns?: ColumnsType<any>;
   data?: object[];
   rowKey: string | GetRowKey<object>;
   titleText?: string;
   showTitle?: boolean;
   titleLevel?: 1 | 2 | 3 | 4 | 5;
   size?: 'small' | 'middle' | 'large' | undefined;
   bordered?: boolean;
   showHeader?: boolean;
   rowSelection?: TableRowSelection<object>;
   footer?: React.ReactNode;
   scrollY?: string;
   expandedRowRender?: (item: any) => any;
   style?: React.CSSProperties;
   hidePaging?: boolean;
}

// A table that's not meant to have any filtering options for the User
const MinimalistTable: React.FC<IProps> = (props) => {
   const {
      rowKey, bordered, className, columns, data, expandedRowRender,
      footer, onChange, rowSelection, scrollY, showHeader, showTitle, size, style,
      titleLevel, titleText, hidePaging
   } = props;
   const [currentPage, setCurrentPage] = React.useState(1);
   const [currentPageSize, setCurrentPageSize] = React.useState(25);
   const localOnChange: TableProps<object>['onChange'] = (pagination, filters, sorter, extra) => {
      pagination && setCurrentPage(pagination?.current);
      onChange && onChange(pagination, filters, sorter, extra);
      setCurrentPageSize && setCurrentPageSize(pagination?.pageSize)
   }
   const _hidePaging = React.useMemo(() => data?.length ?? 0 < currentPageSize, [data?.length, currentPageSize])
   const footerProp = footer ? { footer: () => footer } : undefined;

   return <>
      {showTitle &&
         <NumberedTitle level={titleLevel} text={titleText} />
      }

      <Table
         style={style}
         onChange={localOnChange}
         rowKey={rowKey}
         columns={columns}
         dataSource={data}
         size={size}
         bordered={bordered}
         className={className ? `ap-minimalist-table ${className}` : 'ap-minimalist-table'}
         showHeader={showHeader}
         pagination={_hidePaging || !currentPageSize ? false : {
            defaultCurrent: 1,
            defaultPageSize: 25,
            pageSize: currentPageSize,
            pageSizeOptions: [25, 50, 100, 500],
            simple: false,
            current: currentPage,
            hideOnSinglePage: false
         }}
         {...footerProp}
         rowSelection={rowSelection}
         scroll={scrollY && { y: scrollY }}
         showSorterTooltip={false}
         expandable={expandedRowRender && {
            expandedRowRender: expandedRowRender,
            expandIcon: ({ expanded, onExpand, record }) =>
               expanded ? (
                  <span onClick={e => onExpand(record, e)} > <CustomIcon type={CustomIconType.UpOutlined} /></span>
               ) : (
                  <span onClick={e => onExpand(record, e)} > <CustomIcon type={CustomIconType.DownOutlined} /></span>
               )
         } as ExpandableConfig<object>}
      />
   </>
}

export default MinimalistTable;