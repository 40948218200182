import * as React from 'react';
//rhyme === ocd
import {
   AlertFilled,
   AlertOutlined,
   AntCloudOutlined,
   AppstoreOutlined,
   AreaChartOutlined,
   BankOutlined,
   BarChartOutlined,
   BellOutlined,
   BorderOutlined,
   CaretDownFilled,
   CaretUpFilled,
   CheckOutlined,
   CloseCircleOutlined,
   CloseOutlined,
   CloudUploadOutlined,
   CoffeeOutlined,
   CopyOutlined,
   CopyFilled,
   ContainerOutlined,
   DeleteFilled,
   DeleteOutlined,
   DisconnectOutlined,
   DownOutlined,
   DownSquareOutlined,
   EditOutlined,
   ExportOutlined,
   EyeOutlined,
   FilePdfOutlined,
   FilterFilled,
   FilterOutlined,
   GithubFilled,
   HomeOutlined,
   IdcardOutlined,
   InfoCircleFilled,
   LineChartOutlined,
   LinkOutlined,
   MailOutlined,
   MedicineBoxOutlined,
   MehOutlined,
   MenuFoldOutlined,
   MenuOutlined,
   MenuUnfoldOutlined,
   PlusOutlined,
   PrinterOutlined,
   ProfileOutlined,
   RedoOutlined,
   ReloadOutlined,
   RightOutlined,
   SafetyCertificateOutlined,
   SaveFilled,
   SearchOutlined,
   SendOutlined,
   ShopOutlined,
   SnippetsOutlined,
   StarFilled,
   StarOutlined,
   StopOutlined,
   SwapOutlined,
   UpOutlined,
   UpSquareOutlined,
   UserOutlined,
   UserSwitchOutlined,
   WarningOutlined,
} from '@ant-design/icons';

//reason === alphabet
const CustomIcon: React.FC<{
   type: CustomIconType,
   style?: React.CSSProperties,
   className?: string | undefined
}> = (props) => {
   switch (props.type) {
      case CustomIconType.AlertFilled:
         return <AlertFilled style={props.style} className={props.className} />
      case CustomIconType.AlertOutlined:
         return <AlertOutlined style={props.style} className={props.className} />
      case CustomIconType.AntCloudOutlined:
         return <AntCloudOutlined style={props.style} className={props.className} />
      case CustomIconType.AppstoreOutlined:
         return <AppstoreOutlined style={props.style} className={props.className} />
      case CustomIconType.AreaChartOutlined:
         return <AreaChartOutlined style={props.style} className={props.className} />
      case CustomIconType.BankOutlined:
         return <BankOutlined style={props.style} className={props.className} />
      case CustomIconType.BarChartOutlined:
         return <BarChartOutlined style={props.style} className={props.className} />
      case CustomIconType.BellOutlined:
         return <BellOutlined style={props.style} className={props.className} />
      case CustomIconType.BorderOutlined:
         return <BorderOutlined style={props.style} className={props.className} />
      case CustomIconType.CaretDownFilled:
         return <CaretDownFilled style={props.style} className={props.className} />
      case CustomIconType.CaretUpFilled:
         return <CaretUpFilled style={props.style} className={props.className} />
      case CustomIconType.CheckOutlined:
         return <CheckOutlined style={props.style} className={props.className} />
      case CustomIconType.CloseCircleOutlined:
         return <CloseCircleOutlined style={props.style} className={props.className} />
      case CustomIconType.CloseOutlined:
         return <CloseOutlined style={props.style} className={props.className} />
      case CustomIconType.CloudUploadOutlined:
         return <CloudUploadOutlined style={props.style} className={props.className} />
      case CustomIconType.CoffeeOutlined:
         return <CoffeeOutlined style={props.style} className={props.className} />
      case CustomIconType.CopyFilled:
         return <CopyFilled style={props.style} className={props.className} />
      case CustomIconType.CopyOutlined:
         return <CopyOutlined style={props.style} className={props.className} />
      case CustomIconType.ContainerOutlined:
         return <ContainerOutlined style={props.style} className={props.className} />
      case CustomIconType.DeleteFilled:
         return <DeleteFilled style={props.style} className={props.className} />
      case CustomIconType.DeleteOutlined:
         return <DeleteOutlined style={props.style} className={props.className} />
      case CustomIconType.DisconnectOutlined:
         return <DisconnectOutlined style={props.style} className={props.className} />
      case CustomIconType.DownOutlined:
         return <DownOutlined style={props.style} className={props.className} />
      case CustomIconType.DownSquareOutlined:
         return <DownSquareOutlined style={props.style} className={props.className} />
      case CustomIconType.EditOutlined:
         return <EditOutlined style={props.style} className={props.className} />
      case CustomIconType.EyeOutlined:
         return <EyeOutlined style={props.style} className={props.className} />
      case CustomIconType.ExportOutlined:
         return <ExportOutlined style={props.style} className={props.className} />
      case CustomIconType.FilterFilled:
         return <FilterFilled style={props.style} className={props.className} />
      case CustomIconType.FilterOutlined:
         return <FilterOutlined style={props.style} className={props.className} />
      case CustomIconType.FilePdfOutlined:
         return <FilePdfOutlined style={props.style} className={props.className} />
      case CustomIconType.GithubFilled:
         return <GithubFilled style={props.style} className={props.className} />
      case CustomIconType.HomeOutlined:
         return <HomeOutlined style={props.style} className={props.className} />
      case CustomIconType.IdcardOutlined:
         return <IdcardOutlined style={props.style} className={props.className} />
      case CustomIconType.InfoCircleFilled:
         return <InfoCircleFilled style={props.style} className={props.className} />
      case CustomIconType.LineChartOutlined:
         return <LineChartOutlined style={props.style} className={props.className} />
      case CustomIconType.LinkOutlined:
         return <LinkOutlined style={props.style} className={props.className} />
      case CustomIconType.MailOutlined:
         return <MailOutlined style={props.style} className={props.className} />
      case CustomIconType.MedicineBoxOutlined:
         return <MedicineBoxOutlined style={props.style} className={props.className} />
      case CustomIconType.MenuFoldOutlined:
         return <MenuFoldOutlined style={props.style} className={props.className} />
      case CustomIconType.MenuOutlined:
         return <MenuOutlined style={props.style} className={props.className} />
      case CustomIconType.MenuUnfoldOutlined:
         return <MenuUnfoldOutlined style={props.style} className={props.className} />
      case CustomIconType.PlusOutlined:
         return <PlusOutlined style={props.style} className={props.className} />
      case CustomIconType.PrinterOutlined:
         return <PrinterOutlined style={props.style} className={props.className} />
      case CustomIconType.ProfileOutlined:
         return <ProfileOutlined style={props.style} className={props.className} />
      case CustomIconType.RightOutlined:
         return <RightOutlined style={props.style} className={props.className} />
      case CustomIconType.RedoOutlined:
         return <RedoOutlined style={props.style} className={props.className} />
      case CustomIconType.ReloadOutlined:
         return <ReloadOutlined style={props.style} className={props.className} />
      case CustomIconType.SafetyCertificateOutlined:
         return <SafetyCertificateOutlined style={props.style} className={props.className} />
      case CustomIconType.SaveFilled:
         return <SaveFilled style={props.style} className={props.className} />
      case CustomIconType.SearchOutlined:
         return <SearchOutlined style={props.style} className={props.className} />
      case CustomIconType.SendOutlined:
         return <SendOutlined style={props.style} className={props.className} />
      case CustomIconType.ShopOutlined:
         return <ShopOutlined style={props.style} className={props.className} />
      case CustomIconType.SnippetsOutlined:
         return <SnippetsOutlined style={props.style} className={props.className} />
      case CustomIconType.StarFilled:
         return <StarFilled style={props.style} className={props.className} />
      case CustomIconType.StarOutlined:
         return <StarOutlined style={props.style} className={props.className} />
      case CustomIconType.StopOutlined:
         return <StopOutlined style={props.style} className={props.className} />
      case CustomIconType.SwapOutlined:
         return <SwapOutlined style={props.style} className={props.className} />
      case CustomIconType.UpOutlined:
         return <UpOutlined style={props.style} className={props.className} />
      case CustomIconType.UpSquareOutlined:
         return <UpSquareOutlined style={props.style} className={props.className} />
      case CustomIconType.UserOutlined:
         return <UserOutlined style={props.style} className={props.className} />
      case CustomIconType.UserSwitchOutlined:
         return <UserSwitchOutlined style={props.style} className={props.className} />
      case CustomIconType.WarningOutlined:
         return <WarningOutlined style={props.style} className={props.className} />
      default:
         return <MehOutlined style={props.style} className={props.className} />
   }
}

export default CustomIcon;

// Before you ask, there wasn't any rhyme or reason as to these assignments, just order of seeing them used
export enum CustomIconType {
   AlertFilled, AlertOutlined, AntCloudOutlined, AppstoreOutlined, AreaChartOutlined,
   BankOutlined, BarChartOutlined, BellOutlined, BorderOutlined,
   CaretDownFilled, CaretUpFilled, CheckOutlined,
   CloseCircleOutlined, CloseOutlined, CloudUploadOutlined, ContainerOutlined,
   CoffeeOutlined, CopyOutlined, CopyFilled,
   DeleteFilled, DeleteOutlined, DisconnectOutlined, DownOutlined, DownSquareOutlined,
   EditOutlined, EyeOutlined, ExportOutlined,
   FilterFilled, FilterOutlined, FilePdfOutlined,
   GithubFilled,
   HomeOutlined,
   IdcardOutlined, InfoCircleFilled,
   LineChartOutlined, LinkOutlined,
   MailOutlined, MedicineBoxOutlined,
   MehOutlined, MenuFoldOutlined, MenuOutlined, MenuUnfoldOutlined,
   PlusOutlined, ProfileOutlined, PrinterOutlined,
   RightOutlined, RedoOutlined, ReloadOutlined,
   SafetyCertificateOutlined, SaveFilled, SearchOutlined, SendOutlined, ShopOutlined, SnippetsOutlined, StarOutlined, StarFilled, StopOutlined, SwapOutlined,
   UpOutlined, UpSquareOutlined, UserOutlined, UserSwitchOutlined,
   Unknown, WarningOutlined
}
