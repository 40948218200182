import { Space, TableProps } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import * as React from 'react';
import { numberComparer, stringComparer } from '../../../functions/comparer.functions';
import { AssistanceServiceBrandDefaultsViewModel } from '../../../store/program/AssistanceServiceBrandDefaultsModel';
import {
   useBrandDefaultsAssistanceServiceOptions, useBrandDefaultsBrandOptions,
   useBrandDefaultsManufacturerOptions, useBrandDefaultsViewModel
} from '../../../store/program/AssistanceServiceBrandDefaultsFetcher';
import CustomIcon, { CustomIconType } from '../../shared/AntComponents/CustomIcon';
import { ActionButton } from '../../shared/Buttons';
import AssistanceServiceBrandDefaultsEditor from './AssistanceServiceBrandDefaultsEditor';
import { useApiContext } from '../../../store/ApiContext';
import { SearchResultsTableWithFilters } from '../../shared/AntComponents/Table/SearchResultsTableWithFilters';
import { IFilteredInfo } from '../../shared/AntComponents/Filter/FilteredInfo';
import ColumnFilter from '../../shared/AntComponents/Filter/ColumnFilter';
import MultiSelectColumnFilter from '../../shared/AntComponents/Filter/MultiSelectColumnFilter';
import { antSortOptions } from '../../shared/AntComponents/Table/table.functions';

type searchFilter = 'manufacturerName' | 'brandName' | 'assistanceServiceName';
const defaultFilterValue: Record<searchFilter, IFilteredInfo> = {
   manufacturerName: undefined,
   brandName: undefined,
   assistanceServiceName: undefined
}
const AssistanceServiceBrandDefaultsIndex: React.FC = () => {
   const { httpGet } = useApiContext();
   const { brandDefaultsViewModelList, isLoading } = useBrandDefaultsViewModel(httpGet);
   const { brandDefaultsAssistanceProgramServiceOptions } = useBrandDefaultsAssistanceServiceOptions(httpGet);
   const { brandDefaultsManufacturerOptions } = useBrandDefaultsManufacturerOptions(httpGet);
   const { brandDefaultsBrandOptions } = useBrandDefaultsBrandOptions(httpGet);
   const [selectedItem, setSelectedItem] = React.useState<AssistanceServiceBrandDefaultsViewModel>(undefined);
   const [isEditorOpen, setIsEditorOpen] = React.useState<boolean>(false);
   const [isEditorReadonly, setIsEditorReadonly] = React.useState<boolean>(true);

   const [filteredInfo, setFilteredInfo] = React.useState<Record<searchFilter, IFilteredInfo>>(defaultFilterValue);
   const [currentPage, setCurrentPage] = React.useState(1);
   const [currentPageSize, setCurrentPageSize] = React.useState(25);
   const handleOnChange: TableProps<AssistanceServiceBrandDefaultsViewModel>['onChange'] =
      (pagination, filters, sorter, extra) => setCurrentPage(pagination?.current);

   const clearFilters = () => setFilteredInfo(defaultFilterValue);

   const filterByManufacturerName = (m: AssistanceServiceBrandDefaultsViewModel, search: number[]): boolean => {
      return !search || search.length == 0 || search.includes(m.manufacturerId);
   }

   const filterByBrandName = (m: AssistanceServiceBrandDefaultsViewModel, search: number[]): boolean => {
      return !search || search.length == 0 || search.includes(m.brandId);
   }

   const filterByAssistanceServiceName = (m: AssistanceServiceBrandDefaultsViewModel, search: number[]): boolean => {
     return !search || search.length == 0 || search.includes(m.assistanceServiceId);
   }

   const filterGridContent = (records: AssistanceServiceBrandDefaultsViewModel[]): AssistanceServiceBrandDefaultsViewModel[] => {
      if (records && records?.length > 0) {
         return records.filter(y =>
            filterByManufacturerName(y, filteredInfo?.manufacturerName?.values.map(m => m.value as number)) &&
            filterByBrandName(y, filteredInfo?.brandName?.values.map(m => m.value as number)) &&
            filterByAssistanceServiceName(y, filteredInfo?.assistanceServiceName?.values.map(m => m.value as number)));
      }
      return [];
   }

   const filteredData = React.useMemo(() => {
      if (brandDefaultsViewModelList?.length > 0) {
         return filterGridContent(brandDefaultsViewModelList)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [brandDefaultsViewModelList, filteredInfo]);

   const handleCloseEditorClick = () => {
      setIsEditorOpen(false);
      setSelectedItem(undefined);
   }
   const tableColumns: ColumnsType<AssistanceServiceBrandDefaultsViewModel> = [
      {
         title: 'Id',
         dataIndex: 'id',
         key: 'id',
         width: 50,
         sorter: (a, b) => numberComparer(a.id, b.id),
         sortDirections: antSortOptions,
         defaultSortOrder: 'descend',
      },
      {
         title: <ColumnFilter title='Manufacturer Name'
            filteredInfo={filteredInfo?.manufacturerName}
            content={<MultiSelectColumnFilter
               options={brandDefaultsManufacturerOptions}
               searchPlaceholder={'Filter by Manufacturer'}
               allowSelectAll={true}
               searchable={true}
               filteredInfo={filteredInfo}
               setFilteredInfo={setFilteredInfo}
               filteredInfoKey={'manufacturerName'}
               filteredInfoTitle='Manufacturer Name'
            />}
         />,
         dataIndex: 'manufacturerName',
         key: 'manufacturerName',
         width: '15%',
         sorter: (a, b) => stringComparer(a.manufacturerName, b.manufacturerName),
         sortDirections: antSortOptions,
      },
      {
         title: 'BrandId',
         dataIndex: 'brandId',
         key: 'brandId',
         width: 50,
         sorter: (a, b) => numberComparer(a.brandId, b.brandId),
         sortDirections: antSortOptions,
      },
      {
         title: <ColumnFilter title='Brand Name'
            filteredInfo={filteredInfo?.brandName}
            content={<MultiSelectColumnFilter
               options={brandDefaultsBrandOptions}
               searchPlaceholder={'Filter by Brand'}
               allowSelectAll={true}
               searchable={true}
               filteredInfo={filteredInfo}
               setFilteredInfo={setFilteredInfo}
               filteredInfoKey={'brandName'}
               filteredInfoTitle='Brand Name'
            />}
         />,
         dataIndex: 'brandName',
         key: 'brandName',
         width: '15%',
         sorter: (a, b) => stringComparer(a.brandName, b.brandName),
         sortDirections: antSortOptions,
      },
      {
         title: 'ASID',
         showSorterTooltip: { title: 'Assistance Service Identifier' },
         dataIndex: 'assistanceServiceId',
         key: 'assistanceServiceId',
         width: 50,
         sorter: (a, b) => numberComparer(a.assistanceServiceId, b.assistanceServiceId),
         sortDirections: antSortOptions,
      },
      {
         title: <ColumnFilter title='Assistance Service Name'
            filteredInfo={filteredInfo?.assistanceServiceName}
            content={<MultiSelectColumnFilter
               options={brandDefaultsAssistanceProgramServiceOptions}
               searchPlaceholder={'Filter by Assistance Service'}
               allowSelectAll={true}
               searchable={true}
               filteredInfo={filteredInfo}
               setFilteredInfo={setFilteredInfo}
               filteredInfoKey={'assistanceServiceName'}
               filteredInfoTitle='Assistance Service'
            />}
         />,
         dataIndex: 'assistanceServiceName',
         key: 'assistanceServiceName',
         width: '15%',
         sorter: (a, b) => stringComparer(a.assistanceServiceName, b.assistanceServiceName),
         sortDirections: antSortOptions,
      },
      {
         title: 'Program Name',
         dataIndex: 'programName',
         key: 'programName',
         width: '15%',
         sorter: (a, b) => stringComparer(a.programName, b.programName),
         sortDirections: antSortOptions,
      },
      {
         dataIndex: 'actions',
         key: 'actions',
         width: 30,
         fixed: 'right',
         render: (text, record) => <Space>
            <ActionButton
               title='Preview'
               icon={<CustomIcon type={CustomIconType.EyeOutlined} />}
               onClick={() => {
                  setIsEditorOpen(true);
                  setIsEditorReadonly(true);
                  setSelectedItem(record);
               }}
            />
         </Space>
      },
   ];

   const html = (
      <>
         <SearchResultsTableWithFilters
            loading={isLoading}
            rowkey={'id'}
            titleText={'Assistance Service Brand Defaults'}
            columns={tableColumns}
            data={filteredData}
            currentPage={currentPage}
            currentPageSize={currentPageSize}
            setCurrentPageSize={setCurrentPageSize}
            onChange={handleOnChange}
            fixedHeader={true}
            filteredInfo={filteredInfo}
            setFilteredInfo={setFilteredInfo}
            onFiltersClear={() => clearFilters()}
            size={'small'}
            scrollHeight={350}
         />

         <AssistanceServiceBrandDefaultsEditor isOpen={isEditorOpen}
            closeEditor={() => handleCloseEditorClick()}
            id={selectedItem?.id}
            readonly={isEditorReadonly}
         />

      </>
   )
   return html;
}

export default AssistanceServiceBrandDefaultsIndex;
