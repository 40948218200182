import * as React from 'react';
import FoundationDiseaseTypeList from '../foundation/FoundationDiseaseTypeList';
import { AssistanceServiceLookup } from '../../store/program/LookupModels';
import { AddButton } from '../shared/Buttons';
import { Row, Col } from 'antd';

interface Props {
   assistanceProgramId: number;
   assistanceServiceLookups: AssistanceServiceLookup[];
   onAddService: (assistanceProgramId: number) => void;
   onEditService: (assistanceService: AssistanceServiceLookup) => void;
}

const FoundationAssistanceServiceList: React.FC<Props> = (props) => {
   const { assistanceProgramId, assistanceServiceLookups, onAddService, onEditService } = props;
   // specific filtering of 'discontinued' in the description, per AH-1999 request
   const filteredList = assistanceServiceLookups?.filter(f => !(f.description.toLowerCase().indexOf('discontinued') > -1));
   return (
      <>
         <div key={assistanceProgramId}>
            {
               filteredList?.map((assistanceService, i) => {
                  return (
                     <div key={assistanceService.assistanceServiceId} style={{ paddingBottom: 10 }}>
                        <div className='row header'>
                           <div className='column'>
                              <span className='span-title' onClick={() => onEditService(assistanceService)} title='Edit assistance service details'>{assistanceService.assistanceServiceName}</span>
                           </div>
                        </div>
                        <FoundationDiseaseTypeList assistanceServiceId={assistanceService.assistanceServiceId}
                           foundationDiseaseTypeLookups={assistanceService.foundationDiseaseTypeLookups} />
                     </div>
                  )
               })
            }
            <Row justify="end" align="bottom" style={{ paddingTop: 10 }}>
               <Col>
                  <AddButton
                     title='Add Service'
                     buttonText='Add Service'
                     onClick={() => onAddService(assistanceProgramId)}
                  />
               </Col>
            </Row>
         </div>
      </>
   );
}

export default FoundationAssistanceServiceList;