import CustomIcon, { CustomIconType } from '../../shared/AntComponents/CustomIcon'
import { Col, List, Row, Space } from 'antd';
import * as React from 'react';
import { useFetchDrug } from '../../../store/drug/DrugFetcher';
import { Drug } from '../../../store/drug/DrugModel';
import { useFetchAssistanceServices } from '../../../store/program/AssistanceServiceFetcher';
import { useFetchAssistanceServiceToDrug, saveAssistanceServiceToDrug, assistanceServiceToDrugBaseUrl } from '../../../store/program/AssistanceServiceToDrugFetcher';
import { AssistanceServiceToDrug } from '../../../store/program/AssistanceServiceToDrugModel';
import { useFetchManufacturer } from '../../../store/program/ManufacturerFetcher';
import { Manufacturer } from '../../../store/program/ManufacturerModel';
import Dialog from '../../Dialog';
import HighlightSearchText from '../../HighlightSearchText';
import { NumberedTitle } from '../../shared/AntComponents/Typography/Title';
import { BasicInputField } from '../../shared/BasicInputLibrary';
import { ActionButton, AddButton, CancelButton, SaveButton } from '../../shared/Buttons';
import Spinner from '../../Spinner';
import Restricted from '../../../auth/Restricted';
import { KnownSettings } from '../../../store/SettingsModel';
import { useApiContext } from '../../../store/ApiContext';
import ApiErrorDisplay from '../../ApiErrorDisplay';
import { useErrorContext } from '../../../store/ErrorContext';

interface IProps {
   assistanceServiceId: number;
   isOpen: boolean;
   closeEditor: () => void;
};

const dialogContentStyle = {
   height: '500px',
   maxHeight: '90vh',
}

const _keysLike: string[] = [`${assistanceServiceToDrugBaseUrl}/SaveAllByAssistanceServiceId`];

const AssistanceServiceToDrugEditor: React.FC<IProps> = (props) => {
   const { assistanceServiceId, closeEditor, isOpen } = props;

   const { httpGet, httpPost } = useApiContext();

   const { assistanceServiceToDrugs, isLoading } = useFetchAssistanceServiceToDrug(httpGet);
   const { drugs: rawDrugs } = useFetchDrug(httpGet);
   const { manufacturers } = useFetchManufacturer(httpGet);
   const { assistanceServices } = useFetchAssistanceServices(httpGet);
   const [mappedDrugs, setMappedDrugs] = React.useState<Drug[]>();
   const { removeErrors } = useErrorContext();

   //Search Bar
   const [searchText, setSearchText] = React.useState('');
   const filterFunction = (d: Drug) => {
      if (searchText === '') {
         return true;
      }
      else {
         var matchingManufacturers = manufacturers
            .filter(y => y.manufacturerName.toLowerCase().includes(searchText.toLowerCase()))
            .map((manufacturer: Manufacturer) => manufacturer.id);

         return d.drugName.toLowerCase().includes(searchText.toLowerCase())
            /* || d.description.toLowerCase().includes(searchText.toLowerCase())*/
            || d.ndcPrefix.toLowerCase().includes(searchText.toLowerCase())
            || matchingManufacturers.includes(d.manufacturerId);
      }
   }

   const filteredOptions = rawDrugs?.filter(filterFunction);

   //Add/Remove Drug
   const handleAddDrugClick = (drug: Drug) => {
      if (mappedDrugs.findIndex(i => i.id === drug.id) === -1) {
         setMappedDrugs([...mappedDrugs, drug]);
      }
   }

   const handleRemoveDrugClick = (id: number) => {
      const temp = mappedDrugs.filter(y => y.id !== id);
      setMappedDrugs(temp);
   }

   const sortFunction = (a: Drug, b: Drug) => { return a.drugName < b.drugName ? -1 : 1 };

   React.useEffect(() => {
      if (rawDrugs?.length > 0 && assistanceServiceToDrugs?.length > 0) {
         const temp: Drug[] = assistanceServiceToDrugs?.filter(y => y.assistanceServiceId === assistanceServiceId)
            .map((as2d: AssistanceServiceToDrug, index: number) =>
               rawDrugs?.find(y => y.id === as2d.drugId)
            ) ?? [];

         setMappedDrugs([...new Set(temp.filter(y => y))]);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [assistanceServiceId, isOpen, assistanceServiceToDrugs, rawDrugs]);

   const assistanceService = React.useMemo(() => {
      return assistanceServices?.find(y => y.id === assistanceServiceId);
   }, [assistanceServiceId, assistanceServices]);
   
   const [isFormSaving, setIsFormSaving] = React.useState<boolean>(false);

   const handleSaveClick = () => {

      const drugIds: number[] = mappedDrugs?.map((as2d: Drug) => as2d.id);
      setIsFormSaving(true);
      saveAssistanceServiceToDrug(httpPost, assistanceServiceId, drugIds)
         .then(() => {
            handleClose();
         })
         .catch(err => console.log(`Error Saving`, err))
         .finally(() => {
            setIsFormSaving(false);
         });
   }

   const handleClose = () => {
      setMappedDrugs([]);
      setSearchText('');
      setIsFormSaving(false);
      removeErrors({ keysLike: _keysLike });
      closeEditor();
   }

   const getManufacturerName = (manufacturerId: number) => {
      return manufacturers?.find(y => y.id === manufacturerId)?.manufacturerName;
   }

   const html = (
      <Dialog
         title={`Assistance Service To Drugs for ${assistanceService?.id} - ${assistanceService?.assistanceServiceName}`}
         open={isOpen}
         size="large"
         actionButtons={
            <>
               <CancelButton
                  onClick={() => handleClose()}
                  loading={isFormSaving}
               />
               <Restricted requiredRoles={[KnownSettings.ContentAdmin]}>
                  <SaveButton onClick={() => handleSaveClick()} loading={isFormSaving} />
               </Restricted>
            </>
         }>
         <div style={dialogContentStyle} className='ap-listmap'>
            <ApiErrorDisplay
               title='Error saving Assistance Service To Drug'
               keysLike={_keysLike}
            />

            <Row gutter={[16, 16]} justify="end" align="middle" style={{ paddingBottom: 10 }}>
               <Col span={12}>
                  <Space>
                     <BasicInputField
                        containerStyle={{ marginBottom: 0 }}
                        placeholder='Search by Drug Name or Manufacturer'
                        type="text"
                        name="drugSearch"
                        value={searchText}
                        autoFocus={true}
                        onChange={(e) => setSearchText(String(e))} />
                     <ActionButton onClick={() => setSearchText('')}
                        buttonText='Clear'></ActionButton>
                  </Space>
               </Col>
               <Col span={12} style={{ paddingBottom: 10 }}>
                  <NumberedTitle text='Associated Drugs' level={4} />
               </Col>
            </Row>

            <Row gutter={[16, 16]} justify="space-between">
               <Col span={12}>
                  {(!mappedDrugs || !assistanceService || !manufacturers || isLoading) && <Spinner />}
                  {mappedDrugs && assistanceService && manufacturers && !isLoading &&
                     <List
                        bordered
                        size='small'
                        style={{ maxHeight: '455px', overflowY: 'auto' }}
                        pagination={{
                           simple: true,
                           pageSize: 10,
                           hideOnSinglePage: true
                        }}
                        dataSource={filteredOptions.sort(sortFunction)}
                        renderItem={item => {
                           const isMapped = mappedDrugs.some(y => y.id === item.id);
                           return <List.Item>
                              <List.Item.Meta
                                 title={<>{item.id} - <HighlightSearchText searchString={searchText} targetString={item.drugName} /></>}
                                 description={getManufacturerName(item.manufacturerId)}
                              />
                              {!isMapped &&
                                 <Restricted requiredRoles={[KnownSettings.ContentAdmin]}>
                                    <AddButton
                                       title='Add'
                                       onClick={() => handleAddDrugClick(item)}
                                    />
                                 </Restricted>
                              }
                              {isMapped && <CustomIcon type={CustomIconType.CheckOutlined} />}
                           </List.Item>
                        }}
                     />
                  }
               </Col>
               <Col span={12}>
                  {mappedDrugs?.length > 0 &&
                     <List
                        bordered
                        style={{ maxHeight: '455px', overflowY: 'auto' }}
                        size='small'
                        dataSource={mappedDrugs.sort((a, b) => a.drugName.localeCompare(b.drugName))}
                        renderItem={item => (
                           <List.Item>
                              <List.Item.Meta
                                 title={<>{item.id} - {item.drugName}</>}
                                 description={getManufacturerName(item.manufacturerId)}
                              />
                              <Restricted requiredRoles={[KnownSettings.ContentAdmin]}>
                                 <ActionButton
                                    title='Delete'
                                    danger
                                    icon={<CustomIcon type={CustomIconType.DeleteOutlined} />}
                                    onClick={() => handleRemoveDrugClick(item.id)}
                                 />
                              </Restricted>
                           </List.Item>
                        )}
                     />
                  }
               </Col>
            </Row>

         </div>
      </Dialog>
   )
   return html;
}

export default AssistanceServiceToDrugEditor;
