import { Tag } from 'antd';
import * as React from 'react';
import { FundStatusType } from '../../store/program/FundingStatusModel';

interface IStatusTagProps {
   status: string;
}

const StatusTag: React.FC<IStatusTagProps> = (props: IStatusTagProps) => {
   return buildStatus(props.status);
}

const buildStatus = (status: string) => {
   const statusText = FundStatusType[parseInt(status)];
   const statusColor = getStatusColor(statusText);
   return (
      <Tag color={statusColor}>{statusText}</Tag>
   );
}

const getStatusColor = (status: string): string => {
   switch (status) {
      case 'Open': return 'success';
      case 'Closed': return 'error';
      case 'ReenrollmentOnly': return 'warning';
      default: return 'info';
   }
}

export default StatusTag;