import { mutate } from 'swr';
import { TPatch } from '../../functions/httpClient';

const baseUrl = `api/auth/User`;
export const adminPortalChangePasswordUrl = (userMainId: number) => `${baseUrl}/RequestAdminPortalPasswordChange/${userMainId}`;
export const assistpointChangePasswordUrl = (userMainId: number) => `${baseUrl}/RequestAssistpointPasswordChange/${userMainId}`;

export const requestAssistpointPasswordChange = async (httpPatch: TPatch, userMainId: number) => {
   const result = await httpPatch(assistpointChangePasswordUrl(userMainId))
      .then(() => mutate(baseUrl))
   return result;
}

export const requestAdminPortalPasswordChange = async (httpPatch: TPatch, userMainId: number) => {
   const result = await httpPatch(adminPortalChangePasswordUrl(userMainId))
      .then(() => mutate(baseUrl))
   return result;
}
