import CustomIcon, { CustomIconType } from '../../shared/AntComponents/CustomIcon';
import { Col, List, Row } from 'antd';
import * as React from 'react';
import { useFetchAssistanceServices } from '../../../store/program/AssistanceServiceFetcher';
import { AssistanceServiceToInsuranceClass } from '../../../store/program/AssistanceServiceToInsuranceClassModel';
import { assistanceServiceToInsuranceClassBaseUrl, useFetchAssistanceServiceToInsuranceClass, saveAssistanceServiceToDrug } from '../../../store/program/AssistanceServiceToInsuranceClassFetcher';
import { useFetchInsuranceClasses } from '../../../store/program/InsuranceClassFetcher';
import { InsuranceClass } from '../../../store/program/InsuranceClassModel';
import Dialog from '../../Dialog';
import { NumberedTitle } from '../../shared/AntComponents/Typography/Title';
import { ActionButton, CancelButton, SaveButton, AddButton } from '../../shared/Buttons';
import Spinner from '../../Spinner';
import Restricted from '../../../auth/Restricted';
import { KnownSettings } from '../../../store/SettingsModel';
import { useApiContext } from '../../../store/ApiContext';
import ApiErrorDisplay from '../../ApiErrorDisplay';
import { useErrorContext } from '../../../store/ErrorContext';

interface IProps {
   assistanceServiceId: number;
   isOpen: boolean;
   closeEditor: () => void;
};

const dialogContentStyle = {
   maxHeight: '95vh',
}
const _keysLike: string[] = [assistanceServiceToInsuranceClassBaseUrl];

const AssistanceServiceToInsuranceClassEditor: React.FC<IProps> = (props) => {
   const { assistanceServiceId, closeEditor, isOpen } = props;
   const { httpGet, httpPost } = useApiContext();
   const { insuranceClasses: rawInsuranceClasses } = useFetchInsuranceClasses(httpGet);
   const { assistanceServiceToInsuranceClasses, isLoading } = useFetchAssistanceServiceToInsuranceClass(httpGet);
   const { assistanceServices } = useFetchAssistanceServices(httpGet);
   const [insuranceClasses, setInsuranceClasses] = React.useState<InsuranceClass[]>();
   const { removeErrors } = useErrorContext();

   //Add/Remove
   const handleAddInsuranceClassClick = (insuranceClass: InsuranceClass) => {
      if (insuranceClasses.findIndex(i => i.insuranceClassId === insuranceClass.insuranceClassId) === -1) {
         setInsuranceClasses([...insuranceClasses, insuranceClass]);
      }
   }

   const handleRemoveInsuranceClassClick = (insuranceClassId: number) => {
      const temp = insuranceClasses.filter(y => y.insuranceClassId !== insuranceClassId);
      setInsuranceClasses(temp);
   }
   
   React.useEffect(() => {
      if (rawInsuranceClasses?.length > 0 && assistanceServiceToInsuranceClasses?.length > 0) {
         const temp: InsuranceClass[] = assistanceServiceToInsuranceClasses?.filter(y => y.assistanceServiceId === assistanceServiceId)
            .map((as2ic: AssistanceServiceToInsuranceClass, index: number) =>
               rawInsuranceClasses?.find(y => y.insuranceClassId === as2ic.insuranceClassId)
            ) ?? [];

         setInsuranceClasses([...new Set(temp.filter(y => y))]);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [assistanceServiceId, isOpen, assistanceServiceToInsuranceClasses, rawInsuranceClasses]);

   const assistanceService = React.useMemo(() => {
      return assistanceServices?.find(y => y.id === assistanceServiceId);
   }, [assistanceServiceId, assistanceServices]);

   const [isFormSaving, setIsFormSaving] = React.useState<boolean>(false);

   const handleSaveClick = () => {

      const insuranceClassIds: number[] = insuranceClasses?.map((ic: InsuranceClass) => ic.insuranceClassId);
      setIsFormSaving(true);
      saveAssistanceServiceToDrug(httpPost, assistanceServiceId, insuranceClassIds)
         .then(() => {
            handleClose();
         })
         .catch(err => console.log(`Error Saving`, err))
         .finally(() => {
            setIsFormSaving(false);
         });
   }

   const handleClose = () => {
      setInsuranceClasses([]);
      setIsFormSaving(false);
      removeErrors({ keysLike: _keysLike });
      closeEditor();
   }

   const html = (
      <Dialog
         title={`Assistance Service To Insurance Class for ${assistanceService?.id} - ${assistanceService?.assistanceServiceName}`}
         open={isOpen}
         size="large"
         actionButtons={
            <>
               <CancelButton
                  onClick={() => handleClose()}
                  loading={isFormSaving}
               />
               <Restricted requiredRoles={[KnownSettings.ContentAdmin]}>
                  <SaveButton onClick={() => handleSaveClick()} loading={isFormSaving} />
               </Restricted>
            </>
         }>
         <div style={dialogContentStyle}>

            <ApiErrorDisplay
               title='Error saving Assistance Service To Insurance Class'
               keysLike={_keysLike}
            />
            <>
               {(!insuranceClasses || !assistanceService || isLoading) && <Spinner />}

               {insuranceClasses && assistanceService && !isLoading &&
                  <Row gutter={[16, 16]} justify="end" align="top">
                     <Col span={11}>
                        <List
                           dataSource={rawInsuranceClasses}
                           header={<NumberedTitle level={3} text='Insurance Classes Available' />}
                           renderItem={ic => (
                              <List.Item key={ic.insuranceClassId} onClick={() => handleAddInsuranceClassClick(ic)} >
                                 <div style={{ width: 200 }}>
                                    <Row gutter={[16, 16]} justify="end" align="middle">
                                       <Col span={20}>
                                          {ic.insuranceClassId} - {ic.name}
                                       </Col>
                                       <Col span={4}>
                                          <Restricted requiredRoles={[KnownSettings.ContentAdmin]}>
                                             <AddButton
                                                title='Add Insurance Class'
                                                onClick={() => handleAddInsuranceClassClick(ic)}
                                             />
                                          </Restricted>
                                       </Col>
                                    </Row>
                                 </div>
                              </List.Item>
                           )}
                        />
                     </Col>
                     <Col span={2} />
                     <Col span={11}>
                        <List
                           dataSource={insuranceClasses}
                           header={<NumberedTitle level={3} text='Insurance Classes Associated' />}
                           renderItem={ic => (
                              <List.Item key={ic.insuranceClassId}
                                 onClick={() => handleAddInsuranceClassClick(ic)} >
                                 <div style={{ width: 200 }}>
                                    <Row gutter={[16, 16]} justify="end" align="middle">
                                       <Col span={20}>
                                          {ic.insuranceClassId} - {ic.name}
                                       </Col>
                                       <Col span={4}>
                                          <Restricted requiredRoles={[KnownSettings.ContentAdmin]}>
                                             <ActionButton
                                                title='Remove Associated Insurance Classes'
                                                danger
                                                icon={<CustomIcon type={CustomIconType.DeleteOutlined} />}
                                                onClick={() => handleRemoveInsuranceClassClick(ic.insuranceClassId)}
                                             />
                                          </Restricted>
                                       </Col>
                                    </Row>
                                 </div>
                              </List.Item>
                           )}
                        />
                     </Col>
                  </Row>
               }
            </>
         </div>
      </Dialog>
   )
   return html;
}
export default AssistanceServiceToInsuranceClassEditor;
