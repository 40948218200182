export interface IFormError {
   DataName: number;
   Message: any;
}
export interface IValidationResult {
   isValid: boolean;
   formError?: IFormError;
}

export class FieldTypes {
   public static dropdownlist: string = 'dropdownlist';
   public static currency: string = 'currency';
   public static date: string = 'date';
   public static radiogroup: string = 'radiogroup';
   public static checkboxlist: string = 'checkboxlist';
   public static integer: string = 'integer';
   public static string: string = 'string';
   public static yesno: string = 'yes|no';
   public static textarea: string = 'textarea';

   //admin portal specific controls
   public static currencydollars: string = 'currency-dollars';
   public static dropdownlistfromtable: string = 'dropdownlist-fromtable';
   public static url: string = 'url';
   public static yesinput: string = 'yes|input';
}