export const formatTime = (time: any): string => {
    if (!time)
        return null;
    const date = new Date(time);
    return typeof date === "object" ? date.toLocaleString() : "";
}

export const formatDateTimeString = (date: Date, includeSeconds?: boolean, includeTimeZone?: boolean): string => {
   if (!date)
      return null;

   const curr = new Date(date);
   const dateTimeOptions: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
   }

   if (includeSeconds) {
      dateTimeOptions.second = '2-digit';
   }

   if (includeTimeZone) {
      dateTimeOptions.timeZoneName = 'short';
   }

   const result = curr.toLocaleString('en-US', dateTimeOptions)

   return result;
}

export const formatShortDateString = (date: Date): string => {
    if (!date)
        return null;

    const curr = new Date(date);
    const curr_date = curr.getDate();
    const curr_month = curr.getMonth() + 1; //Months are zero based
    const curr_year = curr.getFullYear();

    return `${curr_month}/${curr_date}/${curr_year}`;
}

export const formatShortDate = (date: Date): Date => {
    if (!date)
        return null;
    const dateString: string = formatShortDateString(date);
    return new Date(dateString);
}


export const getFormattedDateForApi = (date: Date) => {
   if (!date) return undefined;   
   const newDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
   return newDate;
}

///dayOfWeek 0-6 -> Sunday===0
export const getNextDayOfWeek = (date: Date, dayOfWeek: number): Date => {
    const resultDate: Date = new Date(date.getTime());
    resultDate.setDate(date.getDate() + (7 + dayOfWeek - date.getDay()) % 7);
    return resultDate;
}

export const truncateStringStart = (string: string, maxLength: number): string => {

    if (!string || (string && string.length <= maxLength))
        return string;

    const startPos: number = string.length - maxLength + 3;
    const result: string = string.slice(startPos);
    return `...${result}`;
}

export const truncateStringEnd = (text: string, maxLength: number, truncSymbol: string = '...'): string => {
    if (!text || text?.length <= maxLength)
       return text;
    return `${text.substring(0, maxLength - truncSymbol.length)}${truncSymbol}`;
}

const fmt_currency = (decimals: number) => new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: decimals });
export const formatCurrency = (number: number, decimals: number) => {
    if (number === null || number === undefined) { return ''; }
    return fmt_currency(decimals).format(number);
}

export const formatBoolean = (val?: boolean): string => {
    if (val === undefined || val === null)
        return '';

    return val ? 'Yes' : 'No';
}

export const cleanPhoneNumber = (phoneNumberString: string) => ('' + phoneNumberString).replace(/\D/g, '');

export const formatPhone = (phoneNumberString: string) => {
   const cleaned = cleanPhoneNumber(phoneNumberString);
   const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
   if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
   }
   return phoneNumberString;
}