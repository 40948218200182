import { useMemo } from 'react';
import { HttpError, TPost, TDelete, TGet } from '../../functions/httpClient';
import { IOptionItem } from '../../functions/option.functions';
import { IStatus } from '../models';
import useSWR, { mutate } from 'swr';
import { GenericDrug } from './GenericDrugModel';
import { stringComparer } from '../../functions/comparer.functions';

export const genericDrugBaseUrl = `api/drug/genericDrug`;
export const genericDrugSaveUrl = `${genericDrugBaseUrl}/Save`;
export const genericDrugDeleteUrl = (id: number) => `${genericDrugBaseUrl}/Delete/${id}`;

const swrOptions = {
   refreshInterval: 0,
   revalidateOnFocus: false,
   dedupingInterval: 60000
};


export function useFetchGenericDrug(httpGet: TGet): IStatus & { genericDrugs: GenericDrug[] } {
   const { data, isValidating, error } = useSWR<GenericDrug[], HttpError>(
      genericDrugBaseUrl,
      httpGet,
      { ...swrOptions }
   );

   return {
      genericDrugs: data,
      isLoading: !error && !data && isValidating,
      error: error?.message
   };
}

export const saveGenericDrug = async (httpPost: TPost, genericDrug: GenericDrug) => {
   const result = await httpPost(genericDrugSaveUrl, genericDrug)
      .then(() => mutate(genericDrugBaseUrl));
   return result;
}

export const deleteGenericDrug = async (httpDelete: TDelete, id: number) => {
   const result = await httpDelete(genericDrugDeleteUrl(id))
      .then(() => mutate(genericDrugBaseUrl));
   return result;
}

export function useGenericDrugOptions(httpGet: TGet): { genericDrugOptions: IOptionItem[] } {
   const { genericDrugs } = useFetchGenericDrug(httpGet);

   const options = useMemo(() => {
      if (genericDrugs?.length > 0) {

         const sorted = [...genericDrugs];
         sorted.sort((a: GenericDrug, b: GenericDrug) => stringComparer(String(a.genericName), String(b.genericName)));

         const options = sorted.map((o): IOptionItem => ({
            key: o.id,
            label: `[${o.id}]: ${o.genericName ?? 'not specified'}`,
            value: o.id
         } as IOptionItem));

         return options;
      }
   }, [genericDrugs]);


   return {
      genericDrugOptions: options
   };
}
