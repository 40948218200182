import { HttpError, TPost, TGet } from '../../functions/httpClient';
import { IStatus } from '../models';
import useSWR, { mutate } from 'swr';
import { FundingStatusExcludedId } from './FundingStatusExcludedIdModel';

export const fundingStatusExcludedIdsBaseUrl = `api/program/fundingStatusExcludedId`;
const fundingStatusExludedIdsSaveUrl = `${fundingStatusExcludedIdsBaseUrl}/SaveAllExcludedIdsForFoundation`;

const swrOptions = {
   refreshInterval: 0,
   revalidateOnFocus: false,
   dedupingInterval: 60000
};


export function useFetchFundingStatusExludedIds(httpGet: TGet): IStatus & { fundingStatusExcludedIdList: FundingStatusExcludedId[] } {
   const { data, isValidating, error } = useSWR<FundingStatusExcludedId[], HttpError>(
      fundingStatusExcludedIdsBaseUrl,
      httpGet,
      { ...swrOptions }
   );

   return {
      fundingStatusExcludedIdList: data,
      isLoading: !error && !data && isValidating,
      error: error?.message
   };
}

export const saveFundingStatusExcludedIds = async (httpPost: TPost, foundationId: number,  fundingStatusExcludedIds: FundingStatusExcludedId[]) => {
   const result = await httpPost(fundingStatusExludedIdsSaveUrl,
      { foundationId: foundationId, excludedIds: fundingStatusExcludedIds  })
      .then(() => mutate(fundingStatusExcludedIdsBaseUrl));
   return result;
}