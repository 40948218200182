import { Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { numberComparer, stringComparer } from '../../functions/comparer.functions';
import { DiseaseToDiagnosisMap, DiseaseTypeToFoundation } from '../../store/program/DiseaseTypeModel';
import HighlightSearchText from '../HighlightSearchText';
import { AddButton, EditButton } from '../shared/Buttons';
import { useFetchDiseaseTypes, useDiseaseTypeOptions, useFetchDiseaseTypeToFoundationList, diseaseTypeBaseUrl } from '../../store/program/DiseaseTypeFetcher';
import DiseaseTypeEditor from './DiseaseTypeEditor';
const { Title } = Typography;
import MinimalistTable from '../shared/AntComponents/Table/MinimalistTable';
import { SearchResultsTableWithFilters } from '../shared/AntComponents/Table/SearchResultsTableWithFilters';
import ColumnFilter from '../shared/AntComponents/Filter/ColumnFilter';
import { IFilteredInfo } from '../shared/AntComponents/Filter/FilteredInfo';
import { IOptionItem } from '../../functions/option.functions';
import MultiSelectColumnFilter from '../shared/AntComponents/Filter/MultiSelectColumnFilter';
import Restricted from '../../auth/Restricted';
import { KnownSettings } from '../../store/SettingsModel';
import { useUserContext } from '../../auth/authContext';
import { isInRole } from '../../functions/auth.functions';
import { useApiContext } from '../../store/ApiContext';
import { BasicInputField } from '../shared/BasicInputLibrary';
import { HttpVerb, KeyWithVerb, useErrorContext } from '../../store/ErrorContext';
import ApiErrorDisplay from '../ApiErrorDisplay';


const DISEASE_TYPE_TITLE = 'Disease Type';
const DIAGNOSIS_CODE_TITLE = 'Diagnoses Codes';
type searchFilter = 'diseaseTypeName' | 'diagnosisCode' | 'diagnosisCodeString';
const defaultFilterValue: Record<searchFilter, IFilteredInfo> = {
   diagnosisCode: undefined,
   diseaseTypeName: undefined,
   diagnosisCodeString: undefined
};

const _keysWithVerb: KeyWithVerb[] = [{ key: diseaseTypeBaseUrl, verb: HttpVerb.GET }]

const DiseaseTypeIndex: React.FC = () => {
   const { httpGet } = useApiContext();
   const { removeErrors } = useErrorContext();
   const { diseaseTypes } = useFetchDiseaseTypes(httpGet);
   const { diseaseTypeOptions } = useDiseaseTypeOptions(httpGet);
   const { diseaseTypeToFoundations } = useFetchDiseaseTypeToFoundationList(httpGet);
   
   const [selectedItem, setSelectedItem] = React.useState<DiseaseToDiagnosisMap>(undefined);
   const [isEditorOpen, setIsEditorOpen] = React.useState<boolean>(false);
   const [filteredInfo, setFilteredInfo] = React.useState<Record<searchFilter, IFilteredInfo>>(defaultFilterValue);
   
   React.useEffect(() => {
      removeErrors({ keysWithVerb: _keysWithVerb });
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []) // remove Errors on initial render

   const filterName = (m: DiseaseToDiagnosisMap): boolean => {
      return !filteredInfo?.diseaseTypeName?.values || filteredInfo.diseaseTypeName.values.length === 0 ||
         filteredInfo.diseaseTypeName.values.some(y => y.value === m.diseaseTypeId);
   }

   const filterCodes = (m: DiseaseToDiagnosisMap): boolean => {
      return !filteredInfo?.diagnosisCode?.values || filteredInfo.diagnosisCode.values.length === 0 ||
         filteredInfo.diagnosisCode.values.some(y => m.diagnoses.map(d => d.code).includes(String(y.value)));
   }

   const filterCodesString = (m: DiseaseToDiagnosisMap): boolean => {
      return !filteredInfo?.diagnosisCodeString?.value ||
         m.diagnoses.some(y => y.code.indexOf((filteredInfo.diagnosisCodeString.value as string)?.toUpperCase()) > -1)
      // RE: the .toUpperCase() - as this is an interim solution for performance and I've been assured all codes get entered with an upper case letter, I went with that... 
   }

   const filterGridContent = (records: DiseaseToDiagnosisMap[]): DiseaseToDiagnosisMap[] => {
      if (records && records?.length > 0) {
         return records.filter(y => filterName(y) &&
            filterCodes(y) &&
            filterCodesString(y));
      }
      return [];
   }

   const filteredData = React.useMemo(() => {
      if (diseaseTypes?.length > 0) {
         return filterGridContent(diseaseTypes);
      }
      // for "filterGridContent" - we actually need the two in this dependency array
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [diseaseTypes, filteredInfo]);

   const diagnosisCodeOptionsFiltered = React.useMemo(() => {
      if (filteredData) {
         //slow when removing the last item - consider adding a default list
         const options: IOptionItem[] = [];
         filteredData.forEach(item => {
            item?.diagnoses.forEach(d => {
               if (!options.some(o => (o.value as string).toLowerCase() === d.code.toLowerCase())) {
                  options.push({
                     key: d.id,
                     label: `[${d.code}] ${d.description}`,
                     value: `${d.code}`
                  } as IOptionItem);
               }
            });
         });
         //options.sort((a: IOptionItem, b: IOptionItem) => stringComparer(a.value as string, b.value as string));
         return options;
      }
      return [];
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [filteredData]);

   const joinCodes = (diseaseType: DiseaseToDiagnosisMap): string => {
      const codestring = diseaseType.diagnoses ? diseaseType.diagnoses.map(y => y.code).join(', ') : ''
      return codestring;
   }

   const handleCloseEditorClick = () => {
      setIsEditorOpen(false);
      setSelectedItem(undefined);
   }

   const tableColumns: ColumnsType<DiseaseToDiagnosisMap> = [
      {
         title: 'Id',
         dataIndex: 'diseaseTypeId',
         key: 'diseaseTypeId',
         width: 100,
         sorter: (a, b) => numberComparer(a.diseaseTypeId, b.diseaseTypeId),
         sortDirections: ['ascend', 'descend']
      },
      {
         title: <ColumnFilter title={DISEASE_TYPE_TITLE}
            filteredInfo={filteredInfo?.diseaseTypeName}
            content={<MultiSelectColumnFilter
               options={diseaseTypeOptions}
               searchPlaceholder={'Filter by Disease Type'}
               allowSelectAll={true}
               searchable={true}
               filteredInfo={filteredInfo}
               setFilteredInfo={setFilteredInfo}
               filteredInfoKey={'diseaseTypeName'}
               filteredInfoTitle={DISEASE_TYPE_TITLE}
            />}
         />,
         dataIndex: 'diseaseTypeName',
         key: 'diseaseTypeName',
         sorter: (a, b) => stringComparer(a.diseaseTypeName, b.diseaseTypeName),
         sortDirections: ['ascend', 'descend'],
         defaultSortOrder: 'ascend',
         render: (text, record) => <HighlightSearchText searchString={filteredInfo.diseaseTypeName?.values.some(y => y.value === record.diseaseTypeId) ? text : ''} targetString={text} />
      },
      {
         title: <ColumnFilter
            title={DIAGNOSIS_CODE_TITLE}
            filteredInfo={filteredInfo.diagnosisCode}
            content={
               <MultiSelectColumnFilter
                  options={diagnosisCodeOptionsFiltered}
                  searchPlaceholder={'Filter by Diagnosis Code'}
                  allowSelectAll={true}
                  searchable={true}
                  filteredInfo={filteredInfo}
                  setFilteredInfo={setFilteredInfo}
                  filteredInfoKey={'diagnosisCode'}
                  filteredInfoTitle={DIAGNOSIS_CODE_TITLE}
               />}
         />,
         dataIndex: 'diagnoses',
         key: 'diagnoses',
         render: (text, record) => <HighlightSearchText searchString={filteredInfo?.diagnosisCode?.value as string} targetString={joinCodes(record)} />,
      },
      {
         title: () =>
            <Restricted requiredRoles={[KnownSettings.ContentAdmin]}>
               <AddButton title='Add New Disease Type.' buttonText='New' onClick={() => {
                  setSelectedItem(undefined);
                  setIsEditorOpen(true)
               }} />
            </Restricted>,
         dataIndex: '',
         key: 4,
         width: 100,
         fixed: 'right',
         render: (text, record) =>
            <Restricted requiredRoles={[KnownSettings.ContentAdmin]}>
               <EditButton title='Edit' onClick={() => {
                  setIsEditorOpen(true);
                  setSelectedItem(record);
               }} />
            </Restricted>
      }
   ];

   const buildExpandedContent = (diseaseExpanding: DiseaseToDiagnosisMap) => {
      return (
         <RenderFoundationDiseaseTypeTable
            key={`${diseaseExpanding.diseaseTypeId}_table`}
            diseaseTypeId={diseaseExpanding.diseaseTypeId}
            diseaseTypeToFoundations={diseaseTypeToFoundations}
         />
      )
   }

   const html = (
      <>
         <ApiErrorDisplay
            title={'Error'}
            keysWithVerb={_keysWithVerb}
         />

         <SearchResultsTableWithFilters
            rowkey={'diseaseTypeId'}
            columns={tableColumns}
            data={filteredData}
            titleText='Disease Type'
            fixedHeader={true}
            filteredInfo={filteredInfo}
            setFilteredInfo={setFilteredInfo}
            onFiltersClear={() => setFilteredInfo(defaultFilterValue)}
            expandedRowRender={(record) => buildExpandedContent(record)}
            additionalComponents={[
               <BasicInputField
                  label={'Filter By Diagnosis Code:'}
                  placeholder='Diagnosis Code...'
                  value={filteredInfo?.diagnosisCodeString?.value as string}
                  onChange={(val) => {
                     setFilteredInfo({
                        ...filteredInfo,
                        diagnosisCodeString: {
                           title: DIAGNOSIS_CODE_TITLE,
                           value: val as string
                        }
                     })
                  }}
               />
            ]}
         />
         {isEditorOpen &&
            <DiseaseTypeEditor isOpen={isEditorOpen} closeEditor={() => handleCloseEditorClick()} diseaseType={selectedItem} />
         }
      </>
   );

   return html;
}

interface FoundationDiseaseTypeTableProps {
   diseaseTypeId: number;
   diseaseTypeToFoundations: DiseaseTypeToFoundation[]
}

const RenderFoundationDiseaseTypeTable: React.FC<FoundationDiseaseTypeTableProps> = (props) => {
   const { userRoles } = useUserContext();
   const canEdit = isInRole(userRoles, [KnownSettings.ContentAdmin]);
   const foundationDiseaseTypeColumns: ColumnsType<DiseaseTypeToFoundation> = [
      {
         title: 'Foundation',
         dataIndex: 'foundationName',
         key: 1,
         render: (text, record) => {
            return canEdit ?
               <Link
                  to={{ pathname: `/program/Foundation/${record.foundationId}` }}
                  title={record.foundationName}>
                  {record.foundationName}
               </Link> :
               <p>{record.foundationName}</p>
         }
      },
      {
         title: 'FoundationDiseaseTypeName',
         dataIndex: 'foundationDiseaseTypeName',
         key: 2,
         render: (text, record) => {
            return canEdit ? 
               <Link
                  to={{ pathname: `/program/foundation/${record.foundationId}/diseaseType/${record.foundationDiseaseTypeId}` }}
                  title={record.foundationDiseaseTypeName}>
                  {record.foundationDiseaseTypeName}
               </Link> :
               <p>{record.foundationDiseaseTypeName}</p>
         }
      }
   ]
   {/*** For reference on the above Links:
     * program/foundation/<foundationid>/<foundationDiseaseTypeId>
     *  program/foundation/2000011/diseaseType/2000123*/}

   const items = (props.diseaseTypeToFoundations ?? []).filter(y => y.diseaseTypeId === props.diseaseTypeId);

   const html = (
      <>
         {(!items || items.length === 0) &&
            <Title level={5}>No mapped foundation disease types</Title>
         }
         {(items && items.length > 0) &&
            <MinimalistTable
               rowKey={'foundationDiseaseTypeId'}
               className={'searchresultstable searchresultstablehidescroll'}
               data-table={'searchresultstable'}
               columns={foundationDiseaseTypeColumns as ColumnsType<object>}
               data={items}
               showHeader={true}
            />
         }
      </>
   );

   return html;
}

export default DiseaseTypeIndex;
