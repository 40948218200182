import { ColumnsType, TableProps } from 'antd/lib/table';
import * as React from 'react';
import { Link } from 'react-router-dom';
import Restricted from '../../auth/Restricted';
import { boolComparer, dateComparer, numberComparer, stringComparer } from '../../functions/comparer.functions';
import { formatBoolean, formatDateTimeString } from '../../functions/format.functions';
import { useApiContext } from '../../store/ApiContext';
import { HttpVerb, KeyWithVerb, useErrorContext } from '../../store/ErrorContext';
import { practiceBaseUrl, useFetchPractice } from '../../store/practice/PracticeFetcher';
import { Practice } from '../../store/practice/PracticeModel';
import { KnownSettings } from '../../store/SettingsModel';
import ApiErrorDisplay from '../ApiErrorDisplay';
import HighlightSearchText from '../HighlightSearchText';
import SearchResultsTable from '../shared/AntComponents/Table/SearchResultsTable';
import { BasicInputField } from '../shared/BasicInputLibrary';
import { AddButton } from '../shared/Buttons';
import Spinner from '../Spinner';
import PracticeEditor from './PracticeEditor';

const _keysWithVerb: KeyWithVerb[] = [{key: practiceBaseUrl, verb: HttpVerb.GET}];

const PracticeIndex: React.FC = () => {
   const { httpGet } = useApiContext();
   const { removeErrors } = useErrorContext();
   const { practices, isLoading } = useFetchPractice(httpGet);
   const [nameFilter, setNameFilter] = React.useState<string>('');
   const [currentPage, setCurrentPage] = React.useState(1);
   const [currentPageSize, setCurrentPageSize] = React.useState(25);
   const [selectedItem, setSelectedItem] = React.useState<Practice>(undefined);
   const [isEditorOpen, setIsEditorOpen] = React.useState<boolean>(false);

   React.useEffect(() => {
      removeErrors({ keysWithVerb: _keysWithVerb });
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []) // remove Errors on initial render

   const handleOnChange: TableProps<Practice>['onChange'] = (pagination, filters, sorter, extra) => setCurrentPage(pagination?.current);

   const filterName = (m: Practice): boolean => {
      return !nameFilter || m.name?.toLowerCase().indexOf(nameFilter.toLowerCase()) > -1 ||
         !nameFilter || m.managedTenantId?.toLowerCase().indexOf(nameFilter.toLowerCase()) > -1 ||
         !nameFilter || m.externalTenantId?.toLowerCase().indexOf(nameFilter.toLowerCase()) > -1 ||
         !nameFilter || m.id.toString().indexOf(parseInt(nameFilter).toString()) > -1
         ? true : false;
   }

   const filterGridContent = (records: Practice[]): Practice[] => {
      if (records && records.length > 0) {
         return records.filter(y => filterName(y));
      }
      return [];
   }

   const filteredData = React.useMemo(() => {
      if (practices?.length > 0) {
         return filterGridContent(practices);
      }
      // for "filterGridContent" - we actually need the two in this dependency array
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [practices, nameFilter])

   const handleCloseEditorClick = () => {
      setIsEditorOpen(false);
      setSelectedItem(undefined);
   }

   const renderName = (record: Practice): JSX.Element => {
      if (record && record.name) {
         return <HighlightSearchText searchString={nameFilter} targetString={record.name} />
      } else {
         return <>{'<noname>'}</>
      }
   }

   if (isLoading || !practices) {
      return <Spinner />
   }

   const columns: ColumnsType<Practice> = [
      {
         title: 'Id',
         dataIndex: 'id',
         sorter: (a, b) => numberComparer(a.id, b.id),
         sortDirections: ['ascend', 'descend'],
         defaultSortOrder: 'descend',
         width: 80,
         render: (text, record) =>
            <HighlightSearchText searchString={nameFilter} targetString={record?.id.toString()} />
      },
      {
         title: 'Name',
         dataIndex: 'name',
         sorter: (a, b) => stringComparer(a.name, b.name),
         sortDirections: ['ascend', 'descend'],
         render: (text, record) =>
            <Link
               to={{ pathname: `/practice/${record.id}` }}
               title={record.name}>
               {renderName(record)}
            </Link>
      },
      {
         title: 'External Tenant Id',
         dataIndex: 'externalTenantId',
         sorter: (a, b) => stringComparer(a.externalTenantId, b.externalTenantId),
         sortDirections: ['ascend', 'descend'],
         render: (text, record) =>
            <HighlightSearchText searchString={nameFilter} targetString={record?.externalTenantId} />
      },
      {
         title: 'Managed Tenant Id',
         dataIndex: 'managedTenantId',
         sorter: (a, b) => stringComparer(a.managedTenantId, b.managedTenantId),
         sortDirections: ['ascend', 'descend'],
         render: (text, record) =>
            <HighlightSearchText searchString={nameFilter} targetString={record?.managedTenantId} />
      },
      {
         title: 'NPI',
         dataIndex: 'npi',
         sorter: (a, b) => stringComparer(a.npi, b.npi),
         sortDirections: ['ascend', 'descend']
      },
      {
         title: 'Has Feed',
         dataIndex: 'hasFeed',
         sorter: (a, b) => boolComparer(a.hasFeed, b.hasFeed),
         sortDirections: ['ascend', 'descend'],
         render: ((hasFeedValue: boolean) => formatBoolean(hasFeedValue)),
         width: 80,
      },
      {
         title: 'Created',
         dataIndex: 'created',
         sorter: (a, b) => dateComparer(a.created, b.created),
         sortDirections: ['ascend', 'descend'],
         render: ((createdDateValue: Date) => formatDateTimeString(createdDateValue))
      },
      {
         title: 'Deactive',
         dataIndex: 'deactivated',
         sorter: (a, b) => boolComparer(a.deactivated, b.deactivated),
         sortDirections: ['ascend', 'descend'],
         render: ((deactivatedValue: boolean) => formatBoolean(deactivatedValue)),
         width: 80,
      },
      {
         title: 'Deactivated Date',
         dataIndex: 'deactivatedDate',
         sorter: (a, b) => dateComparer(a.deactivatedDate, b.deactivatedDate),
         sortDirections: ['ascend', 'descend'],
         render: ((deactivatedDateValue: Date) => formatDateTimeString(deactivatedDateValue))
      },
      {
         title: () =>
            <Restricted requiredRoles={[KnownSettings.ContentAdmin]}>
               <AddButton
                  title='Add New Practice'
                  buttonText='New'
                  onClick={() => {
                     setSelectedItem(undefined);
                     setIsEditorOpen(true)
                  }} />
            </Restricted>,
         dataIndex: 'actions',
         fixed: 'right'
      }
   ]

   const html = (
      <>
         <ApiErrorDisplay
            title='Error'
            keysWithVerb={_keysWithVerb}
         />

         <SearchResultsTable
            rowkey={'id'}
            onChange={handleOnChange}
            columns={columns}
            data={filteredData}
            currentPage={currentPage}
            currentPageSize={currentPageSize}
            setCurrentPageSize={setCurrentPageSize}
            titleText='Practice'
            fixedHeader={true}
            additionalComponents={[
               <BasicInputField
                  placeholder='Search by id or name'
                  label='Search by id or name'
                  type="text"
                  name="practiceSearchString"
                  value={nameFilter}
                  onChange={(e) => setNameFilter(String(e))} />,
            ]}
         />

         {isEditorOpen &&
            <PracticeEditor
               isOpen={isEditorOpen}
               closeEditor={() => handleCloseEditorClick()}
               id={selectedItem?.id}
               isReadOnly={false} // Only used for "Add New" here
            />
         }
      </>
   )
   return html;
}

export default PracticeIndex;
