import * as React from 'react';
import { isInRole } from '../functions/auth.functions';
import { useUserContext } from './authContext';

interface IProps {
   children?: React.ReactNode;
   requiredRoles: string[];
}

/* Wrap this control around role restricted compoments.
   <Restricted requiredRoles={[KnownSettings.ContentAdmin]}>
      <div>
         Seekrit thing
      </div>
   </Restricted>
 */
const Restricted: React.FC<IProps> = (props) => {
   const { userRoles } = useUserContext();

   const accessGranted: boolean = isInRole(userRoles, props.requiredRoles);

   const html = (
      <React.Fragment>
         {accessGranted &&
            props.children}
      </React.Fragment>
   )
   return html;
}

export default Restricted;
