import { PowerBIReportVM } from "../../store/report/ReportModel";
import { View_Browse_Certified, View_Browse_Uncertified } from "../../store/report/ReportFunctions";

export enum ReportRoutes {
   certified = '/report/browsecertified',
   uncertified = '/report/browseuncertified',
   favorites = '/report/favorite',
   search = '/report/search'
}

export const validateBrowseRoute = (currentReport: PowerBIReportVM, path: string) => {
   const isBrowse: boolean = path.toLowerCase().indexOf('/report/browse') !== -1;
   
   if (currentReport.isCertified && isBrowse) {
      if (path.toLowerCase().indexOf(ReportRoutes.certified) === -1) {
         window.location.assign(`${window.location.origin}/report/${View_Browse_Certified}/${currentReport.id}`);
      }
   } else if (!currentReport.isCertified && isBrowse) {
      if (path.toLowerCase().indexOf(ReportRoutes.uncertified) === -1) {
         window.location.assign(`${window.location.origin}/report/${View_Browse_Uncertified}/${currentReport.id}`);
      }
   }
}
