import { ColumnsType } from 'antd/lib/table';
import * as React from 'react';
import { formatBoolean } from '../../../functions/format.functions';
import { useFetchAssistanceServices } from '../../../store/program/AssistanceServiceFetcher';
import { AssistanceServiceManufacturerEligibilityCriteria } from '../../../store/program/AssistanceServiceModel';
import MinimalistTable from '../../shared/AntComponents/Table/MinimalistTable';
import { ActionButton, AddButton, CancelButton, EditButton } from '../../shared/Buttons';

import { assistanceServiceToEligibilityCriteriaBaseUrl, saveAssistanceServiceEligibilityCriterias, useEligibilityCriteriaViewModel, useFetchEligibilityCriteria } from '../../../store/program/AssistanceServiceEligibilityCriteriaFetcher';
import { AssistanceServiceEligibilityCriteria, AssistanceServiceEligibilityCriteriaViewModel } from '../../../store/program/AssistanceServiceEligibilityCriteriaModel';
import { useFetchAssistanceServiceToDrug } from '../../../store/program/AssistanceServiceToDrugFetcher';

import { Col, Divider, Row, Space, Table } from 'antd';
import { TableRowSelection } from 'antd/lib/table/interface';
import Restricted from '../../../auth/Restricted';
import { boolComparer, numberComparer, stringComparer } from '../../../functions/comparer.functions';
import { useApiContext } from '../../../store/ApiContext';
import { KnownSettings } from '../../../store/SettingsModel';
import { useDrugViewModel } from '../../../store/drug/DrugFetcher';
import ApiErrorDisplay from '../../ApiErrorDisplay';
import Dialog from '../../Dialog';
import { useCohortContext } from '../../cohort/CohortContext';
import CustomIcon, { CustomIconType } from '../../shared/AntComponents/CustomIcon';
import { NumberedTitle } from '../../shared/AntComponents/Typography/Title';
import AssistanceServiceEligibilityCriteriaEditor from './AssistanceServiceEligibilityCriteriaEditor';
import AssistanceServiceSelectionDialog from './AssistanceServiceSelectionDialog';
import { useErrorContext } from '../../../store/ErrorContext';

interface IProps {
   assistanceServiceId: number;
   isOpen: boolean;
   handleClose: () => void;
}

const _keysLike: string[] = [assistanceServiceToEligibilityCriteriaBaseUrl];

const AssistanceServiceToManufacturerEligibilityCriteriaIndex: React.FC<IProps> = (props: IProps) => {
   const { assistanceServiceId, handleClose, isOpen } = props;
   const { httpGet, httpPost } = useApiContext();
   const [selectedEligibilityCriteria, setSelectedEligibilityCriteria] = React.useState<AssistanceServiceEligibilityCriteriaViewModel>(undefined);
   const [isEditorOpen, setIsEditorOpen] = React.useState<boolean>(false);
   const [shouldBrandBeDisabledInEditor, setShouldBrandBeDisabledInEditor] = React.useState<boolean>(false);
   const [shouldUseProvidedAssistanceServiceInEditor, setShouldUseProvidedAssistanceServiceInEditor] = React.useState(false);
   const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);
   const [bulkItems, setBulkItems] = React.useState<number[]>([]);
   const [isAssistanceServiceSelectionDialogOpen, setIsAssistanceServiceSelectionDialogOpen] = React.useState<boolean>(false);
   const { assistanceServices } = useFetchAssistanceServices(httpGet);
   const { assistanceServiceToDrugs } = useFetchAssistanceServiceToDrug(httpGet);
   const { eligibilityCriteria } = useFetchEligibilityCriteria(httpGet);
   const { drugViewModelList } = useDrugViewModel(httpGet);
   const { eligibilityCriteriaViewModel } = useEligibilityCriteriaViewModel(httpGet);
   const { cohortSummaryFromDate } = useCohortContext();
   const { removeErrors } = useErrorContext();

   const selectedAssistanceService = React.useMemo(() => {
      return assistanceServices?.find(s => s.id === assistanceServiceId);
   }, [assistanceServices, assistanceServiceId]);

   const eligibilityCriteriaViewModels = React.useMemo(() => {
      return eligibilityCriteriaViewModel?.filter(c => c.assistanceServiceId === assistanceServiceId);
   }, [eligibilityCriteriaViewModel, assistanceServiceId]);

   const { _eligibilityCriteria: aggregateEligibilityCriteria,
      _brandIdsFromDrugs: brandIdsMappedFromDrugs
   } = React.useMemo(() => {
      const _eligibilityCriteria: AssistanceServiceManufacturerEligibilityCriteria[] = [];
      let _brandIdsFromDrugs: number[] = [];
      if (assistanceServices?.length > 0 && assistanceServiceToDrugs?.length > 0 && eligibilityCriteria?.length > 0 && drugViewModelList?.length > 0) {
         const assistanceService = assistanceServices.find(s => s.id === assistanceServiceId);
         const drugAssociations = assistanceServiceToDrugs.filter(d => d.assistanceServiceId === assistanceServiceId);
         _brandIdsFromDrugs = drugViewModelList.filter(l => drugAssociations.map(association => association.drugId)?.indexOf(l.id) > -1).map(l => l.brandId);

         _brandIdsFromDrugs.forEach(brandId => {
            if (!_eligibilityCriteria.some(m => m.brandId === brandId)) {
               let drugIdsForThisBrand = drugViewModelList.filter(l => l.brandId === brandId).map(l => l.id);
               let drugCountForThisModel = drugAssociations.filter(da => drugIdsForThisBrand.indexOf(da.drugId) > -1);
               const newModel =
                  {
                     ...assistanceService,
                     brandId: brandId,
                     brandName: drugViewModelList.find(l => l.brandId === brandId).brandName,
                     hasRelatedEligibilityCriteria: eligibilityCriteria.some(criteria => criteria.brandId === brandId && criteria.assistanceServiceId === assistanceServiceId),
                     assistanceServiceToDrugCount: drugCountForThisModel?.length
                  } as AssistanceServiceManufacturerEligibilityCriteria;
               _eligibilityCriteria.push(newModel);
            }
         });
      }
      return { _eligibilityCriteria, _brandIdsFromDrugs }
   }, [assistanceServiceId, assistanceServices, assistanceServiceToDrugs,
      eligibilityCriteria, drugViewModelList]);
   
   const closeThisModal = () => {
      removeErrors({ keysLike: _keysLike });
      handleClose();
   }

   const closeEditor = () => {
      setSelectedEligibilityCriteria(undefined);
      setShouldUseProvidedAssistanceServiceInEditor(false);
      setIsEditorOpen(false);
   }

   const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
      const arr = newSelectedRowKeys.map((k) => { return Number(k) });
      setBulkItems(arr);
      setSelectedRowKeys(newSelectedRowKeys);
   }

   const rowSelection: TableRowSelection<object> = {
      selectedRowKeys,
      onChange: onSelectChange,
   };

   const onClearBulkSelection = () => {
      setBulkItems([]);
      setSelectedRowKeys([]);
   };

   const clickBulkEditButton = () => {
      setIsAssistanceServiceSelectionDialogOpen(true);
   }

   const closeAssistanceServiceSelectionDialog = () => {
      setIsAssistanceServiceSelectionDialogOpen(false);
      removeErrors({ keysLike: _keysLike });
   }

   const saveAssistanceServiceSelection = (assistanceServiceId: number) => {
      const modelsToSave: AssistanceServiceEligibilityCriteria[] = eligibilityCriteriaViewModels.filter(y => bulkItems.includes(y.id));
      modelsToSave.forEach(model => { model.assistanceServiceId = assistanceServiceId; })
      saveAssistanceServiceEligibilityCriterias(httpPost, modelsToSave, cohortSummaryFromDate)
         .then(() => {
            closeAssistanceServiceSelectionDialog();
            onClearBulkSelection(); // The selected items should drop off the grid since they've been moved to another Service so we should ensure to clear the selection of them
         })
         .catch(err => console.log(`Error Saving`, err));
   }

   const aggregateEligibilityCriteriaColumns: ColumnsType<AssistanceServiceManufacturerEligibilityCriteria> = [
      {
         title: 'Brand',
         dataIndex: 'brandName',
         key: 'brandName',
         sortDirections: ['ascend', 'descend'],
         sorter: (a, b) => stringComparer(a.brandName, b.brandName),
         defaultSortOrder: 'ascend'
      },
      {
         title: 'Drug Count',
         dataIndex: 'assistanceServiceToDrugCount',
         key: 'assistanceServiceToDrugCount',
         sortDirections: ['ascend', 'descend'],
         sorter: (a, b) => numberComparer(a.assistanceServiceToDrugCount, b.assistanceServiceToDrugCount)
      },
      {
         title: 'Has Eligibility Criteria',
         dataIndex: 'hasRelatedEligibilityCriteria',
         key: 'hasRelatedEligibilityCriteria',
         sortDirections: ['ascend', 'descend'],
         sorter: (a, b) => boolComparer(a.hasRelatedEligibilityCriteria, b.hasRelatedEligibilityCriteria),
         render: (text, record) => formatBoolean(record?.hasRelatedEligibilityCriteria)
      }
   ];

   const eligibilityCriteriaColumns: ColumnsType<AssistanceServiceEligibilityCriteriaViewModel> = [
      {
         title: 'Brand',
         dataIndex: 'brandName',
         key: 'brandName',
         sortDirections: ['ascend', 'descend'],
         sorter: (a, b) => stringComparer(a.brandName, b.brandName),
         defaultSortOrder: 'ascend'
      },
      {
         title: 'Category',
         dataIndex: 'categoryName',
         key: 'categoryName',
         sortDirections: ['ascend', 'descend'],
         sorter: (a, b) => stringComparer(a.categoryName, b.categoryName)
      },
      {
         title: 'Value',
         dataIndex: 'value',
         key: 'value',
         sortDirections: ['ascend', 'descend'],
         sorter: (a, b) => stringComparer(a.value, b.value)
      },
      {
         title: 'Notes',
         dataIndex: 'notes',
         key: 'notes',
         sortDirections: ['ascend', 'descend'],
         sorter: (a, b) => stringComparer(a.notes, b.notes)
      },
      {
         title: 'URL',
         dataIndex: 'url',
         key: 'url',
         sortDirections: ['ascend', 'descend'],
         sorter: (a, b) => stringComparer(a.url, b.url)
      },
      {
         title: 'Sort Order',
         dataIndex: 'sortOrder',
         key: 'sortOrder',
         sortDirections: ['ascend', 'descend'],
         sorter: (a, b) => numberComparer(a.sortOrder, b.sortOrder)
      },
      {
         title: <Restricted requiredRoles={[KnownSettings.ContentAdmin]}>
            <AddButton buttonText='New' onClick={() => {
               setSelectedEligibilityCriteria(undefined);
               setShouldBrandBeDisabledInEditor(false);
               setShouldUseProvidedAssistanceServiceInEditor(true);
               setIsEditorOpen(true);
            }} />
         </Restricted>,
         fixed: 'right',
         render: (text, record) =>
            <Restricted requiredRoles={[KnownSettings.ContentAdmin]}>
               <EditButton onClick={() => {
                  setSelectedEligibilityCriteria(record);
                  setShouldBrandBeDisabledInEditor(true);
                  setIsEditorOpen(true);
               }} />
            </Restricted>
      },
      Table.SELECTION_COLUMN
   ];

   const html = (
      <Dialog
         scrollingContent={true}
         title={'Assistance Service Eligibility Criteria'}
         open={isOpen}
         size='large'
         actionButtons={
            <CancelButton onClick={() => { closeThisModal(); }} />
         }>
         <NumberedTitle
            text={`Eligibility Criteria Information for Service: ${selectedAssistanceService?.assistanceServiceName}`}
            level={5} />

         <ApiErrorDisplay
            title='Error saving Assistance Service Eligiblity Criteria'
            keysLike={_keysLike}
         />

         <MinimalistTable
            rowKey={'brandId'}
            size={'small'}
            columns={aggregateEligibilityCriteriaColumns}
            data={aggregateEligibilityCriteria}
            showHeader={true}
         />
         <Divider />
         <MinimalistTable
            rowKey={'id'}
            size={'small'}
            columns={eligibilityCriteriaColumns}
            data={eligibilityCriteriaViewModels}
            showHeader={true} footer={<Row justify="end">
               <Col>
                  <Space>
                     <Restricted requiredRoles={[KnownSettings.ContentAdmin]}>
                        <ActionButton
                           buttonText='Clear Bulk Selection'
                           onClick={onClearBulkSelection}
                           disabled={bulkItems.length < 1}
                           icon={<CustomIcon type={CustomIconType.CloseOutlined} />}
                        />
                        <ActionButton
                           buttonText='Modify Assistance Service(s)'
                           disabled={bulkItems.length < 1}
                           onClick={() => clickBulkEditButton()}
                           icon={<CustomIcon type={CustomIconType.EditOutlined} />}
                        />
                     </Restricted>
                  </Space>
               </Col>
            </Row>}
            rowSelection={rowSelection}
         />

         {isAssistanceServiceSelectionDialogOpen &&
            <AssistanceServiceSelectionDialog
               isOpen={isAssistanceServiceSelectionDialogOpen}
               saveAssistanceServiceLink={saveAssistanceServiceSelection}
               cancelAssistanceServiceLink={closeAssistanceServiceSelectionDialog}
            />
         }

         {isEditorOpen &&
            <AssistanceServiceEligibilityCriteriaEditor
               closeEditor={closeEditor}
               isOpen={isEditorOpen}
               shouldBrandBeDisabled={shouldBrandBeDisabledInEditor}
               shouldAssistanceServiceBeDisabled={true}
               assistanceService={selectedAssistanceService}
               id={selectedEligibilityCriteria?.id}
               brandIdsMappedForDrugs={brandIdsMappedFromDrugs}
               shouldUseProvidedAssistanceService={shouldUseProvidedAssistanceServiceInEditor}
            />
         }
      </Dialog>
   );
   return html;
}

export default AssistanceServiceToManufacturerEligibilityCriteriaIndex;