import * as React from 'react';
import MinimalistTable from '../../shared/AntComponents/Table/MinimalistTable';
import { IUserMigrationStatus } from '../../../store/auth/UserMigrationReducer';
import { ColumnsType } from 'antd/lib/table';
import { boolComparer } from '../../../functions/comparer.functions';
import { formatBoolean } from '../../../functions/format.functions';

const UserMigrationStatusList: React.FC<{ migrationStatusRecords: IUserMigrationStatus[], hidePagingOnTable: boolean }> = (props) => {
   const { migrationStatusRecords, hidePagingOnTable } = props;

   const statusColumns: ColumnsType<IUserMigrationStatus> = [
      {
         title: 'Prior Upn',
         key: 'priorUpn',
         dataIndex: ['migrationStatus', 'priorUpn'],
      },
      {
         title: 'Updated Auth Upn',
         key: 'authUpn',
         dataIndex: ['migrationStatus', 'authUpn'],
      },
      {
         title: 'Migration Status',
         key: 'isLoading',
         dataIndex: 'isLoading',
         render: (text, record) => {
            if (record.isLoading) {
               return <p>Loading...</p>
            } else {
               if (record?.migrationStatus?.userCreationError || record?.migrationStatus?.welcomeEmailError) {
                  return <b>Error</b>;
               } else {
                  return <b>Complete!</b>;
               }
            }
         }
      },
      {
         title: 'Was User Created',
         key: 'wasCreated',
         dataIndex: ['migrationStatus', 'wasCreated'],
         sorter: (a, b) => boolComparer(a?.migrationStatus?.wasCreated, b?.migrationStatus?.wasCreated),
         sortDirections: ['ascend', 'descend'],
         render: (val) => formatBoolean(val)
      },
      {
         title: 'User Creation Error',
         key: 'userCreationError',
         dataIndex: ['migrationStatus', 'userCreationError']
      },
      {
         title: 'Was Welcome Email Sent',
         key: 'wasSentWelcomeEmail',
         dataIndex: ['migrationStatus', 'wasSentWelcomeEmail'],
         sorter: (a, b) => boolComparer(a?.migrationStatus?.wasSentWelcomeEmail, b?.migrationStatus?.wasSentWelcomeEmail),
         sortDirections: ['ascend', 'descend'],
         render: (val) => formatBoolean(val)
      },
      {
         title: 'Welcome Email Error',
         key: 'welcomeEmailError',
         dataIndex: ['migrationStatus', 'welcomeEmailError']
      }
   ]

   const html = (
      <>
         <h3>User Migration Status</h3>
         <MinimalistTable
            rowKey={'userMainId'}
            bordered
            showHeader
            columns={statusColumns}
            data={migrationStatusRecords}
            hidePaging={hidePagingOnTable}
            size={'small'} />

      </>
   )

   return html;
}

export default UserMigrationStatusList;