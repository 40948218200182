import * as React from 'react';
import EnvironmentDisplay from '../components/EnvironmentDisplay';
import VersionDisplay from '../components/VersionDisplay';

import './MainFooter.css';


const MainFooter: React.FC = () => {
   return (
      <>
         <div className="ap-footer-shelf"></div>
         <div className="ap-footer-main">
            <div className="ap-footer-right">
               <span className="ap-text-italic">
                  <VersionDisplay />
               </span>
               &nbsp;-&nbsp;&copy; {(new Date().getFullYear())} Annexus Health
            </div>
         </div>
         <div className="footer-content">
            <div className="ap-footer-left">
               <EnvironmentDisplay />
            </div>
         </div>
      </>
   );
};

export default MainFooter;