// These constants are also defined as an enum in AssistPoint.Models.Storage.FundstatusEmailProcessingEntity
export enum BatchStatusType {
   Queued,
   Processing,
   Finished
}

export interface FundStatusRecentlySentEmail {
   fundStatusEmailId: number;
   sentOn: Date;
   sentBy: string;
   uId: string;
   batchStatus?: BatchStatusType; // "Queued" | "Dequeued" | "Finished", derived from TableStorage
   sentDetails: FundStatusRecentlySentDetails[];
   emailBatches: FundStatusEmailBatch[];
}

export interface FundStatusRecentlySentDetails {
   foundationId: string;
   foundationName: string;
   programName: string;
   programId: number;
   sentFunds: FundStatusRecentlySendFunds[];
}

export interface FundStatusRecentlySendFunds {
   diseaseTypeId: number;
   diseaseTypeName: string;
   assistanceServiceName: string;
   insuranceClassNames: string;
   status: string;
}

export interface FundStatusEmailBatch {
   batchUId: string;
   batchSentOn: Date;
   batchSentBy: string;
   recipientCount: number;
   successCount: number;
   errorCount: number;
   results: FundStatusEmailResults[];
}

export interface FundStatusEmailResults {  
   fundStatusEmailRecipientId: number;
   insightlyContactId?: number;
   firstName: string;
   lastName: string;
   toAddress: string;
   beginSendOn: Date;
   endSendOn: Date;
   result: string;
}