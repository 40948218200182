import { isInRole } from '../functions/auth.functions';
import { Navigate, useLocation } from 'react-router';
import { useUserContext } from './authContext';

export type ProtectedRouteProps = {
   allowedRoles: string[];
   outlet: JSX.Element;
};

export default function ProtectedRoute({ allowedRoles, outlet }: ProtectedRouteProps) {
   const { userRoles } = useUserContext();
   const accessGranted: boolean = isInRole(userRoles, allowedRoles);
   const currentLocation = useLocation();

   if (accessGranted) {
      return outlet;
   } else {
      console.error(`User does not have access to ${currentLocation.pathname}`);
      return <Navigate to={{ pathname: '/' }} />;
   }
};
