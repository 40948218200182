import * as React from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Dialog from '../../Dialog';
import { SaveButton, DeleteButton, CancelButton } from '../../shared/Buttons';
import Spinner from '../../Spinner';
import { useFetchAssistancePrograms, saveAssistanceProgram, deleteAssistanceProgram, assistanceProgramBaseUrl } from '../../../store/program/AssistanceProgramFetcher';
import { useFoundationIdOptions } from '../../../store/program/FoundationFetcher';
import { Form, Typography } from 'antd';
import { InputField, DropdownField, TextAreaField, RadioListField, CheckboxField } from '../../shared/InputLibrary';
import { usePracticeOptions } from '../../../store/practice/PracticeFetcher'
import { AssistanceProgram, ProgramType, ConnectivityType, programTypeOptions as StoreProgramTypeOptions } from '../../../store/program/AssistanceProgramModel';
import ApiErrorDisplay from '../../ApiErrorDisplay';
import { TitleError } from '../../shared/AntComponents/Typography/Title';
import { IOptionItem } from '../../../functions/option.functions';
import { useApiContext } from '../../../store/ApiContext';
import { useErrorContext } from '../../../store/ErrorContext';
const { Title } = Typography;

interface IProps {
   isOpen: boolean;
   closeEditor: () => void;
   assistanceProgramId?: number;
};

export const connectivityTypeOptions = [{
   key: ConnectivityType.Manual,
   value: ConnectivityType.Manual,
   label: 'Manual',
} as IOptionItem
   , {
      key: ConnectivityType.OutboundDigitalApplication,
      value: ConnectivityType.OutboundDigitalApplication,
      label: 'Outbound',

   } as IOptionItem
   , {
      key: ConnectivityType.DigitalEnrollment,
      value: ConnectivityType.DigitalEnrollment,
      label: 'Digital Full',

   } as IOptionItem
   , {
      key: ConnectivityType.DigitalEnrollment15,
      value: ConnectivityType.DigitalEnrollment15,
      label: 'Internal Testing',

   } as IOptionItem
   , {
      key: ConnectivityType.RPA,
      value: ConnectivityType.RPA,
      label: 'RPA',

   } as IOptionItem
];


const dialogContentStyle = {
   height: '580px',
   maxHeight: '95vh',
}

const yupAssistanceProgramSchema = yup.object({
   id: yup.number().notRequired(),
   programName: yup.string()
      .required('Program Name is required')
      .max(256, 'Max length is 256 characters'),
   programDescription: yup.string()
      .required('Program Description is required')
      .max(1024, 'Max length is 1024 characters'),
   programType: yup.number()
      .required('Program Type is required'),
   serviceSelectionMessage: yup.string().notRequired(),
   logoUrl: yup.string()
      .url('The Resource URL must be a fully-qualified url that begins with "https://" or "https://"')
      .max(1024, 'Max length is 1024 characters')
      .notRequired(),
   isDemo: yup.boolean().notRequired(),
   canSelectAllServices: yup.boolean().notRequired(),
   practiceId: yup.number()
      .required('Practive Id is required'),
   singleServicePerApplication: yup.boolean().notRequired(),
   connectivityType: yup.number()
      .required('Connectivity Type is required'),
   interfaceId: yup.number()
      .nullable()
      //transform from empty string to null ¯\_(ツ)_/¯
      .transform((_, val) => val === '' ? null : Number(val)),
   faxNumber: yup.string()
      .max(64, 'Max length is 64 characters')
      .notRequired(),

   foundationId: yup.number()
      .when('programType', {
         is: ProgramType.Foundation,
         then: () => yup.number().required('Must select a Foundation')
      }),

});

interface IAssistanceProgramYup extends yup.Asserts<typeof yupAssistanceProgramSchema> { }
const __formId = "frmAssistanceProgram";
const _keysLike: string[] = [assistanceProgramBaseUrl];

const AssistanceProgramEditor: React.FC<IProps> = (props: IProps) => {
   const { closeEditor, isOpen, assistanceProgramId } = props;
   const { httpGet, httpPost, httpDelete } = useApiContext();
   const [isFormSaving, setIsFormSaving] = React.useState<boolean>(false);
   const { removeErrors } = useErrorContext();
   const clearErrors = () => {
      removeErrors({ keysLike: _keysLike });
   }

   const { assistancePrograms } = useFetchAssistancePrograms(httpGet);
   const [programType, setProgramType] = React.useState<number>();
   const { foundationOptions } = useFoundationIdOptions(httpGet);
   const { practiceOptions } = usePracticeOptions(httpGet, true);

   const isAssistanceProgramDigitalEnrollment = (assistanceProgramBeingEdited: AssistanceProgram): boolean => {
      return (assistanceProgramBeingEdited?.id ?? 0) > 0
   }

   const {
      control,
      handleSubmit,
      formState: { errors },
      reset
   } = useForm<IAssistanceProgramYup>({
      resolver: yupResolver<yup.AnyObject>(yupAssistanceProgramSchema),
      shouldFocusError: true,
   });

   const assistanceProgramToEdit = React.useMemo(() => {
      return assistancePrograms?.find(y => y.id === assistanceProgramId)
   }, [assistanceProgramId, assistancePrograms]);
     

   React.useEffect(() => {
      reset(assistanceProgramToEdit ?? {
         practiceId: -1
      } as AssistanceProgram);
      setIsFormSaving(false);
      setProgramType(assistanceProgramToEdit?.programType);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [assistanceProgramToEdit, isOpen]);

   const onSubmit = (data: IAssistanceProgramYup): void => {
      //console.log('--------------AssistanceProgramEditor-------------------------')
      //console.log(errors)

      //ts hoops to convert data with unknown props into assistance programs
      const item = data as unknown as AssistanceProgram;
      setIsFormSaving(true);
      saveAssistanceProgram(httpPost, item)
         .then(() => {
            handleClose();
         })
         .catch(err => console.log(`Error Saving`, err))
         .finally(() => {
            setIsFormSaving(false);
         });
   }

   const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState<boolean>(false);

   const handleDeleteClick = () => {
      setIsDeleteDialogOpen(false);
      deleteAssistanceProgram(httpDelete, assistanceProgramToEdit.id)
         .then(handleClose)
         .catch(err => console.log(`Error Deleting`, err))
   }

   const handleClose = () => {
      setIsFormSaving(false);
      setIsDeleteDialogOpen(false);
      clearErrors();
      closeEditor();
   }

   if (isFormSaving) {
      return <Spinner message='Saving Assistance Program...' />
   }

   //console.log('--------------AssistanceProgramEditor-------------------------')
   //console.log(errors)


   const html = (
      <Dialog
         scrollingContent={true}
         title={`Assistance Program Editor`}
         open={isOpen}
         size="large"
         pending={isFormSaving}
         actionButtons={
            <>
               <CancelButton
                  onClick={() => handleClose()}
                  loading={isFormSaving}
               />
               <DeleteButton
                  onClick={() => setIsDeleteDialogOpen(true)}
                  disabled={!assistanceProgramToEdit || !assistanceProgramToEdit?.id || isDeleteDialogOpen}
                  loading={isDeleteDialogOpen} />
               <SaveButton
                  formId={__formId}
                  onClick={() => { return false }}
                  disabled={Object.keys(errors).length > 0}
                  loading={isFormSaving} />
            </>
         }>
         <div style={dialogContentStyle}>

            {errors && Object.keys(errors).length > 0 &&
               <>
                  <TitleError text='Please correct the errors below' />
                  {console.log('--------------Form Errors: -------------------------')}
                  {console.log(errors)}
               </>
            }

            <ApiErrorDisplay
               title='Error saving Assistance Program'
               keysLike={_keysLike}
             />

            <Form id={__formId} onFinish={handleSubmit(onSubmit)}>
               <InputField
                  control={control}
                  name='programName'
                  label='Program Name'
                  type='text'
                  error={errors?.programName}
                  required={true}
               />
               <TextAreaField
                  control={control}
                  name='programDescription'
                  label='Program Description'
                  error={errors?.programDescription}
                  required={true}
                  rows={2}
               />
               <RadioListField
                  control={control}
                  name='programType'
                  label='Program Type'
                  error={errors?.programType}
                  options={StoreProgramTypeOptions}
                  required={true}
                  onChange={(value) => { setProgramType(Number(value)) }}
               />
               {programType === ProgramType.Foundation &&
                  <DropdownField
                     control={control}
                     name='foundationId'
                     label='Foundation'
                     error={errors?.foundationId}
                     multiple={false}
                     options={foundationOptions}
                     required={true}
                     search={true}
                  />
               }
               <TextAreaField
                  control={control}
                  name='serviceSelectionMessage'
                  label='Service Selection Message'
                  error={errors?.serviceSelectionMessage}
                  required={false}
               />
               <InputField
                  control={control}
                  name='logoUrl'
                  label='logoUrl'
                  type='text'
                  error={errors?.logoUrl}
                  required={false}
               />
               <CheckboxField
                  control={control}
                  name='isDemo'
                  label='IsDemo'
                  error={errors?.isDemo}
                  toggle={true}
                  required={false}
               />
               <CheckboxField
                  control={control}
                  name='canSelectAllServices'
                  label='Can Select All Services'
                  error={errors?.canSelectAllServices}
                  toggle={true}
                  required={false}
               />
               <DropdownField
                  control={control}
                  name='practiceId'
                  label='Practice'
                  error={errors?.practiceId}
                  multiple={false}
                  options={practiceOptions}
                  required={true}
                  search={true}
               />
               <CheckboxField
                  control={control}
                  name='singleServicePerApplication'
                  label='singleServicePerApplication'
                  error={errors?.singleServicePerApplication}
                  toggle={true}
                  required={false}
               />
               {isAssistanceProgramDigitalEnrollment(assistanceProgramToEdit) &&
                  <Title level={5}>
                     Edits to Digital Enrollment fields are not supported
                  </Title>
               }
               <RadioListField
                  control={control}
                  name='connectivityType'
                  label='Connectivity Type'
                  error={errors?.connectivityType}
                  options={connectivityTypeOptions}
                  required={(assistanceProgramToEdit?.id ?? 0) <= 0}
                  disabled={isAssistanceProgramDigitalEnrollment(assistanceProgramToEdit)}
               />
               <InputField
                  control={control}
                  name='interfaceId'
                  label='Interface Id'
                  type='number'
                  error={errors?.interfaceId}
                  disabled={isAssistanceProgramDigitalEnrollment(assistanceProgramToEdit)}
               />
               <InputField
                  control={control}
                  placeholder='xxx-xxx-xxxx'
                  name='faxNumber'
                  label='Fax Number'
                  type='text'
                  error={errors?.faxNumber}
                  disabled={false}
               />
            </Form>
         </div>

         <Dialog
            title='CONFIRM DELETE'
            open={isDeleteDialogOpen}
            style={{ maxWidth: '550px' }}
            actionButtons={
               <>
                  <CancelButton onClick={() => setIsDeleteDialogOpen(false)} />
                  <DeleteButton
                     disabled={isFormSaving}
                     onClick={() => handleDeleteClick()} />
               </>
            }
         >
            {`Are you sure you want to Delete the Assistance Program (${assistanceProgramToEdit?.id}) ${assistanceProgramToEdit?.programName || ''}?`}
         </Dialog>

      </Dialog>
   )
   return html;
}

export default AssistanceProgramEditor;

