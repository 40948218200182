import { mutate } from 'swr';
import { getFormattedDateForApi } from '../../functions/format.functions';
import { TPost } from '../../functions/httpClient';
import { CohortItemAnswerRequest, CohortItemReviewRequest } from './CohortItemModel';
import { findCohortSummaryUrl } from './CohortSummaryFetcher';
import { programSummaryDetailGetUrl } from './ProgramSummaryDetailFetcher';
import { programSummaryGetUrl } from './ProgramSummaryFetcher';

export const cohortItemBaseUrl = `api/cohort/CohortItem`;
export const cohortItemSaveAnswersUrl = `${cohortItemBaseUrl}/SaveAnswers`;
export const cohortItemSaveReviewsUrl = `${cohortItemBaseUrl}/SaveReviews`;

export const saveCohortAnswers = async (httpPost: TPost, answerRequest: CohortItemAnswerRequest, fromDate: Date) => {
   const result = await httpPost(cohortItemSaveAnswersUrl, answerRequest)
      .then(() => {
         mutate(programSummaryDetailGetUrl(answerRequest.cohortId));
         mutate(programSummaryGetUrl(answerRequest.cohortId));
         mutate([findCohortSummaryUrl, { fromDate: getFormattedDateForApi(fromDate) }]);
      });

   return result;
}

export const saveCohortReviews = async (httpPost: TPost, reviewRequest: CohortItemReviewRequest, fromDate: Date) => {
   const result = await httpPost(cohortItemSaveReviewsUrl, reviewRequest)
      .then(() => {
         mutate(programSummaryDetailGetUrl(reviewRequest.cohortId));
         mutate(programSummaryGetUrl(reviewRequest.cohortId));
         mutate([findCohortSummaryUrl, { fromDate: getFormattedDateForApi(fromDate) }]);
      });

   return result;
}
