import * as React from 'react';
import { formatDate } from 'src/functions/time.functions';
import { formatShortDate, getNextDayOfWeek } from '../../functions/format.functions';
import { useApiContext } from '../../store/ApiContext';
import { useErrorContext } from '../../store/ErrorContext';
import { cohortBaseUrl, cohortSaveUrl, saveCohort, useCohortTypesOptions } from '../../store/cohort/CohortFetcher';
import { Cohort } from '../../store/cohort/CohortModel';
import { CohortSummary } from '../../store/cohort/CohortSummaryModel';
import ApiErrorDisplay from '../ApiErrorDisplay';
import Dialog from '../Dialog';
import { BasicDatePickerField, BasicDropdownField } from '../shared/BasicInputLibrary';
import { CancelButton, SaveButton } from '../shared/Buttons';
import { useCohortContext } from './CohortContext';

interface IProps {
   cohortSummary?: CohortSummary;
   isOpen: boolean;
   closeEditor: () => void;
}

const getNewCohort = (cohortSummary?: CohortSummary): Cohort => {
   const newCohort: Cohort = {
      cohortTypeId: undefined,
      startDate: cohortSummary?.startDate,
      endDate: cohortSummary?.endDate,
      status: "New"
   };

   let startDate: Date, endDate: Date;

   if (cohortSummary) {
      startDate = formatShortDate(cohortSummary.startDate);
      startDate.setDate(startDate.getDate() + 7);
      endDate = new Date(startDate);
      endDate.setDate(endDate.getDate() + 6);
   } else {
      startDate = getNextDayOfWeek(new Date(), 5);
      endDate = new Date(startDate);
      endDate.setDate(startDate.getDate() + 6);
   }

   newCohort.cohortTypeId = cohortSummary?.cohortTypeId;
   newCohort.startDate = formatShortDate(startDate);
   newCohort.endDate = formatShortDate(endDate);
   return newCohort;
}

const errorKeys = { keys: [cohortBaseUrl, cohortSaveUrl] };

const CohortSummaryEditor: React.FC<IProps> = (props) => {
   const { httpGet, httpPost } = useApiContext();
   const { cohortSummaryFromDate } = useCohortContext();
   const { removeErrors } = useErrorContext();
   const { cohortTypeOptions } = useCohortTypesOptions(httpGet);
   const [isFormSaving, setIsFormSaving] = React.useState<boolean>();
   const [newCohort, setNewCohort] = React.useState<Cohort>(getNewCohort(props.cohortSummary));

   const clearErrors = () => {
      removeErrors(errorKeys);
   }

   const handleValueChange = (targetName: string, targetValue: any) => {
      setNewCohort({
         ...newCohort,
         [targetName]: targetValue
      });
   }

   const handleSaveClick = () => {
      setIsFormSaving(true);
      saveCohort(httpPost, newCohort, cohortSummaryFromDate)
         .then(() => handleClose())
         .catch((e) => {
            console.error(`${e?.message.substring(0, 41)}...`);
         })
         .finally(() => {
            setIsFormSaving(false);
         });
   }

   const handleClose = () => {
      setIsFormSaving(false);
      clearErrors();
      props.closeEditor();
   }

   const html = (
      <Dialog
         scrollingContent={true}
         title={`Add New Cohort`}
         open={props.isOpen}
         size="small"
         actionButtons={
            <>
               <CancelButton onClick={() => handleClose()} />
               <SaveButton onClick={() => handleSaveClick()}
                  disabled={isFormSaving}
                  loading={isFormSaving} />
            </>
         }>

         <ApiErrorDisplay
            title='Error'
            keys={errorKeys.keys} />

         <BasicDropdownField
            label='Cohort Type'
            clearable={true}
            value={newCohort?.cohortTypeId}
            onChange={(e) => handleValueChange("cohortTypeId", Number(e))}
            name='specialtyFilter'
            search={true}
            options={cohortTypeOptions} />

         <BasicDatePickerField
            containerStyle={{ width: 150 }}
            name='startDate'
            label='Start Date'
            value={newCohort?.startDate}
            clearable={true}
            onChange={(e) => handleValueChange("startDate", formatDate(e as string))}
         />

         <BasicDatePickerField
            containerStyle={{ width: 150 }}
            name='endDate'
            label='End Date'
            value={newCohort?.endDate}
            clearable={true}
            onChange={(e) => handleValueChange("endDate", formatDate(e as string))}
         />

      </Dialog>
   )
   return html;
}

export default CohortSummaryEditor;
