import { ColumnsType, TableProps } from 'antd/lib/table';
import * as React from 'react';
import Restricted from '../../../auth/Restricted';
import { boolComparer, numberComparer, stringComparer } from '../../../functions/comparer.functions';
import { formatBoolean } from '../../../functions/format.functions';
import { useBrandOptions } from '../../../store/drug/BrandFetcher';
import { DrugViewModel } from '../../../store/drug/DrugModel';
import { drugBaseUrl, useDrugViewModel } from '../../../store/drug/DrugFetcher';
import { useManufacturerOptions } from '../../../store/program/ManufacturerFetcher';
import { KnownSettings } from '../../../store/SettingsModel';
import HighlightSearchText from '../../HighlightSearchText';
import SearchResultsTable from '../../shared/AntComponents/Table/SearchResultsTable';
import { BasicDropdownField, BasicInputField } from '../../shared/BasicInputLibrary';
import { AddButton, EditButton } from '../../shared/Buttons';
import Spinner from '../../Spinner';
import DrugEditor from './DrugEditor';
import { useApiContext } from '../../../store/ApiContext';
import { HttpVerb, KeyWithVerb, useErrorContext } from '../../../store/ErrorContext';
import ApiErrorDisplay from '../../ApiErrorDisplay';

const _keysWithVerb: KeyWithVerb[] = [{ key: drugBaseUrl, verb: HttpVerb.GET }]

const DrugIndex: React.FC = () => {
   const { httpGet } = useApiContext();
   const { removeErrors } = useErrorContext();
   const { drugViewModelList, isLoading } = useDrugViewModel(httpGet);

   const { manufacturerOptions } = useManufacturerOptions(httpGet);
   const { brandOptions } = useBrandOptions(httpGet);

   const [currentPage, setCurrentPage] = React.useState(1);
   const [currentPageSize, setCurrentPageSize] = React.useState(25);
   const handleOnChange: TableProps<DrugViewModel>['onChange'] = (pagination, filters, sorter, extra) => setCurrentPage(pagination?.current);

   const [nameFilter, setNameFilter] = React.useState<string>('');
   const [manufacturerFilter, setManufacturerFilter] = React.useState<number>();
   const [brandFilter, setBrandFilter] = React.useState<number>();
   const [selectedItem, setSelectedItem] = React.useState<DrugViewModel>(undefined);
   const [isEditorOpen, setIsEditorOpen] = React.useState<boolean>(false);

   React.useEffect(() => {
      removeErrors({ keysWithVerb: _keysWithVerb });
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []) // remove Errors on initial render

   const filterName = (m: DrugViewModel): boolean => {
      return !nameFilter || m.drugName?.toLowerCase().indexOf(nameFilter.toLowerCase()) > -1 ? true : false;
   }

   const filterManufacturer = (m: DrugViewModel): boolean => {
      return !manufacturerFilter || m.manufacturerId === manufacturerFilter ? true : false;
   }

   const filterBrand = (m: DrugViewModel): boolean => {
      return !brandFilter || m.brandId === brandFilter ? true : false;
   }

   const filterGridContent = (records: DrugViewModel[]): DrugViewModel[] => {
      if (records && records?.length > 0) {
         return records.filter(y =>
            filterName(y) &&
            filterManufacturer(y) &&
            filterBrand(y));
      }
      return [];
   }

   const filteredData = React.useMemo(() => {
      if (drugViewModelList?.length > 0) {
         return filterGridContent(drugViewModelList);
      }
      // for "filterGridContent" - we actually need the ones in this dependency array
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [drugViewModelList, brandFilter, manufacturerFilter, nameFilter])

   const handleCloseEditorClick = () => {
      setIsEditorOpen(false);
      setSelectedItem(undefined);
   }

   if (isLoading) {
      return <Spinner />
   }


   const tableColumns: ColumnsType<DrugViewModel> = [
      {
         title: 'Id',
         dataIndex: 'id',
         sorter: (a, b) => numberComparer(a.id, b.id),
         sortDirections: ['ascend', 'descend', 'ascend'],
         width: 80,
      },
      {
         title: 'Drug Name',
         dataIndex: 'drugName',
         sorter: (a, b) => stringComparer(a.drugName, b.drugName),
         sortDirections: ['ascend', 'descend', 'ascend'],
         render: (text, record) =>
            <HighlightSearchText searchString={nameFilter} targetString={record.drugName} />
      },
      {
         title: 'Manufacturer',
         dataIndex: 'manufacturerName',
         defaultSortOrder: 'descend',
         sortDirections: ['ascend', 'descend', 'ascend'],
         sorter: (a, b) => stringComparer(a.manufacturerName, b.manufacturerName),
         render: (text, record) => {
            if (manufacturerFilter === record.manufacturerId) {
               return <HighlightSearchText searchString={record.manufacturerName} targetString={record.manufacturerName} />
            }
            return text;
         }
      },
      {
         title: 'Demo Only',
         dataIndex: 'demoOnly',
         sorter: (a, b) => boolComparer(a.demoOnly, b.demoOnly),
         sortDirections: ['ascend', 'descend', 'ascend'],
         width: 80,
         render: (text, record) => formatBoolean(record.demoOnly)
      },
      {
         title: 'Generic Name',
         dataIndex: 'genericName',
         sorter: (a, b) => stringComparer(a.genericName, b.genericName),
         sortDirections: ['ascend', 'descend', 'ascend'],
      },
      {
         title: 'Brand Name',
         dataIndex: 'brandName',
         defaultSortOrder: 'descend',
         sortDirections: ['ascend', 'descend', 'ascend'],
         sorter: (a, b) => stringComparer(a.brandName, b.brandName),
         render: (text, record) => {
            if (brandFilter === record.brandId) {
               return <HighlightSearchText searchString={record.brandName} targetString={record.brandName} />
            }
            return text;
         }
      },
      {
         title: 'Route',
         dataIndex: 'routeDescription',
         sorter: (a, b) => stringComparer(a.genericName, b.routeDescription),
         sortDirections: ['ascend', 'descend', 'ascend'],
         width: 125,
      },
      {
         title: 'NDC Prefix',
         dataIndex: 'ndcPrefix',
         sorter: (a, b) => stringComparer(a.ndcPrefix, b.ndcPrefix),
         sortDirections: ['ascend', 'descend', 'ascend'],
         width: 100,
      },
      {
         title: () =>
            <Restricted requiredRoles={[KnownSettings.ContentAdmin]}><AddButton
               title='Add New Drug'
               buttonText='New'
               onClick={() => {
                  setSelectedItem(undefined);
                  setIsEditorOpen(true)
               }} />
            </Restricted>,
         width: 100,
         fixed: 'right',
         render: (text, record) => <Restricted requiredRoles={[KnownSettings.ContentAdmin]}>
            <EditButton
               title='Edit'
               onClick={() => {
                  setIsEditorOpen(true);
                  setSelectedItem(record);
               }}
            />
         </Restricted>
      },
   ];

   const html = (
      <>
         <ApiErrorDisplay
            title={'Error'}
            keysWithVerb={_keysWithVerb}
         />

         <SearchResultsTable
            rowkey={'id'}
            onChange={handleOnChange}
            columns={tableColumns}
            data={filteredData}
            currentPage={currentPage}
            currentPageSize={currentPageSize}
            setCurrentPageSize={setCurrentPageSize}
            titleText='Drugs'
            fixedHeader={true}
            size={'small'}
            additionalComponents={[
               <BasicInputField
                  label='Drug Name'
                  name='drugNameSearchString'
                  placeholder='Search by Drug Name'
                  value={nameFilter}
                  onChange={(e) => setNameFilter(String(e))} />,
               <BasicDropdownField
                  name='manufacturerSearchString'
                  label='Manufacturer'
                  placeholder={' - Filter By Manufacturer -'}
                  value={manufacturerFilter}
                  options={manufacturerOptions}
                  search={true}
                  clearable={true}
                  onChange={(e) => setManufacturerFilter(e ? Number(e) : undefined)}
               />,
               <BasicDropdownField
                  name='brandSearchString'
                  label='Brand'
                  placeholder={' - Filter By Brand -'}
                  value={brandFilter}
                  options={brandOptions}
                  search={true}
                  clearable={true}
                  onChange={(e) => setBrandFilter(e ? Number(e) : undefined)}
               />
            ]} />

         {isEditorOpen &&
            <DrugEditor isOpen={isEditorOpen}
               closeEditor={() => handleCloseEditorClick()}
               drugId={selectedItem?.id} />
         }
      </>
   )
   return html;
}

export default DrugIndex;