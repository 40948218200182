import * as React from 'react';
import useSWR from 'swr';
import { Parameter, ParameterClass, ParameterClassViewModel, PlanType, PlanParameter, Scorecard, ScorecardTemplate } from './ScorecardModel';
import { HttpError, TGet, TPost } from '../../../functions/httpClient'
import { IStatus } from '../../models';
import { numberComparer } from '../../../functions/comparer.functions';

export const scorecardBaseUrl = `api/adparo/scorecard`;
const parameterGetUrl = `${scorecardBaseUrl}/GetScorecardParameters`;
const parameterClassGetUrl = `${scorecardBaseUrl}/GetScorecardParameterClasses`;
export const saveScorecardUrl = `${scorecardBaseUrl}/SaveScorecard`;
const getPlanTypesUrl = `${scorecardBaseUrl}/GetPlanTypes`;
const getPlanParemetersUrl = `${scorecardBaseUrl}/GetScorecardPlanParemeters`;

const swrOptions = {
   refreshInterval: 0,
   revalidateOnFocus: false,
   dedupingInterval: 60000
};

export function useFetchParameters(httpGet: TGet): IStatus & { parameters: Parameter[] } {
   const { data, isValidating, error } = useSWR<Parameter[], HttpError>(
      parameterGetUrl,
      httpGet,
      { ...swrOptions }
   );

   return {
      parameters: data,
      isLoading: !error && !data && isValidating,
      error: error?.message
   };
}
export function useFetchParameterClasses(httpGet: TGet): IStatus & { parameterClasses: ParameterClass[] } {
   const { data, isValidating, error } = useSWR<ParameterClass[], HttpError>(
      parameterClassGetUrl,
      httpGet,
      { ...swrOptions }
   );

   return {
      parameterClasses: data,
      isLoading: !error && !data && isValidating,
      error: error?.message
   };
}

export function useFetchPlanParameters(httpGet: TGet): IStatus & { planParameters: PlanParameter[] } {
   const { data, isValidating, error } = useSWR<PlanParameter[], HttpError>(
      getPlanParemetersUrl,
      httpGet,
      { ...swrOptions }
   );

   return {
      planParameters: data,
      isLoading: !error && !data && isValidating,
      error: error?.message
   };
}

export const saveScorecard = async (httpPost: TPost, scorecard: Scorecard) => {
   const result = await httpPost(saveScorecardUrl, scorecard)
   return result;
}

export function useScorecardTemplates(httpGet: TGet): { scorecardTemplates: ScorecardTemplate[] } {
   const { parameters } = useFetchParameters(httpGet);
   const { parameterClasses } = useFetchParameterClasses(httpGet);
   const { planParameters } = useFetchPlanParameters(httpGet);
   const { planTypes } = useGetPlanTypes(httpGet);

   const models = React.useMemo(() => {
      if (!parameters || !parameterClasses || !planParameters || !planTypes) return;

      const tempModels = planTypes.map((planType): ScorecardTemplate => {
         const parameterIds = planParameters.filter(y => y.planTypeId === planType.id).map(y => y.parameterId);
         const theseParameters = parameters.filter(y => parameterIds.includes(y.id));
         const theseClasses = parameterClasses.filter(y => theseParameters.some(p => p.parameterClassId === y.id));

         let template: ScorecardTemplate = { planTypeId: planType.id, classes: [] };

         theseClasses.map((paramClass) => {
            template.classes.push({
               ...paramClass,
               parameters: theseParameters.filter(y => y.parameterClassId === paramClass.id).sort((a, b) => numberComparer(a.sequence, b.sequence))
            } as ParameterClassViewModel)
         });

         template.classes = template.classes.sort((a, b) => numberComparer(a.sequence, b.sequence));

         return template;
      });

      return tempModels;

   }, [parameters, parameterClasses, planParameters, planTypes]);

   return { scorecardTemplates: models };
}

export function useScorecardTemplate(httpGet: TGet, scorecardTemplateId: number) {
   const { scorecardTemplates } = useScorecardTemplates(httpGet);

   const model = React.useMemo(() => {
      if (scorecardTemplates && scorecardTemplateId) {
         return scorecardTemplates.find(y => y.planTypeId === scorecardTemplateId);
      }
   }, [scorecardTemplates, scorecardTemplateId]);

   return { scorecardTemplate: model }
}

export function useGetPlanTypes(httpGet: TGet) {
   const { data, isValidating, error } = useSWR<PlanType[], HttpError>(
      getPlanTypesUrl,
      httpGet,
      { ...swrOptions }
   );

   return {
      planTypes: data,
      isLoading: !error && !data && isValidating,
      error: error?.message
   };
}