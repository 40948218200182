import { formatShortDate } from './format.functions';

export const arraysEqualIgnoreCase = (
   a: Array<any>,
   b: Array<any>
): boolean => {
   if (!a || !b) return false;
   if (a.length !== b.length) return false;

   const uniqueValues = new Set([...a, ...b]);
   for (const v of uniqueValues) {
      const aCount = a.filter(e => e === v).length;
      const bCount = b.filter(e => e === v).length;
      if (aCount !== bCount) return false;
   }
   return true;
}

// This doesn't seem to work for an array of number if the numbers are out of order
export const arraysEqual = (
   a: Array<any>,
   b: Array<any>,
): boolean => {
   if (a === undefined && b === undefined) return true;
   if (a === undefined || b === undefined) return false;

   if (a === b) return true;
   if (a === null || b === null) return false;
   if (a.length !== b.length) return false;

   // ignoring the order of the elements inside the array

   for (var i = 0; i < a.length; ++i) {
      if (!objectsEqual(a, b))
         return false;
   }
   return true;
};

export const numericArraysEqual = (
   a: number[],
   b: number[],
): boolean => {
   if (a === undefined && b === undefined) return true;
   if (a === undefined || b === undefined) return false;

   if (a === b) return true;
   if (a === null || b === null) return false;
   if (a.length !== b.length) return false;

   if (a.every(y => b.includes(y))) {
      return true;
   }
   return false;
};

export const objectsEqual = (
   a: any,
   b: any
): boolean => {
   return Object.keys(a).length === Object.keys(b).length
      && Object.keys(a).every(p => a[p] === b[p]);
}

export const deepEqual = (
   a: any,
   b: any
): boolean => {
   const ok = Object.keys, tx = typeof a, ty = typeof b;
   return a && b && tx === 'object' && tx === ty ? (
      ok(a).length === ok(b).length &&
      ok(a).every(key => deepEqual(a[key], b[key]))
   ) : (a === b);
}

/**
 * V1: Slightly altered.
 * Deep copy function for TypeScript.
 * @param T Generic type of target/copied value.
 * @param target Target value to be copied.
 * @see Source project, ts-deepcopy https://github.com/ykdr2017/ts-deepcopy
 * @see Code pen https://codepen.io/erikvullings/pen/ejyBYg
 */
export const deepCopy = <T>(target: T): T => {
   if (target === null) {
      return target;
   }
   if (target instanceof Date) {
      return new Date(target.getTime()) as any;
   }
   if (target instanceof Array) {
      const cp = [] as any[];
      (target as any[]).forEach((v) => { cp.push(v); });
      return cp.map((n: any) => deepCopy<any>(n)) as any;
   }
   //V1: deviated from original code here to use Object.keys
   if (typeof target === 'object' && Object.keys(target)?.length > 0) {
      const cp = { ...(target as { [key: string]: any }) } as { [key: string]: any };
      Object.keys(cp).forEach(k => {
         cp[k] = deepCopy<any>(cp[k]);
      });
      return cp as T;
   }
   return target;
};

export const stringsEqual = (
   a: any,
   b: any
): boolean => {
   const ok = Object.keys, tx = typeof a, ty = typeof b;
   return a && b && tx === 'object' && tx === ty ? (
      ok(a).length === ok(b).length &&
      ok(a).every(key => deepEqual(a[key], b[key]))
   ) : (a === b);
}

/**
 * takes two strings, splits "searchFor" on spaces and searches for these words within
 * "searchIn".  Return true if found, otherwise false.
 * @param searchFor string may be empty, one word or multiple words
 * @param searchIn string in which to search for searchFor string(s)
 * @returns true if found, otherwise false
**/
export const areStringsInString = (searchFor: string, searchIn: string): boolean => {
   let lowerCaseSearchFor: string = "";
   let lowerCaseSearchIn: string = "";

   if (searchFor) lowerCaseSearchFor = searchFor.toLowerCase().trim();
   if (searchIn) lowerCaseSearchIn = searchIn.toLowerCase().trim();

   if (lowerCaseSearchFor === "") return true;
   if (lowerCaseSearchIn === "") return false;

   return lowerCaseSearchFor.split(' ').every((word) => lowerCaseSearchIn.indexOf(word) > -1);
}

//like sql, start and end are included
export const isTodayBetween = (start: Date, end: Date): boolean => {
   const startDate = formatShortDate(start);
   const endDate = formatShortDate(start);
   const today = formatShortDate(new Date());

   return startDate >= today || endDate <= today;
}

//https://stackoverflow.com/a/53438248
export const enumElementCount = (enumName: any): number => {
   let count = 0
   for (let item in enumName) {
      if (isNaN(Number(item))) count++
   }
   return count
}

//treat an array like a circular index - ask for an element
//greater than or equal the array length & get the first.
export const getNextCircularIndex = (currentIndex: number, array: any[]): number => {
   if (!currentIndex || !array)
      return undefined;

   const length: number = array.length;
   const nextIndex: number = (currentIndex % array.length + length) % length;
   return nextIndex;
}

export const addDate = (dt: Date, days: number): Date => {
   if (!dt)
      return null;

   // Add days to given date
   var date = dt;
   date.setDate(date.getDate() + days);
   return date;
};

export const leftPadDayOrMonth = (dayOrMonth: number | string): string => {
   const valueAsString = dayOrMonth.toString();
   if (valueAsString.length === 1)
      return '0' + valueAsString;
   else
      return valueAsString;
}

//https://stackoverflow.com/a/62765924
export const groupBy = <T, K extends keyof any>(arr: T[], key: (i: T) => K) => {
   return arr.reduce((groups, item) => {
      (groups[key(item)] ||= []).push(item);
      return groups;
   }, {} as Record<K, T[]>);
}

export const replaceWhitespace = (inStr: string, replaceStr: string = '') => inStr?.replace(/\s/g, '');

