import { HttpError, TGet, TPut } from '../../functions/httpClient';
import { IStatus } from '../models';
import useSWR, { mutate } from 'swr';
import { PowerBIReportToPractice } from './PowerBIExtermalReportModel';

export const powerBIExternalReportBaseUrl = `api/analytics/PowerBIExternalReport`;
const powerBIReportUpdateOrderUrl = `${powerBIExternalReportBaseUrl}/UpdateReportOrder`;

const swrOptions = {
   refreshInterval: 0,
   revalidateOnFocus: false,
   dedupingInterval: 60000
};

export function useFetchPowerBIExternalReportList(httpGet: TGet): IStatus & { powerBIExternalReportList: PowerBIReportToPractice[] } {
   const { data, isLoading, error } = useSWR<PowerBIReportToPractice[], HttpError>(
      powerBIExternalReportBaseUrl,
      httpGet,
      { ...swrOptions }
   );

   return {
      powerBIExternalReportList: data,
      isLoading: isLoading,
      error: error?.message
   };
}

export const updateReportOrder = async (httpPut: TPut, orderedItems: number[]) => {
   const result = await httpPut(powerBIReportUpdateOrderUrl, orderedItems)
      .then(() => mutate(powerBIExternalReportBaseUrl));
   return result;
}
