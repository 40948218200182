import * as React from "react";
import { CreateUserStatus } from "./UserModel";
import UserMigrationReducer, { ADD_STATUS, IUserMigrationStatus, MigrationState, REMOVE_STATUS } from "./UserMigrationReducer";

export interface IUserMigrationContextProps {
   migrationStatusList: IUserMigrationStatus[];
   addStatus: (userMainId: number, migrationStatus: CreateUserStatus, isLoading: boolean) => void;
   removeStatus: (userMainId: number) => void;
}

interface IUserMigrationContextProviderProps {
   children: React.ReactNode;
}

export const UserMigrationContext = React.createContext<IUserMigrationContextProps>({} as IUserMigrationContextProps);
export const useUserMigrationContext = () => React.useContext(UserMigrationContext);

const initialUserMigrationState: MigrationState = { migrationStatusRecords: [] };

export const UserMigrationContextProvider: React.FC<IUserMigrationContextProviderProps> = (props) => {

   const [userMigrationState, userMigrationDispatch] = React.useReducer(UserMigrationReducer, initialUserMigrationState);
   const userMigrationRecords = userMigrationState.migrationStatusRecords;

   const addStatus = (userMainId: number, migrationStatus: CreateUserStatus, isLoading: boolean) => {
      userMigrationDispatch({
         type: ADD_STATUS,
         payload: { userMainId: userMainId, migrationStatus: migrationStatus, isLoading: isLoading } as IUserMigrationStatus
      });
   }

   const removeStatus = (userMainId: number) => {
      userMigrationDispatch({
         type: REMOVE_STATUS,
         payload: { userMainId: userMainId } as IUserMigrationStatus
      });
   }

   return <UserMigrationContext.Provider
      value={{
         migrationStatusList: userMigrationRecords,
         addStatus:  addStatus,
         removeStatus: removeStatus
      }}>
      {props.children}
   </UserMigrationContext.Provider>
}