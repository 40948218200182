import useSWR from "swr";
import { HttpError, TGet } from "../../functions/httpClient";
import { FsaSubscriptionChangesModel } from "./FsaSubscriptionChangesModel";

export const fsaSubscriptionChangesBaseUrl = 'api/fsa/FundStatusSubscriptionChanges';

const swrOptions = {
   refreshInterval: 0,
   revalidateOnFocus: false,
   dedupingInterval: 60000
};

export const useFetchFsaSubscriptionChanges = (httpGet: TGet) => {
   const { data, isValidating, error } = useSWR<FsaSubscriptionChangesModel[], HttpError>(
      fsaSubscriptionChangesBaseUrl,
      httpGet,
      {
         ...swrOptions,
         refreshInterval: 300000, //refresh every 5 minutes
         dedupingInterval: 0     //disable deduping
      }
   );

   return {
      fsaSubscriptionChanges: data,
      isLoading: !error && !data && isValidating,
      error: error
   };
}