import { ColumnsType, TableProps } from 'antd/lib/table';
import * as React from 'react';
import Restricted from '../../../auth/Restricted';
import { numberComparer, stringComparer } from '../../../functions/comparer.functions';
import { useApiContext } from '../../../store/ApiContext';
import { genericDrugBaseUrl, useFetchGenericDrug } from '../../../store/drug/GenericDrugFetcher';
import { GenericDrug } from '../../../store/drug/GenericDrugModel';
import { HttpVerb, KeyWithVerb, useErrorContext } from '../../../store/ErrorContext';
import { KnownSettings } from '../../../store/SettingsModel';
import ApiErrorDisplay from '../../ApiErrorDisplay';
import HighlightSearchText from '../../HighlightSearchText';
import SearchResultsTable from '../../shared/AntComponents/Table/SearchResultsTable';
import { BasicInputField } from '../../shared/BasicInputLibrary';
import { AddButton, EditButton } from '../../shared/Buttons';
import Spinner from '../../Spinner';
import GenericDrugEditor from './GenericDrugEditor';


const _keysWithVerb: KeyWithVerb[] = [{ key: genericDrugBaseUrl, verb: HttpVerb.GET }]
const GenericDrugIndex: React.FC = () => {
   const { httpGet } = useApiContext();
   const { removeErrors } = useErrorContext();
   const { genericDrugs, isLoading } = useFetchGenericDrug(httpGet);
   const [selectedItem, setSelectedItem] = React.useState<GenericDrug>(null);
   const [isEditorOpen, setIsEditorOpen] = React.useState<boolean>(false);
   const [nameFilter, setNameFilter] = React.useState<string>('');
   const [currentPage, setCurrentPage] = React.useState(1);
   const [currentPageSize, setCurrentPageSize] = React.useState(25);

   React.useEffect(() => {
      removeErrors({ keysWithVerb: _keysWithVerb });
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []) // remove Errors on initial render

   const filterName = (m: GenericDrug): boolean => {
      return !nameFilter || m.genericName?.toLowerCase().indexOf(nameFilter.toLowerCase()) > -1 ? true : false;
   }

   const filterGridContent = (records: GenericDrug[]): GenericDrug[] => {
      if (records && records?.length > 0) {
         return records.filter(y => filterName(y));
      }
      return [];
   }

   const filteredData = React.useMemo(() => {
      if (genericDrugs?.length > 0) {
         return filterGridContent(genericDrugs);
      }
      // for "filterGridContent" - we actually need the two in this dependency array
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [genericDrugs, nameFilter])

   const handleCloseEditorClick = () => {
      setIsEditorOpen(false);
      setSelectedItem(undefined);
   }

   if (isLoading) return <Spinner />

   const handleOnChange: TableProps<GenericDrug>['onChange'] = (pagination, filters, sorter, extra) => setCurrentPage(pagination?.current);
   const handleNameFilterChange = (filter: string) => setNameFilter(filter);

   const tableColumns: ColumnsType<GenericDrug> = [
      {
         title: 'Id',
         dataIndex: 'id',
         key: 1,
         width: 80,
         sorter: (a, b) => numberComparer(a.id, b.id),
         sortDirections: ['ascend', 'descend', 'ascend'],
         defaultSortOrder: 'descend'
      },
      {
         title: 'Name',
         dataIndex: 'genericName',
         sorter: (a, b) => stringComparer(a.genericName, b.genericName),
         sortDirections: ['ascend', 'descend', 'ascend'],
         render: (text, record) =>
            <HighlightSearchText searchString={nameFilter} targetString={record?.genericName} />
      },
      {
         title: () =>
            <Restricted requiredRoles={[KnownSettings.ContentAdmin]}>
               <AddButton
                  title='Add New Generic Drug'
                  buttonText='New' onClick={() => {
                     setSelectedItem({} as GenericDrug);
                     setIsEditorOpen(true);
                  }} />
            </Restricted>,
         dataIndex: 'actionButtons',
         width: 100,
         fixed: 'right',
         render: (text, record) => <Restricted requiredRoles={[KnownSettings.ContentAdmin]}>
            <EditButton
               buttonText=''
               onClick={() => {
                  setSelectedItem(record);
                  setIsEditorOpen(true);
               }} />
         </Restricted>
      }
   ];

   const html = (
      <>
         <ApiErrorDisplay
            title={'Error'}
            keysWithVerb={_keysWithVerb}
         />

         <SearchResultsTable
            bordered={true}
            rowkey={'id'}
            onChange={handleOnChange}
            columns={tableColumns}
            data={filteredData}
            currentPage={currentPage}
            currentPageSize={currentPageSize}
            setCurrentPageSize={setCurrentPageSize}
            titleText='Generic Drug'
            fixedHeader={true}
            size={'small'}
            additionalComponents={[
               <BasicInputField
                  label='Generic Name'
                  placeholder='Search by Generic Name'
                  value={nameFilter}
                  onChange={(e) => handleNameFilterChange(String(e))} />
            ]} />

         {isEditorOpen &&
            <GenericDrugEditor
            isOpen={isEditorOpen}
            closeEditor={() => handleCloseEditorClick()}
            genericDrugId={selectedItem?.id} />
         }
      </>
   )
   return html;
}
export default GenericDrugIndex;
