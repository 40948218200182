import { ColumnsType } from 'antd/lib/table';
import * as React from 'react';
import Restricted from '../../auth/Restricted';
import { numberComparer, stringComparer } from '../../functions/comparer.functions';
import { useApiContext } from '../../store/ApiContext';
import { KnownSettings } from '../../store/SettingsModel';
import { usePracticeFederationByPracticeId } from '../../store/auth/PracticeFederationFetcher';
import { PracticeFederation } from '../../store/auth/PracticeFederationModel';
import MinimalistTable from '../shared/AntComponents/Table/MinimalistTable';
import { AddButton, EditButton } from '../shared/Buttons';
import PracticeFederationEditor from './PracticeFederationEditor';

interface IProps {
   practiceId: number;
   scrollYValue: string;
}

export const PracticeFederationEmbeddedList: React.FC<IProps> = (props) => {
   const { httpGet } = useApiContext();
   const { practiceId } = props;
   const { practiceFederations } = usePracticeFederationByPracticeId(httpGet, practiceId);
   const [isPracticeFederationEditorOpen, setIsPracticeFederationEditorOpen] = React.useState<boolean>(false);
   const [practiceFederationToEdit, setPracticeFederationToEdit] = React.useState<PracticeFederation>(undefined);

   const onPracticeSubscriptionEditorClose = () => {
      setIsPracticeFederationEditorOpen(false);
      setPracticeFederationToEdit(undefined);
   }

   const practiceFederationColumns: ColumnsType<PracticeFederation> = [
      {
         title: 'Id',
         dataIndex: 'id',
         key: 'id',
         sorter: (a, b) => numberComparer(a.id, b.id),
         sortDirections: ['ascend', 'descend'],
         defaultSortOrder: 'ascend'
      },
      {
         title: 'Domain',
         dataIndex: 'domain',
         key: 'domain',
         sorter: (a, b) => stringComparer(a.domain, b.domain),
         sortDirections: ['ascend', 'descend'],
         defaultSortOrder: 'ascend'
      },
      {
         title: <Restricted requiredRoles={[KnownSettings.UserAdmin]}>
            <AddButton
               title='New Domain'
               buttonText='New'
               onClick={() => {
                  setPracticeFederationToEdit(undefined);
                  setIsPracticeFederationEditorOpen(true);
               }}
            />
         </Restricted>
         ,
         render: (text, record) =>
            <Restricted requiredRoles={[KnownSettings.UserAdmin]}>
               <EditButton
                  onClick={() => {
                     setPracticeFederationToEdit(record);
                     setIsPracticeFederationEditorOpen(true);
                  }}
                  title='Edit' />
            </Restricted>
      }
   ]

   return (
      <>
         <MinimalistTable
            rowKey='id' // we need to use the subscriptionId because we're displaying all subscriptions regardless, so using the practiceSubscriptionId may result in key errors due to being empty
            data={practiceFederations}
            columns={practiceFederationColumns}
            size='small'
            showHeader={true}
            scrollY={props.scrollYValue}
         />
         {isPracticeFederationEditorOpen &&
            <PracticeFederationEditor
               practiceIdForNew={props.practiceId}
               closeEditor={onPracticeSubscriptionEditorClose}
               isOpen={isPracticeFederationEditorOpen}
               practiceFederationId={practiceFederationToEdit?.id}
            />
         }
      </>
   );

}

export default PracticeFederationEmbeddedList