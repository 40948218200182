import { yupResolver } from '@hookform/resolvers/yup';
import { Col, Form, Row } from 'antd';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { validateEmail } from '../../../functions/form.validators';
import { useApiContext } from '../../../store/ApiContext';
import { useFetchUserMain } from '../../../store/auth/UserMainFetcher';
import ApiErrorDisplay from '../../ApiErrorDisplay';
import { ActionButton, CancelButton, SaveButton } from '../../shared/Buttons';
import { CheckboxField, DropdownField, InputField } from '../../shared/InputLibrary';
import { usePracticeOptions } from '../../../store/practice/PracticeFetcher';
import { UserMainDetail } from '../../../store/auth/UserMainModel';
import { migrateUserWithUpnEdit, userMigrationBaseUrl } from '../../../store/auth/UserMigrationFetcher';
import Dialog from '../../Dialog';
import { useFetchUserProfiles } from '../../../store/practice/UserProfileFetcher';
import { BasicFieldWrapper } from '../../shared/BasicInputLibrary';
import { UserMigrationViewModel } from '../../../store/auth/UserMigrationModel';
import UserMigrationStatusList from './UserMigrationStatusList';
import { useUserMigrationContext } from '../../../store/auth/UserMigrationContext';
import { CreateUserStatus } from '../../../store/auth/UserModel';
import { HttpError } from '../../../functions/httpClient';
import { stringComparer } from '../../../functions/comparer.functions';
import { useErrorContext } from '../../../store/ErrorContext';

const dialogContentStyle = {
   minHeight: '200px'
}

interface IProps {
   closeEditor: () => void;
   isOpen: boolean;
   userMainId: number;
}
const __formId = "frmUpnEditor";
const _keysLike: string[] = [userMigrationBaseUrl];

const UpnEditor: React.FC<IProps> = (props) => {
   const { userMainId, isOpen, closeEditor } = props;
   const { httpGet, httpPost } = useApiContext();
   const { removeErrors } = useErrorContext();
   const { addStatus, removeStatus, migrationStatusList } = useUserMigrationContext();
   const { data } = useFetchUserMain(httpGet);
   const { userProfiles } = useFetchUserProfiles(httpGet);
   const { practiceOptions } = usePracticeOptions(httpGet);

   const [editItem, setEditItem] = React.useState<UserMainDetail>();
   const [isFormSaving, setIsFormSaving] = React.useState(false);
   const [hasAttemptedSave, setHasAttemptedSave] = React.useState(false);

   const upnEditorYupSchema = yup.object({
      userMainId: yup.number().notRequired(),
      upn: yup.string().required()
         .test('upn', 'A valid Email Address is required as a UPN', value => validateEmail(value))
         .test('upn', 'Original UPN and New UPN must not match', value => editItem?.upn !== value),
      emailAddress: yup.string().required().test('emailAddress', 'A valid Email Address is required', value => validateEmail(value)),
      sendInvite: yup.boolean().required(),
      practiceId: yup.number().when('sendInvite', {
         is: true,
         then: () => yup.number().required('A practice must be selected')
      }),
      userProfileIdForPractice: yup.number().notRequired()
   });

   interface IUpnEditorYup extends yup.Asserts<typeof upnEditorYupSchema> { }

   const { control, handleSubmit, setValue, getValues, trigger, formState: { errors }, reset } = useForm<IUpnEditorYup>({
      resolver: yupResolver<yup.AnyObject>(upnEditorYupSchema),
      shouldFocusError: true,
   });

   React.useEffect(() => {
      if (data && userProfiles) {
         const item = data?.find(vm => vm.id === userMainId);
         setEditItem(item);
         setIsFormSaving(false);
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [userMainId, data, isOpen]);

   const practiceUserProfileOptions = () => {
      if (!editItem || !userProfiles) return [];

      return practiceOptions.filter(y =>
         userProfiles.filter(up => editItem?.userProfileIds?.includes(up.userProfileId))
            .map(up => up.practiceId)
            .includes(y.value as number));
   }

   React.useEffect(() => {
      reset({
         userMainId: editItem?.id,
         upn: editItem?.upn,
         emailAddress: editItem?.emailAddress,
         sendInvite: false
      } as IUpnEditorYup);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [editItem, userMainId, isOpen]);

   const onSubmit = (data: IUpnEditorYup): void => {
      setIsFormSaving(true);
      addStatus(userMainId, { priorUpn: editItem?.upn } as CreateUserStatus, true);
      setHasAttemptedSave(true);

      const dataToSave = data as UserMigrationViewModel;

      migrateUserWithUpnEdit(httpPost, dataToSave)
         .then((statusData) => {
            addStatus(userMainId, statusData, false);
         })
         .catch((error: HttpError) => {
            // In case there was an entirely unhandled exception, we'll try to grab just the exception message and display it in the grid
            let errorMessage = error?.message;
            if (errorMessage && errorMessage.length > 100 && errorMessage.indexOf(',"Message":') > -1) {
               errorMessage = errorMessage.substring(
                  errorMessage.indexOf('"Message":'),
                  ((errorMessage.length - 2) - errorMessage.indexOf(',"Message":')) // the "-2" next to length is meant to cut off the final bracket from the JSON
               );
            }
            addStatus(userMainId, { priorUpn: editItem?.upn, userCreationError: errorMessage } as CreateUserStatus, false)
         })
         .finally(() => {
            setIsFormSaving(false);
         })
   }

   const handleClose = () => {
      setEditItem(undefined);
      removeErrors({ keysLike: _keysLike });
      removeStatus(userMainId);
      closeEditor();
   }

   const html = (
      <Dialog
         scrollingContent={true}
         title={'Migrate User'}
         open={props.isOpen}
         size="large"
         actionButtons={
            <>
               {!hasAttemptedSave && <>
                  <CancelButton onClick={() => {
                     handleClose();
                  }} />
                  <SaveButton
                     buttonText={'Migrate User'}
                     formId={__formId}
                     onClick={() => null}
                     disabled={Object.keys(errors).length > 0}
                     loading={isFormSaving} />
               </>}
               {hasAttemptedSave && <ActionButton buttonText={'Close'} onClick={handleClose} />}
            </>
         }>
         <div style={dialogContentStyle}>

            <ApiErrorDisplay
               title='Error saving User'
               keysLike={_keysLike}
            />

            {!hasAttemptedSave && <Form id={__formId} onFinish={handleSubmit(onSubmit)}>
               <Row gutter={[16, 16]}>
                  <Col span={10}>
                     <BasicFieldWrapper
                        label='Original Upn'
                        field={editItem?.upn} />
                  </Col>
                  <Col span={8}>
                     <InputField
                        control={control}
                        error={errors?.upn}
                        name='upn'
                        label='New User Main UPN'
                        required={true}
                     />
                  </Col>
                  <Col>
                     <CheckboxField
                        control={control}
                        error={errors?.sendInvite}
                        name='sendInvite'
                        label='Send Invite'
                        onChange={(e) => {
                           trigger('sendInvite'); // This one ensures that the "getValues()?.sendInvite" updates appropriately
                           if (!(e as boolean)) {
                              trigger('practiceId') // This one ensures that we re-validate the form when unchecking, which then enables the "Migrate" button even if you had a validation error for PracticeId prior.  If we attempt to validate when checking, we get the validation error prior to the User attempting to Migrate, which is "noisy" and unnecessary.
                           }
                        }}
                        tooltip={"Note for non-federated accounts: User will be locked out of their account until they utilize a 'Password Reset' link."}
                     />
                  </Col>
               </Row>
               {getValues()?.sendInvite === true &&
                  <Row gutter={[16, 16]}>
                     <Col span={10}>
                        <BasicFieldWrapper
                           label='Welcome Email Recipient (UserMain.EmailAddress)'
                           field={editItem?.emailAddress} />
                     </Col>
                     {practiceUserProfileOptions() &&
                        <Col span={12}>
                           <DropdownField
                              required
                              control={control}
                              error={errors?.practiceId}
                              name='practiceId'
                              label='Practice'
                              options={practiceUserProfileOptions()}
                              multiple={false}
                              onChange={(e) => {
                                 setValue('userProfileIdForPractice',
                                    userProfiles?.find(y => editItem.userProfileIds.includes(y.userProfileId) && y.practiceId === e)?.userProfileId
                                 );
                              }}
                              tooltip={'Practice selection is used for the Welcome & Change Password email.'}
                           />
                        </Col>
                     }
                  </Row>
               }
            </Form>}
            {hasAttemptedSave && <UserMigrationStatusList
               migrationStatusRecords={migrationStatusList?.sort((a, b) => stringComparer(a?.migrationStatus?.priorUpn, b?.migrationStatus?.priorUpn))}
               hidePagingOnTable={true}
            />}
         </div>
      </Dialog>
   );

   return html;

}
export default UpnEditor;