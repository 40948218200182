import { theme, ThemeConfig } from 'antd';
import colorWheel from './ColorWheel';

export const defaultTheme: ThemeConfig = {
   algorithm: theme.defaultAlgorithm,
   token: {
      borderRadius: 4,
      //colorPrimary: '#71277a' //creates weird button *shadow
      fontFamily: "Roboto,'sans-serif'",
      fontSize: 14,
      //colorPrimary: colorWheel.tangerineOrange,
      //colorPrimaryBorder: colorWheel.tangerineOrange,
      colorError: colorWheel.red,
      colorErrorBorder: colorWheel.red,
      colorWarning: colorWheel.red,
      colorWarningBorder: colorWheel.red,
      colorBgContainerDisabled: "rgba(245, 245, 245, 0.8)",
      colorTextDisabled: "rgba(0, 0, 0, 0.60)"
      //used on table headers and row hover
      //colorFillAlter: "rgba(250, 250, 250, 0.2)"
   },
   components: {
      Modal: {
         paddingContentHorizontalLG: 4,
         paddingLG: 4,
         paddingMD: 4
      },
      Spin: {
         colorPrimary: colorWheel.plumPurple
      },
      Form: {
         fontSize: 14
      },
      Typography: {
         fontFamilyCode: "Roboto,'sans-serif'",
      },
      Tag: {
         //   colorFillTertiary: 'rgba(250, 250, 250, 0.04)'
         colorFillAlter: "rgba(255, 99, 71, 0.5)"
      },
      Button: {
         // primary colors
         colorPrimary: colorWheel.tangerineOrange,
         colorPrimaryBorder: colorWheel.tangerineOrange,
         colorPrimaryHover: 'rgba(242, 131,0,0.5)',
         colorPrimaryActive: colorWheel.orange3,
         // danger colors
         colorErrorHover: 'rgba(255, 0, 0, 0.5)',
         // default
         colorBgBase: colorWheel.mediumGrey,
         colorBorder: colorWheel.mediumGrey,
      },
      Breadcrumb: {
         fontSize: 14,
         colorBgBase: 'rgba(0,0,0,0)',
         colorBgTextHover: 'rgba(0,0,0,0)',
         linkColor: colorWheel.plumPurple,
         separatorMargin: 4,
         lastItemColor: colorWheel.graniteGrey
      },
      Collapse: {
         colorTextHeading: colorWheel.white,
         colorTextDisabled: colorWheel.white
      }
   }
}
