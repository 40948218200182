import { yupResolver } from '@hookform/resolvers/yup';
import { Col, Divider, Form, Row } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import ChangePasswordButton from '../../../auth/ChangePasswordButton';
import Restricted from '../../../auth/Restricted';
import { formatDateTimeString, formatShortDateString } from '../../../functions/format.functions';
import { useApiContext } from '../../../store/ApiContext';
import { inviteUser, inviteUsersUrl } from '../../../store/auth/UserFetcher';
import { usePracticeBasedPracticeSubscriptionViewModel } from '../../../store/practice/PracticeSubscriptionFetcher';
import { PracticeSubscriptionViewModel } from '../../../store/practice/PracticeSubscriptionModel';
import { saveUserProfile, userProfileBaseUrl } from '../../../store/practice/UserProfileFetcher';
import { UserProfile, UserProfileViewModel } from '../../../store/practice/UserProfileModel';
import { useUserRoleOptions } from '../../../store/practice/UserRoleFetcher';
import { KnownSettings } from '../../../store/SettingsModel';
import ApiErrorDisplay from '../../ApiErrorDisplay';
import Dialog from '../../Dialog';
import MinimalistTable from '../../shared/AntComponents/Table/MinimalistTable';
import { TitleError } from '../../shared/AntComponents/Typography/Title';
import { BasicCheckboxField, BasicFieldWrapper, BasicInputField } from '../../shared/BasicInputLibrary';
import { ActionButton, CancelButton, SaveButton } from '../../shared/Buttons';
import { CheckboxField, DatePickerField, DropdownField, InputField } from '../../shared/InputLibrary';
import { provisionPracticeUser, provisionPracticeUserUrl } from '../../../store/auth/UserFetcher';
import { CreateNewPracticeUser } from '../../../store/auth/UserModel';
import { useUserProfilePracticeOptions } from '../../../store/practice/PracticeFetcher';
import { validateEmail, validatePhoneOrFax } from '../../../functions/form.validators';
import { formatPracticeName } from '../../../store/practice/PracticeModel';
import { HttpVerb, KeyWithVerb, useErrorContext } from '../../../store/ErrorContext';
import { ADPARO_OMEGA_ROLEID } from '../../../store/practice/UserRoleModel';
import { useFetchCallQueue } from '../../../store/practice/RingCallQueueFetcher';


interface IUserProfileEditorProps {
   isEditorReadOnly: boolean;
   closeEditor: () => void;
   isEditorOpen: boolean;
   profileToEdit: UserProfileViewModel;

   dialogTitle?: string;

   // Currently only used for creating a new UserProfile (provisioning)
   // Provide a function to execute after successful creation of the User
   onSubmitSuccess?: (user: UserProfile) => void;

   userMainId?: number;
}

const dialogContentStyle = {
   height: '580px',
   maxHeight: '95vh',
}

const userManagementYupSchema = yup.object({
   userProfileId: yup.number().notRequired(),
   practiceId: yup.number().required('Practice is required'),
   externalObjectId: yup.string().notRequired(),
   externalNameIdentifier: yup.string().notRequired(),
   //CR-743: JH -> Removed this field, we do not want to require userprofile.ExternalUpn 
   //externalUpn: yup.string().required().test('externalUPN', 'A valid Email Address is required as a UPN', value => validateEmail(value)),
   externalFullName: yup.string().notRequired(),
   externalCity: yup.string().notRequired(),
   externalState: yup.string().notRequired(),
   externalDirSyncEnabled: yup.boolean().notRequired(),
   firstName: yup.string().required(), // wasn't required on UserManagementEditor (edit existing), was required on CreateNew flows for Support
   lastName: yup.string().required(), // wasn't required on UserManagementEditor (edit existing), was required on CreateNew flows for Support
   title: yup.string().notRequired(),

   // wasn't required on UserManagementEditor (edit existing), was required on CreateNew flows for Support
   emailAddress: yup.string().required().test('emailAddress', 'A valid Email Address is required', value => validateEmail(value)),

   phone: yup.string()
      .test('phone', 'Invalid phone number, must be 10 numeric digits, and not start with 1 or 0',
         (p) => validatePhoneOrFax(p)),
   phoneExt: yup.string().notRequired(),
   fax: yup.string()
      .test('fax', 'Invalid fax number, must be 10 numeric digits, and not start with 1 or 0.',
         (f) => validatePhoneOrFax(f)),
   isAdmin: yup.boolean().notRequired(),
   isDisabled: yup.boolean().notRequired(),
   isFollowedByAllowed: yup.boolean().notRequired(),
   touVersion: yup.number().notRequired(),
   lastLogin: yup.date().notRequired(),
   created: yup.date().notRequired(),
   modified: yup.date().notRequired(),
   timeZoneName: yup.string().notRequired(),
   disableReason: yup.string().notRequired(),
   userRoles: yup.array().min(1, "At least one User Role must be selected").required("required"),
});

interface IUserManagementYup extends yup.Asserts<typeof userManagementYupSchema> { }

const __formId = "formUserManagementEditor";
const _keys: string[] = [provisionPracticeUserUrl]; // used for new users
const _keysWithVerb: KeyWithVerb[] = [ // used for existing users
   { key: userProfileBaseUrl, verb: HttpVerb.POST },
   { key: inviteUsersUrl, verb: HttpVerb.POST }
]
const AP_SYSTEM_EXTERNAL_UPN = 'apSystem';

const UserProfileEditor: React.FC<IUserProfileEditorProps> = (props) => {

   const {
      isEditorOpen,
      isEditorReadOnly,
      closeEditor,
      profileToEdit,
      onSubmitSuccess,
      dialogTitle,
      userMainId,
   } = props;

   const isExistingUser = profileToEdit && profileToEdit.userProfileId;
   const isUserAPSystem = profileToEdit && profileToEdit.externalUpn === AP_SYSTEM_EXTERNAL_UPN

   const [isFormSaving, setIsFormSaving] = React.useState<boolean>(false);
   const [invitingUser, setInvitingUser] = React.useState(false);
   const [delayInvite, setDelayInvite] = React.useState(true); // Only used when creating new UserProfile

   const { httpGet, httpPost } = useApiContext();
   const { userRoleOptions } = useUserRoleOptions(httpGet);
   const { practiceSubscriptionsViewModels } = usePracticeBasedPracticeSubscriptionViewModel(httpGet);
   const { practiceOptions } = useUserProfilePracticeOptions(httpGet, userMainId);
   const { callQueue } = useFetchCallQueue(httpGet);
   const { control, handleSubmit, formState: { errors }, reset, watch, setValue, } = useForm<IUserManagementYup>({
      resolver: yupResolver<yup.AnyObject>(userManagementYupSchema),
      shouldFocusError: true,
   });
   const watchPracticeId = watch('practiceId');
   const { removeErrors } = useErrorContext();
   const clearErrors = () => {
      removeErrors({ keys: _keys, keysWithVerb: _keysWithVerb });
   }

   React.useEffect(() => {
      reset(profileToEdit);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [profileToEdit]);

   React.useEffect(() => {
      if (watchPracticeId) {
         const matchingPractice = callQueue?.find(y => y.practiceId === watchPracticeId);
         if (profileToEdit.userRoles?.includes(ADPARO_OMEGA_ROLEID)) {
            // Any change in Practice results in Phone/Fax resetting, when matching Practice is available then we use that data
            setValue('phone', matchingPractice?.phone);
            setValue('fax', matchingPractice?.fax);
         }
      }
      // React recommends callQueue, profileToEdit.userRoles, etc.
      // but we genuinely only want this effect to execute when a User changes a Practice selection
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [watchPracticeId]);

   const activePracticeSubscriptions = React.useMemo(() => {
      let result: PracticeSubscriptionViewModel[] = [];
      if (practiceSubscriptionsViewModels && profileToEdit?.practiceId) {
         result = practiceSubscriptionsViewModels.filter(p => p.practiceId === profileToEdit.practiceId && p.active === true);
      }
      return result;
   }, [practiceSubscriptionsViewModels, profileToEdit])

   const handleClose = () => {
      clearErrors();
      closeEditor();
   }

   const onSubmit = (data: IUserManagementYup): void => {
      setIsFormSaving(true);
      const userProfile = data as UserProfile;

      const onFinally = () => { setIsFormSaving(false); }

      // new users need "provisioned"
      if (isExistingUser) {
         saveUserProfile(httpPost, userProfile)
            .then(handleClose)
            .catch(err => console.error('Error Saving', err))
            .finally(onFinally)
      } else {
         const newPracticeUser = {
            ...userProfile,
            externalUpn: userProfile.externalUpn ?? userProfile.emailAddress,
            userMainId: userMainId,
            delayInvite: delayInvite
         } as CreateNewPracticeUser;

         provisionPracticeUser(httpPost, newPracticeUser)
            .then((user) => {
               handleClose();
               onSubmitSuccess && onSubmitSuccess(user);
            })
            .catch(err => console.error('Error Provisioning', err))
            .finally(onFinally);
      }
   }

   const handleInviteUser = (userMainId: number) => {
      setInvitingUser(true);
      inviteUser(httpPost, userMainId)
         .catch(err => console.error('Error Inviting User', err))
         .finally(() => {
            setInvitingUser(false);
         })
   }

   const columns: ColumnsType<PracticeSubscriptionViewModel> = [
      {
         title: 'Friendly Name',
         dataIndex: 'subscriptionFriendlyName',
         key: 1
      },
      {
         title: 'Description',
         dataIndex: 'subscriptionDescription',
         key: 2
      }
   ];

   const html = (
      <Dialog
         scrollingContent={true}
         title={dialogTitle}
         open={isEditorOpen}
         size='large'
         style={dialogContentStyle}
         actionButtons={
            <>
               <CancelButton onClick={() => handleClose()} />
               {!isEditorReadOnly &&
                  <SaveButton
                     formId={__formId}
                     disabled={Object.keys(errors).length > 0}
                     loading={isFormSaving}
                     onClick={() => null} />
               }
            </>
         }>

         <div style={dialogContentStyle}>

            {errors && Object.keys(errors).length > 0 && <>
               <TitleError
                  text='Please correct the errors below' />
               {console.log('--------------Form Errors: -------------------------')}
               {console.log(errors)}
            </>
            }

            <ApiErrorDisplay
               title='Error saving User'
               keys={!isExistingUser && _keys}
               keysWithVerb={isExistingUser && _keysWithVerb}
            />

            <Form id={__formId} onFinish={handleSubmit(onSubmit)}>
               <Divider>User Information</Divider>
               <Row gutter={[16, 16]}>
                  <Col span={4}>
                     <InputField
                        control={control}
                        error={errors?.userProfileId}
                        name='userProfileId'
                        label='UserId'
                        disabled={true}
                     />
                  </Col>
                  <Col span={8}>
                     {profileToEdit?.userProfileId && // for existing users we'll display the UPN off of their UserMain
                        <BasicInputField
                           name={'userUpn'}
                           label={'User UPN'}
                           value={profileToEdit?.userMain?.upn}
                           disabled
                           required
                        />
                     }

                     {/* 
                        //CR-743: JH -> Removed this field, we do not want to show userprofile.ExternalUpn in this field
                        //New UsersProfiles will have their UserMain.Upn fed in on this property and its used on the server as their Auth UPN
                     {!profileToEdit?.userProfileId &&
                        <InputField
                           control={control}
                           error={errors?.externalUpn}
                           name='externalUpn'
                           label='User UPN'
                           disabled={userMainId !== undefined} // Net New User being added directly to a Practice
                           required
                        />
                     } */}
                  </Col>
                  <Col span={12}>
                     {isExistingUser && profileToEdit?.practiceName &&
                        <BasicFieldWrapper
                           label={'Practice'}
                           field={formatPracticeName(profileToEdit.practiceName, profileToEdit.practiceId)}
                        />
                     }
                     {isExistingUser && !profileToEdit?.practiceName && profileToEdit?.practiceId &&
                        <InputField
                           control={control}
                           error={errors?.practiceId}
                           name='practiceId'
                           label='PracticeId'
                           disabled={true}
                        />
                     }
                     {!isExistingUser && !profileToEdit?.practiceId &&
                        <DropdownField
                           control={control}
                           error={errors?.practiceId}
                           name='practiceId'
                           label='PracticeId'
                           options={practiceOptions}
                           tooltip={'Practices that this User already has a Profile with are not displayed in this list.  Changing Practices resets Phone/Fax'}
                           required
                        />
                     }
                  </Col>
               </Row>
               <Row gutter={[16, 16]}>
                  <Col span={6}>
                     <InputField
                        control={control}
                        error={errors?.firstName}
                        name='firstName'
                        label='First Name'
                        disabled={isEditorReadOnly || isUserAPSystem}
                        required
                     />
                  </Col>
                  <Col span={6}>
                     <InputField
                        control={control}
                        error={errors?.lastName}
                        name='lastName'
                        label='Last Name'
                        disabled={isEditorReadOnly || isUserAPSystem}
                        required
                     />
                  </Col>
                  <Col span={6}>
                     <InputField
                        control={control}
                        error={errors?.emailAddress}
                        name='emailAddress'
                        label='User Email Address'
                        disabled={isEditorReadOnly || isUserAPSystem}
                        required
                     />
                  </Col>
                  <Col span={6}>
                     <InputField
                        control={control}
                        error={errors?.title}
                        name='title'
                        label='User Profile Title'
                        disabled={isEditorReadOnly || isUserAPSystem}
                     />
                  </Col>
               </Row>
               <Row gutter={[16, 16]}>
                  <Col span={6}>
                     <InputField
                        control={control}
                        error={errors?.phone}
                        name='phone'
                        label='Phone'
                        disabled={isEditorReadOnly || isUserAPSystem}
                        tooltip={'Changing Practices resets Phone'}
                     />
                  </Col>
                  <Col span={6}>
                     <InputField
                        control={control}
                        error={errors?.phoneExt}
                        name='phoneExt'
                        label='Phone Ext'
                        disabled={isEditorReadOnly || isUserAPSystem}
                     />
                  </Col>
                  <Col span={6}>
                     <InputField
                        control={control}
                        error={errors?.fax}
                        name='fax'
                        label='Fax'
                        disabled={isEditorReadOnly || isUserAPSystem}
                        tooltip={'Changing Practices resets Fax'}
                     />
                  </Col>
                  <Col span={6}>
                     <CheckboxField
                        control={control}
                        error={errors?.title}
                        name='isFollowedByAllowed'
                        label='Is Followed By Allowed'
                        disabled={isEditorReadOnly || isUserAPSystem}
                     />
                  </Col>
               </Row>
               {isExistingUser && <>
                  <Row gutter={[16, 16]}>
                     <Col span={6}>
                        <CheckboxField
                           control={control}
                           error={errors?.externalDirSyncEnabled}
                           name='externalDirSyncEnabled'
                           label='ExternalDirSyncEnabled'
                           disabled={true}
                        />
                     </Col>
                     <Col span={8}>
                        <InputField
                           control={control}
                           error={errors?.externalObjectId}
                           name='externalObjectId'
                           label='ExternalObjectId'
                           disabled={true}
                        />
                     </Col>
                     <Col span={8}>
                        <InputField
                           control={control}
                           error={errors?.externalNameIdentifier}
                           name='externalNameIdentifier'
                           label='ExternalNameIdentifier'
                           disabled={true}
                        />
                     </Col>
                  </Row>
                  <Row gutter={[16, 16]}>
                     <Col span={6}>
                        <InputField
                           control={control}
                           error={errors?.touVersion}
                           name='touVersion'
                           label='Terms of Use Version'
                           disabled={true}
                        />
                     </Col>
                     <Col span={6}>
                        <InputField
                           control={control}
                           error={errors?.timeZoneName}
                           name='timeZone'
                           label='Time Zone'
                           disabled={true}
                        />
                     </Col>
                     <Col span={6}>
                        <DatePickerField
                           control={control}
                           error={errors?.created}
                           name='created'
                           label='Created On'
                           disabled={true}
                        />
                     </Col>
                     <Col span={6}>
                        <DatePickerField
                           control={control}
                           error={errors?.modified}
                           name='modified'
                           label='Modified On'
                           disabled={true}
                        />
                     </Col>
                  </Row>
                  <Row gutter={[16, 16]}>
                     <Col span={6}>
                        <div className='label'><label>Last Login</label></div>
                        <div><span><b>{formatDateTimeString(profileToEdit?.lastLogin, true, true)}</b></span></div>
                     </Col>
                     {profileToEdit?.userMain?.id &&
                        <Restricted requiredRoles={[KnownSettings.UserAdmin]}>
                           <Col span={6}>
                              <div className='label'><label>Invite Sent</label></div>
                              <div><span><b>{formatShortDateString(profileToEdit?.userMain?.inviteSentOn)}</b></span></div>
                           </Col>
                           <Col span={6}>
                              {(!profileToEdit?.userMain?.inviteSentOn || (profileToEdit?.lastLogin < profileToEdit?.userMain?.inviteSentOn)) &&
                                 <ActionButton
                                    title={profileToEdit?.userMain?.inviteSentOn ? 'Re-send Invite' : 'Invite User'}
                                    buttonText={profileToEdit?.userMain?.inviteSentOn ? 'Re-send Invite' : 'Invite User'}
                                    onClick={() => handleInviteUser(profileToEdit?.userMain?.id)}
                                    loading={invitingUser}
                                    disabled={invitingUser}
                                 />
                              }
                           </Col>
                           <Col span={6}>
                              {profileToEdit?.userMain?.auth0Id?.includes('auth0') &&
                                 <ChangePasswordButton userMainId={profileToEdit?.userMain?.id} showButtonText={true} />
                              }
                           </Col>
                        </Restricted>
                     }
                  </Row>
               </>}
               <br />
               {isExistingUser &&
                  <div className='modal-section-bg'>
                     <Divider>Salesforce Fields</Divider>
                     <Row gutter={[16, 16]}>
                        <Col span={8}>
                           <BasicInputField
                              name='insightlyContact.title'
                              label='Salesforce Title'
                              value={profileToEdit?.insightlyContact?.title}
                              disabled={true}
                           />
                        </Col>
                        <Col span={8}>
                           <BasicInputField
                              value={profileToEdit?.insightlyContact?.role}
                              name='insightlyContact.role'
                              label='Salesforce Role'
                              disabled={true}
                           />
                        </Col>
                        <Col span={8}>
                           <BasicInputField
                              value={profileToEdit?.insightlyOrganization?.accountManager}
                              name='insightlyOrganization.accountManager'
                              label='Organization Account Manager'
                              disabled={true}
                           />
                        </Col>
                     </Row>
                     <Row gutter={[16, 16]}>
                        <Col span={8}>
                           <BasicInputField
                              value={profileToEdit?.insightlyContact?.emailAddress}
                              name='insightlyContact.emailAddress'
                              label='Salesforce Email Address'
                              disabled={true}
                           />
                        </Col>
                        <Col span={8}>
                           <BasicInputField
                              value={profileToEdit?.insightlyContact?.phoneMobile}
                              name='insightlyContact.phoneMobile'
                              label='Salesforce Mobile Phone'
                              disabled={true}
                           />
                        </Col>
                        <Col span={8}>
                           <BasicInputField
                              value={profileToEdit?.insightlyContact?.inactiveContact === 0 ? "Active" : "Inactive"} //AH-3431
                              name='insightlyContact.inactiveContact'
                              label='Inactive Contact'
                              disabled={true}
                           />
                        </Col>
                     </Row>
                     <Row gutter={[16, 16]}>
                        <Col span={8}>
                           <BasicInputField
                              value={profileToEdit?.insightlyContact?.featureEnhancCom}
                              name='insightlyContact.featureEnhancCom'
                              label='Feature Enhancement Communication'
                              disabled={true}
                           />
                        </Col>
                        <Col span={8}>
                           <BasicInputField
                              value={profileToEdit?.insightlyContact?.fundStatusCom}
                              name='insightlyContact.fundStatusCom'
                              label='Fund Status Communication'
                              disabled={true}
                           />
                        </Col>
                        <Col span={8}>
                           <BasicInputField
                              value={profileToEdit?.insightlyContact?.salesforceContactId}
                              name='insightlyContact.salesforceContactId'
                              label='Salesforce Contact Id'
                              disabled={true}
                           />
                        </Col>
                     </Row>
                  </div>
               }
               <Divider>User Configuration</Divider>
               <Row gutter={[16, 16]}>
                  <Col span={2}>
                     <CheckboxField
                        control={control}
                        error={errors?.isAdmin}
                        name='isAdmin'
                        label='Admin'
                        disabled={isEditorReadOnly || isUserAPSystem}
                     />
                  </Col>
                  {isExistingUser && <>
                     <Col span={2}>
                        <CheckboxField
                           control={control}
                           error={errors?.isDisabled}
                           name='isDisabled'
                           label='Disabled'
                           disabled={isEditorReadOnly || isUserAPSystem}
                        />
                     </Col>

                     <Col span={14}>
                        <InputField
                           control={control}
                           error={errors?.disableReason}
                           name='disableReason'
                           label='Disabled Reason'
                           disabled={isEditorReadOnly || isUserAPSystem}
                        />
                     </Col>
                  </>}
                  <Col span={6}>
                     <DropdownField
                        control={control}
                        error={errors?.userRoles}
                        name='userRoles'
                        label='User Role'
                        options={userRoleOptions}
                        disabled={isEditorReadOnly || isUserAPSystem}
                        multiple={true}
                        required
                     />
                  </Col>
                  {!isExistingUser &&
                     <BasicCheckboxField
                        name='delayInvite'
                        label='Delay Invite'
                        value={delayInvite}
                        checked={delayInvite}
                        onChange={e => setDelayInvite(e as boolean)}
                     />
                  }
               </Row>
            </Form>
            {isExistingUser && <>
               <Divider>Active Subscriptions</Divider>
               <MinimalistTable
                  rowKey={'id'}
                  columns={columns}
                  data={activePracticeSubscriptions}
                  showTitle={false}
                  titleLevel={5}
                  showHeader={true}
                  bordered={true}
                  size='small'
               />
            </>}
         </div>
      </Dialog>
   );

   return html;
};

export default UserProfileEditor;
