import useSWR from 'swr'; 
import { HttpError, TGet } from '../../functions/httpClient';
import { IStatus } from '../models';
import { ProgramSummaryDetail } from './ProgramSummaryDetailModel';

export const programSummaryDetailBaseUrl = `api/cohort/programsummarydetail`;
export const programSummaryDetailGetUrl = (cohortId: number) => `${programSummaryDetailBaseUrl}/${cohortId}`;

const swrOptions = {
   refreshInterval: 0,
   revalidateOnFocus: false,
   dedupingInterval: 60000
};

export function useFetchProgramSummaryDetail(httpGet: TGet, cohortId: number): IStatus & { results: ProgramSummaryDetail[] } {
   const shouldFetch = cohortId ? true : false;
   const { data, isValidating, error } = useSWR<ProgramSummaryDetail[], HttpError>(
      shouldFetch ? programSummaryDetailGetUrl(cohortId) : null,
      httpGet,
      { ...swrOptions }
   );

   return {
      results: data,
      isLoading: !error && !data && isValidating,
      error: error?.message
   };
}
