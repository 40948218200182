import { Space, Tabs } from 'antd';
import React from 'react';
import ContentClassificationXRefList from './ContentClassificationXRefList';
import ContentClassificationXRefChangeLogList from './ContentClassificationXRefChangeLogList';
import { useParams } from 'react-router';

export const ContentClassificationIndex: React.FC = () => {
   //if cmd is in url, then set changelog to the active tab
   const { cmd } = useParams<{ cmd: string }>();
   return <Tabs defaultActiveKey={cmd ? '2' : '1'} items={[
            {
               label: <Space style={{paddingLeft: 10}}>Mappings</Space>,
               key: '1',
               children: <ContentClassificationXRefList />
            },
            {
               label: <Space style={{ paddingLeft: 10 }}>Practice Payer Mapping Log</Space>,
               key: '2',
               children: <ContentClassificationXRefChangeLogList />
            }
         ]} />
   


}
export default ContentClassificationIndex;

