import * as React from 'react';
import { colorWheel } from '../../Theme/ColorWheel';


interface IProps {
   isTouched?: boolean;
   errorText?: string;
}

export const SmallErrorMessage: React.FC<IProps> = (props) => {

   return (<>
      {props.errorText && props.isTouched &&
         <small style={{ color: colorWheel.red }}>{props.errorText}</small>
      }
      </>
   );
}
