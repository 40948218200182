import { FieldOption, IOption } from "../models";

export const BENEFIT_PERIOD_GROUP_ID = 34;
export const BENEFIT_PERIOD_TYPE_FIELD_ID = 368;
export const BENEFIT_PERIOD_MONTH_FIELD_ID = 369;
export const BENEFIT_PERIOD_DAY_FIELD_ID = 370;
export interface CohortItem {
   cohortItemId: number;
   cohortId: number;
   recordId: number;
   cohortItemStatusId: number;
   repRowId: number;
   cohortFieldId: number;
   value: any;
   formFieldTypeId: number;
   fieldType: string;
   isRequired: boolean;
   minLength: number;
   maxLength: number;
   sequence: number;
   labelText: string;
   placeHolderText?: string;
   tooltip?: string;
   fieldOptions?: FieldOption[];
   benefitPeriodType?: number;
   benefitPeriodMonth?: number;
   benefitPeriodStartDate?: Date;
}

export interface CohortItemAnswerRequest {
   cohortId: number;
   cohortItemAnswers: CohortItemAnswer[];
}

export interface CohortItemAnswer {
   id: number;
   answer: any;
}

export interface CohortItemReviewRequest {
   cohortId: number;
   cohortItemReviews: CohortItemReview[];
}

export interface CohortItemReview {
   id: number;
   cohortItemStatusId: number;
}

export const mapOptions = (cohortItemId: number, fieldOptions: FieldOption[]): IOption[] => {
   let options: IOption[];

   if (!fieldOptions || fieldOptions.length === 0) {
      console.warn(`No FieldOptions found for "${cohortItemId}".`);
      options = [];
   }

   options = fieldOptions.map(option => { return { Name: option.name, Value: option.value, IsDefault: option.isDefault } });
   return options;
}