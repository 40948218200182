import { ColumnsType, TableProps } from 'antd/lib/table';
import * as React from 'react';
import Restricted from '../../../auth/Restricted';
import { numberComparer, stringComparer } from '../../../functions/comparer.functions';
import { BrandViewModel } from '../../../store/drug/BrandModel';
import { brandBaseUrl, useBrandViewModel } from '../../../store/drug/BrandFetcher';
import { KnownSettings } from '../../../store/SettingsModel';
import HighlightSearchText from '../../HighlightSearchText';
import SearchResultsTable from '../../shared/AntComponents/Table/SearchResultsTable';
import { BasicInputField } from '../../shared/BasicInputLibrary';
import { AddButton, EditButton } from '../../shared/Buttons';
import BrandEditor from './BrandEditor';
import BrandResourceRow from './BrandResourceRow';
import { useApiContext } from '../../../store/ApiContext';
import ApiErrorDisplay from '../../ApiErrorDisplay';
import { HttpVerb, KeyWithVerb, useErrorContext } from '../../../store/ErrorContext';


const _keysWithVerb: KeyWithVerb[] = [{ key: brandBaseUrl, verb: HttpVerb.GET }]

const BrandIndex: React.FC = () => {
   const { httpGet } = useApiContext();
   const { removeErrors } = useErrorContext();
   const { brandViewModels, isLoading } = useBrandViewModel(httpGet);
   const [selectedItem, setSelectedItem] = React.useState<BrandViewModel>(null);
   const [isEditorOpen, setIsEditorOpen] = React.useState<boolean>(false);
   const [brandNameFilter, setBrandNameFilter] = React.useState<string>('');
   const [urlResourceFilter, seturlResourceFilter] = React.useState<string>('');
   const [currentPage, setCurrentPage] = React.useState(1);
   const [currentPageSize, setCurrentPageSize] = React.useState(25);

   React.useEffect(() => {
      removeErrors({ keysWithVerb: _keysWithVerb });
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []) // remove Errors on initial render

   const filterBrandItems = (model: BrandViewModel): boolean => {
      const isBrandName = model?.brandName?.toLowerCase().includes(brandNameFilter.toLowerCase());
      const isId = model?.id.toString().includes(brandNameFilter.toLowerCase());
      const isUrl = model?.resources.filter((m) => m.resourceUrl.includes(urlResourceFilter)).length > 0;

      if (urlResourceFilter.length > 0)
         return (isBrandName || isId) && isUrl;
      else
         return isBrandName || isId;
   }

   const filterGridContent = (records: BrandViewModel[]): BrandViewModel[] => {
      if (records && records?.length > 0) {
         return records.filter(y => filterBrandItems(y));
      }
      return [];
   }
   const filteredData = React.useMemo(() => {
      if (brandViewModels?.length > 0) {
         return filterGridContent(brandViewModels);
      }
      // for "filterGridContent" - we actually need the three in this dependency array
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [brandViewModels, brandNameFilter, urlResourceFilter])

   const handleCloseEditorClick = () => {
      setIsEditorOpen(false);
      setSelectedItem(undefined);
   }

   const handleOnChange: TableProps<BrandViewModel>['onChange'] = (pagination, filters, sorter, extra) => setCurrentPage(pagination?.current);
   const handlebrandNameFilterChange = (filter: string) => setBrandNameFilter(filter);
   const handleUrlResourceFilterChange = (filter: string) => seturlResourceFilter(filter);

   const tableColumns: ColumnsType<BrandViewModel> = [
      {
         title: 'Id',
         dataIndex: 'id',
         width: 80,
         sorter: (a, b) => numberComparer(a.id, b.id),
         sortDirections: ['ascend', 'descend', 'ascend'],
         render: (text, record) =>
            <HighlightSearchText searchString={brandNameFilter} targetString={record?.id.toString()} />
      },
      {
         title: 'Brand Name',
         dataIndex: 'brandName',
         defaultSortOrder: 'ascend',
         sorter: (a: BrandViewModel, b: BrandViewModel) => a.brandName.localeCompare(b.brandName),
         sortDirections: ['ascend', 'descend', 'ascend'],
         render: (text, record) =>
            <HighlightSearchText searchString={brandNameFilter} targetString={record?.brandName} />
      },
      {
         dataIndex: 'logoUrl',
         width: 170,
         render: (text, record) =>
            <div className='grid-logo-bg'>{record.logoUrl && <img src={record.logoUrl} className="grid-logo" alt="logo" />}</div>
      },
      {
         title: 'Logo Url',
         dataIndex: 'logoUrl',
         sorter: (a, b) => stringComparer(a.logoUrl, b.logoUrl),
         sortDirections: ['ascend', 'descend', 'ascend']
      },
      {
         title: () =>
            <Restricted requiredRoles={[KnownSettings.ContentAdmin]}>
               <AddButton
                  title='Add New Brand'
                  buttonText='New'
                  onClick={() => {
                     setSelectedItem(undefined);
                     setIsEditorOpen(true);
                  }} />
            </Restricted>,
         dataIndex: 'actionButtons',
         width: 100,
         fixed: 'right',
         render: (text, record) => <Restricted requiredRoles={[KnownSettings.ContentAdmin]}>
            <EditButton
               buttonText=''
               onClick={() => {
                  setSelectedItem(record);
                  setIsEditorOpen(true);
               }} />
         </Restricted>
      }
   ];

   const html = (
      <>
         <ApiErrorDisplay
            title={'Error'}
            keysWithVerb={_keysWithVerb}
         />

         <SearchResultsTable
            bordered={true}
            rowkey={'id'}
            onChange={handleOnChange}
            columns={tableColumns}
            data={filteredData}
            currentPage={currentPage}
            currentPageSize={currentPageSize}
            setCurrentPageSize={setCurrentPageSize}
            titleText='Brand'
            fixedHeader={true}
            size={'small'}
            expandedRowRender={(model: BrandViewModel) => <span key={model.id}>
               {<BrandResourceRow brand={model}
                  closeEditor={() => handleCloseEditorClick()}
                  urlFilter={urlResourceFilter} />}
            </span>}
            additionalComponents={[
               <BasicInputField
                  label='Brand Name'
                  placeholder='Search by Brand Name'
                  value={brandNameFilter}
                  onChange={(e) => handlebrandNameFilterChange(String(e))} />,
               <BasicInputField
                  label='Resource Url'
                  placeholder='Search by Resource Url'
                  value={urlResourceFilter}
                  onChange={(e) => handleUrlResourceFilterChange(String(e))} />,
            ]} />
         {isEditorOpen &&
            <BrandEditor isOpen={isEditorOpen}
               closeEditor={() => handleCloseEditorClick()}
               brand={selectedItem} />
         }
      </>
   )
   return html;
}
export default BrandIndex;
