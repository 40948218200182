import * as React from 'react';
import {
   NotificationState, NotificationReducer,
   MERGE_NOTIFICATION, REMOVE_NOTIFICATION
} from '../../store/notification/NotificationReducer';
import { IAcknowledgeableNotification } from '../../store/notification/NotificationModel';

interface INotificationContextProps {
   notificationDrawerOpen: boolean;
   setNotificationDrawerOpen: (open: boolean) => void;
   notifications: IAcknowledgeableNotification[];
   mergeNotification: (notification: IAcknowledgeableNotification) => void;
   removeNotification: (key: string) => void;
}

interface INotificationContextProviderProps {
   children: React.ReactNode;
}

const initialNotificationState: NotificationState = { notifications: [] };

export const NotificationContext = React.createContext<INotificationContextProps>({} as INotificationContextProps);
export const useNotificationContext = () => React.useContext(NotificationContext);


export const NotificationContextProvider: React.FC<INotificationContextProviderProps> = (props) => {

   const [notificationDrawerOpen, setNotificationDrawerOpen] = React.useState<boolean>(false);
   const [notificationState, notificationDispatch] = React.useReducer(NotificationReducer, initialNotificationState);

   const mergeNotification = (notification: IAcknowledgeableNotification) => {
      notificationDispatch({
         type: MERGE_NOTIFICATION,
         payload: notification
      });
   }

   const removeNotification = (key: string) => {
      notificationDispatch({
         type: REMOVE_NOTIFICATION,
         payload: { key: key }
      });
   }

   return <NotificationContext.Provider
      value={{
         notificationDrawerOpen,
         setNotificationDrawerOpen,
         notifications: notificationState.notifications,
         mergeNotification,
         removeNotification
      }}>
      {props.children}
   </NotificationContext.Provider>
}
