import { Space } from 'antd';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { useApiContext } from '../../../store/ApiContext';
import { updateFavoriteReport } from '../../../store/report/FavoriteReportFetcher';
import { buildReportUrl } from '../../../store/report/ReportFunctions';
import { PowerBIReport } from '../../../store/report/ReportModel';
import colorWheel from '../../../Theme/ColorWheel';
import HighlightSearchText from '../../HighlightSearchText';
import CustomIcon, { CustomIconType } from '../../shared/AntComponents/CustomIcon';
import { ActionButton } from '../../shared/Buttons';
import { useReports } from '../ReportContext';


interface IReportMenuItemProps {
   menuItem: PowerBIReport
   searchText?: string;
   showBreadcrumb?: boolean;
   isFavorite?: boolean;
   isSelected?: boolean;
   showCertifiedAttribute?: boolean;
}

const ReportMenuItem: React.FC<IReportMenuItemProps> = (props) => {
   const { menuItem, searchText, showBreadcrumb, isFavorite, isSelected, showCertifiedAttribute } = props;
   const { httpPut } = useApiContext();
   const { view, workspaceKey, reportKey } = useReports();
   const [isUpdatingFavorite, setIsUpdatingFavorite] = React.useState(false);

   const handleFavoriteClicked = () => {
      setIsUpdatingFavorite(true);
      updateFavoriteReport(httpPut, menuItem.id)
         .finally(() => setIsUpdatingFavorite(false));
   }

   const defaultStyle: React.CSSProperties = {
      padding: '0 8px 8px 8px',
      borderBottom: `solid 1px ${colorWheel.neutral3}`
   }
   const selectedStyle: React.CSSProperties = {
      borderLeft: `solid 4px ${colorWheel.plumPurple}`,
      padding: '0 8px 8px 4px', // Reset the padding with 4 less px to account for the border being 4px
      background: `${colorWheel.figmaGrey}`
   }
   const cssClass = isSelected ? { ...defaultStyle, ...selectedStyle } : defaultStyle

   return <div style={cssClass}>
      <div style={{ display: 'flex', flexDirection: 'row', padding: '16px 10px 0 0' }}>
         <div style={{ width: 'calc(50vw - 30px)' }}>
            <Link
               to={buildReportUrl(view, workspaceKey, reportKey, menuItem.id)}
               
               title={menuItem.reportDescription}
            >
               <span>
                  {searchText && <HighlightSearchText targetString={menuItem.reportPageName} searchString={searchText} />}
                  {!searchText && menuItem.reportPageName}
               </span>
            </Link>
         </div>
         <div >
            {showCertifiedAttribute &&
               <span title={menuItem.isCertified ? 'Certified' : 'Uncertified'}>
                  <CustomIcon style={{color: colorWheel.graniteGrey}}
                     type={menuItem.isCertified ? CustomIconType.SafetyCertificateOutlined : CustomIconType.WarningOutlined}
                  />
               </span>
            }
         </div>
      </div>

      {showBreadcrumb && <>
         <div style={{ fontSize: '10px', paddingTop: '4px' }}>
            {`${menuItem.heading} > ${menuItem.reportPageGroup}`}
         </div>
      </>}

      {isSelected && <p style={{
         fontSize: '12px',
         paddingTop: showBreadcrumb ? '8px' : '4px', // figma shows that this padding changes based on whether the breadcrumb is displayed
         paddingBottom: '16px',
         margin: '0px' // margin: 0 to overwrite antd styles
      }}>
         {menuItem.reportDescription}
      </p>}

      {/* add some top padding even when the description isn't visible but the breadcrumb is so things aren't so crowded */}
      {!isSelected && showBreadcrumb &&
         <div style={{
            paddingTop: '8px', // figma shows that this padding changes based on whether the breadcrumb is displayed
         }}/>
      }

      <Space>
         <ActionButton
            icon={isFavorite ? <CustomIcon type={CustomIconType.StarFilled} /> : <CustomIcon type={CustomIconType.StarOutlined} />}
            displayNoText
            buttonText={'Favorite'}
            loading={isUpdatingFavorite}
            onClick={handleFavoriteClicked} />
         {menuItem.infoUrl &&
            <ActionButton
               icon={<CustomIcon type={CustomIconType.ExportOutlined} />}
               displayNoText
               buttonText={'Info'}
               onClick={() => window.open(menuItem.infoUrl, '_blank')} />
         }
      </Space>
   </div>
};

export default ReportMenuItem;