import * as React from 'react';
import { useApiContext } from '../../../store/ApiContext';
import { useUserMainViewModel } from '../../../store/auth/UserMainFetcher';
import { useUserProfile } from '../../../store/practice/UserProfileFetcher';
import { UserProfileViewModel } from '../../../store/practice/UserProfileModel';
import { ADPARO_OMEGA_ROLEID } from '../../../store/practice/UserRoleModel';
import UserProfileEditor from './UserProfileEditor';

interface ICopyUserProfileEditorProps {
   copyFromUserProfileId: number;
   userMainId: number;
   onCloseEditor: () => void;
   isEditorOpen: boolean;
}

const CopyUserProfileEditor: React.FC<ICopyUserProfileEditorProps> = (props) => {
   const {
      copyFromUserProfileId,
      userMainId,
      onCloseEditor,
      isEditorOpen,
   } = props;

   const { httpGet } = useApiContext();
   const { userProfile } = useUserProfile(httpGet, copyFromUserProfileId);
   const { userMainViewModel } = useUserMainViewModel(httpGet, userMainId);

   const profileToEdit = React.useMemo(() => {
      let result = {} as UserProfileViewModel;
      if (userProfile && userMainId && copyFromUserProfileId) {
         // We're intentionally grabbing a bare UserProfile to feed as a UserProfileViewModel
         // because we don't want the extra data on the ViewModel - just the attributes on UserProfile
         const copiedProfile = JSON.parse(JSON.stringify(userProfile));
         result = {
            ...copiedProfile,
            userProfileId: null,
            practiceId: null,
            externalUpn: userMainViewModel?.upn,
            isFollowedByAllowed: true
         };

         // CR-12 when profileToCopyFrom has Adparo - Omega, don't copy Phone/Fax.  Otherwise, its okay to have them co
         if (userProfile.userRoles?.includes(ADPARO_OMEGA_ROLEID)) {
            result.phone = null;
            result.phoneExt = null;
            result.fax = null;
         }
      }

      return result;
   }, [userMainViewModel, userProfile, userMainId, copyFromUserProfileId]);

   return <UserProfileEditor
      closeEditor={onCloseEditor}
      isEditorOpen={isEditorOpen}
      isEditorReadOnly={false}
      profileToEdit={profileToEdit}
      userMainId={userMainId }
      dialogTitle={'Create New Practice User'}
   />
}

export default CopyUserProfileEditor;