import { Col, Divider, Row, Space, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { TableRowSelection } from 'antd/lib/table/interface';
import * as React from 'react';
import Restricted from '../../../auth/Restricted';
import { boolComparer, numberComparer, stringComparer } from '../../../functions/comparer.functions';
import { formatBoolean } from '../../../functions/format.functions';
import { useApiContext } from '../../../store/ApiContext';
import { KnownSettings } from '../../../store/SettingsModel';
import { useDrugViewModel } from '../../../store/drug/DrugFetcher';
import { saveBrandDefaults, useBrandDefaultsViewModel } from '../../../store/program/AssistanceServiceBrandDefaultsFetcher';
import { AssistanceServiceBrandDefaults, AssistanceServiceBrandDefaultsViewModel } from '../../../store/program/AssistanceServiceBrandDefaultsModel';
import { useFetchAssistanceServices } from '../../../store/program/AssistanceServiceFetcher';
import { AssistanceService, AssistanceServiceAggregateBrandDefaults } from '../../../store/program/AssistanceServiceModel';
import { useFetchAssistanceServiceToDrug } from '../../../store/program/AssistanceServiceToDrugFetcher';
import Dialog from '../../Dialog';
import { useCohortContext } from '../../cohort/CohortContext';
import CustomIcon, { CustomIconType } from '../../shared/AntComponents/CustomIcon';
import MinimalistTable from '../../shared/AntComponents/Table/MinimalistTable';
import { NumberedTitle } from '../../shared/AntComponents/Typography/Title';
import { ActionButton, AddButton, CancelButton, EditButton } from '../../shared/Buttons';
import AssistanceServiceBrandDefaultsEditor from './AssistanceServiceBrandDefaultsEditor';
import AssistanceServiceSelectionDialog from './AssistanceServiceSelectionDialog';

interface IProps {
   assistanceServiceId: number;
   isOpen: boolean;
   handlClose: () => void;
}

const AssistanceServiceToBrandDefaultsIndex: React.FC<IProps> = (props: IProps) => {
   const [selectedAssistanceService, setSelectedAssistanceService] = React.useState<AssistanceService>(undefined);
   const [selectedBrandDefault, setSelectedBrandDefault] = React.useState<AssistanceServiceBrandDefaultsViewModel>(undefined);
   const [brandIdsMappedFromDrugs, setBrandIdsMappedFromDrugs] = React.useState<number[]>(undefined);
   const [isEditorOpen, setIsEditorOpen] = React.useState<boolean>(false);
   const [shouldBrandBeDisabledInEditor, setShouldBrandBeDisabledInEditor] = React.useState<boolean>(false);
   const [shouldUseProvidedAssistanceServiceInEditor, setShouldUseProvidedAssistanceServiceInEditor] = React.useState(false);
   const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);
   const [bulkItems, setBulkItems] = React.useState<number[]>([]);
   const [isAssistanceServiceSelectionDialogOpen, setIsAssistanceServiceSelectionDialogOpen] = React.useState<boolean>(false);
   const [aggregateBrandDefaults, setAggregateBrandDefaults] = React.useState<AssistanceServiceAggregateBrandDefaults[]>(undefined);
   const [brandDefaultsViewModels, setBrandDefaultsViewModels] = React.useState<AssistanceServiceBrandDefaultsViewModel[]>(undefined);
   const { httpGet, httpPost } = useApiContext();
   const { assistanceServices } = useFetchAssistanceServices(httpGet);
   const { assistanceServiceToDrugs } = useFetchAssistanceServiceToDrug(httpGet);
   const { brandDefaultsViewModelList } = useBrandDefaultsViewModel(httpGet);
   const { drugViewModelList } = useDrugViewModel(httpGet);
   const { cohortSummaryFromDate } = useCohortContext();

   React.useEffect(() => {
      let assistanceService: AssistanceService;
      if (assistanceServices?.length > 0) {
         assistanceService = assistanceServices.find(as => as.id === props?.assistanceServiceId);
         setSelectedAssistanceService(assistanceService);
      }

      const buildAggregateModels = () => {
         if (assistanceService && assistanceServiceToDrugs?.length > 0 && brandDefaultsViewModelList?.length > 0 && drugViewModelList?.length > 0) {
            const models: AssistanceServiceAggregateBrandDefaults[] = [];
            const drugAssociations = assistanceServiceToDrugs.filter(d => d.assistanceServiceId === props?.assistanceServiceId);
            const brandIdsForAssistanceServiceToDrugs = drugViewModelList.filter(l => drugAssociations.map(association => association.drugId)?.indexOf(l.id) > -1).map(l => l.brandId);

            setBrandIdsMappedFromDrugs(brandIdsForAssistanceServiceToDrugs);
            brandIdsForAssistanceServiceToDrugs.forEach(brandId => {
               if (!models.some(m => m.brandId === brandId)) {
                  let drugIdsForThisBrand = drugViewModelList.filter(l => l.brandId === brandId).map(l => l.id);
                  let drugCountForThisModel = drugAssociations.filter(da => drugIdsForThisBrand.indexOf(da.drugId) > -1);
                  const newModel =
                     {
                        ...assistanceService,
                        brandId: brandId,
                        brandName: drugViewModelList.find(l => l.brandId === brandId).brandName,
                        hasRelatedBrandDefaults: brandDefaultsViewModelList.some(def => def.brandId === brandId && def.assistanceServiceId === props?.assistanceServiceId),
                        assistanceServiceToDrugCount: drugCountForThisModel?.length
                     } as AssistanceServiceAggregateBrandDefaults;
                  models.push(newModel);
               }
            });
            setAggregateBrandDefaults(models);
         }
      };
      buildAggregateModels();

      if (brandDefaultsViewModelList?.length > 0 && assistanceService) {
         setBrandDefaultsViewModels(brandDefaultsViewModelList.filter(def => def.assistanceServiceId === assistanceService.id));
      }
   }, [props.assistanceServiceId, assistanceServices, assistanceServiceToDrugs, brandDefaultsViewModelList, drugViewModelList]);

   const closeEditor = () => {
      setSelectedBrandDefault(undefined);
      setIsEditorOpen(false);
      setShouldUseProvidedAssistanceServiceInEditor(false);
   }

   const closeThisModal = () => {
      setAggregateBrandDefaults(undefined);
      setBrandDefaultsViewModels(undefined);
      setSelectedAssistanceService(undefined);
      props.handlClose();
   }

   const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
      const arr = newSelectedRowKeys.map((k) => { return Number(k) });
      setBulkItems(arr);
      setSelectedRowKeys(newSelectedRowKeys);
   }

   const rowSelection: TableRowSelection<object> = {
      selectedRowKeys,
      onChange: onSelectChange,
   };

   const onClearBulkSelection = () => {
      setBulkItems([]);
      setSelectedRowKeys([]);
   };

   const clickBulkEditButton = () => {
      setIsAssistanceServiceSelectionDialogOpen(true);
   }

   const closeAssistanceServiceSelectionDialog = () => {
      setIsAssistanceServiceSelectionDialogOpen(false);
   }

   const saveAssistanceServiceSelection = (assistanceServiceId: number) => {
      const modelsToSave: AssistanceServiceBrandDefaults[] = brandDefaultsViewModels.filter(y => bulkItems.includes(y.id));
      modelsToSave.forEach(model => { model.assistanceServiceId = assistanceServiceId; })
      saveBrandDefaults(httpPost, modelsToSave, cohortSummaryFromDate);

      closeAssistanceServiceSelectionDialog();
      onClearBulkSelection(); // The selected items should drop off the grid since they've been moved to another Service so we should ensure to clear the selection of them
   }

   const aggregateBrandDefaultColumns: ColumnsType<AssistanceServiceAggregateBrandDefaults> = [
      {
         title: 'Brand',
         dataIndex: 'brandName',
         key: 'brandName',
         sortDirections: ['ascend', 'descend'],
         sorter: (a, b) => stringComparer(a.brandName, b.brandName),
         defaultSortOrder: 'ascend'
      },
      {
         title: 'Drug Count',
         dataIndex: 'assistanceServiceToDrugCount',
         key: 'assistanceServiceToDrugCount',
         sortDirections: ['ascend', 'descend'],
         sorter: (a, b) => numberComparer(a.assistanceServiceToDrugCount, b.assistanceServiceToDrugCount)
      },
      {
         title: 'Has Brand Defaults',
         dataIndex: 'hasRelatedBrandDefaults',
         key: 'hasRelatedBrandDefaults',
         sortDirections: ['ascend', 'descend'],
         sorter: (a, b) => boolComparer(a.hasRelatedBrandDefaults, b.hasRelatedBrandDefaults),
         render: (text, record) => formatBoolean(record?.hasRelatedBrandDefaults)
      }
   ];

   const brandDefaultsColumns: ColumnsType<AssistanceServiceBrandDefaultsViewModel> = [
      {
         title: 'Id',
         dataIndex: 'id',
         key: 'id',
         sortDirections: ['ascend', 'descend'],
         sorter: (a, b) => numberComparer(a.id, b.id),
         defaultSortOrder: 'ascend'
      },
      {
         title: 'Manufacturer Name',
         dataIndex: 'manufacturerName',
         key: 'manufacturerName',
         sortDirections: ['ascend', 'descend'],
         sorter: (a, b) => stringComparer(a.manufacturerName, b.manufacturerName)
      },
      {
         title: 'BrandId',
         dataIndex: 'brandId',
         key: 'brandId',
         sortDirections: ['ascend', 'descend'],
         sorter: (a, b) => numberComparer(a.brandId, b.brandId)
      },
      {
         title: 'Brand Name',
         dataIndex: 'brandName',
         key: 'brandName',
         sortDirections: ['ascend', 'descend'],
         sorter: (a, b) => stringComparer(a.brandName, b.brandName)
      },
      {
         title: <Restricted requiredRoles={[KnownSettings.ContentAdmin]}>
            <AddButton buttonText='New' onClick={() => {
               setShouldUseProvidedAssistanceServiceInEditor(true);
               setSelectedBrandDefault(undefined);
               setShouldBrandBeDisabledInEditor(false);
               setIsEditorOpen(true)
            }} />
         </Restricted>,
         render: (text, record) =>
            <Restricted requiredRoles={[KnownSettings.ContentAdmin]}>
               <EditButton onClick={() => {
                  setSelectedBrandDefault(record);
                  setShouldBrandBeDisabledInEditor(true);
                  setIsEditorOpen(true);
               }} />
            </Restricted>
      },
      Table.SELECTION_COLUMN
   ];


   const html = (<Dialog
      scrollingContent={true}
      title={'Assistance Service Brand Defaults'}
      open={props.isOpen}
      size='large'
      actionButtons={
         <CancelButton onClick={() => { closeThisModal(); }} />
      }>
      <NumberedTitle
         text={`Assistance Service Brand Defaults Information for Service: ${selectedAssistanceService?.assistanceServiceName}`}
         level={5} />

      <MinimalistTable
         rowKey={'brandId'}
         size={'small'}
         columns={aggregateBrandDefaultColumns}
         data={aggregateBrandDefaults}
         showHeader={true}
         bordered={true}
      />
      <Divider />
      <MinimalistTable
         rowKey={'id'}
         size={'small'}
         columns={brandDefaultsColumns}
         data={brandDefaultsViewModels}
         showHeader={true}
         bordered={true}
         footer={<Row justify="end">
            <Col>
               <Space>
                  <Restricted requiredRoles={[KnownSettings.ContentAdmin]}>
                     <ActionButton
                        buttonText='Clear Bulk Selection'
                        onClick={onClearBulkSelection}
                        disabled={bulkItems.length < 1}
                        icon={<CustomIcon type={CustomIconType.CloseOutlined} />}
                     />
                     <ActionButton
                        buttonText='Modify Assistance Service(s)'
                        disabled={bulkItems.length < 1}
                        onClick={() => clickBulkEditButton()}
                        icon={<CustomIcon type={CustomIconType.EditOutlined} />}
                     />
                  </Restricted>
               </Space>
            </Col>
         </Row>}
         rowSelection={rowSelection}
      />

      {isAssistanceServiceSelectionDialogOpen && 
         <AssistanceServiceSelectionDialog
            isOpen={isAssistanceServiceSelectionDialogOpen}
            saveAssistanceServiceLink={saveAssistanceServiceSelection}
            cancelAssistanceServiceLink={closeAssistanceServiceSelectionDialog}
         />
      }
      {isEditorOpen && 
         <AssistanceServiceBrandDefaultsEditor
            closeEditor={closeEditor}
            isOpen={isEditorOpen}
            shouldBrandBeDisabled={shouldBrandBeDisabledInEditor}
            shouldAssistanceServiceBeDisabled={true}
            assistanceService={selectedAssistanceService}
            id={selectedBrandDefault?.id}
            brandIdsMappedForDrugs={brandIdsMappedFromDrugs}
            shouldUseProvidedAssistanceService={shouldUseProvidedAssistanceServiceInEditor}
         />
      }
   </Dialog>
   );
   return html;
}

export default AssistanceServiceToBrandDefaultsIndex;