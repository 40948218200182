import { HttpError, TPost, TGet } from '../../functions/httpClient';
import { IStatus } from '../models';
import useSWR, { mutate } from 'swr';
import { AssistanceServiceToDrug } from './AssistanceServiceToDrugModel';

export const assistanceServiceToDrugBaseUrl = `api/program/AssistanceServiceToDrug`;
const saveAssistanceServiceToDrugUrl = (assistanceServiceId: number) => `${assistanceServiceToDrugBaseUrl}/SaveAllByAssistanceServiceId/${assistanceServiceId}`;

const swrOptions = {
   refreshInterval: 0,
   revalidateOnFocus: false,
   dedupingInterval: 60000
};


export function useFetchAssistanceServiceToDrug(httpGet: TGet): IStatus & { assistanceServiceToDrugs: AssistanceServiceToDrug[] } {
   const { data, isLoading, error } = useSWR<AssistanceServiceToDrug[], HttpError>(
      assistanceServiceToDrugBaseUrl,
      httpGet,
      { ...swrOptions }
   );

   return {
      assistanceServiceToDrugs: data,
      isLoading: isLoading,
      error: error?.message
   };
}

export const saveAssistanceServiceToDrug = async (httpPost: TPost, assistanceServiceId: number, drugIds: number[]) => {
   const result = await httpPost(saveAssistanceServiceToDrugUrl(assistanceServiceId), drugIds)
      .then(() => mutate(assistanceServiceToDrugBaseUrl));
   return result;
}