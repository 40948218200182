import { Col, Row, Space, Table, Tooltip } from 'antd';
import { ColumnsType, TableProps } from 'antd/lib/table';
import { TableRowSelection } from 'antd/lib/table/interface';
import * as React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ChangePasswordButton from '../../auth/ChangePasswordButton';
import Restricted from '../../auth/Restricted';
import { boolComparer, dateComparer, numberComparer, stringComparer } from '../../functions/comparer.functions';
import { formatBoolean, formatShortDateString } from '../../functions/format.functions';
import { IOptionItem } from '../../functions/option.functions';
import { getSessionItem, setSessionItem } from '../../functions/storage.functions';
import ContentCard from '../../layouts/ContentCard';
import { useLayoutContext } from '../../layouts/LayoutContext';
import { useApiContext } from '../../store/ApiContext';
import { useAccessRoleOptions, useFetchAccessRole } from '../../store/auth/AccessRoleFetcher';
import { inviteUser, inviteUsersUrl } from '../../store/auth/UserFetcher';
import { userMainBaseUrl, useUserMainViewModels } from '../../store/auth/UserMainFetcher';
import { UserMainDetail, UserMainViewModel } from '../../store/auth/UserMainModel';
import { HttpVerb, KeyWithVerb, useErrorContext } from '../../store/ErrorContext';
import { usePracticeOptions } from '../../store/practice/PracticeFetcher';
import { KnownSettings } from '../../store/SettingsModel';
import ApiErrorDisplay from '../ApiErrorDisplay';
import HighlightSearchText from '../HighlightSearchText';
import CustomIcon, { CustomIconType } from '../shared/AntComponents/CustomIcon';
import ColumnFilter from '../shared/AntComponents/Filter/ColumnFilter';
import { IFilteredInfo } from '../shared/AntComponents/Filter/FilteredInfo';
import MultiSelectColumnFilter from '../shared/AntComponents/Filter/MultiSelectColumnFilter';
import { SearchResultsTableWithFilters } from '../shared/AntComponents/Table/SearchResultsTableWithFilters';
import { BasicCheckboxField, BasicInputField } from '../shared/BasicInputLibrary';
import { ActionButton, AddButton } from '../shared/Buttons';
import UserProfileRow from './UserProfileRow';

type searchFilter = 'freeText' | 'practiceId' | 'accessRole' | 'notInvitedYet' | 'disabled' | 'email';
const defaultFilter: Record<searchFilter, IFilteredInfo> = {
   freeText: undefined, // "freeText" refers to the field that will search Email as well as Name fields
   practiceId: undefined,
   accessRole: undefined,
   notInvitedYet: undefined,
   disabled: undefined,
   email: undefined
};
const PRACTICE_ID_TITLE = 'Practice ID(s)';
const ACCESS_ROLES_TITLE = 'Access Roles';
const DISABLED_TITLE = 'Disabled';
const EMAIL_TITLE = 'Email';

const GRID_FILTER_KEY = "USER_INDEX";

const _keys: string[] = [inviteUsersUrl];
const _keysWithVerb: KeyWithVerb[] = [{key: userMainBaseUrl, verb: HttpVerb.GET}]

const UserIndex: React.FC = () => {
   const { httpGet, httpPost } = useApiContext();
   const { removeErrors } = useErrorContext();
   const navigate = useNavigate();
   const { userMainViewModels, isLoading, error } = useUserMainViewModels(httpGet);
   const { accessRoles } = useFetchAccessRole(httpGet);
   const { practiceOptions } = usePracticeOptions(httpGet, false, true);
   const { accessRoleOptions } = useAccessRoleOptions(httpGet, true);

   const [currentPage, setCurrentPage] = React.useState(1);
   const [currentPageSize, setCurrentPageSize] = React.useState(25);
   const handleOnChange: TableProps<UserMainDetail>['onChange'] =
      (pagination, filters, sorter, extra) => setCurrentPage(pagination?.current);

   const determineStartingFilterValue = (): Record<searchFilter, IFilteredInfo> => {
      const storedData = getSessionItem(GRID_FILTER_KEY) as unknown as Record<searchFilter, IFilteredInfo>;
      /* Its important that we check that we have at least one property on the data returned from storage
       * because otherwise we'll return an empty object, which we then have issue setting values on, which results
       * in the filters not working nor erroring */
      if (storedData && (
         storedData?.freeText ||
         storedData?.practiceId ||
         storedData?.accessRole ||
         storedData?.notInvitedYet ||
         storedData?.disabled ||
         storedData?.email)) {
         const storedFilteredInfo = storedData as unknown as Record<searchFilter, IFilteredInfo>;

         /* When we're missing props from storage, we end up with an IFilteredInfo
          * that's missing props, so our filters lose keys and stop working
          * So... we're going to completely reconstruct the record set and let
          * the session storage values override anything.  Enjoy */
         const rebuiltFilteredInfo: Record<searchFilter, IFilteredInfo> = {
            freeText: undefined,
            practiceId: undefined,
            accessRole: undefined,
            notInvitedYet: undefined,
            disabled: undefined,
            email: undefined,
            ...storedFilteredInfo
         };
         return rebuiltFilteredInfo;
      } else {
         return defaultFilter;
      };
   }

   const [filteredInfo, setFilteredInfo] = React.useState<Record<searchFilter, IFilteredInfo>>(determineStartingFilterValue);

   const [bulkItems, setBulkItems] = React.useState<number[]>([]);
   const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);

   // To mimic the behavior/UI you get with most other grid/editor combos (that you get for free by being under "ContentTool" otherwise)
   const { cardName, cardHeaderTotalCount, cardHeaderFilteredCount } = useLayoutContext();

   React.useEffect(() => {
      removeErrors({ keys: _keys, keysWithVerb: _keysWithVerb });
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []) // clear errors on initial render

   React.useEffect(() => {
      setSessionItem(GRID_FILTER_KEY, filteredInfo);
   }, [filteredInfo]);

   const booleanOptions = [{ label: 'Yes', value: 1, key: 'Yes' } as IOptionItem, { label: 'No', value: 0, key: 'No' } as IOptionItem]

   const emailOptions = React.useMemo(() => {
      if (userMainViewModels && userMainViewModels.length > 0) {
         const result: IOptionItem[] = [];
         userMainViewModels.forEach(model => {
            // We allow duplicate emailAddresses so we need to check for that to have unique keys
            if (!result.some(y => y.value === model.emailAddress)) {
               result.push({ label: model.emailAddress, value: model.emailAddress, key: model.emailAddress } as IOptionItem);
            }
         });
         return result;
      }
   }, [userMainViewModels]);

   const filterFreeText = (userModel: UserMainViewModel): boolean => {
      if (!filteredInfo?.freeText?.value) return true;

      const filterValue = (filteredInfo.freeText.value as string).toLowerCase();
      return `${userModel.emailAddress}`.toLowerCase().indexOf(filterValue) > -1
         || `${userModel.upn}`.toLowerCase().indexOf(filterValue) > -1
         || userModel.userProfiles?.map(y => `${y.firstName} ${y.lastName}`.toLowerCase()).some(y => y.includes(filterValue))
         || userModel.userProfiles?.map(y => `${y.emailAddress}`.toLowerCase())?.some(y => y.includes(filterValue));
   }

   const filterPractice = (userModel: UserMainViewModel): boolean => {
      return !filteredInfo?.practiceId?.values || filteredInfo.practiceId.values.length === 0 ||
         filteredInfo.practiceId.values.some(y => userModel.distinctPracticeIds?.includes(y.value as number)) ||
         // Special case - y.value: 0 is the "[NULL]" option so in that case we'll accept models with no practiceIds
         ((filteredInfo.practiceId.values.some(y => y.value === 0) && (userModel.distinctPracticeIds?.length ?? 0) === 0));
   }

   const filterAccessRole = (userModel: UserMainViewModel): boolean => {
      return !filteredInfo?.accessRole?.values || filteredInfo.accessRole.values.length == 0 ||
         filteredInfo.accessRole.values.some(y => userModel.accessRoleIds?.includes(y.value as number)) ||
         // Special case - y.value: 0 is the "[NULL]" option so in that case we'll accept models with no userRoles
         (filteredInfo.accessRole.values.some(y => y.value === 0) && (userModel.accessRoleIds?.length ?? 0) === 0);
   }

   const filterNotInvitedYet = (userModel: UserMainViewModel): boolean => {
      return filteredInfo?.notInvitedYet?.value === undefined || filteredInfo?.notInvitedYet?.value === false ||
         (filteredInfo.notInvitedYet.value && userModel.userProfileIds?.length > 0 && !userModel.inviteSentOn)
   }

   const filterEmail = (model: UserMainViewModel): boolean => {
      return !filteredInfo?.email?.values || filteredInfo.email.values.length === 0 ||
         filteredInfo.email.values.some(y => y.value === model.emailAddress);
   }

   const filterDisabled = (model: UserMainViewModel): boolean => {
      return !filteredInfo?.disabled?.values || filteredInfo.disabled.values.length == 0 ||
         filteredInfo.disabled.values.some(y => Boolean(y.value) === model.isDisabled);
   }

   const filterGridData = React.useMemo(() => {
      if (userMainViewModels && userMainViewModels?.length > 0) {
         return userMainViewModels.filter(y =>
            filterFreeText(y) &&
            filterPractice(y) &&
            filterAccessRole(y) &&
            filterNotInvitedYet(y) &&
            filterEmail(y) &&
            filterDisabled(y));
      }
      return [];
      // for all of the filter functions - we actually need the ones in the dependency array 
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [userMainViewModels, filteredInfo]);

   const renderAccessRoles = (userModel: UserMainDetail): React.ReactNode => {
      if (userModel?.accessRoleIds?.length > 0) {
         const returnArr: JSX.Element[] = [];
         let index = 1;
         userModel.accessRoleIds.forEach(roleId => {
            returnArr.push(
               /* 'ant-table-cell-ellipsis' - is the className that Ant uses to render ellipsis in its table when 
                *  you provide the "ellipsis: true" value for a column definition */
               <div key={`${userModel.id}-${index}`} className='ant-table-cell-ellipsis'>
                  {`${accessRoles?.find(role => roleId === role.id).roleName}${index === userModel.accessRoleIds.length ? '' : ','} `}
               </div>);
            index++;
         })
         return returnArr;
      } else {
         return <></>;
      }
   }

   const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
      const arr = newSelectedRowKeys.map((y) => { return Number(y) });
      setBulkItems(arr);
      setSelectedRowKeys(newSelectedRowKeys);
   };

   const rowSelection: TableRowSelection<object> = {
      selectedRowKeys,
      onChange: onSelectChange,
   };

   const onClearBulkSelection = () => {
      setBulkItems([]);
      setSelectedRowKeys([]);
   }

   const bulkSendInviteButtonTitle = (): string => {
      if (bulkItems.length === 0) return 'Use the checkboxes in the grid to select records to send the Invitation to';

      if (bulkItems.length > 0) return 'Click this button to send the Invitation';
   }

   const handleBulkSendInvite = () => {
      //  alert('bulkSendInvite');
      // Spinner?
      bulkItems.forEach((item) => {
         inviteUser(httpPost, item)
            .catch(err => console.error('Error Inviting', err))
         //TODO: handle this loop better for User feedback
      });
      onClearBulkSelection();
   }

   const renderPracticeNames = (userModel: UserMainViewModel): React.ReactNode => {
      if (userModel?.distinctPracticeIds?.length > 0) {
         let distinctPracticeIds: number[] = [];
         userModel.distinctPracticeIds.forEach((practiceId) => {
            if (!distinctPracticeIds.includes(practiceId)) distinctPracticeIds.push(practiceId);
         })
         return (
            <div className='ant-table-cell-ellipsis'>
               {distinctPracticeIds.join(', ')}
            </div>
         );
      } else {
         return <></>;
      }
   }

   const columns: ColumnsType<UserMainViewModel> = [
      {
         dataIndex: 'id',
         title: 'ID',
         key: 'id',
         sorter: (a, b) => numberComparer(a.id, b.id),
         sortDirections: ['ascend', 'descend'],
         defaultSortOrder: 'descend',
      },
      {
         dataIndex: 'upn',
         title: 'UPN',
         key: 'upn',
         sorter: (a, b) => stringComparer(a.upn, b.upn),
         sortDirections: ['ascend', 'descend'],
         render: (text, record) =>
            <Link
               to={{ pathname: `/user/userdetail/${record.id}` }}
               title={`Edit User Main for ${record.upn}`}>
               <HighlightSearchText targetString={record.upn} searchString={filteredInfo?.freeText?.value as string} />
            </Link>
      },
      {
         dataIndex: 'emailAddress',
         title: <ColumnFilter title={EMAIL_TITLE}
            filteredInfo={filteredInfo?.email}
            content={<MultiSelectColumnFilter
               options={emailOptions}
               searchable
               allowSelectAll
               searchPlaceholder={`Filter by ${EMAIL_TITLE}`}
               filteredInfo={filteredInfo}
               setFilteredInfo={setFilteredInfo}
               filteredInfoKey={'email'}
               filteredInfoTitle={EMAIL_TITLE}
            />}
         />,
         key: 'emailAddress',
         sorter: (a, b) => stringComparer(a.emailAddress, b.emailAddress),
         sortDirections: ['ascend', 'descend'],
         render: (text, record) => <HighlightSearchText targetString={record.emailAddress} searchString={filteredInfo?.freeText?.value as string} />
      },
      {
         dataIndex: 'accessRoleIds',
         title: <ColumnFilter title={ACCESS_ROLES_TITLE}
            filteredInfo={filteredInfo?.accessRole}
            content={<MultiSelectColumnFilter
               options={accessRoleOptions}
               searchPlaceholder={`Filter by ${ACCESS_ROLES_TITLE}`}
               allowSelectAll={true}
               searchable={true}
               filteredInfo={filteredInfo}
               setFilteredInfo={setFilteredInfo}
               filteredInfoKey={'accessRole'}
               filteredInfoTitle={ACCESS_ROLES_TITLE}
            />}
         />,
         key: 'accessRoleIds',
         render: (text, record) => {
            const content = renderAccessRoles(record);
            return <Tooltip placement='top' overlayStyle={{ maxWidth: '350px' }} title={content}>{content}</Tooltip>
         }
      },
      {
         dataIndex: 'practiceIds',
         title: <ColumnFilter title={PRACTICE_ID_TITLE}
            filteredInfo={filteredInfo?.practiceId}
            content={<MultiSelectColumnFilter
               options={practiceOptions}
               searchPlaceholder={`Filter by ${PRACTICE_ID_TITLE}`}
               allowSelectAll={true}
               searchable={true}
               filteredInfo={filteredInfo}
               setFilteredInfo={setFilteredInfo}
               filteredInfoKey={'practiceId'}
               filteredInfoTitle={PRACTICE_ID_TITLE}
            />}
         />,
         key: 'practiceIds',
         render: (text, record) => {
            const content = renderPracticeNames(record);
            return <Tooltip placement='top' overlayStyle={{ maxWidth: '350px' }} title={content}>{content}</Tooltip>
         }
      },
      {
         dataIndex: 'createdOn',
         title: 'Created On',
         key: 'createdOn',
         sorter: (a, b) => dateComparer(a.createdOn, b.createdOn),
         sortDirections: ['ascend', 'descend'],
         render: (text, record) => formatShortDateString(record.createdOn)
      },
      {
         dataIndex: 'inviteSentOn',
         title: 'Invited On',
         key: 'inviteSentOn',
         sorter: (a, b) => dateComparer(a.inviteSentOn, b.inviteSentOn),
         sortDirections: ['ascend', 'descend'],
         render: (text, record) => formatShortDateString(record.inviteSentOn)
      },
      Table.SELECTION_COLUMN,
      {
         dataIndex: 'auth0Id',
         title: 'Auth0 ID',
         key: 'auth0Id',
         sorter: (a, b) => stringComparer(a.auth0Id, b.auth0Id),
         sortDirections: ['ascend', 'descend']
      },
      {
         dataIndex: 'auth0CreatedOn',
         title: 'Auth0 ID Created On',
         key: 'auth0CreatedOn',
         sorter: (a, b) => dateComparer(a.auth0CreatedOn, b.auth0CreatedOn),
         sortDirections: ['ascend', 'descend'],
         render: (text, record) => formatShortDateString(record.auth0CreatedOn)
      },
      {
         dataIndex: 'isDisabled',
         title: <ColumnFilter title={DISABLED_TITLE}
            filteredInfo={filteredInfo?.disabled}
            content={<MultiSelectColumnFilter
               options={booleanOptions}
               searchPlaceholder={`Filter by ${DISABLED_TITLE}`}
               filteredInfo={filteredInfo}
               setFilteredInfo={setFilteredInfo}
               filteredInfoKey={'disabled'}
               filteredInfoTitle={DISABLED_TITLE}
            />}
         />,
         key: 'isDisabled',
         sorter: (a, b) => boolComparer(a.isDisabled, b.isDisabled),
         sortDirections: ['ascend', 'descend'],
         render: (text, record) => formatBoolean(record.isDisabled)
      },
      {
         title: 'Change Password',
         fixed: 'right',
         width: '10rem',
         render: (text, record) => {
            if (record?.auth0Id?.includes('auth0')) {
               return (
                  <Restricted requiredRoles={[KnownSettings.UserAdmin]}>
                     <ChangePasswordButton userMainId={record.id} />
                  </Restricted>);
            }
            else
               return "";
         }
      }
   ];

   const html = (
      // To mimic the behavior/UI you get with most other grid/editor combos (that you get for free by being under "ContentTool" otherwise)
      <ContentCard title={cardName} style={{ width: '100%', minHeight: '50vh' }}
         subTitle={cardHeaderTotalCount && cardHeaderFilteredCount >= 0
            ? `Viewing (${cardHeaderFilteredCount}) of (${cardHeaderTotalCount})` : null}
      >
         <ApiErrorDisplay
            title='Error retrieving User Profiles'
            keys={_keys}
            keysWithVerb={_keysWithVerb}
         />

         <SearchResultsTableWithFilters
            rowkey={'id'}
            currentPage={currentPage}
            currentPageSize={currentPageSize}
            setCurrentPageSize={setCurrentPageSize}
            onChange={handleOnChange}
            titleText='User Main'
            columns={columns}
            data={filterGridData}
            fixedHeader={true}
            onFiltersClear={() => setFilteredInfo(defaultFilter)}
            filteredInfo={filteredInfo}
            setFilteredInfo={setFilteredInfo}
            loading={isLoading}
            scrollY={'calc(100vh - 400px)'}
            rowSelection={rowSelection}
            expandedRowRender={(record: UserMainViewModel) => <UserProfileRow
               userProfiles={record.userProfiles}
               userMainId={record.id}
               freeTextFilter={filteredInfo?.freeText?.value as string}
               displayCopyButton
               displayUnmap
            />}
            footer={<Row justify="end">
               <Col>
                  <Space>
                     <ActionButton
                        buttonText='Clear Bulk Selection'
                        onClick={onClearBulkSelection}
                        disabled={bulkItems.length < 1}
                        icon={<CustomIcon type={CustomIconType.CloseOutlined} />}
                     />
                     <ActionButton
                        buttonText='Send Invite'
                        disabled={bulkItems.length < 1}
                        onClick={() => handleBulkSendInvite()}
                        icon={<CustomIcon type={CustomIconType.MailOutlined} />}
                        title={bulkSendInviteButtonTitle()}
                     />
                  </Space>
               </Col>
            </Row>}
            additionalComponents={[
               <BasicInputField
                  tooltip={'This Filters across Upn and Email, as well as First and Last Name'}
                  placeholder={'Filter...'}
                  label={'Filter by Email, Name...'}
                  conatinerStyle={{ padding: 0, margin: 0 }}
                  value={filteredInfo?.freeText?.value as string}
                  onChange={(e) => {
                     setFilteredInfo({
                        ...filteredInfo,
                        freeText: {
                           title: 'freeText',
                           value: e as string
                        }
                     })
                  }}
               />,
               <BasicCheckboxField
                  tooltip={'Use this Filter to display only those records with at least 1 User Profile associated to them but without an "InviteSentOn" value'}
                  label={'Not Invited Yet'}
                  conatinerStyle={{ padding: 0, margin: 0 }}
                  checked={filteredInfo?.notInvitedYet?.value as boolean}
                  onChange={(checked) => {
                     setFilteredInfo({
                        ...filteredInfo,
                        notInvitedYet: {
                           title: 'Not Invited Yet',
                           value: checked as boolean
                        }
                     })
                  }}
               />,
               <AddButton
                  title='Add'
                  buttonText='Add New User'
                  onClick={() => navigate('/user/newuser')}
               />
            ]}
         />
      </ContentCard>
   );

   return html;

}
export default UserIndex;