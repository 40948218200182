import * as React from 'react';
import { CrxEnrollment } from '../store/external/CrxEnrollmentModels';
import { Row, Col } from 'antd';
import Dialog from '../components/Dialog';
import { ActionButton } from '../components/shared/Buttons';
import { BasicTextAreaField } from '../components/shared/BasicInputLibrary';
import Spinner from '../components/Spinner';
import { useFetchCrxEnrollmentPayload } from '../store/external/CrxEnrollmentFetcher';
import { useApiContext } from '../store/ApiContext';

interface IProps {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    selectedItem: CrxEnrollment;
};

const CrxPayloadDialog: React.FC<IProps> = (props) => {
   const { httpGet } = useApiContext();

   const { payload, isLoading } = useFetchCrxEnrollmentPayload(httpGet, props.selectedItem?.interfaceEventId);

    if (isLoading) {
        return <Spinner />
    }

    const html = (
        <Dialog
            title={`Application Id ${props.selectedItem?.apAppId} Details`}
            open={props.isOpen}
            size="large"
            actionButtons={
                <>
                    <ActionButton buttonText='Close' onClick={() => props.setIsOpen(false)} />
                </>
            }>
            <Row>
                <Col span={12}>
                    <BasicTextAreaField
                        name='crxrequest'
                        label='Request'
                        placeholder='No request found'
                        value={payload?.request}
                        style={{ minHeight: '50vh' }} />
                </Col>
                <Col span={12}>
                    <BasicTextAreaField
                        name='crxresponse'
                        placeholder='No response found'
                        label='Response'
                        value={payload?.response}
                        style={{ minHeight: '50vh' }} />
                </Col>
            </Row>
        </Dialog>
    )
    return html;
}

export default CrxPayloadDialog;

