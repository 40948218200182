import * as React from 'react';
import { ReportViewEmbedded } from '../../report/ReportViewEmbedded';

export interface IScorecardReportProps {
   workspaceId: string;
   reportId: string;
   pageId: string;
}

const ScorecardReport: React.FC<IScorecardReportProps> = (props) => {
   const { workspaceId, reportId, pageId } = props;
   const [, setReportRendered] = React.useState<boolean>(false);

   const html = (
      <div style={{ display: 'flex', flexDirection: 'row', height: 'calc(100vh - 120px)' }}>
         <ReportViewEmbedded
            workspaceId={workspaceId}
            reportId={reportId}
            pageId={pageId}
            setReportRendered={setReportRendered}
        />
      </div>
   )

   return html;
}

export default ScorecardReport
