
export interface FormOptionItem {
   id: number;
   name: string;
   value: string;
   optionGroupId: number;
   isDefault: boolean;
   sequence: number;
}

export const OptionGroupId_DefaultsUnitOfMeasure: number = 104
