import { Col, List, Row } from 'antd';
import * as React from 'react';
import { formatDateTimeString } from '../../../functions/format.functions';
import {
   BatchStatusType,
   FundStatusEmailBatch
} from '../../../store/fsa/FundingStatusEmailSentModel';
import colorWheel from '../../../Theme/ColorWheel';
import CustomIcon, { CustomIconType } from '../../shared/AntComponents/CustomIcon';
import { ActionButton } from '../../shared/Buttons';
import TruncateText from '../../shared/TruncateText';
import AlertEmailResendDialog from '../AlertEmailResendDialog';
import { getFromNowDateString } from 'src/functions/time.functions';

interface IProps {
   fundStatusEmailUid: string;
   batchStatus: BatchStatusType;
   batches: FundStatusEmailBatch[];
}

const RecentlySentBatchList: React.FC<IProps> = (props: IProps) => {
   const { fundStatusEmailUid, batchStatus, batches } = props;
   const [isSendAlertModalOpen, setIsSendAlertModalOpen] = React.useState<boolean>(false);

   const handleCancelSendAlertModalClick = () => {
      setIsSendAlertModalOpen(false);
   }

   const handleCloseSendAlertModalClick = () => {
      setIsSendAlertModalOpen(false);
   }

   const buildSentBatchList = (items: FundStatusEmailBatch[]) => {
      return items?.map((item, index) => {
         return batchStatus === BatchStatusType.Finished && (
            <Row key={item.batchUId} style={{ padding: 8 }}>
               <Col span={3}>
                  <Row justify='end'>
                     <Col>
                        <b>{index === 0 ? 'Initial' : `Attempt ${index}`}:</b>
                     </Col>
                  </Row>
               </Col>
               <Col span={11} style={{ paddingLeft: 5 }}>
                  {item.recipientCount} Attempted Recipients <span style={{ paddingLeft: 5 }}>|</span>
                  <span style={{ color: colorWheel.ivyGreen, paddingLeft: 5, paddingRight: 5 }}>{item.successCount} Success</span>
                  {item.errorCount > 0 && <>|<span style={{ color: colorWheel.red, paddingLeft: 5, paddingRight: 5 }}>{item.errorCount} Failed</span></>
                  }
               </Col>
               <Col span={10}>
                  <Row justify='end'>
                     <Col>
                        <>
                           {formatDateTimeString(item.batchSentOn)} ({getFromNowDateString(item.batchSentOn)})
                        </>
                     </Col>
                  </Row>
               </Col>
            </Row>
         );
      });
   }

   return (
      <>
         {buildSentBatchList(batches)}
         {batches?.length > 0 && batches[batches.length - 1].results?.length > 0 && batchStatus === BatchStatusType.Finished &&
            <>
               <Row style={{ padding: 8 }}>
                  <Col style={{ width: '100%' }}>
                     <List
                        style={{ maxHeight: 200, width: '100%', overflow: 'auto' }}
                        bordered
                        rowKey='fundStatusEmailRecipientId'
                        dataSource={batches[batches.length - 1].results}
                        size={'small'}
                        renderItem={(item) => (
                           <List.Item>
                              <Row style={{ width: '100%', fontSize: 'smaller' }}>
                                 <Col span={12}>
                                    {item?.toAddress?.replace(/\"/g, "")}
                                 </Col>
                                 <Col>
                                    <TruncateText fullText={item.result} maxLength={60} />
                                 </Col>
                              </Row>
                           </List.Item>
                        )}
                     />
                  </Col>
               </Row>
               <Row justify='end' style={{ padding: 8 }}>
                  <Col>
                     <ActionButton title='Resend Failures'
                        icon={<CustomIcon type={CustomIconType.MailOutlined} />}
                        onClick={() => setIsSendAlertModalOpen(true)}
                        buttonText='Resend Failures'
                     />
                  </Col>
               </Row>
            </>
         }
         {isSendAlertModalOpen &&
            <AlertEmailResendDialog
               fundStatusEmailUid={fundStatusEmailUid}
               isOpen={isSendAlertModalOpen}
               cancel={() => handleCancelSendAlertModalClick()}
               close={() => handleCloseSendAlertModalClick()}
               clearFundAlerts={() => { //TODO: 
               }}
            />
         }
      </>
   );
}

export default RecentlySentBatchList;
