import * as React from 'react';
import Dialog from '../../Dialog';
import { SaveButton, CancelButton } from '../../shared/Buttons';
import ApiErrorDisplay from '../../ApiErrorDisplay';
import { BasicInputField } from '../../shared/BasicInputLibrary'
import { Specialty } from '../../../store/specialty/SpecialtyModels';
import { useApiContext } from '../../../store/ApiContext';
import { saveSpecialty, specialtyBaseUrl, useFetchSpecialtyList } from '../../../store/specialty/SpecialtyFetcher';
import { TitleError } from '../../shared/AntComponents/Typography/Title';
import { useErrorContext } from '../../../store/ErrorContext';

interface IProps {
   specialty: Specialty;
   isOpen: boolean;
   closeEditor: () => void;
};

const dialogContentStyle = {
   // height: '300px',
}

const _keysLike: string[] = [specialtyBaseUrl];


const SpecialtyEditor: React.FC<IProps> = (props) => {
   const { closeEditor, isOpen, specialty } = props;
   const { removeErrors } = useErrorContext();
   const [editItem, setEditItem] = React.useState<Specialty>({} as Specialty);

   const { httpGet, httpPost } = useApiContext();
   const { error  } = useFetchSpecialtyList(httpGet);
   const [isFormValid, setIsFormValid] = React.useState<boolean>(false);
   const [fieldName, setFieldName] = React.useState<string>(undefined);

   React.useEffect(() => {
      const theSpecialty = specialty ?? {} as Specialty;
      setEditItem(theSpecialty);
      if (specialty) {
         validateForm(specialty);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [specialty]);

   const validateField = (name: string, value: any): boolean => {
      let fieldValid: boolean = true;
      switch (name) {
         case 'name':
            if (!value) {
               setFieldName('Name is required.');
               fieldValid = false;
            }
            if (fieldValid && fieldName) {
               setFieldName(undefined);
            }
            break;
         default:
      }

      if (fieldValid) {
         if (name
         ) {
            if (!isFormValid) {
               setIsFormValid(true);
            }
         } else {
            if (isFormValid) {
               setIsFormValid(false);
            }
         }
      } else {
         setIsFormValid(false);
      }

      return fieldValid;
   }

   const handleNameChange = (value: string): void => {
      setEditItem({
         ...editItem,
         name: value
      });
      validateField('name', value);
   }

   const validateForm = (specialty: Specialty): boolean => {
      let formValid: boolean = editItem ? true : false;
      if (editItem) {
         if (!validateField('name', specialty.name)) formValid = false;
      }
      if (isFormValid !== formValid) setIsFormValid(formValid);
      return formValid;
   }

   const [isFormSaving, setIsFormSaving] = React.useState<boolean>();

   const handleSaveClick = () => {
      if (validateForm(editItem)) {
         setIsFormSaving(true);
         saveSpecialty(httpPost, editItem)
            .then(handleClose)
            .catch(err => console.error('Error Saving Specialty', err))
            .finally(() => {
               setIsFormSaving(false);
            });
      }
   }

   const handleClose = () => {
      setEditItem(undefined);
      setIsFormSaving(false);
      setIsFormValid(undefined);
      setFieldName(undefined);
      removeErrors({ keysLike: _keysLike });
      closeEditor();
   }

   const html = (
      <Dialog
         scrollingContent={true}
         title={`Specialty Editor`}
         open={isOpen}
         size="large"
         actionButtons={
            <>
               <CancelButton onClick={() => {
                  handleClose();
               }} />
               <SaveButton onClick={() => handleSaveClick()} disabled={!isFormValid} loading={isFormSaving} />
            </>
         }>
         <div style={dialogContentStyle}>

            {error && <>
               <TitleError
                  text='Please correct the errors below' />
               {console.log('--------------Form Errors: -------------------------')}
               {console.log(error)}
            </>
            }

            <ApiErrorDisplay
               title='Error saving Specialty'
               keysLike={_keysLike}
            />

            <BasicInputField
               placeholder='Specialty Name'
               name='Name'
               label='Name'
               type='text'
               value={editItem?.name ?? ''}
               error={fieldName}
               onChange={e => handleNameChange(String(e))}
               required={true} />
         </div>

      </Dialog>
   )
   return html;
}

export default SpecialtyEditor;
