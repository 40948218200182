import * as React from 'react';
import { useApiContext } from '../../store/ApiContext';
import { FaxLogViewModel } from '../../store/fax/FaxLogModels';
import { getResendFaxUrl, resendFax } from '../../store/fax/FaxFetcher';
import ApiErrorDisplay from '../ApiErrorDisplay';
import Spinner from '../Spinner';
import Dialog from '../Dialog';
import { BasicFieldWrapper } from '../shared/BasicInputLibrary';
import { ActionButton, CancelButton } from '../shared/Buttons';
import { Alert } from 'antd';
import CustomIcon, { CustomIconType } from '../shared/AntComponents/CustomIcon';
import { useErrorContext } from '../../store/ErrorContext';

interface IProps {
   faxLog: FaxLogViewModel;
   isOpen: boolean;
   close: () => void;
}

const FaxResend: React.FC<IProps> = (props) => {

   const { faxLog, isOpen, close } = props;
   const _keys: string[] = [getResendFaxUrl(faxLog?.id)]
   const { httpPut } = useApiContext();
   const { removeErrors } = useErrorContext();
   const [isSending, setIsSending] = React.useState<boolean>(false);
   const [sendMessage, setSendMessage] = React.useState<string>();
   const [isSuccess, setIsSuccess] = React.useState<boolean>(false);
   
   const onClose = () => {
      removeErrors({ keys: _keys })
      close();
   }
   
   return (
      <Dialog
         scrollingContent={true}
         title={'Resend Fax'}
         open={isOpen}
         size={'tiny'}
         closeIcon={true}
         pending={isSending}
         actionButtons={
            <>
               <CancelButton buttonText='Close' disabled={isSending} onClick={onClose} />
               <ActionButton
                  buttonText='resend'
                  icon={<CustomIcon type={CustomIconType.PrinterOutlined} />}
                  loading={isSending}
                  disabled={isSuccess}
                  onClick={async () => {
                     setSendMessage(undefined);
                     setIsSuccess(undefined);
                     setIsSending(true);
                     try {
                        await resendFax(httpPut, faxLog.id);
                        setIsSuccess(true);
                        setSendMessage("Fax send queued to Azure Function successfully");
                     } catch (err) {
                        console.error(`Fax Failed: ${faxLog.id}`)
                        console.error(`Fax Err`, err)
                        setSendMessage("Error queuing fax");
                        setIsSuccess(false);
                     }
                     finally {
                        setIsSending(false);
                     }
                  }} />
            </>
         }>
         <ApiErrorDisplay title='Api Error' keys={_keys} />
         {isSending &&
            <Spinner />
         }
         {sendMessage &&
            <Alert message={sendMessage} type={isSuccess ? 'success' : 'error' } />
            }
         <BasicFieldWrapper
            label='FaxLogId'
            field={faxLog.id}
         />
         <BasicFieldWrapper
            label='Fax To Company'
            field={faxLog.faxToCompanyName}
         />
         <BasicFieldWrapper
            label='Fax Number'
            field={faxLog.faxToNumber}
         />
         <BasicFieldWrapper
            label='PracticeId'
            field={faxLog.practiceId}
         />
         <BasicFieldWrapper
            label='DocumentId'
            field={faxLog.faxDocumentGuid}
         />           
      </Dialog>
   );
}

export default FaxResend;