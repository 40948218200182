import * as React from 'react';
import { Form, Row, Col, Divider } from 'antd';
import CustomIcon, { CustomIconType } from '../../shared/AntComponents/CustomIcon'
import { useForm } from 'react-hook-form';
import { InputField, DropdownField, RadioListField, TextAreaField } from '../../shared/InputLibrary';
import { BasicDropdownField, BasicInputField } from '../../shared/BasicInputLibrary';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Dialog from '../../Dialog';
import { SaveButton, CancelButton, ActionButton, AddButton } from '../../shared/Buttons';
import Spinner from '../../Spinner';
import { usePracticeOptions } from '../../../store/practice/PracticeFetcher';
import { useAssistanceProgramNameOptions, useFetchAssistancePrograms } from '../../../store/program/AssistanceProgramFetcher';
import { xrefStatusOptions, PracticeServiceToApXRef, IAdditionalProgram, MAPPED_STATUS } from '../../../store/content-tool/PracticeServiceToApXRefModel';
import {
   savePracticeServiceToApXRef,
   practiceServiceToApXRefBaseUrl
} from '../../../store/content-tool/PracticeServiceToApXRefFetcher';
import ApiErrorDisplay from '../../ApiErrorDisplay';
import colorWheel from '../../../Theme/ColorWheel';
import { formatDateTimeString } from '../../../functions/format.functions';
import { TitleError } from '../../shared/AntComponents/Typography/Title';
import { useApiContext } from '../../../store/ApiContext';
import { programTypeOptions } from '../../../store/program/AssistanceProgramModel';
import { useErrorContext } from '../../../store/ErrorContext';

interface IProps {
    isOpen: boolean;
    closeEditor: () => void;
    editFrom?: PracticeServiceToApXRef;
    newFrom?: PracticeServiceToApXRef;
};

const dialogContentStyle = {
    height: '580px',
    maxHeight: '95vh',
}

const yupPracticeServiceToApXRefSchema = yup.object({
    id: yup.number().notRequired(),
    practiceId: yup.number().notRequired(),
    insurancePlan: yup.string()
      .max(255, 'Max length is 255 characters')
      .notRequired(),
    assistanceProgramId: yup.number()
      .nullable()
      .when('status', {
         is: MAPPED_STATUS,
         then: () => yup.number().required('Assistance Program is required when mapped'),
         otherwise: () => yup.number().notRequired()
      })
      .transform((_, val) => val === '' ? null : Number(val))
   ,
    programName: yup.string()
      .max(255, 'Max length is 255 characters')
      .notRequired(),
    programType: yup.number().notRequired(),
    clientTag: yup.string()
      .max(25, 'Max length is 25 characters')
      .notRequired(),
    status: yup.string()
      .required('Status is required')
      .max(16, 'Max length is 16 characters'),
    insuranceGroup: yup.string()
      .max(255, 'Max length is 255 characters')
      .notRequired(),
    notes: yup.string().notRequired(),
    createdBy: yup.string().notRequired(),
    createdOn: yup.date().notRequired(),
    modifiedBy: yup.string().notRequired(),
    modifiedOn: yup.date().notRequired(),

    //additionalPrograms: yup.array()
    //   .of(
    //      yup.object().shape({
    //         assistanceProgramId: yup.number().notRequired(),
    //         status: yup.string()
    //            .required('Status is required')
    //            .max(16, 'Max length is 16 characters'),
    //      })
    //   )
});


interface IPracticeServiceToApXRefYup extends yup.Asserts<typeof yupPracticeServiceToApXRefSchema> { }
const __formId = "frmPracticeServiceToApXRef";
const _keysLike: string[] = [practiceServiceToApXRefBaseUrl];

const PracticeServiceToApXRefEditor: React.FC<IProps> = (props) => {
   const { isOpen , closeEditor, editFrom, newFrom } = props;

   const { httpGet, httpPost } = useApiContext();
   const { removeErrors } = useErrorContext();
   const [additionalPrograms, setAdditionalPrograms] = React.useState<IAdditionalProgram[]>([]);
   const [isFormSaving, setIsFormSaving] = React.useState<boolean>(false);

   const { practiceOptions } = usePracticeOptions(httpGet);
   const { assistancePrograms } = useFetchAssistancePrograms(httpGet);
   const { assistanceProgramNameOptions } = useAssistanceProgramNameOptions(httpGet);
   const _addNewRef = React.useRef(null);

   const itemToEdit = React.useMemo(() => {
      return editFrom !== undefined ?
         editFrom :
         newFrom !== undefined ?
            newFrom :
            {} as PracticeServiceToApXRef
   }, [editFrom, newFrom]);

   const {
      //register,
      control,
      handleSubmit,
      formState: { errors },
      reset,
      setValue,
      getValues,
      trigger
   } = useForm<IPracticeServiceToApXRefYup>({
      resolver: yupResolver<yup.AnyObject>(yupPracticeServiceToApXRefSchema),
      shouldFocusError: true,
   });

   React.useEffect(() => {
      setIsFormSaving(false);
      setAdditionalPrograms([]);

      reset(itemToEdit ?? { status: 'New' });
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [itemToEdit, isOpen]);
     
   const [form] = Form.useForm();

   const onSubmit = (data: IPracticeServiceToApXRefYup): void => {
      setIsFormSaving(true);

      const item = data as unknown as PracticeServiceToApXRef;

      const itemList = [item];
      if (additionalPrograms?.length > 0) {
         additionalPrograms.map((newProgram) => {

            const p = assistancePrograms.find(y => y.id === newProgram.assistanceProgramId);

            const newItem = {
               practiceId: item.practiceId,
               clientTag: item.clientTag,
               insurancePlan: item.insurancePlan,
               notes: item.notes,
               insuranceGroup: item.insuranceGroup,
               programName: p?.programName,
               programType: p?.programType,
               assistanceProgramId: newProgram.assistanceProgramId,
               status: newProgram.status,
            } as PracticeServiceToApXRef;

            itemList.push(newItem);
            return newItem;
         });
      }

      savePracticeServiceToApXRef(httpPost, itemList)
         .then(handleClose)
         .catch(err => console.error('Error calling savePracticeServiceToApXRef', err))
         .finally(() => setIsFormSaving(false));
   }

   const handleClose = () => {
      setIsFormSaving(false);
      removeErrors({ keysLike: _keysLike });
      setAdditionalPrograms([]);
      closeEditor();
   }

   if (isFormSaving && isOpen) {
      return <Spinner message='Saving PracticeServiceToApXRef...' />
   }

   //console.log('--------------PracticeServiceToApXRefEditor-------------------------')
   //console.log(errors)

   const html = (
      <Dialog
         scrollingContent={true}
         title={'PracticeServiceToApXRef Editor'}
         open={isOpen}
         size="large"
         actionButtons={
            <>
               <CancelButton onClick={() => handleClose()} loading={isFormSaving} />
               <SaveButton
                  formId={__formId}
                  onClick={() => null}
                  disabled={Object.keys(errors).length > 0}
                  loading={isFormSaving} />
            </>
         }>
         <div style={dialogContentStyle}>

            {errors && Object.keys(errors).length > 0 &&
               <>
                  <TitleError text='Please correct the errors below' />
                  {console.log('--------------Form Errors: -------------------------')}
                  {console.log(errors)}
               </>
            }

            <ApiErrorDisplay
               title='Error saving PracticeServiceToApXRef'
               keysLike={_keysLike}
            />

            <Form
               form={form}
               id={__formId}
               onFinish={handleSubmit(onSubmit)}>

               <Row gutter={[16, 16]}>
                  <Col span={12}>
                     <DropdownField
                        control={control}
                        name='practiceId'
                        label='Practice'
                        placeholder={itemToEdit?.practiceId === undefined ? ' - Select a Practice -' : `${itemToEdit?.practiceId} Practice not in current system`}
                        error={errors?.practiceId}
                        multiple={false}
                        options={practiceOptions}
                        setValue={setValue}
                        required={false}
                        search={true}
                        clearable={true}
                        disabled={itemToEdit && itemToEdit.practiceId ? true : false}
                     />
                  </Col>
                  <Col span={12}>
                     <InputField
                        control={control}
                        name='clientTag'
                        label='Client Tag'
                        type='text'
                        error={errors?.clientTag}
                        required={false}
                        disabled={itemToEdit && itemToEdit.clientTag ? true : false}
                     />
                  </Col>
               </Row>
               <Row gutter={[16, 16]}>
                  <Col>
                     <InputField
                        control={control}
                        name='insurancePlan'
                        label='Insurance Plan'
                        type='text'
                        error={errors?.insurancePlan}
                        required={false}
                        disabled={itemToEdit && itemToEdit.insurancePlan ? true : false}
                     />
                  </Col>
               </Row>
               <Row gutter={[16, 16]}>
                  <Col span={8} >
                     <DropdownField
                        control={control}
                        name='status'
                        label='Status'
                        error={errors?.status}
                        multiple={false}
                        options={xrefStatusOptions}
                        setValue={setValue}
                        required={true}
                        search={true}
                        onChange={val => trigger()}
                     />
                  </Col>
                  <Col flex="auto">
                     <DropdownField
                        control={control}
                        name='assistanceProgramId'
                        label='Assistance Program'
                        error={errors?.assistanceProgramId}
                        multiple={false}
                        options={assistanceProgramNameOptions}
                        setValue={setValue}
                        required={getValues()?.status === MAPPED_STATUS}
                        search={true}
                        clearable={true}
                        onChange={(value) => {
                           const program = assistancePrograms?.find(y => y.id === Number(value));

                           setValue('programType', program?.programType);
                           setValue('programName', program?.programName);
                        }}
                     />
                  </Col>
                  <Col flex="auto">
                     <RadioListField
                        control={control}
                        name='programType'
                        label='Program Type'
                        direction='vertical'
                        error={errors?.programType}
                        options={programTypeOptions}
                        setValue={setValue}
                        required={false}
                        disabled={true}
                     />
                  </Col>
               </Row>
               <Row gutter={[16, 16]}>
                  <Col span={24}>
                     <TextAreaField
                        control={control}
                        name='notes'
                        label='Notes'

                        error={errors?.notes}
                        required={false}
                     />
                  </Col>
               </Row>

               <Divider>Additional XRef Records</Divider>

               {additionalPrograms?.length > 0 && additionalPrograms?.map((item, index) => {
                  return <Row gutter={[16, 16]} key={index} align="middle">
                     <Col span={1} />
                     <Col span={6}>
                        <BasicDropdownField
                           name='apStatus'
                           value={item.status}
                           label='Status'
                           placeholder=' - Select a Status - '
                           options={xrefStatusOptions}
                           required={true}
                           search={false}
                           clearable={false}
                           onChange={val => {
                              const newArray = [...additionalPrograms];
                              newArray[index].status = String(val);
                              setAdditionalPrograms([...newArray]);
                           }}
                        />
                     </Col>
                     <Col span={8}>
                        <BasicDropdownField
                           name='assistanceProgram'
                           value={item.assistanceProgramId}
                           label='Assistance Program'
                           placeholder=' - Select an Assistance Program - '
                           options={assistanceProgramNameOptions}
                           required={false}
                           search={true}
                           clearable={false}
                           onChange={val => {
                              const apid = Number(val);
                              const newArray = [...additionalPrograms];
                              newArray[index].assistanceProgramId = apid;
                              setAdditionalPrograms([...newArray]);
                           }}
                        />
                     </Col>
                     <Col span={2}>
                        <ActionButton
                           danger={true}
                           icon={<CustomIcon type={CustomIconType.DeleteOutlined} style={{ color: colorWheel.red }} />}
                           onClick={() => {
                              const newArray = [...additionalPrograms];
                              newArray.splice(index, 1);
                              setAdditionalPrograms([...newArray]);
                           }}
                        />
                     </Col>
                     <Col span={2} />
                  </Row>
               })}

               <Row gutter={[16, 16]}>
                  <Col>
                     <AddButton
                        buttonText='New Program'
                        onClick={() => {
                           let newProgram = {
                              status: 'Mapped'
                           } as IAdditionalProgram;
                           setAdditionalPrograms([
                              ...additionalPrograms,
                              newProgram as IAdditionalProgram
                           ]);
                           if (_addNewRef?.current) {
                              setTimeout(() => _addNewRef.current.scrollIntoView({ block: "start", behavior: "smooth" }), 100);
                           }
                        }}
                     />
                  </Col>
               </Row>
               <div style={{ height: 0 }} ref={_addNewRef} />
               <Divider></Divider>
               {itemToEdit?.id && <>
                  <Row gutter={[16, 16]}>
                     <Col span={8}>
                        <BasicInputField
                           name='createdBy'
                           label='Created By'
                           value={itemToEdit.createdBy}
                           disabled={true}
                        />
                     </Col>
                     <Col span={8}>
                        <BasicInputField
                           name='createdOn'
                           label='Created On'
                           value={formatDateTimeString(itemToEdit.createdOn)}
                           disabled={true}
                        />
                     </Col>
                  </Row>
                  <Row gutter={[16, 16]}>
                     <Col span={8}>
                        <BasicInputField
                           name='modifiedBy'
                           label='Modified By'
                           value={itemToEdit.modifiedBy}
                           disabled={true}
                        />
                     </Col>
                     <Col span={8}>
                        <BasicInputField
                           name='modifiedOn'
                           label='Modified On'
                           value={formatDateTimeString(itemToEdit.modifiedOn)}
                           disabled={true}
                        />
                     </Col>
                  </Row>
               </>}
            </Form>

         </div>

      </Dialog>
   )
   return html;
}

export default PracticeServiceToApXRefEditor;
