import { useFetchSetting } from "../store/SettingsFetcher";
import { KnownSettings } from "../store/SettingsModel";
import colorWheel from "../Theme/ColorWheel";
import EmailDisplay from "./EmailDisplay";



const EnvironmentDisplay: React.FC = () => {
   const environment = useFetchSetting(KnownSettings.Environment);
   const isProduction = environment?.toLocaleLowerCase() === 'live' // 'live'
   const defaultStyle = { padding: '5px' }

   return (
      <div style={isProduction ?
         {
            ...defaultStyle,
            border: `solid 2px ${colorWheel.white}`,
         } :
         defaultStyle
      }
      >
         {isProduction ? environment?.toLocaleUpperCase() : environment}: &nbsp;
         <EmailDisplay />
      </div>
   );
}

export default EnvironmentDisplay;