import useSWR, { mutate } from 'swr';
import { HttpError, TGet, TGetByteUrl, TPut } from '../../functions/httpClient';
import { FaxAuditLogQueryResult, FaxStatusResult } from './FaxLogModels';
import { mutateSearch } from '../../functions/swr.functions';
import { _lastFetchKey_FaxLog, searchFaxesUrl } from './FaxLogFetcher';

export const faxBaseUrl = `api/Fax`;
export const getAttachmentUrl = (faxLogId: number) => `${faxBaseUrl}/GetAttachment/${faxLogId}`;
export const getFaxAuditLogsBaseUrl = `${faxBaseUrl}/GetFaxAuditLogs`;
const getFaxAuditLogsUrl = (faxLogId: number) => `${getFaxAuditLogsBaseUrl}/${faxLogId}`;
export const getFaxStatusUrl = (faxLogId: number) => `${faxBaseUrl}/GetFaxStatus/${faxLogId}`;
export const getResendFaxUrl = (faxLogId: number) => `${faxBaseUrl}/ResendFax/${faxLogId}`;

const swrOptions = {
   refreshInterval: 0,
   revalidateOnFocus: false,
   dedupingInterval: 60000
};

export const getFaxAttachment = async (httpGetByteUrl: TGetByteUrl, faxLogId: number) => {
   const targetUrl = getAttachmentUrl(faxLogId);
   const data: any = await httpGetByteUrl(targetUrl, "application/pdf")
      .then(response => {
         mutate(getFaxAuditLogsUrl(faxLogId));
         mutateSearch(searchFaxesUrl, _lastFetchKey_FaxLog);
         return response;
      })
      .catch(reason => console.error(`Failed to load FaxAttachment ${targetUrl}`, reason));
   
   return data;
}

export const useFaxAuditLogs = (httpGet: TGet, faxLogId: number) => {
   const { data, isLoading, error } = useSWR<FaxAuditLogQueryResult[], HttpError>(
      getFaxAuditLogsUrl(faxLogId),
      httpGet,
      swrOptions
   );

   return {
      faxAuditLogs: data,
      isLoading: isLoading,
      error: error
   };
}

export const resendFax = async (httpPut: TPut, faxLogId: number) => {
   const targetUrl = getResendFaxUrl(faxLogId);
   const data: any = await httpPut(targetUrl)
      .then(response => {
         setTimeout(() => {
            mutate(getFaxAuditLogsUrl(faxLogId));
            mutateSearch(searchFaxesUrl, _lastFetchKey_FaxLog);
         }, 5000)
         return response;
      })
      .catch(reason => console.error(`Failed to Fax Attachment ${targetUrl}`, reason));

   return data;
}

export const useFaxStatus = (httpGet: TGet, faxLogId: number) => {

   const { data, isLoading, error } = useSWR<FaxStatusResult, HttpError>(
      getFaxStatusUrl(faxLogId),
      httpGet,
      swrOptions
   );

   mutate(getFaxAuditLogsUrl(faxLogId));
   mutateSearch(searchFaxesUrl, _lastFetchKey_FaxLog);

   return {
      data: data,
      isLoading: isLoading,
      error: error?.message
   };
}

