import { IOptionItem } from '../../functions/option.functions';
import { NameAndId } from '../models';

export interface AssistanceService {
   id: number;
   assistanceServiceName: string;
   assistanceType: number;
   description: string;
   assistanceProgramId: number;
   assistanceId: number;
   isExternallyUpdated: boolean;
   serviceCategoryId?: number;
   duplicateRules: string;
   //    programName: string;
   hideInWizard: boolean;
}

//Porting AssistanceService editor: extending Assistance Service
export interface AssistanceServiceWithFoundation extends AssistanceService {
   foundationId: number;
}

export interface AssistanceServiceManufacturerEligibilityCriteria extends AssistanceService {
   brandId: number;
   brandName: string;
   assistanceServiceToDrugCount?: number;
   hasRelatedEligibilityCriteria: boolean;
}

export interface AssistanceServiceFoundationEligibilityCriteria extends AssistanceService {
   foundationDiseaseTypeId: number;
   foundationDiseaseTypeName: string;
   fundingStatusCount?: number;
   hasRelatedEligibilityCriteria: boolean;
}

export interface AssistanceServiceAggregateBrandDefaults extends AssistanceService {
   brandId: number;
   brandName: string;
   assistanceServiceToDrugCount?: number;
   hasRelatedBrandDefaults: boolean;
}

export interface AssistanceServiceAggregateFoundationDefaults extends AssistanceService {
   foundationDiseaseTypeId: number;
   foundationDiseaseTypeName: string;
   fundingStatusCount?: number;
   hasRelatedFoundationDefaults: boolean;
}

export const AssistanceType: NameAndId[] = [
   { id: 1, name: 'Co-Pay Assistance' }
   , { id: 2, name: 'Drug Assistance' }
   , { id: 3, name: 'Additional Service' }
];

export const assistanceTypeOptions = (): IOptionItem[] =>
   AssistanceType.map(at => ({ label: at.name, value: at.id }));