import * as React from 'react';
import { useApiContext } from '../../store/ApiContext';
import { FaxLogViewModel } from '../../store/fax/FaxLogModels';
import { getFaxStatusUrl, useFaxStatus } from '../../store/fax/FaxFetcher';
import ApiErrorDisplay from '../ApiErrorDisplay';
import Spinner from '../Spinner';
import Dialog from '../Dialog';
import { BasicFieldWrapper } from '../shared/BasicInputLibrary';
import { formatDateTimeString } from '../../functions/format.functions';
import { useErrorContext } from '../../store/ErrorContext';

interface IProps {
   faxLog: FaxLogViewModel;
   isOpen: boolean;
   close: () => void;
}

const FaxStatusUpdate: React.FC<IProps> = (props) => {

   const { faxLog, isOpen, close } = props;
   const _keys: string[] = [getFaxStatusUrl(faxLog?.id)]
   const { httpGet } = useApiContext();
   const { removeErrors } = useErrorContext();

   const { data, isLoading, error } = useFaxStatus(httpGet, faxLog.id);
   
   const onClose = () => {
      removeErrors({ keys: _keys })
      close();
   }
   
   return (
      <Dialog
         scrollingContent={true}
         title={'Fax Status'}
         open={isOpen}
         size={'tiny'}
         closeIcon={true}
         onClose={() => onClose()}
         pending={isLoading }
      >
         <ApiErrorDisplay title='Api Error' keys={_keys} />
         {isLoading &&
            <Spinner />
         }
         {data &&
            <>
            <BasicFieldWrapper
               label='Status'
               field={data?.status}
            />
            <BasicFieldWrapper
               label='Result'
               field={data?.result}
            />
            <BasicFieldWrapper
               label='LastStatusUpdate'
               field={formatDateTimeString(data?.lastStatusUpdate)}
            />
            {error && 
               <BasicFieldWrapper
                  label='Message'
                  field={error}
               />
            }
               </>
               }
      </Dialog>
   );
}

export default FaxStatusUpdate;