import { Alert, Col, Row, Tag, Typography } from 'antd';
import dayjs from 'dayjs';
import { CSSProperties } from 'react';
import { useNavigate } from 'react-router';
import { UnsafeAppInsights } from './InsightsContext';
import { useUserContext } from './auth/authContext';
import CustomIcon, { CustomIconType } from './components/shared/AntComponents/CustomIcon';
import ImageContainer from './components/shared/AntComponents/ImageContainer';
import { IconButton } from './components/shared/Buttons';

interface IUnhandledErrorProps {
   error?: Error
}

const baseLayer = {
   position: 'fixed',
   zIndex: '10000',
   left: 0,
   top: 0,
   width: '80%',
   maxWidth: '80vw',
   height: '100%',
   overflow: 'hidden',
   backgroundColor: 'rgba(0,0,0,0.4)'
};

const topLayer = {
   position: 'fixed',
   zIndex: '10001',
   top: '50%',
   left: '50%',
   transform: 'translate(-50%, -50%)',
   backgroundColor: '#fff',
   border: '1px solid #888',
   borderRadius: '5px',
   transition: '1.1s ease-out',
   boxShadow: '2rem 2rem 2rem rgba(0, 0, 0, 0.2)',
   width: '80%',
   maxWidth: '80vw',
   height: '100%',
   overflow: 'hidden'
}

//A2-1103: Add a global error page to handle errors
const UnhandledError: React.FC<IUnhandledErrorProps> = (props) => {
   const appInsights = UnsafeAppInsights;
   const { user } = useUserContext();
   const navigate = useNavigate();
   const { Title } = Typography;
   const { error } = props;

   return <div style={baseLayer as CSSProperties}>
      <div style={topLayer as CSSProperties}>
         <Row gutter={[24, 24]}>
            <Col span={5}>
               <ImageContainer imageSrc='/code_runner.png' styles={{ 'width': 275, padding: '1rem' }} />
            </Col>
            <Col span={14}>
               <Title>
                  <div style={{ paddingTop: 20 }}>
                     Meep! Something has gone terribly wrong...
                  </div>
               </Title>

               You have encountered an unexpected error.  Something happened that we did not foresee.

               <Alert style={{ padding: '10px', margin: '10px 0' }}
                  message="There are a couple of things you can try:"
                  description={<ul>
                     <li>Refresh your browser <IconButton
                        icon={<CustomIcon type={CustomIconType.ReloadOutlined} />}
                        onClick={() => navigate(0)} /></li>
                     <li>Close and Reopen your browser</li>
                     <li>Contact our support staff at 844-972-7747 or apsupport@annexushealth.com</li>
                  </ul>}
                  type="info"
               />

               If this error persists, please contact support and provide them with a screenshot of this page to help us quickly identify and resolve any issues.

               <Row gutter={[16, 16]} style={{ marginTop: '8px' }}>
                  {user && <>
                     <Col span={5} style={{ textAlign: 'end' }}>
                        <Tag>User </Tag>
                     </Col>
                     <Col span={19}>
                        {user.username}
                     </Col>
                  </>
                  }
                  <Col span={5} style={{ textAlign: 'end' }}>
                     <Tag>Location </Tag>
                  </Col>
                  <Col span={19}>
                     {window.location.href}
                  </Col>
                  {appInsights && appInsights?.context &&
                     <>
                        <Col span={5} style={{ textAlign: 'end' }}>
                           <Tag>Session Id</Tag>
                        </Col>
                        <Col span={19}>
                           <code>{appInsights.context.getSessionId()}</code>
                        </Col>
                     </>
                  }
                  {appInsights && appInsights?.context?.telemetryTrace &&
                     <>
                        <Col span={5} style={{ textAlign: 'end' }}>
                           <Tag>Operation Id</Tag>
                        </Col>
                        <Col span={19}>
                           <code>{appInsights.context.telemetryTrace.traceID}</code>
                        </Col>
                     </>
                  }
                  <Col span={5} style={{ textAlign: 'end' }}>
                     <Tag>UTC Timestamp </Tag>
                  </Col>
                  <Col span={19}>
                     <code>{dayjs().format('YYYY-MM-DDTHH:mm:ssZ')}</code>
                  </Col>
                  {error && error.message &&
                     <>
                        <Col span={5} style={{ textAlign: 'end' }}>
                           <Tag>Error </Tag>
                        </Col>
                        <Col span={19}>
                           {error.message.replace('Error: ', '')}
                        </Col>
                     </>
                  }
                  {error && error?.stack &&
                     <>
                        <Col span={5} style={{ textAlign: 'end' }}>
                           <Tag>Trace </Tag>
                        </Col>
                        <Col span={19}>
                           {JSON.stringify(error, Object.getOwnPropertyNames(error).filter(y => y !== 'message'), 2)}
                        </Col>
                     </>
                  }
               </Row>
            </Col>
            <Col span={5}>

            </Col>
         </Row>
      </div>
   </div>
}
export default UnhandledError;
