import useSWR, { mutate, SWRConfiguration } from 'swr';
import { CohortSummary } from './CohortSummaryModel';
import { HttpError, TPost } from '../../functions/httpClient';
import { useEffect, useState, useMemo } from 'react';
import { getFormattedDateForApi } from '../../functions/format.functions';

export const cohortSummaryBaseUrl = `api/cohort/cohortsummary`;
export const findCohortSummaryUrl = `${cohortSummaryBaseUrl}/FindCohortSummary`;

const swrOptions: SWRConfiguration = {
   refreshInterval: 0,
   revalidateOnFocus: false,
   dedupingInterval: 60000
};

export const useFetchSearchCohortSummary = (httpPost: TPost, fromDate: Date) => {

   const params = useMemo(() => {
      return { fromDate: getFormattedDateForApi(fromDate) }
   }, [fromDate]);

   const fetcher = () => httpPost<CohortSummary[]>(findCohortSummaryUrl, params);

   const { data, isValidating, error } = useSWR<CohortSummary[], HttpError>(
      [findCohortSummaryUrl, params],
      fetcher,
      swrOptions
   );

   return {
      results: data,
      isLoading: !error && !data && isValidating,
      error: error as HttpError
   };
}

export const useFetchCurrentCohortSummary = (httpPost: TPost, fromDate: Date, id: number) => {
   const { results, error, isLoading } = useFetchSearchCohortSummary(httpPost, fromDate);
   const [currentCohort, setCurrentCohort] = useState<CohortSummary>();

   useEffect(() => {
      const getCurrentCohort = () => {
         if (results?.length > 0) {
            const match = results.find(y => y.cohortId === id);
            setCurrentCohort(match);
         }
      }
      getCurrentCohort();
   }, [results, id]);

   return {
      currentCohort,
      isLoading,
      error
   };
}
