import { NotificationInstance } from 'antd/es/notification/interface';
import colorWheel from '../../Theme/ColorWheel';
import CustomIcon, { CustomIconType } from "./AntComponents/CustomIcon";

// builds and opens the notification
export const openEmailSendNotification = (notificationApi: NotificationInstance) => {
   notificationApi.success({
      icon: <CustomIcon type={CustomIconType.BellOutlined} style={{ color: colorWheel.plumPurple }} />,
      message: <b>Email Sending</b>,
      description: `The email is now sending. Please see the Notifications area for more details.`,
      placement: 'bottomLeft'
   });
}

// settings for configuring ant notification
export const emailSendNotificationConfiguration = {
   bottom: 36
};