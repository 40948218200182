import * as React from 'react';
import { useReports } from '../ReportContext';
import ReportCollectionMenu from './ReportCollectionMenu';

const FavoriteMenu: React.FC = () => {
   const { powerBIReports, favoritedReportIds, menuIsCollapsed } = useReports();
   const filteredReports = powerBIReports?.filter(r => favoritedReportIds?.includes(r.id));

   const html = (<>
      {filteredReports?.length <= 0 &&
         <>
            <p>You don't have any favorites saved.</p>
            <br />
            <p>To favorite a report, click on the favorite button underneath the report description.</p>
         </>
      }

      {filteredReports?.length > 0 &&
         <ReportCollectionMenu
         reports={filteredReports}
         isCollapsed={menuIsCollapsed}
         showBreadcrumb
         showCertifiedAttribute
      />
      }
      </>
   )

   return html;
}
export default FavoriteMenu;
