import * as React from 'react';
import Card from '../layouts/Card';
import { useLayoutContext } from '../layouts/LayoutContext';

import { useFetchSetting } from '../store/SettingsFetcher';
import { KnownSettings } from '../store/SettingsModel';

const Help: React.FC = () => {
   const { menuProps } = useLayoutContext();

   const emailString: string = useFetchSetting(KnownSettings.SupportEmail);
   const phoneString: string = useFetchSetting(KnownSettings.SupportPhone);

   const html = (
      <div className="ap-center">
         <div>
            <span>{phoneString}</span>
         </div>
         <div>
            <a href={`mailto:${emailString}`}>{emailString}</a>
         </div>
      </div>
   );
   return html;
};

export default Help;
