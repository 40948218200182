import * as React from 'react';
import { useApiContext } from '../../store/ApiContext';
import { FaxLogViewModel } from '../../store/fax/FaxLogModels';
import { getAttachmentUrl, getFaxAttachment } from '../../store/fax/FaxFetcher';
import ApiErrorDisplay from '../ApiErrorDisplay';
import Spinner from '../Spinner';
import Dialog from '../Dialog';
import { BasicFieldWrapper } from '../shared/BasicInputLibrary';
import { formatDateTimeString } from '../../functions/format.functions';
import { useErrorContext } from '../../store/ErrorContext';

interface IProps {
   faxLog: FaxLogViewModel;
   isOpen: boolean;
   close: () => void;
}

const FaxPreview: React.FC<IProps> = (props) => {
   const { faxLog, isOpen, close } = props;
   const _keys: string[] = [getAttachmentUrl(faxLog?.id)];
   const { httpGetByteUrl } = useApiContext();
   const { removeErrors } = useErrorContext();

   const [isLoading, setIsLoading] = React.useState(false);
   const [errorMessage, setErrorMessage] = React.useState<string>();
   
   const onClose = () => {
      setIsLoading(false);
      removeErrors({ keys: _keys })
      close();
   }

   React.useEffect(() => {
      if (!faxLog?.externalFaxId && faxLog?.externalFaxId?.toLowerCase() !== 'unknown') {
         setIsLoading(false);
         close();
         return;
      }

      const title = `FaxTo: ${faxLog.faxToNumber} - ${faxLog.faxToCompanyName}`;

      setIsLoading(true);
      const _fetch = async () => {
         let fileUrl: string = null;
         let didError = false;
         try {
            fileUrl = await getFaxAttachment(httpGetByteUrl, faxLog.id);
            if (fileUrl) {
               const pdfWindow = window.open('about:blank', 'Fax Document', 'width=800,height:1000');
               pdfWindow.location.href = fileUrl;
               //NOTE: this does not work :(
               pdfWindow.document.title = title;
               setErrorMessage(undefined);
            } else {
               setErrorMessage(`Fax Not Found - Check back later`)
            }
         } catch (err) {
            didError = true;
            console.log(`Fax File: ${fileUrl}`)
         }
         finally {
            // we were checking "errorMessages" was empty (useApiContext: apiErrors.filter([url filter func])) here before so
            if (fileUrl && !didError) {
               onClose();
            }
            setIsLoading(false);
         }
      }
      _fetch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen, faxLog]);

   return (
      <Dialog
         scrollingContent={true}
         title={'View Faxed Document'}
         open={isOpen}
         size={'tiny'}
         closeIcon={true}
         onClose={() => onClose()}
         pending={isLoading }
      >
         <ApiErrorDisplay title='Api Error' keys={_keys} />
         {isLoading &&
            <Spinner />
         }
         <BasicFieldWrapper
            label='Status'
            field={faxLog.status}
         />
         <BasicFieldWrapper
            label='LastStatusUpdate'
            field={formatDateTimeString(faxLog.lastStatusUpdate)}
         />
         {errorMessage && 
            <BasicFieldWrapper
               label='Error Message'
               field={errorMessage}
               />
         }
      </Dialog>
   );
}

export default FaxPreview;