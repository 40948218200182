import * as React from 'react';
import { useApiContext } from '../../store/ApiContext';
import { useErrorContext } from '../../store/ErrorContext';
import { FundStatusAlertFundEmailRequest } from '../../store/fsa/FundStatusAlertModel';
import { fundStatusEmailBaseUrl, sendFSAEmailRetry, useGetFSAEmailRetry } from '../../store/fsa/FundStatusEmailFetcher';
import ApiErrorDisplay from '../ApiErrorDisplay';
import Dialog from '../Dialog';
import CustomIcon, { CustomIconType } from '../shared/AntComponents/CustomIcon';
import { ActionButton, CancelButton } from '../shared/Buttons';
import FundStatusAlertEmail from './FundStatusAlertEmail';
import { useFundStatusContext } from './FundStatusContext';
import { HttpError } from '../../functions/httpClient';

interface IProps {
   fundStatusEmailUid: string,
   clearFundAlerts: () => void;
   isOpen: boolean;
   close: () => void;
   cancel: () => void;
};

const dialogContentStyle = {
   minHeight: '60vh',
   overflow: 'auto'
}

const _keysLike: string[] = [fundStatusEmailBaseUrl];

const AlertEmailResendDialog: React.FC<IProps> = (props) => {
   const { httpGet, httpPost, apiErrors, removeError } = useApiContext();
   const { sendNotify } = useFundStatusContext();
   const { isOpen, close, cancel, fundStatusEmailUid, clearFundAlerts } = props;
   const { emailMessage } = useGetFSAEmailRetry(httpGet, fundStatusEmailUid);
   const [isSending, setIsSending] = React.useState<boolean>(false);
   const { removeErrors } = useErrorContext();

   const clearErrors = () => {
      removeErrors({ keysLike: _keysLike });
   }

   const handleCancel = () => {
      cancel();
      clearErrors();
      setIsSending(false);
   }

   const handleClose = () => {
      close();
      clearErrors();
      setIsSending(false);
   }

   const sendMail = async () => {
      setIsSending(true);
      clearErrors();

      var request: FundStatusAlertFundEmailRequest = { uId: emailMessage.uId, fundDetail: null }
      await sendFSAEmailRetry(httpPost, request)
         .then(() => {
            sendNotify();
            clearFundAlerts();
            handleClose();
         })
         .catch((e: HttpError) => {
            console.error(`Failed to re-send FSA email: ${e?.httpErrorCode ?? 'Failed to re-send request'} [${e?.responseStatus ?? 'no status code'}]`);
         })
         .finally(() => {
            setIsSending(false);
         });
   }

   const html = (
      <Dialog
         title={`Re-Send Failed FSA`}
         open={isOpen}
         size="large"
         contentStyle={{ backgroundColor: `${isSending ? 'rgba(0,0,0,0.05)' : 'unset'}` }}
         actionButtons={
            <>
               <CancelButton onClick={() => handleCancel()} loading={isSending} disabled={isSending} />
               <ActionButton
                  buttonText='Send'
                  filled={true}
                  icon={<CustomIcon type={CustomIconType.SendOutlined} />}
                  onClick={() => sendMail()}
                  disabled={!emailMessage || isSending}
                  loading={isSending} />
            </>
         }>
         <div style={dialogContentStyle} className={`isSending ? 'ant-modal-mask' : ''`}>
            <ApiErrorDisplay
               title='Api Error'
               keysLike={_keysLike} />

            <FundStatusAlertEmail emailMessage={emailMessage} />
         </div>
      </Dialog>
   )

   return html;
}
export default AlertEmailResendDialog;
