export interface Cohort {
   cohortId?: number;
   cohortTypeId: number;
   startDate: Date;
   endDate: Date;
   status: string;
}

export enum EditorAction {
   Copy,
   Delete,
   Refresh,
   Publish
}

export const SourceTables: string[] = ['AssistanceServiceBrandDefaults', 'AssistanceServiceEligibilityCriteria', 'AssistanceServiceFoundationDefaults'];
