import { Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import * as React from 'react';
import Restricted from '../../auth/Restricted';
import { dateComparer, stringComparer } from '../../functions/comparer.functions';
import { formatShortDate, formatShortDateString } from '../../functions/format.functions';
import { useApiContext } from '../../store/ApiContext';
import { inviteUser } from '../../store/auth/UserFetcher';
import { useFetchUserMainsForNonAcceptedInvites } from '../../store/auth/UserMainFetcher';
import { UserMainNonAcceptedInvite } from '../../store/auth/UserMainModel';
import { KnownSettings } from '../../store/SettingsModel';
import colorWheel from '../../Theme/ColorWheel';
import CustomIcon, { CustomIconType } from '../shared/AntComponents/CustomIcon';
import MinimalistTable from '../shared/AntComponents/Table/MinimalistTable';
import { NumberedTitle } from '../shared/AntComponents/Typography/Title';
import { ActionButton } from '../shared/Buttons';
import Spinner from '../Spinner';


export const NonAcceptedUserInvitesTile: React.FC<{ styles?: React.CSSProperties }> = (props) => {
   const { styles } = props;
   const { httpGet, httpPost } = useApiContext();
   const { data: userMains, isLoading } = useFetchUserMainsForNonAcceptedInvites(httpGet);
   const [userMainsBeingInvited, setUserMainsBeingInvited] = React.useState<number[]>([]);

   const renderPracticeNames = (model: UserMainNonAcceptedInvite): React.ReactNode => {
      if (model?.practiceNames?.length > 0) {
         const returnArr: JSX.Element[] = [];
         let index = 1;
         model?.practiceNames?.forEach(name => {
            returnArr.push(
               /* 'ant-table-cell-ellipsis' - is the className that Ant uses to render ellipsis in its table when 
                *  you provide the "ellipsis: true" value for a column definition */
               <div key={`${model.emailAddress}-${index}`} className='ant-table-cell-ellipsis'>
                  {`${name}${index === model?.practiceNames?.length ? '' : ','} `}
               </div>);
            index++;
         })
         return returnArr;
      } else {
         return <></>;
      }
   }

   const renderFullNames = (record: UserMainNonAcceptedInvite) => {
      if (record.fullNames)
         return record.fullNames[0];
      else
         return '';
   }

   const handleResendInvite = (userMainId: number) => {
      inviteUser(httpPost, userMainId)
         .finally(() => {
            setUserMainsBeingInvited([
               ...userMainsBeingInvited.filter(y => y !== userMainId)
            ]);
         })

   }

   if (isLoading) return <Spinner message={'Checking User Invites...'} />

   const columns: ColumnsType<UserMainNonAcceptedInvite> = [
      {
         title: 'Full name',
         dataIndex: 'fullName',
         key: 'fullName',
         sorter: (a, b) => stringComparer(renderFullNames(a), renderFullNames(b)),
         sortDirections: ['ascend', 'descend'],
         defaultSortOrder: 'ascend',
         render: (key, record) => renderFullNames(record)
      },
      {
         title: 'Email',
         dataIndex: 'emailAddress',
         key: 'emailAddress',
         sorter: (a, b) => stringComparer(a.emailAddress, b.emailAddress),
         sortDirections: ['ascend', 'descend'],         
         render: (key, record) => <div style={{ wordWrap: 'break-word', wordBreak: 'break-word', maxWidth:200 }}>
            {key}
            </div>
      },
      {
         title: 'Practice(s)',
         dataIndex: 'practiceNames',
         key: 'practiceNames',
         render: (text, record) => {
            const content = renderPracticeNames(record);
            return <div style={{ wordWrap: 'break-word', wordBreak: 'break-word', maxWidth: 250 }}>
               <Tooltip placement='top' overlayStyle={{ maxWidth: '250px' }} title={content}>
               {content}
               </Tooltip>
            </div>
         }
      },
      {
         title: () => <Tooltip title='Sort by Invite Sent On'>Resend</Tooltip>,         
         fixed: 'right',
         width: 60,
         sorter: (a, b) => dateComparer(a.inviteSentOn, b.inviteSentOn),
         sortDirections: ['ascend', 'descend'],
         render: (text, record) => {
            return <ActionButton
               onClick={() => {
                  setUserMainsBeingInvited([
                     ...userMainsBeingInvited,
                     record.id
                  ]);
                  handleResendInvite(record.id);
               }}
               icon={<CustomIcon type={CustomIconType.MailOutlined} />}
               title={`Last Invited On ${formatShortDateString(record.inviteSentOn)}`}
               loading={userMainsBeingInvited?.includes(record.id)}
               disabled={userMainsBeingInvited?.includes(record.id)}
            />
         }
      }
   ];

   // AH-6715 adding the overflow Y to allow scrolling within the tile 
   // and overflow X to clip for no bottom scrollbar within the tile
   return (
      <Restricted requiredRoles={[KnownSettings.UserAdmin]}>
         <div style={{ ...styles }}>
            <NumberedTitle level={5} text='Non-Accepted User Invites' />
            <div style={{ border: `1px solid ${colorWheel.mediumGrey}`, maxHeight: '25rem', overflowY: 'scroll', overflowX: 'clip' }}>
               <MinimalistTable
                  rowKey='id'
                  data={userMains}
                  columns={columns}
                  size='small'
                  showHeader={true}
               />
            </div>
         </div>
      </Restricted>
   )
}


export default NonAcceptedUserInvitesTile;