import { HttpError, TGet, TPost } from '../../functions/httpClient';
import { IStatus } from '../models';
import useSWR, { mutate } from 'swr';
import { RingCallQueue } from "./RingCallQueueModel";
import { RingCallQueueViewModel } from "./RingCallQueueViewModel";

export const ringCallQueueBaseUrl = `api/ringcallqueue`;
const saveCallQueueUrl = `${ringCallQueueBaseUrl}/Update`;
const deleteCallQueueUrl = `${ringCallQueueBaseUrl}/Delete`;

const swrOptions = {
   refreshInterval: 0,
   revalidateOnFocus: false,
   dedupingInterval: 60000
};

export function useFetchCallQueue(httpGet: TGet): IStatus & {callQueue: RingCallQueue[]} {
   const {data, isValidating, error} = useSWR<RingCallQueue[], HttpError>(
      ringCallQueueBaseUrl,
      httpGet,
      {...swrOptions}
   );

   return {
      callQueue: data,
      isLoading: !error && !data && isValidating,
      error: error?.message
   };
}

export const saveCallQueue = async (httpPost: TPost, viewModel: RingCallQueueViewModel) => {
   return await httpPost(saveCallQueueUrl, viewModel)
      .then(() => mutate(ringCallQueueBaseUrl));
}

export const deleteCallQueue = async (httpPost: TPost, callQueue: RingCallQueue) => {
   return await httpPost(deleteCallQueueUrl, callQueue)
      .then(() => mutate(ringCallQueueBaseUrl));
}