import { Space, Tooltip } from 'antd';
import { ColumnsType, TableProps } from 'antd/lib/table';
import * as React from 'react';
import { dateComparer, numberComparer, stringComparer } from '../../functions/comparer.functions';
import { formatDateTimeString } from '../../functions/format.functions';
import { IOptionItem } from '../../functions/option.functions';
import {
   FundingStatus, FundingStatusViewModel,
   buildFundStatusTypeOptions
} from '../../store/program/FundingStatusModel';
import { fundingStatusBaseUrl, useFetchFundingStatusViewModel } from '../../store/program/FundingStatusFetcher';
import HighlightSearchText from '../HighlightSearchText';
import CustomIcon, { CustomIconType } from '../shared/AntComponents/CustomIcon';
import { BasicCheckboxField } from '../shared/BasicInputLibrary';
import { ActionButton, AddButton, EditButton } from '../shared/Buttons';
import FundingStatusEditor from './FundingStatusEditor';
import Restricted from '../../auth/Restricted';
import { KnownSettings } from '../../store/SettingsModel';
import { SearchResultsTableWithFilters } from '../shared/AntComponents/Table/SearchResultsTableWithFilters';
import { IFilteredInfo } from '../shared/AntComponents/Filter/FilteredInfo';
import ColumnFilter from '../shared/AntComponents/Filter/ColumnFilter';
import MultiSelectColumnFilter from '../shared/AntComponents/Filter/MultiSelectColumnFilter';
import { ScrapingStatus } from '../../store/program/FoundationModel';
import { useApiContext } from '../../store/ApiContext';
import { HttpVerb, KeyWithVerb, useErrorContext } from '../../store/ErrorContext';
import ApiErrorDisplay from '../ApiErrorDisplay';

const FOUNDATION_TITLE = 'Foundation';
const FOUNDATION_DISEASE_TYPE_TITLE = 'Foundation Disease Type';
const ASSISTANCE_PROGRAM_TITLE = 'Assistance Program';
const FUNDING_STATUS_TITLE = 'Funding Status';
const SCRAPER_STATUS_TITLE = 'Scraper Enabled Only';
type searchFilter = 'foundation' | 'foundationDiseaseType' | 'assistanceProgram' | 'fundingStatus' | 'scraperStatus';
const defaultFilterValue: Record<searchFilter, IFilteredInfo> = {
   foundation: undefined,
   foundationDiseaseType: undefined,
   assistanceProgram: undefined,
   fundingStatus: undefined,
   scraperStatus: undefined
}

const _keysWithVerb: KeyWithVerb[] = [{ key: fundingStatusBaseUrl, verb: HttpVerb.GET }]

const FundingStatusIndex: React.FC = () => {
   const { httpGet } = useApiContext();
   const { removeErrors } = useErrorContext();
   const [selectedItem, setSelectedItem] = React.useState<FundingStatus>(undefined);
   const [isEditorOpen, setIsEditorOpen] = React.useState<boolean>(false);
   const [isEditorReadonly, setIsEditorReadonly] = React.useState<boolean>(true);
   const [filteredInfo, setFilteredInfo] = React.useState<Record<searchFilter, IFilteredInfo>>(defaultFilterValue);

   const [currentPage, setCurrentPage] = React.useState(1);
   const [currentPageSize, setCurrentPageSize] = React.useState(25);
   const handleOnChange: TableProps<FundingStatusViewModel>['onChange'] =
      (pagination, filters, sorter, extra) => setCurrentPage(pagination?.current);

   const { fundingStatusViewModels, isLoading } = useFetchFundingStatusViewModel(httpGet);

   React.useEffect(() => {
      removeErrors({ keysWithVerb: _keysWithVerb });
      // for "removeErrors"
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []) // remove Errors on initial render

   const foundationList = React.useMemo(() => {
      if (fundingStatusViewModels?.length > 0) {

         const sorted = [...fundingStatusViewModels];
         sorted.sort((a: FundingStatusViewModel, b: FundingStatusViewModel) =>
            stringComparer(a.foundationName, b.foundationName));

         const tempList: IOptionItem[] = [];
         for (let i = 0; i < sorted.length; i++) {
            if (!tempList.some(y => y.value === sorted[i].foundationId)) {
               tempList.push({
                  key: sorted[i].foundationId,
                  label: `[${sorted[i].foundationId}]: ${sorted[i].foundationName}`,
                  value: sorted[i].foundationId
               } as IOptionItem);
            }
         }
         return tempList;
      }
      return [];
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [fundingStatusViewModels]);

   const foundationDiseaseTypesList = React.useMemo(() => {
      if (fundingStatusViewModels?.length > 0) {

         /* The AssistanceProgramList and FoundationDiseaseTypeList are both contingent upon the Foundation selection
          * So when Foundation changes, we rebuild those lists and we need to reset their filters
          */
         setFilteredInfo({
            ...filteredInfo,
            assistanceProgram: undefined,
            foundationDiseaseType: undefined
         });

         const sorted = [...fundingStatusViewModels];
         sorted.sort((a: FundingStatusViewModel, b: FundingStatusViewModel) =>
            stringComparer(a.diseaseTypeName, b.diseaseTypeName));
         sorted.sort((a: FundingStatusViewModel, b: FundingStatusViewModel) =>
            stringComparer(a.foundationName, b.foundationName));

         const tempList: IOptionItem[] = [];
         for (let i = 0; i < sorted.length; i++) {
            if (!tempList.some(y => y.value === sorted[i].foundationDiseaseTypeId)) {
               if ((!filteredInfo.foundation?.values?.length ?? 0 === 0) || filteredInfo.foundation.values.map(y => y.value).includes(sorted[i].foundationId)) {
                  tempList.push({
                     key: sorted[i].foundationDiseaseTypeId,
                     label: `[${sorted[i].foundationDiseaseTypeId}]: 
                                ${sorted[i].diseaseTypeName} - ${sorted[i].foundationName}`,
                     value: sorted[i].foundationDiseaseTypeId
                  } as IOptionItem);
               }
            }
         }

         return tempList;
      }
      return [];
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [fundingStatusViewModels, filteredInfo.foundation?.values]);

   const assistanceProgramList = React.useMemo(() => {
      if (fundingStatusViewModels?.length > 0) {
         const sorted = [...fundingStatusViewModels];
         sorted.sort((a: FundingStatusViewModel, b: FundingStatusViewModel) =>
            stringComparer(a.programName, b.programName));
         sorted.sort((a: FundingStatusViewModel, b: FundingStatusViewModel) =>
            numberComparer(a.assistanceProgramId, b.assistanceProgramId));

         const tempList: IOptionItem[] = [];
         for (let i = 0; i < sorted.length; i++) {
            if (!tempList.some(y => y.value === sorted[i].assistanceProgramId)) {
               if (((!filteredInfo.foundation?.values?.length ?? 0 === 0) || filteredInfo.foundation.values.map(y => y.value).includes(sorted[i].foundationId)) &&
                  ((!filteredInfo.foundationDiseaseType?.values?.length ?? 0 === 0) || filteredInfo.foundationDiseaseType.values.map(y => y.value).includes(sorted[i].foundationDiseaseTypeId)))

                  tempList.push({
                     key: sorted[i].assistanceProgramId,
                     label: `[${sorted[i].assistanceProgramId}]: ${sorted[i].programName}`,
                     value: sorted[i].assistanceProgramId
                  } as IOptionItem);
            }
         }
         return tempList;
      }
      return [];
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [fundingStatusViewModels, filteredInfo.foundation?.values, filteredInfo.foundationDiseaseType?.values]);

   const filterFoundation = (m: FundingStatusViewModel): boolean => {
      return !filteredInfo?.foundation?.values || filteredInfo.foundation.values.length == 0 ||
         filteredInfo.foundation.values.some(y => y.value === m.foundationId);
   }

   const filterFoundationDiseaseType = (m: FundingStatusViewModel): boolean => {
      return !filteredInfo?.foundationDiseaseType?.values || filteredInfo.foundationDiseaseType.values.length == 0 ||
         filteredInfo.foundationDiseaseType.values.some(y => y.value === m.foundationDiseaseTypeId);
   }

   const filterAssistancePrograms = (m: FundingStatusViewModel): boolean => {
      return !filteredInfo?.assistanceProgram?.values || filteredInfo.assistanceProgram.values.length == 0 ||
         filteredInfo.assistanceProgram.values.some(y => y.value === m.assistanceProgramId);
   }

   const filterFundingStatus = (m: FundingStatusViewModel): boolean => {
      return !filteredInfo?.fundingStatus?.values || filteredInfo.fundingStatus.values.length == 0 ||
         filteredInfo.fundingStatus.values.some(y => y.value === m.status);
   }

   const filterScrapingStatus = (m: FundingStatusViewModel): boolean => {
      return (filteredInfo?.scraperStatus?.value === ScrapingStatus.On && m.foundationScrapingStatus === ScrapingStatus.On) ||
         !filteredInfo?.scraperStatus?.value;
   }

   const filteredData = React.useMemo(() => {
      if (fundingStatusViewModels?.length > 0) {
         return fundingStatusViewModels.filter(model => {
            return filterFoundation(model) &&
               filterFoundationDiseaseType(model) &&
               filterAssistancePrograms(model) &&
               filterFundingStatus(model) &&
               filterScrapingStatus(model);
         })
      }
      return [];
      // We don't need the filter functions, but we do need the ones in the array
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [fundingStatusViewModels, filteredInfo]);

   const handleCloseEditorClick = () => {
      setIsEditorOpen(false);
      setSelectedItem(undefined);
   }

   const handleScraperStatusChanged = (checked: boolean) => {
      if (checked) {
         setFilteredInfo({
            ...filteredInfo,
            scraperStatus: {
               title: SCRAPER_STATUS_TITLE,
               value: ScrapingStatus.On
            }
         });
      } else {
         // When a User unchecks this we don't do any filtering with the value
         setFilteredInfo({
            ...filteredInfo,
            scraperStatus: undefined
         })
      }
   }

   //if (brandDefaultsViewModelList && brandDefaultsViewModelList.length > 0) {
   //   return <Spinner isLoading={true} />
   //}

   //console.log(`render list`)

   const tableColumns: ColumnsType<FundingStatusViewModel> = [
      {
         title: 'Id',
         dataIndex: 'id',
         key: 1,
         sorter: (a, b) => numberComparer(a.id, b.id),
         sortDirections: ['ascend', 'descend'],
         defaultSortOrder: 'descend'
      },
      {
         title: 'AssistanceServiceName',
         dataIndex: 'assistanceServiceName',
         key: 2,
         sorter: (a, b) => stringComparer(a.assistanceServiceName, b.assistanceServiceName),
         sortDirections: ['ascend', 'descend']
      },
      {
         title: <ColumnFilter title={ASSISTANCE_PROGRAM_TITLE}
            filteredInfo={filteredInfo?.assistanceProgram}
            content={<MultiSelectColumnFilter
               options={assistanceProgramList}
               searchPlaceholder={`Filter by ${ASSISTANCE_PROGRAM_TITLE}`}
               allowSelectAll={true}
               searchable={true}
               filteredInfo={filteredInfo}
               setFilteredInfo={setFilteredInfo}
               filteredInfoKey={'assistanceProgram'}
               filteredInfoTitle={ASSISTANCE_PROGRAM_TITLE}
            />}
         />,
         dataIndex: 'programName',
         key: 3,
         sorter: (a, b) => stringComparer(a.programName, b.programName),
         sortDirections: ['ascend', 'descend'],
         render: (text, record) =>
            <HighlightSearchText searchString={filteredInfo?.assistanceProgram?.values.some(y => y.value === record.assistanceProgramId) ? text : ''} targetString={record.programName} />
      },
      {
         title: <ColumnFilter title={FOUNDATION_DISEASE_TYPE_TITLE}
            filteredInfo={filteredInfo?.foundationDiseaseType}
            content={<MultiSelectColumnFilter
               options={foundationDiseaseTypesList}
               searchPlaceholder={`Filter by ${FOUNDATION_DISEASE_TYPE_TITLE}`}
               allowSelectAll={true}
               searchable={true}
               filteredInfo={filteredInfo}
               setFilteredInfo={setFilteredInfo}
               filteredInfoKey={'foundationDiseaseType'}
               filteredInfoTitle={FOUNDATION_DISEASE_TYPE_TITLE}
            />}
         />,
         dataIndex: 'diseaseTypeName',
         key: 4,
         sorter: (a, b) => stringComparer(a.diseaseTypeName, b.diseaseTypeName),
         sortDirections: ['ascend', 'descend'],
         render: (text, record) =>
            <HighlightSearchText searchString={filteredInfo?.foundationDiseaseType?.values.some(y => y.value === record.foundationDiseaseTypeId) ? text : ''} targetString={record.diseaseTypeName} />
      },
      {
         title: <ColumnFilter title={FOUNDATION_TITLE}
            filteredInfo={filteredInfo?.foundation}
            content={<MultiSelectColumnFilter
               options={foundationList}
               searchPlaceholder={`Filter by ${FOUNDATION_TITLE}`}
               allowSelectAll={true}
               searchable={true}
               filteredInfo={filteredInfo}
               setFilteredInfo={setFilteredInfo}
               filteredInfoKey={'foundation'}
               filteredInfoTitle={FOUNDATION_TITLE}
            />}
         />,
         dataIndex: 'foundationName',
         key: 5,
         sorter: (a, b) => stringComparer(a.foundationName, b.foundationName),
         sortDirections: ['ascend', 'descend'],
         render: (text, record) =>
            <HighlightSearchText searchString={filteredInfo?.foundation?.values.some(y => y.value === record.foundationId) ? text : ''} targetString={record.foundationName} />
      },
      {
         title: 'InsuranceClassName',
         dataIndex: 'insuranceClassName',
         key: 6,
         sorter: (a, b) => stringComparer(a.insuranceClassName, b.insuranceClassName),
         sortDirections: ['ascend', 'descend']
      },
      {
         title: <ColumnFilter title={FUNDING_STATUS_TITLE}
            filteredInfo={filteredInfo?.fundingStatus}
            content={<MultiSelectColumnFilter
               options={buildFundStatusTypeOptions(false)}
               searchPlaceholder={`Filter by ${FUNDING_STATUS_TITLE}`}
               allowSelectAll={true}
               searchable={true}
               filteredInfo={filteredInfo}
               setFilteredInfo={setFilteredInfo}
               filteredInfoKey={'fundingStatus'}
               filteredInfoTitle={FUNDING_STATUS_TITLE}
            />}
         />,
         dataIndex: 'statusType',
         key: 7,
         sorter: (a, b) => stringComparer(a.statusType, b.statusType),
         sortDirections: ['ascend', 'descend']
      },
      {
         title: 'StatusModifiedOn',
         dataIndex: 'statusModifiedOn',
         key: 8,
         sorter: (a, b) => dateComparer(a.statusModifiedOn, b.statusModifiedOn),
         sortDirections: ['ascend', 'descend'],
         render: (text, record) => formatDateTimeString(record.statusModifiedOn)
      },
      {
         title: 'ExternalId',
         dataIndex: 'externalId',
         key: 9,
         sortDirections: ['ascend', 'descend'],
         sorter: (a, b) => stringComparer(a.externalId, b.externalId),
         ellipsis: true,
         render: (text, record) => <Tooltip placement='top' overlayStyle={{ maxWidth: '350px' }} title={record.externalId}>{record.externalId}</Tooltip>
      },
      {
         title: () =>
            <Restricted requiredRoles={[KnownSettings.ContentAdmin]}>
               <AddButton
                  title='Add New Funding Status'
                  buttonText='New'
                  onClick={() => {
                     setSelectedItem(undefined);
                     setIsEditorReadonly(false);
                     setIsEditorOpen(true)
                  }} />
            </Restricted>,
         dataIndex: '',
         key: 10,
         width: 100,
         render: (text, record) =>
            <Space>
               <Restricted requiredRoles={[KnownSettings.ContentAdmin]}>
                  <EditButton title='Edit' onClick={() => {
                     setIsEditorOpen(true);
                     setIsEditorReadonly(false);
                     setSelectedItem(record);
                  }} />
               </Restricted>
               <ActionButton
                  title='Preview'
                  icon={<CustomIcon type={CustomIconType.EyeOutlined} />}
                  onClick={() => {
                     setIsEditorOpen(true);
                     setIsEditorReadonly(true);
                     setSelectedItem(record);
                  }}
               />
            </Space>
      }
   ];


   const html = (
      <>
         <ApiErrorDisplay
            title={'Error'}
            keysWithVerb={_keysWithVerb}
         />

         <SearchResultsTableWithFilters
            rowkey={'id'}
            columns={tableColumns}
            data={filteredData}
            currentPage={currentPage}
            currentPageSize={currentPageSize}
            setCurrentPageSize={setCurrentPageSize}
            onChange={handleOnChange}
            titleText='Funding Status'
            fixedHeader={true}
            filteredInfo={filteredInfo}
            setFilteredInfo={setFilteredInfo}
            onFiltersClear={() => setFilteredInfo(defaultFilterValue)}
            loading={isLoading}
            additionalComponents={[
               <BasicCheckboxField
                  checked={filteredInfo?.scraperStatus?.value === ScrapingStatus.On}
                  label={SCRAPER_STATUS_TITLE}
                  onChange={(e) => { handleScraperStatusChanged(Boolean(e)) }}
               />
            ]}
         />

         {isEditorOpen &&
            <FundingStatusEditor isOpen={isEditorOpen}
               closeEditor={() => handleCloseEditorClick()}
               id={selectedItem?.id}
               readonly={isEditorReadonly}
            />
         }
      </>
   );

   return html;
}

export default FundingStatusIndex;
