import { Space } from 'antd';
import { ColumnsType, TableProps } from 'antd/lib/table';
import * as React from 'react';
import { numberComparer, stringComparer } from '../../../functions/comparer.functions';
import { useApiContext } from '../../../store/ApiContext';
import { AssistanceServiceEligibilityCriteriaViewModel } from '../../../store/program/AssistanceServiceEligibilityCriteriaModel';
import { useEligibilityAssistanceProgramOptions,
   useEligibilityBrandOptions,
   useEligibilityCategoryOptions,
   useEligibilityCriteriaViewModel,
   useEligibilityFoundationDiseaseTypeOptions
} from '../../../store/program/AssistanceServiceEligibilityCriteriaFetcher';
import HighlightSearchText from '../../HighlightSearchText';
import CustomIcon, { CustomIconType } from '../../shared/AntComponents/CustomIcon';
import SearchResultsTable from '../../shared/AntComponents/Table/SearchResultsTable';
import { BasicDropdownField } from '../../shared/BasicInputLibrary';
import { ActionButton, AddButton, EditButton } from '../../shared/Buttons';
import Spinner from '../../Spinner';
import AssistanceServiceEligibilityCriteriaEditor from './AssistanceServiceEligibilityCriteriaEditor';


const AssistanceServiceEligibilityCriteriaIndex: React.FC = () => {
    const { httpGet } = useApiContext();
    const { eligibilityCriteriaViewModel, isLoading, error } = useEligibilityCriteriaViewModel(httpGet);
    const { eligibilityCategoryOptions } = useEligibilityCategoryOptions(httpGet);
    const { eligibilityAssistanceProgramOptions } = useEligibilityAssistanceProgramOptions(httpGet);
    const { eligibilityBrandOptions } = useEligibilityBrandOptions(httpGet);
    const { eligibilityFoundationDiseaseTypeOptions } = useEligibilityFoundationDiseaseTypeOptions(httpGet);

    const [currentPage, setCurrentPage] = React.useState(1);
    const [currentPageSize, setCurrentPageSize] = React.useState(25);
    const handleOnChange: TableProps<AssistanceServiceEligibilityCriteriaViewModel>['onChange'] = (pagination, filters, sorter, extra) => setCurrentPage(pagination?.current);

    const [assistanceProgramFilter, setAssistanceProgramFilter] = React.useState<number>();
    const [foundationDiseaseTypeFilter, setFoundationDiseaseTypeFilter] = React.useState<number>();
    const [brandFilter, setBrandFilter] = React.useState<number>();
    const [categoryFilter, setCategoryFilter] = React.useState<number>();
    const [filteredItems, setFilteredItems] = React.useState<AssistanceServiceEligibilityCriteriaViewModel[]>();
    const [selectedItem, setSelectedItem] = React.useState<AssistanceServiceEligibilityCriteriaViewModel>(undefined);
    const [isEditorOpen, setIsEditorOpen] = React.useState<boolean>(false);
    const [isEditorReadonly, setIsEditorReadonly] = React.useState<boolean>(true);

    React.useEffect(() => {
        createFilteredItems();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eligibilityCriteriaViewModel,
        foundationDiseaseTypeFilter,
        categoryFilter,
        brandFilter,
        assistanceProgramFilter]);

    const createFilteredItems = (inActivePage: number = 1) => {
        if (!eligibilityCriteriaViewModel || eligibilityCriteriaViewModel.length <= 0) return;

        let newList = eligibilityCriteriaViewModel.filter(model => {
            return filterFoundationDiseaseType(model) &&
                filterCategory(model) &&
                filterAssistancePrograms(model) &&
                filterBrand(model);
        }) ?? [];

        setFilteredItems(newList);
    }

    const filterFoundationDiseaseType = (m: AssistanceServiceEligibilityCriteriaViewModel): boolean => {
        return !foundationDiseaseTypeFilter || m.foundationDiseaseTypeId === foundationDiseaseTypeFilter ? true : false;
    }

    const filterBrand = (m: AssistanceServiceEligibilityCriteriaViewModel): boolean => {
        return !brandFilter || m.brandId === brandFilter ? true : false;
    }

    const filterCategory = (m: AssistanceServiceEligibilityCriteriaViewModel): boolean => {
        return !categoryFilter || m.assistanceServiceEligibilityCriteriaCategoryId === categoryFilter ? true : false;
    }

    const filterAssistancePrograms = (m: AssistanceServiceEligibilityCriteriaViewModel): boolean => {
        return !assistanceProgramFilter || m.assistanceProgramId === assistanceProgramFilter ? true : false;
    }

    const handleCloseEditorClick = () => {
        setIsEditorOpen(false);
        setSelectedItem(undefined);
    }

    if (isLoading || !eligibilityCriteriaViewModel || eligibilityCriteriaViewModel.length === 0) {
        return <Spinner />
    }

    const tableColumns: ColumnsType<AssistanceServiceEligibilityCriteriaViewModel> = [
        {
            title: 'Id',
            dataIndex: 'id',
            width: 50,
            sorter: (a, b) => numberComparer(a.id, b.id),
            sortDirections: ['ascend', 'descend', 'ascend'],
            defaultSortOrder: 'descend',
        },
        {
            title: 'Program Name',
            dataIndex: 'programName',
            width: '13%',
            sorter: (a, b) => stringComparer(a.programName, b.programName),
            sortDirections: ['ascend', 'descend', 'ascend'],
            render: (text, record) => {
                if (assistanceProgramFilter && assistanceProgramFilter === record.assistanceProgramId) {
                    return <HighlightSearchText searchString={record.programName} targetString={record.programName} />
                }
                return text;
            }
        },
        {
            title: 'ASID',
            dataIndex: 'assistanceServiceId',
            width: 50,
            sorter: (a, b) => numberComparer(a.assistanceServiceId, b.assistanceServiceId),
            sortDirections: ['ascend', 'descend', 'ascend'],
        },
        {
            title: 'Assistance Service Name',
            dataIndex: 'assistanceServiceName',
            width: '13%',
            sorter: (a, b) => stringComparer(a.assistanceServiceName, b.assistanceServiceName),
            sortDirections: ['ascend', 'descend', 'ascend'],
        },
        {
            title: 'BrandId',
            dataIndex: 'brandId',
            width: 50,
            sorter: (a, b) => numberComparer(a.brandId, b.brandId),
            sortDirections: ['ascend', 'descend', 'ascend'],
            render: (text, record) => {
                if (brandFilter && record.brandId === brandFilter) {
                    return <HighlightSearchText searchString={`${record.brandId}`} targetString={`${record.brandId}`} />
                }
                return text;
            }
        },
        {
            title: 'Brand Name',
            dataIndex: 'brandName',
            width: '13%',
            sorter: (a, b) => stringComparer(a.brandName, b.brandName),
            sortDirections: ['ascend', 'descend', 'ascend'],
            render: (text, record) => {
                if (brandFilter && record.brandId === brandFilter) {
                    return <HighlightSearchText searchString={record.brandName} targetString={record.brandName} />
                }
                return text;
            }
        },
        {
            title: 'FDTId',
            dataIndex: 'foundationDiseaseTypeId',
            width: 50,
            sorter: (a, b) => numberComparer(a.foundationDiseaseTypeId, b.foundationDiseaseTypeId),
            sortDirections: ['ascend', 'descend', 'ascend'],
            render: (text, record) => {
                if (foundationDiseaseTypeFilter && record.foundationDiseaseTypeId === foundationDiseaseTypeFilter) {
                    return <HighlightSearchText searchString={`${record.foundationDiseaseTypeId}`} targetString={`${record.foundationDiseaseTypeId}`} />
                }
                return text;
            }
        },
        {
            title: 'Disease Type Name',
            dataIndex: 'diseaseTypeName',
            width: '10%',
            sorter: (a, b) => stringComparer(a.diseaseTypeName, b.diseaseTypeName),
            sortDirections: ['ascend', 'descend', 'ascend'],
            render: (text, record) => {
                if (foundationDiseaseTypeFilter && record.foundationDiseaseTypeId === foundationDiseaseTypeFilter) {
                    return <HighlightSearchText searchString={record.diseaseTypeName} targetString={record.diseaseTypeName} />
                }
                return text;
            }
        },
        {
            title: 'Category Name',
            dataIndex: 'categoryName',
            width: '10%',
            sorter: (a, b) => stringComparer(a.categoryName, b.categoryName),
            sortDirections: ['ascend', 'descend', 'ascend'],
            render: (text, record) => {
                if (categoryFilter && record.assistanceServiceEligibilityCriteriaCategoryId === categoryFilter) {
                    return <HighlightSearchText searchString={text} targetString={text} />
                }
                return text;
            }
        },
        {
            dataIndex: 'actions',
            width: 60,
            fixed: 'right',
            render: (text, record) => <Space>                
                <ActionButton
                    title='Preview'
                    icon={<CustomIcon type={CustomIconType.EyeOutlined} />}
                    onClick={() => {
                        setIsEditorOpen(true);
                        setIsEditorReadonly(true);
                        setSelectedItem(record);
                    }}
                />
            </Space>
        },
    ];


    const html = (
        <>
            <SearchResultsTable
                rowkey={'id'}
                onChange={handleOnChange}
                columns={tableColumns}
                data={filteredItems}
                currentPage={currentPage}
                currentPageSize={currentPageSize}
                setCurrentPageSize={setCurrentPageSize}
                titleText='Assistance Service Eligibility Criteria'
                fixedHeader={true}
                size={'small'}
                additionalComponents={[
                    <BasicDropdownField
                        containerStyle={{ maxWidth: 250 }}
                        name='assistanceProgramSearchString'
                        label='Assistance Program'
                        placeholder={' - Filter by Assistance Program -'}
                        multiple={false}
                        options={eligibilityAssistanceProgramOptions}
                        value={assistanceProgramFilter}
                        search={true}
                        clearable={true}
                        onChange={(e) => setAssistanceProgramFilter(e ? Number(e) : undefined)}
                    />,
                    <BasicDropdownField
                        containerStyle={{ maxWidth: 250 }}
                        name='brandSearchString'
                        label='Brand'
                        placeholder={' - Filter by Brand -'}
                        multiple={false}
                        options={eligibilityBrandOptions}
                        value={brandFilter}
                        search={true}
                        clearable={true}
                        onChange={(e) => setBrandFilter(e ? Number(e) : undefined)}
                    />,
                    <BasicDropdownField
                        containerStyle={{ maxWidth: 250 }}
                        name='foundationDiseaseTypeSearchString'
                        label='Foundation Disease Type'
                        placeholder={' - Filter by Foundation Disease Type -'}
                        multiple={false}
                        options={eligibilityFoundationDiseaseTypeOptions}
                        value={foundationDiseaseTypeFilter}
                        search={true}
                        clearable={true}
                        onChange={(e) => setFoundationDiseaseTypeFilter(e ? Number(e) : undefined)}
                    />,
                    <BasicDropdownField
                        containerStyle={{ maxWidth: 250 }}
                        name='categorySearchString'
                        label='Category'
                        placeholder={' - Filter by Category -'}
                        multiple={false}
                        options={eligibilityCategoryOptions}
                        value={categoryFilter}
                        search={true}
                        clearable={true}
                        onChange={(e) => setCategoryFilter(e ? Number(e) : undefined)}
                    />
                ]} />

            <AssistanceServiceEligibilityCriteriaEditor isOpen={isEditorOpen}
                closeEditor={() => handleCloseEditorClick()}
                id={selectedItem?.id}
                readonly={isEditorReadonly}
            />
        </>
    )
    return html;
}

export default AssistanceServiceEligibilityCriteriaIndex;
