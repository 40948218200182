import useSWR, { mutate } from 'swr';
import { useMemo } from 'react';
import { BillingCode, BillingCodeViewModel } from './BillingCodeModel';
import { HttpError, TGet, TPost, TDelete } from '../../functions/httpClient';
import { IStatus } from '../models';
import { useFetchDrug } from './DrugFetcher';

export const billingCodeBaseUrl = `api/BillingCode`;
export const billingCodeSaveUrl = `${billingCodeBaseUrl}/Save`;
export const billingCodeDeleteUrl = (id: number) => `${billingCodeBaseUrl}/Delete/${id}`;


const swrOptions = {
   refreshInterval: 0,
   revalidateOnFocus: false,
   dedupingInterval: 60000
};


export function useFetchBillingCode(httpGet: TGet): IStatus & { billingCodes: BillingCode[] } {
   const { data, isValidating, error } = useSWR<BillingCode[], HttpError>(
      billingCodeBaseUrl,
      httpGet,
      { ...swrOptions }
   );

   return {
      billingCodes: data,
      isLoading: !error && !data && isValidating,
      error: error?.message
   };
}

export const saveBillingCode = async (httpPost: TPost, billingCodes: BillingCode[]) => {
   const result = await httpPost(billingCodeSaveUrl, billingCodes);
   mutate(billingCodeBaseUrl);
   return result;
}

export const deleteBillingCode = async (httpDelete: TDelete, id: number) => {
   const result = await httpDelete(billingCodeDeleteUrl(id));
   mutate(billingCodeBaseUrl);
   return result;
}

export function useBillingCodeViewModel(httpGet: TGet): IStatus & { billingCodeViewModel: BillingCodeViewModel[] } {
   const { billingCodes, isLoading, error, isSaving } = useFetchBillingCode(httpGet);
   const { drugs, isLoading: isLoading_d, error: error_d } = useFetchDrug(httpGet);

   const viewModel = useMemo(() => {
      if (billingCodes?.length > 0 && drugs?.length > 0) {
         let tempList = billingCodes.map((billingCode): BillingCodeViewModel => {
            const d = drugs.find(y => y.id === billingCode.drugId);
            return {
               ...billingCode,
               drugName: d?.drugName
            } as BillingCodeViewModel
         });
         return tempList;
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [billingCodes, drugs]);

   return {
      billingCodeViewModel: viewModel,
      isLoading: isLoading || isLoading_d,
      error: error ?? error_d,
      isSaving
   };
}