import * as React from 'react';
import { useApiContext } from '../../../store/ApiContext';
import { useUserProfileViewModel } from '../../../store/practice/UserProfileFetcher';
import UserProfileEditor from './UserProfileEditor';

interface IExistingUserProfileEditorProps {
   userMainId?: number;
   existingUserProfileId: number;
   onCloseEditor: () => void;
   isEditorOpen: boolean;
   isEditorReadOnly: boolean;
}

const ExistingUserProfileEditor: React.FC<IExistingUserProfileEditorProps> = (props) => {
   const {
      existingUserProfileId,
      onCloseEditor,
      isEditorOpen,
      isEditorReadOnly,
      userMainId
   } = props;

   const { httpGet } = useApiContext();
   const { userProfileViewModel } = useUserProfileViewModel(httpGet, existingUserProfileId);

   return <UserProfileEditor
      closeEditor={onCloseEditor}
      isEditorOpen={isEditorOpen}
      isEditorReadOnly={isEditorReadOnly}
      profileToEdit={userProfileViewModel}
      userMainId={userMainId}
      dialogTitle={'AssistPoint User Management Editor'}
   />
}

export default ExistingUserProfileEditor;