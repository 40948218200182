import * as React from 'react';
import { RingCallQueue } from "../../store/practice/RingCallQueueModel";
import Dialog from "../Dialog";
import { CancelButton, DeleteButton } from "../shared/Buttons";
import { deleteCallQueue } from "../../store/practice/RingCallQueueFetcher";
import { useApiContext } from "../../store/ApiContext";
import CustomIcon, { CustomIconType } from "../shared/AntComponents/CustomIcon";
import ColorWheel from "../../Theme/ColorWheel";

interface IProps {
   isOpen: boolean;
   closeEditor: () => void;
   callQueue: RingCallQueue;
}

const RingCallQueueDeletePrompt: React.FC<IProps> = (props) => {
   const {callQueue, closeEditor, isOpen} = props;
   const {httpPost} = useApiContext();
   const [isDeleting, setIsDeleting] = React.useState<boolean>(false);

   const handleClose = () => {
      setIsDeleting(false);
      closeEditor();
   }

   const handleDeleteButton = async () => {
      setIsDeleting(true);
      await deleteCallQueue(httpPost, callQueue)
         .then(() => {
            closeEditor();
         })
         .catch((err) => {
            console.error(err);
         })
         .finally(() => {
            setIsDeleting(false);
         });
   }

   return (
      <Dialog
         title={'Delete Ring Call Queue'}
         open={isOpen}
         size='small'
         actionButtons={
            <>
               <CancelButton
                  buttonText='Cancel'
                  onClick={handleClose}
                  disabled={isDeleting}
                  loading={isDeleting}/>
               <DeleteButton
                  onClick={handleDeleteButton}
                  disabled={isDeleting}
                  loading={isDeleting}/>
            </>}>
         <h3>
            <CustomIcon style={{color: ColorWheel.tangerineOrange, fontSize: 25}}
                        type={CustomIconType.WarningOutlined}/> <b>Warning!</b>
         </h3>
         <p style={{padding: 0, margin: 0}}>This will permanently delete the selected call queue.</p>
      </Dialog>
   );
}

export default RingCallQueueDeletePrompt;