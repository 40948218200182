import * as React from 'react';
import Spinner from '../Spinner';
import ApiErrorDisplay from '../ApiErrorDisplay';
import { useApiContext } from '../../store/ApiContext';
import NewUserSearchView from './shared/NewUserSearchView';
import { useParams } from 'react-router';
import { useFetchUserMain, userMainBaseUrl } from '../../store/auth/UserMainFetcher';
import ContentCard from '../../layouts/ContentCard';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import UserDetail from './UserDetail';
import { Breadcrumb } from 'antd';
import { BreadcrumbItemType } from 'antd/es/breadcrumb/Breadcrumb';
import { useErrorContext } from '../../store/ErrorContext';

const _keys: string[] = [userMainBaseUrl];

const UserMainIndex: React.FC = () => {
   const { httpGet } = useApiContext();
   const { removeErrors } = useErrorContext();
   const navigate = useNavigate();
   const location = useLocation();
   const { id } = useParams();
   const userMainId = id ? Number(id) : id;
   const { data: userMainList, isLoading } = useFetchUserMain(httpGet);
   const [isUserDetailOpen, setIsUserDetailOpen] = React.useState<boolean>(false);
   const [userIsNavigating, setUserIsNavigating] = React.useState(false);
   const [userProfileId, setUserProfileId] = React.useState<number>();

   const userMain = React.useMemo(() => {
      if (userMainId && userMainList && userMainList.length > 0) {
         return userMainList.find(f => f.id === userMainId);
      }
   }, [userMainId, userMainList])

   React.useEffect(() => {
      /* Evidently navigate is supposed to be in a useEffect?  Even though most everywhere else we use it, its not and it works just fine
       * Anyway, this wasn't working in the onClick in the <Link ... /> below, so here's a useEffect instead */
      if (userIsNavigating) {
         if (isUserDetailOpen) setIsUserDetailOpen(false);

         if (!userMain) {
            navigate(-1)
         } else {
            // When editing a User Main, we want to allow navigation back to the main grid
            navigate('/user');
         }
      }
      setUserIsNavigating(false);
      // ^ see comment above regarding the need to wrap "navigate" in a useEffect
      // and know that we only want to navigate when "userIsNavigating" toggles, hence:
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [userIsNavigating]);

   React.useEffect(() => {
      removeErrors({ keys: _keys });
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []) // remove errors on initial render


   if (isLoading) {
      return <Spinner message='Loading Users...' />
   }

   const handleReturnToUsers = () => {
      setUserIsNavigating(true);
   }

   const handleReturnToSearch = () => {
      setIsUserDetailOpen(false);
   }

   const getBreadcrumbItems = () => {
      let items: BreadcrumbItemType[] = [];
      items.push({
         title: <Link
            onClick={handleReturnToUsers}
            to={location.pathname}
            title='Return to Users'>
            &lt; Users
         </Link>
      });

      isUserDetailOpen && items.push({
         title: <Link
            onClick={handleReturnToSearch}
            to={location.pathname}
            title='Return to Search'>
            Users Search
         </Link>
      });

      return items;
   }

   const cardTitle = userMain ? `Edit ${userMain.emailAddress}` : 'New User Main Record'
   const html = (<>
      <ContentCard title={`${cardTitle}`}>
         <div style={{ padding: 10 }}>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
               <Breadcrumb items={getBreadcrumbItems()} />
            </div>

            <ApiErrorDisplay
               title='Error loading User'
               keys={_keys}
            />

            {!userMain && !isUserDetailOpen &&
               <NewUserSearchView setIsUserDetailOpen={setIsUserDetailOpen} setUserProfileId={setUserProfileId} />
            }
            {(userMain || isUserDetailOpen) &&
               <UserDetail userMainId={userMain?.id} userProfileId={userProfileId} />
            }
         </div>
      </ContentCard>
   </>
   )
   return html;
}

export default UserMainIndex;
