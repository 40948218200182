import * as React from 'react';
import { useApiContext } from '../../store/ApiContext';
import { useErrorContext } from '../../store/ErrorContext';
import { FundStatusAlert, FundStatusAlertFundEmailRequest } from '../../store/fsa/FundStatusAlertModel';
import { fundStatusEmailBaseUrl, sendFSAEmail, useGetFSAEmail } from '../../store/fsa/FundStatusEmailFetcher';
import ApiErrorDisplay from '../ApiErrorDisplay';
import Dialog from '../Dialog';
import CustomIcon, { CustomIconType } from '../shared/AntComponents/CustomIcon';
import { ActionButton, CancelButton } from '../shared/Buttons';
import FundStatusAlertEmail from './FundStatusAlertEmail';
import { useFundStatusContext } from './FundStatusContext';
import { HttpError } from '../../functions/httpClient';

interface IProps {
   fundAlerts: FundStatusAlert[],
   clearFundAlerts: () => void;
   isOpen: boolean;
   close: (isFsaSent: boolean) => void;
};

const dialogContentStyle = {
   minHeight: '60vh',
   overflow: 'auto'
}

const _keysLike: string[] = [fundStatusEmailBaseUrl];

const AlertEmailDialog: React.FC<IProps> = (props) => {
   const { httpPost } = useApiContext();
   const { fieldChangeFromDate, sendNotify } = useFundStatusContext();
   const { isOpen, close, fundAlerts, clearFundAlerts } = props;
   const { emailMessage } = useGetFSAEmail(httpPost, fundAlerts);
   const [isSending, setIsSending] = React.useState<boolean>(false);
   const { removeErrors } = useErrorContext();

   const clearErrors = () => {
      removeErrors({ keysLike: _keysLike });
   }

   const handleClose = (isFsaSent: boolean) => {
      close(isFsaSent);
      clearErrors();
      setIsSending(false);
   }

   const sendMail = async () => {
      setIsSending(true);
      clearErrors();

      var request: FundStatusAlertFundEmailRequest = { uId: emailMessage.uId, fundDetail: fundAlerts }
      await sendFSAEmail(httpPost, request, fieldChangeFromDate)
         .then(() => {
            sendNotify();
            clearFundAlerts();
            handleClose(true);
         })
         .catch((e: HttpError) => {
            console.error(`Failed to send FSA email: ${e?.httpErrorCode ?? 'failed to send request'} [${e?.responseStatus ?? 'no status code'}]`);
         })
         .finally(() => {
            setIsSending(false);
         });
   }

   const html = (
      <Dialog
         title={`Send FSA`}
         open={isOpen}
         size="large"
         contentStyle={{ backgroundColor: `${isSending ? 'rgba(0,0,0,0.05)' : 'unset'}` }}
         actionButtons={
            <>
               <CancelButton onClick={() => handleClose(false)} loading={isSending} disabled={isSending} />
               <ActionButton
                  buttonText='Send'
                  filled={true}
                  icon={<CustomIcon type={CustomIconType.SendOutlined} />}
                  onClick={sendMail}
                  disabled={!emailMessage || !fundAlerts || fundAlerts.length === 0 || isSending}
                  loading={isSending} />
            </>
         }>
         <div style={dialogContentStyle} className={`isSending ? 'ant-modal-mask' : ''`}>
            <ApiErrorDisplay
               title='Api Error'
               keysLike={_keysLike} />

            <FundStatusAlertEmail emailMessage={emailMessage} />
         </div>
      </Dialog>
   )

   return html;
}
export default AlertEmailDialog;
