import useSWR, { mutate } from 'swr';
import { useMemo } from 'react';
import { IStatus } from '../models';
import { HttpError, TGet, TPost, TDelete } from '../../functions/httpClient';
import { numberComparer, stringComparer } from '../../functions/comparer.functions';
import { IOptionItem } from '../../functions/option.functions';
import { assistanceProgramWithFoundationsUrl, useFetchAssistancePrograms } from './AssistanceProgramFetcher';
import { useFetchAssistanceServices } from './AssistanceServiceFetcher'
import { useFetchFoundations } from './FoundationFetcher';
import { useFetchFoundationDiseaseTypeGroups, useFetchFoundationDiseaseTypes } from './FoundationDiseaseType';
import { useFetchInsuranceClasses } from './InsuranceClassFetcher';
import {
   FundStatusType, FundingStatus,
   FundingStatusWithNames, FundingStatusViewModel,
   FundingStatusWithInsuranceClasses
} from './FundingStatusModel';

export const fundingStatusBaseUrl = `api/program/fundingstatus`;
const saveFundingStatusUrl = `${fundingStatusBaseUrl}/Save`;
const deleteFundingStatusUrl = (id: number) => `${fundingStatusBaseUrl}/Delete/${id}`;
const deleteMultipleFundingStatusUrl = `${fundingStatusBaseUrl}/DeleteFundingStatuses`;
const saveFundingStatusOnlyUrl = `${fundingStatusBaseUrl}/SaveFundingStatuses`;

const swrOptions = {
   refreshInterval: 0,
   revalidateOnFocus: false,
   dedupingInterval: 60000     //default 2000
};

/*
 * methods to support foundation code
 * 
 */

export const fundStatusAsSelectOptions = (): IOptionItem[] =>
    Object.keys(FundStatusType)
        .filter(key => !isNaN(Number(key)))
      .map(num => ({ label: FundStatusType[Number(num)], value: Number(num) }));


export const findStatusEntry = (a: FundingStatus, b: FundingStatus): boolean => {
    return a.assistanceServiceId === b.assistanceServiceId && a.insuranceClassId === b.insuranceClassId
}

export const findChangedEntryIndex = (cArray: FundingStatus[], entry: FundingStatus): number => {
    return cArray.findIndex((e) => e.assistanceServiceId === entry.assistanceServiceId && e.insuranceClassId === entry.insuranceClassId);
}

export const compareFoundationFundingStatus = (a: FundingStatus, b: FundingStatus): number => {

    let sortValue: number = numberComparer(a.assistanceProgramId, b.assistanceProgramId);

    if (sortValue === 0) sortValue = numberComparer(a.assistanceServiceId, b.assistanceServiceId);

    return sortValue
}

export const compareFoundationFundingStatusWithNames = (a: FundingStatusWithNames, b: FundingStatusWithNames): number => {

    let sortValue: number = stringComparer(a.assistanceProgramName, b.assistanceProgramName);

    if (sortValue === 0) sortValue = stringComparer(a.assistanceServiceName, b.assistanceServiceName);

    return sortValue
}


export const useFoundationFundingStatusListById = (httpGet: TGet, id: number): IStatus & { fundingStatusList: FundingStatus[] } => {
   const { fundingStatusList, isLoading, error } = useFetchFundingStatusList(httpGet);
    return {
        isLoading,
        error: error?.message,
        // eslint-disable-next-line no-restricted-globals
       fundingStatusList: fundingStatusList?.filter((f: FundingStatus) => f.foundationDiseaseTypeId === id) ?? undefined
    }
};

export const useFetchFundingStatusList = (httpGet: TGet) => {
   const { data, isLoading, error } = useSWR<FundingStatus[], HttpError>(
      fundingStatusBaseUrl,
      httpGet,
      { ...swrOptions }
   );

   return {
      fundingStatusList: data,
      isLoading: isLoading,
      error: error
   };
}

export const saveFundingStatus = async (httpPost: TPost, fundingStatus: FundingStatusWithInsuranceClasses) => {
   await httpPost(saveFundingStatusUrl, fundingStatus);
   mutate(fundingStatusBaseUrl);
}

export const saveFundingStatuses = async (httpPost: TPost, fundingStatuses: FundingStatus[], foundationId: number) => {
   await httpPost(saveFundingStatusOnlyUrl, fundingStatuses);
   mutate(fundingStatusBaseUrl);
   // saveFundingStatuses called from FoundationDiseaseTypeList needs to mutate the base foundation data because that's where the FundingStatus/InsuranceLookups come from
   mutate(assistanceProgramWithFoundationsUrl(foundationId));
}

export const deleteFundingStatuses = async (httpPost: TPost, fundingStatusIdsToDelete: number[], foundationId: number) => {
   await httpPost(deleteMultipleFundingStatusUrl, fundingStatusIdsToDelete);
   mutate(fundingStatusBaseUrl);
   // deleteFundingStatuses called from FoundationDiseaseTypeList needs to mutate the base foundation data because that's where the FundingStatus/InsuranceLookups come from
   mutate(assistanceProgramWithFoundationsUrl(foundationId));
}

export const deleteFundingStatus = async (httpDelete: TDelete, id: number) => {
   await httpDelete(deleteFundingStatusUrl(id));
   mutate(fundingStatusBaseUrl);
}

export function useFetchFundingStatusViewModel(httpGet: TGet): IStatus & { fundingStatusViewModels: FundingStatusViewModel[] } {
   const { fundingStatusList, isLoading, error } = useFetchFundingStatusList(httpGet);
   const { assistancePrograms, isLoading: isLoading_p, error: error_p } = useFetchAssistancePrograms(httpGet);
   const { assistanceServices, isLoading: isLoading_s, error: error_s } = useFetchAssistanceServices(httpGet);
   const { foundations, isLoading: isLoading_f, error: error_f } = useFetchFoundations(httpGet);
   const { foundationDiseaseTypes, isLoading: isLoading_fdt, error: error_fdt } = useFetchFoundationDiseaseTypes(httpGet);
   const { foundationDiseaseTypeGroups, isLoading: isLoading_g, error: error_g } = useFetchFoundationDiseaseTypeGroups(httpGet);
   const { insuranceClasses, isLoading: isLoading_i, error: error_i } = useFetchInsuranceClasses(httpGet);

   const viewModels = useMemo(() => {
      if (fundingStatusList?.length > 0
         && assistancePrograms?.length > 0
         && assistanceServices?.length > 0
         && foundations?.length > 0
         && foundationDiseaseTypes?.length > 0
         && foundationDiseaseTypeGroups?.length > 0
         && insuranceClasses?.length > 0) {

         const tempList = fundingStatusList?.map((item): FundingStatusViewModel => {

            const s = assistanceServices.find(y => y.id === item.assistanceServiceId);
            const p = s ? assistancePrograms.find(y => y.id === s?.assistanceProgramId) : undefined;
            const fdt = foundationDiseaseTypes.find(y => y.id === item.foundationDiseaseTypeId);
            const f = fdt ? foundations.find(y => y.id === fdt.foundationId) : undefined;
            const g = fdt ? foundationDiseaseTypeGroups.find(y => y.foundationDiseaseTypeId === item.foundationDiseaseTypeId) : undefined;
            const i = insuranceClasses.find(y => y.insuranceClassId === item.insuranceClassId);

            return {
               ...item,
               foundationId: fdt?.foundationId,
               foundationName: f?.foundationName,
               foundationScrapingStatus: f?.scrapingStatus,
               diseaseTypeId: g?.diseaseTypeId ?? fdt?.diseaseTypeId,
               diseaseTypeName: fdt?.diseaseTypeName,
               assistanceServiceName: s?.assistanceServiceName,
               assistanceProgramId: p?.id,
               programName: p?.programName,
               insuranceClassName: i?.name,
               statusType: item.status === 1 ? 'Closed' :
                  item.status === 2 ? 'Open' :
                     item.status === 3 ? 'Reenrollments Only' : 'Unknown'
            } as FundingStatusViewModel
         });
         return tempList ?? [];
      }
      return [];
   }, [fundingStatusList, assistancePrograms, assistanceServices, foundationDiseaseTypes,
      foundations, foundationDiseaseTypeGroups, insuranceClasses]);

   return {
      fundingStatusViewModels: viewModels,
      isLoading: isLoading || isLoading_p || isLoading_s || isLoading_f || isLoading_fdt || isLoading_g || isLoading_i,
      error: error?.message ?? error_p ?? error_s ?? error_f ?? error_fdt ?? error_g ?? error_i?.message
   };
}