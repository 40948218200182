import { Input, Space, Checkbox, Divider, Popover, Button } from 'antd';
import * as React from 'react';
import { IOptionItem } from '../../../functions/option.functions';
import colorWheel from '../../../Theme/ColorWheel';
import HighlightSearchText from '../../HighlightSearchText';
import CustomIcon, { CustomIconType } from '../AntComponents/CustomIcon';
import { BasicFieldWrapper, TValues } from '../BasicInputLibrary';
import { ActionButton } from '../Buttons';
import { Pill } from '../Pill';

export interface IBasicMultiSelectProps {
   options: IOptionItem[];
   searchable?: boolean;
   searchPlaceholder: string;
   placeholder?: string;
   allowSelectAll?: boolean;
   displayListStartingLength?: number;
   containerStyle?: React.CSSProperties;
   value?: any;
   label?: string;
   popoverTitle?: string;
   disabled?: boolean;
   style?: React.CSSProperties;
   onChange?: (value: TValues) => void;
   multiSelectOnly?: boolean;
   customIcon?: CustomIconType;
   showRecordCount?: boolean;
   showOkButton?: boolean;
}

export const BasicMultiSelect: React.FC<IBasicMultiSelectProps> = (props) => {
   const { searchable, allowSelectAll, searchPlaceholder, placeholder, options,
      displayListStartingLength,
      value, onChange, popoverTitle, label, disabled, multiSelectOnly, customIcon,
      showRecordCount, showOkButton } = props;


   const defaultDisplayListLength = displayListStartingLength ?? 200;
   const [displayListLength, setDisplayListLengh] = React.useState<number>(defaultDisplayListLength);
   const [isSearchOpen, setIsSearchOpen] = React.useState(false);
   const [searchValue, setSearchValue] = React.useState<string>(undefined);

   const searchInputRef = React.useRef(null);

   React.useEffect(() => {
      //set focus on local ref on first load - not perfect - but better than no focus
      //searchInputRef.current.focus();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [isSearchOpen])

   const getValues = (): [] => {
      if (value) {
         return value as [];
      }
      return [];
   }

   const getSelectedOptions = () => {
      const values = getValues();
      return options.filter(y => values?.some(v => v === y.value));
   }

   const filteredOptions = (): IOptionItem[] => {
      return searchValue ?
         options.filter(opt => (opt.label as string)?.toLowerCase().indexOf(searchValue) > -1) :
         options;
   }

   const IsOptionSelected = (option: IOptionItem): boolean => {
      const values = getValues();
      return values?.some(y => y === option.value);
   }

   const toggleSelectAll = (checked: boolean) => {
      if (checked) {
         const valuesToAssign = [...filteredOptions().map(y => y.value)]
         onChange(valuesToAssign);
      } else {
         onChange([]);
      }
   }

   const handleOnChange = (checked: boolean, option: IOptionItem) => {
      const values = getValues();
      if (checked) {
         const newValues = [...values, option.value];
         onChange && onChange(newValues);
      } else {
         const newValues = [...values.filter(y => y !== option.value)];
         onChange && onChange(newValues);
      }
   }

   const onReset = () => {
      onChange([]);
   }

   enum CheckboxState {
      Unchecked,
      Checked,
      Indeterminate
   }

   const determineSelectAllCheckboxState = (): CheckboxState => {
      const values = getValues();
      if (!values || !values?.length || values?.length === 0) {
         return CheckboxState.Unchecked;
      }

      if (values?.length > 0 && values.length === filteredOptions().length) {
         return CheckboxState.Checked;
      }

      return CheckboxState.Indeterminate;
   }

   const SelectAll: React.FC = () => {
      const checkboxState = determineSelectAllCheckboxState();
      return (
         <label style={{ paddingLeft: '2px' }}>
            <Space>
               <Checkbox
                  indeterminate={checkboxState === CheckboxState.Indeterminate}
                  checked={checkboxState === CheckboxState.Checked}
                  onChange={(e) => {
                     toggleSelectAll(e.target.checked);
                  }}
                  style={{ paddingLeft: '3px', transform: 'scale(.8)' }}
               />
               Select All
            </Space>
         </label>
      );
   }

   const ShowAll: React.FC = () => {
      const showingAll = displayListLength > defaultDisplayListLength;
      const buttonText = showingAll ?
         `Show First ${defaultDisplayListLength}` :
         `Show All  ${options?.length}`;

      return (
         <>
            {defaultDisplayListLength < options?.length &&
               <ActionButton
                  hasIcon={true}
                  buttonText={buttonText}
                  title='Note: May cause performance issues for large lists'
                  onClick={() => setDisplayListLengh(showingAll ? defaultDisplayListLength : options.length)} />
            }
         </>
      );
   }

   interface IOptionProps { option: IOptionItem }
   const Option: React.FC<IOptionProps> = (props) => {
      return (
         <div>
            <label>
               <Space>
                  <input
                     type='checkbox'
                     onChange={(e) => {
                        handleOnChange(e.target.checked, props.option)
                     }}
                     checked={IsOptionSelected(props.option)}
                  />
                  {searchable &&
                     <HighlightSearchText searchString={searchValue} targetString={(props.option.label as string)} />
                  }
                  {!searchable &&
                     props.option.label
                  }
               </Space>
            </label>
         </div>
      );
   }

   const toggleIsOpenFilter = (visible: boolean) => {
      setIsSearchOpen(visible);
   }

   const justTheMultiSelect = (
      <div style={{ width: 400 }}>
         {searchable &&
            <div style={{ paddingBottom: '10px' }}>
               <Input
                  placeholder={searchPlaceholder}
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value?.toLowerCase())}
                  ref={searchInputRef}
               />
               <br />
            </div>
         }
         {(allowSelectAll || defaultDisplayListLength < options?.length) &&
            <>
               <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <div>{allowSelectAll && <SelectAll />}</div>
                  <div><ShowAll /></div>
               </div>
               <Divider style={{ margin: '8px 0' }} />
            </>
         }
         <div style={{ height: options?.length > 5 ? 250 : 100, flex: 'auto', overflowY: 'auto' }}>
            {filteredOptions().slice(0, displayListLength).map(m => <Option key={m.value} option={m} />)}
         </div>
         <Divider />
         <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <ActionButton buttonText='Reset Selections'
               onClick={onReset}
               title={`${getValues()?.length} selected`}
            />
            {showRecordCount && <div style={{ paddingTop: '5px' }}>Selected {getValues()?.length ?? 0} record(s)</div> }
            {showOkButton && <div>
               <ActionButton buttonText='Ok'
                  filled
                  onClick={() => {
                     setIsSearchOpen(false)
                  }}
               />
            </div>}
         </div>
      </div>
   )


   const pillsAndPopoverWithMultiSelect = (
      <div className='ap-multiselect'
         style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            zIndex: 0,
            cursor: disabled ? 'not-allowed' : 'auto'
         }}
         onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            if (!disabled && !isSearchOpen) {
               setIsSearchOpen(true)
            }
         }}>
         <div style={{ marginTop: 2, marginLeft: 10, zIndex: 1, overflowY: 'auto', maxHeight: 65 }}>
            {getSelectedOptions().map(option => {
               return <Pill
                  key={option.value}
                  color='default'
                  onClose={disabled ? undefined : () => {
                     handleOnChange(false, option);
                  }}>
                  <>{option.label}</>
               </Pill>
            }
            )}
            {!value &&
               <div className='ap-placeholder'>
                  {placeholder}
               </div>
            }
         </div>
         <div style={{
            display: 'flex',
            flexDirection: 'column'
         }} >
            <Popover
               title={popoverTitle}
               open={isSearchOpen}
               onOpenChange={toggleIsOpenFilter}
               trigger='click'
               placement='left'
               content={justTheMultiSelect}
            >
               {!disabled && <Button
                  style={{ padding: '0px 5px', height: 28 }}
                  icon={<CustomIcon style={{ fontSize: '12px', color: colorWheel.tangerineOrange }} type={customIcon ?? CustomIconType.SearchOutlined} />}
                  onClick={(e) => e.preventDefault()}
               />}

            </Popover>
         </div>
      </div>
   )

   const contentWithoutLabel = multiSelectOnly ? justTheMultiSelect : pillsAndPopoverWithMultiSelect;

   return label ? <BasicFieldWrapper label={label} field={contentWithoutLabel} /> : contentWithoutLabel;
}