import { KeyWithVerb, IApiErrorMessage } from "./ErrorContext";


export interface GlobalErrorState {
   errors: IApiErrorMessage[];
}

export interface IErrorPayload extends IApiErrorMessage {
   keys?: string[]; //exact keys to match
   keysLike?: string[];  //key can contain partial strings
   keysWithVerb?: KeyWithVerb[];
}

export enum GlobalErrorActionTypes {
   ADD_ERROR = 'ADD_ERROR',
   REMOVE_ERROR = 'REMOVE_ERROR',
   BULK_REMOVE_ERROR = 'BULK_REMOVE_ERROR',
}
interface ErrorAction {
   type: GlobalErrorActionTypes;
   payload: IErrorPayload;
}

const filterKeys = (errorMessage: IApiErrorMessage, keys?: string[]): boolean => {
   if (!keys || keys.length === 0) return true;

   return !keys.some(y => y.toLowerCase().trim() === `${errorMessage.key}`.toLowerCase().trim());
}

const filterKeysLike = (errorMessage?: IApiErrorMessage, keysLike?: string[]): boolean => {
   if (!keysLike || keysLike.length === 0) return true;

   return !keysLike.some(y => `${errorMessage.key}`.toLowerCase().trim().indexOf(y.toLowerCase().trim()) > -1);
}

export function GlobalErrorReducer(state: GlobalErrorState, action: ErrorAction) {
   //console.log('GlobalErrorReducer', state, action);

   switch (action.type) {
      case GlobalErrorActionTypes.ADD_ERROR: {
         return {
            errors: [
               ...state.errors?.filter(y => y.key !== action.payload.key),
               action.payload
            ]
         };
      }
      case GlobalErrorActionTypes.REMOVE_ERROR:
         return {
            errors: [
               ...state.errors?.filter(y => y.key !== action.payload.key)
            ]
         };
      case GlobalErrorActionTypes.BULK_REMOVE_ERROR:
         return {
            errors: [
               ...state.errors?.filter(y =>
                  !filterKeys(y, action.payload.keys) &&
                  !filterKeysLike(y, action.payload.keysLike))
            ]
         };

      default:
         console.warn('GlobalErrorReducer was called with an unexpected type', action.type);
         return state;
   }
}
export default GlobalErrorReducer;