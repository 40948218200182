import * as React from 'react';
import { useApiContext } from '../../store/ApiContext';
import { useGetContentClassificationChangeLogSummary } from '../../store/xref/ContentClassificationXRefChangeLogFetcher';
import { modifiedRanges } from '../../store/xref/ContentClassificationXRefChangeLogModel';
import colorWheel from '../../Theme/ColorWheel';
import { NumberedTitle } from '../shared/AntComponents/Typography/Title';
import Spinner from '../Spinner';
import { Avatar, Col, List } from 'antd';
import { useNavigate } from 'react-router';

const ContentClassificationChangeLogSummaryTile: React.FC<{ styles?: React.CSSProperties }> = (props) => {
   const { styles } = props;
   const { httpGet } = useApiContext();
   const { changeLogSummary, isLoading } = useGetContentClassificationChangeLogSummary(httpGet);
   const navigate = useNavigate();

   const NumberIcon = (props: {
      daysRange: modifiedRanges;
      color: string;
      count: number;
   }) => {
      const { daysRange, color, count } = props;

      return <Avatar
         shape='circle'
         size='default'
         style={{
            cursor: count > 0 ? 'pointer' : 'auto',
            backgroundColor: color,
            fontWeight: 600
         }}
         onClick={async () => {
            navigate(`content-tool/CONTENT_CLASSIFICATION_XREF/${daysRange}`)
         }}
      >
         {count}
      </Avatar>
   }

   const RowText = (props: {
      text: string;
   }) => {
      const { text } = props;
      return <span style={{ fontSize: 14, fontWeight: 500 }}>{text}</span>
   }

   if (isLoading) return <Spinner message={'Loading ChangeLog summary...'} />

  
   return (
      <div style={{ ...styles }}>
         <NumberedTitle level={5} text='Practice Payer Mapping' />
         <div style={{ border: `1px solid ${colorWheel.mediumGrey}`, maxHeight: '25rem'}}>
            <List>
               <List.Item style={{ paddingTop: 8, paddingBottom:8, backgroundColor:colorWheel.figmaGrey }}>
                  <Col span={9} style={{ textAlign: 'right' }}><span style={{ fontSize: 14, fontWeight: 700 }}>Period</span></Col>
                  <Col span={10} offset={5} style={{ textAlign: 'left' }}><span style={{ fontSize: 14, fontWeight: 700 }}># of Changes</span></Col>
               </List.Item>
               <List.Item style={{ paddingTop: 3, paddingBottom: 3 }}>
                  <Col span={9} style={{ textAlign: 'right' }}><RowText text='This week:' /></Col>
                  <Col span={9} offset={6} style={{ textAlign: 'left' }}>
                     <NumberIcon daysRange={modifiedRanges.thisWeek}
                        color={colorWheel.ivyGreen}
                        count={changeLogSummary?.thisWeek ?? 0} />
                  </Col>
               </List.Item>
               <List.Item style={{ paddingTop: 3, paddingBottom: 3 }}>
                  <Col span={9} style={{ textAlign: 'right' }}><RowText text='Last week:' /></Col>
                  <Col span={9} offset={6} style={{ textAlign: 'left' }}>
                     <NumberIcon daysRange={modifiedRanges.lastWeek}
                        color={colorWheel.ocherYellow}
                        count={changeLogSummary?.lastWeek ?? 0} />
                  </Col>
               </List.Item>
               <List.Item style={{ paddingTop: 3, paddingBottom: 3 }}>
                  <Col span={9} style={{ textAlign: 'right' }}><RowText text='Prior:' /></Col>
                  <Col span={9} offset={6} style={{ textAlign: 'left' }}>
                     <NumberIcon daysRange={modifiedRanges.lastMonth}
                        color={colorWheel.graniteGrey}
                        count={changeLogSummary?.lastMonth ?? 0} />
                  </Col>
               </List.Item>
            </List>
         </div>
      </div>
   );
}

export default ContentClassificationChangeLogSummaryTile;