import useSWR, { mutate } from 'swr';
import { HttpError, TPost, TGet } from '../../functions/httpClient';
import { UserFundStatusAlert } from './UserFundStatusModels'

const userFundStatusControllerBaseUrl = '/api/fsa/UserFundStatus';
const getUserFundStatusAlertUrl = `${userFundStatusControllerBaseUrl}/GetUserFundStatusAlert`;
export const saveUserFundStatusAlertUrl = `${userFundStatusControllerBaseUrl}/SaveUserFundStatusAlert`;

const swrOptions = {
   refreshInterval: 0,
   revalidateOnFocus: false,
   dedupingInterval: 60000     //default 2000
};

export const useFetchUserFundStatusAlert = (httpGet: TGet) => {
   const { data, isValidating, error } = useSWR<UserFundStatusAlert[], HttpError>(
      getUserFundStatusAlertUrl,
      httpGet,
      { ...swrOptions }
   );

   return {
      userFundStatusAlerts: data,
      isLoading: !error && !data && isValidating,
      error: error as HttpError
   };
}

export const saveUserFundStatusAlert = (httpPost: TPost, userFundStatusAlert: UserFundStatusAlert) => {
   httpPost(saveUserFundStatusAlertUrl, userFundStatusAlert)
      .then(() => mutate(getUserFundStatusAlertUrl));
}