import { Space, Form } from 'antd';
import * as React from 'react';
import { IOptionItem } from '../../functions/option.functions';
import { useApiContext } from '../../store/ApiContext';
import { uploadEnrollmentFile, enrollmentStatusBaseUrl, useFetchSingleEnrollmentStatus } from '../../store/content-tool/EnrollmentStatusFetcher';
import ApiErrorDisplay from '../ApiErrorDisplay';
import Dialog from '../Dialog';
import { CancelButton, SaveButton } from '../shared/Buttons';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Col, Row } from 'antd/lib';
import { DropdownField, InputField } from '../shared/InputLibrary';
import { FormFileUploadDragger, MAX_UPLOAD_FILE_SIZE_MB } from '../shared/AntComponents/FileUploadDragger';
import { BetterEnrollmentUpload } from '../../store/content-tool/EnrollmentStatusModel';
import { TitleError } from '../shared/AntComponents/Typography/Title';
import { CustomIconType } from '../shared/AntComponents/CustomIcon';
import { useErrorContext } from '../../store/ErrorContext';


interface IEnrollmentStatusFileUploadDraggerProps {
   isEditorOpen: boolean,
   applicationId: number
   onCloseEditor: () => void;
}

const enrollmentUploadYup = yup.object({
   applicationId: yup.number().notRequired(),
   // Attempts to type file as a File / object<File> ran into a lot of errors.
   // So, since we're not doing any specific validation, this is sufficient for "is a file present in the form"
   file: yup.mixed().required('A File is required'),
   enrollmentId: yup.number().required('An Enrollment is required'),
   fileDescription: yup.string().notRequired()
});

interface IEnrollmentUploadYup extends yup.Asserts<typeof enrollmentUploadYup> { }

const formId = 'form_enrollmentStatusFileUploadDragger';
const _keysLike: string[] = [enrollmentStatusBaseUrl]; // file upload URL really can't be rebuilt so this is likely the best we can do...

const EnrollmentStatusFileUploadDragger: React.FC<IEnrollmentStatusFileUploadDraggerProps> = (props) => {
   const { applicationId, isEditorOpen, onCloseEditor } = props;
   const { httpGet, httpPutFile } = useApiContext();
   const { removeErrors } = useErrorContext();
   const { enrollmentStatus } = useFetchSingleEnrollmentStatus(httpGet, applicationId);

   const {
      control,
      handleSubmit,
      reset,
      setError,
      clearErrors,
      trigger,
      formState: { errors }
   } = useForm<IEnrollmentUploadYup>({
      resolver: yupResolver<yup.AnyObject>(enrollmentUploadYup),
      shouldFocusError: true,
   });

   const [isSaving, setIsSaving] = React.useState(false);

   React.useEffect(() => {
      if (applicationId) {
         reset({ applicationId: applicationId });
      }
      // for "reset(...)"
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [applicationId])

   const enrollmentOptions = React.useMemo(() => {
      if (enrollmentStatus) {
         let options: IOptionItem[] = [];
         enrollmentStatus?.applicationServices?.forEach(s => {
            s.enrollments?.forEach(e => {
               options.push({
                  key: e.enrollmentId,
                  value: e.enrollmentId,
                  label: `Enrollment Id: ${e.enrollmentId} - Tracking Id: ${e.trackingId ?? 'unknown'}`
               } as IOptionItem);
            })
         });
         return options;
      }
      return undefined;
   }, [enrollmentStatus]);

   const closeDialog = () => {
      removeErrors({ keysLike: _keysLike });
      onCloseEditor();
   }

   const onSubmit = (data: IEnrollmentUploadYup) => {
      setIsSaving(true);

      const dataToSubmit = data as unknown as BetterEnrollmentUpload;

      uploadEnrollmentFile(httpPutFile, dataToSubmit)
         .then(closeDialog)
         .catch(err => console.error('Error Uploading File', err))
         .finally(() => setIsSaving(false));
   }

   const preventDefaultRequest = () => {
      /* for this component, we want the upload to occur on the upload button click event.
      This dummy request is passed into the customRequest prop of the dragger
      preventing the antd default behavior of trying to upload a documnt on file selection.
      */
   };

   const html = (<>
      <Dialog
         title={'Upload Document'}
         open={isEditorOpen}
         size='small'
         actionButtons={
            <Space>
               <CancelButton loading={isSaving} onClick={closeDialog} />
               <SaveButton
                  loading={isSaving}
                  disabled={Object.keys(errors).length > 0}
                  onClick={() => null}
                  formId={formId}
               />
            </Space>
         }
      >
         <div>

            {errors && Object.keys(errors).length > 0 && <>
               <TitleError
                  text='Please correct the errors below' />
               {console.log('--------------Form Errors: -------------------------')}
               {console.log(errors)}
            </>
            }

            <ApiErrorDisplay
               title='Error Uploading File'
               keysLike={_keysLike}
            />

            <Form id={formId} onFinish={handleSubmit(onSubmit)}>
               <Row gutter={[16, 16]}>
                  <Col span={12}>
                     <FormFileUploadDragger
                        control={control}
                        name={'file'}
                        error={errors?.file}
                        trigger={trigger}
                        accept={'.pdf'}
                        maximumFileSizeMB={MAX_UPLOAD_FILE_SIZE_MB}
                        customRequest={preventDefaultRequest}
                        setError={setError}
                        clearErrors={clearErrors}
                        icon={CustomIconType.FilePdfOutlined}
                     />
                  </Col>
                  <Col span={12}>
                     <DropdownField
                        name={'enrollmentId'}
                        control={control}
                        options={enrollmentOptions}
                        error={errors?.enrollmentId}
                        label={'Enrollment'}
                        placeholder={'Choose an Enrollment'}
                        required
                     />
                  </Col>
               </Row>
               <Row gutter={[16, 16]}>
                  <Col span={24}>
                     <InputField
                        label={'File Description'}
                        control={control}
                        name={'fileDescription'}
                        error={errors?.fileDescription}
                     />
                  </Col>
               </Row>
            </Form>
         </div>
      </Dialog>
   </>);

   return html;
}

export default EnrollmentStatusFileUploadDragger;