import * as React from 'react';
import { UserProfile } from '../../store/practice/UserProfileModel';
import MinimalistTable from '../shared/AntComponents/Table/MinimalistTable';
import { ColumnsType } from 'antd/lib/table';
import { boolComparer, dateComparer, stringComparer } from '../../functions/comparer.functions';
import HighlightSearchText from '../HighlightSearchText';
import { Link } from 'react-router-dom';
import { formatBoolean } from '../../functions/format.functions';
import { antSortOptions } from '../shared/AntComponents/Table/table.functions';
import { ActionButton, AddButton, EditButton } from '../shared/Buttons';
import { Space } from 'antd';
import CustomIcon, { CustomIconType } from '../shared/AntComponents/CustomIcon';
import { formatDateLong } from '../../functions/time.functions';
import CopyFromUserProfileEditor from './shared/CopyUserProfileEditor';
import ExistingUserProfileEditor from './shared/ExistingUserProfileEditor';
import NewUserProfileEditor from './shared/NewUserProfileEditor';
import UnmapUserProfileButton from './shared/UnmapUserProfileDialog';
import BulkCopyUserProfile from './shared/BulkCopyUserProfile';


interface IUserProfileRowProps {
   userProfiles: UserProfile[],
   userMainId: number,
   freeTextFilter?: string,
   displayCopyButton?: boolean,
   gridTitle?: string,
   displayAddNew?: boolean,
   displayUnmap?: boolean
}

const UserProfileRow: React.FC<IUserProfileRowProps> = (props) => {
   const { userProfiles, freeTextFilter, displayCopyButton, gridTitle, userMainId, displayAddNew, displayUnmap } = props;
   const [isEditorModalOpen, setIsEditorModalOpen] = React.useState(false);
   const [isCopyModalOpen, setIsCopyModalOpen] = React.useState(false);
   const [isAddNewModalOpen, setIsAddNewModalOpen] = React.useState(false);
   const [isUnmapModalOpen, setIsUnmapModalOpen] = React.useState(false);
   const [isBulkCopyModalOpen, setIsBulkCopyModalOpen] = React.useState(false);
   const [profileToUnmap, setProfileToUnmap] = React.useState<UserProfile>(undefined);
   const [selectedRecord, setSelectedRecord] = React.useState<UserProfile>(undefined);

   const onEditClick = (record: UserProfile) => {
      setSelectedRecord(record);
      setIsEditorModalOpen(true);
   }

   const onEditModelClose = () => {
      setSelectedRecord(undefined);
      setIsEditorModalOpen(false);
   }

   const onCopyClick = (record: UserProfile) => {
      setSelectedRecord(record);
      setIsCopyModalOpen(true);
   }

   const onCopyModalClose = () => {
      setSelectedRecord(undefined);
      setIsCopyModalOpen(false);
   }

   const onAddNewClick = () => {
      setIsAddNewModalOpen(true);
   }

   const onAddNewModalClose = () => {
      setIsAddNewModalOpen(false);
   }

   const onBulkCopyClick = (userProfile: UserProfile) => {
      setSelectedRecord(userProfile);
      setIsBulkCopyModalOpen(true);
   }

   const onClickUnmap = (userProfile: UserProfile) => {
      setProfileToUnmap(userProfile);
      setIsUnmapModalOpen(true);
   }

   const onUnmapDialogClose = () => {
      setProfileToUnmap(undefined);
      setIsUnmapModalOpen(false);
   }

   const onBulkCopyModalClose = () => {
      setSelectedRecord(undefined);
      setIsBulkCopyModalOpen(false);
   }

   const columns: ColumnsType<UserProfile> = [
      {
         dataIndex: 'firstName',
         title: 'First Name',
         key: 'firstName',
         sorter: (a, b) => stringComparer(a.firstName, b.firstName),
         sortDirections: antSortOptions,
         render: (text, record) => <HighlightSearchText targetString={record.firstName} searchString={freeTextFilter} />
      },
      {
         dataIndex: 'lastName',
         title: 'Last Name',
         key: 'lastName',
         sorter: (a, b) => stringComparer(a.lastName, b.lastName),
         sortDirections: antSortOptions,
         render: (text, record) => <HighlightSearchText targetString={record.lastName} searchString={freeTextFilter} />
      },
      {
         dataIndex: 'title',
         title: 'Title',
         key: 'title',
         sorter: (a, b) => stringComparer(a.title, b.title),
         sortDirections: antSortOptions
      },
      {
         dataIndex: 'practiceName',
         title: 'Practice',
         key: 'practiceName',
         sorter: (a, b) => stringComparer(a.practiceName, b.practiceName),
         sortDirections: antSortOptions,
         render: (text, record) => 
            <Link
               to={{ pathname: `/practice/${record.practiceId}` }}
               title = {`Navigate to Practice ${record.practiceId}`}
               state = {{ backTo: 'userMain' }}>
               {record.practiceName ?? "<noname>"} ({record.practiceId})
            </Link> 
      },
      {
         dataIndex: 'isDisabled',
         title: 'Disabled',
         key: 'isDisabled',
         sorter: (a, b) => boolComparer(a.isDisabled, b.isDisabled),
         sortDirections: antSortOptions,
         render: (val) => formatBoolean(val)
      },
      {
         dataIndex: 'lastLogin',
         title: 'Last Login',
         key: 'lastLogin',
         sorter: (a, b) => dateComparer(a.lastLogin, b.lastLogin),
         sortDirections: antSortOptions,
         // Taken from UserDetails Column definition when they were separate 
         render: (record, index) => {
            if (!record || record === undefined)
               return 'N/A'
            // we're assuming client's local time per AH-7794 requirement
            const localTime = `${new Date(record).toLocaleDateString('en-US')} ${new Date(record).toLocaleTimeString()}`;
            return formatDateLong(localTime);
         }
      },
      {
         title: displayAddNew && <AddButton displayNoText title='Add User Profile' onClick={onAddNewClick} />,
         render: (text, record) => <Space>
            <EditButton
               displayNoText
               onClick={() => onEditClick(record) }
            />
            {displayCopyButton && <ActionButton
               onClick={() => onCopyClick(record)}
               icon={<CustomIcon type={CustomIconType.CopyOutlined} />}
               title={'Copy'}
            />}
            {displayCopyButton && <ActionButton
               onClick={() => onBulkCopyClick(record)}
               icon={<CustomIcon type={CustomIconType.CopyFilled} />} // TODO: revisit icon choice
               title={'Bulk Copy' }
               />}
            {displayUnmap && <ActionButton
               onClick={() => onClickUnmap(record)}
               displayNoText
               icon={<CustomIcon type={CustomIconType.DisconnectOutlined} />}
               title={'Unmap User Profile'}
            />}
         </Space>
      }
   ];

   const html = (
      <>
         <MinimalistTable
            showTitle={gridTitle != undefined}
            titleText={gridTitle}
            titleLevel={3}
            rowKey='userProfileId'
            data={userProfiles}
            columns={columns}
            size='small'
            showHeader={true}
         />
         {isEditorModalOpen && <ExistingUserProfileEditor
            onCloseEditor={onEditModelClose}
            existingUserProfileId={selectedRecord.userProfileId}
            isEditorOpen={isEditorModalOpen}
            isEditorReadOnly={false}
            userMainId={userMainId}
            />
         }         
         {isCopyModalOpen && <CopyFromUserProfileEditor
            copyFromUserProfileId={selectedRecord.userProfileId}
            isEditorOpen={isCopyModalOpen}
            onCloseEditor={onCopyModalClose}
            userMainId={userMainId}
            />
         }
         {isAddNewModalOpen && <NewUserProfileEditor
            isEditorOpen={isAddNewModalOpen}
            onCloseEditor={onAddNewModalClose}
            userMainId={userMainId}
         />}
         {isUnmapModalOpen && <UnmapUserProfileButton
            isDialogOpen={isUnmapModalOpen}
            onDialogClose={onUnmapDialogClose}
            userMainId={userMainId}
            userProfile={profileToUnmap}
            />
         }
         {isBulkCopyModalOpen && <BulkCopyUserProfile
            copyFromUserProfileId={selectedRecord.userProfileId}
            copyFromPracticeId={selectedRecord.practiceId}
            isEditorOpen={isBulkCopyModalOpen}
            onCloseEditor={onBulkCopyModalClose}
            userMainId={userMainId}
         />}
      </>
   )

   return html;
}

export default UserProfileRow;