import * as React from 'react';
import { AppInsightsContext, AppInsightsErrorBoundary, ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ICustomProperties } from '@microsoft/applicationinsights-core-js';
import { useFetchSettings } from './store/SettingsFetcher';
import { createBrowserHistory } from 'history';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import UnhandledError from './UnhandledError';

interface IInsightsContextProps {
   reactPlugin: ReactPlugin;
   //appInsights: ApplicationInsights;
   logAppInsightsError: (error: Error, message?: string) => void;
   logAppInsightsErrorMessage: (message: string) => void;
   setAuthenticatedUserContext: (authenticatedUserId: string) => void;
}

export const InsightsContext = React.createContext<IInsightsContextProps>({} as IInsightsContextProps);
export const useInsightsContext = () => React.useContext(InsightsContext);

interface IInsightsContextProviderProps {
   children: React.ReactElement;
}

let UnsafeAppInsights: ApplicationInsights;
export { UnsafeAppInsights };

export const InsightsContextProvider: React.FC<IInsightsContextProviderProps> = (props) => {
   const { children } = props;
   const { settings, isLoading } = useFetchSettings();
   const [reactPlugin, setReactPlugin] = React.useState<ReactPlugin>();
   const [appInsights, setAppInsights] = React.useState<ApplicationInsights>();

   React.useEffect(() => {
      const loadReactPlugin = async () => {
         if (settings && settings.length > 0 && !reactPlugin) {
            const instrumentationKey = settings?.find(f => f?.group === "ApplicationInsights" && f?.key === 'InstrumentationKey')?.value;
            const connectionString = settings?.find(f => f?.group === "ApplicationInsights" && f?.key === 'ConnectionString')?.value;

            const browserHistory = createBrowserHistory();
            const _reactPlugin = new ReactPlugin();
            const _appInsights = new ApplicationInsights({
               config: {
                  instrumentationKey: instrumentationKey,
                  connectionString: connectionString,
                  enableAutoRouteTracking: true,
                  extensions: [_reactPlugin],
                  extensionConfig: {
                     [_reactPlugin.identifier]: { history: browserHistory }
                  }
               }
            });

            _appInsights.loadAppInsights();

            UnsafeAppInsights = _appInsights;
            setReactPlugin(_reactPlugin);
            setAppInsights(_appInsights);
         }
      };

      loadReactPlugin();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [settings]);

   const logAppInsightsErrorMessage = (message: string) => {
      try {
         appInsights.trackException(new Error(message) as unknown, { message: message });
      } finally {
         console.error(message);
      }
   }

   const logAppInsightsError = (error: Error, message: string = null) => {
      try {
         if (message) {
            appInsights.trackException(error as unknown, { message: message });
         } else {
            appInsights.trackException(error as unknown);
         }
      } finally {
         message ? console.error(message, error) : console.error(error);
      }
   }

   const setAuthenticatedUserContext = (authenticatedUserId: string) => {
      try {
         appInsights.setAuthenticatedUserContext(authenticatedUserId);
      } catch (e) {
         console.error('Error calling setAuthenticatedUserContext', authenticatedUserId, 'appInsights has instance', appInsights !== undefined)
      }
   }

   return (
      <InsightsContext.Provider
         value={{
            reactPlugin,
            //appInsights,
            logAppInsightsError,
            logAppInsightsErrorMessage,
            setAuthenticatedUserContext
         }}
      >
         <AppInsightsContext.Provider value={reactPlugin}>
            <AppInsightsErrorBoundary onError={() => <UnhandledError />} appInsights={reactPlugin}>
               {children}
            </AppInsightsErrorBoundary>
         </AppInsightsContext.Provider>
      </InsightsContext.Provider>
   );
};
