import { isTodayBetween } from "../../functions/common.functions";

//cohortSummary
export interface CohortSummary {
   cohortId: number;
   cohortTypeId: number;
   name: string;
   cohortStatus: string;
   totalItems: number;
   newItems: number;
   auditedItems: number;
   recommendedChanges: number;
   acceptedChanges: number;
   rejectedChanges: number;
   publishedItems: number;
   startDate: Date;
   endDate: Date;
   publishedDate: Date;
}

export const countOpenCohorts = (cohorts: CohortSummary[]): number => {
   let count: number = 0;
   for (let idx = 0; idx < cohorts.length; idx++) {
      if (isCohortOpen(cohorts[idx])) {
         count += 1;
      }
   }
   return count;
}

export const isCohortOpen = (cohort: CohortSummary): boolean => {
   //should bring status in 
   return cohort && !isCohortPublished(cohort) &&
      isTodayBetween(cohort.startDate, cohort.endDate);
}

export const isCohortPublished = (cohort: CohortSummary): boolean => {
   return cohort && cohort.publishedDate !== undefined;
}

export const countNewItems = (cohorts: CohortSummary[]): number => {

   let count: number = 0;

   for (let idx = 0; idx < cohorts.length; idx++) {
      if (isCohortOpen(cohorts[idx])) {
         count += cohorts[idx].newItems;
      }
   }
   return count;
}

export const countUserItemsTodo = (cohorts: CohortSummary[]): number => {
   let count: number = 0;
   for (let idx = 0; idx < cohorts.length; idx++) {
      if (isCohortOpen(cohorts[idx])) {
         count += cohorts[idx].newItems;
      }
   }
   return count;
}

export const countAdminItemsTodo = (cohorts: CohortSummary[]): number => {
   let count: number = 0;
   for (let idx = 0; idx < cohorts.length; idx++) {
      if (isCohortOpen(cohorts[idx])) {
         count += cohorts[idx].recommendedChanges;
      }
   }
   return count;
}
