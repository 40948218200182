import * as React from 'react';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models, Page } from 'powerbi-client';
import { embedReportUrl, useEmbeddedReport } from '../../store/report/ReportFetcher';
import ApiErrorDisplay from '../ApiErrorDisplay';
import Spinner from '../Spinner';
import { useApiContext } from '../../store/ApiContext';
import { savePowerBIAudit } from '../../store/report/ReportAuditFetcher';

interface IReportContextProps {
   setReportRendered: (isReportRendered: boolean) => void;
   workspaceId: string;
   reportId: string;
   pageId: string;
}

export const ReportViewEmbedded: React.FC<IReportContextProps> = (props) => {
   const { httpGet, httpPut } = useApiContext();
   const {workspaceId, reportId, pageId, setReportRendered } = props;
   const _keysLike: string[] = [embedReportUrl(workspaceId, reportId)];

   const { embeddedReport, isLoading, error } = useEmbeddedReport(httpGet, workspaceId, reportId);

   React.useEffect(() => {
      if (embeddedReport)
         savePowerBIAudit(httpPut, workspaceId, reportId, pageId);
   },
   // eslint-disable-next-line react-hooks/exhaustive-deps
   [embeddedReport, workspaceId, reportId, pageId])

   React.useEffect(() => {
      //The effect will flag the report as rendered in the event of an api error
      const theEffect = async () => {
         if (error || embeddedReport?.errorMessage) {
            setReportRendered(true);
         }
      }
      theEffect();
   },
   // eslint-disable-next-line react-hooks/exhaustive-deps
   [error, embeddedReport?.errorMessage])

   if (!pageId) {
      return (
         <div style={{ height: '100%', width: '100%', padding: 25 }}>
            <Spinner style={{ height: '100vh' }} message="Loading Report Page" />
         </div>)
   }

   if (isLoading) {
      return (
         <div style={{ height: '100%', width: '100%', padding: 25 }}>
            <Spinner style={{ height: '100vh' }} message="Loading Report" />
         </div>)
   }
   
   const html = <>
      <div style={{ height: 'unset', width: 'unset', padding: 'unset' }}>
         <ApiErrorDisplay
            title={`Unable to load this report: WorkspaceId: ${workspaceId}, ReportId: ${reportId}`}
            keysLike={_keysLike}
         />
      </div>

      {!embeddedReport && !error &&
         <Spinner message="Loading Report Configuration" />
      }

      {embeddedReport &&
         <PowerBIEmbed
            embedConfig={{
               type: 'report',
               tokenType: models.TokenType.Embed,
               id: embeddedReport.id,
               embedUrl: embeddedReport.embedUrl,
               accessToken: embeddedReport.token,
               pageName: pageId,
               slicers: GetSlicerState(workspaceId, reportId),
               settings: {
                  //https://powerbi.microsoft.com/en-us/blog/announcing-persistent-filters-in-the-service/
                  // persistentFiltersEnabled: true,  //<--would be perfect if it worked
                  panes: {
                     pageNavigation: {
                        visible: false
                     }
                  }
               },
            }}
         cssClassName="powerBiEmbed"            
            eventHandlers={
               new Map([
                  //'loaded' event still let some timing issues in - moved to 'rendered'
                  // ['loaded', (e: any, report: any) => {
                  //    console.log('loaded', e, report);
                  //}],
                  ['rendered', (e: any, report: any) => {
                     //will fire each time a report loads or a slicer state changes
                     SaveSlicerState(report.config.groupId, report.config.id, report.page(report.config.pageName));
                     setReportRendered(true);
                  }]
               ])
            }
         />
      }
   </>

   return html;
}

const slicerName = (workspaceId: string, reportId: string) => `reports/${workspaceId}/${reportId}/slicers`;

const SaveSlicerState = async (workspaceId: string, reportId: string, page: Page) => {
   try {

      const stateKeyName = slicerName(workspaceId, reportId);
      const visuals = await page.getVisuals();
      const slicers = [];

      for (const visual of visuals.filter((f: any) => f.type === 'slicer')) {
         try {
            const slicerState = await visual.getSlicerState();
            slicers.push({
               selector: {
                  $schema: "http://powerbi.com/product/schema#visualSelector",
                  visualName: visual.name
               },
               state: {
                  filters: slicerState.filters
               }
            });
         } catch (e) {
            const estr = JSON.stringify(e);
            if (estr?.includes('visualConfigIsNotInitialized')) {
               //if (e.message === 'visualConfigIsNotInitialized') {
               //A2-536 : added catch to stop this error from landing on the console
               //message: "visualConfigIsNotInitialized"
               //detailedMessage: "Visual container config is not initialized"
            } else {
               console.warn(`SaveSlicerState embedded error`, e);
               throw e;
            }
         }
      }
      //if an empty array or nothing is returned, do not set and overwrite any filters
      const slicerString = JSON.stringify(slicers);
      if (slicerString?.length > 2) {
         localStorage.setItem(stateKeyName, slicerString);
      }
   }
   catch (ve) {
      //console.error(`get visual blew up for [${reportId}]`, ve, page);

      const vestr = JSON.stringify(ve);
      if (vestr?.includes('pageNotFound') || vestr?.includes('PowerBIEntityNotFound')) {
         //if (ve?.message === 'pageNotFound' || vie?.message === 'PowerBIEntityNotFound') {
         console.warn('Expected error', ve);
         return;
      }
      else {
         //console.error(`visual error`);
         //throw ve;
         console.warn('Unexpected Visual Error', ve);
      }
   }
}

const GetSlicerState = (workspaceId: string, reportId: string) => {
   const stateKeyName = slicerName(workspaceId, reportId);
   const slicerString = JSON.parse(localStorage.getItem(stateKeyName));
   return slicerString as unknown as models.ISlicer[];
}

