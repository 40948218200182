import * as React from 'react';
import './EmptyModal.css';

interface IProps {
   title?: string,
   closeFunc?: () => void;
   show: boolean;
   children: React.ReactNode,
   modalCss?: string,
}

const EmptyModal: React.FC<IProps> = (props) => {
   if (!props.show) 
      return null;

   return (
      <div className="modal">
         <div className={`modal-container ${props.modalCss}`}>
            {(props.title || props.closeFunc) &&
               <div className="modal-toolbar">
               {props.title &&
                  <h2 title={props.title}>
                     {props.title}
                  </h2>
               }
               {props.closeFunc &&
                  <div>
                  <span onClick={props.closeFunc} className="close-modal-button">&times;</span>
                  </div>
                  }
               </div>
            }
            <div className="content ">
               {props.children}
            </div>
         </div>
      </div>
   )
};

export default EmptyModal;