import * as React from 'react';
import { useForm } from 'react-hook-form';
import { InputField, TextAreaField, DropdownField, RadioListField, CheckboxField } from '../../shared/InputLibrary';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Dialog from '../../Dialog';
import { SaveButton, DeleteButton, CancelButton } from '../../shared/Buttons';
import Spinner from '../../Spinner';
import { useFetchAssistancePrograms } from '../../../store/program/AssistanceProgramFetcher';
import { Form } from 'antd';
import { AssistanceService, assistanceTypeOptions } from '../../../store/program/AssistanceServiceModel';
import { useFetchAssistanceServices, saveAssistanceService, deleteAssistanceService, assistanceServiceBaseUrl, assistanceServiceSaveUrl } from '../../../store/program/AssistanceServiceFetcher';
import { useServiceCategories } from '../../../store/program/ServiceCategoryFetcher';
import ApiErrorDisplay from '../../ApiErrorDisplay';
import { IOptionItem } from '../../../functions/option.functions';
import { TitleError } from '../../shared/AntComponents/Typography/Title';
import { useApiContext } from '../../../store/ApiContext';
import { useErrorContext } from '../../../store/ErrorContext';

interface IProps {
    isOpen: boolean;
    closeEditor: (programId?: number, serviceId?: number) => void;
    assistanceProgramId: number;
    assistanceServiceId?: number;
};

const dialogContentStyle = {
    height: '580px',
    maxHeight: '95vh',
}

const yupAssistanceServiceSchema = yup.object({
    id: yup.number().notRequired(),
    assistanceServiceName: yup.string()
        .required('Assistance Service Name is required')
        .max(1024, 'Max length is 1024 characters'),
    assistanceType: yup.number()
        .required('Assistance Type is required'),
    description: yup.string()
        .required('Description is required')
        .max(1024, 'Max length is 1024 characters'),
    assistanceProgramId: yup.number()
        .required('Assistance Program Id is required'),
    assistanceId: yup.number().notRequired(),
    isExternallyUpdated: yup.boolean().notRequired(),
    serviceCategoryId: yup.number()
        .required('Service Category Id is required'),
    duplicateRules: yup.string()
        .max(100, 'Max length is 100 characters')
        .notRequired(),
    hideInWizard: yup.boolean().notRequired()
});

interface IAssistanceServiceYup extends yup.Asserts<typeof yupAssistanceServiceSchema> { }
const __formId = "frmAssistanceService";
const _keys: string[] = [assistanceServiceSaveUrl];
const _keysLike: string[] = [`${assistanceServiceBaseUrl}/Delete`];

const AssistanceServiceEditor: React.FC<IProps> = (props) => {
   const { assistanceProgramId, closeEditor, isOpen, assistanceServiceId } = props;
   const { httpGet, httpPost, httpDelete } = useApiContext();

   const { assistancePrograms } = useFetchAssistancePrograms(httpGet);
   const { assistanceServices } = useFetchAssistanceServices(httpGet);
   const { serviceCategories } = useServiceCategories(httpGet);
   const [isFormSaving, setIsFormSaving] = React.useState<boolean>(false);
   const { removeErrors } = useErrorContext();
   const clearErrors = () => {
      removeErrors({ keys: _keys, keysLike: _keysLike });
   }

   const {
      control,
      handleSubmit,
      formState: { errors },
      reset,
      setValue
   } = useForm<IAssistanceServiceYup>({
      resolver: yupResolver<yup.AnyObject>(yupAssistanceServiceSchema),
      shouldFocusError: true,
   });

   const assistanceServiceToEdit = React.useMemo(() => {
      return assistanceServices?.find(y => y.id === assistanceServiceId)
   }, [assistanceServiceId, assistanceServices]);

   const assistanceProgram = React.useMemo(() => {
      return assistancePrograms?.find(y => y.id === assistanceProgramId)
   }, [assistanceProgramId, assistancePrograms]);

   const serviceCategoryList = React.useMemo(() => {
      if (serviceCategories?.length > 0) {
         return serviceCategories.map((cat): IOptionItem => ({
            label: `[${cat.id}]: ${cat.serviceDescription}`,
            value: cat.id
         } as IOptionItem));
      }
      return [];
   }, [serviceCategories]);
  
   React.useEffect(() => {
      reset(assistanceServiceToEdit ?? {
         assistanceProgramId: assistanceProgramId,
         serviceCategoryId: null
      } as AssistanceService);

      setIsFormSaving(false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [assistanceServiceToEdit, assistanceProgramId, isOpen]);

  


   const onSubmit = (data: IAssistanceServiceYup): void => {
      //console.log('--------------AssistanceServiceEditor-------------------------')
      //console.log(errors)

      //ts hoops to convert data with unknown props into assistance programs
      const item = data as unknown as AssistanceService;
      setIsFormSaving(true);
      saveAssistanceService(httpPost, item)
         .then(() => {
            handleClose();
         })
         .catch(err => console.log(`Error Saving`, err))
         .finally(() => {
            setIsFormSaving(false);
         });
   }

   const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState<boolean>(false);

   const handleDeleteClick = () => {
      setIsDeleteDialogOpen(false);
      deleteAssistanceService(httpDelete, assistanceServiceToEdit.id)
         .then(handleClose)
         .catch(err => console.log(`Error Saving`, err));
   }

   const handleClose = () => {
      setIsFormSaving(false);
      setIsDeleteDialogOpen(false);
      clearErrors();
      closeEditor();
   }

   if (isFormSaving) {
      return <Spinner message='Saving Assistance Service...' />
   }

   //console.log('--------------AssistanceServcieEditor-------------------------')
   //console.log(errors)


   const html = (
      <Dialog
         scrollingContent={true}
         title={`Assistance Service Editor for ${assistanceProgram?.programName}`}
         open={isOpen}
         size="large"
         actionButtons={
            <>
               <CancelButton
                  onClick={() => handleClose()}
                  loading={isFormSaving}
               />
               <DeleteButton
                  onClick={() => setIsDeleteDialogOpen(true)}
                  disabled={!assistanceServiceToEdit || !assistanceServiceToEdit?.id || isDeleteDialogOpen}
                  loading={isFormSaving} />
               <SaveButton
                  formId={__formId}
                  onClick={() => null}
                  disabled={Object.keys(errors).length > 0}
                  loading={isFormSaving} />
            </>
         }>
         <div style={dialogContentStyle}>

            {errors && Object.keys(errors).length > 0 && <>
               <TitleError
                  text='Please correct the errors below' />
               {console.log('--------------Form Errors: -------------------------')}
               {console.log(errors)}
            </>
            }

            <ApiErrorDisplay
               title='Error saving Assistance Service'
               keys={_keys}
               keysLike={_keysLike}
            />

            <Form id={__formId} onFinish={handleSubmit(onSubmit)}>

               <InputField
                  control={control}
                  name='assistanceServiceName'
                  label='assistanceServiceName'
                  type='text'
                  error={errors?.assistanceServiceName}
                  required={true}
               />
               <RadioListField
                  control={control}
                  name='assistanceType'
                  label='assistanceType'
                  error={errors?.assistanceType}
                  options={assistanceTypeOptions()}
                  setValue={setValue}
                  required={true}
               />
               <TextAreaField
                  control={control}
                  name='description'
                  label='Description'
                  error={errors?.description}
                  required={true}
               />
               <InputField
                  control={control}
                  name='assistanceId'
                  label='assistanceId'
                  type='text'
                  error={errors?.assistanceId}
                  required={false}
               />
               <CheckboxField
                  control={control}
                  name='isExternallyUpdated'
                  label='isExternallyUpdated'
                  error={errors?.isExternallyUpdated}
                  toggle={true}
                  setValue={setValue}
                  required={false}
               />
               <DropdownField
                  control={control}
                  name='serviceCategoryId'
                  label='serviceCategoryId'
                  error={errors?.serviceCategoryId}
                  multiple={false}
                  options={serviceCategoryList}
                  setValue={setValue}
                  required={true}
               />
               <InputField
                  control={control}
                  name='duplicateRules'
                  label='duplicateRules'
                  type='text'
                  error={errors?.duplicateRules}
                  disabled={false}
               />
               <CheckboxField
                  control={control}
                  name='hideInWizard'
                  label='hideInWizard'
                  error={errors?.hideInWizard}
                  toggle={true}
                  setValue={setValue}
                  required={false}
               />
            </Form>
         </div>

         <Dialog
            title='CONFIRM DELETE'
            open={isDeleteDialogOpen}
            style={{ maxWidth: '550px' }}
            actionButtons={
               <>
                  <CancelButton onClick={() => setIsDeleteDialogOpen(false)} />
                  <DeleteButton
                     disabled={isFormSaving}
                     onClick={() => handleDeleteClick()} />
               </>
            }
         >
            {`Are you sure you want to Delete the Assistance Service (${assistanceServiceToEdit?.id}) ${assistanceServiceToEdit?.assistanceServiceName || ''}?`}
         </Dialog>

      </Dialog>
   )
   return html;
}

export default AssistanceServiceEditor;
