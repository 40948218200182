import { Col, Row, Space, Table } from 'antd';
import { ColumnsType, TableProps } from 'antd/lib/table';
import { TableRowSelection } from 'antd/lib/table/interface';
import * as React from 'react';
import Restricted from '../../../auth/Restricted';
import { boolComparer, dateComparer, numberComparer, stringComparer } from '../../../functions/comparer.functions';
import { formatBoolean, formatDateTimeString } from '../../../functions/format.functions';
import { BillingCodeViewModel } from '../../../store/drug/BillingCodeModel';
import { billingCodeBaseUrl, useBillingCodeViewModel } from '../../../store/drug/BillingCodeFetcher';
import { KnownSettings } from '../../../store/SettingsModel';
import HighlightSearchText from '../../HighlightSearchText';
import CustomIcon, { CustomIconType } from '../../shared/AntComponents/CustomIcon';
import SearchResultsTable from '../../shared/AntComponents/Table/SearchResultsTable';
import { BasicInputField } from '../../shared/BasicInputLibrary';
import { ActionButton, AddButton, EditButton } from '../../shared/Buttons';
import BillingCodeBulkEditor from './BillingCodeBulkEditor';
import BillingCodeEditor from './BillingCodeEditor';
import { useApiContext } from '../../../store/ApiContext';
import { HttpVerb, KeyWithVerb, useErrorContext } from '../../../store/ErrorContext';
import ApiErrorDisplay from '../../ApiErrorDisplay';

const _keysWithVerb: KeyWithVerb[] = [{ key: billingCodeBaseUrl, verb: HttpVerb.GET }]

const BillingCodeIndex: React.FC = () => {
   const { httpGet } = useApiContext();
   const { removeErrors } = useErrorContext();
   const { billingCodeViewModel, isLoading } = useBillingCodeViewModel(httpGet);
   const [selectedItem, setSelectedItem] = React.useState<BillingCodeViewModel>(undefined);
   const [isEditorOpen, setIsEditorOpen] = React.useState<boolean>(false);
   const [nameFilter, setNameFilter] = React.useState<string>('');
   const [currentPage, setCurrentPage] = React.useState(1);
   const [currentPageSize, setCurrentPageSize] = React.useState(25);
   const [bulkItems, setBulkItems] = React.useState<number[]>([]);
   const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);
   const [isBulkEditorOpen, setIsBulkEditorOpen] = React.useState<boolean>(false);

   React.useEffect(() => {
      removeErrors({ keysWithVerb: _keysWithVerb });
      // eslint-disable-next-line react-hooks/exhaustive-deps
   },[]) // remove Errors on initial render

   const filterBillingCode = (model: BillingCodeViewModel): boolean => {
      return model?.code?.toLowerCase().includes(nameFilter.toLowerCase())
         || model?.drugName?.toLowerCase().includes(nameFilter.toLowerCase())
         || model?.description.toLowerCase().includes(nameFilter.toLowerCase());
   }

   const filterGridContent = (records: BillingCodeViewModel[]): BillingCodeViewModel[] => {
      if (records && records?.length > 0) {
         return records.filter(y => filterBillingCode(y));
      }
      return [];
   }
   const filteredData = React.useMemo(() => {
      if (billingCodeViewModel?.length > 0) {
         return filterGridContent(billingCodeViewModel);
      }
      // for "filterGridContent" - we actually need the two in this dependency array
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [billingCodeViewModel, nameFilter]);

   const handleOnChange: TableProps<BillingCodeViewModel>['onChange'] = (pagination, filters, sorter, extra) => setCurrentPage(pagination?.current);
   const handleSearchFilterChange = (filter: string) => setNameFilter(filter);

   const handleCloseEditorClick = () => {
      setIsEditorOpen(false);
      setSelectedItem(undefined);
   }

   const handleCloseBulkEditorClick = () => {
      //remove all checkboxes on close
      setBulkItems([]);
      setSelectedRowKeys([]);
      setIsBulkEditorOpen(false);
   }

   const clickBulkEditButton = () => {
      setIsBulkEditorOpen(true);
   }

   const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
      const arr = newSelectedRowKeys.map((y) => { return Number(y) });
      setBulkItems(arr);
      setSelectedRowKeys(newSelectedRowKeys);
   };

   const rowSelection: TableRowSelection<object> = {
      selectedRowKeys,
      onChange: onSelectChange,
   };

   const onClearBulkSelection = () => {
      setBulkItems([]);
      setSelectedRowKeys([]);
   }

   const tableColumns: ColumnsType<BillingCodeViewModel> = [
      {
         title: 'Id',
         dataIndex: 'id',
         width: 80,
         sorter: (a, b) => numberComparer(a.id, b.id),
         sortDirections: ['ascend', 'descend', 'ascend'],
         defaultSortOrder: 'descend',
      },
      {
         title: 'Code',
         dataIndex: 'code',
         width: 80,
         sorter: (a, b) => stringComparer(a.code, b.code),
         sortDirections: ['ascend', 'descend', 'ascend'],
         render: (text, record) =>
            <HighlightSearchText searchString={nameFilter} targetString={record?.code} />
      },
      {
         title: 'Description',
         dataIndex: 'description',
         sorter: (a, b) => stringComparer(a.description, b.description),
         sortDirections: ['ascend', 'descend', 'ascend'],
         render: (text, record) =>
            <HighlightSearchText searchString={nameFilter} targetString={record?.description} />
      },
      {
         title: 'Inactive',
         dataIndex: 'inactive',
         width: 80,
         sorter: (a, b) => boolComparer(a.inactive, b.inactive),
         sortDirections: ['ascend', 'descend', 'ascend'],
         render: (text, record) => formatBoolean(record.inactive)
      },
      {
         title: 'Inactive Start Date',
         dataIndex: 'inactiveStartDate',
         sorter: (a, b) => dateComparer(a.inactiveStartDate, b.inactiveStartDate),
         sortDirections: ['ascend', 'descend', 'ascend'],
         render: (text, record) => formatDateTimeString(record.inactiveStartDate)
      },
      Table.SELECTION_COLUMN,
      {
         title: 'Drug Id',
         dataIndex: 'drugId',
         width: 80,
         sorter: (a, b) => numberComparer(a.drugId, b.drugId),
         sortDirections: ['ascend', 'descend', 'ascend'],
      },
      {
         title: 'Drug',
         dataIndex: 'drugName',
         sorter: (a, b) => stringComparer(a.drugName, b.drugName),
         sortDirections: ['ascend', 'descend', 'ascend'],
         render: (text, record) =>
            <HighlightSearchText searchString={nameFilter} targetString={record?.drugName} />
      },
      {
         title: () =>
            <Restricted requiredRoles={[KnownSettings.ContentAdmin]}>
               <AddButton
                  title='Add New Billing Code'
                  buttonText='New'
                  onClick={() => {
                     setSelectedItem(undefined);
                     setIsEditorOpen(true);
                  }} />
            </Restricted>,
         dataIndex: 'actions',
         width: 100,
         fixed: 'right',
         render: (text, record) => <Restricted requiredRoles={[KnownSettings.ContentAdmin]}>
            <EditButton
               onClick={() => {
                  setSelectedItem(record);
                  setIsEditorOpen(true);
               }} />
         </Restricted>
      },
   ];

   const html = (
      <>
         <ApiErrorDisplay
            title={'Error'}
            keysWithVerb={_keysWithVerb}
         />

         <SearchResultsTable
            rowkey={'id'}
            onChange={handleOnChange}
            columns={tableColumns}
            data={filteredData}
            currentPage={currentPage}
            currentPageSize={currentPageSize}
            setCurrentPageSize={setCurrentPageSize}
            titleText='Billing Code'
            fixedHeader={true}
            size={'small'}
            rowSelection={rowSelection}
            loading={!billingCodeViewModel || isLoading}
            footer={<Row justify="end">
               <Col>
                  <Restricted requiredRoles={[KnownSettings.ContentAdmin]}>
                     <Space>
                        <ActionButton
                           buttonText='Clear Bulk Selection'
                           onClick={onClearBulkSelection}
                           disabled={bulkItems.length < 2}
                           icon={<CustomIcon type={CustomIconType.CloseOutlined} />}
                        />
                        <ActionButton
                           buttonText='Edit Bulk'
                           disabled={bulkItems.length < 2}
                           onClick={() => clickBulkEditButton()}
                           icon={<CustomIcon type={CustomIconType.EditOutlined} />}
                        />
                     </Space>
                  </Restricted>
               </Col>
            </Row>}
            additionalComponents={[
               <BasicInputField
                  label='Search String'
                  placeholder='Search by Code or Description'
                  value={nameFilter}
                  onChange={(e) => handleSearchFilterChange(String(e))} />
            ]} />
         {isEditorOpen &&
            <BillingCodeEditor isOpen={isEditorOpen}
               closeEditor={() => handleCloseEditorClick()}
               id={selectedItem?.id} />
         }

         {isBulkEditorOpen &&
            <BillingCodeBulkEditor
               isOpen={isBulkEditorOpen}
               closeEditor={() => handleCloseBulkEditorClick()}
               bulkItems={bulkItems} />
         }
      </>
   )
   return html;
}
export default BillingCodeIndex;
