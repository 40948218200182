import * as React from 'react';
import CustomIcon, { CustomIconType } from '../CustomIcon';
import { colorWheel } from '../../../../Theme/ColorWheel';
import { Popover } from 'antd';
import { IFilteredInfo } from './FilteredInfo';
import { TooltipPlacement } from 'antd/lib/tooltip';

interface IProps {
   title: string;
   content?: React.ReactNode | (() => React.ReactNode);
   filteredInfo: IFilteredInfo;
   placement?: TooltipPlacement;
}

const ColumnFilter: React.FC<IProps> = (props) => {
   const { title, content, filteredInfo, placement } = props;
   const [ isFilterOpen, setIsFilterOpen] = React.useState<boolean>(false);

   /*
   //TODO: uncomment this to patch in open props so the fancy multiselectinput can have an 'ok' button
   //    initially didn't do it becuase it doesn't work for all filters...maybe it would be better to
   //    add the Ok button at this level instead ¯\_(ツ)_/¯
   //
   //const contentClone = React.isValidElement(content) ?
   //   React.cloneElement(content, {
   //      isOpenFilter: isFilterOpen,
   //      setIsOpenFilter: setIsFilterOpen
   //   } as {
   //      isOpenFilter?: boolean;
   //      setIsOpenFilter?: (isOpen: boolean) => void;
   //   }) :
   //   content;
   */

    return (
       <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>{title}</div>
          <div style={{ alignSelf: 'center'}}
             onClick={e => e.stopPropagation()}>
             <Popover placement={placement ?? 'bottom'}                
                trigger="click"
                open={isFilterOpen}
                onOpenChange={setIsFilterOpen}
                content={content /*contentClone*/}>
                <span className='ap-filter'>
                   <CustomIcon
                      style={{color: filteredInfo?.values?.length > 0 || filteredInfo?.value ? colorWheel.skyBlue : colorWheel.neutral3 }}
                      type={CustomIconType.FilterFilled} />
                </span>
             </Popover>
          </div>
       </div>
    );
}

export default ColumnFilter;
