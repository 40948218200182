import * as React from 'react';
import { Space, theme } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';

interface IProps {
   title?: string;
   style?: React.CSSProperties;
   color?: PillColor;
   onClose?: (e: React.MouseEvent<HTMLElement>) => void;
}

/*
   Created due to Antd Tag component's wonky hidden tag issue when using onCLick to remove 
   //see: https://github.com/ant-design/ant-design/issues/28768
   // e.preventDefault()
*/
export const Pill: React.FC<React.PropsWithChildren<IProps>> = (props) => {
   //debugger
   const { title, style, color, onClose, children } = props;
   const { token } = theme.useToken();

   const _title = title ? { title: title } : {};
   const _style = style ? { style: style } : {};

   let _backgroundColor: string;
   let _textColor: string;
   let _borderColor: string;
   let _iconColor: string;
   let _iconHoverColor: string;

   switch (color ?? 'default') {
      case 'success':
         {
            _backgroundColor = token.colorSuccessBg;
            _textColor = token.colorSuccess;
            _borderColor = token.colorSuccessBorder;
            _iconColor = token.colorSuccess;
            _iconHoverColor = token.colorSuccessHover;
            break;
         }
      default:
         {
            _backgroundColor = token.colorBgLayout;
            _textColor = token.colorTextLabel;
            _borderColor = token.colorBorder;
            _iconColor = token.colorIcon;
            _iconHoverColor = token.colorIconHover;
         }
   }

   const handleClose = (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault();
      e.stopPropagation();
      if (onClose) onClose(e);
   }
   let _closeIcon;
   if (onClose && !_closeIcon) {
      _closeIcon = <CloseCircleOutlined
         style={{
            color: _iconColor,
         }}
         onMouseEnter={(e) => {
            e.currentTarget.style.color = _iconHoverColor;
         }}
         onMouseLeave={(e) => {
            e.currentTarget.style.color = _iconColor;
         }}
         onClick={handleClose} />
   }

   return (<span style={{ margin: 2 }} onClick={(e) => e.stopPropagation()}>
      <span
         {..._style}
         {..._title}
         style={{
            color: _textColor,
            backgroundColor: _backgroundColor,
            border: `1px solid ${_borderColor}`,
            borderRadius: 4,
            padding: '2px 4px',
            fontSize: 'smaller'
         }}
      >
         <Space direction='horizontal'>
            {children}
            {onClose && _closeIcon}
         </Space>
      </span>
   </span>
   );
}

export type PillColor = 'default' | 'success';
