import { IOptionItem } from "../../functions/option.functions";

export interface IntrinsiqPayerToAP {
   id: number;
   practiceId?: number;
   payeeName: string;
   payeePCN: string;
   payeeBin: string;
   brandId?: number;
   assistanceProgramId?: number;
   programName: string;
   status: string;
   reviewedBy: string;
   reviewedOn: Date;
}

export interface IntrinsiqPayerToAPViewModel extends IntrinsiqPayerToAP {
   practiceName: string;
   brandName: string;
}

export interface IntrinsiqPayerToAPSearchFilter {
   page: number;
   pageSize: number;
   sortField: string;
   sortDirection: string;
   practiceId?: number;
   assistanceProgramId?: number;
   payeeName?: string;
   status?: string;
}

export interface IntrinsiqPayerToAPBulkMapProgramType {
   record: IntrinsiqPayerToAP;
   programTypes: number[];
   status?: string;
}

export const xrefStatusOptions: IOptionItem[] = [
   { key: 'New', value: 'New', label: 'New', } as IOptionItem
   , { key: 'Mapped', value: 'Mapped', label: 'Mapped', } as IOptionItem
   , { key: 'Duplicate', value: 'Duplicate', label: 'Duplicate', } as IOptionItem
   , { key: 'Not Mappable', value: 'Not Mappable', label: 'Not Mappable', } as IOptionItem
   , { key: 'Ignored', value: 'Ignored', label: 'Ignored', } as IOptionItem
];