import { ColumnsType, TableProps } from 'antd/lib/table';
import * as React from 'react';
import Restricted from '../../auth/Restricted';
import { dateComparer, numberComparer, stringComparer } from '../../functions/comparer.functions';
import { formatShortDateString } from '../../functions/format.functions';
import { Manufacturer } from '../../store/program/ManufacturerModel';
import {
   manufacturerBaseUrl, useFetchManufacturer, useManufacturerNameOptions
} from '../../store/program/ManufacturerFetcher';
import { KnownSettings } from '../../store/SettingsModel';
import HighlightSearchText from '../HighlightSearchText';
import SearchResultsTable from '../shared/AntComponents/Table/SearchResultsTable';
import { BasicDropdownField } from '../shared/BasicInputLibrary';
import { AddButton, EditButton } from '../shared/Buttons';
import ManufacturerEditor from './ManufacturerEditor';
import { useApiContext } from '../../store/ApiContext';
import { HttpVerb, KeyWithVerb, useErrorContext } from '../../store/ErrorContext';
import ApiErrorDisplay from '../ApiErrorDisplay';


const _keysWithVerb: KeyWithVerb[] = [{ key: manufacturerBaseUrl, verb: HttpVerb.GET }]

const ManufacturerList: React.FC = () => {
   const { httpGet } = useApiContext();
   const { removeErrors } = useErrorContext();
   const { manufacturers } = useFetchManufacturer(httpGet);
   const { manufacturerOptions } = useManufacturerNameOptions(httpGet);
   const [currentPage, setCurrentPage] = React.useState(1);
   const [currentPageSize, setCurrentPageSize] = React.useState(25);
   const handleOnChange: TableProps<Manufacturer>['onChange'] = (pagination, filters, sorter, extra) => setCurrentPage(pagination?.current);

   const [nameFilter, setNameFilter] = React.useState<string>('');
   const [selectedItem, setSelectedItem] = React.useState<Manufacturer>(undefined);
   const [isEditorOpen, setIsEditorOpen] = React.useState<boolean>(false);

   React.useEffect(() => {
      removeErrors({ keysWithVerb: _keysWithVerb });
      // for "removeErrors"
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []) // remove Errors on initial render

   const filterName = (m: Manufacturer): boolean => {
      return !nameFilter || m.manufacturerName?.toLowerCase().indexOf(nameFilter.toLowerCase()) > -1 ||
         !nameFilter || m.managedTenantId?.toLowerCase().indexOf(nameFilter.toLowerCase()) > -1
         ? true : false;
   }

   const filterGridContent = (records: Manufacturer[]): Manufacturer[] => {
      if (records && records.length > 0) {
         return records.filter(y => filterName(y));
      }
      return [];
   }

   const filteredData = React.useMemo(() => {
      if (manufacturers?.length > 0) {
         return filterGridContent(manufacturers);
      }
      // for "filterGridContent" - we actually need the two in this dependency array
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [manufacturers, nameFilter])

   const handleCloseEditorClick = () => {
      setIsEditorOpen(false);
      setSelectedItem(undefined);
   }

   const tableColumns: ColumnsType<Manufacturer> = [
      {
         title: 'Id',
         dataIndex: 'id',
         key: 1,
         sorter: (a, b) => numberComparer(a.id, b.id),
         sortDirections: ['ascend', 'descend'],
         defaultSortOrder: 'descend'
      },
      {
         title: 'Manufacturer Name',
         dataIndex: 'manufacturerName',
         key: 2,
         sorter: (a, b) => stringComparer(a.manufacturerName, b.manufacturerName),
         sortDirections: ['ascend', 'descend'],
         render: (text, record) => <HighlightSearchText searchString={nameFilter} targetString={record.manufacturerName} />
      },
      {
         title: 'Managed Tenant Id',
         dataIndex: 'managedTenantId',
         key: 3,
         sorter: (a, b) => stringComparer(a.managedTenantId, b.managedTenantId),
         sortDirections: ['ascend', 'descend'],
         render: (text, record) => <HighlightSearchText searchString={nameFilter} targetString={record.managedTenantId} />
      },
      {
         title: 'Created By',
         dataIndex: 'createdBy',
         key: 4,
         sorter: (a, b) => stringComparer(a.createdBy, b.createdBy),
         sortDirections: ['ascend', 'descend']
      },
      {
         title: 'Created On',
         dataIndex: 'createdOn',
         key: 5,
         sorter: (a, b) => dateComparer(a.createdOn, b.createdOn),
         sortDirections: ['ascend', 'descend'],
         render: (text, record) => formatShortDateString(record.createdOn)
      },
      {
         title: 'Modified By',
         dataIndex: 'modifiedBy',
         key: 6,
         sorter: (a, b) => stringComparer(a.modifiedBy, b.modifiedBy),
         sortDirections: ['ascend', 'descend']
      },
      {
         title: 'Modified On',
         dataIndex: 'modifiedOn',
         key: 7,
         sorter: (a, b) => dateComparer(a.modifiedOn, b.modifiedOn),
         sortDirections: ['ascend', 'descend'],
         render: (text, record) => formatShortDateString(record.modifiedOn)
      },
      {
         title: () =>
            <Restricted requiredRoles={[KnownSettings.ContentAdmin]}>
               <AddButton
                  title='Add New Manufacturer.'
                  buttonText='New'
                  onClick={() => {
                     setSelectedItem(undefined);
                     setIsEditorOpen(true)
                  }} />
            </Restricted>,
         dataIndex: '',
         fixed: 'right',
         key: 8,
         width: 100,
         render: (text, record) => <Restricted requiredRoles={[KnownSettings.ContentAdmin]}>
            <EditButton
               title='Edit'
               onClick={() => {
                  setIsEditorOpen(true);
                  setSelectedItem(record);
               }} />
         </Restricted>
      }
   ];

   const html = (
      <>
         <ApiErrorDisplay
            title={'Error'}
            keysWithVerb={_keysWithVerb}
         />

         <SearchResultsTable
            rowkey={'id'}
            size='small'
            onChange={handleOnChange}
            columns={tableColumns}
            data={filteredData}
            currentPage={currentPage}
            currentPageSize={currentPageSize}
            setCurrentPageSize={setCurrentPageSize}
            titleText='Manufacturer'
            fixedHeader={true}
            additionalComponents={[
               <BasicDropdownField
                  label='Manufacturer:'
                  placeholder='Filter by Manufacturer'
                  clearable={true}
                  onChange={(e) => setNameFilter(e as string)}
                  name='foundationFilter'
                  search={true}
                  options={manufacturerOptions} />,
            ]}
         />

         {isEditorOpen &&
            <ManufacturerEditor
               isOpen={isEditorOpen}
               closeEditor={handleCloseEditorClick}
               id={selectedItem?.id} />
         }
      </>
   );

   return html;
}

export default ManufacturerList;