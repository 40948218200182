import { Breadcrumb, Spin, Typography } from "antd";
import * as React from 'react';
import { Link, useNavigate } from "react-router-dom";
import { WorkspaceParam, View_Favorite, View_Search, View_Browse_Certified, View_Browse_Uncertified } from "../../store/report/ReportFunctions";
import { colorWheel } from '../../Theme/ColorWheel';
import CustomIcon, { CustomIconType } from '../shared/AntComponents/CustomIcon';
import { ActionButton } from '../shared/Buttons';
import FavoriteMenu from './Menu/FavoriteMenu';
import ReportMenuContainer from "./Menu/ReportMenuContainer";
import SearchMenu from './Menu/SearchMenu';
import { useReports } from './ReportContext';
import BrowseHeadings from "./Menu/BrowseHeadings";

const { Title } = Typography;

export const ReportNav: React.FC = () => {
   const {
      view,
      workspaceKey,
      reportKey,
      currentReport,
      menuIsCollapsed,
      setMenuIsCollapsed,
      reportRendered,
      powerBIReportId } = useReports();

   const headerTitle: string = currentReport ? `${currentReport.reportPageGroup}: ${currentReport.reportName}` : '';
   const navWitdh: string = menuIsCollapsed ? '34px' : '300px';

   const isBrowseView: boolean = view === View_Browse_Certified || view === View_Browse_Uncertified;

   const headerText = (): string => {
      let viewDisplay = undefined;
      switch (view) {
         case View_Browse_Certified:
            viewDisplay = 'Certified';
            break;

         case View_Browse_Uncertified:
            viewDisplay = 'Uncertified';
            break;

         case View_Search:
            viewDisplay = 'Search';
            break;

         case View_Favorite:
            viewDisplay = 'Favorites';
            break;
      }
      return `Analytics${viewDisplay ? (': ' + viewDisplay) : ''}`;
   }

   const navigate = useNavigate();

   const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, url: string) => {
      navigate(url);
      //in a breadcrumb, this will prevent navigation
      //e.preventDefault();
   }

   const html = (
      <Spin
         spinning={!reportRendered && !isNaN(powerBIReportId)}
         tip={menuIsCollapsed ? undefined : 'Reports loading...'}
      >
         <div className='skinny-scroll' style={{
            paddingTop: 10, margin: 0, height: 'calc(100vh - 50px)',
            width: navWitdh, display: 'flex', flexDirection: 'column',
            maxWidth: '250px', borderRight: '1px solid lightgray',
            backgroundColor: colorWheel.white
         }}>
            <div title={headerTitle}
               style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'baseline',
                  height: 40,
                  paddingLeft: 5
               }}>
               <ActionButton
                  title={menuIsCollapsed ? 'Expand' : 'Collapse'}
                  style={{
                     borderStyle: 'none'
                  }}
                  icon={<CustomIcon type={menuIsCollapsed ? CustomIconType.MenuUnfoldOutlined : CustomIconType.MenuFoldOutlined}
                     style={{
                        fontWeight: 'bold',
                        fontSize: '1.5em',
                        color: colorWheel.skyBlue,
                     }}
                  />}
                  onClick={() => setMenuIsCollapsed(!menuIsCollapsed)}
               />
               {!menuIsCollapsed &&

                  <Typography.Text ellipsis={true} style={{ maxWidth: 230 }} >
                     <Title level={5} style={{ marginLeft: 15, color: colorWheel.textGrey }}>{headerText()}</Title>
                  </Typography.Text>
               }
            </div>

            <div style={{ paddingLeft: 5, paddingBottom: 10 }}>
               {!menuIsCollapsed && workspaceKey && reportKey && currentReport && isBrowseView &&
                  <Breadcrumb
                     style={{ paddingTop: 5 }}
                     separator='>'
                     items={[
                        {
                           title: reportRendered ? <Link
                              to=''
                              onClick={(e) => handleClick(e, `/report/${view}?${WorkspaceParam}=${encodeURI(workspaceKey)}`)}
                           >
                              {currentReport.workspaceName}
                           </Link> : currentReport.workspaceName,
                        },
                        {
                           title: currentReport.reportName
                        },
                     ]}
                  />
               }
            </div>
            <ReportMenuContainer>
               {view === View_Search &&
                  <SearchMenu />
               }
               {view === View_Favorite &&
                  <FavoriteMenu />
               }
               {(view === View_Browse_Certified || view === View_Browse_Uncertified) &&
                  <BrowseHeadings />
               }
            </ReportMenuContainer>  

         </div>
       </Spin>
   );

   return html;
}
