import * as React from 'react';
import { Form, Row, Col, Divider } from 'antd';
import { BasicInputField, BasicDatePickerField, BasicCheckboxField } from '../../shared/BasicInputLibrary';
import Dialog from '../../Dialog';
import { SaveButton, CancelButton } from '../../shared/Buttons';
import ApiErrorDisplay from '../../ApiErrorDisplay';
import { Space } from 'antd';
import { BillingCodeViewModel } from '../../../store/drug/BillingCodeModel';
import { useBillingCodeViewModel, saveBillingCode, billingCodeBaseUrl } from '../../../store/drug/BillingCodeFetcher';
import { useApiContext } from '../../../store/ApiContext';
import { useErrorContext } from '../../../store/ErrorContext';


interface IProps {
   isOpen: boolean;
   closeEditor: () => void;
   bulkItems: number[];
};

const dialogContentStyle = {
   height: '580px',
   maxHeight: '95vh',
}

const __formId = "frmBillingCodeBulkEditor";
const _keysLike: string[] = [billingCodeBaseUrl];

const BillingCodeBulkEditor: React.FC<IProps> = (props) => {
   const { bulkItems, closeEditor, isOpen } = props;
   const { httpGet, httpPost } = useApiContext();
   const [itemsInactive, setItemsInactive] = React.useState<boolean>(false);
   const [itemsInactiveDate, setItemsInactiveDate] = React.useState<Date>(undefined);
   const [itemsToEdit, setItemsToEdit] = React.useState<BillingCodeViewModel[]>([]);
   const [isFormSaving, setIsFormSaving] = React.useState<boolean>(false);
   const [changedToggle, setChangedToggle] = React.useState<boolean>(false);
   const { removeErrors } = useErrorContext();
   const { billingCodeViewModel, isLoading, error } = useBillingCodeViewModel(httpGet);

   React.useEffect(() => {

   }, [changedToggle]);

   React.useEffect(() => {
      if (!isLoading && !error) {
         const viewModelsToEdit = billingCodeViewModel?.filter(vm => props.bulkItems.includes(vm.id));
         setItemsToEdit(viewModelsToEdit);
         setIsFormSaving(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [bulkItems, isOpen, billingCodeViewModel, isLoading, error]);

   const handleClose = () => {
      setItemsToEdit([]);
      setItemsInactive(false);
      setItemsInactiveDate(undefined);
      setIsFormSaving(false);
      removeErrors({ keysLike: _keysLike });
      closeEditor();
   }

   const handleSubmit = async () => {
      setIsFormSaving(true);

      if (itemsInactive || itemsInactiveDate) {
         itemsToEdit.forEach(item => {
            // For the time being, we only want to use these inputs to set things as "inactive", never to clear them. 
            if (itemsInactive) item.inactive = itemsInactive;
            if (itemsInactiveDate) item.inactiveStartDate = itemsInactiveDate;
         })
      }
      await saveBillingCode(httpPost, itemsToEdit)
         .then(handleClose)
         .catch(err => console.error('Error Saving Billing Code', err))
         .finally(() => {
            setIsFormSaving(false);
         })
      
   }

   const updateObjProp = (obj: any, prop: string, value: any) => {
      obj[prop] = value;
      setChangedToggle(!changedToggle);
   }

   const html = (
      <Dialog
         scrollingContent={true}
         title={'Billing Code Bulk Update'}
         open={props.isOpen}
         size='large'
         actionButtons={
            <Space>
               <CancelButton onClick={() => {
                  handleClose();
               }} />
               <SaveButton
                  onClick={() => handleSubmit()}
                  loading={isFormSaving} />
            </Space>
         }
      >
         <div style={dialogContentStyle}>

            <ApiErrorDisplay
               title='Error saving BillingCode'
               keysLike={_keysLike}
            />

            <Form id={__formId}>
               <Row gutter={[16, 16]}>
                  <Col span={2}>
                     <BasicCheckboxField
                        label='Inactive'
                        name='inactive'
                        checked={itemsInactive}
                        value={itemsInactive}
                        onChange={(e) => setItemsInactive(Boolean(e))}
                     />
                  </Col>
                  <Col span={6}>
                     <BasicDatePickerField
                        label='Inactive Start Date'
                        name='inactive start date'
                        value={itemsInactiveDate}
                        onChangeDate={(e) => setItemsInactiveDate(e)}
                     />
                  </Col>
               </Row>
               <Divider />
               {itemsToEdit?.map((item, index) => {
                  return <div key={item.id}>
                     <Row gutter={[16, 16]} key={item.id}>
                        <Col span={2}>
                           <BasicInputField
                              label='Code'
                              name='code'
                              type='text'
                              value={item.code}
                              disabled={true} />
                        </Col>
                        <Col span={2}>
                           <BasicInputField
                              label='Drug Id'
                              name='drugId'
                              type='text'
                              value={item.drugId}
                              disabled={true} />
                        </Col>
                        <Col span={20}>
                           <BasicInputField
                              label='Description'
                              name='description'
                              type='text'
                              value={item.description}
                              onChange={(e) => { updateObjProp(item, 'description', e) }}
                           />
                        </Col>
                     </Row>
                     <Divider />
                  </div>
               }
               )}
            </Form>
         </div>
      </Dialog>
   );

   return html;
}

export default BillingCodeBulkEditor;