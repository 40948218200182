import * as React from 'react';
import { Link } from 'react-router-dom';
import * as SubMenuStore from '../../store/menu/SubMenu';

interface IProps {
   flyoutMenuItem: SubMenuStore.ISubmenuCategoryItem
   handleClick: () => void;
}

const FlyoutMenuItem: React.FC<IProps> = (props) => (
   <div className="flyout-menu-item">
      <Link to={props.flyoutMenuItem.route}
         onClick={() => props.handleClick()}
         title={props.flyoutMenuItem.label}>
         <div className="flyout-menu-label">
            {props.flyoutMenuItem.label}
         </div>
      </Link>
   </div>
);

export default FlyoutMenuItem;
