import * as React from 'react';
import { PowerBIReport } from '../../../store/report/ReportModel';
import { useReports } from '../ReportContext';
import ReportMenuCollapsed from './ReportMenuCollapsed';
import ReportMenuItem from './ReportMenuItem';

interface IReportCollectionMenu {
   reports: PowerBIReport[];
   searchText?: string;
   isCollapsed?: boolean;
   showBreadcrumb?: boolean;
   showCertifiedAttribute?: boolean;
}

const ReportCollectionMenu: React.FC<IReportCollectionMenu> = (props) => {
   const { reports, searchText, isCollapsed, showBreadcrumb, showCertifiedAttribute } = props;
   const { powerBIReportId, favoritedReportIds } = useReports();

   const html = (
      <div style={{
         padding: '0px' // Need to specify the padding to override ant's styling
      }}> 
         {reports?.length > 0 && !isCollapsed && reports.map((report: PowerBIReport) => {
            const isFavorite = favoritedReportIds?.includes(report.id);
            return <ReportMenuItem
                  key={report.id}
                  showBreadcrumb={showBreadcrumb}
                  searchText={searchText}
                  menuItem={report}
                  isFavorite={isFavorite}
                  isSelected={report.id === powerBIReportId}
                  showCertifiedAttribute={showCertifiedAttribute}
               />
         })}
         {isCollapsed &&
            <ReportMenuCollapsed reports={reports} />
         }
      </div>
   );

   return html;
}

export default ReportCollectionMenu;