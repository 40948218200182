import { Col, Divider, Row, Space, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { TableRowSelection } from 'antd/lib/table/interface';
import * as React from 'react';
import Restricted from '../../../auth/Restricted';
import { boolComparer, numberComparer, stringComparer } from '../../../functions/comparer.functions';
import { formatBoolean } from '../../../functions/format.functions';
import { useApiContext } from '../../../store/ApiContext';
import { KnownSettings } from '../../../store/SettingsModel';
import { useFetchAssistanceServices } from '../../../store/program/AssistanceServiceFetcher';
import { saveFoundationDefaults, useFoundationDefaultsViewModel } from '../../../store/program/AssistanceServiceFoundationDefaultsFetcher';
import { AssistanceServiceFoundationDefaults, AssistanceServiceFoundationDefaultsViewModel } from '../../../store/program/AssistanceServiceFoundationDefaultsModel';
import { AssistanceService, AssistanceServiceAggregateFoundationDefaults } from '../../../store/program/AssistanceServiceModel';
import { useFetchFundingStatusViewModel } from '../../../store/program/FundingStatusFetcher';
import Dialog from '../../Dialog';
import { useCohortContext } from '../../cohort/CohortContext';
import CustomIcon, { CustomIconType } from '../../shared/AntComponents/CustomIcon';
import MinimalistTable from '../../shared/AntComponents/Table/MinimalistTable';
import { NumberedTitle } from '../../shared/AntComponents/Typography/Title';
import { ActionButton, AddButton, CancelButton, EditButton } from '../../shared/Buttons';
import AssistanceServiceFoundationDefaultsEditor from './AssistanceServiceFoundationDefaultsEditor';
import AssistanceServiceSelectionDialog from './AssistanceServiceSelectionDialog';

interface IProps {
   assistanceServiceId: number;
   isOpen: boolean;
   handlClose: () => void;
}

const AssistanceServiceToFoundationDefaultsIndex: React.FC<IProps> = (props: IProps) => {
   const { httpGet, httpPost } = useApiContext();
   const [selectedAssistanceService, setSelectedAssistanceService] = React.useState<AssistanceService>(undefined);
   const [selectedFoundationDefault, setSelectedFoundationDefault] = React.useState<AssistanceServiceFoundationDefaultsViewModel>(undefined);
   const [isEditorOpen, setIsEditorOpen] = React.useState<boolean>(false);
   const [shouldFoundationDiseaseTypeBeDisabled, setShouldFoundationDiseaseTypeBeDisabled] = React.useState<boolean>(false);
   const [shouldUseProvidedAssistanceServiceInEditor, setShouldUseProvidedAssistanceServiceInEditor] = React.useState(false);
   const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);
   const [bulkItems, setBulkItems] = React.useState<number[]>([]);
   const [isAssistanceServiceSelectionDialogOpen, setIsAssistanceServiceSelectionDialogOpen] = React.useState<boolean>(false);
   const [aggregateFoundationDefaults, setAggregateFoundationDefaults] = React.useState<AssistanceServiceAggregateFoundationDefaults[]>(undefined);
   const [foundationDefaultsViewModels, setFoundationDefaultsViewModels] = React.useState<AssistanceServiceFoundationDefaultsViewModel[]>(undefined);
   const { assistanceServices } = useFetchAssistanceServices(httpGet);
   const { foundationDefaultsViewModelList } = useFoundationDefaultsViewModel(httpGet);
   const { fundingStatusViewModels } = useFetchFundingStatusViewModel(httpGet);
   const { cohortSummaryFromDate } = useCohortContext();

   React.useEffect(() => {
      let assistanceService: AssistanceService;
      if (assistanceServices?.length > 0) {
         assistanceService = assistanceServices.find(as => as.id === props?.assistanceServiceId);
         setSelectedAssistanceService(assistanceService);
      }

      const buildModels = () => {
         if (assistanceServices?.length > 0 && fundingStatusViewModels?.length > 0 && foundationDefaultsViewModelList?.length > 0) {
            const assistanceService = assistanceServices.find(s => s.id === props?.assistanceServiceId);
            const models: AssistanceServiceAggregateFoundationDefaults[] = [];
            const fundingStatusAssociations = fundingStatusViewModels.filter(vm => vm.assistanceServiceId === props.assistanceServiceId);
            const foundationDiseaseTypeIds = fundingStatusAssociations.map(fsa => fsa.foundationDiseaseTypeId);

            foundationDiseaseTypeIds.forEach(fdtid => {
               if (!models.some(m => m.foundationDiseaseTypeId === fdtid)) {
                  let fundingStatusCount = fundingStatusAssociations.filter(fsa => fsa.foundationDiseaseTypeId === fdtid)?.length;

                  const newModel =
                     {
                        ...assistanceService,
                        foundationDiseaseTypeName: fundingStatusAssociations.find(fsa => fsa.foundationDiseaseTypeId === fdtid)?.diseaseTypeName,
                        foundationDiseaseTypeId: fdtid,
                        fundingStatusCount: fundingStatusCount,
                        hasRelatedFoundationDefaults: foundationDefaultsViewModelList.some(def => def.foundationDiseaseTypeId === fdtid && def.assistanceServiceId === props?.assistanceServiceId),
                     } as AssistanceServiceAggregateFoundationDefaults;
                  models.push(newModel);
               }
            });
            setAggregateFoundationDefaults(models);
         }
      };
      buildModels();

      if (foundationDefaultsViewModelList?.length > 0 && assistanceService) {
         setFoundationDefaultsViewModels(foundationDefaultsViewModelList.filter(def => def.assistanceServiceId === assistanceService.id));
      }
   }, [props.assistanceServiceId, assistanceServices, fundingStatusViewModels, foundationDefaultsViewModelList]);

   const closeEditor = () => {
      setSelectedFoundationDefault(undefined);
      setIsEditorOpen(false);
      setShouldUseProvidedAssistanceServiceInEditor(false);
   }

   const closeThisModal = () => {
      setAggregateFoundationDefaults(undefined);
      setFoundationDefaultsViewModels(undefined);
      setSelectedAssistanceService(undefined);
      props.handlClose();
   }

   const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
      const arr = newSelectedRowKeys.map((k) => { return Number(k) });
      setBulkItems(arr);
      setSelectedRowKeys(newSelectedRowKeys);
   }

   const rowSelection: TableRowSelection<object> = {
      selectedRowKeys,
      onChange: onSelectChange,
   };

   const onClearBulkSelection = () => {
      setBulkItems([]);
      setSelectedRowKeys([]);
   };

   const clickBulkEditButton = () => {
      setIsAssistanceServiceSelectionDialogOpen(true);
   }

   const closeAssistanceServiceSelectionDialog = () => {
      setIsAssistanceServiceSelectionDialogOpen(false);
   }

   const saveAssistanceServiceSelection = (assistanceServiceId: number) => {
      const modelsToSave: AssistanceServiceFoundationDefaults[] = foundationDefaultsViewModels.filter(y => bulkItems.includes(y.id));
      modelsToSave.forEach(model => { model.assistanceServiceId = assistanceServiceId; })
      saveFoundationDefaults(httpPost, modelsToSave, cohortSummaryFromDate);

      closeAssistanceServiceSelectionDialog();
      onClearBulkSelection(); // The selected items should drop off the grid since they've been moved to another Service so we should ensure to clear the selection of them
   }

   const aggregateFoundationDefaultColumns: ColumnsType<AssistanceServiceAggregateFoundationDefaults> = [
      {
         title: 'Foundation Disease Type Name',
         dataIndex: 'foundationDiseaseTypeName',
         key: 'foundationDiseaseTypeName',
         sortDirections: ['ascend', 'descend'],
         sorter: (a, b) => stringComparer(a.foundationDiseaseTypeName, b.foundationDiseaseTypeName),
         defaultSortOrder: 'ascend'
      },
      {
         title: 'Id',
         dataIndex: 'foundationDiseaseTypeId',
         key: 'foundationDiseaseTypeId',
         sortDirections: ['ascend', 'descend'],
         sorter: (a, b) => numberComparer(a.foundationDiseaseTypeId, b.foundationDiseaseTypeId)
      },
      {
         title: 'Funding Status Count',
         dataIndex: 'fundingStatusCount',
         key: 'fundingStatusCount',
         sortDirections: ['ascend', 'descend'],
         sorter: (a, b) => numberComparer(a.fundingStatusCount, b.fundingStatusCount)
      },
      {
         title: 'Has Foundation Defaults',
         dataIndex: 'hasRelatedFoundationDefaults',
         key: 'hasRelatedFoundationDefaults',
         sortDirections: ['ascend', 'descend'],
         sorter: (a, b) => boolComparer(a.hasRelatedFoundationDefaults, b.hasRelatedFoundationDefaults),
         render: (text, record) => formatBoolean(record?.hasRelatedFoundationDefaults)
      }
   ];

   const foundationDefaultsColumns: ColumnsType<AssistanceServiceFoundationDefaultsViewModel> = [
      {
         title: 'Id',
         dataIndex: 'id',
         key: 'id',
         sortDirections: ['ascend', 'descend'],
         sorter: (a, b) => numberComparer(a.id, b.id),
         defaultSortOrder: 'ascend'
      },
      {
         title: 'Foundation Name',
         dataIndex: 'foundationName',
         key: 'foundationName',
         sortDirections: ['ascend', 'descend'],
         sorter: (a, b) => stringComparer(a.foundationName, b.foundationName)
      },
      {
         title: 'FDTID',
         dataIndex: 'foundationDiseaseTypeId',
         key: 'foundationDiseaseTypeId',
         sortDirections: ['ascend', 'descend'],
         sorter: (a, b) => numberComparer(a.foundationDiseaseTypeId, b.foundationDiseaseTypeId)
      },
      {
         title: 'Foundation DiseaseTypeName',
         dataIndex: 'diseaseTypeName',
         key: 'diseaseTypeName',
         sortDirections: ['ascend', 'descend'],
         sorter: (a, b) => stringComparer(a.diseaseTypeName, b.diseaseTypeName)
      },
      {
         title: <Restricted requiredRoles={[KnownSettings.ContentAdmin]}>
            <AddButton buttonText='New' onClick={() => {
               setShouldUseProvidedAssistanceServiceInEditor(true);
               setSelectedFoundationDefault(undefined);
               setShouldFoundationDiseaseTypeBeDisabled(false);
               setIsEditorOpen(true)
            }} />
         </Restricted>,
         render: (text, record) =>
            <Restricted requiredRoles={[KnownSettings.ContentAdmin]}>
               <EditButton onClick={() => {
                  setSelectedFoundationDefault(record);
                  setShouldFoundationDiseaseTypeBeDisabled(true);
                  setIsEditorOpen(true);
               }} />
            </Restricted>
      },
      Table.SELECTION_COLUMN
   ];


   const html = (<Dialog
      scrollingContent={true}
      title={'Assistance Service Foundation Defaults'}
      open={props.isOpen}
      size='large'
      actionButtons={
         <CancelButton onClick={() => { closeThisModal(); }} />
      }>
      <NumberedTitle
         text={`Assistance Service Foundation Defaults Information for Service: ${selectedAssistanceService?.assistanceServiceName}`}
         level={5} />

      <MinimalistTable
         rowKey={'foundationDiseaseTypeId'}
         size={'small'}
         columns={aggregateFoundationDefaultColumns}
         data={aggregateFoundationDefaults}
         showHeader={true}
      />
      <Divider />
      <MinimalistTable
         rowKey={'id'}
         size={'small'}
         columns={foundationDefaultsColumns}
         data={foundationDefaultsViewModels}
         showHeader={true}
         footer={<Row justify="end">
            <Col>
               <Space>
                  <Restricted requiredRoles={[KnownSettings.ContentAdmin]}>
                     <ActionButton
                        buttonText='Clear Bulk Selection'
                        onClick={onClearBulkSelection}
                        disabled={bulkItems.length < 1}
                        icon={<CustomIcon type={CustomIconType.CloseOutlined} />}
                     />
                     <ActionButton
                        buttonText='Modify Assistance Service(s)'
                        disabled={bulkItems.length < 1}
                        onClick={() => clickBulkEditButton()}
                        icon={<CustomIcon type={CustomIconType.EditOutlined} />}
                     />
                  </Restricted>
               </Space>
            </Col>
         </Row>}
         rowSelection={rowSelection}
      />

      {isAssistanceServiceSelectionDialogOpen &&
         <AssistanceServiceSelectionDialog
            isOpen={isAssistanceServiceSelectionDialogOpen}
            saveAssistanceServiceLink={saveAssistanceServiceSelection}
            cancelAssistanceServiceLink={closeAssistanceServiceSelectionDialog}
         />
      }

      {isEditorOpen &&
         <AssistanceServiceFoundationDefaultsEditor
            closeEditor={closeEditor}
            isOpen={isEditorOpen}
            shouldFoundationDiseaseTypeBeDisabled={shouldFoundationDiseaseTypeBeDisabled}
            shouldAssistanceServiceBeDisabled={true}
            assistanceService={selectedAssistanceService}
            id={selectedFoundationDefault?.id}
            shouldUseProvidedAssistanceService={shouldUseProvidedAssistanceServiceInEditor}
         />
      }
   </Dialog>
   );
   return html;
}

export default AssistanceServiceToFoundationDefaultsIndex;