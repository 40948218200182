import * as React from 'react';
import { useApiContext } from '../../store/ApiContext';
import { useErrorContext } from '../../store/ErrorContext';
import {
   cohortDeleteUrl,
   cohortPublishUrl,
   cohortRefreshUrl,
   deleteCohort, publishCohort, refreshCohort
} from '../../store/cohort/CohortFetcher';
import { EditorAction } from '../../store/cohort/CohortModel';
import { CohortSummary } from '../../store/cohort/CohortSummaryModel';
import ApiErrorDisplay from '../ApiErrorDisplay';
import Dialog from '../Dialog';
import { CancelButton, DeleteButton, SaveButton } from '../shared/Buttons';
import { useCohortContext } from './CohortContext';

interface IProps {
   cohortSummary?: CohortSummary;
   isOpen: boolean;
   closeEditor: () => void;
   editorAction: EditorAction;
}

type ErrorKeys = { keys: string[] }; // doing this to avoid importing from ErrorContext
const errorKeys: ErrorKeys = { keys: [] };

const CohortSummaryCommand: React.FC<IProps> = (props) => {
   const { cohortSummary, isOpen, closeEditor, editorAction } = props;
   const { httpPost, httpDelete } = useApiContext();
   const { cohortSummaryFromDate } = useCohortContext();
   const [isFormSaving, setIsFormSaving] = React.useState<boolean>();
   const { removeErrors } = useErrorContext();

   const clearErrors = () => {
      removeErrors(errorKeys);
   };

   // add dynamic URLs to keys
   errorKeys.keys.push(cohortDeleteUrl(cohortSummary.cohortId));
   errorKeys.keys.push(cohortRefreshUrl(cohortSummary.cohortId));
   errorKeys.keys.push(cohortPublishUrl(cohortSummary.cohortId));

   const handleClick = async () => {
      setIsFormSaving(true);
      try {
         if (editorAction === EditorAction.Delete) {
            await deleteCohort(httpDelete, cohortSummary.cohortId, cohortSummaryFromDate);
         } else if (editorAction === EditorAction.Publish) {
            await publishCohort(httpPost, cohortSummary.cohortId, cohortSummaryFromDate);
         } else if (editorAction === EditorAction.Refresh) {
            await refreshCohort(httpPost, cohortSummary.cohortId, cohortSummaryFromDate);
         } else {
            console.warn(`Unsupported editor action [${editorAction}]`);
         }

         clearErrors();
         handleClose();
      } catch (e: any) {
         console.error(`${e?.message.substring(0, 41)}...`);
      }
      finally {
         setIsFormSaving(false);
      };
   }

   const handleClose = () => {
      setIsFormSaving(false);
      closeEditor();
   }

   let question: string = `Unknown Action: ${editorAction}`;

   if (editorAction === EditorAction.Delete) {
      question = `You are about to DELETE cohort  ${cohortSummary.name}.  Are you sure?`;
   } else if (editorAction === EditorAction.Publish) {
      question = `You are about to PUBLISH cohort ${cohortSummary.name}.  Are you sure?`;
   } else if (editorAction === EditorAction.Refresh) {
      question = `You are about to REFRESH cohort ${cohortSummary.name}.  Are you sure?`;
   }

   const body: JSX.Element = (
      <Dialog
         scrollingContent={true}
         title={`${EditorAction[editorAction]} Cohort`}
         open={isOpen}
         size="small"
         actionButtons={
            <>
               <CancelButton
                  onClick={() => {
                     clearErrors();
                     setIsFormSaving(false);
                     closeEditor();
                  }} />
               {editorAction !== EditorAction.Delete &&
                  <SaveButton
                     onClick={() => handleClick()}
                     disabled={isFormSaving}
                     loading={isFormSaving} />
               }
               {editorAction === EditorAction.Delete &&
                  <DeleteButton
                     onClick={() => handleClick()}
                     disabled={isFormSaving}
                     loading={isFormSaving} />
               }
            </>
         }>

         <ApiErrorDisplay
            title='Error'
            keysLike={errorKeys.keys} />

         <div>{question}</div>

      </Dialog>
   );

   return (body);
}

export default CohortSummaryCommand;