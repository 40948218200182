import { yupResolver } from '@hookform/resolvers/yup';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { Form, Row, Col } from 'antd';
import * as yup from 'yup';
import { Brand, BrandViewModel } from '../../../store/drug/BrandModel';
import { saveBrand, deleteBrand, brandSaveUrl, brandDeleteUrl } from '../../../store/drug/BrandFetcher';
import ApiErrorDisplay from '../../ApiErrorDisplay';
import Dialog from '../../Dialog';
import { CancelButton, DeleteButton, SaveButton } from '../../shared/Buttons';
import { InputField } from '../../shared/InputLibrary';
import Spinner from '../../Spinner';
import { TitleError } from '../../shared/AntComponents/Typography/Title';
import { useApiContext } from '../../../store/ApiContext';
import { useErrorContext } from '../../../store/ErrorContext';

interface IProps {
    isOpen: boolean;
    closeEditor: () => void;
   brand?: BrandViewModel;
};

const dialogContentStyle = {
    height: '580px',
    maxHeight: '95vh',
}

const yupBrandSchema = yup.object({
    id: yup.number().notRequired(),
    brandName: yup.string()
        .required('Brand Name is required')
        .max(256, 'Max length is 256 characters'),
    logoUrl: yup.string()
        .url()
        .max(256, 'Max length is 256 characters')
        .notRequired(),
});

interface IBrandYup extends yup.Asserts<typeof yupBrandSchema> { }
const __formId = "frmBrand";

const BrandEditor: React.FC<IProps> = (props) => {
   const { isOpen, closeEditor, brand } = props;
   const _keys: string[] = brand ? [brandSaveUrl, brandDeleteUrl(brand.id)] : [brandSaveUrl];

   const { httpDelete, httpPost } = useApiContext();
   const { removeErrors } = useErrorContext();

   const [isFormSaving, setIsFormSaving] = React.useState<boolean>(false);

   const {
      control,
      handleSubmit,
      formState: { errors },
      reset,
      getValues
   } = useForm<IBrandYup>({
      resolver: yupResolver<yup.AnyObject>(yupBrandSchema),
      shouldFocusError: true,
   });

   React.useEffect(() => {
      let resetVal = {};
      if (brand) {
         resetVal = brand;
      }
      reset(resetVal);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [brand, isOpen]);

   const onSubmit = async (data: IBrandYup) => {
      //console.log('--------------Editor-------------------------')
      //console.log(errors)

      //ts hoops to convert data with unknown props into assistance programs
      const item = data as unknown as Brand;
      setIsFormSaving(true);
      await saveBrand(httpPost, item)
         .then(handleClose)
         .catch(err => console.error('Error Saving Brand', err))
         .finally(() => {
            setIsFormSaving(false);
         });      
   }

   const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState<boolean>(false);

   const handleDeleteClick = async () => {
      await deleteBrand(httpDelete, getValues()?.id)
         .then(handleClose)
         .catch(err => console.error('Error Deleting Brand', err))
   }

   const handleClose = () => {
      setIsFormSaving(false);
      setIsDeleteDialogOpen(false);
      removeErrors({ keys: _keys });
      closeEditor();
   }

   if (isFormSaving) return <Spinner message='Saving Brand...' />

   //console.log('--------------AssistanceServcieEditor-------------------------')
   //console.log(errors)


   const html = (
      <Dialog
         scrollingContent={true}
         title={'Brand Editor'}
         open={props.isOpen}
         size="large"
         actionButtons={
            <>
               <CancelButton onClick={handleClose} />
               <DeleteButton
                  onClick={() => setIsDeleteDialogOpen(true)}
                  disabled={!getValues()?.id || isDeleteDialogOpen}
                  loading={isDeleteDialogOpen} />
               <SaveButton
                  formId={__formId}
                  onClick={() => null}
                  disabled={Object.keys(errors).length > 0}
                  loading={isFormSaving} />
            </>
         }>
         <div style={dialogContentStyle}>

            {errors && Object.keys(errors).length > 0 && <>
               <TitleError
                  text='Please correct the errors below' />
               {console.log('--------------Form Errors: -------------------------')}
               {console.log(errors)}
            </>
            }

            <ApiErrorDisplay
               title='Error saving Brand'
               keys={_keys}
            />

            <Row>
               <Col span={24}>
                  <Form id={__formId} onFinish={handleSubmit(onSubmit)}>
                     <Form.Item>
                        <InputField
                           control={control}
                           name='brandName'
                           label='Brand Name'
                           type='text'
                           error={errors?.brandName}
                           required={true}
                        />
                     </Form.Item>
                     <Form.Item>
                        <InputField
                           control={control}
                           name='logoUrl'
                           label='Logo Url'
                           type='text'
                           error={errors?.logoUrl}
                           required={false}
                        />
                     </Form.Item>
                  </Form>
               </Col>
            </Row>
         </div>

         <Dialog
            title='CONFIRM DELETE'
            open={isDeleteDialogOpen}
            style={{ maxWidth: '550px' }}
            actionButtons={
               <>
                  <CancelButton onClick={() => setIsDeleteDialogOpen(false)} />
                  <DeleteButton
                     disabled={isFormSaving}
                     onClick={() => handleDeleteClick()} />
               </>
            }
         >
            {`Are you sure you want to Delete the brand (${getValues()?.id}) ${getValues()?.brandName || ''}?`}
         </Dialog>

      </Dialog>
   )
   return html;
}

export default BrandEditor;
