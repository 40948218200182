import { InsightlyOrganization } from './InsightlyOrganizationModel';

export interface Practice {
   id?: number;
   externalTenantId: string;
   managedTenantId: string;
   name: string;
   hasFeed: boolean;
   isRegistered: boolean;
   address1: string;
   address2: string;
   city: string;
   state: string;
   zip: string;
   phone: string;
   phoneExt: string;
   fax: string;
   npi: string;
   taxId: string;
   baaVersion?: number;
   website: string;
   lastLogin: Date;
   createdByUpn: string;
   created: Date;
   modifiedByUserProfileId?: number;
   modified: Date;
   practiceAccessType: number;
   isSecurityEnabled: boolean;
   eHRName: string;
   eHRFax: string;
   oralPharmacyName: string;
   ePEnabled: boolean;
   deactivated: boolean;
   deactivatedDate?: Date;
   numberOfMDs?: number;
   numberOfProviders?: number;
   numberOfExtenders?: number;
   oralPharmacyNCPDP: string;
   oralPharmacyNABP: string;
   oralPharmacyNPI: string;
   oralPharmacyTaxId: string;
   eMRSystem: string;
   practiceManagementSystem: string;
   pharmacyManagementSystem: string;
   fTEProviderCount?: number;
   specialties: number[];
   allowAutoProvision: boolean;
}

export interface PracticeViewModel extends Practice {
   insightlyOrganization: InsightlyOrganization;
}

export function formatPracticeName(practiceName: string, practiceId: number): string  {
   return `(${practiceId}) ${practiceName}`;
}