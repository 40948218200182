import { IOptionItem } from "../../functions/option.functions";

export interface PracticeServiceToApXRef {
   id: number;
   practiceId?: number;
   insurancePlan: string;
   assistanceProgramId?: number;
   programName: string;
   clientTag: string;
   programType?: number;
   status: string;
   insuranceGroup: string;
   notes: string;
   createdBy: string;
   createdOn: Date;
   modifiedBy: string;
   modifiedOn: Date;
}

export interface PracticeServiceToApXRefBulkEditorViewModel {
   sourceRecords: PracticeServiceToApXRef[];
   additionalProgramIds: number[];
   status: string;
}

export interface PracticeServiceToApXRefViewModel extends PracticeServiceToApXRef {
   practiceName: string;
}

export interface IAdditionalProgram {
   key: number;
   assistanceProgramId?: number;
   status: string;
   error?: string;
}

export interface PracticeServiceToApXRefSearchFilter {
   page: number;
   pageSize: number;
   sortField: string;
   sortDirection: string;
   insurancePlanLike?: string;
   practiceIds?: number[];
   assistanceProgramIds?: number[];
   statuses?: string[];
}

export interface PracticeServiceToApXRefBulkMapProgramType {
   record: PracticeServiceToApXRef;
   programTypes: number[];
   status?: string;
}
export const MAPPED_STATUS = 'Mapped';
export const NEW_STATUS = 'New';

export const xrefStatusOptions: IOptionItem[] = [
   { key: NEW_STATUS, value: NEW_STATUS, label: NEW_STATUS, } as IOptionItem
   , { key: 'Deleted', value: 'Deleted', label: 'Deleted', } as IOptionItem
   , { key: MAPPED_STATUS, value: MAPPED_STATUS, label: MAPPED_STATUS, } as IOptionItem
   , { key: 'Not Required', value: 'Not Required', label: 'Not Required', } as IOptionItem
];


