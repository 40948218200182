import * as React from 'react';
import { FieldTypes, IFormError } from '../../../functions/form.contracts';
import {
   BasicInputField
   , BasicTextAreaField
   , BasicDropdownField
   , BasicRadioListField
   , BasicYesInput
} from '../../shared/BasicInputLibrary';
import { IOptionItem, yesNo } from '../../../functions/option.functions';
import { CohortItem } from '../../../store/cohort/CohortItemModel';
import { Alert } from 'antd';

interface Props {
   dataName: string;
   cohortItem: CohortItem;
   onInputChange: (val: any) => void;
   onInputBlur: () => void;
   errors: IFormError[];
   isValid: boolean;
   inputIndex: number;
}

//component to manage creating the correct component based on the supplied question.FieldType.
const FormInputSelector: React.FC<Props> = (props) => {
   const {
      cohortItem,
      errors,
      onInputChange,
      onInputBlur,
      inputIndex,
      dataName, isValid
   } = props;
   const label = cohortItem.isRequired ? cohortItem.labelText : `${cohortItem.labelText} (optional)`;
   const [formError, setFormError] = React.useState<IFormError>();
   React.useEffect(() => {
      const _formError = errors.find(error => error.DataName === cohortItem.cohortFieldId);
      setFormError(_formError);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [errors]);

   return (
      (() => {

         //NOTE: need to add all the types as defined in the FormFieldTypes table 
         if (cohortItem.fieldType === FieldTypes.string ||
            cohortItem.fieldType === FieldTypes.url ||
            cohortItem.fieldType === FieldTypes.integer ||
            cohortItem.fieldType === FieldTypes.currency ||
            cohortItem.fieldType === FieldTypes.currencydollars
         ) {
            return (
               <BasicInputField
                  onChange={onInputChange}
                  onBlur={onInputBlur}
                  name={dataName}
                  required={cohortItem.isRequired}
                  maxLength={cohortItem.maxLength}
                  placeholder={cohortItem.placeHolderText}
                  value={cohortItem.value}
                  error={formError?.Message}
                  label={label}
                  autoFocus={inputIndex === 0}
                  style={{width: '300px'}}
               />
            )
         } else if (cohortItem.fieldType === FieldTypes.textarea) {
            return (
               <BasicTextAreaField
                  onChange={onInputChange}
                  onBlur={onInputBlur}
                  name={dataName}
                  required={cohortItem.isRequired}
                  maxLength={cohortItem.maxLength}
                  placeholder={cohortItem.placeHolderText}
                  value={cohortItem.value}
                  error={formError?.Message}
                  label={label}
               />
            )

         } else if (cohortItem.fieldType === FieldTypes.yesinput) {
            return (
               <BasicYesInput
                  onChange={onInputChange}
                  onBlur={onInputBlur}
                  name={dataName}
                  required={cohortItem.isRequired}
                  value={cohortItem.value}
                  error={formError?.Message}
                  options={yesNo}
                  label={label}
               />
            )
         } else if (cohortItem.fieldType === FieldTypes.yesno) {
            return (
               <BasicRadioListField
                  onChange={onInputChange}
                  onBlur={onInputBlur}
                  name={dataName}
                  required={cohortItem.isRequired}
                  value={cohortItem.value}
                  error={formError?.Message}
                  options={yesNo}
                  label={label}
               />
            )
         } else if (cohortItem.fieldType === FieldTypes.dropdownlist ||
            cohortItem.fieldType === FieldTypes.dropdownlistfromtable) {

            return (
               <BasicDropdownField
                  data-keything='asdf'
                  onChange={onInputChange}
                  onBlur={onInputBlur}
                  name={dataName}
                  required={cohortItem.isRequired}
                  clearable={true}
                  placeholder={cohortItem.placeHolderText}
                  value={cohortItem.value}
                  error={formError?.Message}
                  options={cohortItem?.fieldOptions.map(option => ({
                     label: option.name,
                     value: option.value
                  } as IOptionItem))}
                  label={label}
               />
            )
         } else {
            return (
               <Alert
                  type="error"
                  message={`Not Implemented Type: ${cohortItem.fieldType}`}
               />
            )
         }
      })()
   );
}

export default FormInputSelector;
