import { useMemo } from 'react';
import useSWR, { mutate } from 'swr';
import { numberComparer, stringComparer } from '../../functions/comparer.functions';
import { HttpError, TDelete, TGet, TPost } from '../../functions/httpClient';
import { IOptionItem } from '../../functions/option.functions';
import { IStatus } from '../models';
import { FoundationDiseaseType, FoundationDiseaseTypeGroup, FoundationDiseaseTypeToSpecialty } from './FoundationDiseaseTypeModel';
import { useFetchFoundations } from './FoundationFetcher';
import { fundingStatusBaseUrl } from './FundingStatusFetcher';
import { FundingStatus } from './FundingStatusModel';

export const baseFoundationDiseaseTypeUrl = `api/program/FoundationDiseaseType`;
const saveUrl = `${baseFoundationDiseaseTypeUrl}/SaveFoundationDiseaseTypeAndFundingStatuses`;
const deleteUrl = (id: number) => `${baseFoundationDiseaseTypeUrl}/${id}`;
const getFoundationDiseaseTypeToSpecialty = (id: number) => `${baseFoundationDiseaseTypeUrl}/Specialties/${id}`;
export const getFoundationDiseaseTypesUrl = `${baseFoundationDiseaseTypeUrl}/GetFoundationDiseaseTypes`;
const getFoundationDiseaseTypeGroupsUrl = `${baseFoundationDiseaseTypeUrl}/GetFoundationDiseaseTypeGroups`;
const getFoundationDiseaseTypeByFoundationDiseaseTypeIdUrl = (id: number) => `${baseFoundationDiseaseTypeUrl}/GetFoundationDiseaseTypeByFoundationDiseaseTypeId/${id}`;

const swrOptions = {
   refreshInterval: 0,
   revalidateOnFocus: false,
   dedupingInterval: 60000     //default 2000
};

export const saveNewFoundationDiseaseType = async (httpPost: TPost,
   fundingStatuses: FundingStatus[],
   foundationId: number,
   foundationDiseaseTypeName: string,
   diseaseTypeIds: number[],
   foundationDiseaseTypeToSpecialties: FoundationDiseaseTypeToSpecialty[],
   foundationDiseaseTypeId?: number): Promise<FoundationDiseaseType> => {

   const result = await httpPost<FoundationDiseaseType>(saveUrl, {
      id: foundationDiseaseTypeId,
      foundationDiseaseTypeName,
      diseaseTypeIds,
      foundationId,
      fundingStatuses,
      foundationDiseaseTypeToSpecialties: foundationDiseaseTypeToSpecialties
   })
      .then((response) => {
         mutate(baseFoundationDiseaseTypeUrl);
         mutate(getFoundationDiseaseTypeToSpecialty(foundationDiseaseTypeId));

         mutate(getFoundationDiseaseTypesUrl);
         mutate(getFoundationDiseaseTypeGroupsUrl);
         mutate(fundingStatusBaseUrl);
         return response;
      });
   return result;
}

export const deleteFoundationDiseaseType = async (httpDelete: TDelete, id: number) => {
   await httpDelete(deleteUrl(id))
      .then(() => {
         mutate(baseFoundationDiseaseTypeUrl)
         mutate(getFoundationDiseaseTypesUrl);
         mutate(getFoundationDiseaseTypeGroupsUrl);
         mutate(fundingStatusBaseUrl);
      });
}

export const useFetchFoundationDiseaseTypes = (httpGet: TGet): IStatus & { foundationDiseaseTypes: FoundationDiseaseType[] } => {
   const { data, isLoading, error } = useSWR<FoundationDiseaseType[], HttpError>(
      getFoundationDiseaseTypesUrl,
      httpGet,
      { ...swrOptions }
   );

   return {
      foundationDiseaseTypes: data,
      isLoading: isLoading,
      error: error?.message
   }
}

export const useRequestFoundationDiseaseTypeByFoundationDiseaseTypeId = (httpGet: TGet, id: number): IStatus & { FoundationDiseaseTypeGroups: FoundationDiseaseTypeGroup[] } => {
   const { data, isLoading, error } = useSWR<FoundationDiseaseTypeGroup[], HttpError>(
      getFoundationDiseaseTypeByFoundationDiseaseTypeIdUrl(id),
      httpGet,
      { ...swrOptions }
   );

   return {
      FoundationDiseaseTypeGroups: data,
      isLoading: isLoading,
      error: error?.message
   }
}

export const useRequestFoundationDiseaseTypeToSpecialty = (httpGet: TGet, id: number): IStatus & { SpecialitiesList: FoundationDiseaseTypeToSpecialty[] } => {
   const shouldFetch = id ? true : false;
   const { data, isLoading, error } = useSWR<FoundationDiseaseTypeToSpecialty[], HttpError>(
      shouldFetch ? getFoundationDiseaseTypeToSpecialty(id) : null,
      httpGet,
      { ...swrOptions }
   );

   return {
      SpecialitiesList: data,
      isLoading: isLoading,
      error: error?.message
   }
}

export function useFoundationDiseaseTypeOptions(httpGet: TGet): { foundationDiseaseTypeOptions: IOptionItem[] } {
   const { foundationDiseaseTypes } = useFetchFoundationDiseaseTypes(httpGet);

   const foundationDiseaseTypeOptions = useMemo(() => {
      if (foundationDiseaseTypes?.length > 0) {

         const sorted = [...foundationDiseaseTypes]

         sorted.sort((a: FoundationDiseaseType, b: FoundationDiseaseType) => numberComparer(Number(a.id), Number(b.id)));
         sorted.sort((a: FoundationDiseaseType, b: FoundationDiseaseType) => stringComparer(String(a.diseaseTypeName), String(b.diseaseTypeName)));

         const foundationDiseaseTypeOptions = sorted.map((diseaseType): IOptionItem => ({
            key: diseaseType.id,
            label: `[${diseaseType.id}] ${diseaseType.diseaseTypeName}`,
            value: diseaseType.id
         } as IOptionItem));
         return foundationDiseaseTypeOptions;
      }
      return [];
   }, [foundationDiseaseTypes]);

   return {
      foundationDiseaseTypeOptions
   };
}


export const buildFoundationDiseaseTypesOptions = (foundationDiseaseTypes: FoundationDiseaseType[]): IOptionItem[] => {
   if (foundationDiseaseTypes?.length > 0) {
      const sorted = [...foundationDiseaseTypes];

      const tempOptions = sorted.map((opt): IOptionItem => ({
         key: opt.id,
         label: `[${opt.id}] ${opt.diseaseTypeName}`,
         value: opt.id
      } as IOptionItem));

      return tempOptions;
   }
   return [];
}

export function useFoundationDiseaseTypesOptions(httpGet: TGet): { foundationDiseaseTypesOptions: IOptionItem[] } {
   const { foundationDiseaseTypes } = useFetchFoundationDiseaseTypes(httpGet);

   const options = useMemo(() => {
      if (foundationDiseaseTypes?.length > 0) {
         const options = buildFoundationDiseaseTypesOptions(foundationDiseaseTypes);
         return options;
      }
      return [];
   }, [foundationDiseaseTypes]);

   return {
      foundationDiseaseTypesOptions: options
   };
}

export function useFoundationDiseaseTypesWithFoundationOptions(httpGet: TGet): { foundationDiseaseTypesWithFoundationOptions: IOptionItem[] } {
   const { foundationDiseaseTypes } = useFetchFoundationDiseaseTypes(httpGet);
   const { foundations } = useFetchFoundations(httpGet);

   const options = useMemo(() => {
      if (foundationDiseaseTypes?.length > 0 && foundations?.length > 0) {
         const options = foundationDiseaseTypes.map((fdt): IOptionItem => {
            const f = foundations.find(y => y.id === fdt.foundationId);
            return {
               label: `${fdt.diseaseTypeName} (${fdt.id}) ${f.foundationName}`,
               value: fdt.id
            } as IOptionItem
         });
         return options;
      }
      return [];
   }, [foundationDiseaseTypes, foundations]);


   return {
      foundationDiseaseTypesWithFoundationOptions: options
   };
}

export const useFoundationDiseaseTypesByFoundationId = (httpGet: TGet, foundationId: number): IStatus & { foundationDiseaseTypes: FoundationDiseaseType[] } => {
   const { foundationDiseaseTypes, isLoading, error } = useFetchFoundationDiseaseTypes(httpGet);

   return {
      isLoading,
      error,
      // eslint-disable-next-line no-restricted-globals
      foundationDiseaseTypes: foundationDiseaseTypes?.filter((f: FoundationDiseaseType) => f.foundationId === foundationId)
   }
};

export const useFoundationDiseaseTypeGroupsById = (httpGet: TGet, id: number): IStatus & { foundationDiseaseTypeGroups: FoundationDiseaseTypeGroup[] } => {
   const { foundationDiseaseTypeGroups, isLoading, error } = useFetchFoundationDiseaseTypeGroups(httpGet);

   return {
      isLoading,
      error,
      // eslint-disable-next-line no-restricted-globals
      foundationDiseaseTypeGroups: foundationDiseaseTypeGroups?.filter((f: FoundationDiseaseTypeGroup) => f.foundationDiseaseTypeId === id) ?? undefined,
   }
};

export function useFetchFoundationDiseaseTypeGroups(httpGet: TGet): IStatus & { foundationDiseaseTypeGroups: FoundationDiseaseTypeGroup[] } {
   const { data, isLoading, error } = useSWR<FoundationDiseaseTypeGroup[]>(
      getFoundationDiseaseTypeGroupsUrl,
      httpGet,
      { ...swrOptions }
   );

   return {
      foundationDiseaseTypeGroups: data,
      isLoading: isLoading,
      error: error?.message
   };
}

export const foundationDiseaseTypeExists = (
   foundationId: number,
   foundationDiseaseTypeName: string,
   foundationDiseaseTypes: FoundationDiseaseType[]): boolean => {

   if (foundationDiseaseTypes?.length <= 0) return undefined;

   const matchedDiseaseType: FoundationDiseaseType = foundationDiseaseTypes?.find((f: FoundationDiseaseType) => {
      return (f.foundationId === foundationId && f.diseaseTypeName.toLocaleLowerCase() === foundationDiseaseTypeName.toLocaleLowerCase()) ?? undefined;
   });
   return (matchedDiseaseType === undefined) ? false : true;
}

export const useFoundationDiseaseTypesById = (httpGet: TGet, foundationDiseaseTypeId: number): IStatus & { diseaseTypeIds: number[] } & FoundationDiseaseType => {
   const { foundationDiseaseTypes, isLoading: dtIsLoading, error } = useFetchFoundationDiseaseTypes(httpGet);
   const { foundationDiseaseTypeGroups, isLoading: gIsLoading } = useFoundationDiseaseTypeGroupsById(httpGet, foundationDiseaseTypeId);
   const selectedFoundationDiseaseType = foundationDiseaseTypes?.find((f: FoundationDiseaseType) => f.id === foundationDiseaseTypeId);
   const diseaseTypeIds: number[] = [];

   //if (foundationDiseaseTypeId === undefined || foundationDiseaseTypes?.length <= 0 || gIsLoading === true)
   //   return undefined;
   if (selectedFoundationDiseaseType?.diseaseTypeId)
      diseaseTypeIds.push(selectedFoundationDiseaseType.diseaseTypeId);
   else if (foundationDiseaseTypeGroups?.length > 0)
      diseaseTypeIds.push(...foundationDiseaseTypeGroups.map((e) => e.diseaseTypeId));

   const { diseaseTypeId, ...rest } = (selectedFoundationDiseaseType ?? { diseaseTypeId: undefined } as FoundationDiseaseType);
   return {
      isLoading: gIsLoading || dtIsLoading,
      error,
      // eslint-disable-next-line no-restricted-globals
      ...rest,
      diseaseTypeIds,
      diseaseTypeId: undefined
   }
};
