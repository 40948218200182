import { useMemo } from 'react';
import { numberComparer, stringComparer } from '../../functions/comparer.functions';
import { HttpError, TPost, TDelete, TGet } from '../../functions/httpClient';
import { IOptionItem } from '../../functions/option.functions';
import { IStatus } from '../models';
import useSWR, { mutate } from 'swr';
import { DiseaseToDiagnosisMap, DiseaseTypeToFoundation } from './DiseaseTypeModel';

export const diseaseTypeBaseUrl = `api/program/DiseaseType`;
const diseaseTypeGetUrl = `${diseaseTypeBaseUrl}/GetWithDiagnoses`;
export const diseaseTypeGetWithFoundationsUrl = `${diseaseTypeBaseUrl}/GetWithFoundation`;
export const diseaseTypeSaveUrl = `${diseaseTypeBaseUrl}/SaveDiseaseTypeWithDiagnoses`;
export const diseaseTypeDeleteUrl = (id: number) => `${diseaseTypeBaseUrl}/Delete/${id}`;

const swrOptions = {
   refreshInterval: 0,
   revalidateOnFocus: false,
   dedupingInterval: 60000
};


export function useFetchDiseaseTypes(httpGet: TGet): IStatus & { diseaseTypes: DiseaseToDiagnosisMap[] } {
   const { data, isValidating, error } = useSWR<DiseaseToDiagnosisMap[], HttpError>(
      diseaseTypeGetUrl,
      httpGet,
      { ...swrOptions }
   );

   return {
      diseaseTypes: data,
      isLoading: !error && !data && isValidating,
      error: error?.message
   };
}

export function useFetchDiseaseTypeToFoundationList(httpGet: TGet): IStatus & { diseaseTypeToFoundations: DiseaseTypeToFoundation[] } {
   const { data, isValidating, error } = useSWR<DiseaseTypeToFoundation[], HttpError>(
      diseaseTypeGetWithFoundationsUrl,
      httpGet,
      { ...swrOptions }
   );

   return {
      diseaseTypeToFoundations: data,
      isLoading: !error && !data && isValidating,
      error: error?.message
   };
}

export const saveDiseaseType = async (httpPost: TPost, diseaseType: DiseaseToDiagnosisMap) => {
   const result = await httpPost(diseaseTypeSaveUrl, diseaseType)
      .then(() => mutate(diseaseTypeGetUrl));
   return result;
}

export const deleteDiseaseType = async (httpDelete: TDelete, id: number) => {
   const result = await httpDelete(diseaseTypeDeleteUrl(id))
      .then(() => mutate(diseaseTypeGetUrl));
   return result;
}

export function useDiseaseTypeOptions(httpGet: TGet): { diseaseTypeOptions: IOptionItem[] } {
   const { diseaseTypes } = useFetchDiseaseTypes(httpGet);

   const diseaseTypeOptions = useMemo(() => {
      if (diseaseTypes?.length > 0) {
         const sorted = [...diseaseTypes];

         sorted.sort((a: DiseaseToDiagnosisMap, b: DiseaseToDiagnosisMap) => numberComparer(Number(a.diseaseTypeId), Number(b.diseaseTypeId)));
         sorted.sort((a: DiseaseToDiagnosisMap, b: DiseaseToDiagnosisMap) => stringComparer(String(a.diseaseTypeName), String(b.diseaseTypeName)));

         const options = sorted.map((diseaseType): IOptionItem => ({
            key: diseaseType.diseaseTypeId,
            label: `[${diseaseType.diseaseTypeId}] ${diseaseType.diseaseTypeName}`,
            value: diseaseType.diseaseTypeId
         } as IOptionItem));

         return options
      }
      return [];
   }, [diseaseTypes]);

   return {
      diseaseTypeOptions
   };
}