import { Space, Upload as UploadAnt, UploadFile } from 'antd';
import colorWheel from '../../../Theme/ColorWheel';
import CustomIcon, { CustomIconType } from './CustomIcon';
import { Form } from 'antd';
import * as React from 'react';
import {
   Controller, Control, FieldValue, FieldValues, FieldError
   , DeepMap, DeepPartial
} from 'react-hook-form';
import { ErrorDisplay } from '../ErrorDisplay';
import { RcFile } from 'antd/lib/upload';
import { UploadChangeParam } from 'antd/es/upload';
import { BasicErrorDisplay } from '../BasicInputLibrary';

const { Dragger } = UploadAnt;


// Yea, we export it just to feed it back in as maximumFileSizeMB...
// By passing it in though, we make it easier to allow different .config / values for different implementations
export const MAX_UPLOAD_FILE_SIZE_MB = 25;

const styles = {
   txtStyle: {
      whitespace: 'pre-wrap',
      fontSize: 10,
      margin: '0 16px -8px 16px',
      fontStyle: 'italic',
   },
   dragger: {
      backgroundColor: colorWheel.white,
      width: 230,
      
   }
}

interface IBasicFileUploadDraggerProps {
   fileName: string;
   maximumFileSizeMB: number;
   accept: string; // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#accept
   icon: CustomIconType;

   errors?: string[];
   onChange?: (file: File) => void; // custom file handling
   customRequest?: any; // used to preventDefaultRequest when we want the upload to occur based on an outside interaction e.g. "Save" button on a dialog
   beforeUpload?: (file: File, isFileValid: boolean, error?: string) => void;
   containerWidth?: number;
}

const getExtensionFromFile = (mimeType: string) => {
   switch (mimeType) {
      case 'text/csv': return '.csv';
      case 'application/msword': return '.doc';
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': return '.docx';
      case 'application/pdf': return '.pdf';
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': return '.xlsx';
      case 'application/vnd.ms-excel': return '.xls';
      case 'image/jpeg': return '.jpg';
      case 'image/jpx': return '.jpg';
      case 'image/jpg': return '.jpg';
      case 'image/tiff': return '.jpg';
      case 'image/gif': return '.jpg';
      case 'image/png': return '.jpg';
      case 'image/bmp': return '.jpg';
      case 'image/jfif': return '.jpg';
      default: return 'upload';
   }
}


export const BasicFileUploadDragger: React.FC<IBasicFileUploadDraggerProps> = props => {
   const { onChange, fileName, accept, errors, customRequest, beforeUpload, maximumFileSizeMB, icon, containerWidth } = props;

   const fileSizeError = 'The File was too large';
   const fileTypeError = 'The File type was invalid';

   //const hasFileSizeErrors: boolean = errors && errors.includes(fileSizeError);
   //const hasFileTypeErrors: boolean = errors && errors.includes(fileTypeError);
   const fileHasNoErrors: boolean = !errors;

   return <div>
      <Dragger style={{
         backgroundColor: colorWheel.white,
         width: containerWidth ?? 230,
         borderColor: fileHasNoErrors ? '' : colorWheel.red
      }}
         name='fileUploadDragger'
         data-draggableupload='fileUploadDragger'
         multiple={false}
         showUploadList={false}
         onChange={(e: UploadChangeParam<UploadFile<File>>) => {
            onChange && onChange(e.file.originFileObj);
         }}
         customRequest={customRequest}
         accept={null} // Dragger expects a URL to upload the file to (here), but we place it in a form - so this stops an error from occurring
         beforeUpload={(e: UploadFile<RcFile>) => {
            const betterFile = e as unknown as File;
            const fileSizeResult = betterFile.size <= (maximumFileSizeMB * 1048576);
            const fileExtensionResult = accept
               .replaceAll(' ', '') // accept can be in the form '.pdf, .png' OR '.pdf,.png', etc. so 
               .split(',')
               .includes(getExtensionFromFile(betterFile.type));

            const error =
               !fileSizeResult ? fileSizeError :
                  !fileExtensionResult ? fileTypeError :
                     null;

            const validationResult = fileSizeResult && fileExtensionResult;
            beforeUpload && beforeUpload(betterFile, validationResult, error);
            return validationResult;
         }}
      >

         <Space direction='vertical' style={{overflowWrap: 'break-word'} }>
            <div>
               <span style={{ color: colorWheel.graniteGrey, fontSize: 16 }}>Drag  File  or  </span><span style={{ color: colorWheel.steelBlue, fontSize: 16 }} >Choose</span>
            </div>

            {/* Ideas that we may revisit later... */}
            {/*{hasFileSizeErrors && <div style={{ fontSize: 11, color: colorWheel.red }}>{fileSizeError}</div>}*/}
            {/*{hasFileTypeErrors && <div style={{ fontSize: 11, color: colorWheel.red }}>{fileTypeError}</div>}*/}

            {/* When being used purely as a basic component, we may be fed all sorts of unexpected errors (expected referring to size/type)
             * so we handle them here.  When being used as a form component, those errors are handled by the <Form.Item> <ErrorDisplay /> </Form.Item> */}
            {/*{!fileHasNoErrors && !hasFileSizeErrors && !hasFileTypeErrors &&*/}
            {/*   <div style={{ fontSize: 11, color: colorWheel.red }}>{errors.join(', ')}</div>*/}
            {/*} */}

            <CustomIcon type={icon} />

            {fileHasNoErrors && fileName && <div style={{ color: colorWheel.ivyGreen, fontWeight: 500 }}>{fileName}</div>}

            <p style={styles.txtStyle}>{accept}</p>

            <div style={{ marginBottom: 8 }}>
               {`${maximumFileSizeMB} MB Maximum`}
            </div>
            
         </Space>
      </Dragger>
      <BasicErrorDisplay error={errors} />
   </div>
}

export interface IFormFileUploadDraggerProps extends Omit<IBasicFileUploadDraggerProps, 'fileName'> {
   error: DeepMap<DeepPartial<FieldValue<FieldValues>>, FieldError[] | FieldError | undefined>;
   control: Control<FieldValue<FieldValues>>;
   name: string;

   // react-hook-forms
   trigger: any;
   setError: any;
   clearErrors: any;

   tooltip?: React.ReactNode;
   required?: boolean;

   // Dashboard Content Editor allows editing of existing records, in which case we need to 
   // display the persisted string value in the DB without having to retrieve the file
   fileNameOverride?: string;
}

export const FormFileUploadDragger: React.FC<IFormFileUploadDraggerProps> = (props) => {
   const {
      control,
      name,
      error,
      tooltip,
      onChange: parentOnChange,
      trigger,
      beforeUpload,
      setError,
      clearErrors,
      fileNameOverride,
      ...rest } = props;

   const getCustomErrors = () => {
      if (error) {
         const isArray = Array.isArray(error);

         if (isArray) {
            const result = error.filter((y: FieldError) => y?.type === 'Custom')
               .map((y: FieldError) => y?.message);
            return result;
         } else {
            const singleError = error as FieldError;
            const result = singleError?.type === 'Custom' ? singleError : null;
            return [result?.message];
         }
      }
      return null;
   }

   const getNonCustomErrors = (): FieldError | FieldError[] => {
      if (error) {
         const isArray = Array.isArray(error);
         
         if (isArray) {
            const result = error.filter((y: FieldError) => y?.type !== 'Custom')
            return result;
         } else {
            const singleError = error as FieldError;
            const result = singleError?.type !== 'Custom' ? singleError : null;
            return result;
         }
      }
      return null;
   }

   const html = (
      <Controller
         control={control as unknown as Control<FieldValue<FieldValues>>}
         name={name}
         render={({ field: { onChange, value } }) => <>
            
            <Form.Item
               // style={labelStyles(containerStyle)}
               validateStatus={error ? 'error' : 'success'}
               colon={false}
               labelCol={{ span: 24 }}
               tooltip={tooltip}
            >
               <BasicFileUploadDragger
                  onChange={(e: File) => {
                     if (e) {
                        onChange && onChange(e);
                        parentOnChange && parentOnChange(e)
                     }
                  }}
                  
                  fileName={(fileNameOverride && fileNameOverride) || (value && (value as UploadFile<File>).name)}
                  beforeUpload={(e, isFileValid, error) => {
                     if (isFileValid) {
                        clearErrors(name);
                     } else {
                        setError(name, { message: error ?? 'An unexpected error occurred', type: 'Custom' }); // todo - better data from result to determine error type
                     }
                     beforeUpload && beforeUpload(e, isFileValid);
                     
                  }}
                  {...rest}
                  // Custom errors are our expected errors (file size, type) so we display those in the basic in the box
                  // As we have specific styles / display for them
                  errors={getCustomErrors()}
               />
               {/* Non-custom aka generic errors like form yup / react-hook-forms are handled here */}
               <ErrorDisplay error={getNonCustomErrors()} />
            </Form.Item></>
         } />
   );

   return html;
}


