import * as React from 'react';
import FoundationAssistanceServiceList from '../foundation/FoundationAssistanceServiceList';
import { AssistanceProgramLookup, AssistanceServiceLookup } from '../../store/program/LookupModels';
import { Collapse } from 'antd'
import colorWheel from '../../Theme/ColorWheel';
import { CollapseProps } from 'antd/lib';

interface Props {
   foundationId: number;
   assistanceProgramLookups: AssistanceProgramLookup[];
   onEditProgram: (assistanceProgram: AssistanceProgramLookup) => void;
   onAddService: (assistanceProgramId: number) => void;
   onEditService: (assistanceService: AssistanceServiceLookup) => void;
}

const FoundationAssistanceProgramList: React.FC<Props> = (props) => {
   const { assistanceProgramLookups, onAddService, onEditProgram, onEditService } = props;
   const items: CollapseProps['items'] = assistanceProgramLookups?.map((ap, i) => {
      return {
         key: ap.id,
         label: <div>
            <span title='Edit Assistance Program'
               style={{ textDecorationLine: 'underline' }}
               onClick={(e) => {
                  e.stopPropagation();
                  onEditProgram(ap)
               }}>
               {ap.programName}
            </span>
         </div>,
         header: <>{ap.programName}</>,
         children: <>
            <FoundationAssistanceServiceList
               key={ap.id}
               assistanceServiceLookups={ap.assistanceServiceLookups}
               assistanceProgramId={ap.id}
               onEditService={onEditService} onAddService={onAddService} />
         </>
      }
   });

   return <Collapse
      style={{
         backgroundColor: colorWheel.plumPurple,
         border: 'none',
         borderLeft: 'none',
         borderRight: 'none',
         borderRadius: 'none',
         marginBottom: '1rem'
      }}
      accordion
      expandIconPosition='end'
      ghost={false}
      items={items}
   />
}

export default FoundationAssistanceProgramList;
