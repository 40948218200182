import { Space } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import * as React from 'react';
import { useApiContext } from '../../store/ApiContext';
import { useFetchUserPendingAccess, sendDenyRequest } from '../../store/auth/UserPendingAccessFetcher';
import { UserPendingAccessModel } from '../../store/auth/UserPendingAccessModel';
import colorWheel from '../../Theme/ColorWheel';
import Dialog from '../Dialog';
import CustomIcon, { CustomIconType } from '../shared/AntComponents/CustomIcon';
import MinimalistTable from '../shared/AntComponents/Table/MinimalistTable';
import { NumberedTitle } from '../shared/AntComponents/Typography/Title';
import { BasicInputField } from '../shared/BasicInputLibrary';
import { ActionButton, CancelButton } from '../shared/Buttons';
import Spinner from '../Spinner';
import ApproveNewUserEditor from '../user/shared/ApproveNewUserEditor';

export const PendingAccessRequestTile: React.FC<{ styles?: React.CSSProperties }> = (props) => {
   const { styles } = props;
   const { httpGet, httpPost } = useApiContext();
   const { userPendingAccessList, isLoading } = useFetchUserPendingAccess(httpGet);
   const [isApproveNewUserEditorOpen, setIsApproveNewUserEditorOpen] = React.useState(false);
   const [isDenyDialogOpen, setIsDenyDialogOpen] = React.useState<boolean>(false);
   const [denialReason, setDenialReason] = React.useState<string>(undefined);
   const [selectedUserPendingAccess, SetSelectedUserPendingAccess] = React.useState<UserPendingAccessModel>();
   const [denyRequestProcessing, setDenyRequestProcessing] = React.useState<boolean>(false);

   const onCloseApproveNewUserEditor = () => {
      setIsApproveNewUserEditorOpen(false);
   }

   if (isLoading) return <Spinner message={'Loading Pending Access Requests...'} />

   const columns: ColumnsType<UserPendingAccessModel> = [
      {
         title: 'Full name',
         dataIndex: 'lastName',
         key: 'lastName',
         render: (text, record) => `${record.firstName} ${record.lastName}`.trim()
      },
      {
         title: 'UPN',
         dataIndex: 'upn',
         key: 'upn'
      },
      {
         title: 'Requested Practice',
         dataIndex: 'requestedPracticeName',
         key: 'requestedPracticeName'
      },
      {
         width: 100,
         render: (text, record) => {
            return <Space>
               <ActionButton
                  onClick={() => {
                     SetSelectedUserPendingAccess(record);
                     setIsApproveNewUserEditorOpen(true);
                  }}
                  title={'Approve'}
                  icon={<CustomIcon type={CustomIconType.CheckOutlined} />}
               />
               <ActionButton
                  onClick={() => {
                     SetSelectedUserPendingAccess(record);
                     setIsDenyDialogOpen(true)
                  }}
                  title={'deny'}
                  danger
                  icon={<CustomIcon type={CustomIconType.CloseCircleOutlined} />}
               />
            </Space>
         }
      }
   ];

   // AH-6715 adding the overflow Y to allow scrolling within the tile
   // and overflow X to clip for no bottom scrollbar within the tile
   return (<>
      <div style={{ ...styles }}>
         <NumberedTitle level={5} text='Pending Access Requests' />
         <div style={{ border: `1px solid ${colorWheel.mediumGrey}`, maxHeight: '25rem', overflowY: 'scroll', overflowX: 'clip' }}>
            <MinimalistTable
               rowKey='id'
               data={userPendingAccessList}
               columns={columns}
               size='small'
               showHeader={true}
            />
         </div>
      </div>

      {isApproveNewUserEditorOpen &&
         <ApproveNewUserEditor
            closeEditor={onCloseApproveNewUserEditor}
            isEditorOpen={isApproveNewUserEditorOpen}
            userPendingAccessId={selectedUserPendingAccess.id}
            key={'approveNewUserEditor'}
         />
      }

      {isDenyDialogOpen &&
         <Dialog
            title='Deny'
            open={isDenyDialogOpen}
            style={{ maxWidth: '550px' }}
            actionButtons={
               <Space>
                  <CancelButton
                     loading={denyRequestProcessing}
                     onClick={() => {
                        SetSelectedUserPendingAccess(undefined);
                        setIsDenyDialogOpen(false);
                        setDenyRequestProcessing(false);
                        setDenialReason(undefined);
                     }} />
                  <ActionButton
                     danger={true}
                     icon={<CustomIcon type={CustomIconType.CloseCircleOutlined} />}
                     buttonText='Deny Access'
                     loading={denyRequestProcessing}
                     onClick={() => {
                        setDenyRequestProcessing(true);
                        sendDenyRequest(httpPost, { pendingUserId: selectedUserPendingAccess.id, statusReason: denialReason })
                           .then(() => {
                              SetSelectedUserPendingAccess(undefined);
                              setIsDenyDialogOpen(false);
                              setDenialReason(undefined);
                           })
                           .catch((e) => { console.log(e); })
                           .finally(() => { setDenyRequestProcessing(false); });
                     }} />
               </Space>
            }>
            <p style={{ margin: '0', marginBottom: '1rem' }}>Selected User: <b>{selectedUserPendingAccess?.emailAddress}</b></p>
            <BasicInputField label='Denial Reason (optional)'
               onChange={(v) => setDenialReason(v as string)}
               value={denialReason}
               maxLength={1023} />
         </Dialog>
      }
   </>
   )
}


export default PendingAccessRequestTile;