import { HttpError, TGet, TDelete, TPost } from '../../functions/httpClient';
import { IStatus } from '../models';
import useSWR, { mutate } from 'swr';
import { BrandResource } from './BrandResourceModel';

export const brandResourceBaseUrl = `api/brandResource`;
export const brandResourceSaveUrl = `${brandResourceBaseUrl}/Save`;
export const brandResourceDeleteUrl = (id: number) => `${brandResourceBaseUrl}/Delete/${id}`;

const swrOptions = {
   refreshInterval: 0,
   revalidateOnFocus: false,
   dedupingInterval: 60000
};


export function useFetchBrandResource(httpGet: TGet): IStatus & { brandResources: BrandResource[] } {
   const { data, isValidating, error } = useSWR<BrandResource[], HttpError>(
      brandResourceBaseUrl,
      httpGet,
      { ...swrOptions }
   );

   return {
      brandResources: data,
      isLoading: !error && !data && isValidating,
      error: error?.message
   };
}

export const saveBrandResource = async (httpPost: TPost, brandResource: BrandResource) => {
   const result = await httpPost(brandResourceSaveUrl, brandResource);
   mutate(brandResourceBaseUrl);
   return result;
}

export const deleteBrandResource = async (httpDelete: TDelete, id: number) => {
   const result = await httpDelete(brandResourceDeleteUrl(id));
   mutate(brandResourceBaseUrl);
   return result;
}