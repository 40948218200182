import * as React from 'react';
import { Link } from 'react-router-dom';
import { Col, Row, Space, Table, Form, TableProps } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { FilterValue, TableRowSelection } from 'antd/lib/table/interface';
import { UserProfile } from '../../store/practice/UserProfileModel';
import MinimalistTable from '../shared/AntComponents/Table/MinimalistTable';
import { dateComparer, numberComparer, stringComparer } from '../../functions/comparer.functions';
import { useApiContext } from '../../store/ApiContext';
import { useUserMainViewModels } from '../../store/auth/UserMainFetcher';
import { UserMain } from '../../store/auth/UserMainModel';
import { ActionButton, EditButton } from '../shared/Buttons';
import CustomIcon, { CustomIconType } from '../shared/AntComponents/CustomIcon';
import { formatShortDateString } from '../../functions/format.functions';
import { UserMigrationContextProvider } from '../../store/auth/UserMigrationContext';
import { UserMigration } from '../../store/auth/UserMigrationModel';
import { BasicFieldWrapper } from '../shared/BasicInputLibrary';
import Restricted from '../../auth/Restricted';
import { KnownSettings } from '../../store/SettingsModel';
import { PracticeUserMigrationConfirmation } from './PracticeUserMigrationConfirmation';
import { PracticeUserEditEmailAddressDialog } from './PracticeUserEditEmailAddressDialog';
import ExistingUserProfileEditor from '../user/shared/ExistingUserProfileEditor';

interface IProps {
   practiceId: number;
}

interface IGridModel {
   key: number;
   userMain: UserMain;
   userProfile: UserProfile;
   userMigration: UserMigration;
}

type searchFilter = 'userMainId'
   | 'upn'
   | 'userProfileId'
   | 'emailAddress'
   | 'firstName'
   | 'lastName'
   | 'migrationStatus'
   ;
const defaultSearchFilter: Record<searchFilter, { text: string, value: string }[]> = {} as Record<searchFilter, { text: string, value: string }[]>;


export const PracticeUserMigrationEmbeddedList: React.FC<IProps> = (props) => {
   const { practiceId } = props;

   const { httpGet } = useApiContext();
   const { userMainViewModels } = useUserMainViewModels(httpGet);

   const [gridModels, setGridModels] = React.useState<IGridModel[]>([]);

   const [bulkItems, setBulkItems] = React.useState<number[]>([]);
   const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);
   const [userMainsToMigrate, setUserMainsToMigrate] = React.useState<UserMain[]>([]);
   const [isConfirmMigrateUsersDialogOpen, setIsConfirmMigrateUsersDialogOpen] = React.useState<boolean>(false);
   const [userProfileForEdit, setUserProfileForEdit] = React.useState<UserProfile>(undefined);
   const [isEmailEditDialogOpen, setIsEmailEditDialogOpen] = React.useState(false);
   const [isUserManagementEditorOpen, setIsUserManagementEditorOpen] = React.useState(false);

   const [hasAppliedUserSearchFilter, setHasAppliedUserSearchFilter] = React.useState<boolean>(false);
   const [appliedUserSearchFilter, setAppliedUserSearchFilter] = React.useState<Record<string, FilterValue | null>>({});
   const [userSearchFilters, setUserSearchFilters] = React.useState<Record<searchFilter, { text: string, value: string }[]>>(defaultSearchFilter);

   const [form] = Form.useForm();

   React.useEffect(() => {
      if (!userMainViewModels || !practiceId) return;

      const storeModels = userMainViewModels.filter(y => !y.isDisabled
         && y.userProfiles?.some(x => x.practiceId == practiceId &&  !x.isDisabled)
      );

      if (storeModels?.length === 0) return;

      const _setGridModels = () => {

         const userMainIdList: { text: string, value: string }[] = [];
         const upnList: { text: string, value: string }[] = [];
         const userProfileIdList: { text: string, value: string }[] = [];
         const emailAddressList: { text: string, value: string }[] = [];
         const firstNameList: { text: string, value: string }[] = [];
         const lastNameList: { text: string, value: string }[] = [];
         const migrationStatusList: { text: string, value: string }[] = [];

         const tempModels: IGridModel[] = storeModels.map((model) => {

            const thisUserProfile = model.userProfiles.find(y => y.practiceId === practiceId);

            userMainIdList.push({ text: model.id.toString(), value: model.id.toString() });

            const _upn = model.upn.toLowerCase().trim();
            if (!upnList.some(y => y.value === _upn)) {
               upnList.push({ text: _upn, value: _upn });
            }

            const _up = thisUserProfile.userProfileId.toString();
            if (_up && !userProfileIdList.some(y => y.value === _up)) {
               userProfileIdList.push({ text: _up, value: _up });
            }
            const _em = thisUserProfile.emailAddress?.trim();
            if (_em && !emailAddressList.some(y => y.value === _em.toLowerCase())) {
               emailAddressList.push({ text: _em, value: _em.toLowerCase() });
            }

            const _fn = thisUserProfile.firstName?.trim();
            if (_fn && !firstNameList.some(y => y.value === _fn?.toLowerCase())) {
               firstNameList.push({ text: _fn, value: _fn?.toLowerCase() });
            }

            const _ln = thisUserProfile.lastName?.trim();
            if (_ln && !lastNameList.some(y => y.value === _ln?.toLowerCase())) {
               lastNameList.push({ text: _ln, value: _ln?.toLowerCase() });
            }
                        
            let derivedActivityStatus = model.userMigration?.activityStatus ?? '';

            let _as = derivedActivityStatus.trim();
            if (!migrationStatusList.some(y => y.value === _as.toLowerCase())) {
               migrationStatusList.push({ text: _as, value: _as.toLowerCase() });
            }

            return {
               key: model.id,
               userMain: model,
               userProfile: thisUserProfile,
               userMigration: model.userMigration
            } as IGridModel
         });

         setUserSearchFilters({
            userMainId: userMainIdList.sort((a, b) => numberComparer(Number(a.value), Number(b.value))),
            upn: upnList.sort((a, b) => stringComparer(a.text, b.text)),
            userProfileId: userProfileIdList.sort((a, b) => numberComparer(Number(a.value), Number(b.value))),
            emailAddress: emailAddressList.sort((a, b) => stringComparer(a.text, b.text)),
            firstName: firstNameList.sort((a, b) => stringComparer(a.text, b.text)),
            lastName: lastNameList.sort((a, b) => stringComparer(a.text, b.text)),
            migrationStatus: migrationStatusList.sort((a, b) => stringComparer(a.text, b.text)),
         });
         setGridModels(tempModels);
      }

      _setGridModels();

   }, [practiceId, userMainViewModels]);

   const handleAppliedFilterChange: TableProps<IGridModel>['onChange'] = (pagination, filters, sorter) => {
      let hasAppliedFilter = false;
      for (const key in filters) {
         if (!hasAppliedFilter) {
            if (filters[key]) {
               setHasAppliedUserSearchFilter(true);
               hasAppliedFilter = true;
               break;
            }
         }
      }

      setAppliedUserSearchFilter(filters);
      setHasAppliedUserSearchFilter(hasAppliedFilter);
   };

   const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
      const arr = newSelectedRowKeys.map((y) => { return Number(y) });
      setBulkItems(arr);
      setSelectedRowKeys(newSelectedRowKeys);
   };

   const rowSelection: TableRowSelection<object> = {
      selectedRowKeys,
      onChange: onSelectChange,
   };

   const onClearBulkSelection = () => {
      setBulkItems([]);
      setSelectedRowKeys([]);
   }

   const onClickMigrateUsers = () => {
      var sorted = userMainViewModels.filter(y => bulkItems.includes(y.id)).sort((a, b) => stringComparer(a.upn, b.upn));
      setUserMainsToMigrate(sorted);
      setIsConfirmMigrateUsersDialogOpen(true);
   }

   const onCloseConfirmMigrateUsersDialog = () => {
      setIsConfirmMigrateUsersDialogOpen(false);
      setUserMainsToMigrate([]);
      onClearBulkSelection();
   }

   const onCloseEmailEditDialog = () => {
      setIsEmailEditDialogOpen(false);
      setUserProfileForEdit(undefined);
   }
   const onCloseUserManagementEditDialog = () => {
      setIsUserManagementEditorOpen(false);
      setUserProfileForEdit(undefined);
   }
   const parentColumns: ColumnsType<IGridModel> = [
      {
         title: <h3 style={{ justifyContent: 'center' }}>User Main</h3>,
         dataIndex: 'userMain',
         width: '30%',
         children: [
            {
               dataIndex: ['userMain', 'id'],
               title: 'Id',               
               key: 'userMainId',
               sorter: (a, b) => numberComparer(a.userMain.id, b.userMain.id),
               sortDirections: ['ascend', 'descend'],
               defaultSortOrder: 'ascend',
               filterSearch: true,
               filters: userSearchFilters.userMainId,
               filteredValue: appliedUserSearchFilter.userMainId || null,
               onFilter: (value, record) => (value !== null && record.userMain.id.toString().indexOf(value as string) > -1),
               width: 80
            },
            {
               dataIndex: ['userMain', 'upn'],
               title: 'Auth UPN',
               key: 'upn',
               width: '20%',
               sorter: (a, b) => stringComparer(a.userMain.upn, b.userMain.upn),
               sortDirections: ['ascend', 'descend'],
               defaultSortOrder: 'ascend',
               filterSearch: true,
               filters: userSearchFilters.upn,
               filteredValue: appliedUserSearchFilter.upn || null,
               onFilter: (value, record) => (value === null && !record.userMain?.upn) || (value !== null && record.userMain?.upn?.toLowerCase().indexOf(value as string) > -1),
               render: (text, record) =>
                  <Link
                     to={{ pathname: `/user/userdetail/${record.userMain.id}` }}
                     title={`Edit User Main for ${record.userMain.upn}`}>
                     {record.userMain.upn}
                  </Link>
            },
         ]
      },
      {
         title: <h3 style={{ alignContent: 'center' }}>User Profile</h3>,
         dataIndex: 'userProfile',
         //       width: '40%',
         children: [
            {
               dataIndex: ['userProfile', 'userProfileId'],
               title: 'UPId',
               key: 'userProfileId',
               sorter: (a, b) => numberComparer(a.userProfile.userProfileId, b.userProfile.userProfileId),
               sortDirections: ['ascend', 'descend'],
               defaultSortOrder: 'ascend',
               filterSearch: true,
               filters: userSearchFilters.userProfileId,
               filteredValue: appliedUserSearchFilter.userProfileId || null,
               onFilter: (value, record) => (value !== null && record.userProfile.userProfileId.toString().indexOf(value as string) > -1),
               width: 90,
            },
            {
               dataIndex: ['userProfile', 'emailAddress'],
               title: 'UserProfile Email Address',
               key: 'emailAddress',
               sorter: (a, b) => stringComparer(a.userProfile.emailAddress, b.userProfile.emailAddress),
               sortDirections: ['ascend', 'descend'],
               showSorterTooltip: { title: 'Click on the Email to edit it.  Press Enter or Click outside of the input box to Save.' },
               width: '25%',
               filterSearch: true,
               filters: userSearchFilters.emailAddress,
               filteredValue: appliedUserSearchFilter.emailAddress || null,
               onFilter: (value, record) => (value === null && !record.userProfile?.emailAddress) || (value !== null && record.userProfile?.emailAddress?.toLowerCase().indexOf(value as string) > -1),

               render: (text, record) =>
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                     <Restricted requiredRoles={[KnownSettings.UserAdmin]}>
                        <EditButton
                           title='Edit UserProfile.EmailAddress'
                           buttonText={record?.userProfile?.emailAddress}
                           onClick={() => {
                              setIsEmailEditDialogOpen(true);
                              setUserProfileForEdit(record.userProfile);
                           }}
                        />
                     </Restricted>
                  </div>
            },
            {
               dataIndex: ['userProfile', 'firstName'],
               title: 'First Name',
               key: 'firstName',
               sorter: (a, b) => stringComparer(a.userProfile.firstName, b.userProfile.firstName),
               sortDirections: ['ascend', 'descend'],
               filterSearch: true,
               filters: userSearchFilters.firstName,
               filteredValue: appliedUserSearchFilter.firstName || null,
               onFilter: (value, record) => (value === null && !record.userProfile?.firstName) || (value !== null && record.userProfile?.firstName?.toLowerCase().indexOf(value as string) > -1),
            },
            {
               dataIndex: ['userProfile', 'lastName'],
               title: 'Last Name',
               key: 'userProfile.lastName',
               sorter: (a, b) => stringComparer(a.userProfile.lastName, b.userProfile.lastName),
               sortDirections: ['ascend', 'descend'],
               filterSearch: true,
               filters: userSearchFilters.lastName,
               filteredValue: appliedUserSearchFilter.lastName || null,
               onFilter: (value, record) => (value === null && !record.userProfile?.lastName) || (value !== null && record.userProfile?.lastName?.toLowerCase().indexOf(value as string) > -1),
            }
         ]
      },
      {
         title: <h3 style={{ alignContent: 'center' }}>User Migration</h3>,
         dataIndex: 'userMigration',
         width: '30%',
         children: [
            {
               title: 'Migration Status',
               dataIndex: ['userMigration', 'activityStatus'],
               key: 'activityStatus',
               sorter: (a, b) => stringComparer(a.userMigration?.activityStatus, b.userMigration?.activityStatus),
               sortDirections: ['ascend', 'descend'],
               filterSearch: true,
               filters: userSearchFilters.migrationStatus,
               filteredValue: appliedUserSearchFilter.activityStatus || null,
               onFilter: (value, record) => (!value && !record.userMigration?.activityStatus) || (value !== '' && record.userMigration?.activityStatus?.toLowerCase().indexOf(value as string) > -1),
               render: (text, record) => {
                  if (record.userMigration?.activityStatus?.toLowerCase() !== 'success') {
                     return <BasicFieldWrapper label={record.userMigration?.activityStatus} tooltip={record.userMigration?.error} field={undefined} />
                  } else {
                     return record.userMigration?.activityStatus;
                  }
               }
            },
            {
               title: 'Activity Date',
               dataIndex: 'userMigration.activityStatusOn',
               key: 'userMigration.activityStatusOn',
               sorter: (a, b) => dateComparer(a.userMigration?.activityStatusOn, b.userMigration?.activityStatusOn),
               sortDirections: ['ascend', 'descend'],
               render: (text, record) => formatShortDateString(record.userMigration?.activityStatusOn)

            }
         ]
      },
      Table.SELECTION_COLUMN,
      {
         dataIndex: '',
         title: '',
         key: 'editrow',
         width: '4em',
         align: 'right',
         render: (text, record) =>
            <Restricted requiredRoles={[KnownSettings.UserAdmin]}>
               <EditButton
                  title='Edit User Profile'
                  onClick={() => {
                     setUserProfileForEdit(record.userProfile);
                     setIsUserManagementEditorOpen(true);
                  }} />
            </Restricted>
      }
   ]

   return (
      <>
         <Form form={form}>
            <MinimalistTable
               rowKey='key'
               data={gridModels}
               columns={parentColumns}
               size='small'
               showHeader={true}
               bordered
               scrollY='calc(100vh - 520px)'
               rowSelection={rowSelection}
               onChange={handleAppliedFilterChange}
               footer={<Row justify="end">
                  <Col>
                     <Restricted requiredRoles={[KnownSettings.UserAdmin]}>
                        <Space>
                           <ActionButton onClick={() => {
                              setAppliedUserSearchFilter({});
                              setHasAppliedUserSearchFilter(false);
                           }}
                              disabled={!hasAppliedUserSearchFilter}
                              buttonText='Reset Filters'
                              icon={<CustomIcon type={CustomIconType.RedoOutlined} />}
                           />
                           <ActionButton
                              buttonText='Clear Bulk Selection'
                              onClick={onClearBulkSelection}
                              disabled={bulkItems.length < 1}
                              icon={<CustomIcon type={CustomIconType.CloseOutlined} />}
                           />
                           <ActionButton
                              buttonText='Migrate Users'
                              disabled={bulkItems.length < 1}
                              onClick={onClickMigrateUsers}
                              icon={<CustomIcon type={CustomIconType.EditOutlined} />}
                              title={'Migrate Users'}
                           />
                        </Space>
                     </Restricted>
                  </Col>
               </Row>}
            />
         </Form>
         {isConfirmMigrateUsersDialogOpen &&
            <UserMigrationContextProvider>
               <PracticeUserMigrationConfirmation
                  isConfirmationDialogOpen={isConfirmMigrateUsersDialogOpen}
                  onCloseEditor={onCloseConfirmMigrateUsersDialog}
                  practiceId={practiceId}
                  userMainsToMigrate={userMainsToMigrate} />
            </UserMigrationContextProvider>
         }
         {isEmailEditDialogOpen &&
            <PracticeUserEditEmailAddressDialog
               userProfileToEdit={userProfileForEdit}
               isEditEmailAddressDialogOpen={isEmailEditDialogOpen}
               onClose={onCloseEmailEditDialog}
            />
         }

         {isUserManagementEditorOpen &&
            <ExistingUserProfileEditor
               isEditorOpen={isUserManagementEditorOpen}
               onCloseEditor={() => onCloseUserManagementEditDialog()}
               existingUserProfileId={userProfileForEdit?.userProfileId}
               isEditorReadOnly={false} />
         }
      </>
   );

}

export default PracticeUserMigrationEmbeddedList;
