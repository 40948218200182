import * as React from 'react';
import MinimalistTable from '../shared/AntComponents/Table/MinimalistTable';
import { ColumnsType } from 'antd/lib/table';
import {  dateComparer, stringComparer } from '../../functions/comparer.functions';
import { antSortOptions } from '../shared/AntComponents/Table/table.functions';
import { formatDate } from '../../functions/time.functions';
import { FaxAuditLogQueryResult, FaxLogViewModel } from '../../store/fax/FaxLogModels';
import { useApiContext } from '../../store/ApiContext';
import { useFaxAuditLogs } from '../../store/fax/FaxFetcher';
import TruncateText from '../shared/TruncateText';
import { getFaxAuditLogsBaseUrl } from '../../store/fax/FaxFetcher';
import ApiErrorDisplay from '../ApiErrorDisplay';
import Spinner from '../Spinner';
import { useErrorContext } from '../../store/ErrorContext';


interface IProps {
   faxLogViewModel: FaxLogViewModel
}

const _keysLike: string[] = [getFaxAuditLogsBaseUrl]

const FaxAuditLogRow: React.FC<IProps> = (props) => {
   const { faxLogViewModel } = props;
   const { httpGet } = useApiContext();
   const { removeErrors } = useErrorContext();
   const { faxAuditLogs, isLoading, error } = useFaxAuditLogs(httpGet, faxLogViewModel.id);

   React.useEffect(() => {
      removeErrors({ keysLike: _keysLike });
      // for "removeErrors"
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []) // clear errors on intial render of the page

   const columns: ColumnsType<FaxAuditLogQueryResult> = [
      {
         dataIndex: 'timestamp',
         title: 'Timestamp',
         key: 'timestamp',
         sorter: (a, b) => dateComparer(a.timestamp, b.timestamp),
         sortDirections: antSortOptions,
         render: (text, record) => formatDate(record.timestamp, 'MM/DD/YYYY hh:mm:ss Z')
     },
      {
         dataIndex: 'message',
         title: 'Message',
         key: 'message',
         sorter: (a, b) => stringComparer(a.message, b.message),
         sortDirections: antSortOptions,
      },
      {
         dataIndex: 'statusType',
         title: 'Status',
         key: 'statusType',
         sorter: (a, b) => stringComparer(a.statusType, b.statusType),
         sortDirections: antSortOptions,
      },
      {
         dataIndex: 'apiMessage',
         title: 'apiMessage',
         key: 'apiMessage',
         width: '30%',
         sorter: (a, b) => stringComparer(a.apiMessage, b.apiMessage),
         sortDirections: antSortOptions,
         render: (text, record) => <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
            <TruncateText
               fullText={text}
               maxLength={200} />
         </div>
      },
      {
         dataIndex: 'apiException',
         title: 'apiException',
         key: 'apiException',
         width: '30%',
         sorter: (a, b) => stringComparer(a.apiException, b.apiException),
         sortDirections: antSortOptions,
         render: (text, record) => <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
            <TruncateText
            fullText={text}
               maxLength={200} />
         </div>
      },
   ];

   const html = (
      <>
         <ApiErrorDisplay title='Api Error' keysLike={_keysLike} />

         {isLoading && <Spinner />}

         <MinimalistTable
            showTitle={false}
            rowKey='rowKey'
            data={faxAuditLogs}
            columns={columns}
            size='small'
            showHeader={true}
            hidePaging={true}
            
         />        
      </>
   )

   return html;
}

export default FaxAuditLogRow;