import { enumElementCount } from '../../functions/common.functions';
import { CohortItem } from './CohortItemModel';
import { CohortItemStatusType } from './CohortItemStatus';

export interface ProgramSummaryDetail {
   cohortId: number;
   cohortTypeId: number;
   recordId: number;

   programType: number;
   auditState: string;
   partner: string;
   partnerId: number;
   diseaseOrBrand: string;
   diseaseOrBrandId: number;
   programName: string;
   assistanceProgramId: number;
   assistanceServiceName: string;
   assistanceServiceId: number;
   serviceCategoryId: number;
   dataGroups: ProgramSummaryDataGroup[]
}

export interface ProgramSummaryDataGroup {
   dataGroupId: number;
   dataGroupName: string;
   cohortItems: CohortItem[]
}

export interface ProgramServiceDiseaseOrBrandLookup {
   assistanceProgramId: number;
   programName: string;
   assistanceServiceId: number;
   assistanceServiceName: string;
   diseaseOrBrandId: number;
   diseaseOrBrand: string;
}

export interface AssistanceServiceLookup {
   assistanceProgramId: number;
   assistanceServiceId: number;
   assistanceServiceName: string;
}

export interface DiseaseOrBrandLookup {
   diseaseOrBrand: string;
   diseaseOrBrandId: number;
   assistanceProgramId: number;
   assistanceServiceId: number;
}

//export const stateOptions: Array<IOption> = [
export const countCohortItems = (dataGroups: ProgramSummaryDataGroup[]): number => {
   let count: number = 0;
   if (dataGroups?.length > 0) {
      for (let group = 0; group < dataGroups.length; group++) {
         count += dataGroups[group].cohortItems.length
      }
   }
   return count;
}

export const getCohortItemStatusArray = (dataGroups: ProgramSummaryDataGroup[]): number[] => {
   const statusArr: number[] = [];
   if (dataGroups?.length > 0) {
      for (let group = 0; group < dataGroups.length; group++) {
         const temp: number[] = dataGroups[group].cohortItems.map(y => y.cohortItemStatusId);
         statusArr.push(...temp);
      }
   }
   return statusArr;
}

export const getColumnCohortItemStatusArray = (rows: ProgramSummaryDetail[], dataGroupIndex: number): number[] => {
   //get total enums possible
   const maxStatusValues = enumElementCount(CohortItemStatusType);

   const statusArr: number[] = [];

   for (let rowIdx = 0; rowIdx < rows.length; rowIdx++) {
      //for (let groupIdx = 0; groupIdx < rows[rowIdx].dataGroups.length; groupIdx++) {
      for (let itemIdx = 0; itemIdx < rows[rowIdx].dataGroups[dataGroupIndex].cohortItems.length; itemIdx++) {
         const statusId: number = rows[rowIdx].dataGroups[dataGroupIndex].cohortItems[itemIdx].cohortItemStatusId;
         //if status not in list, then add
         if (statusArr.indexOf(statusId) === -1) {
            statusArr.push(statusId);
            //if total items matches total possible, then just exit
            if (statusArr.length === maxStatusValues) {
               return statusArr;
            }
         }
         //}
      }

      //for (let rowIdx = 0; rowIdx < rows.length; rowIdx++) {
      //   for (let groupIdx = 0; groupIdx < rows[rowIdx].dataGroups.length; groupIdx++) {
      //      for (let itemIdx = 0; itemIdx < rows[rowIdx].dataGroups[groupIdx].cohortItems.length; itemIdx++) {
      //         const statusId: number = rows[rowIdx].dataGroups[groupIdx].cohortItems[itemIdx].cohortItemStatusId;
      //         //if status not in list, then add
      //         if (statusArr.indexOf(statusId) === -1) {
      //            statusArr.push(statusId);
      //            //if total items matches total possible, then just exit 
      //            if (statusArr.length === maxStatusValues) {
      //               return statusArr;
      //            }
      //         }
      //      }
      //   }
   }
   return statusArr;
}
