import { HttpError, TPost, TDelete, TGet, TPutFile, TPut } from '../../functions/httpClient';
import { IStatus } from '../models';
import useSWR, { mutate } from 'swr';
import { DashboardContent } from './DashboardContentModel';

export const dashboardContentBaseUrl = `api/DashboardContent`;
export const dashboardContentDeleteUrl = (id: number) => `${dashboardContentBaseUrl}/${id}`;
const dashboardContentCheckFileExistsUrl = `${dashboardContentBaseUrl}/CheckFileExist`;
export const dashboardContentUploadFileUrl = `${dashboardContentBaseUrl}/Upload`;
export const dashboardContentUpdateSortOrder = `${dashboardContentBaseUrl}/UpdateSortOrder`;

const swrOptions = {
   refreshInterval: 0,
   revalidateOnFocus: false,
   dedupingInterval: 60000
};


export function useFetchDashboardContent(httpGet: TGet): IStatus & { dashboardContentList: DashboardContent[] } {
   const { data, isValidating, error } = useSWR<DashboardContent[], HttpError>(
      dashboardContentBaseUrl,
      httpGet,
      { ...swrOptions }
   );

   return {
      dashboardContentList: data,
      isLoading: !error && !data && isValidating,
      error: error?.message
   };
}

export const saveDashboardContent = async (httpPost: TPost, dashboardContent: DashboardContent) => {
   const result = await httpPost(dashboardContentBaseUrl, dashboardContent)
      .then(() => mutate(dashboardContentBaseUrl));
   return result;
}

export const deleteDashboardContent = async (httpDelete: TDelete, id: number) => {
   const result = await httpDelete(dashboardContentDeleteUrl(id))
      .then(() => mutate(dashboardContentBaseUrl));
   return result;
}

export const uploadDashboardContentFile = async (httpPutFile: TPutFile, item: File) => {
   const result = await httpPutFile(dashboardContentUploadFileUrl, item);
   return result;
}

export const checkFileExists = async (httpPost: TPost, filename: string, isThumbnail: boolean) => {
   const result = await httpPost<boolean>(dashboardContentCheckFileExistsUrl, { filename, isThumbnail });
   return result;
}

export const updateSortOrder = async (httpPut: TPut, orderedItems: number[]) => {
   const result = await httpPut(dashboardContentUpdateSortOrder, orderedItems)
      .then(() => (mutate(dashboardContentBaseUrl)));
   return result;
}

//export const checkFileExists = (httpPost: TPost, filename: string, isThumbnail: boolean): boolean => {
//   let returnVal: boolean;
//   httpPost<boolean>(dashboardContentCheckFileExistsUrl, { filename, isThumbnail })
//      .then(response => {
//         returnVal = response;
//      });

//   return returnVal;
//} 