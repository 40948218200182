import { HttpError, TPost, TGet } from '../../functions/httpClient';
import { IStatus } from '../models';
import useSWR, { mutate } from 'swr';
import { PracticeSettings } from './PracticeSettingsModel';

export const practiceSettingBaseUrl = `api/practiceSettings`;
const practiceSettingSaveUrl = `${practiceSettingBaseUrl}/Save`;

const swrOptions = {
   refreshInterval: 0,
   revalidateOnFocus: false,
   dedupingInterval: 60000
};


export function useFetchPracticeSettings(httpGet: TGet): IStatus & { practiceSettings: PracticeSettings[] } {
   const { data, isValidating, error } = useSWR<PracticeSettings[], HttpError>(
      practiceSettingBaseUrl,
      httpGet,
      { ...swrOptions }
   );

   return {
      practiceSettings: data,
      isLoading: !error && !data && isValidating,
      error: error?.message
   };
}

export const savePracticeSetting = async (httpPost: TPost, practiceSetting: PracticeSettings) => {
   const result = await httpPost(practiceSettingSaveUrl, practiceSetting)
      .then(() => mutate(practiceSettingBaseUrl));
   return result;
}