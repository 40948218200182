import { useAuth0 } from '@auth0/auth0-react';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import React from 'react';
import { Popconfirm } from 'antd';
import { IFSAProcessingMessage, SignalRTestConnectivityMessage, IAcknowledgeableNotification, NotificationStatusType } from '../../store/notification/NotificationModel';
import { useNotificationContext } from './NotificationContext';
import { useGetRecentFundEmails } from '../../store/fsa/RecentlySentFetcher';
import { useApiContext } from '../../store/ApiContext';

interface IProps {
}

const QA_TEST_CONNECTION_METHOD_NAME = 'TestConnectivity';
const SEND_FSA_METHOD_NAME = 'SendFSANotification';

const SignalRNotification: React.FC<IProps> = (props) => {
   const { mergeNotification } = useNotificationContext();
   const [connection, setConnection] = React.useState<HubConnection>(undefined);
   const { getAccessTokenSilently } = useAuth0();

   const { httpPost } = useApiContext();
   const { recentEmailsMutator } = useGetRecentFundEmails(httpPost);

   // these were primarily used in conjunction with the <Popover /> at the bottom for proof-of-concept / testing
   const [open, setOpen] = React.useState(false);
   const [message, setMessage] = React.useState<IFSAProcessingMessage>();

   const handleOk = () => {
      console.log('Clicked cancel button');
      setMessage(undefined);
      setOpen(false);
   };


   React.useEffect(() => {
      setConnection(new HubConnectionBuilder()
         .withUrl(`${window.location.origin}/notifications`, { accessTokenFactory: async () => await getAccessTokenSilently(), withCredentials: false })
         .withAutomaticReconnect()
         .build());
      /* eslint-disable-next-line react-hooks/exhaustive-deps */
   }, []);

   const handleFSANotfication = (notification: IFSAProcessingMessage) => {
      mergeNotification({
         key: notification.uId,
         ...notification
      } as IAcknowledgeableNotification);

      // Since "running" updates come frequently for a given FSA and the recentEmailsMutate query is expensive...
      notification.status.toString() !== NotificationStatusType[NotificationStatusType.Running] && recentEmailsMutator();
   }

   React.useEffect(() => {
      const setupConnection = async () => {
         try {
            if (connection) {
               await connection.start();

               // QA / Test endpoint for validating everything is hooked up propertly
               connection.on(QA_TEST_CONNECTION_METHOD_NAME, (message: SignalRTestConnectivityMessage) => {
                  console.log(QA_TEST_CONNECTION_METHOD_NAME, message);
                  message.key = `ConnectivityTest_${message.timestamp}`;
                  message.timestamp = message.timestamp;
                  message.title = 'Connectivity Test';
                  mergeNotification(message)
               })

               connection.on(SEND_FSA_METHOD_NAME, (notification: IFSAProcessingMessage) => {
                  console.log(SEND_FSA_METHOD_NAME, notification);
                  handleFSANotfication(notification);
               });
            }
         } catch (error) {
            console.log(error);
         }
      };

      setupConnection();
      /* eslint-disable-next-line */
   }, [connection]);

   return <>
      {message &&
         <Popconfirm
            title={`SignalR: ${message?.uId}`}
            description={`Message: ${message?.message}`}
            open={open}
            onConfirm={handleOk}>
            <div></div>
         </Popconfirm>}
   </>
};

export default SignalRNotification;
