import * as React from 'react';
import { useRecentlyViewedReports } from './RecentlyViewedReportsContext';
import { Link } from 'react-router-dom';
import { View_Browse_Certified, View_Browse_Uncertified, buildReportUrl } from '../../store/report/ReportFunctions';
import colorWheel from '../../Theme/ColorWheel';
import CustomIcon, { CustomIconType } from '../../components/shared/AntComponents/CustomIcon';
import { ActionButton } from '../../components/shared/Buttons';
import ImageContainer from '../../components/shared/AntComponents/ImageContainer';

const plumPurple: React.CSSProperties = {
   filter: 'invert(19%) sepia(43%) saturate(2750%) hue-rotate(270deg) brightness(92%) contrast(94%)'
}

const RecentlyViewedReports: React.FC = () => {
   const { recentReports, removeAllReports, removeReport } = useRecentlyViewedReports();

   const html = (<>
      {recentReports?.length > 0 &&

         <div style={{ paddingLeft: '20px' }} className="menu-item-inner">
            <CustomIcon style={{ fontSize: 16, color: colorWheel.neutral3 }} type={CustomIconType.ContainerOutlined} />

            <span className="menu-title" style={{ paddingRight: '60px' }}>Recent Reports</span>

            <ActionButton
               onClick={removeAllReports}
               displayNoText
               icon={<CustomIcon type={CustomIconType.DeleteFilled} />}
               title={'Remove all Recently Viewed Reports'}
            />

         </div>
      }

      {recentReports?.length > 0 && recentReports.map(report => {

         return (
            <li key={report.id} style={{
               minHeight: '40px',
               paddingLeft: '25px',
               paddingBottom: '2px',
               borderBottom: `solid 1px ${colorWheel.neutral3}`,
               marginRight: '5px'
            }}>
               <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div style={{ position: 'relative', width: '50px', margin: 'auto' }}>
                     <ImageContainer
                        iconName={'arrow-turn-down-right'}
                        styles={plumPurple}
                     />
                  </div>

                  {/* The Link and Certified/Uncertified icon is borrowed from ReportMenuItem with styles adjusted for this smaller display */}
                  <div style={{
                     paddingLeft: '15px',
                     width: 'calc(50vw - 30px)',
                     margin: 'auto',
                  }}>
                     <Link
                        to={buildReportUrl(
                           report.isCertified ? View_Browse_Certified : View_Browse_Uncertified,
                           undefined,
                           undefined,
                           report.id)
                        }
                        title={report.reportDescription}
                     >
                        <span >
                           {report.reportPageName}
                        </span>
                     </Link>
                  </div>

                  <div >
                     <ActionButton
                        icon={
                           <CustomIcon
                              style={{ fontSize: '100%' }}
                              type={CustomIconType.DeleteOutlined}
                           />
                        }
                        displayNoText
                        onClick={() => removeReport(report)}
                        title={'Remove Report from Recently Viewed'}
                     />
                  </div>
               </div>
            </li>
         )
      })}
   </>);

   return html;
}

export default RecentlyViewedReports;