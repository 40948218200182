import useSWR from 'swr'; 
import { HttpError, TGet } from '../../functions/httpClient';
import { IStatus } from '../models';
import { ProgramSummary } from './ProgramSummaryModel';

export const programSummaryBaseUrl = `api/cohort/programSummary`;
export const programSummaryGetUrl = (cohortId: number) => `${programSummaryBaseUrl}/${cohortId}`;

const swrOptions = {
   refreshInterval: 0,
   revalidateOnFocus: false,
   dedupingInterval: 60000
};

export function useFetchProgramSummary(httpGet: TGet, cohortId: number): IStatus & { results: ProgramSummary[] } {

   const shouldFetch = cohortId ? true : false;
   const { data, isValidating, error } = useSWR<ProgramSummary[], HttpError>(
      shouldFetch ? programSummaryGetUrl(cohortId) : null,
      httpGet,
      { ...swrOptions }
   );

   return {
      results: data,
      isLoading: !error && !data && isValidating,
      error: error?.message
   };
}
