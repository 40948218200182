import { Space, TableProps } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { SorterResult } from 'antd/lib/table/interface';
import * as React from 'react';
import { boolComparer } from '../../functions/comparer.functions';
import { formatBoolean, formatDateTimeString } from '../../functions/format.functions';
import { IOptionItem, yesNo } from '../../functions/option.functions';
import { formatDate, isWithinDaysAgo } from '../../functions/time.functions';
import { AlignItemsCenter } from '../../layouts/AlignItems';
import { useApiContext } from '../../store/ApiContext';
import { useErrorContext } from '../../store/ErrorContext';
import {
   searchFaxesUrl, useFaxLogSearch,
   useFetchStatusValues, useFetchResultValues
} from '../../store/fax/FaxLogFetcher';
import { FaxLogSearchFilter, FaxLogViewModel } from '../../store/fax/FaxLogModels';
import { usePracticeOptions } from '../../store/practice/PracticeFetcher';
import ApiErrorDisplay from '../ApiErrorDisplay';
import HighlightSearchText from '../HighlightSearchText';
import CustomIcon, { CustomIconType } from '../shared/AntComponents/CustomIcon';
import ColumnFilter from '../shared/AntComponents/Filter/ColumnFilter';
import { IFilteredInfo } from '../shared/AntComponents/Filter/FilteredInfo';
import SearchResultsTable from '../shared/AntComponents/Table/SearchResultsTable';
import { antSortOptions } from '../shared/AntComponents/Table/table.functions';
import { BasicDatePickerField, BasicDropdownField, DebouncedBasicInputField } from '../shared/BasicInputLibrary';
import { IconButton } from '../shared/Buttons';
import FaxAuditLogRow from './FaxAuditLogRow';
import FaxPreview from './FaxPreview';
import FaxResend from './FaxResend';
import FaxStatusUpdate from './FaxStatusUpdate';
import ReviewFax from './ReviewFax';
import MultiSelectColumnFilter from '../shared/AntComponents/Filter/MultiSelectColumnFilter';

const initSearchFilter: FaxLogSearchFilter = {
   page: 1,
   pageSize: 25,
   sortDirection: 'desc',
   sortField: 'id'
}

type searchFilter = 'id'
   | 'statusValues'
   | 'resultValues'
   | 'externalFaxId'
   | 'faxToNumber'
   | 'fromSentDate'
   | 'toSentDate'
   | 'lastStatusUpdate'
   | 'practiceId'
   | 'patientId'
   | 'statusUpdateAttempts'
   | 'userFullName'
   | 'reviewed'
   | 'refreshTick'
   ;

const defaultFilterValue: Record<searchFilter, IFilteredInfo> = {
   id: undefined,
   statusValues: undefined,
   resultValues: undefined,
   externalFaxId: undefined,
   faxToNumber: undefined,
   fromSentDate: { title: 'Sent From', value: new Date() },
   toSentDate: { title: 'Sent To', value: new Date() },
   lastStatusUpdate: undefined,
   practiceId: undefined,
   patientId: undefined,
   statusUpdateAttempts: undefined,
   userFullName: undefined,
   reviewed: undefined,
   refreshTick: undefined
};

const attemptOptions: IOptionItem[] = [
   { label: '0', value: 0 }
   , { label: '1', value: 1 }
   , { label: '2', value: 2 }
   , { label: '3', value: 3 }
   , { label: '4', value: 4 }
   , { label: '5', value: 5 }
   , { label: '>5', value: 6 }
];

const _keys: string[] = [searchFaxesUrl];

const FaxIndex: React.FC = () => {
   const { httpGet, httpPost } = useApiContext();
   const { removeErrors } = useErrorContext();

   const [filteredInfo, setFilteredInfo] = React.useState<Record<searchFilter, IFilteredInfo>>(defaultFilterValue);
   const [searchFilter, setSearchFilter] = React.useState<FaxLogSearchFilter>(initSearchFilter);
   const { pagedResult, isLoading, refreshDate } = useFaxLogSearch(httpPost, searchFilter);
   const { practiceOptions } = usePracticeOptions(httpGet);
   const [isPreviewOpen, setIsPreviewOpen] = React.useState(false);
   const [isStatusUpdateOpen, setIsStatusUpdateOpen] = React.useState(false);
   const [isReviewFaxOpen, setIsReviewFaxOpen] = React.useState(false);
   const [isResendFaxOpen, setIsResendFaxOpen] = React.useState(false);
   const [selectedFax, setSelectedFax] = React.useState<FaxLogViewModel>();

   const { faxStatusList } = useFetchStatusValues(httpGet);
   const { faxResultList } = useFetchResultValues(httpGet);

   React.useEffect(() => {
      removeErrors({ keys: _keys });
      // for "removeErrors"
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []) // clear errors on intial render of the page

   React.useEffect(() => {
      const reviewed = filteredInfo.reviewed?.value as string;
      const newSearchFilter = {
         ...searchFilter,
         id: filteredInfo.id?.value as number,
         statusValues: filteredInfo.statusValues?.values.map(y => y.value as string),
         resultValues: filteredInfo.resultValues?.values.map(y => y.value as string),
         externalFaxId: filteredInfo.externalFaxId?.value as string,
         faxToNumber: filteredInfo.faxToNumber?.value as string,
         fromSentDate: filteredInfo.fromSentDate?.value as Date,
         toSentDate: filteredInfo.toSentDate?.value as Date,
         lastStatusUpdate: filteredInfo.lastStatusUpdate?.value as Date,
         practiceId: filteredInfo.practiceId?.value as number,
         patientId: filteredInfo.patientId?.value as string,
         statusUpdateAttempts: filteredInfo.statusUpdateAttempts?.value as number,
         userFullName: filteredInfo.userFullName?.value as string,
         reviewed: reviewed?.toLowerCase() === 'yes' ? true :
            reviewed?.toLowerCase() === 'no' ? false : undefined,
         page: 1
      }

      setSearchFilter(newSearchFilter);

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [filteredInfo]);

   const handleOnChange: TableProps<FaxLogViewModel>['onChange'] = (pagination, filters, sorter, extra) => {
      const newSearchFilter = {
         ...searchFilter,
         page: pagination?.current
      }
      const theSorter: SorterResult<FaxLogViewModel> = Array.isArray(sorter) ? sorter[0] : sorter;
      if (sorter && sorter.hasOwnProperty('field')) {
         newSearchFilter.sortField = theSorter.field as string;
         newSearchFilter.sortDirection = theSorter.order?.indexOf('desc') > -1 ? 'desc' : 'asc';
      }

      setSearchFilter(newSearchFilter);
   }

   const openPreview = async (faxLog: FaxLogViewModel) => {
      setIsPreviewOpen(true);
      setSelectedFax(faxLog);
   }

   const closePreview = async () => {
      setIsPreviewOpen(false);
      setSelectedFax(undefined);
   }

   const openStatusUpdate = async (faxLog: FaxLogViewModel) => {
      setIsStatusUpdateOpen(true);
      setSelectedFax(faxLog);
   }

   const closeStatusUpdate = async () => {
      setIsStatusUpdateOpen(false);
      setSelectedFax(undefined);
   }

   const openReviewFax = async (faxLog: FaxLogViewModel) => {
      setIsReviewFaxOpen(true);
      setSelectedFax(faxLog);
   }

   const closeReviewFax = async () => {
      setIsReviewFaxOpen(false);
      setSelectedFax(undefined);
   }

   const openResendFax = async (faxLog: FaxLogViewModel) => {
      setIsResendFaxOpen(true);
      setSelectedFax(faxLog);
   }

   const closeResendFax = async () => {
      setIsResendFaxOpen(false);
      setSelectedFax(undefined);
   }
   
   const statusFilters = React.useMemo(() => {
      return faxStatusList?.map((item): IOptionItem => ({
         label: item,
         value: item,
      } as IOptionItem))
   }, [faxStatusList])

   const resultFilters = React.useMemo(() => {
      return faxResultList?.map((item): IOptionItem => ({
         label: item,
         value: item,
      } as IOptionItem))
   }, [faxResultList])

   const columns: ColumnsType<FaxLogViewModel> = [
      {
         title: <ColumnFilter
            title='Id'
            filteredInfo={filteredInfo?.id}
            content={
               <>
                  <DebouncedBasicInputField
                     label='FaxLog Id'
                     placeholder='FaxLog Id'
                     value={filteredInfo?.id?.value as number}
                     onChange={(e) => {
                        setFilteredInfo({
                           ...filteredInfo,
                           id: {
                              title: 'id',
                              value: e ? e as number : undefined
                           }
                        })
                     }} />
               </>
            }
         />,
         width: '5rem',
         dataIndex: 'id',
         sorter: true,
         sortDirections: antSortOptions,
         defaultSortOrder: 'descend',
         render: (text, record) => <HighlightSearchText searchString={filteredInfo?.id?.value as string} targetString={record.id.toString()} />
      },
      {
         title: <ColumnFilter
            title='Status'
            filteredInfo={filteredInfo?.statusValues}
            content={
               <MultiSelectColumnFilter
                  options={statusFilters ?? []}
                  searchPlaceholder={'Filter by Status'}
                  allowSelectAll={true}
                  searchable={false}
                  filteredInfo={filteredInfo}
                  setFilteredInfo={setFilteredInfo}
                  filteredInfoKey={'statusValues'}
                  filteredInfoTitle='Status'
               />
            }
         />,
         dataIndex: 'status',
         sorter: true,
         sortDirections: antSortOptions,
         render: (text, record) => {
            const searchString = record.status && filteredInfo.statusValues?.values?.length > 0 ?
               (filteredInfo.statusValues.values).find(str => (str.value as string).toLowerCase().includes(record.status?.toLowerCase()))?.value as string : '';
            return <HighlightSearchText searchString={searchString} targetString={record.status} />
         }
      },
      {
         title: <ColumnFilter
            title='Sent On'
            filteredInfo={filteredInfo?.fromSentDate}
            content={
               <>
                  <BasicDatePickerField
                     containerStyle={{ width: 150 }}
                     label='Sent On From Date'
                     value={filteredInfo?.fromSentDate?.value as Date}
                     clearable={true}
                     onChange={(e) => {
                        setFilteredInfo({
                           ...filteredInfo,
                           fromSentDate: {
                              title: 'From Date',
                              value: e ? e as unknown as Date : undefined
                           }
                        })
                     }} />
                  <BasicDatePickerField
                     containerStyle={{ width: 150 }}
                     label='Sent On To Date'
                     value={filteredInfo?.toSentDate?.value as Date}
                     clearable={true}
                     onChange={(e) => {
                        setFilteredInfo({
                           ...filteredInfo,
                           toSentDate: {
                              title: 'To Date',
                              value: e ? e as unknown as Date : undefined
                           }
                        })
                     }} />
               </>
            }
         />,
         dataIndex: 'sentDate',
         sorter: true,
         sortDirections: antSortOptions,
         render: (text, record) => formatDate(record.sentDate, 'MM/DD/YYYY hh:mm:ss Z')
      },
      {
         title: <ColumnFilter
            title='Practice'
            filteredInfo={filteredInfo?.practiceId}
            content={
               <>
                  <BasicDropdownField
                     containerStyle={{ width: 300 }}
                     name='practiceId'
                     value={filteredInfo?.practiceId as unknown as number}
                     label='Practice'
                     multiple={false}
                     options={practiceOptions}
                     required={false}
                     search={true}
                     clearable={true}
                     onChange={(e) => {
                        setFilteredInfo({
                           ...filteredInfo,
                           practiceId: {
                              title: 'practiceId',
                              value: e ? e as unknown as number : undefined
                           }
                        })
                     }}
                  />
               </>
            }
         />,
         dataIndex: 'practiceId',
         sorter: true,
         sortDirections: antSortOptions,
         render: (text, record) => {
            const practiceId = filteredInfo?.practiceId?.value as number;
            const targetString = `[${record.practiceId}] ${record.practice}`;
            const searchString = practiceId && record.practiceId ? targetString : undefined;

            return <HighlightSearchText searchString={searchString} targetString={targetString} />
         }
      },
      {
         title: <ColumnFilter
            title='User'
            filteredInfo={filteredInfo?.userFullName}
            content={
               <>
                  <DebouncedBasicInputField
                     label='Filter by User'
                     placeholder='Filter by User'
                     value={filteredInfo?.userFullName?.value as string}
                     onChange={(e) => {
                        setFilteredInfo({
                           ...filteredInfo,
                           userFullName: {
                              title: 'userFullName',
                              value: e ? e as string : undefined
                           }
                        })
                     }} />
               </>
            }
         />,
         dataIndex: 'userFullName',
         sorter: true,
         sortDirections: antSortOptions,
         render: (text, record) => {
            return <HighlightSearchText searchString={filteredInfo?.userFullName?.value as string} targetString={record.userFullName} />
         }
      },
      {
         title: <ColumnFilter
            title='PatientId'
            filteredInfo={filteredInfo.patientId}
            content={
               <>
                  <DebouncedBasicInputField
                     label='Filter by PatientId'
                     placeholder='Filter by PatientId'
                     value={filteredInfo.patientId?.value as string}
                     onChange={(e) => {
                        setFilteredInfo({
                           ...filteredInfo,
                           patientId: {
                              title: 'PatientId',
                              value: e ? e as string : undefined
                           }
                        })
                     }} />
               </>
            }
         />,
         dataIndex: 'patientId',
         sorter: true,
         sortDirections: antSortOptions,
         render: (text, record) => {
            return <HighlightSearchText searchString={filteredInfo?.patientId?.value as string} targetString={record.patientId} />
         }
      },
      {
         title: <ColumnFilter
            title='Recipient Fax #'
            filteredInfo={filteredInfo.faxToNumber}
            content={
               <>
                  <DebouncedBasicInputField
                     label='Filter by Recipient Fax #'
                     placeholder='Filter by Recipient Fax #'
                     value={filteredInfo.faxToNumber?.value as string}
                     onChange={(e) => {
                        setFilteredInfo({
                           ...filteredInfo,
                           faxToNumber: {
                              title: 'Fax To Number',
                              value: e ? e as string : undefined
                           }
                        })
                     }} />
               </>
            }
         />,
         width: '10.5rem',
         dataIndex: 'faxToNumber',
         sorter: true,
         sortDirections: antSortOptions,
         render: (text, record) => {
            return <HighlightSearchText searchString={filteredInfo.faxToNumber?.value as string} targetString={record.faxToNumber} />
         }
      },
      {
         title: <ColumnFilter
            title='Attempts'
            filteredInfo={filteredInfo?.statusUpdateAttempts}
            content={
               <>
                  <BasicDropdownField
                     containerStyle={{ width: 300 }}
                     name='statusUpdateAttempts'
                     value={filteredInfo?.statusUpdateAttempts as unknown as number}
                     label='Attempts'
                     multiple={false}
                     options={attemptOptions}
                     required={false}
                     search={false}
                     clearable={true}
                     onChange={(e) => {
                        setFilteredInfo({
                           ...filteredInfo,
                           statusUpdateAttempts: {
                              title: 'statusUpdateAttempts',
                              value: e ? e as unknown as number : undefined
                           }
                        })
                     }}
                  />
               </>
            }
         />,
         dataIndex: 'statusUpdateAttempts',
         sorter: true,
         width: '8rem',
         sortDirections: antSortOptions,
         render: (text, record) =>
            <AlignItemsCenter>
               <span>{record.statusUpdateAttempts}</span>
            </AlignItemsCenter>
      },
      {
         title: <ColumnFilter
            title='Last Status Update'
            filteredInfo={filteredInfo?.lastStatusUpdate}
            content={
               <>
                  <BasicDatePickerField
                     containerStyle={{ width: 300 }}
                     label='Filter by Last Status Update'
                     value={filteredInfo?.lastStatusUpdate?.value as Date}
                     clearable={true}
                     onChange={(e) => {
                        setFilteredInfo({
                           ...filteredInfo,
                           lastStatusUpdate: {
                              title: 'LastStatusUpdate',
                              value: e ? e as unknown as Date : undefined
                           }
                        })
                     }} />
               </>
            }
         />,
         dataIndex: 'lastStatusUpdate',
         sorter: true,
         sortDirections: antSortOptions,
         render: (text, record) => formatDate(record.lastStatusUpdate, 'MM/DD/YYYY hh:mm:ss Z')
      },
      {
         title: <ColumnFilter
            title='result'
            filteredInfo={filteredInfo?.resultValues}
            content={
                <MultiSelectColumnFilter
                  options={resultFilters ?? []}
                  searchPlaceholder={'Filter by Result'}
                  allowSelectAll={true}
                  searchable={false}
                  filteredInfo={filteredInfo}
                  setFilteredInfo={setFilteredInfo}
                  filteredInfoKey={'resultValues'}
                  filteredInfoTitle='Result'
               />
            }
         />,
         dataIndex: 'result',
         sorter: true,
         sortDirections: antSortOptions,
         render: (text, record) => {
            const searchString = record.result && filteredInfo.resultValues?.values?.length > 0 ?
               (filteredInfo.resultValues.values).find(str => (str.value as string).toLowerCase().includes(record.result?.toLowerCase()))?.value as string : '';

            return <HighlightSearchText searchString={searchString} targetString={record.result} />
         }
      },
      {
         title: <ColumnFilter
            title='ExternalFax Id'
            filteredInfo={filteredInfo?.externalFaxId}
            content={
               <>
                  <DebouncedBasicInputField
                     label='Filter by External Fax Id'
                     placeholder='Filter by External Fax Id'
                     value={filteredInfo?.externalFaxId?.value as string}
                     onChange={(e) => {
                        setFilteredInfo({
                           ...filteredInfo,
                           externalFaxId: {
                              title: 'ExternalFaxId',
                              value: e ? e as string : undefined
                           }
                        })
                     }} />
               </>
            }
         />,
         dataIndex: 'externalFaxId',
         sorter: true,
         sortDirections: antSortOptions,
         render: (text, record) => {
            return <HighlightSearchText searchString={filteredInfo?.externalFaxId?.value as string} targetString={record.externalFaxId} />
         }
      },
      {
         dataIndex: 'reviewed',
         title: <ColumnFilter
            title='Reviewed'
            filteredInfo={filteredInfo?.reviewed}
            content={
               <BasicDropdownField
                  containerStyle={{ width: 300 }}
                  name='reviewed'
                  value={filteredInfo?.reviewed as unknown as string}
                  label='Reviewed'
                  multiple={false}
                  options={yesNo}
                  required={false}
                  search={false}
                  clearable={true}
                  onChange={(e) => {
                     setFilteredInfo({
                        ...filteredInfo,
                        reviewed: {
                           title: 'reviewed',
                           value: e as unknown as string
                        }
                     })
                  }}
               />
            }
         />,
         width: '8rem',
         sorter: (a, b) => boolComparer(a.reviewed, b.reviewed),
         sortDirections: antSortOptions,
         render: (text, record) => {
            return <span title={record.notes}> {formatBoolean(record.reviewed)} </span>
         }
      },
      {
         fixed: 'right',
         width: '8rem',
         dataIndex: 'actions',
         render: (text, record) =>
            <AlignItemsCenter>
               <Space>
                  {record.externalFaxId && record.externalFaxId?.toLowerCase() !== 'unknown' &&
                     <>
                        <IconButton
                           style={{ border: 'none' }}
                           displayNoText={false}
                           title='View Fax Document'
                           onClick={async () => await openPreview(record)}
                           icon={<CustomIcon type={CustomIconType.FilePdfOutlined} />} />
                        <IconButton
                           style={{ border: 'none' }}
                           title='Check Status'
                           onClick={async () => await openStatusUpdate(record)}
                           icon={<CustomIcon type={CustomIconType.RedoOutlined} />} />
                     </>
                  }
                  {
                     record.externalFaxId && record.externalFaxId?.toLowerCase() === 'unknown' && isWithinDaysAgo(record.sentDate, 5) &&
                     <IconButton
                        style={{ border: 'none' }}
                        displayNoText={false}
                        title='Resend Fax'
                        onClick={async () => await openResendFax(record)}
                        icon={<CustomIcon type={CustomIconType.PrinterOutlined} />} />
                  }
                  <IconButton
                     style={{ border: 'none' }}
                     title='Review'
                     onClick={async () => await openReviewFax(record)}
                     icon={<CustomIcon type={CustomIconType.EditOutlined} />} />
               </Space>
            </AlignItemsCenter >
      }
   ];
   return (
      <>
         <ApiErrorDisplay title='Api Error' keys={_keys} />

         <div style={{ margin: '0.5rem' }}>
            <p>Last Checked: <u><b>{formatDateTimeString(refreshDate)}</b></u>
               <IconButton
                  title='Refresh Fax List'
                  icon={<CustomIcon type={CustomIconType.ReloadOutlined} />}
                  disabled={isLoading}
                  onClick={() => {
                     setSearchFilter({ ...searchFilter, refreshTick: new Date().getTime() });
                  }}
               /> </p>
         </div>

         <SearchResultsTable
            rowkey={'id'}
            onChange={handleOnChange}
            columns={columns}
            data={pagedResult?.data}
            serverSideRowCount={pagedResult?.totalRecords}
            currentPage={searchFilter.page}
            currentPageSize={searchFilter?.pageSize}
            setCurrentPageSize={newPageSize => {
               setSearchFilter({
                  ...searchFilter,
                  page: 1, //reset the page to keep us on the page
                  pageSize: Number(newPageSize)
               })
            }}
            titleText='Sent Faxes'
            fixedHeader={true}
            scrollX="150rem"
            scrollY='calc(100vh - 275px)'
            size='small'
            expandedRowRender={(record: FaxLogViewModel) => <FaxAuditLogRow faxLogViewModel={record} />}
            loading={isLoading}

         />

         {isPreviewOpen &&
            <FaxPreview
               isOpen={isPreviewOpen}
               faxLog={selectedFax}
               close={closePreview} />
         }

         {isStatusUpdateOpen &&
            <FaxStatusUpdate
               isOpen={isStatusUpdateOpen}
               faxLog={selectedFax}
               close={closeStatusUpdate} />
         }
         {isReviewFaxOpen &&
            <ReviewFax
               isOpen={isReviewFaxOpen}
               faxLog={selectedFax}
               close={closeReviewFax} />
         }
         {isResendFaxOpen &&
            <FaxResend
               isOpen={isResendFaxOpen}
               faxLog={selectedFax}
               close={closeResendFax} />
         }
      </>
   );
}

export default FaxIndex;