import * as React from 'react';
import { useApiContext } from '../../store/ApiContext';
import { useFetchPracticeLogo } from '../../store/practice/PracticeFetcher';
import { ImageMetadata } from '../../store/ImageMetadataModel';
import Default_Practice_Logo from '../../images/Default_Practice_Logo.png';


interface IProps {
   practiceId: number;
}

const PracticeLogo: React.FC<IProps> = (props) => {
   const { practiceId } = props;

   const { httpGet } = useApiContext();
   const [practiceLogo, setPracticeLogo] = React.useState<ImageMetadata>(undefined);
   const { logoData } = useFetchPracticeLogo(httpGet, practiceId);

   React.useEffect(() => {
      if (!logoData) return;

      setPracticeLogo(logoData);
   }, [logoData, practiceId]);


   const buildImageSource = (): string => {
      if (!practiceLogo || !practiceLogo?.data) {
         return Default_Practice_Logo;
      }
      return `data:${practiceLogo.contentType};base64,${practiceLogo.data}`;
   }

   return <img alt="Practice Logo" title="Practice Logo" src={buildImageSource()} className='header-funding-img' />
}

export default PracticeLogo;

