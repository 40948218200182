import useSWR, { mutate } from "swr";
import { HttpError, TGet, TPost }  from "../../functions/httpClient";
import { userMainBaseUrl } from "./UserMainFetcher";
import { UserMigrationViewModel, UserMigration } from './UserMigrationModel';
import { CreateUserStatus } from "./UserModel";

export const userMigrationBaseUrl = 'api/auth/usermigration';
export const getUserMigrationsUrl = `${userMigrationBaseUrl}/GetMigrations`
export const migrateUsersUrl = (practiceId: number) => `${userMigrationBaseUrl}/MigrateUsers/${practiceId}`;
export const upnMigrateUrl = `${userMigrationBaseUrl}/MigrateUserWithUpnEdit`;


const swrOptions = {
   refreshInterval: 0,
   revalidateOnFocus: false,
   dedupingInterval: 60000
};

export const useFetchUserMigrations = (httpGet: TGet) => {
   const { data, isValidating, error } = useSWR<UserMigration[], HttpError>(
      getUserMigrationsUrl,
      httpGet,
      { ...swrOptions }
   );

   return {
      userMigrationList: data,
      isLoading: isValidating,
      error: error
   };
}

export const migrateUser = async (httpPost: TPost, practiceId: number, userMainId: number): Promise<CreateUserStatus> => {
   let statusRecords: CreateUserStatus;

   await httpPost<CreateUserStatus>(
      migrateUsersUrl(practiceId),
      { userMainId: userMainId }
   )
      .then((response) => {
         mutate(userMainBaseUrl);
         mutate(getUserMigrationsUrl);
         statusRecords = response;
      });

   return statusRecords;
}

export const migrateUserWithUpnEdit = async (httpPost: TPost, data: UserMigrationViewModel): Promise<CreateUserStatus> => {
   let statusRecord: CreateUserStatus;
   await httpPost<CreateUserStatus>(upnMigrateUrl, data)
      .then((response) => {
         mutate(userMainBaseUrl);
         mutate(getUserMigrationsUrl);
         statusRecord = response;
      });

   return statusRecord;
}