import { useMemo } from 'react';
import useSWR from 'swr';
import { HttpError, TGet, TPost } from '../../functions/httpClient';
import { CrxEnrollment, CrxEnrollmentsFilter, CrxEnrollmentPayload } from './CrxEnrollmentModels';


const ctrlBaseUrl = `api/external/CrxEnrollment`;
const getPayloadUrl = (id: number) => `${ctrlBaseUrl}/GetPayload/${id}`;
const getFilteredEnrollments = `${ctrlBaseUrl}/GetEnrollments`;

const swrOptions = {
    refreshInterval: 0,
    revalidateOnFocus: false,
    dedupingInterval: 60000
};

export const useFetchCrxEnrollmentPayload = (httpGet: TGet, id: number) => {
    const { data, isValidating, error } = useSWR<CrxEnrollmentPayload, HttpError>(
        getPayloadUrl(id),
        httpGet,
        { ...swrOptions }
    );

    return {
        payload: data,
        isLoading: !error && !data && isValidating,
        error: error
    };
}


/* AH-3882 - The idea is that by sending the dates to the server to be filtered at the DB, we'll reduce the burden on client machines
 * by reducing the overall size of the data that the client will need to work with, but by keeping the afsNumber and Practice (see CrxIndex)
 * we won't introduce a large amount of API requests either
 */
export const useCrxEnrollments = (httpPost: TPost, filter: CrxEnrollmentsFilter) => {
    const params = useMemo(() => {
        return filter;
    }, [filter]);

   const fetcher = () => httpPost<CrxEnrollment[]>(getFilteredEnrollments, params);

    const { data, isValidating, error } = useSWR<CrxEnrollment[], HttpError>(
        [getFilteredEnrollments, params],
       fetcher,
        { ...swrOptions }
    );

    return {
        items: data,
        isLoading: !error && !data && isValidating,
        error: error
    }
}
