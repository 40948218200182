import { Divider, Drawer, Space, Spin } from 'antd';
import * as React from 'react';
import { useNotificationContext } from './NotificationContext';
import { IFSAProcessingMessage, NotificationType, NotificationStatusType } from '../../store/notification/NotificationModel';
import CustomIcon, { CustomIconType } from '../shared/AntComponents/CustomIcon';
import { ActionButton } from '../shared/Buttons';
import {  BasicFieldDisplay } from '../shared/BasicInputLibrary';
import { ColumnsType } from 'antd/lib/table';
import MinimalistTable from '../shared/AntComponents/Table/MinimalistTable';
import { formatDateTimeString } from '../../functions/format.functions';
import colorWheel from '../../Theme/ColorWheel';
import { dateComparer } from '../../functions/comparer.functions';
import { Link } from 'react-router-dom';

interface IProps {
}

const NotificationIndex: React.FC<IProps> = (props) => {
   //const [text, setText] = React.useState<string>(); // was used for test/proof-of-concept 

   const {
      notificationDrawerOpen, setNotificationDrawerOpen,
      mergeNotification, removeNotification,
      notifications
   } = useNotificationContext();

   React.useEffect(() => {

   })

   React.useEffect(() => {
      const acknowledgeNotifications = () => {
         notifications.filter(y => !y.acknowledged).forEach(n => {
            mergeNotification({
               acknowledged: true,
               ...n
            });
         });
      }
      if (notifications?.some(y => !y.acknowledged && notificationDrawerOpen)) {
         acknowledgeNotifications();
      }
   }, [notificationDrawerOpen, notifications])

   const onClose = () => {
      setNotificationDrawerOpen(false);
   };

   const renderTitleLink = (record: IFSAProcessingMessage) => {
      if (record.notificationType === NotificationType.FSAProcessingMessage) {
         return <Link
            to={{ pathname: `/fsa` }}
            title={`Navigate to Fund Status Alerts`}
            onClick={() => setNotificationDrawerOpen(false)}
         >
            {record.title}
         </Link>
      } else {
         return <>{record.title}</>;
      }
   }

   const columns: ColumnsType<IFSAProcessingMessage> = [
      {
         title: 'Title',
         dataIndex: 'title',
         key: 'title',
         render: (text, record) => renderTitleLink(record)
      },
      {
         title: 'Timestamp',
         dataIndex: 'timestamp',
         key: 'timestamp',
         render: ((text, record) => formatDateTimeString(record.timestamp))
      },
      {
         title: 'Status',
         dataIndex: 'status',
         key: 'status',
         render: (text, record) => {
            return <Space>
               {record.status?.toString() === NotificationStatusType[NotificationStatusType.Error] && <CustomIcon type={CustomIconType.WarningOutlined} style={{ color: colorWheel.red }} />}
               {record.status?.toString() === NotificationStatusType[NotificationStatusType.Success] && <CustomIcon type={CustomIconType.CheckOutlined} style={{ color: colorWheel.ivyGreen }} />}
               {record.status?.toString() === NotificationStatusType[NotificationStatusType.Running] && <Spin size={'small'} />}
               {record.status?.toString() === NotificationStatusType[NotificationStatusType.Starting] && <Spin size={'small'} />}
               {record.status ?? <>No Status</>}
            </Space>
         }

      },
      {
         fixed: 'right',
         width: 60,
         render: (text, record) => {
            return <ActionButton
                  onClick={() => {
                     removeNotification(record.key);
                  }}
                  icon={<CustomIcon type={CustomIconType.DeleteOutlined} />}
                  title={'Delete'}
               />
         }
      }
   ];

   const html = (
      <Drawer
         closable={false}
         title='Notifications'
         placement='left'
         size='large'
         onClose={onClose}
         open={notificationDrawerOpen}

         extra={
            <ActionButton buttonText='Close'
               icon={<CustomIcon type={CustomIconType.CloseOutlined} />}
               onClick={onClose}
               title='Close' />
         }
      >
         <MinimalistTable
            rowKey='key'
            data={notifications.sort((a, b) => dateComparer(a.timestamp, b.timestamp))}
            columns={columns}
            size='small'
            showHeader={true}
            expandedRowRender={(record: IFSAProcessingMessage) => <NotificationExpand
               message={record?.message}
               key={`expand_${record.uId}`}
            />}
         />
         <Divider />
         {/* Everything beneath this was for testing and is to be removed when this is plumbed in with actual data 
         <BasicInputField
            value={text}
            onChange={(val) => setText(val as string)}
         />
         <ActionButton buttonText='Add Notification'
            icon={<CustomIcon type={CustomIconType.PlusOutlined} />}
            onClick={() => {
               const newMessage: FundStatusFieldChange = {
                  key: `${(new Date()).getTime()}`,
                  acknowledged: false,
                  message: text,
                  title: text,
                  timestamp: new Date(),
                  id: (new Date()).getTime(),
                  uId: '2A6779E5-F992-451A-A9CB-855B571E92C1',
                  status: 'Running'
               };
               console.log('new message', newMessage);
               mergeNotification(newMessage);
            }} /> */}
      </Drawer>
   );

   return html;
}

export default NotificationIndex;

const NotificationExpand: React.FC<{ message: string }> = (props) => {
   const { message } = props;

   return (
      <BasicFieldDisplay field={message ?? '[No message to view]'} />
   );
}

