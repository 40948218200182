import * as React from 'react';
import HighlightSearchText from '../HighlightSearchText';
import { useFetchDiseaseTypes, saveDiseaseType, deleteDiseaseType, diseaseTypeSaveUrl, diseaseTypeDeleteUrl } from '../../store/program/DiseaseTypeFetcher';
import { Diagnosis } from '../../store/program/DiagnosisModel';
import Dialog from '../Dialog';
import { SmallErrorMessage } from '../shared/ErrorMessage';
import { SaveButton, CancelButton, DeleteButton, ActionButton } from '../shared/Buttons';
import { DiseaseToDiagnosisMap } from '../../store/program/DiseaseTypeModel';
import { List, Row, Col } from 'antd';
import CustomIcon, { CustomIconType } from '../shared/AntComponents/CustomIcon'
import { BasicInputField } from '../shared/BasicInputLibrary';
import { NumberedTitle } from '../shared/AntComponents/Typography/Title';
import { useApiContext } from '../../store/ApiContext';
import { useFetchDiagnosisCodes } from '../../store/program/DiagnosisFetcher';
import ApiErrorDisplay from '../ApiErrorDisplay';
import { useErrorContext } from '../../store/ErrorContext';

interface IProps {
   diseaseType: DiseaseToDiagnosisMap;
   isOpen: boolean;
   closeEditor: () => void;
};

const dialogContentStyle = {
   height: '580px'
}


const DiseaseTypeEditor: React.FC<IProps> = (props) => {
   const { closeEditor, diseaseType, isOpen } = props;
   const _keys: string[] = diseaseType ? [diseaseTypeSaveUrl, diseaseTypeDeleteUrl(diseaseType.diseaseTypeId)] : [diseaseTypeSaveUrl];

   const { httpGet, httpPost, httpDelete } = useApiContext();
   const { removeErrors } = useErrorContext();
   const { diseaseTypes } = useFetchDiseaseTypes(httpGet);
   const { diagnosisCodes } = useFetchDiagnosisCodes(httpGet);
   const [diseaseTypeName, setDiseaseTypeName] = React.useState<string>('');
   const [diagnoses, setDiagnoses] = React.useState<Diagnosis[]>([]);
   const [filteredDiagnosisCodes, setFilteredDiagnosisCodes] = React.useState<Diagnosis[]>([]);

   //Search Bar
   const [searchText, setSearchText] = React.useState('');
   const filterFunction = (d: Diagnosis) => {
      if (searchText === '') {
         return true;
      }
      else {
         return d.code.toLowerCase().includes(searchText.toLowerCase()) || d.description.toLowerCase().includes(searchText.toLowerCase());
      }
   }

   React.useEffect(() => {
      setFilteredDiagnosisCodes(
         diagnosisCodes?.filter(d => d.codeType !== 1) //AH-3841 - hide ICD-9 codes (codeType = 1) - implemented here as at this time, they're not meant to be hidden elsewhere
            .filter(filterFunction)
      );
      // No, filterFunction does not need to be in the dependency array
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [diagnosisCodes, searchText])

   //Add/RemoveDiagnoses
   const handleAddDiagnosisClick = (diagnosis: Diagnosis) => {
      if (diagnoses.findIndex(i => i.id === diagnosis.id) === -1) {
         setDiagnoses([...diagnoses, diagnosis]);
      }
   }

   const getIcon = (item: Diagnosis, list: Diagnosis[]) => {
      const isDiagnosisInList = list.findIndex(li => li.id === item.id) > -1;
      const customIconType = isDiagnosisInList ? CustomIconType.CheckOutlined : CustomIconType.PlusOutlined;
      const titleText = isDiagnosisInList ? '' : 'Add';

      return <ActionButton
         onClick={() => handleAddDiagnosisClick(item)}
         icon={<CustomIcon type={customIconType} />}
         disabled={isDiagnosisInList}
         title={titleText} />;
   }

   const handleRemoveDiagnosisClick = (id: number) => {
      var temp = diagnoses.filter(y => y.id !== id);
      setDiagnoses(temp);
   }

   const savedDiagnosesCount = React.useMemo(() => {
      return diseaseType?.diagnoses?.length ?? 0;
   }, [diseaseType]);

   React.useEffect(() => {
      setDiseaseTypeName(diseaseType?.diseaseTypeName);
      setDiagnoses(diseaseType?.diagnoses ? [...diseaseType.diagnoses] : []);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [diseaseType]);

   React.useEffect(() => {
      validateDiseaseType();

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [diseaseTypes,
      diagnosisCodes,
      diseaseType]);

   const validateDiseaseType = () => {
      setIsFormValid(diseaseTypeName && diseaseTypeName.length > 0);
   }

   const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState<boolean>(false);
   const [isFormSaving, setIsFormSaving] = React.useState<boolean>(false);
   const [isFormValid, setIsFormValid] = React.useState<boolean>(undefined);

   React.useEffect(() => {
      validateDiseaseType()
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [diseaseTypeName]);

   const handleSaveClick = () => {
      if (isFormValid) {
         setIsFormSaving(true);
         let editedDiseaseType: DiseaseToDiagnosisMap = { ...diseaseType };
         if (!editedDiseaseType) {
            editedDiseaseType = {} as DiseaseToDiagnosisMap;
         }
         editedDiseaseType.diseaseTypeName = diseaseTypeName;
         editedDiseaseType.diagnoses = diagnoses;
         saveDiseaseType(httpPost, editedDiseaseType)
            .then(handleClose)
            .catch(err => console.error('Error Saving Disease Type', err))
            .finally(() => {
               setIsFormSaving(false);
            });
      }
   }

   const handleDeleteClick = () => {
      setIsDeleteDialogOpen(false);
      deleteDiseaseType(httpDelete, diseaseType.diseaseTypeId)
         .then(handleClose)
         .catch(err => console.error('Error Deleting Disease Type', err))
   }

   const handleClose = () => {
      setDiseaseTypeName('');
      setDiagnoses([]);
      setIsDeleteDialogOpen(false);
      setSearchText('');
      setIsDeleteDialogOpen(false);
      setIsFormSaving(false);
      removeErrors({ keys: _keys });
      closeEditor();
   }

   const html = (
      <Dialog
         title={`Disease Type Editor`}
         open={isOpen}
         size="large"
         actionButtons={
            <>
               <CancelButton onClick={() => {
                  handleClose();
               }} />
               <DeleteButton onClick={() => setIsDeleteDialogOpen(true)} disabled={!diseaseType || !diseaseType.diseaseTypeId || savedDiagnosesCount > 0 || isDeleteDialogOpen} loading={isDeleteDialogOpen} />
               <SaveButton onClick={() => handleSaveClick()} disabled={!isFormValid} loading={isFormSaving} />
            </>
         }>
         <div style={dialogContentStyle} className='ap-listmap'>

            <ApiErrorDisplay
               title='Error saving Disease Type'
               keys={_keys} />

            <Row gutter={[32, 32]} justify="space-between" align="bottom" >
               <Col span={12}>
                  <BasicInputField
                     placeholder={`Search by ICD Code or Description`}
                     onChange={ev => { setSearchText(ev?.toString()) }}
                     value={searchText}
                     name='searchByCodeOrDescription'
                     type='text' />
               </Col>
               <Col span={12}>
                  <BasicInputField
                     placeholder={`Provide a Disease Type Name`}
                     onChange={ev => { setDiseaseTypeName(ev?.toString()) }}
                     value={diseaseTypeName}
                     name='diseaseTypeName'
                     type='text' />
               </Col>
            </Row>
            <Row gutter={[32, 32]} justify="space-between" align="bottom" >
               <Col span={12}>
                  {/*This Col is here to push the validation messages below under the Disease Type input field while keeping the ICD input in-line with the Disease Type field*/}
               </Col>
               <Col span={12}>
                  {!isFormValid &&
                     <SmallErrorMessage isTouched={true} errorText="Disease Type Name is required" />
                  }
               </Col>
            </Row>
            <Row gutter={[16, 16]} justify="end" align="middle" >
               <Col span={12}>
                  <div className='disease-type-editor-list' style={{ height: '550px', overflowY: 'auto' }}>
                     <List
                        size='small'
                        bordered
                        header={<NumberedTitle level={3} text='Available Diagnoses' />}
                        pagination={{
                           simple: true,
                           pageSize: 10
                        }}

                        dataSource={filteredDiagnosisCodes}
                        renderItem={record => (
                           <List.Item key={record.id} extra={getIcon(record, diagnoses)} >
                              <List.Item.Meta
                                 title={<HighlightSearchText searchString={searchText} targetString={record.code} />}
                                 description={<HighlightSearchText searchString={searchText} targetString={record.description} />}
                              />
                           </List.Item>
                        )}
                        style={{ height: '525px', overflowY: 'auto' }}
                     />
                  </div>
               </Col>
               <Col span={12}>
                  <div className='disease-type-editor-list' style={{ height: '550px', overflowY: 'auto' }}>
                     <List
                        size='small'
                        bordered
                        header={<NumberedTitle level={3} text='Associated Diagnoses' />}
                        pagination={{
                           simple: true,
                           pageSize: 10
                        }}
                        dataSource={diagnoses}
                        renderItem={record => (
                           <List.Item
                              extra={
                                 <ActionButton
                                    onClick={() => handleRemoveDiagnosisClick(record.id)}
                                    icon={<CustomIcon type={CustomIconType.DeleteFilled} />}
                                    title={'Delete'} />
                              }>
                              <List.Item.Meta
                                 title={<HighlightSearchText searchString={searchText} targetString={record.code} />}
                                 description={<HighlightSearchText searchString={searchText} targetString={record.description} />}
                              />
                           </List.Item>
                        )}
                     />
                  </div>
               </Col>
            </Row>
         </div>

         <Dialog
            title='CONFIRM DELETE'
            open={isDeleteDialogOpen}
            style={{ maxWidth: '550px' }}
            actionButtons={
               <>
                  <CancelButton onClick={() => setIsDeleteDialogOpen(false)} />
                  <DeleteButton
                     disabled={isFormSaving}
                     onClick={() => handleDeleteClick()} />
               </>
            }
         >
            {`Are you sure you want to DELETE the disease (${diseaseType?.diseaseTypeId}) ${diseaseType?.diseaseTypeName}?`}
         </Dialog>

      </Dialog>
   )
   return html;
}

export default DiseaseTypeEditor;
