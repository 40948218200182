import { Card, Col, Row, Space, Spin, Typography } from 'antd';
import * as React from 'react';
import { getDayjs, getFromNowDateString, isDateAfter } from 'src/functions/time.functions';
import { mutate } from 'swr';
import colorWheel from '../../../Theme/ColorWheel';
import { formatDateTimeString } from '../../../functions/format.functions';
import { useApiContext } from '../../../store/ApiContext';
import { HttpVerb, KeyWithVerb, useErrorContext } from '../../../store/ErrorContext';
import { useFetchSetting } from '../../../store/SettingsFetcher';
import { KnownSettings } from '../../../store/SettingsModel';
import {
   BatchStatusType,
   FundStatusRecentlySendFunds,
   FundStatusRecentlySentDetails,
   FundStatusRecentlySentEmail
} from '../../../store/fsa/FundingStatusEmailSentModel';
import { getRecentlySentEmailUrl, useGetRecentFundEmails } from '../../../store/fsa/RecentlySentFetcher';
import ApiErrorDisplay from '../../ApiErrorDisplay';
import CustomIcon, { CustomIconType } from '../../shared/AntComponents/CustomIcon';
import StatusTag from '../StatusTag';
import RecentlySentBatchList from './RecentlySentBatchList';

const _keysWithVerb: KeyWithVerb[] = [{ key: getRecentlySentEmailUrl, verb: HttpVerb.GET }];

const RecentlySentList: React.FC = () => {
   const { httpPost } = useApiContext();
   const { recentEmails } = useGetRecentFundEmails(httpPost);
   const { removeErrors } = useErrorContext();
   const [timerToggle, setTimerToggle] = React.useState(false);
   const [hasActiveTimer, setHasActiveTimer] = React.useState(false);
   const maxSendTimeString = useFetchSetting(KnownSettings.MaxAnticipatedFSASendTimeUISeconds)
   const maxSendTimeSeconds: number = +maxSendTimeString;

   const _timeoutRef_Events = React.useRef(null);

   React.useEffect(() => {
      removeErrors({ keysWithVerb: _keysWithVerb });
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   React.useEffect(() => {
      if (!recentEmails) return;

      /* Create a timer to fire when there are emails not finished
         The idea is to force a mutate on the collection based on the configurable maxAnticipatedSendTime
         so that even if SignalR or the WebJob are somehow dead, the UI can still try to reflect that something 
         "Finished" or "Errored" 
         Also, try not to create more than one timer so that we're not firing off too many requests / queries */
      if (recentEmails.some(y =>
         y.batchStatus !== BatchStatusType.Finished &&
         isDateAfter(getDayjs(y.sentOn).add(maxSendTimeSeconds, 's'), getDayjs(new Date())) &&
         !hasActiveTimer
      )) {
         setHasActiveTimer(true);

         _timeoutRef_Events.current = setTimeout(() => {
            _timeoutRef_Events.current = null;
            setTimerToggle(!timerToggle);
            mutate(getRecentlySentEmailUrl);
            setHasActiveTimer(false);
         }, (maxSendTimeSeconds * 1000)) // milliseconds
      }

   }, [hasActiveTimer, maxSendTimeSeconds, recentEmails, timerToggle]);

   const buildProgramItems = (items: FundStatusRecentlySendFunds[]) => {
      return items?.map((item, index) => {
         return (
            <Row key={`programItem-${index}`}>
               <Col span={6}>
                  <Card title='Fund' size='small' type='inner'>
                     {item.diseaseTypeName ?? "N/A"}
                  </Card>
               </Col>
               <Col span={6}>
                  <Card title='Service' size='small' type='inner'>
                     {item.assistanceServiceName}
                  </Card>
               </Col>
               <Col span={6}>
                  <Card title='Insurance' size='small' type='inner'>
                     <div style={{ wordBreak: 'break-all' }} title={item.insuranceClassNames}>
                        {item.insuranceClassNames}
                     </div>
                  </Card>
               </Col>
               <Col span={6}>
                  <Card title='Status' size='small' type='inner'>
                     <div style={{ wordBreak: 'break-all' }}>
                        <StatusTag status={item.status} />
                     </div>
                  </Card>
               </Col>
            </Row>
         );
      });
   }

   const buildProgramCard = (programs: FundStatusRecentlySentDetails[], fsaEmailId: number, emailUid: string) => {
      return programs?.map((program, index) => {
         const keyValue = `programCard-${program.foundationId}-${index}`;
         return (
            <Card title={program.programName}
               type='inner'
               size="small"
               style={{ width: '100%' }}
               key={keyValue}>
               {buildProgramItems(program?.sentFunds)}
            </Card>
         );
      });
   }

   const buildListItems = (items: FundStatusRecentlySentEmail[]) => {
      if (items?.length > 0) {
         return items.map((email, index) => {
            const title = (
               <>
                  <Row>
                     <Col span={10}>
                        <span onClick={() => { navigator.clipboard.writeText(email?.uId) }} title='Click to copy'>
                           <CustomIcon style={{ paddingLeft: 5, paddingRight: 5 }} type={CustomIconType.MailOutlined} />
                           <Typography.Text ellipsis={true}>
                              {email?.uId}
                           </Typography.Text>
                        </span>
                     </Col>
                     <Col span={2} />
                     <Col span={12}>
                        <Row justify='end'>
                           <Col>
                              <Space>
                                 <b>
                                    <Typography.Text ellipsis={true}>
                                       {formatDateTimeString(email.sentOn)} {email.batchStatus === BatchStatusType.Finished && <> ({getFromNowDateString(email.sentOn)}) </>}
                                    </Typography.Text>
                                 </b>
                                 {email.batchStatus === BatchStatusType.Queued &&
                                    <span>
                                       <b>(Queued...) &nbsp;</b>
                                       <CustomIcon type={CustomIconType.MehOutlined} />
                                    </span>
                                 }
                                 {email.batchStatus === BatchStatusType.Processing &&
                                    <span>
                                       <b>(Sending...) &nbsp;</b>
                                       <Spin size={'small'} />
                                    </span>
                                 }
                                 {email.batchStatus === BatchStatusType.Finished && email.emailBatches && email.emailBatches[email.emailBatches.length - 1].errorCount === 0 &&
                                    <CustomIcon type={CustomIconType.CheckOutlined} style={{ color: colorWheel.ivyGreen, fontSize: 18, paddingLeft: 5 }} />
                                 }
                                 {email.batchStatus === BatchStatusType.Finished &&
                                    email.emailBatches &&
                                    email.emailBatches[email.emailBatches.length - 1].errorCount !== 0 &&
                                    <span style={{ color: colorWheel.red }}>
                                       <CustomIcon type={CustomIconType.WarningOutlined} style={{ color: colorWheel.red, fontSize: 18, paddingLeft: 5, paddingRight: 5 }} />
                                       <b>{email.emailBatches ? email.emailBatches[email.emailBatches?.length - 1]?.errorCount : 0}</b>
                                    </span>
                                 }
                              </Space>
                           </Col>
                        </Row>
                     </Col>
                  </Row>
               </>);
            return (
               <Col span={24} key={email.uId}>
                  <Card title={title} size="small" type='inner' style={{ width: '100%' }}>
                     {buildProgramCard(email?.sentDetails, email?.fundStatusEmailId, email?.uId)}
                     {email.fundStatusEmailId && email.emailBatches &&
                        <RecentlySentBatchList fundStatusEmailUid={email.uId} batches={email.emailBatches} batchStatus={email.batchStatus} />
                     }
                  </Card>
               </Col>
            )
         });
      }
   }

   return (
      <div style={{ margin: '0.5rem' }}>
         <ApiErrorDisplay
            title='Recently Sent List Error:'
            keysWithVerb={_keysWithVerb} />
         <Row gutter={[16, 16]} justify="center" align="middle">
            {buildListItems(recentEmails)}
         </Row>
      </div>
   );

}

export default RecentlySentList;
