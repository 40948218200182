import { ColumnsType, TableProps } from 'antd/lib/table';
import * as React from 'react';
import Restricted from '../../../auth/Restricted';
import { numberComparer, stringComparer } from '../../../functions/comparer.functions';
import { useAssistanceProgramNameOptions, useFetchAssistancePrograms } from '../../../store/program/AssistanceProgramFetcher';
import { AssistanceProgramResource } from '../../../store/program/AssistanceProgramResourceModel';
import { useFetchAssistanceProgramResourceCategories, useFetchAssistanceProgramResources } from '../../../store/program/AssistanceProgramResourceFetcher';
import { KnownSettings } from '../../../store/SettingsModel';
import HighlightSearchText from '../../HighlightSearchText';
import SearchResultsTable from '../../shared/AntComponents/Table/SearchResultsTable';
import { BasicDropdownField, BasicInputField } from '../../shared/BasicInputLibrary';
import { EditButton } from '../../shared/Buttons';
import AssistanceProgramResourceEditor from './AssistanceProgramResourceEditor';
import { useApiContext } from '../../../store/ApiContext';

const AssistanceProgramResourceList: React.FC = () => {
   const { httpGet } = useApiContext();
   const [currentPage, setCurrentPage] = React.useState(1);
   const [currentPageSize, setCurrentPageSize] = React.useState(25);
   const { assistancePrograms } = useFetchAssistancePrograms(httpGet);
   const { assistanceProgramResources } = useFetchAssistanceProgramResources(httpGet);
   const { assistanceProgramResourceCategories } = useFetchAssistanceProgramResourceCategories(httpGet);

   const [urlFilter, setUrlFilter] = React.useState<string>('');
   const [programFilter, setProgramFilter] = React.useState<number>();
   const [filteredItems, setFilteredItems] = React.useState<AssistanceProgramResource[]>([]);

   const { assistanceProgramNameOptions } = useAssistanceProgramNameOptions(httpGet);

   const [selectedAssistanceProgramResourceId, setSelectedAssistanceProgramResourceId] = React.useState<number>();
   const [selectedItem, setSelectedAssistanceProgramResource] = React.useState<AssistanceProgramResource>();
   const [isEditorOpen, setIsEditorOpen] = React.useState<boolean>(false);

   const handleOnChange: TableProps<AssistanceProgramResource>['onChange'] = (pagination, filters, sorter, extra) => setCurrentPage(pagination?.current);

   React.useEffect(() => {
      createFilteredItems();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [assistanceProgramResources,
      urlFilter,
      programFilter]);

   React.useEffect(() => {
      if (selectedAssistanceProgramResourceId && assistanceProgramResources && assistanceProgramResources.length > 0) {
         const match = assistanceProgramResources.find(y => y.id === selectedAssistanceProgramResourceId);
         setSelectedAssistanceProgramResource(match);
      } else {
         setSelectedAssistanceProgramResource(undefined);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [selectedAssistanceProgramResourceId]);

   const createFilteredItems = () => {
      if (!assistanceProgramResources || assistanceProgramResources.length <= 0) return;

      let newList = assistanceProgramResources.filter(model => {
         return filterProgram(model) && filterUrl(model);
      }) ?? [];

      setFilteredItems(newList);
   }

   const filterProgram = (m: AssistanceProgramResource): boolean => {
      return !programFilter || m.assistanceProgramId === programFilter ? true : false;
   }

   const filterUrl = (m: AssistanceProgramResource): boolean => {
      return !urlFilter || m.url.toLowerCase().indexOf(urlFilter.toLowerCase()) > -1 ? true : false;
   }

   const handleCloseEditorClick = (assistanceProgramResourceId?: number) => {
      setIsEditorOpen(false);
      setSelectedAssistanceProgramResourceId(assistanceProgramResourceId);
   }

   const tableColumns: ColumnsType<AssistanceProgramResource> = [
      {
         title: 'Id',
         dataIndex: 'id',
         key: 1,
         sorter: (a, b) => numberComparer(a.id, b.id),
         sortDirections: ['ascend', 'descend'],
         defaultSortOrder: 'ascend'
      },
      {
         title: 'ProgramId',
         dataIndex: 'assistanceProgramId',
         key: 2,
         sorter: (a, b) => numberComparer(a.assistanceProgramId, b.assistanceProgramId),
         sortDirections: ['ascend', 'descend'],
         render: (text, record) => {
            const programName = assistancePrograms?.find(y => y.id === record.assistanceProgramId)?.programName
            return `[${record.assistanceProgramId}] ${programName}`
         }
      },
      {
         title: 'Type',
         dataIndex: 'type',
         key: 3,
         sorter: (a, b) => numberComparer(a.type, b.type),
         sortDirections: ['ascend', 'descend'],
         render: (text, record) => {
            const type = record?.type === 0 ? 'Enrollment Link' :
               (record?.type === 1 ? 'More Information' : '');
            return `[${record.type}] ${type}`;
         }
      },
      {
         title: 'URL',
         dataIndex: 'url',
         key: 4,
         sorter: (a, b) => stringComparer(a.url, b.url),
         sortDirections: ['ascend', 'descend'],
         render: (text, record) => {
            return <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
               {record.url && <a href={record.url} target='_blank' rel='noopener noreferrer'>
                  <HighlightSearchText searchString={urlFilter} targetString={record.url} />
               </a>}
            </div>
         }
      },
      {
         title: 'Description',
         dataIndex: 'description',
         key: 5,
         sorter: (a, b) => stringComparer(a.description, b.description),
         sortDirections: ['ascend', 'descend'],
         render: (description) =>
            <div style={{ overflowWrap: 'break-word', maxWidth: '20vw' }} >
               {description}
            </div>
      },
      {
         title: 'Category',
         dataIndex: 'assistanceProgramResourceCategoryId',
         key: 6,
         sorter: (a, b) => numberComparer(a.assistanceProgramResourceCategoryId, b.assistanceProgramResourceCategoryId),
         sortDirections: ['ascend', 'descend'],
         render: (text, record) => {
            const category = assistanceProgramResourceCategories?.find(y => y.id === record.assistanceProgramResourceCategoryId)?.category
            return category
         }
      },
      {
         title: '',
         dataIndex: '',
         fixed: 'right',
         render: (text, record) =>
            <Restricted requiredRoles={[KnownSettings.ContentAdmin]}>
               <EditButton title='Edit' onClick={() => {
                  setSelectedAssistanceProgramResourceId(record.id);
                  setIsEditorOpen(true);
               }} />
            </Restricted>
      }
   ];

   const html = (
      <>
         <SearchResultsTable
            rowkey={'id'}
            size={'small'}
            onChange={handleOnChange}
            columns={tableColumns}
            data={filteredItems}
            currentPage={currentPage}
            currentPageSize={currentPageSize}
            setCurrentPageSize={setCurrentPageSize}
            titleText='Assistance Program Resource'
            fixedHeader={true}
            additionalComponents={[
               <BasicInputField
                  label='Url:'
                  placeholder='Search by URL'
                  value={urlFilter}
                  type='text'
                  name='urlSearchString'
                  onChange={(e) => setUrlFilter(String(e))} />,
               <BasicDropdownField
                  label='Program:'
                  placeholder='Search by Program'
                  search={true}
                  clearable={true}
                  name='programSearchString'
                  options={assistanceProgramNameOptions}
                  onChange={(e) => setProgramFilter(e ? Number(e) : undefined)}
               />
            ]}
         />

         <AssistanceProgramResourceEditor
            assistanceProgramId={selectedItem?.assistanceProgramId}
            assistanceProgramResourceId={selectedAssistanceProgramResourceId}
            isOpen={isEditorOpen}
            closeEditor={handleCloseEditorClick} />
      </>
   );

   return html;
}

export default AssistanceProgramResourceList;