import { ColumnsType, TableProps } from 'antd/lib/table';
import * as React from 'react';
import Restricted from '../../auth/Restricted';
import { boolComparer, dateComparer, numberComparer, stringComparer } from '../../functions/comparer.functions';
import { formatBoolean, formatShortDateString } from '../../functions/format.functions';
import { useApiContext } from '../../store/ApiContext';
import { usePracticeOptions } from '../../store/practice/PracticeFetcher';
import { PracticeSubscriptionViewModel } from '../../store/practice/PracticeSubscriptionModel';
import { usePracticeBasedPracticeSubscriptionViewModel } from '../../store/practice/PracticeSubscriptionFetcher';
import { useAllSubscriptionOptions } from '../../store/practice/SubscriptionFetcher';
import { KnownSettings } from '../../store/SettingsModel';
import SearchResultsTable from '../shared/AntComponents/Table/SearchResultsTable';
import { BasicDropdownField } from '../shared/BasicInputLibrary';
import { EditButton, AddButton } from '../shared/Buttons';
import Spinner from '../Spinner';
import PracticeSubscriptionEditor from './PracticeSubscriptionEditor';
import { formatDate } from 'src/functions/time.functions';

const PracticeSubscriptionIndex: React.FC = () => {
   const { httpGet } = useApiContext();
   const { practiceOptions } = usePracticeOptions(httpGet);
   const { subscriptionOptions } = useAllSubscriptionOptions(httpGet);
   const { practiceSubscriptionsViewModels, isLoading } = usePracticeBasedPracticeSubscriptionViewModel(httpGet);

   const [currentPage, setCurrentPage] = React.useState(1);
   const [currentPageSize, setCurrentPageSize] = React.useState(25);

   const [practiceFilter, setPracticeFilter] = React.useState<number>(undefined);
   const [subscriptionFilter, setSubscriptionFilter] = React.useState<number>(undefined);
   const [filteredItems, setFilteredItems] = React.useState<PracticeSubscriptionViewModel[]>([]);
   const [selectedItem, setSelectedItem] = React.useState<PracticeSubscriptionViewModel>(undefined);
   const [isEditorOpen, setIsEditorOpen] = React.useState<boolean>(false);

   React.useEffect(() => {
      createFilteredItems();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [practiceSubscriptionsViewModels,
      practiceFilter,
      subscriptionFilter
   ]);

   const createFilteredItems = (inActivePage: number = 1) => {
      if (!practiceSubscriptionsViewModels || practiceSubscriptionsViewModels.length <= 0) return;

      let newList = practiceSubscriptionsViewModels.filter(model => {
         return filterPractice(model) &&
            filterSubscription(model);
      }) ?? [];

      setFilteredItems(newList);
   }

   const filterPractice = (m: PracticeSubscriptionViewModel): boolean => {
      const match = !practiceFilter || m.practiceId === practiceFilter;
      return match;
   }

   const filterSubscription = (m: PracticeSubscriptionViewModel): boolean => {
      const match = !subscriptionFilter || m.subscriptionId === subscriptionFilter;
      return match;
   }

   const clearFilters = () => {
      setPracticeFilter(undefined);
      setSubscriptionFilter(undefined);
   };

   const handleCloseEditorClick = () => {
      setIsEditorOpen(false);
      setSelectedItem(undefined);
   }

   const columns: ColumnsType<PracticeSubscriptionViewModel> = [
      {
         title: 'Id',
         dataIndex: 'id',
         sorter: (a, b) => numberComparer(a.id, b.id),
         sortDirections: ['ascend', 'descend', 'ascend'],
         width: 80,
      },
      {
         title: 'PracticeId',
         dataIndex: 'practiceId',
         sorter: (a, b) => numberComparer(a.practiceId, b.practiceId),
         sortDirections: ['ascend', 'descend', 'ascend'],
         width: 80,
      },
      {
         title: 'Practice',
         dataIndex: 'practiceName',
         sortDirections: ['ascend', 'descend', 'ascend'],
         defaultSortOrder: 'descend',
         sorter: (a, b) => stringComparer(a.practiceName, b.practiceName),
      },
      {
         title: 'SubId',
         dataIndex: 'subscriptionId',
         sorter: (a, b) => numberComparer(a.subscriptionId, b.subscriptionId),
         sortDirections: ['ascend', 'descend', 'ascend'],
         width: 80,
      },
      {
         title: 'Subscription',
         dataIndex: 'subscriptionName',
         sorter: (a, b) => stringComparer(a.subscriptionName, b.subscriptionName),
         sortDirections: ['ascend', 'descend', 'ascend'],
      },
      {
         title: 'Start Date',
         dataIndex: 'startDate',
         sorter: (a, b) => dateComparer(a.startDate, b.startDate),
         sortDirections: ['ascend', 'descend', 'ascend'],
         render: ((date: string) => formatDate(date))
      },
      {
         title: 'End Date',
         dataIndex: 'endDate',
         sorter: (a, b) => dateComparer(a.endDate, b.endDate),
         sortDirections: ['ascend', 'descend', 'ascend'],
         render: ((date: string) => formatDate(date))
      },
      {
         title: 'Active',
         dataIndex: 'active',
         sorter: (a, b) => boolComparer(a.active, b.active),
         sortDirections: ['ascend', 'descend', 'ascend'],
         render: ((bval: boolean) => formatBoolean(bval)),
         width: 80,
      },
      {
         title: 'Modified On',
         dataIndex: 'modifiedOn',
         sorter: (a, b) => dateComparer(a.modifiedOn, b.modifiedOn),
         sortDirections: ['ascend', 'descend', 'ascend'],
         render: ((date: Date) => formatShortDateString(date))
      },
      {
         title: () =>
            <Restricted requiredRoles={[KnownSettings.ContentAdmin]}>
               <AddButton
                  title='Add New Practice Subscription'
                  buttonText='New'
                  onClick={() => {
                     setSelectedItem(undefined);
                     setIsEditorOpen(true)
                  }}
               />
            </Restricted>,
         width: 100,
         fixed: 'right',
         render: (text, record) => <Restricted requiredRoles={[KnownSettings.ContentAdmin]}>
            <EditButton
               title='Edit'
               onClick={() => {
                  setSelectedItem(record);
                  setIsEditorOpen(true);
               }}
            />
         </Restricted>
      },
   ];

   if (!practiceSubscriptionsViewModels || practiceSubscriptionsViewModels.length === 0 || isLoading) {
      return <Spinner />
   }

   const html = (
      <>
         <SearchResultsTable
            rowkey={'id'}
            columns={columns}
            data={filteredItems}
            currentPage={currentPage}
            currentPageSize={currentPageSize}
            setCurrentPageSize={setCurrentPageSize}
            titleText='Practice Subscriptions'
            fixedHeader={true}
            size='small'
            additionalComponents={[
               <BasicDropdownField
                  containerStyle={{ maxWidth: 250 }}
                  name='practiceId'
                  value={practiceFilter}
                  label='Practice'
                  multiple={false}
                  options={practiceOptions}
                  required={false}
                  search={true}
                  clearable={true}
                  onChange={val => {
                     setPracticeFilter(val ? Number(val) : undefined);
                  }}
               />,
               <BasicDropdownField
                  containerStyle={{ maxWidth: 250 }}
                  name='subscriptionId'
                  value={subscriptionFilter}
                  label='Subscription'
                  multiple={false}
                  options={subscriptionOptions}
                  required={false}
                  search={true}
                  clearable={true}
                  onChange={val => {
                     setSubscriptionFilter(val ? Number(val) : undefined);
                  }}
               />,
            ]}
         />
         {isEditorOpen &&
            <PracticeSubscriptionEditor isOpen={isEditorOpen}
               closeEditor={() => handleCloseEditorClick()}
               practiceSubscriptionId={selectedItem?.id}
               isOpenFromPractice={false}
            />
         }
      </>
   )
   return html;
}

export default PracticeSubscriptionIndex;
