import { ColumnsType } from 'antd/lib/table';
import * as React from 'react';
import { formatDate } from 'src/functions/time.functions';
import Restricted from '../../auth/Restricted';
import { formatBoolean } from '../../functions/format.functions';
import { useApiContext } from '../../store/ApiContext';
import { KnownSettings } from '../../store/SettingsModel';
import { useSubscriptionsBasedPracticeSubscriptionViewModel } from '../../store/practice/PracticeSubscriptionFetcher';
import { PracticeSubscriptionViewModel } from '../../store/practice/PracticeSubscriptionModel';
import MinimalistTable from '../shared/AntComponents/Table/MinimalistTable';
import { EditButton } from '../shared/Buttons';
import PracticeSubscriptionEditor from './PracticeSubscriptionEditor';

interface IProps {
   practiceId: number;
   scrollYValue: string;
}

export const PracticeSubscriptionEmbeddedList: React.FC<IProps> = (props) => {
   const { httpGet } = useApiContext();
   const { practiceSubscriptionsViewModels: practiceSubscriptionsViewModels } = useSubscriptionsBasedPracticeSubscriptionViewModel(httpGet, props.practiceId);
   const [isPracticeSubscriptionEditorOpen, setIsPracticeSubscriptionEditorOpen] = React.useState<boolean>(false);
   const [practiceSubscriptionToEdit, setPracticeSubscriptionToEdit] = React.useState<PracticeSubscriptionViewModel>(undefined);

   const onPracticeSubscriptionEditorClose = () => {
      setIsPracticeSubscriptionEditorOpen(false);
      setPracticeSubscriptionToEdit(undefined);
   }

   const subscriptionColumns: ColumnsType<PracticeSubscriptionViewModel> = [
      {
         title: 'Id',
         dataIndex: 'id',
         key: 'id',
         defaultSortOrder: 'ascend'
      },
      {
         title: 'Subscription Name',
         dataIndex: 'subscriptionName',
         key: 'subscriptionName'
      },
      {
         title: 'Active',
         dataIndex: 'active',
         key: 'active',
         render: (text, record) => formatBoolean(record?.active)
      },
      {
         title: 'Start Date',
         dataIndex: 'startDate',
         key: 'startDate',
         render: ((date: string) => formatDate(date))
      },
      {
         title: 'End Date',
         dataIndex: 'endDate',
         key: 'endDate',
         render: ((date: string) => formatDate(date))
      },
      {
         render: (text, record) =>
            <Restricted requiredRoles={[KnownSettings.ContentAdmin]}>
               <EditButton
                  onClick={() => {
                     setPracticeSubscriptionToEdit(record);
                     setIsPracticeSubscriptionEditorOpen(true);
                  }}
                  title='Edit' />
            </Restricted>
      }
   ]

   return (
      <>
         <MinimalistTable
            rowKey='subscriptionId' // we need to use the subscriptionId because we're displaying all subscriptions regardless, so using the practiceSubscriptionId may result in key errors due to being empty
            data={practiceSubscriptionsViewModels}
            columns={subscriptionColumns}
            size='small'
            showHeader={true}
            scrollY={props.scrollYValue}
         />
         {isPracticeSubscriptionEditorOpen &&
            <PracticeSubscriptionEditor
               isOpenFromPractice={true}
               practiceIdForNew={props.practiceId}
               closeEditor={onPracticeSubscriptionEditorClose}
               isOpen={isPracticeSubscriptionEditorOpen}
               subscriptionId={practiceSubscriptionToEdit?.subscriptionId}
               practiceSubscriptionId={practiceSubscriptionToEdit?.id}
            />
         }
      </>
   );

}

export default PracticeSubscriptionEmbeddedList