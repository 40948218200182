import useSWR, { mutate } from 'swr';
import { HttpError, TGet, TPut } from '../../functions/httpClient';

export const favoriteReportBaseUrl = `/api/rpt/PowerBIFavorites`;
const allFavoritesUrl = `${favoriteReportBaseUrl}/GetAll`;
const allFavoritedReportsUrl = `${favoriteReportBaseUrl}/GetAllFavoritedReports`;
const updateFavoriteUrl = (reportId: number) => `${favoriteReportBaseUrl}/Update/${reportId}`;

const swrOptions = {
   refreshInterval: 0,
   revalidateOnFocus: false,
   dedupingInterval: 60000     //default 2000
};

export const useFavoriteReports = (httpGet: TGet) => {
   const { data, isValidating, error } = useSWR<number[], HttpError>(
      allFavoritesUrl,
      httpGet,
      { ...swrOptions });

   return {
      favoritedReportIds: data,
      isLoading: !error && !data && !isValidating,
      error: error
   };
}

export const updateFavoriteReport = async (httpPut: TPut, reportId: number) => {
   const result = await httpPut(updateFavoriteUrl(reportId))
      .then(() => {
         mutate(allFavoritesUrl);
         mutate(allFavoritedReportsUrl);
      });
   return result;
}
