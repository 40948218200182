import * as React from 'react';
import { IFormError } from '../../../functions/form.contracts';
import FormRow1Col from './FormRow1Col';
import { CohortItem } from '../../../store/cohort/CohortItemModel';

interface Props {
   dataName: string;
   cohortItem: CohortItem;
   onInputChange: (cohortItemId: number, val: any) => void;
   inputIndex: number;
   errors: IFormError[];
}

//form row contains label, input, validation message
const FormRow: React.FC<Props> = (props) => {
   const [isTouched, setIsTouched] = React.useState<boolean>(false);
   const {
      dataName,
      errors,
      inputIndex,
      cohortItem,
      onInputChange
   } = props;
   
   const handleBlur = (): void => {
      if (isTouched === false) {
         setIsTouched(true);
      }
   }

   const handleChange = (valueArray: any): void => {
      //TODO: need to check if value is an array already - server does not support it today
      //const targetArray = Array.isArray(valueArray) ? valueArray : [valueArray];
      //onInputChange(cohortItem.cohortFieldId, targetArray);
      onInputChange(cohortItem.cohortFieldId, valueArray);
   }

   let formError = errors.find(error => error.DataName === cohortItem.cohortFieldId);

   return (
      <FormRow1Col
         dataName={dataName}
         cohortItem={cohortItem}
         onInputChange={handleChange}
         errors={errors}
         isTouched={isTouched}
         onInputBlur={handleBlur}
         isValid={formError && formError?.DataName > 0}
         errMessage={formError?.Message}
         inputIndex={inputIndex}
      />
   );
}

export default FormRow;
