import { Card, Col, Divider, Row, Tag } from 'antd';
import * as React from 'react';
import { useState } from 'react';
import { colorWheel } from '../../Theme/ColorWheel';
import { numericArraysEqual } from '../../functions/common.functions';
import { stringComparer } from '../../functions/comparer.functions';
import { IOptionItem } from '../../functions/option.functions';
import { useApiContext } from '../../store/ApiContext';
import { HttpVerb, KeyWithVerb, useErrorContext } from '../../store/ErrorContext';
import { getFundStatusAlertUrl, useGetFundStatusAlertFundDetails } from '../../store/fsa/FundStatusAlertFetcher';
import { FundStatusAlert } from '../../store/fsa/FundStatusAlertModel';
import { sendFSAEmailUrl } from '../../store/fsa/FundStatusEmailFetcher';
import { assistanceProgramBaseUrl, buildAssistanceProgramOptions, useFetchAssistancePrograms } from '../../store/program/AssistanceProgramFetcher';
import { assistanceServiceBaseUrl, buildAssistanceServiceOptions, useFetchAssistanceServices } from '../../store/program/AssistanceServiceFetcher';
import { buildFoundationDiseaseTypesOptions, getFoundationDiseaseTypesUrl, useFetchFoundationDiseaseTypes } from '../../store/program/FoundationDiseaseType';
import { buildFoundationOptions, foundationBaseUrl, useFetchFoundations } from '../../store/program/FoundationFetcher';
import { FundStatusType, buildFundStatusTypeOptions } from '../../store/program/FundingStatusModel';
import { buildInsuranceClassOptions, insuranceClassBaseUrl, useFetchInsuranceClassesActive } from '../../store/program/InsuranceClassFetcher';
import ApiErrorDisplay from '../ApiErrorDisplay';
import Dialog from '../Dialog';
import CustomIcon, { CustomIconType } from '../shared/AntComponents/CustomIcon';
import { BasicCheckboxListField, BasicDropdownField } from '../shared/BasicInputLibrary';
import { ActionButton, CancelButton, DeleteButton } from '../shared/Buttons';
import AlertEmailDialog from './AlertEmailDialog';

interface IProps {
   isOpen: boolean;
   closeEditor: (isFsaSent: boolean) => void;
};

const dialogContentStyle = {
   height: '580px',
   maxHeight: '95vh',
}

const _keysWithVerb: KeyWithVerb[] = [
   { key: foundationBaseUrl, verb: HttpVerb.GET },
   { key: getFoundationDiseaseTypesUrl, verb: HttpVerb.GET },
   { key: insuranceClassBaseUrl, verb: HttpVerb.GET },
   { key: assistanceProgramBaseUrl, verb: HttpVerb.GET },
   { key: assistanceServiceBaseUrl, verb: HttpVerb.GET },
   { key: sendFSAEmailUrl, verb: HttpVerb.POST }
];

// If you are viewing this class, it is obvious there were some re-render issues.
// The end result is a passable performing screen, but there is something at a higher level
// causing these components to rerender.
const NewFundingStatusAlertDialog: React.FC<IProps> = (props) => {
   const { httpGet } = useApiContext();
   const { isOpen, closeEditor } = props;

   const [selectedFundAlerts, setSelectedFundAlerts] = useState<FundStatusAlert[]>([]);
   const [isSendAlertModalOpen, setIsSendAlertModalOpen] = React.useState<boolean>(false);
   const [filteredFoundation, setFilteredFoundation] = React.useState<number>();
   const [filteredAssistanceProgram, setFilteredAssistanceProgram] = React.useState<number>();
   const [filteredFoundationDiseaseType, setFilteredFoundationDiseaseType] = React.useState<number>();
   const [filteredAssistanceServices, setFilteredAssistanceServices] = React.useState<number[]>([]);
   const [filteredInsuranceClasses, setFilteredInsuranceClasses] = React.useState<number[]>([]);
   const [filteredStatus, setFilteredStatus] = React.useState<number>(undefined);

   const { removeErrors } = useErrorContext();

   const { foundations } = useFetchFoundations(httpGet);
   const { foundationDiseaseTypes } = useFetchFoundationDiseaseTypes(httpGet);
   const { insuranceClasses } = useFetchInsuranceClassesActive(httpGet);
   const assistancePrograms = useFetchAssistancePrograms(httpGet).assistancePrograms?.filter(y => y.programDescription !== 'DISCONTINUED: This program has been discontinued.');
   const assistanceServices = useFetchAssistanceServices(httpGet).assistanceServices?.filter(y => y.description?.toLocaleLowerCase()?.indexOf('discontinued') === -1);

   const { fundStatusAlerts } = useGetFundStatusAlertFundDetails(httpGet, filteredFoundation);
   // add dynamic URL to error keys with needed parameters
   _keysWithVerb.push({ key: getFundStatusAlertUrl(filteredFoundation), verb: HttpVerb.GET });

   const clearErrors = () => {
      removeErrors({ keysWithVerb: _keysWithVerb });
   }

   const resetFiltered = () => {
      setFilteredFoundation(undefined);
      setFilteredAssistanceProgram(undefined);
      setFilteredFoundationDiseaseType(undefined);
      setFilteredAssistanceServices(undefined);
      setFilteredInsuranceClasses(undefined);
      setFilteredStatus(undefined);
   }

   const foundationOptions = React.useMemo(() => {
      let options: IOptionItem[] = [];

      if (foundations?.length > 0 && assistancePrograms?.length > 0) {
         const foundationsWithPrograms = foundations.filter(f => assistancePrograms.some(p => p.foundationId === f.id));
         options = buildFoundationOptions(foundationsWithPrograms);
      }

      return options;

   }, [assistancePrograms, foundations]);

   const insuranceClassOptions = React.useMemo(() => {
      let options: IOptionItem[] = [];
      if (insuranceClasses?.length > 0)
         options = buildInsuranceClassOptions(insuranceClasses);

      return options;
   }, [insuranceClasses]);

   const assistanceProgramOptions = React.useMemo(() => {
      let options: IOptionItem[] = [];
      if (assistancePrograms?.length > 0 && filteredFoundation) {
         const tempPrograms = assistancePrograms.filter(y => y.foundationId === filteredFoundation);
         const tempOptions = buildAssistanceProgramOptions(tempPrograms);
         options = tempOptions;
      }

      return options;
   }, [assistancePrograms, filteredFoundation]);

   const foundationDiseaseTypesOptions = React.useMemo(() => {
      let options: IOptionItem[] = [];
      if (foundationDiseaseTypes?.length > 0 && filteredFoundation) {
         const tempFdts = foundationDiseaseTypes.filter(y => y.foundationId === filteredFoundation);
         const tempOptions = buildFoundationDiseaseTypesOptions(tempFdts);
         options = tempOptions;
      }

      return options;
   }, [filteredFoundation, foundationDiseaseTypes]);

   const assistanceProgramServiceOptions = React.useMemo(() => {
      let options: IOptionItem[] = [];
      if (assistanceServices?.length > 0 && filteredAssistanceProgram) {
         const tempServices = assistanceServices.filter(y => y.assistanceProgramId === filteredAssistanceProgram);
         const tempOptions = buildAssistanceServiceOptions(tempServices);
         options = tempOptions;
      }

      return options;
   }, [assistanceServices, filteredAssistanceProgram]);

   const handleClose = (isFsaSent: boolean) => {
      closeEditor(isFsaSent);
      clearErrors();
      resetFiltered();
      setSelectedFundAlerts([]);
   }

   const addToEmail = () => {
      const originalSelectedFunds = fundStatusAlerts.filter(f => f.assistanceProgramId === filteredAssistanceProgram &&
         f.foundationDiseaseTypeId === filteredFoundationDiseaseType &&
         filteredAssistanceServices?.some(s => s === f.assistanceServiceId));

      const newFunds: FundStatusAlert[] = [];

      const foundation = foundations.find(y => y.id === filteredFoundation);
      const assistanceProgram = assistancePrograms.find(y => y.id === filteredAssistanceProgram);
      const foundationDiseaseType = foundationDiseaseTypes.find(y => y.id === filteredFoundationDiseaseType);
      const statusId = filteredStatus;
      const statusText = statusId === 1 ? 'Closed' :
         statusId === 2 ? 'Open' :
            statusId === 3 ? 'Open for Re-Enrollment Only' : 'Unknown';

      for (let idx = 0; idx < filteredAssistanceServices.length; idx++) {
         const assistanceService = assistanceServices.find(y => y.id === filteredAssistanceServices[idx]);
         const originalSelectedFund = originalSelectedFunds?.find(y => y.assistanceServiceId === assistanceService.id);

         //stupid key.  careful not to end up with something greater than 2147483647 -> dotnet will get upset.
         //const rowKey = Number(`${idx}${assistanceProgram.id}${assistanceService.id}${filteredFoundationDiseaseType}`);
         const rowKey = Number(`${idx}${filteredFoundationDiseaseType}`);

         const newFund: FundStatusAlert = {
            rowKey: rowKey,
            foundationId: foundation.id,
            foundationName: foundation.foundationName,
            assistanceProgramId: assistanceProgram.id,
            programName: assistanceProgram.programName,
            foundationDiseaseTypeId: foundationDiseaseType.id,
            diseaseTypeName: foundationDiseaseType.diseaseTypeName,
            assistanceServiceId: assistanceService.id,
            assistanceServiceName: assistanceService.assistanceServiceName,
            status: statusId,
            statusText: statusText,
            alertDetails: []
         };

         filteredInsuranceClasses?.map(ic => {
            const insuranceClass = insuranceClasses.find(y => y.insuranceClassId === ic);
            const existingIc = originalSelectedFund?.alertDetails?.find(y => y.insuranceClassId === ic);

            newFund.alertDetails.push({
               insuranceClassId: insuranceClass.insuranceClassId,
               insuranceClass: insuranceClass.name,
               fundingStatusId: existingIc?.fundingStatusId,
               fundingStatusFieldChangeLogId: existingIc?.fundingStatusFieldChangeLogId
            });
         })

         newFunds.push(newFund);
      }

      const sorted = [
         ...selectedFundAlerts,
         ...newFunds
      ]

      sorted.sort((a: FundStatusAlert, b: FundStatusAlert) =>
         stringComparer(a.foundationName, b.foundationName) ||
         stringComparer(a.programName, b.programName) ||
         stringComparer(a.assistanceServiceName, b.assistanceServiceName) ||
         stringComparer(a.diseaseTypeName, b.diseaseTypeName)
      );

      setSelectedFundAlerts(sorted);
      resetFiltered();
   }

   const sendMail = async () => {
      setIsSendAlertModalOpen(true);
   }

   const handleCloseSendAlertModalClick = (isFsaSent: boolean) => {
      setIsSendAlertModalOpen(false);
      if (isFsaSent) {
         handleClose(isFsaSent);
      }
   }

   if (!isOpen) return null;

   const html = (
      <Dialog
         scrollingContent={true}
         title={'Create Custom FSA'}
         open={isOpen}
         size="large"
         actionButtons={
            <>
               <CancelButton onClick={() => {
                  handleClose(false);
               }} />
               <ActionButton
                  buttonText='Preview FSA Email'
                  filled={true}
                  icon={<CustomIcon type={CustomIconType.MailOutlined} />}
                  onClick={() => sendMail()}
                  disabled={!(selectedFundAlerts?.length > 0)} />
            </>
         }>
         <div style={dialogContentStyle}>

            <ApiErrorDisplay
               title='Error when attempting to Create Custom FSA'
               keysWithVerb={_keysWithVerb} />

            <Row gutter={[16, 16]}>
               <Col span={8}>
                  <BasicDropdownField
                     name='filteredFoundationIds'
                     label='Foundation'
                     multiple={false}
                     options={foundationOptions}
                     value={filteredFoundation}
                     search={true}
                     clearable={true}
                     onChange={(val) => {
                        const tempVal = val as number;
                        if (tempVal !== filteredFoundation) {
                           setFilteredFoundation(tempVal);
                           if (!tempVal) {
                              setFilteredAssistanceProgram(undefined);
                              setFilteredAssistanceServices([]);
                              setFilteredFoundationDiseaseType(undefined);
                           }
                        }
                     }}
                     required={true}
                  />
               </Col>
               <Col span={8}>
                  <BasicDropdownField
                     name='filteredAssistanceProgramIds'
                     label='Assistance Programs'
                     multiple={false}
                     options={assistanceProgramOptions}
                     value={filteredAssistanceProgram}
                     search={true}
                     clearable={true}
                     onChange={(val) => {
                        const tempVal = val as number;
                        if (tempVal !== filteredAssistanceProgram) {
                           setFilteredAssistanceProgram(tempVal);
                           if (!tempVal) {
                              setFilteredAssistanceServices([]);
                           }
                        }
                     }}
                     required={true}
                     disabled={!filteredFoundation}
                  />
               </Col>
               <Col span={8}>
                  <BasicDropdownField
                     name='foundationDiseaseTypeIds'
                     label='Foundation Disease Types'
                     multiple={false}
                     options={foundationDiseaseTypesOptions}
                     value={filteredFoundationDiseaseType}
                     search={true}
                     clearable={true}
                     onChange={(val) => {
                        const tempVal = val as number;
                        if (tempVal !== filteredFoundationDiseaseType) {
                           setFilteredFoundationDiseaseType(tempVal);
                        }
                     }}
                     required={true}
                     disabled={!filteredFoundation}
                  />
               </Col>
            </Row>
            <Row gutter={[16, 16]}>
               <Col span={8}>
                  <BasicDropdownField
                     name='filteredAssistanceServiceIds'
                     label='Assistance Service'
                     multiple={true}
                     options={assistanceProgramServiceOptions}
                     value={filteredAssistanceServices}
                     search={true}
                     clearable={true}
                     onChange={(val) => {
                        const tempVal = val as number[];
                        if (!numericArraysEqual(tempVal, filteredAssistanceServices)) {
                           setFilteredAssistanceServices(tempVal);
                        }
                     }}
                     required={true}
                     disabled={!filteredAssistanceProgram}
                  />
               </Col>
               <Col span={16}>
                  <BasicCheckboxListField
                     name='insuranceClassId'
                     label='Insurance Classes'
                     multiple={true}
                     options={insuranceClassOptions}
                     value={filteredInsuranceClasses}
                     search={true}
                     clearable={true}
                     onChange={(val) => {
                        const tempVal = val as number[];
                        if (!numericArraysEqual(tempVal, filteredInsuranceClasses)) {
                           setFilteredInsuranceClasses(tempVal);
                        }
                     }}
                     required={true}
                  />
               </Col>
               <Row>
                  <Col span={24}>
                     <BasicDropdownField
                        name='status'
                        label='status'
                        multiple={false}
                        value={filteredStatus}
                        options={buildFundStatusTypeOptions(false)}
                        onChange={(val) => {
                           const tempVal = val as number;
                           if (tempVal !== filteredStatus) {
                              setFilteredStatus(tempVal);
                           }
                        }}
                        required={true}
                        search={true}
                        clearable={true}
                     />
                  </Col>
               </Row>
            </Row>
            <Row gutter={[0, 0]} justify="end">
               <Col>
                  <ActionButton
                     buttonText='Add To FSA Email'
                     icon={<CustomIcon type={CustomIconType.MailOutlined} />}
                     style={{
                        paddingLeft: 5,
                        paddingRight: 5
                     }}
                     onClick={() => addToEmail()}
                     disabled={!filteredFoundation ||
                        !filteredAssistanceProgram ||
                        !filteredFoundationDiseaseType ||
                        !filteredAssistanceServices || filteredAssistanceServices?.length === 0 ||
                        !filteredInsuranceClasses || filteredInsuranceClasses?.length === 0 ||
                        filteredStatus === undefined}
                  />
               </Col>
            </Row>
            <Divider />
            <FSATable selectedFundAlerts={selectedFundAlerts} setSelectedFundAlerts={setSelectedFundAlerts} />
         </div>

         <AlertEmailDialog
            fundAlerts={selectedFundAlerts}
            clearFundAlerts={() => {
               setSelectedFundAlerts([]);
            }}
            isOpen={isSendAlertModalOpen}
            close={handleCloseSendAlertModalClick} />

      </Dialog>
   )
   return html;
}
export default NewFundingStatusAlertDialog;

interface IFSATableProps {
   selectedFundAlerts: FundStatusAlert[];
   setSelectedFundAlerts: (selectedFundAlerts: FundStatusAlert[]) => void;
};


const FSATable: React.FC<IFSATableProps> = (props) => {
   const { selectedFundAlerts, setSelectedFundAlerts } = props;

   return (<div key={'fsatable'}>
      {selectedFundAlerts?.map((item, index) => {
         return (<Row gutter={[16, 16]} key={index}>
            <Col style={{ width: '100%' }}>
               <Card
                  className='ap-fundstatuscard'
                  title={`${item.foundationName} - ${item.programName}`}
               >
                  <Row gutter={[16, 16]} style={{ paddingTop: 10, paddingBottom: 10 }}>
                     <Col span={14}>
                        <Row gutter={[16, 16]} justify="space-between">
                           <Col span={20}>
                              <span style={{ fontStyle: 'italic', overflowWrap: 'break-word' }}>{item.assistanceServiceName}</span> - <span style={{ fontStyle: 'italic', overflowWrap: 'break-word', fontWeight: 'bold' }}>{item.diseaseTypeName}</span>
                           </Col>
                           <Col span={4} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                              <div>
                                 <Tag color={item.status === FundStatusType.Open ? 'success' :
                                    item.status === FundStatusType.Closed ? 'error' :
                                       item.status === FundStatusType.ReenrollmentOnly ? 'warning' : 'info'} >{FundStatusType[item.status]}</Tag>
                              </div>
                           </Col>
                        </Row>
                     </Col>
                     <Col span={7}>
                        <div style={{ display: 'flex', flexWrap: 'wrap', padding: 10, width: '100%', color: colorWheel.graniteGrey, alignItems: 'center', border: `1px solid ${colorWheel.mediumGrey}` }}>
                           {item.alertDetails.map((aic) => {
                              return (<div key={aic.insuranceClassId} style={{ flex: '50%', width: '100%' }}>
                                 {aic.insuranceClass}
                              </div>)
                           })}
                        </div>
                     </Col>
                     <Col span={3} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                        <DeleteButton title='Remove'
                           onClick={() => {
                              setSelectedFundAlerts(selectedFundAlerts.filter(f =>
                                 !(f.assistanceProgramId === item.assistanceProgramId &&
                                    f.assistanceServiceId === item.assistanceServiceId &&
                                    f.foundationDiseaseTypeId === item.foundationDiseaseTypeId)
                              ));
                           }} />
                     </Col>
                  </Row>
               </Card>
            </Col>
         </Row>)
      })}
   </div>)
}