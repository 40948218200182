import useSWR, { mutate } from "swr";
import { HttpError, TGet, TPatch, TPost } from "../../functions/httpClient";
import { useInsightlyContactList } from "./InsightlyContactFetcher";
import { useInsightlyOrganizationList } from "./InsightlyOrganizationFetcher";
import { BulkUserProfile, UserProfile, UserProfileViewModel } from './UserProfileModel';
import * as React from 'react';
import { useFetchUserRoles } from "../practice/UserRoleFetcher";
import { nonAcceptedUserMainsUrl, useFetchUserMain, userMainBaseUrl } from "../auth/UserMainFetcher";

export const userProfileBaseUrl = 'api/practice/UserProfile';
export const userProfilePatchEmailUrl = (userProfileId: number) => `${userProfileBaseUrl}/UpdateEmail/${userProfileId}`;
export const userProfileBulkSaveUrl = `${userProfileBaseUrl}/BulkSaveUserProfile`;

const swrOptions = {
   refreshInterval: 0,
   revalidateOnFocus: false,
   dedupingInterval: 60000
};

export const useFetchUserProfiles = (httpGet: TGet) => {
   const { data, isValidating, error } = useSWR<UserProfile[], HttpError>(
      userProfileBaseUrl,
      httpGet,
      { ...swrOptions }
   );

   return {
      userProfiles: data,
      isLoading: !error && !data && isValidating,
      error: error
   };
}

export const useUserProfile = (httpGet: TGet, userProfileId: number) => {
   const { userProfiles, isLoading, error } = useFetchUserProfiles(httpGet);

   const model: UserProfile = React.useMemo(() => {
      if (userProfileId && userProfiles) {
         return userProfiles.find(y => y.userProfileId === userProfileId);
      }
      return undefined;
   }, [userProfiles, userProfileId]);

   return {
      userProfile: model,
      isLoading: isLoading,
      error: error
   };
}

export const useUserProfileViewModels = (httpGet: TGet) => {
   const { userRoles, isLoading: isLoading_roles } = useFetchUserRoles(httpGet);
   const { userProfiles, error: error_userProfiles, isLoading: isLoading_userProfiles } = useFetchUserProfiles(httpGet);
   const { insightlyOrganizations, error: error_orgs, isLoading: isLoading_orgs } = useInsightlyOrganizationList(httpGet);
   const { insightlyContacts, error: error_contacts, isLoading: isLoading_contacts } = useInsightlyContactList(httpGet);
   const { data: userMainList, error: error_userMains, isLoading: isLoading_userMains } = useFetchUserMain(httpGet);

   const models = React.useMemo(() => {
      const buildUserRoleNamesString = (user: UserProfile): string => {
         if (user.userRoles) {
            return userRoles.filter(r => user.userRoles?.includes(r.userRoleId)).map(r => r.roleName).join(', ');
         }
         return '';
      }

      if (!isLoading_userProfiles && !isLoading_orgs && !isLoading_contacts && !isLoading_roles && !isLoading_userMains) {
         let tempModels = userProfiles.map((profile): UserProfileViewModel => {
            return {
               ...profile,
               insightlyOrganization: insightlyOrganizations?.find(org => org.practiceId === profile.practiceId),
               insightlyContact: insightlyContacts?.find(contact => contact.id === profile.insightlyContactId),
               userRoleNamesString: buildUserRoleNamesString(profile),
               userMain: userMainList?.find(main => main.userProfileIds?.includes(profile.userProfileId))
            } as UserProfileViewModel;
         });
         return tempModels;
      }
   }, [userRoles, userProfiles, insightlyOrganizations, insightlyContacts, userMainList, isLoading_contacts, isLoading_orgs, isLoading_roles, isLoading_userProfiles, isLoading_userMains]);

   return {
      userProfileViewModels: models,
      isLoading: isLoading_userProfiles || isLoading_orgs || isLoading_contacts,
      error: error_userProfiles?.message ?? error_orgs?.message ?? error_contacts?.message ?? error_userMains?.message
   }
}

export const useUserProfileViewModel = (httpGet: TGet, userProfileId: number) => {
   const { userProfileViewModels, isLoading, error } = useUserProfileViewModels(httpGet);

   const model: UserProfileViewModel = React.useMemo(() => {
      if (userProfileId && userProfileViewModels) {
         return userProfileViewModels.find(y => y.userProfileId === userProfileId);
      }
      return undefined;
   }, [userProfileId, userProfileViewModels])

   return {
      userProfileViewModel: model,
      isLoading: isLoading,
      error: error
   }
}

export const bulkSaveUserProfile = async (httpPost: TPost, bulkRecords: BulkUserProfile) => {
   await httpPost(userProfileBulkSaveUrl, bulkRecords);
   mutate(userProfileBaseUrl);
   mutate(userMainBaseUrl); // bulkSave is called from places where we hang off the UserMain stack
   // nonAcceptedUserMains is dependant upon practice.UserProfile and especially isDisabled, so we should mutate it
   mutate(nonAcceptedUserMainsUrl);
}

export const saveUserProfile = async (httpPost: TPost, userProfile: UserProfile) => {
   await httpPost(userProfileBaseUrl, userProfile);
   mutate(userProfileBaseUrl);
   // nonAcceptedUserMains is dependant upon practice.UserProfile and especially isDisabled, so we should mutate it
   mutate(nonAcceptedUserMainsUrl);
}

export const patchUserProfileEmail = async (httpPatch: TPatch, userProfileId: number, updatedEmail: string) => {
   await httpPatch(userProfilePatchEmailUrl(userProfileId), { updatedEmail: updatedEmail });
   mutate(userProfileBaseUrl);
}