import { Space } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import * as React from 'react';
import Restricted from '../../auth/Restricted';
import { OrderDirection } from '../../functions/comparer.functions';
import { formatBoolean } from '../../functions/format.functions';
import { useApiContext } from '../../store/ApiContext';
import { dashboardContentBaseUrl, dashboardContentUpdateSortOrder, updateSortOrder, useFetchDashboardContent } from '../../store/dashboard/DashboardContentFetcher';
import { DashboardContent } from '../../store/dashboard/DashboardContentModel';
import { HttpVerb, KeyWithVerb, useErrorContext } from '../../store/ErrorContext';
import { KnownSettings } from '../../store/SettingsModel';
import colorWheel from '../../Theme/ColorWheel';
import ApiErrorDisplay from '../ApiErrorDisplay';
import '../grid/Grid.css';
import CustomIcon, { CustomIconType } from '../shared/AntComponents/CustomIcon';
import SearchResultsTable from '../shared/AntComponents/Table/SearchResultsTable';
import { ActionButton, AddButton, EditButton } from '../shared/Buttons';
import Spinner from '../Spinner';
import '../Spinner.css';
import DashboardContentEditor from './DashboardContentEditor';


const _keys: string[] = [dashboardContentUpdateSortOrder];
const _keysWithVerb: KeyWithVerb[] = [{ key: dashboardContentBaseUrl, verb: HttpVerb.GET}];

const DashboardContentList: React.FC = () => {
   const { httpGet, httpPut } = useApiContext();
   const { removeErrors } = useErrorContext();
   const [selectedItem, setSelectedItem] = React.useState<DashboardContent>(undefined);
   const [isGridUpdating, setIsGridUpdating] = React.useState<boolean>(false);
   const [isEditorOpen, setIsEditorOpen] = React.useState<boolean>(false);
   const { dashboardContentList, isLoading, isSaving } = useFetchDashboardContent(httpGet);

   const setupGridContent = (data: DashboardContent[]) => {
      if (data && data.length > 0) {
         // ensure items are sorted
         const sortedItems = data?.sort((a, b) => a.sortOrder - b.sortOrder);
         // ensure items are ordered 0 to n
         return sortedItems?.map((item, index) => {
            item.sortOrder = index;
            return item;
         });
      }
      return [];
   };

   React.useEffect(() => {
      removeErrors({ keys: _keys, keysWithVerb: _keysWithVerb });
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []) // clear errors on initial render

   const gridContent = React.useMemo(() => {
      if (dashboardContentList?.length > 0) {
         return setupGridContent(dashboardContentList);
      }
   }, [dashboardContentList])

   const handleCloseModal = () => {
      setIsEditorOpen(false);
      setSelectedItem(undefined);
   }

   const firstOfType = (order: number): boolean => order <= gridContent[0].sortOrder;
   const lastOfType = (order: number): boolean => order >= gridContent[gridContent.length - 1].sortOrder;

   const moveElement = (array: DashboardContent[], initialIndex: number, finalIndex: number) => {
      array.splice(finalIndex, 0, array.splice(initialIndex, 1)[0]);
      return array;
   }

   const reorderGridContent = async (item: DashboardContent, direction: OrderDirection) => {
      setIsGridUpdating(true);
      const index = gridContent.findIndex(i => i.id === item.id);
      // Disconnected copy of filtered reports to determine which changed after reorder
      let duplicateList = gridContent;
      await moveElement(duplicateList, index, direction === OrderDirection.Up ? index - 1 : index + 1);
      const orderedItems = duplicateList.map(i => i.id);
      // send update request
      await updateSortOrder(httpPut, orderedItems)
         .catch(err => console.error('Error updating Sort Order', err));
      setIsGridUpdating(false);
   }

   const tableColumns: ColumnsType<DashboardContent> = [
      {
         title: '',
         dataIndex: 'actions',
         fixed: 'right',
         width: 100,
         render: (text, record) => {
            const isFirstOfType = firstOfType(record.sortOrder);
            const isLastOfType = lastOfType(record.sortOrder);
            return <Restricted requiredRoles={[KnownSettings.ContentAdmin]}>
               <Space size={0}>
                  <ActionButton
                     disabled={isFirstOfType || isSaving || isGridUpdating}
                     style={{
                        borderColor: colorWheel.mediumGrey
                     }}
                     icon={isFirstOfType ?
                        <CustomIcon type={CustomIconType.BorderOutlined} style={{ color: colorWheel.graniteGrey }} /> :
                        <CustomIcon type={CustomIconType.UpSquareOutlined} style={{ color: colorWheel.graniteGrey }} />}
                     onClick={() => reorderGridContent(record, OrderDirection.Up)}
                  />
                  <ActionButton
                     disabled={isLastOfType || isSaving || isGridUpdating}
                     style={{
                        borderColor: colorWheel.mediumGrey
                     }}
                     icon={isLastOfType ?
                        <CustomIcon type={CustomIconType.BorderOutlined} style={{ color: colorWheel.graniteGrey }} /> :
                        <CustomIcon type={CustomIconType.DownSquareOutlined} style={{ color: colorWheel.graniteGrey }} />}
                     onClick={() => reorderGridContent(record, OrderDirection.Down)}
                  />
               </Space>
            </Restricted>
         }
      },
      {
         title: 'Id',
         dataIndex: 'id',
         sortDirections: ['ascend', 'descend', 'ascend'],
         defaultSortOrder: 'descend',
         width: 60
      },
      {
         title: 'Name',
         dataIndex: 'name',
         sortDirections: ['ascend', 'descend', 'ascend'],
      },
      {
         title: 'Description',
         dataIndex: 'description',
         sortDirections: ['ascend', 'descend', 'ascend'],
      },
      {
         title: 'IsNew',
         dataIndex: 'isNew',
         sortDirections: ['ascend', 'descend', 'ascend'],
         render: (text, record) => formatBoolean(record.isNew),
         width: 80
      },
      {
         title: 'Duration',
         dataIndex: 'duration',
         sortDirections: ['ascend', 'descend', 'ascend'],
         width: 80
      },
      {
         title: 'IsDeleted',
         dataIndex: 'isDeleted',
         sortDirections: ['ascend', 'descend', 'ascend'],
         render: (text, record) => formatBoolean(record.isDeleted),
         width: 80
      },
      {
         title: () =>
            <Restricted requiredRoles={[KnownSettings.ContentAdmin]}>
               <AddButton
                  title='Add New Dashboard Content.'
                  buttonText='New'
                  onClick={() => {
                     setSelectedItem(undefined);
                     setIsEditorOpen(true);
                  }}
               />
            </Restricted>,
         dataIndex: 'actions',
         fixed: 'right',
         width: 100,
         render: (text, record) => <Restricted requiredRoles={[KnownSettings.ContentAdmin]}>
            <EditButton
               onClick={() => {
                  setSelectedItem(record);
                  setIsEditorOpen(true);
               }} />
         </Restricted>
      },
   ];

   if (isLoading) {
      return <Spinner />
   }

   return (
      <>
         <ApiErrorDisplay
            title={'Error'}
            keys={_keys}
            keysWithVerb={_keysWithVerb}
            
         />

         <SearchResultsTable
            rowkey={'id'}
            columns={tableColumns}
            data={gridContent}
            titleText='Dasboard Content'
            fixedHeader={true}
            size='small'
            scrollY={'calc(100vh -  175px)'}
         />

         {isEditorOpen && <DashboardContentEditor
            selectedItemId={selectedItem?.id}
            onCloseEditor={handleCloseModal}
            isEditorOpen={isEditorOpen} />
         }
      </>
   );
}

export default DashboardContentList;