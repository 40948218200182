import { numberComparer, stringComparer } from '../../functions/comparer.functions';
import { useMemo } from 'react';
import { HttpError, TPost, TDelete, TGet } from '../../functions/httpClient';
import { IOptionItem } from '../../functions/option.functions';
import { IStatus } from '../models';
import useSWR, { mutate } from 'swr';
import { Manufacturer } from './ManufacturerModel';

export const manufacturerBaseUrl = `api/program/Manufacturer`;
const manufacturerDeleteUrl = (id: number) => `${manufacturerBaseUrl}/${id}`;

const swrOptions = {
   refreshInterval: 0,
   revalidateOnFocus: false,
   dedupingInterval: 60000
};


export function useFetchManufacturer(httpGet: TGet): IStatus & { manufacturers: Manufacturer[] } {
   const { data, isLoading, error } = useSWR<Manufacturer[], HttpError>(
      manufacturerBaseUrl,
      httpGet,
      { ...swrOptions }
   );

   return {
      manufacturers: data,
      isLoading: isLoading,
      error: error?.message
   };
}

export const saveManufacturer = async (httpPost: TPost, manufacturer: Manufacturer) => {
   const result = await httpPost(manufacturerBaseUrl, manufacturer)
      .then(() => mutate(manufacturerBaseUrl));
   return result;
}

export const deleteManufacturer = async (httpDelete: TDelete, id: number) => {
   const result = await httpDelete(manufacturerDeleteUrl(id))
      .then(() => mutate(manufacturerBaseUrl));
   return result;
}



export function useManufacturerOptions(httpGet: TGet): { manufacturerOptions: IOptionItem[] } {
   const { manufacturers } = useFetchManufacturer(httpGet);

   const options = useMemo(() => {
      if (manufacturers?.length > 0) {

         const sorted = [...manufacturers];
         sorted.sort((a: Manufacturer, b: Manufacturer) => stringComparer(String(a.manufacturerName), String(b.manufacturerName)));

         const options = sorted.map((o): IOptionItem => ({
            key: o.id,
            label: `[${o.id}]: ${o.manufacturerName ?? 'not specified'}`,
            value: o.id
         } as IOptionItem));
         return options;
      }
      return [];
   }, [manufacturers]);


   return {
      manufacturerOptions: options
   };
}


export function useManufacturerNameOptions(httpGet: TGet): { manufacturerOptions: IOptionItem[] } {
   const { manufacturers } = useFetchManufacturer(httpGet);

   const manufacturerOptions = useMemo(() => {
      if (manufacturers?.length > 0) {
         const sorted = [...manufacturers];

         sorted.sort((a: Manufacturer, b: Manufacturer) => numberComparer(Number(a.id), Number(b.id)));
         sorted.sort((a: Manufacturer, b: Manufacturer) => stringComparer(String(a.manufacturerName), String(b.manufacturerName)));

         const manufacturerOptions = sorted.map((manufacturer): IOptionItem => ({
            key: manufacturer.id,
            label: `[${manufacturer.id}] ${manufacturer.manufacturerName}`,
            value: `${manufacturer.manufacturerName}`
         } as IOptionItem));
         return manufacturerOptions;
      }
      return [];
   }, [manufacturers]);

   return {
      manufacturerOptions
   };
}