import * as React from 'react';
import { addDate } from '../../functions/common.functions';

import { openEmailSendNotification, emailSendNotificationConfiguration } from '../shared/NotificationBuilder';
import { notification } from 'antd';

interface IFundStatusContextProps {
   fieldChangeCount: number;
   setFieldChangeCount: (changeCount: number) => void;
   fieldChangeFromDate: Date;
   sendNotify: () => void;
}

export const FundStatusContext = React.createContext<IFundStatusContextProps>({} as IFundStatusContextProps);
export const useFundStatusContext = () => React.useContext(FundStatusContext);

interface IFundStatusContextProviderProps {
   children: React.ReactNode;
}

const fieldChangeSeedDate = addDate(new Date(), -60);

export const FundStatusContextProvider: React.FC<IFundStatusContextProviderProps> = (props) => {
   const [fieldChangeFromDate,] = React.useState<Date>(fieldChangeSeedDate);
   const [fieldChangeCount, setFieldChangeCount] = React.useState<number>();
   const [notificationApi, contextHolder] = notification.useNotification(emailSendNotificationConfiguration);

   const sendNotify = () => {
      openEmailSendNotification(notificationApi);
   }

   return (
      <FundStatusContext.Provider
         value={{
            fieldChangeCount,
            setFieldChangeCount,
            fieldChangeFromDate,
            sendNotify
         }}
      >
      <>
            {props.children}
            {contextHolder}
         </>
      </FundStatusContext.Provider>
   );
};
