import * as React from 'react';
import useSWR, { mutate } from "swr";
import { HttpError, TGet, TPost, TPut } from "../../functions/httpClient";
import { userProfileBaseUrl } from '../practice/UserProfileFetcher';
import { UserProfile } from '../practice/UserProfileModel';
import { nonAcceptedUserMainsUrl, userMainBaseUrl } from './UserMainFetcher';
import { CreateNewPracticeUser } from './UserModel';
import { UserPendingAccessDenyModel, UserPendingAccessModel, UserPendingAccessModelStatusType } from './UserPendingAccessModel';

export const userPendingAccessBaseUrl = 'api/auth/UserPendingAccess';
const userPendingAccessStatusUrl = (status: number) => `${userPendingAccessBaseUrl}/${status}`;
export const denyAccessUrl = `${userPendingAccessBaseUrl}/Deny`;
export const approveUserUrl = `${userPendingAccessBaseUrl}/Approve`;

const swrOptions = {
   refreshInterval: 0,
   revalidateOnFocus: false,
   dedupingInterval: 60000
};

export const useFetchUserPendingAccess = (httpGet: TGet, status: number = UserPendingAccessModelStatusType.Pending) => {
   const { data, isValidating, error } = useSWR<UserPendingAccessModel[], HttpError>(
      userPendingAccessStatusUrl(status),
      httpGet,
      { ...swrOptions }
   );

   return {
      userPendingAccessList: data,
      isLoading: !error && !data && isValidating,
      error: error
   };
}

export const sendDenyRequest = async (httpPost: TPost, request: UserPendingAccessDenyModel) => {
   return await httpPost(denyAccessUrl, request)
      .then(() => { mutate(userPendingAccessStatusUrl(1)); });
}

export const approveUser = async (httpPost: TPost, userBeingApproved: CreateNewPracticeUser): Promise<UserProfile> => {
   let userProfile: UserProfile;

   await httpPost<UserProfile>(
      approveUserUrl,
      userBeingApproved
   ).
      then((response) => {
         mutate(userMainBaseUrl);
         mutate(userPendingAccessStatusUrl(1)); // 1 === Pending
         mutate(userProfileBaseUrl);
         // nonAcceptedUserInvites is dependant on practice.UserProfile so we should mutate
         mutate(nonAcceptedUserMainsUrl);
         userProfile = response;
      });

   return userProfile;
}