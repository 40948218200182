import * as React from 'react';
import { useApiContext } from '../../../store/ApiContext';
import { useUserMainViewModel } from '../../../store/auth/UserMainFetcher';
import { UserProfileViewModel } from '../../../store/practice/UserProfileModel';
import UserProfileEditor from './UserProfileEditor';

interface INewUserProfileEditorProps {
   userMainId: number;
   isEditorOpen: boolean;
   onCloseEditor: () => void;
}

const NewUserProfileEditor: React.FC<INewUserProfileEditorProps> = (props) => {
   const { httpGet } = useApiContext();
   const { userMainId, isEditorOpen, onCloseEditor } = props;
   const { userMainViewModel } = useUserMainViewModel(httpGet, userMainId);

   const profileToEdit = React.useMemo(() => {
      let result = {} as UserProfileViewModel;
      if (userMainViewModel) {
         result.externalUpn = userMainViewModel.upn;
      }
      return result;
   }, [userMainViewModel])

   return <UserProfileEditor
      dialogTitle={'Create New Practice User'}
      userMainId={userMainId}
      profileToEdit={profileToEdit}
      isEditorOpen={isEditorOpen}
      closeEditor={onCloseEditor}
      isEditorReadOnly={false}
   />;
}

export default NewUserProfileEditor;