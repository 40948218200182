import {CustomIconType} from '../../components/shared/AntComponents/CustomIcon';
import {KnownSettings} from '../SettingsModel';

export interface IMainMenuItem {
   labelIconName: CustomIconType;
   toggleOpenIconUrl: string;
   toggleClosedIconUrl: string;
   id: string;
   itemType: MainItemType | ReportItemType;
   label: string;
   isExpanded: boolean;
   isSelected: boolean;
   canExpand: boolean;
   canSelect: boolean;
   route: string;
   flyoutItems: IMainMenuSubItem[];
   allowedRoles: string[]; //if no roles, then everyone can view
   childMenuItems?: IMainMenuItem[];
   parentMenuItemType?: MainItemType | ReportItemType;
}

export interface IMainMenuSubItem {
   id: string;
   label: string;
   hoverText: string;
   isSelected: boolean;
   route: string;
   allowedRoles: string[];
}

export enum MainItemType {
   Dashboard = '',
   Analytics = 'report',
   ContentTool = 'content-tool',
   External = 'Crx',
   FSA = 'fsa',
   AdparoScorecard = 'adparo',
   User = 'user',
}

export enum ReportItemType {
   BrowseCertified = 'browsecertified',
   BrowseUncertified = 'browseuncertified',
   Favorites = 'favorite',
   Search = 'search'
}

export const deselectAll = (menuItems: IMainMenuItem[]): IMainMenuItem[] => {
   return reduceMainMenuDeselectAll(menuItems);
};

export const selectById = (menuItems: IMainMenuItem[], id: string): IMainMenuItem[] => {
   return reduceMainMenuItemSelectedById(menuItems, id)
};

//kept these broken out like the current AP site to make it easier to follow
const reduceMainMenuDeselectAll = (
   state: IMainMenuItem[]
): IMainMenuItem[] =>
   state.map(e => ({
      ...e,
      isSelected: false,
      subItems: e.flyoutItems.map(subItem => ({
         ...subItem,
         isSelected: false
      }))
   }));

const reduceMainMenuItemSelectedById = (
   state: IMainMenuItem[],
   payload: string
): IMainMenuItem[] => {
   const index = state.findIndex(e => e.id === payload);
   //console.log(`Selected MenuID: ${payload} - ${index}`);
   if (index >= 0) return reduceMainMenuItemSelectedByType(state, state[index].itemType);
   return state;
};

const reduceMainMenuItemSelectedByType = (
   state: IMainMenuItem[],
   payload: MainItemType | ReportItemType
): IMainMenuItem[] =>
   state.map(e => ({
      ...e,
      isSelected: e.itemType === payload,
      subItems: e.flyoutItems.map(subItem => ({
         ...subItem,
         isSelected: payload !== MainItemType.Dashboard && subItem.isSelected
      }))
   }));

export const MainMenuItems: IMainMenuItem[] = [
   {
      labelIconName: CustomIconType.HomeOutlined,
      toggleOpenIconUrl: undefined,
      toggleClosedIconUrl: undefined,
      id: "Dashboard",
      itemType: MainItemType.Dashboard,
      label: "Dashboard",
      isExpanded: true,
      isSelected: false,
      canExpand: true,
      canSelect: true,
      route: "/",
      flyoutItems: [],
      allowedRoles: []
   },
   {
      labelIconName: CustomIconType.EditOutlined,
      toggleOpenIconUrl: undefined,
      toggleClosedIconUrl: undefined,
      id: "Content-Tool",
      itemType: MainItemType.ContentTool,
      label: "Content Tool",
      isExpanded: true,
      isSelected: false,
      canExpand: true,
      canSelect: true,
      route: undefined,
      flyoutItems: [],
      allowedRoles: [
         KnownSettings.ContentAdmin,
         KnownSettings.ContentViewer,
         KnownSettings.CohortAdmin,
         KnownSettings.CohortReviewer,
         KnownSettings.BIReportManager
      ]
   },
   {
      labelIconName: CustomIconType.ShopOutlined,
      toggleOpenIconUrl: undefined,
      toggleClosedIconUrl: undefined,
      id: "Crx - Amgen",
      itemType: MainItemType.External,
      label: "Crx - Amgen",
      isExpanded: false,
      isSelected: false,
      canExpand: false,
      canSelect: true,
      route: "/Crx",
      flyoutItems: [],
      allowedRoles: [KnownSettings.CrxUser]
   },
   {
      labelIconName: CustomIconType.AlertOutlined,
      toggleOpenIconUrl: undefined,
      toggleClosedIconUrl: undefined,
      id: "Funding Status Alerts",
      itemType: MainItemType.FSA,
      label: "Funding Status Alerts",
      isExpanded: false,
      isSelected: false,
      canExpand: false,
      canSelect: true,
      route: "/fsa",
      flyoutItems: [],
      allowedRoles: [KnownSettings.FSAUser]
   },
   {
      labelIconName: CustomIconType.UserOutlined,
      toggleOpenIconUrl: undefined,
      toggleClosedIconUrl: undefined,
      id: "User",
      itemType: MainItemType.User,
      label: "Manage Users",
      isExpanded: false,
      isSelected: false,
      canExpand: false,
      canSelect: true,
      route: "/user",
      flyoutItems: [],
      allowedRoles: [KnownSettings.UserAdmin]
   },
   {
      labelIconName: CustomIconType.ProfileOutlined,
      toggleOpenIconUrl: undefined,
      toggleClosedIconUrl: undefined,
      id: "Adparo Scorecard",
      itemType: MainItemType.AdparoScorecard,
      label: "Adparo Scorecard",
      isExpanded: false,
      isSelected: false,
      canExpand: false,
      canSelect: true,
      route: "/adparo",
      flyoutItems: [],
      allowedRoles: [KnownSettings.AdparoManager]
   },
   {
      labelIconName: CustomIconType.BarChartOutlined,
      toggleOpenIconUrl: undefined,
      toggleClosedIconUrl: undefined,
      id: "Analytics",
      itemType: MainItemType.Analytics,
      label: "Analytics",
      isExpanded: true,
      isSelected: false,
      canExpand: true,
      canSelect: true,
      route: undefined,
      flyoutItems: [],
      childMenuItems: [
         {
            id: 'Analytics_Browse_Certified',
            label: 'Browse Certified',
            toggleOpenIconUrl: undefined,
            toggleClosedIconUrl: undefined,
            isExpanded: true,
            isSelected: false,
            canSelect: true,
            canExpand: false,
            route: "/report/browsecertified",
            flyoutItems: [],
            itemType: ReportItemType.BrowseCertified,
            allowedRoles: [],
            labelIconName: CustomIconType.SafetyCertificateOutlined,
            parentMenuItemType: MainItemType.Analytics
         },
         {
            id: 'Analytics_Browse_Uncertified',
            label: 'Browse Uncertified',
            toggleOpenIconUrl: undefined,
            toggleClosedIconUrl: undefined,
            isExpanded: true,
            isSelected: false,
            canSelect: true,
            canExpand: false,
            route: "/report/browseuncertified",
            flyoutItems: [],
            itemType: ReportItemType.BrowseUncertified,
            allowedRoles: [],
            labelIconName: CustomIconType.WarningOutlined,
            parentMenuItemType: MainItemType.Analytics
         },
         {
            id: 'Analytics_Favorites',
            label: 'Favorites',
            labelIconName: CustomIconType.StarOutlined,
            toggleOpenIconUrl: undefined,
            toggleClosedIconUrl: undefined,
            isExpanded: true,
            isSelected: false,
            canSelect: true,
            canExpand: false,
            route: "/report/favorite",
            flyoutItems: [],
            itemType: ReportItemType.Favorites,
            allowedRoles: [],
            parentMenuItemType: MainItemType.Analytics
         },
         {
            id: 'Analytics_Search',
            label: 'Search',
            labelIconName: CustomIconType.SearchOutlined,
            toggleOpenIconUrl: undefined,
            toggleClosedIconUrl: undefined,
            isExpanded: true,
            isSelected: false,
            canSelect: true,
            canExpand: false,
            route: "/report/search",
            flyoutItems: [],
            itemType: ReportItemType.Search,
            allowedRoles: [],
            parentMenuItemType: MainItemType.Analytics
         }
      ],
      allowedRoles: [
         KnownSettings.ReportViewer,
         KnownSettings.OmegaManager,
         KnownSettings.BIReportManager,
         KnownSettings.AdparoManager
      ]
   }
];
