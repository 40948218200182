import * as React from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { InputField, DropdownField, RadioListField } from '../../shared/InputLibrary';
import Dialog from '../../Dialog';
import { SaveButton, DeleteButton, CancelButton } from '../../shared/Buttons';
import Spinner from '../../Spinner';
import { useBrandWithManufacturerOptions } from '../../../store/drug/BrandFetcher';
import { useFoundationDiseaseTypesWithFoundationOptions } from '../../../store/program/FoundationDiseaseType';
import { AssistanceService } from '../../../store/program/AssistanceServiceModel';
import { useAssistanceProgramAndServicesOptions } from '../../../store/program/AssistanceServiceFetcher';
import { AssistanceServiceEligibilityCriteria } from '../../../store/program/AssistanceServiceEligibilityCriteriaModel';
import {
   useEligibilityCategoryOptions,
   useFetchEligibilityCriteria,
   saveAssistanceServiceEligibilityCriterias,
   deleteAssistanceServiceEligibilityCriteria,
   assistanceServiceToEligibilityCriteriaBaseUrl
} from '../../../store/program/AssistanceServiceEligibilityCriteriaFetcher';
import ApiErrorDisplay from '../../ApiErrorDisplay';
import { Form } from 'antd';
import { TitleError } from '../../shared/AntComponents/Typography/Title';
import { useApiContext } from '../../../store/ApiContext';
import { useCohortContext } from '../../cohort/CohortContext';
import { useErrorContext } from '../../../store/ErrorContext';

interface IProps {
   isOpen: boolean;
   closeEditor: () => void;
   id?: number;
   readonly?: boolean;
   shouldBrandBeDisabled?: boolean; // should be set to true when editing from a manufacturer program
   shouldAssistanceServiceBeDisabled?: boolean; // should be set to true when editing/"adding new" as a User is viewing a specific service when doing so
   shouldUseProvidedAssistanceService?: boolean; // should be set to true when "adding new" as a User is viewing a specific service when they click "add"
   shouldFoundationDiseaseTypeBeDisabled?: boolean, //should be set to true when editing from a foundation program
   assistanceService?: AssistanceService;
   brandIdsMappedForDrugs?: number[];
};

const dialogContentStyle = {
   height: '580px',
   maxHeight: '95vh',
}

const yupEligibilityCriteriaSchema = yup.object({
   id: yup.number().notRequired(),
   assistanceServiceId: yup.number()
      .required('Assistance Service is required'),
   brandId: yup.number()
      .notRequired(),
   foundationDiseaseTypeId: yup.number()
      .nullable()
      .transform((_, val) => val === '' ? null : Number(val)),
   assistanceServiceEligibilityCriteriaCategoryId: yup.number()
      .required('Eligibility Category is required'),
   inputValue: yup.string()
      .max(256, 'Max length is 256 characters')
      .notRequired(),
   value: yup.string()
      .max(256, 'Max length is 256 characters')
      .when('inputValue', {
         is: 'Yes',
         then: () => yup.string().notRequired(),
         otherwise: () => yup.string().required()
      }),
   url: yup.string()
      .max(1024, 'Max length is 1024 characters')
      .url()
      .notRequired(),
   sortOrder: yup.number()
      .nullable()
      .transform((_, val) => val === '' ? null : Number(val)),
   notes: yup.string()
      .max(512, 'Max length is 512 characters')
      .notRequired(),
});

interface IEligibilityCriteriaYup extends yup.Asserts<typeof yupEligibilityCriteriaSchema> { }
const __formId = "frmEligibilityCriteriaYup";
const _keysLike: string[] = [assistanceServiceToEligibilityCriteriaBaseUrl];

const AssistanceServiceEligibilityCriteriaEditor: React.FC<IProps> = (props) => {
   const { closeEditor, isOpen, assistanceService, brandIdsMappedForDrugs,
      id, readonly, shouldAssistanceServiceBeDisabled, shouldBrandBeDisabled,
      shouldFoundationDiseaseTypeBeDisabled, shouldUseProvidedAssistanceService } = props;

   const { httpGet, httpPost, httpDelete } = useApiContext();

   const { eligibilityCriteria } = useFetchEligibilityCriteria(httpGet);
   const { cohortSummaryFromDate } = useCohortContext();

   const { eligibilityCategoryOptions } = useEligibilityCategoryOptions(httpGet);
   const { brandWithManufacturerOptions } = useBrandWithManufacturerOptions(httpGet);
   const { assistanceProgramAndServicesOptions } = useAssistanceProgramAndServicesOptions(httpGet);
   const { foundationDiseaseTypesWithFoundationOptions } = useFoundationDiseaseTypesWithFoundationOptions(httpGet);
   const [radioValue, setRadioValue] = React.useState(false);
   const [isFormSaving, setIsFormSaving] = React.useState<boolean>(false);
   const { removeErrors } = useErrorContext();
   const clearErrors = () => {
      removeErrors({ keysLike: _keysLike });
   }

   const {
      control,
      handleSubmit,
      formState: { errors },
      reset,
      setValue,
      trigger
   } = useForm<IEligibilityCriteriaYup>({
      resolver: yupResolver<yup.AnyObject>(yupEligibilityCriteriaSchema),
      shouldFocusError: true,
   });
   
   const itemToEdit = React.useMemo(() => {
      const item = eligibilityCriteria?.find(y => y.id === id)
      setRadioValue(item?.value?.toLowerCase() === 'yes' || !item?.value);
      return item;
   }, [id, eligibilityCriteria]);

   const filteredBrandOptions = React.useMemo(() => {
      if (brandIdsMappedForDrugs?.length > 0) {
         // IOptionItem.value holds the brandId
         return brandWithManufacturerOptions?.filter(opt => brandIdsMappedForDrugs?.indexOf(Number(opt.value)) > -1);
      } else {
         return brandWithManufacturerOptions;
      }
   }, [brandIdsMappedForDrugs, brandWithManufacturerOptions]);

   React.useEffect(() => {
      let resetVal: AssistanceServiceEligibilityCriteria;

      if (shouldUseProvidedAssistanceService) {
         resetVal = {
            ...itemToEdit,
            inputValue: itemToEdit?.value === 'Yes' || !itemToEdit?.value ? 'Yes' : 'Other',
            assistanceServiceId: assistanceService?.id
         } as AssistanceServiceEligibilityCriteria;
      } else {
         resetVal = {
            ...itemToEdit,
            inputValue: itemToEdit?.value === 'Yes' || !itemToEdit?.value ? 'Yes' : 'Other'
         } as AssistanceServiceEligibilityCriteria;

         resetVal = resetVal ?? { id: id } as AssistanceServiceEligibilityCriteria;
      }

      reset(resetVal);
      setIsFormSaving(false);

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [itemToEdit, id, isOpen]);

   const [form] = Form.useForm();

   const onSubmit = (data: IEligibilityCriteriaYup): void => {
      setIsFormSaving(true);
      //console.log('--------------AssistanceServiceEditor-------------------------')
      //console.log(errors)

      //ts hoops to convert data with unknown props into assistance programs
      const item = data as unknown as AssistanceServiceEligibilityCriteria;
      if (radioValue || item.value.trim() === '')
         item.value = 'Yes';

      const itemToSave = [item];

      saveAssistanceServiceEligibilityCriterias(httpPost, itemToSave, cohortSummaryFromDate)
         .then(() => {
            handleClose();
         })
         .catch(err => console.log(`Error Saving`, err))
         .finally(() => {
            setIsFormSaving(false);
         });
   }

   const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState<boolean>(false);

   const handleDeleteClick = () => {
      setIsDeleteDialogOpen(false);
      deleteAssistanceServiceEligibilityCriteria(httpDelete, itemToEdit.id)
         .then(handleClose)
         .catch(err => console.log(`Error Saving`, err));
   }

   const handleClose = () => {
      setIsFormSaving(false);
      setIsDeleteDialogOpen(false);
      clearErrors();
      closeEditor();
   }

   if (isFormSaving) {
      return <Spinner message='Saving Eligibility Criteria...' />
   }

   //console.log('--------------AssistanceServiceEligibilityCriteriaEditor-------------------------')
   //console.log(errors)

   const html = (
      <Dialog
         scrollingContent={true}
         title={'Assistance Service Eligibility Criteria Editor'}
         open={isOpen}
         size="large"
         actionButtons={
            <>
               <CancelButton
                  onClick={() => handleClose()}
                  loading={isFormSaving}
               />
               {!readonly && <DeleteButton
                  onClick={() => setIsDeleteDialogOpen(true)}
                  disabled={!itemToEdit || !itemToEdit?.id || isDeleteDialogOpen}
                  loading={isFormSaving} />
               }
               {!readonly && <SaveButton
                  formId={__formId}
                  onClick={() => null}
                  disabled={Object.keys(errors).length > 0}
                  loading={isFormSaving} />
               }
            </>
         }>
         <div style={dialogContentStyle}>

            {errors && Object.keys(errors).length > 0 &&
               <>
                  <TitleError text='Please correct the errors below' />
                  {console.log('--------------Form Errors: -------------------------')}
                  {console.log(errors)}
               </>
            }

            <ApiErrorDisplay
               title='Error saving assistanceServiceEligibilityCriteriaState'
               keysLike={_keysLike}
            />

            <Form form={form}
               id={__formId}
               onFinish={handleSubmit(onSubmit)}>
               <DropdownField
                  control={control}
                  name='brandId'
                  label='Brand'
                  error={errors?.brandId}
                  multiple={false}
                  options={filteredBrandOptions}
                  setValue={setValue}
                  required={false}
                  search={true}
                  clearable={true}
                  disabled={(readonly ? true : false) || shouldBrandBeDisabled}
               />
               <DropdownField
                  control={control}
                  name='assistanceServiceId'
                  label='Assistance Service'
                  error={errors?.assistanceServiceId}
                  multiple={false}
                  options={assistanceProgramAndServicesOptions}
                  setValue={setValue}
                  required={true}
                  search={true}
                  clearable={true}
                  disabled={(readonly ? true : false) || shouldAssistanceServiceBeDisabled}
               />
               <DropdownField
                  control={control}
                  name='foundationDiseaseTypeId'
                  label='Foundation Disease Type'
                  error={errors?.foundationDiseaseTypeId}
                  multiple={false}
                  options={foundationDiseaseTypesWithFoundationOptions}
                  setValue={setValue}
                  required={false}
                  search={true}
                  clearable={true}
                  disabled={readonly ? true : false || shouldFoundationDiseaseTypeBeDisabled}
               />
               <DropdownField
                  control={control}
                  name='assistanceServiceEligibilityCriteriaCategoryId'
                  label='Category Name'
                  error={errors?.assistanceServiceEligibilityCriteriaCategoryId}
                  multiple={false}
                  options={eligibilityCategoryOptions}
                  setValue={setValue}
                  required={true}
                  search={true}
                  clearable={true}
                  disabled={readonly ? true : false}
               />

               <div style={{ display: 'flex' }}>
                  <RadioListField
                     options={[{ label: 'Yes', value: 'Yes' }, { label: 'Other Value', value: 'Other' }]}
                     label='Value'
                     name='inputValue'
                     control={control}
                     onChange={(val) => {
                        setRadioValue(val === 'Yes');
                        if (val !== 'Yes') {
                           setValue('value', '');
                        } else {
                           trigger('value');
                        }
                     }}
                     error={null}
                     setValue={setValue}
                     direction='horizontal'
                  />
                  {!radioValue &&
                     <div style={{ alignSelf: 'flex-end', marginLeft: '1rem' }}>
                        <InputField
                           control={control}
                           name='value'
                           type='text'
                           error={errors?.value}
                           required={true}
                           onChange={() => setTimeout(() => trigger('value'), 300)}
                           disabled={readonly ? true : false}
                        />
                     </div>
                  }
               </div>

               <InputField
                  control={control}
                  name='notes'
                  label='notes'
                  type='text'
                  error={errors?.notes}
                  required={false}
                  disabled={readonly ? true : false}
               />
               <InputField
                  control={control}
                  name='url'
                  label='url'
                  type='text'
                  error={errors?.url}
                  required={false}
                  disabled={readonly ? true : false}
               />
               <InputField
                  control={control}
                  name='sortOrder'
                  label='sortOrder'
                  type='number'
                  error={errors?.sortOrder}
                  required={false}
                  disabled={readonly ? true : false}
               />
            </Form>
         </div>

         <Dialog
            title='CONFIRM DELETE'
            open={isDeleteDialogOpen}
            style={{ maxWidth: '550px' }}
            actionButtons={
               <>
                  <CancelButton onClick={() => setIsDeleteDialogOpen(false)} />
                  <DeleteButton
                     disabled={isFormSaving}
                     onClick={() => handleDeleteClick()} />
               </>
            }
         >
            {`Are you sure you want to Delete the AssistanceServiceEligibilityCriteria with id: ${itemToEdit?.id}?`}
         </Dialog>

      </Dialog >
   )
   return html;
}

export default AssistanceServiceEligibilityCriteriaEditor;
