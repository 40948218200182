import * as React from 'react';
import {
   BENEFIT_PERIOD_DAY_FIELD_ID,
   BENEFIT_PERIOD_MONTH_FIELD_ID,
   BENEFIT_PERIOD_TYPE_FIELD_ID,
   CohortItem
} from "../../../store/cohort/CohortItemModel";
import { CSSProperties } from "react";
import { CALENDAR_BENEFIT_TYPE_ID, ENROLLMENT_BENEFIT_TYPE_ID } from "../../../store/program/BenefitPeriodTypeModel";
import { getMonth } from "../../../functions/time.functions";

interface IProps {
   cohortItems: CohortItem[];
}

const style: CSSProperties = {margin: 0, padding: 0, marginBottom: '0.25rem'};

const BenefitPeriodSummaryDisplay: React.FC<IProps> = (props) => {
   const {cohortItems} = props;
   return <>
      {cohortItems?.map((item, index) => {
         let label, valueText, toolTipText;
         switch (item?.cohortFieldId) {
            case BENEFIT_PERIOD_TYPE_FIELD_ID:
               label = 'Type';
               if (Number(item?.value) === ENROLLMENT_BENEFIT_TYPE_ID) {

                  toolTipText = 'Enrollment Period';
                  valueText = toolTipText;
               } else if (Number(item?.value) === CALENDAR_BENEFIT_TYPE_ID) {
                  toolTipText = 'Calendar 365'
                  valueText = toolTipText;
               } else {
                  valueText = '';
               }
               break;
            case BENEFIT_PERIOD_MONTH_FIELD_ID:
               label = 'Month';
               valueText = getMonth(item?.value as number);
               break;
            case BENEFIT_PERIOD_DAY_FIELD_ID:
               label = 'Day';
               valueText = item?.value;
               break;
         }

         return (
            <p style={style} key={`benefit-period-display-${index}`}>
               {label}:&ensp;<span title={toolTipText}>{valueText}</span>
            </p>
         );
      })}
   </>
}

export default BenefitPeriodSummaryDisplay;