import * as React from 'react';
import { useForm } from 'react-hook-form';
import ApiErrorDisplay from '../ApiErrorDisplay';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Dialog from '../Dialog';
import { CancelButton, DeleteButton, SaveButton } from '../shared/Buttons';
import Spinner from '../Spinner';
import { Form, Divider } from 'antd';
import { useFoundationDiseaseTypesWithFoundationOptions } from '../../store/program/FoundationDiseaseType';
import { useInsuranceClassOptions } from '../../store/program/InsuranceClassFetcher';
import { useAssistanceProgramAndServicesOptions } from '../../store/program/AssistanceServiceFetcher';
import {
   useFetchFundingStatusList,
   saveFundingStatus,
   deleteFundingStatus,
   fundingStatusBaseUrl
} from '../../store/program/FundingStatusFetcher';
import { FundingStatusWithInsuranceClasses, buildFundStatusTypeOptions } from '../../store/program/FundingStatusModel';
import { InputField, DropdownField } from '../shared/InputLibrary';
import { TitleError } from '../shared/AntComponents/Typography/Title';
import { useApiContext } from '../../store/ApiContext';
import { useErrorContext } from '../../store/ErrorContext';

interface IProps {
    isOpen: boolean;
    closeEditor: () => void;
    id?: number;
    readonly?: boolean;
};

const dialogContentStyle = {
    height: '580px',
    maxHeight: '95vh',
}

const yupFundingStatusSchema = yup.object({
    id: yup.number().notRequired(),
    assistanceServiceId: yup.number()
        .required('Assistance Service is required'),
    foundationDiseaseTypeId: yup.number()
        .required('FoundationDiseaseType is required'),
    //insuranceClassId: yup.number()
    //   .required('insuranceClassId is required'),
    status: yup.number()
        .required('status is required'),
    modifiedOn: yup.date().notRequired(),
    modifiedBy: yup.number().notRequired(),
    statusModifiedOn: yup.date().notRequired(),
    lastChecked: yup.date().notRequired(),
    externalId: yup.string()
        .max(200, 'Max length is 200 characters')
        .notRequired(),
    //   externalFundingData: yup.string().notRequired(),
    IsAPOnly: yup.boolean().notRequired(),
    modifiedByDisplay: yup.string().notRequired(),

   insuranceClasses: yup.array().min(1, "At least one insurance class must be selected").required("required")
});

interface IFundingStatusYup extends yup.Asserts<typeof yupFundingStatusSchema> { }

const _keysLike: string[] = [fundingStatusBaseUrl];

const FundingStatusEditor: React.FC<IProps> = (props) => {
   const { closeEditor, isOpen, id, readonly } = props;
   const __formId = "frmFundingStatus";
   const { httpGet, httpPost, httpDelete } = useApiContext();
   const { removeErrors } = useErrorContext();

   const { fundingStatusList, isLoading } = useFetchFundingStatusList(httpGet);

   const { insuranceClassOptions } = useInsuranceClassOptions(httpGet);
   const { foundationDiseaseTypesWithFoundationOptions } = useFoundationDiseaseTypesWithFoundationOptions(httpGet);
   const { assistanceProgramAndServicesOptions } = useAssistanceProgramAndServicesOptions(httpGet);

   const [isFormSaving, setIsFormSaving] = React.useState<boolean>(false);

   const {
      control,
      handleSubmit,
      formState: { errors },
      reset,
      getValues
   } = useForm<IFundingStatusYup>({
      // @ts-ignore  - Type instantiation is excessively deep and possibly infinite
      resolver: yupResolver<yup.AnyObject>(yupFundingStatusSchema),
      shouldFocusError: true,
   });

   React.useEffect(() => {
      let resetVal = {} as FundingStatusWithInsuranceClasses;
      if (id && fundingStatusList?.length > 0) {
         resetVal = fundingStatusList?.find(y => y.id === id) as FundingStatusWithInsuranceClasses;
         resetVal.insuranceClasses = resetVal?.insuranceClassId ? [resetVal.insuranceClassId] : [];
      }
      reset(resetVal);
      // for "reset"
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [id, fundingStatusList])

   const onSubmit = async (data: IFundingStatusYup) => {

      setIsFormSaving(true);

      //console.log('--------------AssistanceServiceEditor-------------------------')
      //console.log(errors)

      //ts hoops to convert data with unknown props into assistance programs
      const item = data as unknown as FundingStatusWithInsuranceClasses;


      await saveFundingStatus(httpPost, item)
         .then(handleClose)
         .catch(err => console.error('Error Saving Funding Status'))
         .finally(() => setIsFormSaving(false));
   }

   const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState<boolean>(false);

   const handleDeleteClick = async () => {
      setIsDeleteDialogOpen(false);
      await deleteFundingStatus(httpDelete, Number(getValues().id))
         .then(handleClose)
         .catch(err => console.error('Error Deleting Funding Status'));
   }

   const handleClose = () => {
      setIsFormSaving(false);
      setIsDeleteDialogOpen(false);
      removeErrors({ keysLike: _keysLike });
      closeEditor();
   }

   if (isFormSaving) {
      return <Spinner message='Saving Funding Status...' />
   }

   //console.log('--------------FundingStatusEditor-------------------------')
   //console.log(errors)

   const html = (
      <Dialog
         scrollingContent={true}
         title={'Funding Status Editor'}
         open={isOpen}
         size="large"
         actionButtons={
            <>
               <CancelButton onClick={() => {
                  handleClose();
               }} />
               {!readonly && <DeleteButton
                  onClick={() => setIsDeleteDialogOpen(true)}
                  disabled={!getValues()?.id || isDeleteDialogOpen}
                  loading={isDeleteDialogOpen} />
               }
               {!readonly && <SaveButton
                  formId={__formId}
                  onClick={() => null}
                  disabled={Object.keys(errors).length > 0}
                  loading={isFormSaving} />
               }
            </>
         }>
         <div style={dialogContentStyle}>

            {errors && Object.keys(errors).length > 0 &&
               <>
                  <TitleError text='Please correct the errors below' />
                  {console.log('--------------Form Errors: -------------------------')}
                  {console.log(errors)}
               </>
            }

            <ApiErrorDisplay
               title='Error saving Funding Status'
               keysLike={_keysLike}
            />

            <Form id={__formId} onFinish={handleSubmit(onSubmit)}>
               <DropdownField
                  control={control}
                  name='assistanceServiceId'
                  label='Assistance Service Id'
                  error={errors?.assistanceServiceId}
                  multiple={false}
                  options={assistanceProgramAndServicesOptions}
                  required={true}
                  search={true}
                  clearable={true}
                  disabled={readonly ? true : false}
               />
               <DropdownField
                  control={control}
                  name='foundationDiseaseTypeId'
                  label='Foundation Disease Type Id'
                  error={errors?.foundationDiseaseTypeId}
                  multiple={false}
                  options={foundationDiseaseTypesWithFoundationOptions}
                  required={true}
                  search={true}
                  clearable={true}
                  disabled={readonly ? true : false}
               />
               <DropdownField
                  control={control}
                  name='insuranceClasses'
                  label='Insurance Classes'
                  error={errors?.insuranceClasses}
                  multiple={true}
                  options={insuranceClassOptions}
                  required={true}
                  search={true}
                  clearable={true}
                  disabled={readonly ? true : false}
               />
               <DropdownField
                  control={control}
                  name='status'
                  label='Status'
                  error={errors?.status}
                  multiple={false}
                  options={buildFundStatusTypeOptions(false)}
                  required={true}
                  search={true}
                  clearable={true}
                  disabled={readonly ? true : false}
               />
               <InputField
                  control={control}
                  name='externalId'
                  label='External Id'
                  type='text'
                  error={errors?.externalId}
                  required={false}
                  disabled={readonly ? true : false}
               />
               {id &&
                  <>
                     <Divider />
                     <InputField
                        control={control}
                        name='modifiedOn'
                        label='Modified On'
                        type='text'
                        error={errors?.modifiedOn}
                        required={false}
                        disabled={true}
                     />
                     <InputField
                        control={control}
                        name='modifiedByDisplay'
                        label='Modified By Display'
                        type='text'
                        error={errors?.modifiedByDisplay}
                        required={false}
                        disabled={true}
                     />
                     <InputField
                        control={control}
                        name='statusModifiedOn'
                        label='Status Modified On'
                        type='text'
                        error={errors?.statusModifiedOn}
                        required={false}
                        disabled={true}
                     />
                  </>
               }
            </Form>
         </div>

         <Dialog
            title='CONFIRM DELETE'
            open={isDeleteDialogOpen}
            style={{ maxWidth: '550px' }}
            actionButtons={
               <>
                  <CancelButton onClick={() => setIsDeleteDialogOpen(false)} />
                  <DeleteButton
                     disabled={isFormSaving}
                     onClick={() => handleDeleteClick()} />
               </>
            }
         >
            {`Are you sure you want to Delete the Funding Status record with id: ${getValues()?.id}?`}
         </Dialog>

      </Dialog>
   )
   return html;
}
export default FundingStatusEditor;
