import { IStatus } from '../models';
import { useEffect, useState } from 'react';
import { IOptionItem } from '../../functions/option.functions';
import useSWR from 'swr';
import { DrugRoute } from './DrugRouteModel';
import { stringComparer } from '../../functions/comparer.functions';
import { HttpError, TGet } from '../../functions/httpClient';

const drugRouteBaseUrl = `api/drug`;
const drugRouteGetUrl = `${drugRouteBaseUrl}/GetDrugRoute`;

const swrOptions = {
   refreshInterval: 0,
   revalidateOnFocus: false,
   dedupingInterval: 60000
};


export function useFetchDrugRoute(httpGet: TGet): IStatus & { drugRoutes: DrugRoute[] } {
   const { data, isValidating, error } = useSWR<DrugRoute[], HttpError>(
      drugRouteGetUrl,
      httpGet,
      { ...swrOptions }
   );

   return {
      drugRoutes: data,
      isLoading: !error && !data && isValidating,
      error: error?.message
   };
}

export function useDrugRouteOptions(httpGet: TGet): { drugRouteOptions: IOptionItem[] } {
   const { drugRoutes } = useFetchDrugRoute(httpGet);
   const [options, setOptions] = useState<IOptionItem[]>([]);

   useEffect(() => {
      const buildOptions = () => {
         if (drugRoutes?.length > 0) {

            const sorted = [...drugRoutes];
            sorted.sort((a: DrugRoute, b: DrugRoute) => stringComparer(String(a.routeDescription), String(b.routeDescription)));

            const options = sorted.map((o): IOptionItem => ({
               key: o.id,
               label: `[${o.id}]: ${o.routeDescription ?? 'not specified'}`,
               value: o.id
            } as IOptionItem));
            setOptions(options);
         }
      }
      buildOptions();

   }, [drugRoutes]);


   return {
      drugRouteOptions: options
   };
}