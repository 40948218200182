import * as React from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { InputField, DropdownField } from '../../shared/InputLibrary';
import { Form } from 'antd';
import Dialog from '../../Dialog';
import { SaveButton, DeleteButton, CancelButton } from '../../shared/Buttons';
import Spinner from '../../Spinner';
import { useDrugOptions } from '../../../store/drug/DrugFetcher';
import { Ndc } from '../../../store/drug/NdcModel';
import { useFetchNdc, saveNdc, deleteNdc, ndcSaveUrl, ndcDeleteUrl } from '../../../store/drug/NdcFetcher';
import ApiErrorDisplay from '../../ApiErrorDisplay';
import { TitleError } from '../../shared/AntComponents/Typography/Title';
import {  useApiContext } from '../../../store/ApiContext';
import { useErrorContext } from '../../../store/ErrorContext';

interface IProps {
    isOpen: boolean;
    closeEditor: () => void;
    id?: number;
};

const dialogContentStyle = {
    height: '580px',
    maxHeight: '95vh',
}

const yupNdcSchema = yup.object({
    id: yup.number().notRequired(),
   ndc11: yup.string()
        .trim()
        .required('Ndc11 is required')
        .matches(/^[0-9]+$/, "Must be only digits")
        .min(11, 'Must be exactly 11 digits')
        .max(11, 'Must be exactly 11 digits'),
   ndc10: yup.string()
        .trim()
        .max(12, 'Max length is 12 characters')
        .matches(new RegExp('^([0-9]{4}|[0-9]{5})-([0-9]{3}|[0-9]{4})-([0-9]{1}|[0-9]{2})$'),
            {
                message: 'Invalid NDC10 Format: Enter format in 9999-9999-99 or 99999-999-99 or 99999-9999-9',
                excludeEmptyString: true
            })
        .required('Ndc10 is required'),
    drugId: yup.number().required(),
    description: yup.string()
        .max(256, 'Max length is 256 characters')
        .required(),
});

interface INdcYup extends yup.Asserts<typeof yupNdcSchema> { }
const __formId = "frmNdc";

const NdcEditor: React.FC<IProps> = (props) => {
   const { closeEditor, isOpen, id } = props;
   const _keys: string[] = id ? [ndcSaveUrl, ndcDeleteUrl(id)] : [ndcSaveUrl];
   const { httpPost, httpDelete, httpGet } = useApiContext();
   const { removeErrors } = useErrorContext();
   const { ndcList } = useFetchNdc(httpGet);
   const { drugOptions } = useDrugOptions(httpGet);

   const [isFormSaving, setIsFormSaving] = React.useState<boolean>(false);

   const {
      control,
      handleSubmit,
      formState: { errors },
      reset,
      getValues
   } = useForm<INdcYup>({
      resolver: yupResolver<yup.AnyObject>(yupNdcSchema),
      shouldFocusError: true,
   });

   React.useEffect(() => {
      let resetVal = {};
      if (id && ndcList?.length > 0) {
         resetVal = ndcList.find(y => y.id === id);
      }
      reset(resetVal);
      // for "reset"
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [ndcList, id])

   const onSubmit = (data: INdcYup) => {
      //console.log('--------------Editor-------------------------')
      //console.log(errors)

      //ts hoops to convert data with unknown props into assistance programs
      const item = data as unknown as Ndc;

      setIsFormSaving(true);
      saveNdc(httpPost, item)
         .then(handleClose)
         .catch(err => console.error('Error Saving NDC', err))
         .finally(() => {
            setIsFormSaving(false);
         })
   }

   const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState<boolean>(false);

   const handleDeleteClick = () => {
      setIsDeleteDialogOpen(false);
      deleteNdc(httpDelete, getValues().id)
         .then(handleClose)
         .catch(err => console.error('Error Deleting NDC', err))
   }

   const handleClose = () => {
      setIsFormSaving(false);
      setIsDeleteDialogOpen(false);
      removeErrors({ keys: _keys });
      closeEditor();
   }

   if (isFormSaving) {
      return <Spinner message='Saving Ndc...' />
   }

   //console.log('--------------NdcEditor-------------------------')
   //console.log(errors)


   const html = (
      <Dialog
         scrollingContent={true}
         title={'Ndc Editor'}
         open={isOpen}
         size="large"
         actionButtons={
            <>
               <CancelButton onClick={() => {
                  handleClose();
               }} />
               <DeleteButton
                  onClick={() => setIsDeleteDialogOpen(true)}
                  disabled={!getValues()?.id || isDeleteDialogOpen}
                  loading={isDeleteDialogOpen} />
               <SaveButton
                  formId={__formId}
                  onClick={() => null}
                  disabled={Object.keys(errors).length > 0}
                  loading={isFormSaving} />
            </>
         }>
         <div style={dialogContentStyle}>

            {errors && Object.keys(errors).length > 0 &&
               <>
               <TitleError
                  text='Please correct the errors below' />
                  {console.log('--------------Form Errors: -------------------------')}
                  {console.log(errors)}
               </>
            }

            <ApiErrorDisplay
               title='Error saving Ndc'
               keys={_keys}
            />

            <Form id={__formId} onFinish={handleSubmit(onSubmit)}>

               <InputField
                  control={control}
                  error={errors?.ndc11}
                  name='ndc11'
                  label='Ndc 11'
                  type='text'
                  required={true}
                  maxLength={11}
               />
               <InputField
                  control={control}
                  error={errors?.ndc10}
                  name='ndc10'
                  label='Ndc 10'
                  type='text'
                  required={true}
                  maxLength={12}
               />
               <DropdownField
                  control={control}
                  error={errors?.drugId}
                  name='drugId'
                  label='Drug Id'
                  multiple={false}
                  options={drugOptions}
                  required={true}
                  search={true}
                  clearable={true}
               />
               <InputField
                  control={control}
                  error={errors?.description}
                  name='description'
                  label='Description'
                  type='text'
                  required={true}
               />
            </Form>
         </div>

         <Dialog
            title='CONFIRM DELETE'
            open={isDeleteDialogOpen}
            style={{ maxWidth: '550px' }}
            actionButtons={
               <>
                  <CancelButton onClick={() => setIsDeleteDialogOpen(false)} />
                  <DeleteButton
                     disabled={isFormSaving}
                     onClick={() => handleDeleteClick()} />
               </>
            }
         >
            {`Are you sure you want to Delete the Ndc (${getValues()?.ndc11}) ${getValues()?.description || ''}?`}
         </Dialog>

      </Dialog>
   )
   return html;
}

export default NdcEditor;

