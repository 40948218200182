import * as React from 'react';
import { Alert, List } from 'antd';
import { HttpError } from '../functions/httpClient';
import { KeyWithVerb, useErrorContext, IApiErrorMessage } from '../store/ErrorContext';

//Use keys, andor keysLike, andor keysWithVerb.  
//Use addError from ErrorContext to add an error to this list
interface IProps {
   title: string;
   keys?: string[]; //exact keys to match
   keysLike?: string[];  //key can contain partial strings
   keysWithVerb?: KeyWithVerb[]; // exact keys with a verb to match (situations where GET/POST are done against the same URL)
   onKeyChange?: (keys: string[]) => void;
}

export const getMessage = (error: IApiErrorMessage | HttpError | string): string => {
   const apiError = error as IApiErrorMessage;
   const httpError: HttpError = apiError?.error?.message ? apiError.error : error as HttpError;

   const errorDescription = httpError?.message ? `[${httpError.httpErrorCode}]: ${httpError.message}` : error as string;
   return errorDescription;
}

const filterKeys = (errorMessage: IApiErrorMessage, keys?: string[], ): boolean => {
   if (!keys || keys.length === 0) return false;

   return keys.some(y => y.toLowerCase().trim() === `${errorMessage.key}`.toLowerCase().trim());
}

const filterKeysLike = (errorMessage?: IApiErrorMessage, keysLike?: string[]): boolean => {
   if (!keysLike || keysLike.length === 0) return false;

   return keysLike.some(y => `${errorMessage.key}`.toLowerCase().trim().indexOf(y.toLowerCase().trim()) > -1);
}

const filterKeysWithVerb = (errorMessage?: IApiErrorMessage, keysWithVerb?: KeyWithVerb[]): boolean => {
   if (!keysWithVerb || keysWithVerb.length === 0) return false;

   return keysWithVerb.some(y => y.key.toLowerCase().trim() === `${errorMessage.key}`.toLowerCase().trim() &&
      y.verb === errorMessage.verb);
}

const ApiErrorDisplay: React.FC<IProps> = (props) => {
   const { apiErrors, removeError } = useErrorContext();

   const { title, keys, keysLike, keysWithVerb, onKeyChange } = props;

   const errorMessages = React.useMemo(() => {
      
      const _apiErrs = apiErrors?.filter(y =>
         filterKeys(y, keys) ||
         filterKeysLike(y, keysLike) ||
         filterKeysWithVerb(y, keysWithVerb)
      ) ?? [];

      onKeyChange && onKeyChange(_apiErrs?.map(y => y.key));
      return _apiErrs;
   }, [apiErrors, keys, keysLike, keysWithVerb, onKeyChange]);

   const messagesFromApi = (errorMessages && errorMessages?.length > 0 ? errorMessages.map(e => getMessage(e)) : []) ?? [];

   const onClose = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      const _errorMessages = errorMessages?.length > 0 ? errorMessages : [];

      const _all = [ ..._errorMessages ]
      _all?.forEach((apiErr) => {
         const _apiError = apiErr as IApiErrorMessage;
         if (_apiError?.key) {
            removeError(_apiError?.key);
         }
      })
   };
   const displayData = [...messagesFromApi]
   if (!displayData || displayData?.length === 0)
      return;

   const html = (
      <Alert
         message={title ?? 'Api Error'}
         className='ap-apierrors'
         description={<>
            <List               
               style={{ overflowWrap: 'anywhere' }}
               size="small"
               dataSource={displayData}
               renderItem={(item, idx) => <List.Item key={idx}>{item}</List.Item>}
            />
         </>}
         type="error"
         style={{ overflowWrap: 'break-word' }}
         closable={true}
         onClose={onClose}
      />
   );

   return html;
}

export default ApiErrorDisplay;
