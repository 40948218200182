import * as React from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Dialog from '../Dialog';
import { SaveButton, DeleteButton, CancelButton } from '../shared/Buttons';
import Spinner from '../Spinner';
import { Manufacturer } from '../../store/program/ManufacturerModel';
import { useFetchManufacturer, saveManufacturer, deleteManufacturer, manufacturerBaseUrl } from '../../store/program/ManufacturerFetcher';
import ApiErrorDisplay from '../ApiErrorDisplay';
import { Form, Divider } from 'antd';
import { InputField, DatePickerField } from '../shared/InputLibrary';
import { TitleError } from '../shared/AntComponents/Typography/Title';
import { useApiContext } from '../../store/ApiContext';
import { useErrorContext } from '../../store/ErrorContext';

interface IProps {
   isOpen: boolean;
   closeEditor: () => void;
   id?: number;
};

const dialogContentStyle = {
   maxHeight: '95vh',
}

const yupManufacturerSchema = yup.object({
   id: yup.number().notRequired(),
   manufacturerName: yup.string()
      .max(256, 'Max length is 256 characters')
      .required(),
   managedTenantId: yup.string()
      .max(100, 'Max length is 100 characters')
      .notRequired(),
   createdBy: yup.string().notRequired(),
   createdOn: yup.date().notRequired(),
   modifiedBy: yup.string().notRequired(),
   modifiedOn: yup.date().notRequired(),
});

interface IManufacturerYup extends yup.Asserts<typeof yupManufacturerSchema> { }

const __formId = "frmManufacturer";
const _keysLike: string[] = [manufacturerBaseUrl];

const ManufacturerEditor: React.FC<IProps> = (props) => {
   const { closeEditor, isOpen, id } = props;
   const { httpGet, httpPost, httpDelete } = useApiContext();
   const { removeErrors } = useErrorContext();
   const { manufacturers } = useFetchManufacturer(httpGet);
   const [isFormSaving, setIsFormSaving] = React.useState<boolean>(false);

   const {
      control,
      handleSubmit,
      formState: { errors },
      reset,
      getValues
   } = useForm<IManufacturerYup>({
      resolver: yupResolver<yup.AnyObject>(yupManufacturerSchema),
      shouldFocusError: true,
   });

   const handleReset = React.useCallback((item: Manufacturer) => {
      reset(item);
   }, [reset]);

   React.useEffect(() => {
      const itemToEdit = manufacturers?.find(y => y.id === id);
      handleReset(itemToEdit);
      setIsFormSaving(false);
   }, [handleReset, manufacturers, id])

   const onSubmit = (data: IManufacturerYup): void => {
      //console.log('--------------Editor-------------------------')
      //console.log(errors)

      //ts hoops to convert data with unknown props into assistance programs
      const item = data as unknown as Manufacturer;

      setIsFormSaving(true);
      saveManufacturer(httpPost, item)
         .then(handleClose)
         .catch(err => console.error('Error Saving Manufacturer', err))
         .finally(() => {
            setIsFormSaving(false);
         });
   }

   const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState<boolean>(false);

   const handleDeleteClick = () => {
      setIsDeleteDialogOpen(true);
      deleteManufacturer(httpDelete, getValues().id)
         .then(handleClose)
         .catch(err => console.error('Error Deleting Manufacturer', err))
         .finally(() => {
            setIsFormSaving(false);
         });
   }

   const handleClose = () => {
      setIsFormSaving(false);
      setIsDeleteDialogOpen(false);
      removeErrors({ keysLike: _keysLike });
      closeEditor();
   }

   if (isFormSaving) {
      return <div><Spinner message='Saving Manufacturer...' /></div>
   }

   //console.log('--------------ManufacturerEditor-------------------------')
   //console.log(errors)


   const html = (
      <Dialog
         scrollingContent={true}
         title={'Manufacturer Editor'}
         open={isOpen}
         size="large"
         actionButtons={
            <>
               <CancelButton onClick={() => {
                  handleClose();
               }} />
               <DeleteButton
                  onClick={() => setIsDeleteDialogOpen(true)}
                  disabled={!getValues()?.id || isDeleteDialogOpen}
                  loading={isDeleteDialogOpen} />
               <SaveButton
                  formId={__formId}
                  onClick={() => null}
                  disabled={Object.keys(errors).length > 0}
                  loading={isFormSaving} />
            </>
         }>
         <div style={dialogContentStyle}>

            {errors && Object.keys(errors).length > 0 &&
               <>
                  <TitleError text='Please correct the errors below' />
                  {console.log('--------------Form Errors: -------------------------')}
                  {console.log(errors)}
               </>
            }

            <ApiErrorDisplay
               title='Error saving Manufacturer'
               keysLike={_keysLike}
            />

            <Form id={__formId} onFinish={handleSubmit(onSubmit)}>
               <InputField
                  control={control}
                  name='manufacturerName'
                  label='Manufacturer Name'
                  type='text'
                  error={errors?.manufacturerName}
                  disabled={false}
                  required={true} />
               <InputField
                  control={control}
                  name='managedTenantId'
                  label='Managed Tenant Id'
                  type='text'
                  error={errors?.managedTenantId}
                  required={false} />
               {getValues()?.id && <Divider />}
               {getValues()?.id && getValues().createdBy &&
                  <InputField
                     control={control}
                     name='createdBy'
                     label='Created By'
                     type='text'
                     error={errors?.createdBy}
                     disabled={true} />
               }
               {getValues()?.id && getValues().createdOn &&
                  <DatePickerField
                     control={control}
                     name='createdOn'
                     label='Created On'
                     error={errors?.createdOn}
                     disabled={true} />
               }
               {getValues()?.id && getValues().modifiedBy &&
                  <InputField
                     control={control}
                     name='modifiedBy'
                     label='Modified By'
                     type='text'
                     error={errors?.modifiedBy}
                     disabled={true} />
               }
               {getValues()?.id && getValues().modifiedOn &&
                  <DatePickerField
                     control={control}
                     name='modifiedOn'
                     label='Modified On'
                     error={errors?.modifiedOn}
                     disabled={true} />
               }
            </Form>
         </div>

         <Dialog
            title='CONFIRM DELETE'
            open={isDeleteDialogOpen}
            style={{ maxWidth: '550px' }}
            actionButtons={
               <>
                  <CancelButton onClick={() => setIsDeleteDialogOpen(false)} />
                  <DeleteButton
                     disabled={isFormSaving}
                     onClick={() => handleDeleteClick()} />
               </>
            }
         >
            {`Are you sure you want to Delete the Manufacturer (${getValues()?.id}) ${getValues()?.manufacturerName || ''}?`}
         </Dialog>

      </Dialog>
   )
   return html;
}

export default ManufacturerEditor;
