import { Dropdown, MenuProps, Row, Space } from 'antd';
import Col from 'antd/es/grid/col';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import * as React from 'react';
import { useNavigate } from 'react-router';
import { useLayoutContext } from '../../../layouts/LayoutContext';
import { useGetPlanTypes } from '../../../store/adparo/scorecard/ScorecardFetcher';
import { useApiContext } from '../../../store/ApiContext';
import { useReportList } from '../../../store/report/ReportFetcher';
import { KnownSettings } from '../../../store/SettingsModel';
import colorWheel from '../../../Theme/ColorWheel';
import CustomIcon, { CustomIconType } from '../../shared/AntComponents/CustomIcon';
import { ActionButton } from '../../shared/Buttons';
import ScorecardReport, { IScorecardReportProps } from './ScorecardReport';

const ScorecardIndex: React.FC = () => {
   const navigate = useNavigate();
   const { httpGet } = useApiContext();

   const { setCardName } = useLayoutContext();
   const [reportProps, setReportProps] = React.useState<IScorecardReportProps>();
   const { powerBIReports, isLoading: isLoadingReports } = useReportList(httpGet);
   const { planTypes, isLoading } = useGetPlanTypes(httpGet);

   const scorecardReports = powerBIReports?.filter(y => y.reportType.toLowerCase().indexOf(KnownSettings.ReportType_AdparoScorecard.toLowerCase()) > -1);

   const scorecardMenu = React.useMemo(() => {
      if (!planTypes) return;

      const items = planTypes?.map((planType, index) => {
         const menuProp: ItemType = {
            key: index,
            label: (
               <a target="_blank" rel="noopener"
                  onClick={() => {
                     setReportProps(undefined)
                     setCardName(planType.name)
                     navigate(`/adparo/scorecard/type/${planType.id}`);
                  }}>
                  {planType.name}
               </a>
            )
         }
         return menuProp;
      });
      return { items } as MenuProps;
      // for navigate() & setCardName
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [planTypes]);

   const reportMenu = React.useMemo(() => {
      if (!scorecardReports) return;

      const items = scorecardReports?.map((report, idx) => {
         const menuProp: ItemType = {
            key: idx,
            label: (
               <a target="_blank" rel="noopener"
                  onClick={() => {
                     setReportProps({
                        workspaceId: report.workspaceId,
                        reportId: report.reportId,
                        pageId: report.reportPageId
                     })
                  }}>
                  {report.reportPageName}
               </a>
            )
         };
         return menuProp;
      });
      return { items } as MenuProps;

   }, [scorecardReports]);

   const html = (
      <>
         <div style={{ padding: 5 }}>
            <Row gutter={[8, 32]} >
               <Col>
                  <Space>
                     <Dropdown disabled={isLoading} menu={scorecardMenu} trigger={['click']}>
                        <ActionButton
                           icon={<CustomIcon
                              type={reportProps ? CustomIconType.CaretDownFilled : CustomIconType.CaretUpFilled}
                              style={{
                                 color: colorWheel.tangerineOrange
                              }}
                           />}
                           buttonText='Add New Scorecard'
                           onClick={() => { }}
                           loading={isLoading}
                           disabled={isLoading}
                        />
                     </Dropdown>
                     <Dropdown disabled={isLoadingReports || !(reportMenu.items?.length > 0)} menu={reportMenu} trigger={['click']}>
                        <ActionButton
                           icon={<CustomIcon
                              type={reportProps ? CustomIconType.CaretDownFilled : CustomIconType.CaretUpFilled}
                              style={{
                                 color: colorWheel.tangerineOrange
                              }}
                           />}
                           disabled={isLoadingReports || !(reportMenu.items?.length > 0)}
                           buttonText='View Reports'
                           onClick={() => { }}
                        ></ActionButton>
                     </Dropdown>
                  </Space>
               </Col>
            </Row>
         </div>
         {reportProps &&
            <ScorecardReport {...reportProps} />
         }
      </>
   );

   return html;
}

export default ScorecardIndex;