import { IOptionItem } from "../../functions/option.functions";

export interface CohortItemStatus {
   id: number;
   status: string;
}

export enum CohortItemStatusType {
   New = 1,
   Audited_Confirm_No_Change = 2,
   Recommended_Change = 3,
   Accepted_Change = 4,
   Rejected_Change = 5,
   Published_Change = 6
}

export const cohortItemStatuses: IOptionItem[] = [
   { value: CohortItemStatusType.New, label: "New" }
   , { value: CohortItemStatusType.Audited_Confirm_No_Change, label: "Audited\\Confirm No Changes" }
   , { value: CohortItemStatusType.Recommended_Change, label: "Recommended Changes" }
   , { value: CohortItemStatusType.Accepted_Change, label: "Accepted Changes" }
   , { value: CohortItemStatusType.Rejected_Change, label: "Rejected Changes" }
   , { value: CohortItemStatusType.Published_Change, label: "Published Changes" }
];

export const getItemStatus = (cohortItemStatus: number): string => {
   if (!cohortItemStatus || !isFinite(cohortItemStatus)) return undefined;

   return String(cohortItemStatuses.find(y => y.value === cohortItemStatus).label);
}
