import { yupResolver } from '@hookform/resolvers/yup';
import { Form } from 'antd';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { Row, Col } from 'antd';
import * as yup from 'yup';
import { BrandViewModel } from '../../../store/drug/BrandModel';
import { deleteBrandResource, saveBrandResource, brandResourceSaveUrl, brandResourceDeleteUrl } from '../../../store/drug/BrandResourceFetcher';
import { BrandResource } from '../../../store/drug/BrandResourceModel';
import ApiErrorDisplay from '../../ApiErrorDisplay';
import Dialog from '../../Dialog';
import { CancelButton, DeleteButton, SaveButton } from '../../shared/Buttons';
import { InputField, RadioListField, TextAreaField } from '../../shared/InputLibrary';
import Spinner from '../../Spinner';
import { IOptionItem } from '../../../functions/option.functions';
import { TitleError } from '../../shared/AntComponents/Typography/Title';
import { useApiContext } from '../../../store/ApiContext';
import { useErrorContext } from '../../../store/ErrorContext';

interface IProps {
    isOpen: boolean;
    closeEditor: (resourceId?: number) => void;
    brand: BrandViewModel;
    brandResource?: BrandResource;
};

const dialogContentStyle = {
    height: '580px',
    maxHeight: '95vh',
}

const yupBrandResourceSchema = yup.object({
    id: yup.number().notRequired(),
    brandId: yup.number()
        .required('Brand Id is required'),
    resourceType: yup.number()
        .required('Resource Type is required'),
    description: yup.string()
        .required('Description is required')
        .max(1024, 'Max length is 1024 characters'),
    resourceUrl: yup.string()
        .url('The Resource URL must be a fully-qualified url that begins with "https://" or "https://"')
        .required('Url is required')
        .max(1024, 'Max length is 1024 characters')
});

interface IBrandResourceYup extends yup.Asserts<typeof yupBrandResourceSchema> { }
const __formId = "frmBrandResource";

const resourceTypeOptions = [
   {
      key: 0,
      value: 0,
      label: 'PDF',
   } as IOptionItem
   , {
      key: 1,
      value: 1,
      label: 'Link',
   } as IOptionItem
];

const BrandResourceEditor: React.FC<IProps> = (props) => {
   const { isOpen, closeEditor, brand, brandResource } = props;
   const _keys: string[] = brandResource ? [brandResourceSaveUrl, brandResourceDeleteUrl(brandResource.id)] : [brandResourceSaveUrl];

   const { httpDelete, httpPost } = useApiContext();
   const { removeErrors } = useErrorContext();
   const [isFormSaving, setIsFormSaving] = React.useState<boolean>(false);

   const {
      control,
      handleSubmit,
      formState: { errors },
      reset,
      setValue,
      getValues
   } = useForm<IBrandResourceYup>({
      resolver: yupResolver<yup.AnyObject>(yupBrandResourceSchema),
      shouldFocusError: true,
   });


   React.useEffect(() => {
      let resetVal = {};
      if (brandResource) {
         resetVal = brandResource;
      } else if (brand) {
         resetVal = {brandId: brand.id}
      }
      reset(resetVal);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [brand, brandResource, isOpen]);


   const onSubmit = async (data: IBrandResourceYup) => {
      //console.log('--------------AssistanceProgramResourceEditor-------------------------')
      //console.log(errors)

      //ts hoops to convert data with unknown props into assistance programs
      setIsFormSaving(true);
      const brandResource = data as unknown as BrandResource;
      await saveBrandResource(httpPost, brandResource)
         .then(handleClose)
         .catch(err => console.error('Error Saving Brand Resource', err))
         .finally(() => {
            setIsFormSaving(false);
         });      
   }

   const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState<boolean>(false);

   const handleDeleteClick = async () => {
      await deleteBrandResource(httpDelete, getValues().id)
         .then(handleClose)
         .catch(err => console.error('Error Deleting Brand Resource', err))
   }

   const handleClose = () => {
      setIsFormSaving(false);
      setIsDeleteDialogOpen(false);
      removeErrors({ keys: _keys });
      closeEditor();
   }

   if (isFormSaving) return <Spinner message='Saving Brand Resource...' />

   //console.log('--------------AssistanceServcieEditor-------------------------')
   //console.log(errors)


   const html = (
      <Dialog
         scrollingContent={true}
         title={`Brand Resource Editor for ${brand?.brandName}`}
         open={isOpen}
         size="large"
         pending={isFormSaving}
         actionButtons={
            <>
               <CancelButton onClick={() => {
                  handleClose();
               }} />
               <DeleteButton
                  onClick={() => setIsDeleteDialogOpen(true)}
                  disabled={!getValues()?.id || isDeleteDialogOpen}
                  loading={isDeleteDialogOpen} />
               <SaveButton
                  formId={__formId}
                  onClick={() => null}
                  disabled={Object.keys(errors).length > 0}
                  loading={isFormSaving} />
            </>
         }>
         <div style={dialogContentStyle}>

            {errors && Object.keys(errors).length > 0 && <>
               <TitleError
                  text='Please correct the errors below' />
               {console.log('--------------Form Errors: -------------------------')}
               {console.log(errors)}
            </>
            }

            <ApiErrorDisplay
               title='Error saving Branch Resource'
               keys={_keys}
            />

            <Row>
               <Col span={24}>
                  <Form id={__formId} onFinish={handleSubmit(onSubmit)}>
                     <RadioListField
                        control={control}
                        name='resourceType'
                        label='Resource Type'
                        error={errors?.resourceType}
                        options={resourceTypeOptions}
                        setValue={setValue}
                        required={true}
                        direction='vertical'
                     />
                     <TextAreaField
                        control={control}
                        name='description'
                        label='Description'
                        error={errors?.description}
                        required={true}
                     />
                     <InputField
                        control={control}
                        name='resourceUrl'
                        label='Resource Url'
                        type='text'
                        error={errors?.resourceUrl}
                        required={true}
                     />
                  </Form>
               </Col>
            </Row>
         </div>

         <Dialog
            title='CONFIRM DELETE'
            open={isDeleteDialogOpen}
            style={{ maxWidth: '550px' }}
            actionButtons={
               <>
                  <CancelButton onClick={() => setIsDeleteDialogOpen(false)} />
                  <DeleteButton
                     disabled={isFormSaving}
                     onClick={() => handleDeleteClick()} />
               </>
            }>
            {`Are you sure you want to Delete the Brand Resource (${getValues()?.id}) ${getValues()?.description || ''}?`}
         </Dialog>

      </Dialog>
   )
   return html;
}

export default BrandResourceEditor;
