import * as React from 'react';
import { IFormError } from '../../../functions/form.contracts';
import {
   BENEFIT_PERIOD_DAY_FIELD_ID,
   BENEFIT_PERIOD_MONTH_FIELD_ID,
   BENEFIT_PERIOD_TYPE_FIELD_ID,
   CohortItem
} from '../../../store/cohort/CohortItemModel';
import { BasicDropdownField } from "../../shared/BasicInputLibrary";
import { useBenefitPeriodTypeOptions } from "../../../store/program/BenefitPeriodTypeFetcher";
import { useApiContext } from "../../../store/ApiContext";
import {
   CALENDAR_BENEFIT_TYPE_ID,
   dayOptions,
   ENROLLMENT_BENEFIT_TYPE_ID,
   monthOptions
} from "../../../store/program/BenefitPeriodTypeModel";
import { useCohortContext } from "../CohortContext";
import { isDateValid } from "../../../functions/time.functions";
import { leftPadDayOrMonth } from "../../../functions/common.functions";

interface Props {
   cohortItems: CohortItem[];
   onInputChanges: (changes: {cohortFieldId: number, val: any}[]) => void;
}

const BenefitPeriodSelector: React.FC<Props> = (props) => {
   const {setFormErrors, formErrors} = useCohortContext();
   const {cohortItems, onInputChanges} = props;
   const {httpGet} = useApiContext();
   const {benefitPeriodTypeOptions} = useBenefitPeriodTypeOptions(httpGet);

   // CohortItem objects based on sequence in DB
   const typeItem = cohortItems?.find(ci => ci.cohortFieldId === BENEFIT_PERIOD_TYPE_FIELD_ID);
   const monthItem = cohortItems?.find(ci => ci.cohortFieldId === BENEFIT_PERIOD_MONTH_FIELD_ID);
   const dayItem = cohortItems?.find(ci => ci.cohortFieldId === BENEFIT_PERIOD_DAY_FIELD_ID);

   const typeItemValue = React.useMemo(() => parseInt(String(typeItem?.value), 10), [typeItem]);

   const isEnrollmentPeriod = typeItemValue === ENROLLMENT_BENEFIT_TYPE_ID;

   const validateLocalForm = (type: number, month: number, day: number) => {
      //NOTE: future cohort explorer - javascript thinks these values are strings
      //    We have to force the runtime into knowing these are really numbers
      const _type: number = parseInt(String(type), 10);
      const _localFormErrors: IFormError[] = [];

      if (!_type) {
         _localFormErrors.push({
            Message: 'A Benefit Period Type selection is required.',
            DataName: typeItem.cohortFieldId
         });
      }

      if (_type === CALENDAR_BENEFIT_TYPE_ID) {
         const dateString = month && day ?
            `${new Date().getFullYear()}-${leftPadDayOrMonth(month)}-${leftPadDayOrMonth(day)}` : '';
         const isDateSelectionValid = isDateValid(dateString);

         if (!month)
            _localFormErrors.push({Message: 'A Month selection is required.', DataName: monthItem.cohortFieldId});

         if (!day)
            _localFormErrors.push({Message: 'A Day selection is required.', DataName: dayItem.cohortFieldId});

         if (!isDateSelectionValid)
            _localFormErrors.push({Message: 'A Day selection must be valid within the month selected.', DataName: dayItem.cohortFieldId});
      }

      setFormErrors(_localFormErrors);
   };

   const handleTypeOnChange = (selectedTypeId: number) => {
      const changes: {cohortFieldId: number, val: any}[] = [];

      if (selectedTypeId === ENROLLMENT_BENEFIT_TYPE_ID) {
         changes.push({cohortFieldId: monthItem.cohortFieldId, val: null});
         changes.push({cohortFieldId: dayItem.cohortFieldId, val: null});
      }

      changes.push({cohortFieldId: typeItem.cohortFieldId, val: selectedTypeId.toString()});
      onInputChanges(changes);
      validateLocalForm(selectedTypeId, monthItem.value, dayItem.value);
   };

   const handleMonthOnChange = (selectedMonthId: number) => {
      const changes: { cohortFieldId: number, val: any }[] = [];
      changes.push({ cohortFieldId: typeItem.cohortFieldId, val: typeItem.value });
      changes.push({cohortFieldId: monthItem.cohortFieldId, val: selectedMonthId.toString()});
      changes.push({ cohortFieldId: dayItem.cohortFieldId, val: dayItem.value });

      onInputChanges(changes);
      validateLocalForm(typeItem.value as number, selectedMonthId, dayItem.value as number);
   };

   const handleDayOnChange = (selectedDayId: number) => {
      const changes: { cohortFieldId: number, val: any }[] = [];
      changes.push({ cohortFieldId: typeItem.cohortFieldId, val: typeItem.value });
      changes.push({ cohortFieldId: monthItem.cohortFieldId, val: monthItem.value });
      changes.push({cohortFieldId: dayItem.cohortFieldId, val: selectedDayId.toString()});
      onInputChanges(changes);
      validateLocalForm(typeItem.value, monthItem.value, selectedDayId);
   };

   return (
      <>
         <BasicDropdownField
            value={typeItem?.value ? Number(typeItem.value) : null}
            label={typeItem.labelText}
            options={benefitPeriodTypeOptions}
            onChange={(id) => handleTypeOnChange(id as number)}
            required={true}
            error={formErrors?.find(e => e.DataName === typeItem.cohortFieldId)?.Message}/>

         <BasicDropdownField
            value={monthItem?.value ? Number(monthItem.value) : null}
            label={monthItem.labelText}
            options={monthOptions}
            onChange={(id) => handleMonthOnChange(id as number)}
            required={typeItem?.value && !isEnrollmentPeriod}
            disabled={isEnrollmentPeriod}
            error={formErrors?.find(e => e.DataName === monthItem.cohortFieldId)?.Message}/>

         <BasicDropdownField
            value={dayItem?.value ? Number(dayItem.value) : null}
            label={dayItem.labelText}
            options={dayOptions()}
            onChange={(id) => handleDayOnChange(id as number)}
            required={typeItem?.value && !isEnrollmentPeriod}
            disabled={isEnrollmentPeriod}
            error={formErrors?.find(e => e.DataName === dayItem.cohortFieldId)?.Message}/>
      </>
   );
}

export default BenefitPeriodSelector;

