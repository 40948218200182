import * as React from 'react';
import { useParams } from 'react-router';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Spinner from '../Spinner';
import ApiErrorDisplay from '../ApiErrorDisplay';
import { useApiContext } from '../../store/ApiContext';
import NewUserSearchView from '../user/shared/NewUserSearchView';
import UserDetail from '../user/UserDetail';
import { useFetchUserMain, userMainBaseUrl } from '../../store/auth/UserMainFetcher';
import ContentCard from '../../layouts/ContentCard';
import { practiceBaseUrl, useFetchPractice } from '../../store/practice/PracticeFetcher';
import { formatPracticeName } from '../../store/practice/PracticeModel';
import { Breadcrumb } from 'antd';
import { BreadcrumbItemType } from 'antd/es/breadcrumb/Breadcrumb';
import { HttpVerb, KeyWithVerb, useErrorContext } from '../../store/ErrorContext';

const _keysWithVerb: KeyWithVerb[] = [
   { key: userMainBaseUrl, verb: HttpVerb.GET },
   { key: practiceBaseUrl, verb: HttpVerb.GET }
];

const PracticeUserIndex: React.FC = () => {
   const { httpGet } = useApiContext();
   const { removeErrors } = useErrorContext();
   const navigate = useNavigate();
   const location = useLocation();

   const { practiceId, id } = useParams();

   const _practiceId = Number(practiceId);
   const _userMainId = id ? Number(id) : id;

   const { data: userMainList } = useFetchUserMain(httpGet);
   const { practices } = useFetchPractice(httpGet);

   const [isUserDetailOpen, setIsUserDetailOpen] = React.useState<boolean>(false);
   const [userIsNavigating, setUserIsNavigating] = React.useState(false);
   const [userProfileId, setUserProfileId] = React.useState<number>();

   const userMain = React.useMemo(() => {
      if (_userMainId && userMainList?.length > 0) {
         return userMainList.find(f => f.id === _userMainId)
      }
   }, [_userMainId, userMainList]);

   const practice = React.useMemo(() => {
      if (_practiceId && practices?.length > 0) {
         return practices.find(y => y.id === _practiceId)
      }
   }, [_practiceId, practices])

   React.useEffect(() => {
      removeErrors({ keysWithVerb: _keysWithVerb });
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []) // remove Errors on initial render


   React.useEffect(() => {
      /* Evidently navigate is supposed to be in a useEffect?  Even though most everywhere else we use it, its not and it works just fine
       * Anyway, this wasn't working in the onClick in the <Link ... /> below, so here's a useEffect instead */
      if (userIsNavigating) {
         if (isUserDetailOpen) setIsUserDetailOpen(false);

         if (!userMain) {
            navigate(-1)
         } else {
            // When editing a User Main, we want to allow navigation back to the main grid
            navigate(`/practice/${practiceId}/user`);
         }
      }
      setUserIsNavigating(false);
      // ^ see comment above regarding the need to wrap "navigate" in a useEffect
      // and know that we only want to navigate when "userIsNavigating" toggles, hence: 
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [userIsNavigating]);


   if (!userMainList || userMainList.length === 0) {
      return <Spinner message='Loading Users...' />
   }

   const handleReturnToPractices = () => {
      setUserIsNavigating(true);
   }

   const handleReturnToSearch = () => {
      setIsUserDetailOpen(false);
   }

   const getBreadcrumbItems = () => {
      let items: BreadcrumbItemType[] = [];
      items.push({
         title: <Link
            onClick={handleReturnToPractices}
            to={location.pathname}
            title='Return to Practice'>
            &lt; Practice
         </Link>
      });

      isUserDetailOpen && items.push({
         title: <Link
            onClick={handleReturnToSearch}
            to={location.pathname}
            title='Return to Search'>
            Users Search
         </Link>
      });

      return items;
   }

   const cardTitle = userMain ?
      `Edit ${userMain.emailAddress} at ${formatPracticeName(practice?.name, practice?.id)}` :
      `New User Main Record at ${formatPracticeName(practice?.name, practice?.id)}`
   const html = (<>
      <ContentCard title={cardTitle} >
         <div style={{ padding: 10 }}>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
               <Breadcrumb items={getBreadcrumbItems()} />
            </div>

            <ApiErrorDisplay
               title='Error loading User'
               keysWithVerb={_keysWithVerb}
            />

            {(!userMain && !isUserDetailOpen) &&
               <NewUserSearchView practiceId={_practiceId}
                  setIsUserDetailOpen={setIsUserDetailOpen}
                  setUserProfileId={setUserProfileId} />
            }

            {(userMain || isUserDetailOpen) &&
               <UserDetail userMainId={userMain?.id} userProfileId={userProfileId} />
            }
         </div>
      </ContentCard>
   </>
   )
   return html;
}

export default PracticeUserIndex;