import { Col, Row, Tag, theme } from 'antd';
import * as React from 'react';
import { formatDateTimeString } from '../../../../functions/format.functions';
import colorWheel from '../../../../Theme/ColorWheel';
import { ActionButton } from '../../../shared/Buttons';
import CustomIcon, { CustomIconType } from '../CustomIcon';
import { NumberedTitle } from '../Typography/Title';
import { IFilteredInfo, IFilteredInfoWithName } from './FilteredInfo';

interface IProps {
   filteredInfo?: Record<string, IFilteredInfo | null>;
   setFilteredInfo?: (filter: Record<string, IFilteredInfo | null>) => void;
   onFiltersClear?: () => void;
}

export const AppliedFilterDisplay: React.FC<IProps> = (props) => {
   const { filteredInfo, setFilteredInfo, onFiltersClear } = props;
   const [showAppliedFilters, setShowAppliedFilters] = React.useState<boolean>(false);
   const [appliedFilters, setAppliedFilters] = React.useState<IFilteredInfoWithName[]>([]);
   const [filterCount, setFilterCount] = React.useState<number>(0);
   const { token: csstoken } = theme.useToken();

   React.useEffect(() => {
      const filterEffect = () => {
         let xcnt = 0;
         const filters: IFilteredInfoWithName[] = [];

         if (filteredInfo) {
            const keys = Object.keys(filteredInfo);
            
            keys.forEach(key => {
               if (filteredInfo[key]?.values?.length > 0 && filteredInfo[key].values.some(x => x)) {
                  filters.push({ ...filteredInfo[key], name: key } as IFilteredInfoWithName);
                  xcnt += filteredInfo[key].values.length;
               } else if (filteredInfo[key]?.value !== undefined && (
                     (typeof filteredInfo[key].value === 'string' || typeof filteredInfo[key].value === 'boolean')
                     && filteredInfo[key]?.value)
               ) {
                  //Added typeof check to allow empty strings to not create a new applied filter
                  //input box with no values
                  filters.push({ ...filteredInfo[key], name: key } as IFilteredInfoWithName);
                  xcnt++;
               }
            })
         }
         setFilterCount(xcnt);
         setAppliedFilters(filters);
      }

      filterEffect();
   }, [filteredInfo]);

   const removeValue = (key: string, value: string | number | boolean | Date) => {

      const newFilters = { ...filteredInfo };

      if (filteredInfo[key]?.values?.length > 0 && filteredInfo[key].values.some(x => x)) {
         newFilters[key].values = filteredInfo[key]?.values.filter(y => y.value !== value);
      } else if (filteredInfo[key].value !== undefined) {
         //input box with no values
         newFilters[key] = undefined;
      }

      setFilteredInfo && setFilteredInfo(newFilters);

      //some funky behavior on this approach - keeping here for reference for if\when the above blows up
      //var idx = newFilters[groupName].values.findIndex(v => v.value === value);
      //if (idx > -1) {
      //   newFilters[groupName].values.splice(idx);         
      //}
      //props.setFilteredInfo(newFilters);
   }

   const clearFilters = () => {
      const newFilters = { ...filteredInfo };
      const keys = Object.keys(filteredInfo);
      keys.forEach(key => {
         newFilters[key] = undefined;
      })

      setFilteredInfo && setFilteredInfo(newFilters);
      onFiltersClear && onFiltersClear();
      setShowAppliedFilters(false);
   }

   const buildTagDisplay = (value: string | number | boolean | Date): string => {
      if (value instanceof Date) return formatDateTimeString(value);

      return value?.toString();
   }

   if (filterCount === 0) {
      return;
   }

   return <>
      <Row style={{ marginBottom: 8 }}>
         <Col span={12}>
            <NumberedTitle level={5}>
               <span style={{ color: colorWheel.tangerineOrange, cursor: 'pointer' }} onClick={() => setShowAppliedFilters(!showAppliedFilters)}>
                  ({`${filterCount}`}) Applied Filters {filterCount > 0 &&
                     <CustomIcon type={showAppliedFilters ? CustomIconType.DownOutlined : CustomIconType.RightOutlined} />}
               </span>
            </NumberedTitle>
         </Col>
         <Col span={12}>
            <span style={{ float: 'right' }}>
                 {/* disabled={filterCount > 0 ? false : true}*/}
               <ActionButton onClick={() => clearFilters()}
                  buttonText='Reset Filters'
                  title={filterCount <= 0 ? 'One or more filters required before clearing.' : null}
               />
            </span>
         </Col>
      </Row>
      {(filterCount > 0)
         && showAppliedFilters
         && <div style={{ backgroundColor: colorWheel.mediumGrey, padding: 8, borderBottom: `1px solid ${colorWheel.neutral3}`, marginBottom: 8 }}>
            {
               appliedFilters.map((group) => {
                     return <Row key={group.name} align='top'>
                        <Col span={5} style={{ paddingTop: 5 }}>
                           <span>{`(${group.values?.length ?? 1}) ${group.title}:`}</span>
                        </Col>
                        <Col span={19} style={{overflowY:'auto', maxHeight: 65}}>
                           {group.values?.length > 0 && group.values.map((val) => (
                              <Tag 
                                 style={{ background: csstoken.colorBgContainer }}
                                 closable
                                 onClose={() => removeValue(group.name, val.value)}
                                 key={`${group.name}_${val.value}`}>{val.label}
                              </Tag>
                           ))}
                           {group.value !== undefined && (
                              <Tag
                                 style={{ background: csstoken.colorBgContainer }}
                                 closable
                                 onClose={() => removeValue(group.name, group.value)}
                                 key={`${group.name}_${group.value}`}>{buildTagDisplay(group.value)}
                              </Tag>
                           )}
                        </Col>
                     </Row>
               })
            }
         </div>}
   </>
}
