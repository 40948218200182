import * as React from 'react';
import { Space } from 'antd';
import { stringComparer } from '../../functions/comparer.functions';
import { useApiContext } from '../../store/ApiContext';
import { migrateUser, migrateUsersUrl } from '../../store/auth/UserMigrationFetcher';
import { UserMain } from '../../store/auth/UserMainModel';
import { CancelButton, SaveButton } from '../shared/Buttons';
import Dialog from '../Dialog';
import Spinner from '../Spinner';
import ApiErrorDisplay from '../ApiErrorDisplay';
import { useUserMigrationContext } from '../../store/auth/UserMigrationContext';
import { IUserMigrationStatus } from '../../store/auth/UserMigrationReducer';
import { CreateUserStatus } from '../../store/auth/UserModel';
import { HttpError } from '../../functions/httpClient';
import UserMigrationStatusList from '../user/shared/UserMigrationStatusList';
import { useErrorContext } from '../../store/ErrorContext';

interface IProps {
   isConfirmationDialogOpen: boolean;
   onCloseEditor: () => void;
   practiceId: number;
   userMainsToMigrate: UserMain[];
}

export const PracticeUserMigrationConfirmation: React.FC<IProps> = (props) => {
   const { isConfirmationDialogOpen, onCloseEditor, practiceId, userMainsToMigrate } = props;
   const _keys = [migrateUsersUrl(practiceId)];
   const { httpPost } = useApiContext();
   const { removeErrors } = useErrorContext();
   const { migrationStatusList, addStatus, removeStatus } = useUserMigrationContext();

   const [isMigrating, setIsMigrating] = React.useState(false);
   const [hasAttemptedMigrating, setHasAttemptedMigrating] = React.useState(false);
   const [statusRecords, setStatusRecords] = React.useState<IUserMigrationStatus[]>([]);
   

   React.useEffect(() => {
      if (migrationStatusList?.length > 0) {
         setStatusRecords(migrationStatusList.sort((a, b) => stringComparer(a.migrationStatus.priorUpn, b.migrationStatus.priorUpn)));

         if (!migrationStatusList.some(y => y.isLoading) && isMigrating) setIsMigrating(!isMigrating);
      }

   }, [migrationStatusList, isMigrating])

   const callMigrateUser = (userMain: UserMain, index: number) => {
      addStatus(userMain.id, { priorUpn: userMain.upn } as CreateUserStatus , true);

      migrateUser(httpPost, practiceId, userMain.id)
         .then((response) => {
            addStatus(userMain.id, response, false);
         })
         .catch((error: HttpError) => {
            // In case there was an entirely unhandled exception, we'll try to grab just the exception message and display it in the grid
            let errorMessage = error?.message;
            if (errorMessage && errorMessage?.length > 100 && errorMessage.indexOf(',"Message":') > -1) {
               errorMessage = errorMessage.substring(
                  errorMessage.indexOf('"Message":'),
                  ((errorMessage.length - 2) - errorMessage.indexOf(',"Message":')) // the "-2" next to length is meant to cut off the final bracket from the JSON
               );
            }
            addStatus(userMain.id, { priorUpn: userMain.upn, userCreationError: errorMessage } as CreateUserStatus, false)
         })
   }

   const onMigrateUsers = () => {
      setIsMigrating(true);
      setHasAttemptedMigrating(true);

      userMainsToMigrate.forEach((userMain, index) => {
         callMigrateUser(userMain, index);
      });
   }

   const onClose = () => {
      removeErrors({ keys: _keys });
      statusRecords?.forEach((record) => {
         if (record.userMainId) removeStatus(record.userMainId);
      })
      setStatusRecords([]);
      setHasAttemptedMigrating(false);
      onCloseEditor();
   }

   const html = (
      <Dialog
         scrollingContent={true}
         title={'Confirm Migrate Users'}
         open={isConfirmationDialogOpen}
         size={'large'}
         actionButtons={
            <Space>
               <CancelButton buttonText={'Close'} onClick={onClose} disabled={isMigrating} />
               <SaveButton buttonText={'Migrate Users'} disabled={isMigrating || hasAttemptedMigrating} onClick={onMigrateUsers} />
               
            </Space>
         }
      >
         <ApiErrorDisplay
            title='Error Migrating Users'
            keys={_keys}
         />

         {isMigrating && <Spinner message={'Migrating Users - Please do not attempt to refresh this page or navigate away until this action has completed'} />}

         {hasAttemptedMigrating &&
            <>
               {statusRecords?.length === userMainsToMigrate?.length && statusRecords.some(y => !y?.migrationStatus?.wasSentWelcomeEmail || !y?.migrationStatus?. wasCreated) === false &&
                  <h3>All Users Migrated successfully!</h3>
               }

            {statusRecords?.length > 0 &&
               <UserMigrationStatusList
                  migrationStatusRecords={statusRecords}
                  hidePagingOnTable={false}
                  />
               }
            </>
         }

         {!isMigrating && !hasAttemptedMigrating &&
            <>
               <h3>Are you sure that you want to migrate the following Users?</h3>
               {userMainsToMigrate.map((userMain, index) => {
                  return <div key={index}>{userMain.upn}</div>
               })}
            </>
         }

      </Dialog>
   );

   return html;
}
