import { mutate } from "swr";
import { TPost } from "../../functions/httpClient";
import { userProfileBaseUrl } from "../practice/UserProfileFetcher";
import { UserProfile } from '../practice/UserProfileModel';
import { nonAcceptedUserMainsUrl, userMainBaseUrl } from './UserMainFetcher';
import { UserMainDetail } from './UserMainModel';
import { CreateNewPracticeUser, CreateUserStatus, CreateUserMain } from './UserModel';

export const userBaseUrl = 'api/auth/user';
export const provisionPracticeUserUrl = `${userBaseUrl}/ProvisionPracticeUser`;
export const provisionUserUrl = `${userBaseUrl}/ProvisionUser`;
export const inviteUsersUrl = `${userBaseUrl}/InviteUser`;

export const provisionUser = async (httpPost: TPost, newUser: CreateUserMain): Promise<UserMainDetail> => {
   let returnedUserMain: UserMainDetail;

   await httpPost<UserMainDetail>(
      provisionUserUrl, newUser)
      .then((response) => {
         returnedUserMain = response;
         mutate(userMainBaseUrl);
      });

   return returnedUserMain;
}

export const provisionPracticeUser = async (httpPost: TPost, newUser: CreateNewPracticeUser): Promise<UserProfile> => {
   let result: UserProfile;

   await httpPost<UserProfile>(
      provisionPracticeUserUrl, newUser)
      .then((response) => {
         result = response;
         mutate(userMainBaseUrl);
         mutate(userProfileBaseUrl);
         // nonAcceptedUserMains is dependant upon practice.UserProfile which this api call may create a new one, so we must mutate
         mutate(nonAcceptedUserMainsUrl);
      });

   return result;
}

export const inviteUser = async (httpPost: TPost, userMainId: number): Promise<CreateUserStatus[]> => {
   let statusRecords: CreateUserStatus[];

   await httpPost<CreateUserStatus[]>(
      inviteUsersUrl,
      { userMainId: userMainId }
   )
      .then((response) => {
         mutate(userMainBaseUrl);
        // nonAcceptedUserMains are determined in part due to the auth.UserMain.InviteSentOn value which this call may update, hence this mutate. 
         mutate(nonAcceptedUserMainsUrl);
         statusRecords = response;
      });

   return statusRecords;
}