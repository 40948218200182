import * as React from 'react';
import { useApiContext } from '../../store/ApiContext';
import { FaxLogReview, FaxLogViewModel } from '../../store/fax/FaxLogModels';
import { saveFaxLogReviewUrl, saveFaxLogReview } from '../../store/fax/FaxLogFetcher';
import ApiErrorDisplay from '../ApiErrorDisplay';
import Dialog from '../Dialog';
import { Form } from 'antd/lib';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { CheckboxField, TextAreaField } from '../shared/InputLibrary';
import { CancelButton, SaveButton } from '../shared/Buttons';
import { TitleError } from '../shared/AntComponents/Typography/Title';
import { BasicFieldWrapper } from '../shared/BasicInputLibrary';
import { useUserMainViewModel } from '../../store/auth/UserMainFetcher';
import { formatDateTimeString } from '../../functions/format.functions';
import { useErrorContext } from '../../store/ErrorContext';

interface IProps {
   faxLog: FaxLogViewModel;
   isOpen: boolean;
   close: () => void;
}

const dialogContentStyle = {
   minHeight: '300px',
   maxHeight: '95vh',
}

const yupFaxLogReviewSchema = yup.object({
   id: yup.number().notRequired(),
   reviewed: yup.boolean().required(),
   reviewedOn: yup.date().notRequired(),
   reviewedUserMainId: yup.number().notRequired(),
   notes: yup.string().notRequired(),
});

interface IFaxLogReviewYup extends yup.Asserts<typeof yupFaxLogReviewSchema> { }
const __formId = "formReviewFax";

const _keys: string[] = [saveFaxLogReviewUrl];

const ReviewFax: React.FC<IProps> = (props) => {
   const { faxLog, isOpen, close } = props;
   const { httpGet, httpPut } = useApiContext();
   const { removeErrors } = useErrorContext();

   const { userMainViewModel } = useUserMainViewModel(httpGet, faxLog.reviewedUserMainId);
   const [isFormSaving, setIsFormSaving] = React.useState<boolean>(false);

   const {
      control,
      handleSubmit,
      formState: { errors }, reset
   } = useForm<IFaxLogReviewYup>({
      resolver: yupResolver<yup.AnyObject>(yupFaxLogReviewSchema),
      shouldFocusError: true,
   });

   React.useEffect(() => {
      setIsFormSaving(false);
      reset(faxLog as FaxLogReview);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [faxLog, isOpen]);
   
   const onSubmit = (data: IFaxLogReviewYup): void => {
      //console.log('--------------Editor-------------------------')
      //console.log(errors)

      //ts hoops to convert data with unknown props into the thing
      const item = data as unknown as FaxLogReview;

      setIsFormSaving(true);
      saveFaxLogReview(httpPut, item)
         .then(() => {
            close();
         })
         .finally(() => {
            setIsFormSaving(false);
         });
   }

   const handleClose = () => {
      removeErrors({ keys: _keys })
      close();
   }

   return (
      <Dialog
         scrollingContent={true}
         title={'Review Fax'}
         open={isOpen}
         size={'small'}
         closeIcon={true}
         actionButtons={
            <>
               <CancelButton onClick={handleClose} />
               <SaveButton
                  formId={__formId}
                  disabled={Object.keys(errors).length > 0}
                  loading={isFormSaving}
                  onClick={() => null} />
            </>
         }>

         <div style={dialogContentStyle}>

            {errors && Object.keys(errors).length > 0 && <>
               <TitleError
                  text='Please correct the errors below' />
               {console.log('--------------Form Errors: -------------------------')}
               {console.log(errors)}
            </>
            }

            <ApiErrorDisplay title='Api Error' keys={_keys} />
        
         <Form id={__formId} onFinish={handleSubmit(onSubmit)}>

               <CheckboxField
                  control={control}
                  error={errors?.reviewed}
                  name='reviewed'
                  label='Reviewed' />
               <TextAreaField
                  control={control}
                  error={errors?.notes}
                  name='notes'
                  label='Notes'
                  rows={4}
                  maxLength={1023} />
               {userMainViewModel &&
                  <BasicFieldWrapper
                     label='Reviewed By'
                     field={userMainViewModel.upn}
                  />
               }
               {faxLog?.reviewedOn &&
                  <BasicFieldWrapper
                     label='Reviewed On'
                     field={formatDateTimeString(faxLog.reviewedOn)}
                  />
               }
            </Form>
         </div>
      </Dialog>
   );
}

export default ReviewFax;