import { FC, ReactNode } from 'react';

interface IProps {
   children?: ReactNode;
}

/**
 * Convience component using flexbox to center align items within the container they are in.
 */
export const AlignItemsCenter: FC<IProps> = ({ children }) => {
   return <div style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'center' }}>
      {children}
   </div>
};

/**
 * Convience component using flexbox to left align items within the container they are in.
 */
export const AlignItemsLeft: FC<IProps> = ({ children }) => {
   return <div style={{ display: 'flex', alignItems: 'start', width: '100%', justifyContent: 'start' }}>
      {children}
   </div>
};

/**
 * Convience component using flexbox to right align items within the container they are in.
 */
export const AlignItemsRight: FC<IProps> = ({ children }) => {
   return <div style={{ display: 'flex', alignItems: 'end', width: '100%', justifyContent: 'end' }}>
      {children}
   </div>
};