import * as React from 'react';
import * as FoundationDiseaseType from '../../store/program/FoundationDiseaseTypeModel';
import { ColumnsType } from 'antd/lib/table';
import MinimalistTable from '../shared/AntComponents/Table/MinimalistTable';
import { Space } from 'antd';
import { CancelButton, EditButton, SaveButton } from '../shared/Buttons';
import { InsuranceClassTypes } from '../../store/program/InsuranceClassModel';
import { FundingStatus, FundStatusType } from '../../store/program/FundingStatusModel';
import { deleteFundingStatuses, saveFundingStatuses } from '../../store/program/FundingStatusFetcher';
import { useApiContext } from '../../store/ApiContext';
import { useParams } from 'react-router-dom';
import { formatShortDateString } from '../../functions/format.functions';
import ImageContainer from '../shared/AntComponents/ImageContainer';
import './FoundationDiseaseTypeList.css'
import colorWheel from '../../Theme/ColorWheel';

interface Props {
   assistanceServiceId: number;
   foundationDiseaseTypeLookups: FoundationDiseaseType.FoundationDiseaseType_[];
}

interface IEditRecord {
   foundationDiseaseTypeId: number;
   commercialStatus: number;
   medicareStatus: number;
   medicaidStatus: number;
   vaDodStatus: number;
   uninsuredStatus: number;
}

const FoundationDiseaseTypeList: React.FC<Props> = (props) => {
   const { assistanceServiceId, foundationDiseaseTypeLookups } = props;
   const { httpPost } = useApiContext();
   const { foundationId } = useParams(); // Because we need it to mutate the URL that retrieves the FundingStatus lookups... 
   const foundationIdNumber = parseInt(foundationId);

   const [isSaving, setIsSaving] = React.useState(false);
   const [isDeleting, setIsDeleting] = React.useState(false);
   const [editId, setEditId] = React.useState<number>(undefined);
   const [editRecord, setEditRecord] = React.useState<IEditRecord>(undefined);

   const isBeingEdited = (foundationDiseaseTypeId: number) => foundationDiseaseTypeId === editId;
   const shouldShowStatusSelector = (foundationDiseaseTypeId: number) => foundationDiseaseTypeId === editId && editRecord;

   /* In the unlikely chance that a User attempts to edit the same record they just edited, this will ensure
       * that their prior edits (which will be fed back through foundationDiseaseTypeLookups) will be visible
       * when this data comes back */
   React.useEffect(() => {
      if (editId && foundationDiseaseTypeLookups?.length > 0) {
         const record = foundationDiseaseTypeLookups.find(y => y.id === editId);
         setEditRecord({
            foundationDiseaseTypeId: record.id,
            commercialStatus: record.insuranceClassStatusLookups.find(y => y.insuranceClassId === InsuranceClassTypes.Commercial).status,
            medicareStatus: record.insuranceClassStatusLookups.find(y => y.insuranceClassId === InsuranceClassTypes.Medicare).status,
            medicaidStatus: record.insuranceClassStatusLookups.find(y => y.insuranceClassId === InsuranceClassTypes.Medicaid).status,
            vaDodStatus: record.insuranceClassStatusLookups.find(y => y.insuranceClassId === InsuranceClassTypes.VaDod).status,
            uninsuredStatus: record.insuranceClassStatusLookups.find(y => y.insuranceClassId === InsuranceClassTypes.Uninsured).status,
         });
      }
   }, [foundationDiseaseTypeLookups, editId])

   const onSelectStatus = (propertyName: string, newStatusId: number) => {
      setEditRecord({
         ...editRecord,
         [propertyName]: newStatusId
      })
   }

   const onCancelEdit = () => {
      setEditId(undefined);
      setEditRecord(undefined);
   }

   const onSave = async () => {
      const insuranceLookups = foundationDiseaseTypeLookups.find(y => y.id === editRecord.foundationDiseaseTypeId).insuranceClassStatusLookups;

      const buildFundingStatus = (insuranceClassId: number, status: FundStatusType): FundingStatus => {
         const thisInsuranceLookup = insuranceLookups.find(y => y.insuranceClassId === insuranceClassId);
         
         return {
            // API assigns -1 for "fake" insuranceLookups when retrieving
            id: thisInsuranceLookup && thisInsuranceLookup?.fundingStatusId !== -1 ? thisInsuranceLookup.fundingStatusId : null,
            externalId: thisInsuranceLookup?.externalId, // For existing records we'll assign this back

            // Overrides / assignments from this page
            assistanceServiceId: assistanceServiceId,
            foundationDiseaseTypeId: editRecord.foundationDiseaseTypeId,
            insuranceClassId: insuranceClassId,
            status: status,
         } as FundingStatus;
      }

      // re-map editRecord to collection of fundingStatus and ship to API
      let fundingStatusRecords: FundingStatus[] = [];
      fundingStatusRecords.push(buildFundingStatus(InsuranceClassTypes.Commercial, editRecord.commercialStatus))
      fundingStatusRecords.push(buildFundingStatus(InsuranceClassTypes.Medicare, editRecord.medicareStatus))
      fundingStatusRecords.push(buildFundingStatus(InsuranceClassTypes.Medicaid, editRecord.medicaidStatus))
      fundingStatusRecords.push(buildFundingStatus(InsuranceClassTypes.VaDod, editRecord.vaDodStatus))
      fundingStatusRecords.push(buildFundingStatus(InsuranceClassTypes.Uninsured, editRecord.uninsuredStatus));

      let fundingStatusRecordsToSave = fundingStatusRecords.filter(y => y.status > -1); // 0 and up are real status', negative numbers are for delete / no-record
      let fundingStatusRecordsToDelete = fundingStatusRecords.filter(y => y.id && y.status === FundStatusType.Delete);

      if (fundingStatusRecordsToSave?.length > 0) {
         setIsSaving(true);
         await saveFundingStatuses(httpPost, fundingStatusRecordsToSave, foundationIdNumber)
            .then(() => {
               onCancelEdit(); // now that we've saved we can reset editing
            })
            .finally(() => setIsSaving(false));
      }
      if (fundingStatusRecordsToDelete?.length > 0) {
         setIsDeleting(true);
         await deleteFundingStatuses(httpPost, fundingStatusRecordsToDelete.map(y => y.id), foundationIdNumber)
            .then(() => {
               onCancelEdit(); // now that we've saved we can reset editing
            })
            .finally(() => setIsDeleting(false));
      }
      
   }

   const renderInsuranceStatus = (foundationDiseaseTypeId: number, insuranceClassId: number) => {
      const insuranceLookup = foundationDiseaseTypeLookups.find(y => y.id === foundationDiseaseTypeId)
         .insuranceClassStatusLookups.find(y => y.insuranceClassId === insuranceClassId);

      let returnVal = null;

      if (!insuranceLookup) {
         console.warn(`No Insurance lookup found for FDT ID ${foundationDiseaseTypeId}, Ins. Class ID ${insuranceClassId}`);
      } else {
         switch (insuranceLookup.status) {
            case FundStatusType.Open:
               returnVal = <ImageContainer
                  iconName={'circle-check'}
                  className={'fontawesomeIvyGreen'}
                  title={'Funds are available'}
                  />
               break;
            case FundStatusType.Closed:
               returnVal = <ImageContainer
                  iconName={'square-x'}
                  className={'fontawesomeRed'}
                  title={'Funds not available'}
               />
               break;
            case FundStatusType.Unknown:
               returnVal = <ImageContainer
                  iconName={'square-question'}
                  className={'fontawesomeGraniteGrey'}
                  title={'Funding status is unknown'}
               />
               break;
            case FundStatusType.ReenrollmentOnly:
               returnVal = <span style={{ backgroundColor: colorWheel.ocherYellow, padding: '1px 4px', borderRadius: 26 }}>
                  <ImageContainer
                     iconName={'rotate'}
                     className='fontawesomeWhite'
                     styles={{ width: 18, marginBottom: 2 }}
                     title='Open for re-enrollment only' />
               </span>
               break;

            default:
               break;
         }

         return returnVal;
      }
   }

   const renderInsuranceStatusSelector = (foundationDiseaseTypeId: number, insuranceClassId: number, editRecordPropertyName: string) => {
      // TS gets mad at trying to access the object by index/property name sooooo
      // @ts-ignore
      const currentFundingStatus: number = editRecord[editRecordPropertyName];

      const getStatusForSelect = (status: FundStatusType) => {
         if (currentFundingStatus !== status) return status;

         // when this status is already selected, deselecting it (as-in clicking it again) marks it for deletion
         return FundStatusType.Delete;
      }

      const selectedWidth = 40;
      const notSelectedWidth = 20;

      return <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
         <span onClick={() => onSelectStatus(editRecordPropertyName, getStatusForSelect(FundStatusType.Open)) }>
            <ImageContainer
               iconName={'circle-check'}
               styles={{ width: currentFundingStatus === FundStatusType.Open ? selectedWidth : notSelectedWidth }}
               className={'fontawesomeIvyGreen'}
               title={'Funds are available'}
            />
         </span>&nbsp;
         <span style={{ backgroundColor: colorWheel.ocherYellow, padding: currentFundingStatus === FundStatusType.ReenrollmentOnly ? '5px 5px' : '0 4px', borderRadius: 24 }}
            onClick={() => onSelectStatus(editRecordPropertyName, getStatusForSelect(FundStatusType.ReenrollmentOnly)) }>
            <ImageContainer
               iconName={'rotate'}
               styles={
                  // rotate needs different sizing
                  currentFundingStatus === FundStatusType.ReenrollmentOnly ? { width: 30 } : { width: 15, marginBottom: 2 }
               }
               className={'fontawesomeWhite'}
               title={'Open for re-enrollment only'}
            />
         </span>&nbsp;
         <span onClick={() => onSelectStatus(editRecordPropertyName, getStatusForSelect(FundStatusType.Unknown)) }>
            <ImageContainer
               iconName={'square-question'}
               styles={{ width: currentFundingStatus === FundStatusType.Unknown ? selectedWidth : notSelectedWidth }}
               className={'fontawesomeGraniteGrey'}
               title={'Funding status is unknown'}
            />
         </span>&nbsp;
         <span onClick={() => onSelectStatus(editRecordPropertyName, getStatusForSelect(FundStatusType.Closed)) }>
            <ImageContainer
               iconName={'square-x'}
               styles={{ width: currentFundingStatus === FundStatusType.Closed ? selectedWidth : notSelectedWidth }}
               className={'fontawesomeRed'}
               title={'Funds not available'}
            />
         </span>
      </div>
   }

   const getLastChanged = (foundationDiseaseTypeId: number) => {
      const dates = foundationDiseaseTypeLookups.find(y => y.id === foundationDiseaseTypeId)
         .insuranceClassStatusLookups.map(y => y.fundingStatusStatusModifiedOn);
      
      const maxDate = dates.reduce((a, b) => {
         if (!a) return b;
         if (!b) return a;
         return a > b ? a : b;
      });

      return maxDate;
   }

   const columnTitle = (title: string) => {
      return <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
         {title}
      </div>
   }

   const columns: ColumnsType<FoundationDiseaseType.FoundationDiseaseType_> = [
      {
         dataIndex: 'buttons',
         key: 'buttons',
         width: '5%',
         render: (text, record) => {
            if (isBeingEdited(record.id)) {
               return <Space>
                  <SaveButton
                     displayNoText
                     onClick={onSave}
                     loading={editId === record.id && (isSaving || isDeleting)}
                     disabled={isSaving || isDeleting} />
                  <CancelButton
                     displayNoText
                     onClick={onCancelEdit}
                     loading={editId === record.id && (isSaving || isDeleting)}
                     disabled={isSaving || isDeleting} />
               </Space>
            } else {
               return <EditButton displayNoText
                  onClick={() => setEditId(record.id)}
                  disabled={editId !== undefined}
               />
            }
         }
      },
      {
         dataIndex: 'foundationDiseaseTypeName',
         title: 'Disease Type',
         key: 'foundationDiseaseTypeName',
         width: '35%'
      },
      {
         dataIndex: 2,
         title: columnTitle('Commercial'),
         width: '10%',
         render: (text, record) => {
            return <div style={{ display: 'flex', justifyContent: 'center' }} >{shouldShowStatusSelector(record.id) ?
               renderInsuranceStatusSelector(record.id, 1, 'commercialStatus') :
               renderInsuranceStatus(record.id, 1)}
            </div>
         }
      },
      {
         dataIndex: 3,
         title: columnTitle('Medicare'),
         width: '10%',
         render: (text, record) => {
            return <div style={{ display: 'flex', justifyContent: 'center' }} >{shouldShowStatusSelector(record.id) ?
               renderInsuranceStatusSelector(record.id, 2, 'medicareStatus') :
               renderInsuranceStatus(record.id, 2)}
            </div>
         }
      },
      {
         dataIndex: 4,
         title: columnTitle('Medicaid'),
         width: '10%',
         render: (text, record) => {
            return <div style={{ display: 'flex', justifyContent: 'center' }} >{shouldShowStatusSelector(record.id) ?
               renderInsuranceStatusSelector(record.id, 3, 'medicaidStatus') :
               renderInsuranceStatus(record.id, 3)}
            </div>
         }
      },
      {
         dataIndex: 5,
         title: columnTitle('VA / DOD'),
         width: '10%',
         render: (text, record) => {
            return <div style={{ display: 'flex', justifyContent: 'center' }} >{shouldShowStatusSelector(record.id) ?
               renderInsuranceStatusSelector(record.id, 7, 'vaDodStatus') :
               renderInsuranceStatus(record.id, 7)}
            </div>
         }
      },
      {
         dataIndex: 6,
         title: columnTitle('Uninsured'),
         width: '10%',
         render: (text, record) => {
            return <div style={{ display: 'flex', justifyContent: 'center' }} >{shouldShowStatusSelector(record.id) ?
               renderInsuranceStatusSelector(record.id, 5, 'uninsuredStatus') :
               renderInsuranceStatus(record.id, 5)}
            </div>
         }
      },
      {
         dataIndex: 7,
         title: columnTitle('Last Changed'),
         width: '10%',
         render: (text, record) => <div style={{ display: 'flex', justifyContent: 'center' }}>
               {formatShortDateString(getLastChanged(record.id))}
            </div>
         
      }
   ];

   const html = (
      <MinimalistTable
         rowKey={'id'}
         columns={columns}
         data={foundationDiseaseTypeLookups}
         hidePaging
      />
   );

   return html;
}

export default FoundationDiseaseTypeList;