import { useMemo } from 'react';
import { HttpError, TPost, TDelete, TGet } from '../../functions/httpClient';
import { IStatus } from '../models';
import useSWR, { mutate } from 'swr';
import { PracticeSubscription, PracticeSubscriptionViewModel } from './PracticeSubscriptionModel';
import { useFetchPractice } from './PracticeFetcher';
import { useFetchSubscriptions } from './SubscriptionFetcher';

export const practiceSubscriptionBaseUrl = `api/practice/practiceSubscription`;
const practiceSubscriptionDeleteUrl = (id: number) => `${practiceSubscriptionBaseUrl}/${id}`;

const swrOptions = {
   refreshInterval: 0,
   revalidateOnFocus: false,
   dedupingInterval: 60000
};


export function useFetchPracticeSubscription(httpGet: TGet): IStatus & { practiceSubscriptions: PracticeSubscription[] } {
   const { data, isValidating, error } = useSWR<PracticeSubscription[], HttpError>(
      practiceSubscriptionBaseUrl,
      httpGet,
      { ...swrOptions }
   );

   return {
      practiceSubscriptions: data,
      isLoading: !error && !data && isValidating,
      error: error?.message
   };
}

export const savePracticeSubscription = async (httpPost: TPost, practiceSubscription: PracticeSubscription) => {
   const result = await httpPost(practiceSubscriptionBaseUrl, practiceSubscription)
      .then(() => mutate(practiceSubscriptionBaseUrl));
   return result;
}

export const deletePracticeSubscription = async (httpDelete: TDelete, id: number) => {
   const result = await httpDelete(practiceSubscriptionDeleteUrl(id))
      .then(() => mutate(practiceSubscriptionBaseUrl));
   return result;
}

// Returns PracticeSubscriptionViewModels entirely on existing PracticeSubscription records
export function usePracticeBasedPracticeSubscriptionViewModel(httpGet: TGet): IStatus & { practiceSubscriptionsViewModels: PracticeSubscriptionViewModel[] } {
   const { practiceSubscriptions, isLoading, error, isSaving } = useFetchPracticeSubscription(httpGet);
   const { practices, isLoading: isLoading_p, error: error_p } = useFetchPractice(httpGet);
   const { subscriptions, isLoading: isLoading_s, error: error_s } = useFetchSubscriptions(httpGet);

   const viewModel = useMemo(() => {
      if (practiceSubscriptions?.length > 0 && practices?.length > 0) {
         let tempList = practiceSubscriptions.map((item): PracticeSubscriptionViewModel => {
            const s = subscriptions?.find(y => y.id === item.subscriptionId);
            const p = practices.find(y => y.id === item.practiceId);

            return {
               ...item,
               subscriptionName: s?.name,
               practiceName: p?.name,
               subscriptionFriendlyName: s?.friendlyName,
               subscriptionDescription: s?.description
            } as PracticeSubscriptionViewModel;
         });
         return tempList;
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [practiceSubscriptions, practices, subscriptions]);


   return {
      practiceSubscriptionsViewModels: viewModel,
      isLoading: isLoading || isLoading_p || isLoading_s,
      error: error ?? error_p ?? error_s,
      isSaving
   };
}

/* Returns PracticeSubscriptionViewModels for all existing Subscription records, and ties them into PracticeSubscription records if they exist for the provided PracticeId. 
 * This function was specifically written to satisfy AH-5018 where we need to display all available Subscriptions and fill in the PracticeSubscription info when available */
export function useSubscriptionsBasedPracticeSubscriptionViewModel(httpGet: TGet, practiceId: number): IStatus & { practiceSubscriptionsViewModels: PracticeSubscriptionViewModel[] } {
   const { practiceSubscriptions, isLoading, error, isSaving } = useFetchPracticeSubscription(httpGet);
   const { practices, isLoading: isLoading_p, error: error_p } = useFetchPractice(httpGet);
   const { subscriptions, isLoading: isLoading_s, error: error_s } = useFetchSubscriptions(httpGet);

   const viewModel = useMemo(() => {
      if (subscriptions && practices && practiceSubscriptions) {
         const p = practices.find(y => y.id === practiceId);
         //CR-89 - only display active subscriptions
         let tempList = subscriptions.filter(y => y.isActive).map((item): PracticeSubscriptionViewModel => {
            const practiceSubscription = practiceSubscriptions?.find(y => y.practiceId === practiceId && y.subscriptionId === item.id);
            return {
               ...practiceSubscription,
               subscriptionId: item.id,
               subscriptionName: item.name,
               practiceName: p?.name,
               subscriptionFriendlyName: item.friendlyName,
               subscriptionDescription: item.description
            } as PracticeSubscriptionViewModel;
         });
         return tempList;
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [practiceSubscriptions, practices, subscriptions]);


   return {
      practiceSubscriptionsViewModels: viewModel,
      isLoading: isLoading || isLoading_p || isLoading_s,
      error: error ?? error_p ?? error_s,
      isSaving
   };
}