export interface Setting {
   group: string;
   key: string;
   value: string;
}

export class KnownSettings {
   public static SupportEmail: string = 'SupportEmail';
   public static SupportPhone: string = 'SupportPhone';
   public static Version: string = 'Version';
   //public static Authority: string = 'Authority';
   //public static ApiScope: string = 'ApiScope';
   //all Annexus
   public static ReportViewer: string = 'ReportViewer';
   public static ContentAdmin: string = 'ContentAdmin';
   //purva - program summary cohorts
   public static CohortReviewer: string = 'CohortReviewer'; 
   //AH-3505 - content viewer role
   public static ContentViewer: string = 'ContentViewer';
   //A1-368- custom crx user
   public static CrxUser: string = 'CrxUser';
   public static PowerBi: string = 'PowerBi';
   //AH-3911 - fund status alerts user
   public static FSAUser: string = 'FSAUser';
   //AH-4242 - Cohort Admin
   public static CohortAdmin: string = 'CohortAdmin';
   //AH-5107 - User Admin
   public static UserAdmin: string = 'UserAdmin';
   // AH-5423 - Adparo Manager
   public static AdparoManager: string = 'AdparoManager';
   public static OmegaManager: string = 'OmegaManager';
   public static BIReportManager: string = 'BIReportManager';

   public static FeatureManagement: string = 'FeatureManagement';
   public static FeatureFlag_ProgramSummaryCohorts: string = 'ProgramSummaryCohorts';
   public static FeatureFlag_Foundation: string = 'Foundation';
   public static FeatureFlag_UserManagement: string = 'UserManagement';

   public static Auth0: string = 'Auth0';
   public static Domain: string = 'Domain';
   public static ClientId: string = 'ClientId';
   public static Audience: string = 'Audience';

   public static ReportType_AdparoScorecard: string = 'AdparoScorecard';

   public static Environment: string = "ASPNETCORE_ENVIRONMENT";
   public static MaxAnticipatedFSASendTimeUISeconds: string = "MaxAnticipatedFSASendTimeUISeconds";
}