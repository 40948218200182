import * as React from 'react';
import { ADPARO_DISCLAIMER_NAME, PracticeSettingsViewModel } from '../../store/practice/PracticeSettingsModel';
import { useFetchPracticeSettings, savePracticeSetting, practiceSettingBaseUrl } from '../../store/practice/PracticeSettingsFetcher';
import {  useApiContext } from '../../store/ApiContext';
import ApiErrorDisplay from '../ApiErrorDisplay';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Form } from 'antd';
import Spinner from '../Spinner';
import Dialog from '../Dialog';
import { CancelButton, SaveButton } from '../shared/Buttons';
import { TitleError } from '../shared/AntComponents/Typography/Title';
import { CheckboxField, InputField, TextAreaField } from '../shared/InputLibrary';
import { useErrorContext } from '../../store/ErrorContext';

interface IProps {
   settingsName: string;
   practiceSettingsId?: number;
   practiceId: number;
   closeEditor: () => void;
   isOpen: boolean;
}

const dialogContentStyle = {
   height: '250px',
   maxHeight: '95vh',
}

const yupPracticeSettingsSchema = yup.object({
   id: yup.number().notRequired(),
   practiceId: yup.number().notRequired(),
   userId: yup.number().notRequired(),
   name: yup.string().required(),
   value: yup.string()
      .when('name', {
         is: ADPARO_DISCLAIMER_NAME,
         then: () => yup.string().required(`Value is required for ${ADPARO_DISCLAIMER_NAME}`)
      })
      .max(2048, 'Max length is 2048 characters'),
   createdDate: yup.date().notRequired(),
   // UpdatedDate is actually required by the DB but it is assigned to (along with createdDate) server-side before hitting the DB
   updatedDate: yup.date().notRequired(),
   valueAsBoolean: yup.boolean().notRequired()
});

interface IPracticeSettingsYup extends yup.Asserts<typeof yupPracticeSettingsSchema> { }
const __formId = "frmEligibilityCriteriaYup";

const _keysLike: string[] = [practiceSettingBaseUrl];

const PracticeSettingsEditor: React.FC<IProps> = (props) => {
   const { closeEditor, isOpen, practiceId, settingsName, practiceSettingsId } = props;
   const { httpGet, httpPost } = useApiContext();
   const { removeErrors } = useErrorContext();
   const { practiceSettings } = useFetchPracticeSettings(httpGet);
   const [form] = Form.useForm();
   const [isFormSaving, setIsFormSaving] = React.useState<boolean>(false);

   const {
      control,
      handleSubmit,
      formState: { errors },
      reset
   } = useForm<IPracticeSettingsYup>({
      resolver: yupResolver<yup.AnyObject>(yupPracticeSettingsSchema),
      shouldFocusError: true,
   });

   React.useEffect(() => {
      let resetVal = {} as PracticeSettingsViewModel;

      if (practiceSettingsId && practiceSettings?.length > 0) {
         resetVal = practiceSettings.find(y => y.id === practiceSettingsId);

         if (resetVal.name !== ADPARO_DISCLAIMER_NAME) {
            resetVal.valueAsBoolean = resetVal.value?.toLowerCase() == 'true';
         }
      } else if (settingsName && practiceId && !practiceSettingsId) {
         resetVal.name = settingsName;
         resetVal.practiceId = practiceId;
         resetVal.valueAsBoolean = false;
      }

      reset(resetVal);
      // for "reset"
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [practiceSettingsId, practiceSettings, settingsName, practiceId])

   const onSubmit = (data: IPracticeSettingsYup): void => {
      setIsFormSaving(true);
      const item = data as unknown as PracticeSettingsViewModel;

      if (item.name !== ADPARO_DISCLAIMER_NAME)
         item.value = String(item.valueAsBoolean);

      savePracticeSetting(httpPost, item)
         .then(handleClose)
         .catch(err => console.error('Error Saving Practice Settings', err))
         .finally(() => {
            setIsFormSaving(false);
         });
   }

   const handleClose = () => {
      removeErrors({ keysLike: _keysLike });
      closeEditor();
   }

   if (isFormSaving) {
      return <Spinner message='Saving Practice Settings...' />
   }

   const html = (
      <Dialog
         scrollingContent={true}
         title={'Practice Settings Editor'}
         open={isOpen}
         size={'small'}
         actionButtons={
            <>
               <CancelButton onClick={() => {
                  handleClose();
               }} />
               <SaveButton
                  formId={__formId}
                  onClick={() => null}
                  disabled={Object.keys(errors).length > 0}
                  loading={isFormSaving} />
            </>
         }
      >
         <div style={dialogContentStyle}>
            {errors && Object.keys(errors).length > 0 &&
               <>
                  <TitleError text='Please correct the errors below' />
                  {console.log('--------------Form Errors: -------------------------')}
                  {console.log(errors)}
               </>
            }

            <ApiErrorDisplay
               title='Error saving PracticeSettings'
               keysLike={_keysLike}
            />

            <Form form={form} id={__formId} onFinish={handleSubmit(onSubmit)}>
               <InputField
                  control={control}
                  name='name'
                  label='Name'
                  type='text'
                  error={errors?.value}
                  disabled={true}
               />
               {settingsName === ADPARO_DISCLAIMER_NAME &&
                  <TextAreaField
                     control={control}
                     name='value'
                     label='Value'
                     rows={4}
                     maxLength={2048}
                     error={errors?.value}
                     disabled={false}
                  />
               }
               {settingsName !== ADPARO_DISCLAIMER_NAME &&
                  <CheckboxField
                     label='Value'
                     control={control}
                     error={errors?.valueAsBoolean}
                     name='valueAsBoolean' />
               }
            </Form>
         </div>
      </Dialog>
   );

   return html;
}

export default PracticeSettingsEditor;