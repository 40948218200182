import { ColumnsType, TableProps } from 'antd/lib/table';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import HighlightSearchText from '../components/HighlightSearchText';
import SearchResultsTable from '../components/shared/AntComponents/Table/SearchResultsTable';
import { BasicDatePickerField, BasicDropdownField, BasicInputField } from '../components/shared/BasicInputLibrary';
import { ActionButton } from '../components/shared/Buttons';
import { dateComparer, numberComparer, stringComparer } from '../functions/comparer.functions';
import { formatDateTimeString } from '../functions/format.functions';
import { IOptionItem } from '../functions/option.functions';
import { CrxEnrollment, CrxEnrollmentsFilter } from '../store/external/CrxEnrollmentModels';
import { useCrxEnrollments } from '../store/external/CrxEnrollmentFetcher';
import CrxPayloadDialog from './CrxPayloadDialog';
import ContentCard from '../layouts/ContentCard';
import { useApiContext } from '../store/ApiContext';

export const CrxIndex: React.FC = () => {
   const { httpPost } = useApiContext();

   const { cmd } = useParams<{ cmd: string }>();
   const [excludedPracticeIds,] = React.useState<number[]>(cmd === 'dev' ? [] : [3, 13]);
   const [isAppDetailsOpen, setIsAppDetailsOpen] = React.useState<boolean>(false);

   const [filteredItems, setFilteredItems] = React.useState<CrxEnrollment[]>([]);
   const [selectedItem, setSelectedItem] = React.useState<CrxEnrollment>();

   const defaultFromDate: Date = new Date(new Date().setHours(0, 0, 0, 0) - 12096e5); // 12096e5 == 1209600000 == 14*24*60*60*1000 == minus 14 days
   const defaultToDate: Date = new Date(new Date().setHours(23, 59, 59, 999));
   const [crxEnrollmentsFilter, setCrxEnrollmentsFilter] = React.useState<CrxEnrollmentsFilter>({ fromDate: defaultFromDate, toDate: defaultToDate });
   // crxEnrollmentsFilter is sent to the server to filter the result set at the DB in an attempt to reduce the overall payload of the data being sent to the client
   const { items: itemsFromStore, isLoading } = useCrxEnrollments(httpPost, crxEnrollmentsFilter);

   const [practiceList, setPracticeList] = React.useState<IOptionItem[]>([]);
   const [practiceFilter, setPracticeFilter] = React.useState<number>(undefined);
   const [afsNumberFilter, setAfsNumberFilter] = React.useState<string>(undefined);

   const [currentPage, setCurrentPage] = React.useState(1);
   const [currentPageSize, setCurrentPageSize] = React.useState(25);
   const handleOnChange: TableProps<CrxEnrollment>['onChange'] = (pagination, filters, sorter, extra) => setCurrentPage(pagination?.current);


   useEffect(() => {
      if (!isAppDetailsOpen) {
         setSelectedItem(undefined);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [isAppDetailsOpen]);

   useEffect(() => {
      //populate practice list
      if (filteredItems) {
         const options: IOptionItem[] = [];
         filteredItems?.forEach(y => {
            if (!options.some(o => o.value === y.practiceId)) {
               options.push({ label: y.practiceName, value: y.practiceId } as IOptionItem);
            }
         })

         options.sort((a: IOptionItem, b: IOptionItem) => {
            return stringComparer(a.label as string, b.label as string);
         });

         setPracticeList(options);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [filteredItems]);

   useEffect(() => {
      createFilteredItems();
      // No, we don't need to add "createFilteredItems" to the dependency array
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [afsNumberFilter, practiceFilter, itemsFromStore]);

   const createFilteredItems = (inActivePage: number = 1) => {
      if (!itemsFromStore || isLoading) return;

      let newList = itemsFromStore.filter(r => {
         return filterPractice(r) &&
            filterAfsNumber(r) &&
            filterExcludedPractices(r)
      }) ?? [];

      setFilteredItems(newList);
   }

   const filterPractice = (r: CrxEnrollment) => {
      return !practiceFilter || practiceFilter === r.practiceId ? true : false;
   }

   const filterAfsNumber = (r: CrxEnrollment) => {
      return !afsNumberFilter || r.afsNumber?.toLowerCase().indexOf(afsNumberFilter.toLowerCase()) > -1 ? true : false;
   }

   const filterExcludedPractices = (r: CrxEnrollment) => {
      return !excludedPracticeIds || !(excludedPracticeIds.length > 0) || !excludedPracticeIds.includes(r.practiceId)
   }

   // Used for the text highlighting - NOT for filtering the grid data as all date filtering is done server-side in the query to the DB
   const filterToDate = (r: CrxEnrollment) => {
      const t = new Date(r.transmittedOn);
      if (!crxEnrollmentsFilter?.toDate) { return true; }
      else {
         return t <= crxEnrollmentsFilter.toDate;
      }
   }

   // Used for the text highlighting - NOT for filtering the grid data as all date filtering is done server-side in the query to the DB
   const filterFromDate = (r: CrxEnrollment) => {
      const t = new Date(r.transmittedOn);
      if (!crxEnrollmentsFilter?.fromDate) { return true; }
      else {
         return t >= crxEnrollmentsFilter.fromDate;
      }
   }

   const openDetailItem = (item: CrxEnrollment) => {
      setIsAppDetailsOpen(true);
      setSelectedItem(item);
   }

   const tableColumns: ColumnsType<CrxEnrollment> = [
      {
         dataIndex: 'actions',
         width: 100,
         render: (text, record) => <ActionButton
            title='Details'
            buttonText='Details'
            onClick={() => {
               openDetailItem(record);
            }}
         />
      },
      {
         title: 'afsNumber',
         dataIndex: 'afsNumber',
         width: 100,
         sorter: (a, b) => stringComparer(a.afsNumber, b.afsNumber),
         sortDirections: ['ascend', 'descend', 'ascend'],
         defaultSortOrder: 'descend',
         render: (text, record) => {
            if (afsNumberFilter && afsNumberFilter === record.afsNumber) {
               return <HighlightSearchText searchString={afsNumberFilter} targetString={record.afsNumber} />
            }
            return record.afsNumber;
         }
      },
      {
         title: 'PracticeName',
         dataIndex: 'PracticeName',
         sorter: (a, b) => stringComparer(a.practiceName, b.practiceName),
         sortDirections: ['ascend', 'descend', 'ascend'],
         render: (text, record) => {
            if (practiceFilter && practiceFilter === record.practiceId) {
               return <HighlightSearchText searchString={record.practiceName} targetString={record.practiceName} />
            }
            return record.practiceName;
         }
      },
      {
         title: 'FacilityName',
         dataIndex: 'facilityName',
         sorter: (a, b) => stringComparer(a.facilityName, b.facilityName),
         sortDirections: ['ascend', 'descend', 'ascend'],
      },
      {
         title: 'FacilityAddr',
         dataIndex: 'facilityAddr',
         sorter: (a, b) => stringComparer(a.facilityAddr, b.facilityAddr),
         sortDirections: ['ascend', 'descend', 'ascend'],
      },
      {
         title: 'FacilityCity',
         dataIndex: 'facilityCity',
         sorter: (a, b) => stringComparer(a.facilityCity, b.facilityCity),
         sortDirections: ['ascend', 'descend', 'ascend'],
      },
      {
         title: 'FacilityState',
         dataIndex: 'facilityState',
         sorter: (a, b) => stringComparer(a.facilityState, b.facilityState),
         sortDirections: ['ascend', 'descend', 'ascend'],
      },
      {
         title: 'FacilityPhone',
         dataIndex: 'facilityPhone',
         sorter: (a, b) => stringComparer(a.facilityPhone, b.facilityPhone),
         sortDirections: ['ascend', 'descend', 'ascend'],
      },
      {
         title: 'PatientId',
         dataIndex: 'patientId',
         sorter: (a, b) => stringComparer(a.patientId, b.patientId),
         sortDirections: ['ascend', 'descend', 'ascend'],
      },
      {
         title: 'PatientLName',
         dataIndex: 'patientLName',
         sorter: (a, b) => stringComparer(a.patientLName, b.patientLName),
         sortDirections: ['ascend', 'descend', 'ascend'],
      },
      {
         title: 'PatientFName',
         dataIndex: 'patientFName',
         sorter: (a, b) => stringComparer(a.patientFName, b.patientFName),
         sortDirections: ['ascend', 'descend', 'ascend'],
      },
      {
         title: 'PrescriberLName',
         dataIndex: 'prescriberLName',
         sorter: (a, b) => stringComparer(a.prescriberLName, b.prescriberLName),
         sortDirections: ['ascend', 'descend', 'ascend'],
      },
      {
         title: 'PrescriberFName',
         dataIndex: 'prescriberFName',
         sorter: (a, b) => stringComparer(a.prescriberFName, b.prescriberFName),
         sortDirections: ['ascend', 'descend', 'ascend'],
      },
      {
         title: 'TransmittedOn',
         dataIndex: 'transmittedOn',
         sorter: (a, b) => dateComparer(a.transmittedOn, b.transmittedOn),
         sortDirections: ['ascend', 'descend', 'ascend'],
         render: (text, record) => {
            const transmittedOn = formatDateTimeString(record.transmittedOn);
            if (filterFromDate || filterToDate) {
               return <HighlightSearchText searchString={transmittedOn} targetString={transmittedOn} />
            }
            return transmittedOn;
         }
      },
      {
         title: 'APAppId',
         width: 80,
         dataIndex: 'apAppId',
         sorter: (a, b) => numberComparer(a.apAppId, b.apAppId),
         sortDirections: ['ascend', 'descend', 'ascend'],
      },

   ];
   const html = (
      <ContentCard>
         <SearchResultsTable
            loading={isLoading}
            rowkey={'apAppId'}
            onChange={handleOnChange}
            columns={tableColumns}
            data={filteredItems}
            currentPage={currentPage}
            currentPageSize={currentPageSize}
            setCurrentPageSize={setCurrentPageSize}
            titleText='Applications'
            fixedHeader={true}
            size={'small'}
            scrollHeight={300}
            additionalComponents={[
               <BasicDropdownField
                  containerStyle={{ width: 250 }}
                  name='PracticeFilter'
                  label='Filter by Practice'
                  placeholder={' - Filter by Practice -'}
                  multiple={false}
                  options={[{ label: "All", value: "" }, ...practiceList]}
                  value={practiceFilter}
                  search={true}
                  clearable={true}
                  onChange={(e) => {
                     setPracticeFilter(e as number);
                  }}
               />,
               <BasicInputField
                  containerStyle={{ width: 250 }}
                  placeholder='AFS Number'
                  label='Filter by AFS Number'
                  type="text"
                  name="afsNumberFilter"
                  value={afsNumberFilter}
                  onChange={(e) => {
                     setAfsNumberFilter(e as string);
                  }} />,
               <BasicDatePickerField
                  containerStyle={{ width: 150 }}
                  name='fromDate'
                  label='Select From Date'
                  multiple={false}
                  value={crxEnrollmentsFilter?.fromDate}
                  clearable={true}
                  onChangeDate={(e) => setCrxEnrollmentsFilter({ ...crxEnrollmentsFilter, fromDate: e as Date })}
               // ^ crxEnrollmentsFilter is sent to the server to filter the result set at the DB in an attempt to reduce the overall payload of the data being sent to the client
               />,
               <BasicDatePickerField
                  containerStyle={{ width: 150 }}
                  name='toDate'
                  label='Select To Date'
                  multiple={false}
                  value={crxEnrollmentsFilter?.toDate}
                  clearable={true}
                  onChangeDate={(e) => setCrxEnrollmentsFilter({ ...crxEnrollmentsFilter, toDate: e as Date })}
               // ^ crxEnrollmentsFilter is sent to the server to filter the result set at the DB in an attempt to reduce the overall payload of the data being sent to the client
               />]}
         />


         {selectedItem &&
            <CrxPayloadDialog
               isOpen={isAppDetailsOpen}
               setIsOpen={setIsAppDetailsOpen}
               selectedItem={selectedItem}
            />
         }
      </ContentCard>
   )

   return html;
}
