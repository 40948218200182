import { HttpError, TPost } from '../functions/httpClient';

const logBaseUrl = `/api/LogMsg`;
const debugUrl = `${logBaseUrl}/Debug`;
const infoUrl = `${logBaseUrl}/Info`;
const warnUrl = `${logBaseUrl}/Warn`;
const errorUrl = `${logBaseUrl}/Error`;
export const throwOneUrl = `${logBaseUrl}/ThrowErrorOne`;
export const throwTwoUrl = `${logBaseUrl}/ThrowErrorTwo`;
const timeoutAttemptUrl = `${logBaseUrl}/AttemptTimeoutError`;

export const logDebug = async (httpPost: TPost, message: string) => {
   await httpPost(debugUrl, { message: message });
}

export const logInfo = async (httpPost: TPost, message: string) => {
   await httpPost(infoUrl, { message: message });
}

export const logWarn = async (httpPost: TPost, message: string) => {
   await httpPost(warnUrl, { message: message });
}

export const logError = async (httpPost: TPost, message: string) => {
   await httpPost(errorUrl, { message: message });
}

export const throwErrorOne = async (httpPost: TPost) => {
   await httpPost(throwOneUrl)
      .catch((err: HttpError) => {
         console.log('throwErrorOne', err);
      });
}

export const throwErrorTwo = async (httpPost: TPost) => {
   await httpPost(throwTwoUrl)
      .catch((err: HttpError) => {
         console.log('throwErrorTwo', err);
      });
}

export const timeoutAttempt = async (httpPost: TPost) => {
   await httpPost(timeoutAttemptUrl)
      .catch((err: HttpError) => {
         console.log('throwErrorTwo', err);
      });
}