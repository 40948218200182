import * as React from 'react';
import { Divider, Popover, Checkbox, Row, Col } from 'antd';
import { ActionButton, EditButton } from '../../shared/Buttons';
import { FoundationDiseaseTypeToSpecialty } from '../../../store/program/FoundationDiseaseTypeModel';
import { Specialty } from '../../../store/specialty/SpecialtyModels';
import { useFetchSpecialtyList } from '../../../store/specialty/SpecialtyFetcher';
import { useApiContext } from '../../../store/ApiContext';

interface IProps {
   foundationDiseaseTypeId?: number,
   selectedSpecialties: FoundationDiseaseTypeToSpecialty[];
   onChange: (dataset: FoundationDiseaseTypeToSpecialty[]) => void;
};

const SpecialtySelector: React.FC<IProps> = (props) => {
   const { foundationDiseaseTypeId, selectedSpecialties, onChange } = props;
   const { httpGet } = useApiContext();
   const { specialtyList } = useFetchSpecialtyList(httpGet);
   const [isOpenFilter, setIsOpenFilter] = React.useState<boolean>(false);
   const defaultButtonText: string = '-- Select a specialty --'
   const [buttonText, setButtonText] = React.useState<string>(defaultButtonText);

   React.useEffect(() => {

      const displayText = specialtyList?.reduce((acc: string, current: Specialty) => {
         if (selectedSpecialties.some(y => y.specialtyId === current.id)) {
            return `${acc}${acc.length ? ", " : ""}${current.name}`;
         }
         return acc;
      }, '');

      setButtonText(displayText ? displayText : defaultButtonText);
   }, [selectedSpecialties,
      specialtyList]);

   const handleSpecialtyClick = async (specialty: Specialty) => {
      const mappedItem = selectedSpecialties.find(y => y.specialtyId === specialty.id);
      let tempDataSet: FoundationDiseaseTypeToSpecialty[] = [];

      if (mappedItem) {
         tempDataSet = selectedSpecialties.filter(y => y.specialtyId !== specialty.id);
      } else {
         tempDataSet = [...selectedSpecialties, { specialtyId: specialty.id, foundationDiseaseTypeId: foundationDiseaseTypeId } as FoundationDiseaseTypeToSpecialty];
      }

      await onChange(tempDataSet);
   }

   const handleIncludeAllClick = async (specialty: Specialty) => {
      const mappedItem = selectedSpecialties.find(y => y.specialtyId === specialty.id);
      if (mappedItem) {
         const tempDataSet = selectedSpecialties.filter(y => y.specialtyId !== specialty.id);
         tempDataSet.push({
            ...mappedItem,
            includeAllDiagnosis: !mappedItem.includeAllDiagnosis
         })

         await onChange(tempDataSet);
      }
   }

   return <Popover
      title='Select Specialties and Dx Unspecified'
      content={<div style={{ width: 350 }}>
         {specialtyList?.map(s => {
            const mappedItem = selectedSpecialties.find(y => y.specialtyId === s.id);

            return <Row key={s.id} justify="end" align="bottom">
               <Col span={12}>
                  <Checkbox
                     onChange={() => handleSpecialtyClick(s)}
                     checked={mappedItem !== undefined}
                  >
                     {s.name}
                  </Checkbox>
               </Col>
               <Col span={12}>
                  <Checkbox
                     onChange={() => handleIncludeAllClick(s)}
                     disabled={!mappedItem}
                     checked={mappedItem && mappedItem.includeAllDiagnosis}
                  >
                     Include All
                  </Checkbox>
               </Col>
            </Row>
         }
         )}
         <Divider style={{ marginTop: 10, marginBottom: 10, marginLeft: 0, marginRight: 0 }} />
         <Row gutter={[16, 16]} justify='end'>
            <Col>
               <ActionButton buttonText='Close Selector' onClick={() => setIsOpenFilter(false)} />
            </Col>
         </Row>
      </div>
      }
      open={isOpenFilter}
      onOpenChange={() => setIsOpenFilter(!isOpenFilter)}
      trigger='click'

   >
      <div>
         <EditButton
            buttonText={buttonText}
            onClick={() => ''} />
      </div>

   </Popover>
}

export default SpecialtySelector;