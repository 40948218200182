import * as React from 'react';
import { Form, Row, Col, Divider } from 'antd';
import { CustomIconType } from '../../shared/AntComponents/CustomIcon'
import { BasicFieldWrapper } from '../../shared/BasicInputLibrary';
import Dialog from '../../Dialog';
import { SaveButton, CancelButton } from '../../shared/Buttons';
import Spinner from '../../Spinner';
import { usePracticeOptions } from '../../../store/practice/PracticeFetcher';
import { useAssistanceProgramNameOptions, useFetchAssistancePrograms} from '../../../store/program/AssistanceProgramFetcher';
import { useBrandOptions } from '../../../store/drug/BrandFetcher';
import { xrefStatusOptions, IntrinsiqPayerToAP } from '../../../store/content-tool/IntrinsiqPayerToAPModel';
import {
   saveIntrinsiqPayerToAP,
   intrinsiqPayerBaseUrl
} from '../../../store/content-tool/IntrinsiqPayerToAPFetcher';
import ApiErrorDisplay from '../../ApiErrorDisplay';
import { TitleError } from '../../shared/AntComponents/Typography/Title';
import { useApiContext } from '../../../store/ApiContext';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormMultiSelectInput } from '../../shared/MultiSelect/FormMultiSelectInput';
import { DropdownField } from '../../shared/InputLibrary';
import { useErrorContext } from '../../../store/ErrorContext';

interface IProps {
    isOpen: boolean;
    closeEditor: () => void;
    bulkSourceItems: IntrinsiqPayerToAP[]
};

const dialogContentStyle = {
    height: '580px',
    maxHeight: '95vh',
}

interface IIntrinsiqBulkEditor {
   newRecordStatus: string;
   newRecordAssistanceProgramId: number;
   additionalPrograms: number[];
}

const yupIntrinsiqPayerBulkEditorSchema = yup.object({
   newRecordStatus: yup.string().required('A Status must be selected'),
   newRecordAssistanceProgramId: yup.number().required('An Assistance Program must be selected'),
   multiSelectAdditionalPrograms: yup.array()
})

interface IIntrinsiqPayerBulkEditorYup extends yup.Asserts<typeof yupIntrinsiqPayerBulkEditorSchema> { } 

const __formId = "frmIntrinsiqPayerToApXRefBulk";
const MAPPED_STATUS = 'Mapped';
const _keysLike: string[] = [intrinsiqPayerBaseUrl];

const IntrinsiqPayerToApXRefBulkEditor: React.FC<IProps> = (props) => {
   const { isOpen, closeEditor, bulkSourceItems } = props;

   const { httpGet, httpPost } = useApiContext();
   const { removeErrors } = useErrorContext();
   const [isFormSaving, setIsFormSaving] = React.useState<boolean>(false);

   const { practiceOptions } = usePracticeOptions(httpGet);
   const { assistancePrograms } = useFetchAssistancePrograms(httpGet);
   const { assistanceProgramNameOptions } = useAssistanceProgramNameOptions(httpGet);
   const { brandOptions } = useBrandOptions(httpGet);
   const _addNewRef = React.useRef(null);

   const { control, handleSubmit, getValues, trigger, reset, formState: { errors, } } = useForm<IIntrinsiqPayerBulkEditorYup>({
      resolver: yupResolver<yup.AnyObject>(yupIntrinsiqPayerBulkEditorSchema),
      shouldFocusError: true,
   });

   React.useEffect(() => {
      reset({
         newRecordStatus: MAPPED_STATUS,
         newRecordAssistanceProgramId: undefined,
         additionalPrograms: []
      } as IIntrinsiqBulkEditor);
      setIsFormSaving(false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const onSubmit = (data: IIntrinsiqPayerBulkEditorYup) => {
      setIsFormSaving(true);

      const recordsToSave: IntrinsiqPayerToAP[] = [];

      // The first set of Status/Program is required so let's map that to all source items
      const firstProgram = assistancePrograms.find(y => y.id === data.newRecordAssistanceProgramId);
      bulkSourceItems?.map(sourceItem => {
         recordsToSave.push({
            ...sourceItem,
            /* Based on the prior logic of "update first item, create new after" - This first Program/Status is 
             * meant to update the existing records while the multi-select does net new */
            id: sourceItem.id, 
            assistanceProgramId: firstProgram?.id,
            programName: firstProgram?.programName,
            status: data.newRecordStatus
         });
      })

      // If we have any multiSelectAddiitonalPrograms, let's map them to all source items as well
      if (data.multiSelectAdditionalPrograms?.length > 0) {
         bulkSourceItems?.map((sourceItem) => (
            data.multiSelectAdditionalPrograms.map(programId => {
               const p = assistancePrograms.find(y => y.id === programId);
               recordsToSave.push({
                  ...sourceItem,
                  id: undefined,
                  assistanceProgramId: p?.id,
                  programName: p?.programName,
                  status: MAPPED_STATUS
               } as IntrinsiqPayerToAP)
            })
         ));   
      }

      saveIntrinsiqPayerToAP(httpPost, recordsToSave)
         .then(handleClose)
         .catch(err => console.error('Error calling saveIntrinsiqPayerToAP', err))
         .finally(() => setIsFormSaving(false));

   }

   const handleClose = () => {
      setIsFormSaving(false);
      removeErrors({ keysLike: _keysLike });
      closeEditor();
   }

   if (isFormSaving && isOpen) {
      return <Spinner message='Saving Bulk IntrinsiqPayerToAP...' />
   }

   const html = (
      <Dialog
         scrollingContent={true}
         title={'IntrinsiqPayerToAP Bulk Update'}
         open={isOpen}
         size="large"
         actionButtons={
            <> 
               <CancelButton onClick={() => handleClose()} loading={isFormSaving} />
               <SaveButton
                  formId={__formId}
                  onClick={() => null}
                  disabled={Object.keys(errors).length > 0}
                  loading={isFormSaving} />
            </>
         }>
         <div style={dialogContentStyle}>

            {errors && Object.keys(errors).length > 0 && <>
               <TitleError
                  text='Please correct the errors below' />
               {console.log('--------------Form Errors: -------------------------')}
               {console.log(errors)}
            </>
            }

            <ApiErrorDisplay
               title='Error saving IntrinsiqPayerToAP'
               keysLike={_keysLike}
            />

            <Form id={__formId} onFinish={handleSubmit(onSubmit)}>
               {bulkSourceItems?.map((field, index) => {
                  return <Row gutter={[16, 16]} key={field.id}>
                     <Col span={8}>
                        <BasicFieldWrapper
                           field={practiceOptions.find(y => y.value === field.practiceId)?.label}
                           label='Practice'
                           placeholder={field?.practiceId === undefined ? ' - none -' : `${field?.practiceId}`}
                           disabled={true}
                        />
                     </Col>
                     <Col span={8}>
                        <BasicFieldWrapper
                           name='payeeName'
                           field={field.payeeName}
                           label='payeeName'
                           type='text'
                           disabled={true}
                        />
                     </Col>
                     <Col span={8}>
                        <BasicFieldWrapper
                           name='brandId'
                           field={brandOptions.find(y => y.value === field.brandId)?.label}
                           label='brandId'
                           placeholder={field?.brandId === undefined ? ' - none -' : `${field?.brandId}`}
                           disabled={true}
                        />
                     </Col>
                  </Row>
               })}

               <Row gutter={[16, 16]}>
                  <Col span={12}>
                     <DropdownField
                        name='newRecordStatus'
                        label='Status'
                        placeholder={' - Select a Status -'}
                        multiple={false}
                        options={xrefStatusOptions}
                        control={control}
                        error={errors?.newRecordStatus}
                        required
                        search
                     />
                  </Col>
                  <Col span={12}>
                     <DropdownField
                        name='newRecordAssistanceProgramId'
                        label='Assistance Program'
                        multiple={false}
                        options={assistanceProgramNameOptions}
                        required={getValues()?.newRecordStatus === MAPPED_STATUS}
                        control={control}
                        error={errors?.newRecordAssistanceProgramId}
                        search
                        clearable
                     />

                  </Col>
               </Row>
               <Divider>Additional XRef Records</Divider>
               <FormMultiSelectInput
                  options={assistanceProgramNameOptions}
                  searchable
                  allowSelectAll
                  label={`Select Additional Programs (${getValues()?.multiSelectAdditionalPrograms?.length ?? 0} selected)`}
                  searchPlaceholder={'Seach for Programs'}
                  popoverTitle={'Select Additional Programs'}
                  control={control}
                  name={'multiSelectAdditionalPrograms'}
                  error={errors?.multiSelectAdditionalPrograms}
                  customIcon={CustomIconType.PlusOutlined}
                  showRecordCount
                  trigger={trigger}
                  displayListStartingLength={200}
                  showOkButton
               />
               <p><strong>NOTE:</strong> Each Additional Program selected here will be created with a <strong>Status</strong> of <strong>Mapped</strong></p>
               
            </Form>
            <div style={{ height: 0 }} ref={_addNewRef} />
         </div>

      </Dialog>
   )
   return html;
}

export default IntrinsiqPayerToApXRefBulkEditor;

