import * as React from 'react';
import { Link } from 'react-router-dom';
import { EditorAction } from '../../store/cohort/CohortModel';
import {
   cohortSummaryBaseUrl,
   findCohortSummaryUrl,
   useFetchSearchCohortSummary
} from '../../store/cohort/CohortSummaryFetcher';
import {
   CohortSummary, countAdminItemsTodo, countOpenCohorts,
   countUserItemsTodo, isCohortPublished
} from '../../store/cohort/CohortSummaryModel';

import { Space } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { formatDate } from 'src/functions/time.functions';
import { colorWheel } from '../../Theme/ColorWheel';
import Restricted from '../../auth/Restricted';
import { dateComparer, numberComparer, stringComparer } from '../../functions/comparer.functions';
import { CohortStatusOptions } from '../../functions/option.functions';
import { useApiContext } from '../../store/ApiContext';
import { useErrorContext } from '../../store/ErrorContext';
import { KnownSettings } from '../../store/SettingsModel';
import { cohortRefreshAllUrl } from '../../store/cohort/CohortFetcher';
import { CohortItemStatusType } from '../../store/cohort/CohortItemStatus';
import ApiErrorDisplay from '../ApiErrorDisplay';
import CustomIcon, { CustomIconType } from '../shared/AntComponents/CustomIcon';
import SearchResultsTable from '../shared/AntComponents/Table/SearchResultsTable';
import { BasicDatePickerField, BasicDropdownField } from '../shared/BasicInputLibrary';
import { ActionButton } from '../shared/Buttons';
import { useCohortContext } from './CohortContext';
import CohortSummaryCommand from './CohortSummaryCommand';
import CohortSummaryEditor from './CohortSummaryEditor';
import ItemStatusIndicator from './ItemStatusIndicator';

interface IModalCommand {
   opened: boolean;
   headingCss: string;
   cohort: CohortSummary;
   editorAction?: EditorAction
}

interface IModalEditor {
   opened: boolean;
   headingCss: string;
   cohort: CohortSummary;
}

const errorKeys = { keys: [cohortSummaryBaseUrl, findCohortSummaryUrl, cohortRefreshAllUrl] };

const CohortSummaryList: React.FC = () => {
   const { httpPost } = useApiContext();
   const { removeErrors } = useErrorContext();

   const [modalCommand, setModalCommand] = React.useState<IModalCommand>({ opened: false, headingCss: '', cohort: undefined });
   const [modalEditor, setModalEditor] = React.useState<IModalEditor>({ opened: false, headingCss: '', cohort: undefined });

   const { setCardTitle, setCardSubText, cohortSummaryFromDate, setCohortSummaryFromDate } = useCohortContext();

   const { results, isLoading, error } = useFetchSearchCohortSummary(httpPost, cohortSummaryFromDate);
   // const [earliestDateFilter, setEarliestDateFilter] = React.useState<Date>();
   const [cohortStatusFilter, setCohortStatusFilter] = React.useState<string>();
   const [filteredItems, setFilteredItems] = React.useState<CohortSummary[]>([]);

   React.useEffect(() => {
      const loadView = (): void => {
         if (results) {
            setCardTitle(`${countOpenCohorts(results)} Open Cohorts`);
            setCardSubText(`Content ${countUserItemsTodo(results)}, Admin ${countAdminItemsTodo(results)} Items`);
         }
      }
      loadView();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [results]);

   React.useEffect(() => {
      createFilteredItems();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [results,
      cohortSummaryFromDate,
      cohortStatusFilter
   ]);

   const createFilteredItems = () => {
      if (!results || results.length <= 0) return;

      let newList = results.filter(model => {
         return filterCohortStatus(model);
      }) ?? [];

      setFilteredItems(newList);
   }

   const filterCohortStatus = (p: CohortSummary): boolean => {
      return !cohortStatusFilter || p.cohortStatus.toLowerCase().indexOf(cohortStatusFilter.toLowerCase()) > -1;
   }

   const handleCohortCommand = (row: CohortSummary,
      editorAction: EditorAction) => {

      if (editorAction === EditorAction.Copy) {
         setModalEditor({
            ...modalEditor,
            opened: true,
            cohort: row,
         });
      } else {
         setModalCommand({
            ...modalCommand,
            opened: true,
            cohort: row,
            editorAction: editorAction
         });
      }
   };

   const handleModalCommandClose = () => {
      //close editor 
      setModalCommand({
         ...modalCommand,
         opened: false,
      });
      setModalEditor({
         ...modalEditor,
         opened: false,
      });
   }

   const getDerivedStatus = (cohort: CohortSummary): CohortItemStatusType => {
      let derivedItemsStatus: number = CohortItemStatusType.New;
      const isPublished = isCohortPublished(cohort);

      if (isPublished) {
         derivedItemsStatus = CohortItemStatusType.Published_Change;
      } else if (cohort.rejectedChanges > 0) {
         //at least one rejection results in ⛔
         derivedItemsStatus = CohortItemStatusType.Rejected_Change;
      } else if (cohort.totalItems > 0 && cohort.acceptedChanges + cohort.auditedItems + cohort.rejectedChanges === cohort.totalItems) {
         //all done, ready to publish ✅
         derivedItemsStatus = CohortItemStatusType.Accepted_Change;
      } else if (cohort.recommendedChanges > 0) {
         //at least one recommended change ⚠️
         derivedItemsStatus = CohortItemStatusType.Recommended_Change;
      } else if (cohort.auditedItems > 0) {
         //at least one audited\no change item ✔️
         derivedItemsStatus = CohortItemStatusType.Audited_Confirm_No_Change;
      } else {
         //purva has work
         derivedItemsStatus = CohortItemStatusType.New;
      }
      return derivedItemsStatus;
   }

   const columns: ColumnsType<CohortSummary> = [
      {
         dataIndex: 'cohortId',
         width: 10,
         render: (text, record) => <ItemStatusIndicator itemStatusArray={[getDerivedStatus(record)]} />
      },
      {
         title: 'Cohort',
         width: 40,
         dataIndex: 'name',
         sorter: (a, b) => stringComparer(a.name, b.name),
         sortDirections: ['ascend', 'descend', 'ascend'],
         render: (text, record) => <Link to={{
            pathname: `/cohort/programsummary/${record.cohortId}`

         }}
            title={record.name}>
            {record.name}
         </Link>
      },
      {
         title: 'Cohort Start',
         dataIndex: 'startDate',
         sorter: (a, b) => dateComparer(a.startDate, b.startDate),
         sortDirections: ['ascend', 'descend', 'ascend'],
         defaultSortOrder: 'descend',
         render: (text, record) => <span style={{ whiteSpace: 'nowrap' }}>{formatDate(record.startDate)}</span>,
         width: 35,
      },
      {
         title: 'Cohort End',
         dataIndex: 'endDate',
         sorter: (a, b) => dateComparer(a.endDate, b.endDate),
         sortDirections: ['ascend', 'descend', 'ascend'],
         render: (text, record) => <span style={{ whiteSpace: 'nowrap' }}>{formatDate(record.endDate)}</span>,
         width: 35,
      },
      {
         title: 'Total',
         dataIndex: 'totalItems',
         sorter: (a, b) => numberComparer(a.totalItems, b.totalItems),
         sortDirections: ['ascend', 'descend', 'ascend'],
         width: 30,
      },
      {
         title: 'New',
         dataIndex: 'newItems',
         sorter: (a, b) => numberComparer(a.newItems, b.newItems),
         sortDirections: ['ascend', 'descend', 'ascend'],
         width: 30,
      },
      {
         title: 'Audited',
         dataIndex: 'auditedItems',
         sorter: (a, b) => numberComparer(a.auditedItems, b.auditedItems),
         sortDirections: ['ascend', 'descend', 'ascend'],
         width: 30,
      },
      {
         title: 'Recommended',
         dataIndex: 'recommendedChanges',
         sorter: (a, b) => numberComparer(a.recommendedChanges, b.recommendedChanges),
         sortDirections: ['ascend', 'descend', 'ascend'],
         width: 35,
      },
      {
         title: 'Accepted',
         dataIndex: 'acceptedChanges',
         sorter: (a, b) => numberComparer(a.acceptedChanges, b.acceptedChanges),
         sortDirections: ['ascend', 'descend', 'ascend'],
         width: 30,
      },
      {
         title: 'Rejected',
         dataIndex: 'rejectedChanges',
         sorter: (a, b) => numberComparer(a.rejectedChanges, b.rejectedChanges),
         sortDirections: ['ascend', 'descend', 'ascend'],
         width: 30,
      },
      {
         title: 'Published',
         dataIndex: 'publishedItems',
         sorter: (a, b) => numberComparer(a.publishedItems, b.publishedItems),
         sortDirections: ['ascend', 'descend', 'ascend'],
         width: 30,
      },
      {
         title: 'Published On',
         dataIndex: 'publishedDate',
         sorter: (a, b) => dateComparer(a.publishedDate, b.publishedDate),
         sortDirections: ['ascend', 'descend', 'ascend'],
         render: (text, record) => record.publishedDate ? <span style={{ whiteSpace: 'nowrap' }}>{formatDate(record.publishedDate)}</span> : null,
         width: 35,
      },
      {
         title: 'Action',
         fixed: 'right',
         width: 40,
         render: (text, record) => {
            const derivedItemStatus = getDerivedStatus(record);
            const showPublish = record.totalItems > 0 && record.newItems === 0 && record.recommendedChanges === 0;
            const isPublished = isCohortPublished(record);

            return <Space size={2}>
               {derivedItemStatus < 6 &&
                  <ActionButton
                     title='Refresh Cohort'
                     icon={<CustomIcon type={CustomIconType.RedoOutlined} />}
                     style={{
                        paddingLeft: 5,
                        paddingRight: 5
                     }}
                     onClick={() => handleCohortCommand(record, EditorAction.Refresh)}
                  />
               }
               <Restricted requiredRoles={[KnownSettings.CohortAdmin]}>
                  <Space size={2}>
                     <ActionButton
                        title='Create new from copy'
                        icon={<CustomIcon type={CustomIconType.CopyOutlined} />}
                        style={{
                           paddingLeft: 5,
                           paddingRight: 5,
                        }}
                        onClick={() => handleCohortCommand(record, EditorAction.Copy)}
                     />
                     {!isPublished &&
                        <ActionButton
                           title='Soft Delete Cohort'
                           danger
                           icon={<CustomIcon type={CustomIconType.DeleteOutlined} />}
                           style={{
                              paddingLeft: 5,
                              paddingRight: 5
                           }}
                           onClick={() => handleCohortCommand(record, EditorAction.Delete)}
                        />
                     }
                     {!isPublished && showPublish &&
                        <ActionButton
                           title='Publish and Close Cohort'
                           icon={<CustomIcon type={CustomIconType.CloudUploadOutlined} style={{ color: colorWheel.ivyGreen }} />}
                           style={{
                              paddingLeft: 5,
                              paddingRight: 5,
                              borderColor: colorWheel.ivyGreen
                           }}
                           onClick={() => handleCohortCommand(record, EditorAction.Publish)}
                        />
                     }
                  </Space>
               </Restricted>
            </Space>
         }
      },
   ];

   return (
      <>
         <ApiErrorDisplay
            title='Error'
            keys={errorKeys.keys} />

         <SearchResultsTable
            rowkey={'cohortId'}
            columns={columns}
            data={filteredItems}
            fixedHeader={true}
            size='small'
            loading={isLoading}
            scrollX='calc(100vw - 50px)'
            scrollY='calc(100vh - 275px)'
            additionalComponents={[
               <Space>
                  <BasicDatePickerField
                     containerStyle={{ width: 200 }}
                     name='earliestDate'
                     label='Earliest Date'
                     value={cohortSummaryFromDate}
                     clearable={true}
                     onChangeDate={(e) => setCohortSummaryFromDate(e)}
                  />
                  <BasicDropdownField
                     containerStyle={{ width: 200 }}
                     label='Status'
                     placeholder=' -- Select Status -- '
                     clearable={true}
                     value={cohortStatusFilter}
                     onChange={(e) => setCohortStatusFilter(e as string)}
                     name='specialtyFilter'
                     search={true}
                     options={CohortStatusOptions}
                  />
               </Space>
            ]}
         />

         {modalCommand.opened &&
            <CohortSummaryCommand
               key={22222}
               cohortSummary={modalCommand.cohort}
               isOpen={modalCommand.opened}
               closeEditor={handleModalCommandClose}
               editorAction={modalCommand.editorAction}
            />
         }

         {modalEditor.opened &&
            <CohortSummaryEditor
               key={33333}
               cohortSummary={modalEditor.cohort}
               isOpen={modalEditor.opened}
               closeEditor={handleModalCommandClose}
            />
         }
      </>)
}

export default CohortSummaryList;