import { AssistanceServiceBrandDefaults, AssistanceServiceBrandDefaultsViewModel } from './AssistanceServiceBrandDefaultsModel';
import * as React from 'react';
import useSWR, { mutate } from "swr";
import { HttpError, TGet, TPost, TDelete } from "../../functions/httpClient";
import { IOptionItem } from "../../functions/option.functions";
import { IStatus } from '../models';
import { useAssistanceProgramNameOptions, useFetchAssistancePrograms } from './AssistanceProgramFetcher';
import { useAssistanceProgramAndServicesOptions, useFetchAssistanceServices } from './AssistanceServiceFetcher';
import { useFetchManufacturer, useManufacturerOptions } from './ManufacturerFetcher';
import { useBrandOptions, useFetchBrand  } from '../drug/BrandFetcher';
import { useFetchDrug } from '../drug/DrugFetcher';
import { refreshActiveCohorts } from '../cohort/CohortFetcher';

export const assistanceServiceBrandDefaultsBaseUrl = 'api/program/AssistanceServiceBrandDefaults';
const saveAssistanceServiceBrandDefaultsUrl = `${assistanceServiceBrandDefaultsBaseUrl}/Save`;
const deleteAssistanceServiceBrandDefaultUrl = (id: number) => `${assistanceServiceBrandDefaultsBaseUrl}/Delete/${id}`;

const swrOptions = {
   refreshInterval: 0,
   revalidateOnFocus: false,
   dedupingInterval: 60000
};

export const useFetchBrandDefaults = (httpGet: TGet): IStatus & { brandDefaults: AssistanceServiceBrandDefaults[] } => {
   const { data, isLoading, error } = useSWR<AssistanceServiceBrandDefaults[], HttpError>(
      assistanceServiceBrandDefaultsBaseUrl,
      httpGet,
      { ...swrOptions }
   );

   return {
      brandDefaults: data,
      isLoading: isLoading,
      error: error?.message
   };
}

export const saveBrandDefaults = async (httpPost: TPost, brandDefaults: AssistanceServiceBrandDefaults[], cohortFromDate: Date) => {
   const result = await httpPost(saveAssistanceServiceBrandDefaultsUrl, brandDefaults).then();
   refreshActiveCohorts(httpPost, cohortFromDate);
   mutate(assistanceServiceBrandDefaultsBaseUrl);
   return result;
}

export const deleteBrandDefault = async (httpDelete: TDelete, id: number) => {
   const result = await httpDelete(deleteAssistanceServiceBrandDefaultUrl(id));
   mutate(assistanceServiceBrandDefaultsBaseUrl);
   return result;
}

export function useBrandDefaultsViewModel(httpGet: TGet): IStatus & { brandDefaultsViewModelList: AssistanceServiceBrandDefaultsViewModel[] } {
   const { brandDefaults, isLoading, error, isSaving } = useFetchBrandDefaults(httpGet);
   const { assistancePrograms, isLoading: isLoading_p, error: error_p } = useFetchAssistancePrograms(httpGet);
   const { assistanceServices, isLoading: isLoading_s, error: error_s } = useFetchAssistanceServices(httpGet);
   const { manufacturers, isLoading: isLoading_m, error: error_m } = useFetchManufacturer(httpGet);
   const { brands, isLoading: isLoading_b, error: error_b } = useFetchBrand(httpGet);
   const { drugs, isLoading: isLoading_d, error: error_d } = useFetchDrug(httpGet);

   const viewModel = React.useMemo(() => {
      if (brandDefaults?.length > 0 &&
         assistancePrograms?.length > 0 &&
         assistanceServices?.length > 0 &&
         manufacturers?.length > 0 &&
         brands?.length > 0 &&
         drugs?.length > 0) {
         let tempList = brandDefaults.map((item): AssistanceServiceBrandDefaultsViewModel => {
            const s = assistanceServices.find(y => y.id === item.assistanceServiceId);
            const p = s ? assistancePrograms.find(y => y.id === s?.assistanceProgramId) : undefined;
            const b = brands.find(y => y.id === item.brandId);
            const d = b ? drugs.find(y => y.brandId === b.id) : undefined;
            const m = d ? manufacturers.find(y => y.id === d.manufacturerId) : undefined;

            return {
               ...item,
               brandName: b?.brandName,
               assistanceServiceName: s?.assistanceServiceName,
               manufacturerId: m?.id,
               manufacturerName: m?.manufacturerName,
               assistanceProgramId: p?.id,
               programName: p?.programName
            } as AssistanceServiceBrandDefaultsViewModel

         });
         return tempList;
      }
      return [];
   }, [brandDefaults, assistancePrograms, assistanceServices, manufacturers, brands, drugs]);

   return {
      brandDefaultsViewModelList: viewModel,
      isLoading: isLoading || isLoading_p || isLoading_s || isLoading_m || isLoading_b || isLoading_d,
      error: error ?? error_p ?? error_s ?? error_m ?? error_b ?? error_d,
      isSaving
   };
}

export function useBrandDefaultsAssistanceServiceOptions(httpGet: TGet): { brandDefaultsAssistanceProgramServiceOptions: IOptionItem[] } {
   const { brandDefaultsViewModelList } = useBrandDefaultsViewModel(httpGet);
   const { assistanceProgramAndServicesOptions } = useAssistanceProgramAndServicesOptions(httpGet);

   const options = React.useMemo(() => {
      if (brandDefaultsViewModelList?.length > 0 && assistanceProgramAndServicesOptions?.length > 0) {
         const options: IOptionItem[] = [];
         assistanceProgramAndServicesOptions.reduce((acc: IOptionItem[], option) => {
            if (brandDefaultsViewModelList.some(y => y.assistanceServiceId === option.value)) {
               options.push(option);
            }
            return acc;
         }, []);

         return options;
      }
      return [];
   }, [brandDefaultsViewModelList, assistanceProgramAndServicesOptions]);


   return {
      brandDefaultsAssistanceProgramServiceOptions: options
   };
}

export function useBrandDefaultsManufacturerOptions(httpGet: TGet): { brandDefaultsManufacturerOptions: IOptionItem[] } {
   const { brandDefaultsViewModelList } = useBrandDefaultsViewModel(httpGet);
   const { manufacturerOptions } = useManufacturerOptions(httpGet);

   const options = React.useMemo(() => {
      if (brandDefaultsViewModelList?.length > 0 && brandDefaultsViewModelList?.length > 0) {
         const options: IOptionItem[] = [];
         manufacturerOptions.reduce((acc: IOptionItem[], option) => {
            if (brandDefaultsViewModelList.some(y => y.manufacturerId === option.value)) {
               options.push(option);
            }
            return acc;
         }, []);

         return options;
      }
      return []
   }, [brandDefaultsViewModelList, manufacturerOptions]);

   return {
      brandDefaultsManufacturerOptions: options
   };
}

export function useBrandDefaultsBrandOptions(httpGet: TGet): { brandDefaultsBrandOptions: IOptionItem[] } {
   const { brandDefaultsViewModelList } = useBrandDefaultsViewModel(httpGet);
   const { brandOptions } = useBrandOptions(httpGet);

   const options = React.useMemo(() => {
      if (brandDefaultsViewModelList?.length > 0 && brandDefaultsViewModelList?.length > 0) {
         const options: IOptionItem[] = [];
         brandOptions.reduce((acc: IOptionItem[], option) => {
            if (brandDefaultsViewModelList.some(y => y.brandId === option.value)) {
               options.push(option);
            }
            return acc;
         }, []);

         return options;
      }
      return [];
   }, [brandDefaultsViewModelList, brandOptions]);

   return {
      brandDefaultsBrandOptions: options
   };
}
