import * as React from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { InputField, DropdownField } from '../../shared/InputLibrary';
import ApiErrorDisplay from '../../ApiErrorDisplay';
import Dialog from '../../Dialog';
import { SaveButton, CancelButton, DeleteButton } from '../../shared/Buttons';
import { Form, Divider, Row, Col } from 'antd';
import { useUnitOfMeasureOptions } from '../../../store/form/FormOptionItemFetcher';
import { useFoundationDiseaseTypesWithFoundationOptions } from '../../../store/program/FoundationDiseaseType';
import { useFetchAssistancePrograms } from '../../../store/program/AssistanceProgramFetcher';
import { AssistanceServiceFoundationDefaults } from '../../../store/program/AssistanceServiceFoundationDefaultsModel';
import { IOptionItem } from '../../../functions/option.functions';
import { TitleError } from '../../shared/AntComponents/Typography/Title';
import { AssistanceService } from '../../../store/program/AssistanceServiceModel';
import { useFetchAssistanceServices } from '../../../store/program/AssistanceServiceFetcher';
import { useBenefitPeriodTypeOptions } from '../../../store/program/BenefitPeriodTypeFetcher';
import {
   CALENDAR_BENEFIT_TYPE_ID, ENROLLMENT_BENEFIT_TYPE_ID,
   monthOptions, dayOptions
} from '../../../store/program/BenefitPeriodTypeModel';
import { deleteFoundationDefault, saveFoundationDefaults, useFetchFoundationDefaults, assistanceServiceFoundationDefaultsBaseUrl } from '../../../store/program/AssistanceServiceFoundationDefaultsFetcher';
import { useApiContext } from '../../../store/ApiContext';
import { useCohortContext } from '../../cohort/CohortContext';
import { leftPadDayOrMonth } from '../../../functions/common.functions';
import { isDateValid } from 'src/functions/time.functions';
import { useErrorContext } from '../../../store/ErrorContext';

interface IProps {
   isOpen: boolean;
   closeEditor: () => void;
   id?: number;
   readonly?: boolean;
   shouldFoundationDiseaseTypeBeDisabled?: boolean; // should be set to true when editing from a foundation program
   shouldAssistanceServiceBeDisabled?: boolean; // should be set to true when editing/"adding new" as a User is viewing a specific service when doing so
   shouldUseProvidedAssistanceService?: boolean; // should be set to true when "adding new" as a User is viewing a specific service when they click "add"
   assistanceService?: AssistanceService;
};

const dialogContentStyle = {
   height: '580px',
   maxHeight: '95vh',
}

const yupFoundationDefaultsSchema = yup.object({
   id: yup.number().notRequired(),
   assistanceServiceId: yup.number()
      .required('Assistance Service is required'),
   foundationDiseaseTypeId: yup.number()
      .required('FoundationDiseaseType is required'),
   copayAmount: yup.number()
      .nullable()
      .transform((_, val) => val === '' ? null : Number(val)),

   copayAmountUnits: yup.string()
      .max(50, 'Max length is 50 characters')
      .notRequired(),
   copayAmountNotes: yup.string()
      .max(512, 'Max length is 512 characters')
      .notRequired(),
   copayAmountUrl: yup.string()
      .url('The Resource URL must be a fully-qualified url that begins with "https://" or "https://"')
      .max(1024, 'Max length is 1024 characters')
      .notRequired(),

   approvedAwardAmount: yup.number()
      .nullable()
      .transform((_, val) => val === '' ? null : Number(val)),

   approvedAwardAmountUnits: yup.string()
      .max(50, 'Max length is 50 characters')
      .notRequired(),
   approvedAwardAmountNotes: yup.string()
      .max(512, 'Max length is 512 characters')
      .notRequired(),
   approvedAwardAmountUrl: yup.string()
      .url('The Resource URL must be a fully-qualified url that begins with "https://" or "https://"')
      .max(1024, 'Max length is 1024 characters')
      .notRequired(),

   guaranteedAmount: yup.number()
      .nullable()
      .transform((_, val) => val === '' ? null : Number(val)),

   guaranteedAmountUnits: yup.string()
      .max(50, 'Max length is 50 characters')
      .notRequired(),

   backDateDays: yup.number()
      .nullable()
      .transform((_, val) => val === '' ? null : Number(val)),

   backDateDaysUnits: yup.string()
      .max(50, 'Max length is 50 characters')
      .notRequired(),
   backDateDaysNotes: yup.string()
      .max(512, 'Max length is 512 characters')
      .notRequired(),
   backDateDaysUrl: yup.string()
      .url('The Resource URL must be a fully-qualified url that begins with "https://" or "https://"')
      .max(1024, 'Max length is 1024 characters')
      .notRequired(),

   timelyFilingDays: yup.number()
      .nullable()
      .transform((_, val) => val === '' ? null : Number(val)),

   timelyFilingDaysUnits: yup.string()
      .max(50, 'Max length is 50 characters')
      .notRequired(),
   timelyFilingDaysNotes: yup.string()
      .max(512, 'Max length is 512 characters')
      .notRequired(),
   timelyFilingDaysUrl: yup.string()
      .url('The Resource URL must be a fully-qualified url that begins with "https://" or "https://"')
      .max(1024, 'Max length is 1024 characters')
      .notRequired(),

   modifiedByName: yup.string().notRequired(),
   modifiedOn: yup.date().notRequired(),
   url: yup.string().notRequired(),
   createdByName: yup.string().notRequired(),
   createdOn: yup.date().notRequired(),
   deleted: yup.bool().notRequired(),
   deletedByName: yup.string().notRequired(),
   deletedOn: yup.date().notRequired(),
   benefitPeriodTypeId: yup.number().required('Benefit Period Type is required'),
   benefitPeriodStartMonth: yup.number()
      .min(1, 'Benefit Period Start Month must be a valid month')
      .max(12, 'Benefit Period Start Month must be a valid month')
      .when('benefitPeriodTypeId', {
         is: CALENDAR_BENEFIT_TYPE_ID,
         then: () => yup.number()
            .test('benefitPeriodStartMonthDateCheck', 'Benefit Period Start Month is required and/or day must exist in selected month.', (value, ctx) => {
               try {
                  const dateString = `${new Date().getFullYear()}-${leftPadDayOrMonth(ctx.parent?.benefitPeriodStartMonth)}-${leftPadDayOrMonth(value)}`;
                  return isDateValid(dateString);
               } catch {
                  return false;
               }
            })
      }),
   benefitPeriodStartDay: yup.number()
      .min(1, 'Benefit Period Start Day must be a valid day')
      .max(31, 'Benefit Period Start Day must be a valid day')
      .when('benefitPeriodTypeId', {
         is: CALENDAR_BENEFIT_TYPE_ID,
         then: () => yup.number()
            .test('benefitPeriodStartMonthDateCheck', 'Benefit Period Start Day is required and/or must exist in selected month.', (value, ctx) => {
               try {
                  const dateString = `${new Date().getFullYear()}-${leftPadDayOrMonth(ctx.parent?.benefitPeriodStartMonth)}-${leftPadDayOrMonth(value)}`;
                  return isDateValid(dateString);
               } catch {
                  return false;
               }
            })
      }),
});

interface IFoundationDefaultsYup extends yup.Asserts<typeof yupFoundationDefaultsSchema> { }
const __formId = "frmFoundationDefaults";
const _keysLike: string[] = [assistanceServiceFoundationDefaultsBaseUrl];

const AssistanceServiceFoundationDefaultsEditor: React.FC<IProps> = (props) => {
   const { closeEditor, isOpen, assistanceService, id, readonly,
      shouldAssistanceServiceBeDisabled, shouldFoundationDiseaseTypeBeDisabled,
      shouldUseProvidedAssistanceService } = props;
   const { httpGet, httpPost, httpDelete } = useApiContext();
   const { cohortSummaryFromDate } = useCohortContext();

   const { benefitPeriodTypeOptions } = useBenefitPeriodTypeOptions(httpGet);
   const { foundationDefaults } = useFetchFoundationDefaults(httpGet);
   const { unitOfMeasureOptions } = useUnitOfMeasureOptions(httpGet);
   const { foundationDiseaseTypesWithFoundationOptions } = useFoundationDiseaseTypesWithFoundationOptions(httpGet);
   const { assistanceServices } = useFetchAssistanceServices(httpGet);
   const { assistancePrograms } = useFetchAssistancePrograms(httpGet);

   const [isFormSaving, setIsFormSaving] = React.useState<boolean>(false);
   const { removeErrors } = useErrorContext();
   const clearErrors = () => {
      removeErrors({ keysLike: _keysLike });
   }

   const {
      control,
      handleSubmit,
      formState: { errors },
      reset,
      watch,
      setValue,
      trigger
   } = useForm<IFoundationDefaultsYup>({
      resolver: yupResolver<yup.AnyObject>(yupFoundationDefaultsSchema),
      shouldFocusError: true,
   });

   const watchBenefitType = watch('benefitPeriodTypeId');

   const itemToEdit = React.useMemo(() => {
      return foundationDefaults?.find(y => y.id === id)
   }, [id, foundationDefaults]);


   const assistanceProgramAndServiceList = React.useMemo(() => {
      if (assistancePrograms?.length > 0 &&
         assistanceServices?.length > 0) {

         const tempList: IOptionItem[] = [];
         for (let pidx = 0; pidx < assistancePrograms.length; pidx++) {
            const p = assistancePrograms[pidx];
            const svcs = assistanceServices?.filter(y => y.assistanceProgramId === p.id);
            for (let sidx = 0; sidx < svcs.length; sidx++) {
               if (!tempList.some(y => y.value === svcs[sidx].id)) {
                  tempList.push({
                     key: `${p.id}_${svcs[sidx].id}`,
                     label: `${p.id} (${p.programName}) ${svcs[sidx].id} (${svcs[sidx].assistanceServiceName})`,
                     value: svcs[sidx].id
                  } as IOptionItem);
               }
            }
         }

         return tempList;
      }
      return [];
   }, [assistancePrograms, assistanceServices]);


   React.useEffect(() => {
      let resetValue: AssistanceServiceFoundationDefaults;

      if (shouldUseProvidedAssistanceService) {
         resetValue = {
            ...itemToEdit,
            assistanceServiceId: assistanceService?.id,
            benefitPeriodTypeId: ENROLLMENT_BENEFIT_TYPE_ID // default value
         } as AssistanceServiceFoundationDefaults;
      } else {
         resetValue = itemToEdit as AssistanceServiceFoundationDefaults;
      }
      resetValue = resetValue ?? { id: id, deleted: false, benefitPeriodTypeId: ENROLLMENT_BENEFIT_TYPE_ID } as AssistanceServiceFoundationDefaults;

      reset(resetValue);
      setIsFormSaving(false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [itemToEdit, id, isOpen]);


   const [form] = Form.useForm();

   const onSubmit = async (data: IFoundationDefaultsYup) => {
      //console.log('--------------AssistanceServiceFoundationDefaultsEditor-------------------------')
      //console.log(errors)

      //ts hoops to convert data with unknown props into assistance programs
      const item = [data as unknown as AssistanceServiceFoundationDefaults];
      setIsFormSaving(true);

      await saveFoundationDefaults(httpPost, item, cohortSummaryFromDate)
         .then(() => {
            handleClose();
         })
         .catch(err => console.log(`Error Saving`, err))
         .finally(() => {
            setIsFormSaving(false);
         });
   }

   const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState<boolean>(false);

   const handleDeleteClick = () => {
      setIsDeleteDialogOpen(false);
      deleteFoundationDefault(httpDelete, itemToEdit.id)
         .then(handleClose)
         .catch(err => console.log(`Error Saving`, err));
   }

   const handleClose = () => {
      setIsFormSaving(false);
      setIsDeleteDialogOpen(false);
      clearErrors();
      closeEditor();
   }

   //console.log('--------------AssistanceServiceFoundationDefaultsEditor-------------------------')
   //console.log(errors)


   const html = (
      <Dialog
         scrollingContent={true}
         title={'Assistance Service Foundation Default Editor'}
         open={isOpen}
         size="large"
         actionButtons={
            <>
               <CancelButton 
                  onClick={() => handleClose()}
                  loading={isFormSaving}
               />
               {!readonly && <DeleteButton
                  onClick={() => setIsDeleteDialogOpen(true)}
                  disabled={!itemToEdit || !itemToEdit?.id || isDeleteDialogOpen}
                  loading={isFormSaving} />
               }
               {!readonly && <SaveButton
                  formId={__formId}
                  onClick={() => null}
                  disabled={Object.keys(errors).length > 0}
                  loading={isFormSaving} />
               }
            </>
         }>
         <div style={dialogContentStyle}>

            {errors && Object.keys(errors).length > 0 &&
               <>
                  <TitleError text='Please correct the errors below' />
                  {console.log('--------------Form Errors: -------------------------')}
                  {console.log(errors)}
               </>
            }

            <ApiErrorDisplay
               title='Error saving AssistanceServiceFoundationDefaults'
               keysLike={_keysLike}
            />

            <Form form={form}
               id={__formId}
               onFinish={handleSubmit(onSubmit)}>
               <DropdownField
                  control={control}
                  name='foundationDiseaseTypeId'
                  label='Foundation Disease Type'
                  error={errors?.foundationDiseaseTypeId}
                  multiple={false}
                  options={foundationDiseaseTypesWithFoundationOptions}
                  setValue={setValue}
                  required={true}
                  search={true}
                  clearable={true}
                  disabled={readonly ? true : false || shouldFoundationDiseaseTypeBeDisabled}
               />
               <DropdownField
                  control={control}
                  name='assistanceServiceId'
                  label='Assistance Service'
                  error={errors?.assistanceServiceId}
                  multiple={false}
                  options={assistanceProgramAndServiceList}
                  setValue={setValue}
                  required={true}
                  search={true}
                  clearable={true}
                  disabled={readonly ? true : false || shouldAssistanceServiceBeDisabled}
               />
               <Divider>Copay Amount</Divider>
               <InputField
                  control={control}
                  name='copayAmount'
                  label='copayAmount'
                  type='number'
                  error={errors?.copayAmount}
                  required={false}
                  disabled={readonly ? true : false}
               />
               <DropdownField
                  control={control}
                  name='copayAmountUnits'
                  label='copayAmountUnits'
                  error={errors?.copayAmountUnits}
                  multiple={false}
                  options={unitOfMeasureOptions}
                  setValue={setValue}
                  required={false}
                  search={true}
                  clearable={true}
                  disabled={readonly ? true : false}
               />
               <InputField
                  control={control}
                  name='copayAmountNotes'
                  label='copayAmountNotes'
                  type='text'
                  error={errors?.copayAmountNotes}
                  required={false}
                  disabled={readonly ? true : false}
               />
               <InputField
                  control={control}
                  name='copayAmountUrl'
                  label='copayAmountUrl'
                  type='text'
                  error={errors?.copayAmountUrl}
                  required={false}
                  disabled={readonly ? true : false}
               />
               <Divider>Approved Award Amount</Divider>
               <InputField
                  control={control}
                  name='approvedAwardAmount'
                  label='approvedAwardAmount'
                  type='number'
                  error={errors?.approvedAwardAmount}
                  required={false}
                  disabled={readonly ? true : false}
               />
               <DropdownField
                  control={control}
                  name='approvedAwardAmountUnits'
                  label='approvedAwardAmountUnits'
                  error={errors?.approvedAwardAmountUnits}
                  multiple={false}
                  options={unitOfMeasureOptions}
                  setValue={setValue}
                  required={false}
                  search={true}
                  clearable={true}
                  disabled={readonly ? true : false}
               />
               <InputField
                  control={control}
                  name='approvedAwardAmountNotes'
                  label='approvedAwardAmountNotes'
                  type='text'
                  error={errors?.approvedAwardAmountNotes}
                  required={false}
                  disabled={readonly ? true : false}
               />
               <InputField
                  control={control}
                  name='approvedAwardAmountUrl'
                  label='approvedAwardAmountUrl'
                  type='text'
                  error={errors?.approvedAwardAmountUrl}
                  required={false}
                  disabled={readonly ? true : false}
               />
               <Row gutter={[32, 32]} justify="space-between" align="top" >
                  <Col span={8}>
                     <DropdownField
                        control={control}
                        name='benefitPeriodTypeId'
                        label='Benefit Period Type'
                        error={errors?.benefitPeriodTypeId}
                        multiple={false}
                        options={benefitPeriodTypeOptions}
                        setValue={setValue}
                        required={true}
                        search={true}
                        clearable={true}
                        disabled={readonly ? true : false}
                        onChange={(value) => trigger('benefitPeriodTypeId')}
                     />
                  </Col>
                  <Col span={8}>
                     <DropdownField
                        control={control}
                        name='benefitPeriodStartMonth'
                        label='Benefit Period Start Month'
                        error={errors?.benefitPeriodStartMonth}
                        multiple={false}
                        options={monthOptions}
                        setValue={setValue}
                        required={watchBenefitType === CALENDAR_BENEFIT_TYPE_ID}
                        search={true}
                        clearable={true}
                        disabled={readonly || watchBenefitType == ENROLLMENT_BENEFIT_TYPE_ID ? true : false}
                        onChange={(value) => { trigger('benefitPeriodStartDay'); trigger('benefitPeriodStartMonth') }}
                     />
                  </Col>
                  <Col span={8}>
                     <DropdownField
                        control={control}
                        name='benefitPeriodStartDay'
                        label='Benefit Period Start Day'
                        error={errors?.benefitPeriodStartDay}
                        multiple={false}
                        options={dayOptions()}
                        setValue={setValue}
                        required={watchBenefitType === CALENDAR_BENEFIT_TYPE_ID}
                        search={true}
                        clearable={true}
                        disabled={readonly || watchBenefitType == ENROLLMENT_BENEFIT_TYPE_ID ? true : false}
                        onChange={(value) => { trigger('benefitPeriodStartDay'); trigger('benefitPeriodStartMonth') }}
                     />
                  </Col>
               </Row>
               <Divider>Guaranteed Amount</Divider>
               <InputField
                  control={control}
                  name='guaranteedAmount'
                  label='guaranteedAmount'
                  type='number'
                  error={errors?.guaranteedAmount}
                  required={false}
                  disabled={readonly ? true : false}
               />
               <DropdownField
                  control={control}
                  name='guaranteedAmountUnits'
                  label='guaranteedAmountUnits'
                  error={errors?.guaranteedAmountUnits}
                  multiple={false}
                  options={unitOfMeasureOptions}
                  setValue={setValue}
                  required={false}
                  search={true}
                  clearable={true}
                  disabled={readonly ? true : false}
               />
               <Divider>Back Date Days</Divider>
               <InputField
                  control={control}
                  name='backDateDays'
                  label='backDateDays'
                  type='number'
                  error={errors?.backDateDays}
                  required={false}
                  disabled={readonly ? true : false}
               />
               <DropdownField
                  control={control}
                  name='backDateDaysUnits'
                  label='backDateDaysUnits'
                  error={errors?.backDateDaysUnits}
                  multiple={false}
                  options={unitOfMeasureOptions}
                  setValue={setValue}
                  required={false}
                  search={true}
                  clearable={true}
                  disabled={readonly ? true : false}
               />
               <InputField
                  control={control}
                  name='backDateDaysNotes'
                  label='backDateDaysNotes'
                  type='text'
                  error={errors?.backDateDaysNotes}
                  required={false}
                  disabled={readonly ? true : false}
               />
               <InputField
                  control={control}
                  name='backDateDaysUrl'
                  label='backDateDaysUrl'
                  type='text'
                  error={errors?.backDateDaysUrl}
                  required={false}
                  disabled={readonly ? true : false}
               />
               <Divider>Timely Filing Days</Divider>
               <InputField
                  control={control}
                  name='timelyFilingDays'
                  label='timelyFilingDays'
                  type='number'
                  error={errors?.timelyFilingDays}
                  required={false}
                  disabled={readonly ? true : false}
               />
               <DropdownField
                  control={control}
                  name='timelyFilingDaysUnits'
                  label='timelyFilingDaysUnits'
                  error={errors?.timelyFilingDaysUnits}
                  multiple={false}
                  options={unitOfMeasureOptions}
                  setValue={setValue}
                  required={false}
                  search={true}
                  clearable={true}
                  disabled={readonly ? true : false}
               />
               <InputField
                  control={control}
                  name='timelyFilingDaysNotes'
                  label='timelyFilingDaysNotes'
                  type='text'
                  error={errors?.timelyFilingDaysNotes}
                  required={false}
                  disabled={readonly ? true : false}
               />
               <InputField
                  control={control}
                  name='timelyFilingDaysUrl'
                  label='timelyFilingDaysUrl'
                  type='text'
                  error={errors?.timelyFilingDaysUrl}
                  required={false}
                  disabled={readonly ? true : false}
               />
               <Divider>Legacy Fields</Divider>
               <InputField
                  control={control}
                  name='url'
                  label='url'
                  type='string'
                  error={errors?.url}
                  required={false}
                  disabled={readonly ? true : false}
               />
            </Form>
         </div>

         <Dialog
            title='CONFIRM DELETE'
            open={isDeleteDialogOpen}
            style={{ maxWidth: '550px' }}
            actionButtons={
               <>
                  <CancelButton onClick={() => setIsDeleteDialogOpen(false)} />
                  <DeleteButton
                     disabled={isFormSaving}
                     onClick={() => handleDeleteClick()} />
               </>
            }
         >
            {`Are you sure you want to Delete the AssistanceServiceFoundationDefaults with id: ${itemToEdit?.id}?`}
         </Dialog>

      </Dialog>
   )
   return html;
}

export default AssistanceServiceFoundationDefaultsEditor;

