import * as React from 'react';
import { useUserContext } from './authContext';
import VersionDisplay from '../components/VersionDisplay';
import AdminPortal_Logo_White from '../images/AdminPortal_Logo_White.png';
import Spinner from '../components/Spinner';
import { ReactComponent as SignInSvg } from "../images/icons/right-to-bracket-solid.svg";
import Icon from "@ant-design/icons";
import colorWheel from '../Theme/ColorWheel';
import { Button, Alert } from 'antd';
import { useFetchSetting } from '../store/SettingsFetcher';
import { KnownSettings } from '../store/SettingsModel';
import { LogoutOptions, useAuth0, User } from "@auth0/auth0-react";

interface Props {
   children: React.ReactNode;
}

const AuthLayoutWrapper: React.FC<Props> = (props) => {

   const emailString: string = useFetchSetting(KnownSettings.SupportEmail);
   const phoneString: string = useFetchSetting(KnownSettings.SupportPhone);

   return (
      <div className="auth-layout">
         <img alt="AssistPoint Logo" title="AssistPoint" src={AdminPortal_Logo_White} className="logo" />
         <span className="italic"><VersionDisplay /></span>
         <h2>Annexus Health Admin Portal</h2>
         <div className="divider"></div>
         <p>Accelerating access to care through digital innovation</p>
         <div className="authContent">
            {props.children}
         </div>
         <h3>For Questions</h3>
         <a href={`tel:${phoneString}`} className="contact">{phoneString}</a>
         <a className="contact" href={`mailto:${emailString}`}>{emailString}</a>
         <div className="footer">
            &#169; {(new Date().getFullYear())} Annexus Health
         </div>
      </div>
   );
}

interface ISignInProps {
   redirectPath: string
}

export const SignIn = (props: ISignInProps) => {
   const { redirectPath } = props;
   const { signIn } = useUserContext();
   const [hoverStyle, setHoverStyle] = React.useState<string>(colorWheel.tangerineOrange);

   return (
      <AuthLayoutWrapper>
         <Button
            style={{
               display: 'flex',
               flexDirection: 'row',
               alignItems: 'center',
               paddingTop: 1,
               paddingBottom: 1,
               paddingLeft: 0,
               paddingRight: 1,
               border: 0,
               backgroundColor: hoverStyle,
               fontSize: 12,
               fontWeight: 'normal'
            }}
            size='large'
            onMouseEnter={() => setHoverStyle(colorWheel.orange3)}
            onMouseLeave={() => setHoverStyle(colorWheel.tangerineOrange)}
            onClick={() => signIn(redirectPath)}
            title='Sign In'
            icon={<Icon
               style={{
                  color: colorWheel.white,
                  paddingLeft: 15,
                  paddingRight: 15,
                  paddingTop: 2,
                  fontSize: 20
               }}
               component={SignInSvg} />}
         >
            <div style={{
               borderTopRightRadius: 3,
               borderBottomRightRadius: 3,
               paddingLeft: 10,
               paddingRight: 10,
               paddingTop: 9,
               paddingBottom: 10,
               backgroundColor: colorWheel.white,
               color: colorWheel.tangerineOrange,
               fontWeight: 'bold'
            }}>Sign In</div>
         </Button>
      </AuthLayoutWrapper>
   );
}

export const PendingAuthentication = () => {
   return (
      <AuthLayoutWrapper>
         <Spinner style={{ width: '200px' }} message="Authenticating user..." />
      </AuthLayoutWrapper>
   );
}

export const AuthError = () => {
   const { logout, user } = useAuth0();

   return (
      <AuthLayoutWrapper>
         <div style={{ paddingBottom: 10 }}>
            <Alert
               message='Authentication Failed'
               description='Refresh or close and re-open browser to try again.  For additional assistance, connect with us using the information below'
               type="error"
            />
         </div>
         {user &&
            <div style={{ display: 'flex', justifyContent: 'center' }}>
               <Button
                  style={{
                     display: 'flex',
                     flexDirection: 'row',
                     alignItems: 'center',
                     paddingTop: 1,
                     paddingBottom: 1,
                     paddingLeft: 0,
                     paddingRight: 1,
                     border: 0,
                  }}
                  size='large'
                  onClick={async () => {
                     await logout();
                     window.location.href = '/';
                  }}
                  title='Continue'
                  icon={<Icon
                     style={{
                        color: colorWheel.white,
                        paddingLeft: 15,
                        paddingRight: 15,
                        paddingTop: 2,
                        fontSize: 20
                     }}
                     component={SignInSvg} />}
               >
                  <div style={{
                     borderTopRightRadius: 3,
                     borderBottomRightRadius: 3,
                     paddingLeft: 10,
                     paddingRight: 10,
                     paddingTop: 9,
                     paddingBottom: 10,
                     backgroundColor: colorWheel.white,
                     color: colorWheel.tangerineOrange,
                     fontWeight: 'bold'
                  }}>Continue</div>
               </Button>
            </div>
         }
      </AuthLayoutWrapper>
   );
}

export const UnauthorizedUser = () => {
   const { signOut, signIn, loginError } = useUserContext();

   return (
      <AuthLayoutWrapper>
         <div style={{ paddingBottom: 10 }}>
            <Alert
               message='Account Not Assigned Access to Admin Portal.'
               description='Click continue to try again using a verified "xxxx@annexushealth.com" or "xxxx@apusers.zone" account.  For additional assistance, connect with us using the information below.'
               type="warning"
            />
         </div>
         <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
               style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  paddingTop: 1,
                  paddingBottom: 1,
                  paddingLeft: 0,
                  paddingRight: 1,
                  border: 0,
               }}
               size='large'
               onClick={async () => {
                  await signOut();
                  /* signIn();*/
               }}
               title='Continue'
               icon={<Icon
                  style={{
                     color: colorWheel.white,
                     paddingLeft: 15,
                     paddingRight: 15,
                     paddingTop: 2,
                     fontSize: 20
                  }}
                  component={SignInSvg} />}
            >
               <div style={{
                  borderTopRightRadius: 3,
                  borderBottomRightRadius: 3,
                  paddingLeft: 10,
                  paddingRight: 10,
                  paddingTop: 9,
                  paddingBottom: 10,
                  backgroundColor: colorWheel.white,
                  color: colorWheel.tangerineOrange,
                  fontWeight: 'bold'
               }}>Continue</div>
            </Button>
         </div>
      </AuthLayoutWrapper>
   )
}