import { Form } from 'antd';
import * as React from 'react';
import {
   Controller, Control, FieldValue, FieldValues, FieldError, UseFormSetValue
   , DeepMap, DeepPartial
} from 'react-hook-form';
import { ErrorDisplay } from '../ErrorDisplay';
import { IBasicMultiSelectProps, BasicMultiSelect } from './BasicMultiSelectInput';

export interface IFormMultiSelectProps extends IBasicMultiSelectProps {
   error?: DeepMap<DeepPartial<FieldValue<FieldValues>>, FieldError[] | FieldError | undefined>;
   control: Control<FieldValue<FieldValues>>;
   name: string;
   onChange?: (value: string | number | boolean | (string | number | boolean)[]) => void;
   onBlur?: () => void;
   tooltip?: React.ReactNode;
   required?: boolean;
   trigger: any;
}

export const FormMultiSelectInput: React.FC<IFormMultiSelectProps> = (props) => {
   const { control, name, containerStyle, error, required, label, tooltip, onChange: parentOnChange, trigger, ...rest } = props;

   const labelStyles = (containerStyle: React.CSSProperties) => ({ fontWeight: 600, opacity: 0.8, ...containerStyle } as React.CSSProperties);
   
   const html = (
      <Controller
         control={control as unknown as Control<FieldValue<FieldValues>>}
         name={name}
         render={({ field: { onChange, onBlur, value } }) =>
            <Form.Item
               style={labelStyles(containerStyle)}
               validateStatus={error ? 'error' : 'success'}
               required={required}
               label={label}
               colon={false}
               labelCol={{ span: 24 }}
               tooltip={tooltip}
            >
               <BasicMultiSelect
                  onChange={(e) => {
                     onChange && onChange(e);
                     parentOnChange && parentOnChange(e)
                     trigger(name);
                  }}
                  // specifically setting undefined since we render the label here with Form.Item so we don't want the basic control to display it
                  label={undefined}
                  value={value}
                  {...rest}
               />
               <ErrorDisplay error={error} />
            </Form.Item>
         } />
   );

   return html;
}