import * as React from 'react';
import ContentCard from '../../layouts/ContentCard';
import FundStatusChangeList from './FundStatusChangeList';
import RecentlySentList from './recentlySentWidget/RecentlySentList';
import {FundStatusContextProvider, useFundStatusContext } from './FundStatusContext';
import { ActionButton, buttonStyles } from '../shared/Buttons';
import CustomIcon, { CustomIconType } from '../shared/AntComponents/CustomIcon';
import NewFundingStatusAlertDialog from './NewFundingStatusAlertDialog';

const FundStatusAlertIndex: React.FC = () => {

   return (<FundStatusContextProvider>
      <FundStatusAlertMain />
   </FundStatusContextProvider>
    );

}
export default FundStatusAlertIndex;

const FundStatusAlertMain: React.FC = () => {
   const { fieldChangeCount} = useFundStatusContext();
   const [isNewFundingStatusAlertDialogOpen, setIsNewFundingStatusAlertDialogOpen] = React.useState<boolean>(false);

   return (<>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
         <ContentCard
            title={`Funding Status Changes ${fieldChangeCount > 0 ? '(' + fieldChangeCount + ')' : ''}`}
            style={{ width: '50%', minHeight: '50vh' }}
            subTitle={
               <ActionButton
                  buttonText='Create Custom FSA'
                  filled={true}
                  icon={<CustomIcon type={CustomIconType.SendOutlined} />}
                  onClick={() => setIsNewFundingStatusAlertDialogOpen(true)}
                  disabled={false} />
            }
         >
            <FundStatusChangeList />
         </ContentCard>
         <ContentCard title='Recently Sent' style={{ width: '50%', minHeight: '50vh' }}>
            <RecentlySentList />
         </ContentCard>
      </div>

      <NewFundingStatusAlertDialog isOpen={isNewFundingStatusAlertDialogOpen} closeEditor={() => setIsNewFundingStatusAlertDialogOpen(false)} />
   </>
   );

}
