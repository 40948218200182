import { AssistanceProgramLookup } from '../program/LookupModels';
import { numberComparer, stringComparer } from '../../functions/comparer.functions';
import { useMemo } from 'react';
import { HttpError, TPost, TDelete, TGet } from '../../functions/httpClient';
import { IOptionItem } from '../../functions/option.functions';
import { IStatus } from '../models';
import useSWR, { mutate } from 'swr';
import { AssistanceProgram } from './AssistanceProgramModel';

export const assistanceProgramBaseUrl = `api/program/assistanceProgram`;
export const assistanceProgramWithFoundationsUrl = (foundationId: number) => `${assistanceProgramBaseUrl}/GetFoundationAssistanceProgramLookups/${foundationId}`;
const assistanceProgramSaveUrl = `${assistanceProgramBaseUrl}/Save`;
const assistanceProgramDeleteUrl = (id: number) => `${assistanceProgramBaseUrl}/Delete/${id}`;
const assistanceProgramLookupSaveUrl = `${assistanceProgramBaseUrl}/SaveLookupWithFoundation`

const swrOptions = {
   refreshInterval: 0,
   revalidateOnFocus: false,
   dedupingInterval: 60000
};

export function useFetchAssistancePrograms(httpGet: TGet): IStatus & { assistancePrograms: AssistanceProgram[] } {
   const { data, isLoading, error } = useSWR<AssistanceProgram[], HttpError>(
      assistanceProgramBaseUrl,
      httpGet,
      { ...swrOptions }
   );

   return {
      assistancePrograms: data,
      isLoading: isLoading,
      error: error?.message
   };
}

export function useFetchAssistanceProgramsByFoundation(httpGet: TGet, foundationId: number): IStatus & { assistanceProgramLookups: AssistanceProgramLookup[] } {
   const { data, isLoading, error } = useSWR<AssistanceProgramLookup[], HttpError>(
      assistanceProgramWithFoundationsUrl(foundationId),
      httpGet,
      { ...swrOptions }
   );

   return {
      assistanceProgramLookups: data,
      isLoading: isLoading,
      error: error?.message
   };
}

export const saveAssistanceProgram = async (httpPost: TPost, assistanceProgram: AssistanceProgram) => {
   const result = await httpPost(assistanceProgramSaveUrl, assistanceProgram)
      .then(() => mutate(assistanceProgramBaseUrl));
   return result;
}

export const deleteAssistanceProgram = async (httpDelete: TDelete, id: number) => {
   const result = await httpDelete(assistanceProgramDeleteUrl(id))
      .then(() => mutate(assistanceProgramBaseUrl));
   return result;
}

export const updateAssistanceProgramLookup = async (httpPost: TPost, assistanceProgramLookup: AssistanceProgramLookup, foundationId: number) => {
   const result = await httpPost(assistanceProgramLookupSaveUrl, assistanceProgramLookup)
      .then(() => mutate(assistanceProgramWithFoundationsUrl(foundationId)))
   return result;
}

export const buildAssistanceProgramOptions = (assistancePrograms: AssistanceProgram[]): IOptionItem[] => {
   if (assistancePrograms?.length > 0) {
      const sorted = [...assistancePrograms];

      //sort by ProgramName, then Id
      sorted.sort((a: AssistanceProgram, b: AssistanceProgram) => numberComparer(Number(a.id), Number(b.id)));
      sorted.sort((a: AssistanceProgram, b: AssistanceProgram) => stringComparer(String(a.programName), String(b.programName)));

      const assistanceProgramNameOptions = sorted.map((program): IOptionItem => ({
         key: program.id,
         label: `[${program.id}] ${program.programName}`,
         value: program.id
      } as IOptionItem));

      return assistanceProgramNameOptions;
   }
   return [];
}

export function useAssistanceProgramNameOptions(httpGet: TGet): { assistanceProgramNameOptions: IOptionItem[] } {
   const { assistancePrograms } = useFetchAssistancePrograms(httpGet);

   const assistanceProgramNameOptions = useMemo(() => {
      if (assistancePrograms?.length > 0) {

         const assistanceProgramNameOptions = buildAssistanceProgramOptions(assistancePrograms);

         //Attempt to convert the select options to align all names to the right of id.
         //Current display will remove mutiple white space characters - even if it didn't,
         // the font characters are not all the same width.  Will need a templated option label
         //
         //Gonna need to take a step back and rethink this one
         //
         //const maxId = `${Math.max(...sorted.map(o => o.id))}`.length;
         //const assistanceProgramNameOptions = sorted.map((program): IOptionItem => {
         //   const programName = (program.programName ?? '');
         //   const max = programName.length + maxId - `${program.id}`.length - 1;
         //   return {
         //      label: `[${program.id}] ${programName.padStart(max, '')}`,
         //      value: program.id
         //   } as IOptionItem;
         // });
         return assistanceProgramNameOptions;
      }
      return [];
   }, [assistancePrograms]);

   return { assistanceProgramNameOptions };
}

export const getAssistanceProgramById = (id: number, assistancePrograms: AssistanceProgram[]): AssistanceProgram => {
// eslint-disable-next-line no-restricted-globals
   return assistancePrograms?.find((f: AssistanceProgram) => f.id === id) ?? undefined
};