import { IAcknowledgeableNotification } from "./NotificationModel";

export interface NotificationState {
   notifications: IAcknowledgeableNotification[];
}

export const MERGE_NOTIFICATION = 'MERGE_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';

export function NotificationReducer(state: NotificationState, action: any) {
   switch (action.type) {
      case MERGE_NOTIFICATION: {
         return {
            notifications: [
               ...state.notifications?.filter(y => y.key !== action.payload.key),
               action.payload
            ]
         };
      }
      case REMOVE_NOTIFICATION:
         return {
            notifications: [
               ...state.notifications?.filter(y => y.key !== action.payload.key)
            ]
         };

      default:
         console.warn('NotificationReducer was called with an unexpected type', action.type);
         return state;
   }
}

export default NotificationReducer;