import * as React from 'react';
import { AppliedFilterDisplay } from '../Filter/AppliedFilterDisplay';
import { IAntTableProps } from './IAntTableProps';
import { IFilteredInfo } from '../Filter/FilteredInfo';
import SearchResultsTable from './SearchResultsTable';

interface IFilterTableProps extends IAntTableProps {
   filteredInfo?: Record<string, IFilteredInfo | null>;
   setFilteredInfo?: (filter: Record<string, IFilteredInfo | null>) => void;
   onFiltersClear?: { (): void };
}

/*
 * Use this table to skip built-in antd filtering.
 * */
export const SearchResultsTableWithFilters: React.FC<IFilterTableProps> = (props) => {

   const {
      scrollHeight, scrollY,
      filteredInfo, setFilteredInfo, onFiltersClear
   } = props;

   const _scrollHeight = !scrollY ? (scrollHeight ?? 250) : undefined;
   return <SearchResultsTable
      {...props}
      scrollHeight={_scrollHeight}
      appliedFilterDisplay={<AppliedFilterDisplay
         filteredInfo={filteredInfo}
         setFilteredInfo={setFilteredInfo}
         onFiltersClear={onFiltersClear}
      />}
   />
}
