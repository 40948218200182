import useSWR from 'swr';
import { useMemo } from 'react';
import { FormOptionItem, OptionGroupId_DefaultsUnitOfMeasure } from './FormOptionItemModel';
import { HttpError, TGet } from '../../functions/httpClient';
import { IStatus } from '../models';
import { IOptionItem } from '../../functions/option.functions';
import { stringComparer } from '../../functions/comparer.functions';

const formOptionItemBaseUrl = `api/form/formOptionItem`;
const getUnitOfMeasureUrl = `${formOptionItemBaseUrl}/${OptionGroupId_DefaultsUnitOfMeasure}`;

const swrOptions = {
   refreshInterval: 0,
   revalidateOnFocus: false,
   dedupingInterval: 60000
};

export function useFetchUnitOfMeasure(httpGet: TGet): IStatus & { unitOfMeasureOptions: FormOptionItem[] } {
   const { data, isLoading, error } = useSWR<FormOptionItem[], HttpError>(
      getUnitOfMeasureUrl,
      httpGet,
      { ...swrOptions }
   );

   return {
      unitOfMeasureOptions: data,
      isLoading: isLoading,
      error: error?.message
   };
}

export function useUnitOfMeasureOptions(httpGet: TGet): { unitOfMeasureOptions: IOptionItem[] } {
   const { unitOfMeasureOptions: source } = useFetchUnitOfMeasure(httpGet);

   const unitOfMeasureOptions = useMemo(() => {
      if (source?.length > 0) {
         const sorted = [...source];
         sorted.sort((a: FormOptionItem, b: FormOptionItem) => stringComparer(String(a.name), String(b.name)));

         const options = sorted.map((item): IOptionItem => ({
            label: `${item.name}`,
            value: item.value
         } as IOptionItem));

         return options;
      }
      return [];
   }, [source]);

   return { unitOfMeasureOptions };
}