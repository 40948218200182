import Table, { ColumnsType } from 'antd/lib/table';
import * as React from 'react';
import Restricted from '../../../auth/Restricted';
import { BrandViewModel } from '../../../store/drug/BrandModel';
import { BrandResource } from '../../../store/drug/BrandResourceModel';
import { KnownSettings } from '../../../store/SettingsModel';
import HighlightSearchText from '../../HighlightSearchText';
import { AddButton, EditButton } from '../../shared/Buttons';
import BrandResourceEditor from './BrandResourceEditor';

interface IExpandedResourceProps {
   brand: BrandViewModel;
   closeEditor: (brandId?: number, brandResourceId?: number) => void;
   urlFilter: string;
}

const BrandResourceRow: React.FC<IExpandedResourceProps> = (props) => {

   const [isEditorOpen, setIsEditorOpen] = React.useState<boolean>(false);
   const [selectedItem, setSelectedItem] = React.useState<BrandResource>(null);

   const handleCloseClick = () => {
      setSelectedItem(undefined);
      setIsEditorOpen(false);
   }

   const columns: ColumnsType<BrandResource> = [
      {
         title: 'Id',
         dataIndex: 'id',
         key: 0,
      },
      {
         title: 'ResourceType',
         dataIndex: 'resourceType',
         key: 1,
      },
      {
         title: 'Description',
         dataIndex: 'description',
         key: 2,
      },
      {
         title: 'Resource URL',
         dataIndex: 'resourceUrl',
         key: 3,
         render: (text, record) =>
            <HighlightSearchText searchString={props.urlFilter} targetString={record.resourceUrl} />
      },
      {
         title: '',
         dataIndex: 'actionItems',
         key: 4,
         render: (text, record) => <Restricted requiredRoles={[KnownSettings.ContentAdmin]}>
            <EditButton
               buttonText='Edit Resource'
               onClick={() => {
                  setSelectedItem(record);
                  setIsEditorOpen(true);
               }} />
         </Restricted>
      }
   ];

   return <>
      <Table dataSource={props.brand?.resources ?? []}
         columns={columns}
         pagination={false}
         rowKey={'id'}
         bordered
         title={() => <>
            <span style={{ display: 'flex', justifyContent: 'space-between' }}>
               <b>Resources</b>
               <span>
                  <Restricted requiredRoles={[KnownSettings.ContentAdmin]}>
                     <AddButton buttonText='New Resource' onClick={() => {
                        setSelectedItem(undefined);
                        setIsEditorOpen(true);
                     }} />
                  </Restricted>
               </span>
            </span>
         </>} />

      {isEditorOpen && <BrandResourceEditor
         isOpen={isEditorOpen}
         closeEditor={handleCloseClick}
         brand={props.brand}
         brandResource={selectedItem}
      />
      }
   </>
}

export default BrandResourceRow;
