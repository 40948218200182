import { CohortItem } from '../store/cohort/CohortItemModel';
import { IValidationResult, FieldTypes } from './form.contracts';
import { yesNo } from './option.functions';

export const validateField = (
   val: any,
   cohortItem: CohortItem): IValidationResult => {
   let validationResult: IValidationResult = { isValid: true };
   let iscohortItemValid = true;
   let message = "undefined error";
   let stringvalue = String(val || '');
   try {
      switch (cohortItem.fieldType) {
         case FieldTypes.string:
         case FieldTypes.textarea:
            if (cohortItem.isRequired && isNullOrEmpty(val)) {
               message = `"${cohortItem.labelText}" is Required`;
               iscohortItemValid = false;
            }
            else if (!validateStringLengthEqual(val, cohortItem.isRequired, cohortItem.minLength, cohortItem.maxLength)) {
               message = `"${cohortItem.labelText}" must be ${cohortItem.minLength} characters`;
               iscohortItemValid = false;
            }
            else if (!validateStringMin(val, cohortItem.isRequired, cohortItem.minLength)) {
               message = `"${cohortItem.labelText}" must be at least ${cohortItem.minLength} characters`;
               iscohortItemValid = false;
            }
            else if (!validateStringMax(val, cohortItem.isRequired, cohortItem.maxLength)) {
               message = `"${cohortItem.labelText}" must be ${cohortItem.maxLength} characters or less`;
               iscohortItemValid = false;
            }
            break;
         case FieldTypes.url:
            if (cohortItem.isRequired && isNullOrEmpty(stringvalue)) {
               message = `"${cohortItem.labelText}" is Required`;
               iscohortItemValid = false;
            }
            else if (!validateUrl(stringvalue, cohortItem.isRequired, cohortItem.minLength, cohortItem.maxLength)) {
               message = `"${cohortItem.labelText}" must be a valid url that begins with "http://" or "https://"`;
               iscohortItemValid = false;
            }
            break;
         case FieldTypes.integer:
            const cleanStringInt = cleanNumber(stringvalue);
            if (cohortItem.isRequired && isNullOrEmpty(stringvalue)) {
               message = `"${cohortItem.labelText}" is Required`;
               iscohortItemValid = false;
            }
            else if (!validateStringLengthEqual(cleanStringInt, cohortItem.isRequired, cohortItem.minLength, cohortItem.maxLength)) {
               //Note use of the clean number - this is done here to throw up the preferred error message before first checking to see if we have a validInteger
               message = `"${cohortItem.labelText}" must be a valid ${cohortItem.minLength} digit number`;
               iscohortItemValid = false;
            }
            else if (!validateInteger(stringvalue, cohortItem.isRequired)) {
               message = `"${cohortItem.labelText}" must be a valid number with ${cohortItem.maxLength} digits or less`;
               iscohortItemValid = false;
            }
            else if (!validateStringMin(cleanStringInt, cohortItem.isRequired, cohortItem.minLength)) {
               message = `"${cohortItem.labelText}" must be a number with at least ${cohortItem.minLength} digits`;
               iscohortItemValid = false;
            }
            else if (!validateStringMax(cleanStringInt, cohortItem.isRequired, cohortItem.maxLength)) {
               message = `"${cohortItem.labelText}" must be a number with ${cohortItem.maxLength} digits or less`;
               iscohortItemValid = false;
            }
            break;
         case FieldTypes.currency:
         case FieldTypes.currencydollars:
            if (cohortItem.isRequired && isNullOrEmpty(stringvalue)) {
               message = `"${cohortItem.labelText}" is Required`;
               iscohortItemValid = false;
            }
            else if (!validateCurrency(stringvalue, cohortItem.isRequired, cohortItem.minLength, cohortItem.maxLength)) {
               message = `"${cohortItem.labelText}" must be a valid US currency in the formats: "$##,###.##" or "#####.##"`;
               iscohortItemValid = false;
            }
            break;
         //case FieldTypes.currencydollars:
         //   if (cohortItem.isRequired && isNullOrEmpty(stringvalue)) {
         //      message = `"${cohortItem.labelText}" is Required`;
         //      iscohortItemValid = false;
         //   }
         //   else if (!validateCurrencyDollars(stringvalue, cohortItem.isRequired, cohortItem.minLength, cohortItem.maxLength)) {
         //      message = `"${cohortItem.labelText}" must be a valid US currency whole number in the formats: "$##,###" or "#####"`;
         //      iscohortItemValid = false;
         //   }
         //   break;
         case FieldTypes.date:
            if (cohortItem.isRequired && isNullOrEmpty(stringvalue)) {
               message = `"${cohortItem.labelText}" is Required`;
               iscohortItemValid = false;
            }
            else if (!isNullOrEmpty(stringvalue) && !validateDate(stringvalue)) {
               message = `"${cohortItem.labelText}" must be a valid date in the format: MM/DD/YYYY`;
               iscohortItemValid = false;
            }
            break;

         case FieldTypes.checkboxlist:
            if (cohortItem.isRequired) {
               if (!Array.isArray(val) || val.length === 0) {
                  message = `At least one ${cohortItem.labelText} is required`;
                  iscohortItemValid = false;
               }
            }
            if (val.length > 0) {
               let found = false;
               //make sure at least one value in the answer is also present in the options
               for (let i = 0; i < val.length; i++) {
                  if (cohortItem.fieldOptions.some(e => e.value === val[i])) {
                     found = true;
                     break;
                  }
               }
               if (!found) {
                  //note the slightly different error messaage than the above 
                  // - helpful in diagnosing issues where the selected answers do not match the field options
                  message = `At least one ${cohortItem.labelText} is required`;
                  iscohortItemValid = false;
               }
            }
            break;        
         case FieldTypes.yesno:
            if (cohortItem.isRequired && isNullOrEmpty(val)) {
               message = `"${cohortItem.labelText}" is Required`;
               iscohortItemValid = false;
            }
            if (!isNullOrEmpty(stringvalue)) {
               let found = false;
               if (yesNo.some(e => e.value === stringvalue)) {
                  found = true;
                  break;
               }
               if (!found) {
                  iscohortItemValid = false;
                  console.warn(`[${cohortItem.cohortItemId}] ${cohortItem.labelText} contains value "${stringvalue}", which is not a valid yesnoOption`);
               }
            }
            break;
         case FieldTypes.yesinput:
            if (cohortItem.isRequired && isNullOrEmpty(val)) {
               message = `"${cohortItem.labelText}" is Required`;
               iscohortItemValid = false;
               console.warn(`[${cohortItem.cohortItemId}] ${cohortItem.labelText} is required for yes|Input type`);
            }
            
            break;
         case FieldTypes.dropdownlist:
         case FieldTypes.radiogroup:
            // case FieldTypes.gender:
            // case FieldTypes.besttimetocall:
            if (cohortItem.isRequired && isNullOrEmpty(stringvalue)) {
               message = `"${cohortItem.labelText}" is Required`;
               iscohortItemValid = false;
            }
            if (!isNullOrEmpty(stringvalue)) {
               let found = false;
               if (cohortItem.fieldOptions.some(e => e.value === stringvalue)) {
                  found = true;
                  break;
               }
               if (!found) {
                  iscohortItemValid = false;
                  console.warn(`[${cohortItem.cohortItemId}] ${cohortItem.labelText} contains value "${stringvalue}", which is not a valid FieldOption`);
               }
            }
            break;
         default:
            if (cohortItem.isRequired && isNullOrEmpty(val)) {
               message = `"${cohortItem.labelText}" is Required`;
               iscohortItemValid = false;
            }
            if (!iscohortItemValid)
               console.warn(`Unknown fieldType: "${cohortItem.fieldType}"  treating as string - isValid=${iscohortItemValid}`);
      }

   } catch (e) {
      console.warn(e)
      console.warn(`validateField failed~> [${cohortItem.cohortItemId}] ${cohortItem.labelText} with value of '${val}': isvalid:${iscohortItemValid}: message:${message}`);
   }


   validationResult.isValid = iscohortItemValid;

   if (!iscohortItemValid) {
      validationResult.formError = { DataName: cohortItem.cohortFieldId, Message: message }
   }

   return validationResult;
}

export const isNullOrEmpty = (value: string): boolean => value === undefined || value === null || String(value).trim() === '';

export const validateStringLengthEqual = (val: string, isRequired: boolean, minLength: number, maxLength: number): boolean => {
   if (isRequired === false && isNullOrEmpty(val))
      return true;

   if (!isNullOrEmpty(val) && minLength === maxLength && minLength !== val.length)
      return false;

   return true;
}

export const validateStringMin = (val: string, isRequired: boolean, minLength: number = 0): boolean => {
   if (isRequired === false && isNullOrEmpty(val))
      return true;

   if (!isNullOrEmpty(val) && val.length < minLength)
      return false;

   return true;
}

export const validateStringMax = (val: string, isRequired: boolean, maxLength: number = 250): boolean => {
   if (isRequired === false && isNullOrEmpty(val))
      return true;

   if (!isNullOrEmpty(val) && val.length > maxLength)
      return false;

   return true;
}

export const getDate = (val: string): Date => {
   if (isNullOrEmpty(val))
      return null;

   return new Date(val);
};
export const minSqlDate = new Date('1753-01-01');
export const maxSqlDate = new Date('9999-12-31');

export const validateDate = (val: string): boolean => {

   //dates come accross as "12/16/2011 and 2011-12-16T00:00:00"  
   if (val) {
      var timestamp = Date.parse(val);

      if (isNaN(timestamp) === false) {
         new Date(timestamp);
         return true;
      }
   }
   return false;
};

export const validateInteger = (val: string, isRequired: boolean): boolean => {

   if (isRequired === false && isNullOrEmpty(val))
      return true;

   const parsed = Number(val);

   if (Number.isNaN(parsed))
      return false;

   if (!Number.isFinite(parsed))
      return false;

   //NOTE: javascript integer: 9007199254740991 c# long max value: 9223372036854775807
   if (!Number.isInteger(parsed))
      return false;

   return true;
};

export const cleanCurrency = (val: string): string => {
   let cleanval = val.replace(/[,$]/g, '');
   return cleanval;
}

export const validateCurrency = (val: string, isRequired: boolean, minLength: number = 0, maxLength: number = 10): boolean => {
   let cleanval = cleanCurrency(val);

   if (isRequired === false && isNullOrEmpty(val))
      return true;

   if (!validateStringMin(cleanval, isRequired, minLength))
      return false;

   if (!validateStringMax(cleanval, isRequired, maxLength))
      return false;

   if (Number.isNaN(Number(cleanval)))
      return false;

   const parsed = Number.parseFloat(cleanval);

   if (Number.isNaN(parsed))
      return false;

   if (!Number.isFinite(parsed))
      return false;

   return true;
};

export const validateCurrencyDollars = (val: string, isRequired: boolean, minLength: number = 0, maxLength: number = 10): boolean => {

   if (!validateCurrency(val, isRequired, minLength, maxLength)) 
      return false;

   if (!Number.isInteger(Number(val)))
      return false;

   return true;
};

export const cleanNumber = (val: string): string => {
   if (val === null)
      return val;

   let cleanval = val.replace(/\D/g, '');
   return cleanval;
}

export const validateUrl = (val: string, isRequired: boolean, minLength: number = 0, maxLength: number = 10): boolean => {
   let cleanval = cleanCurrency(val);

   if (isRequired === false && isNullOrEmpty(val))
      return true;

   if (!validateStringMin(cleanval, isRequired, minLength))
      return false;

   if (!validateStringMax(cleanval, isRequired, maxLength))
      return false;

   if (!isValidHttpUrl(val))
      return false;

   return true;
};


export const isValidHttpUrl = (val: string): boolean => {
   let url;
   try {
      url = new URL(val);
   } catch (_) {
      return false;
   }

   return url.protocol === "http:" || url.protocol === "https:";
}


export const validateUUID = (val: string): boolean => {
   let s = "" + val;

   const result = s.match('^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$');
   return result && result.length > 0 ? true : false;
}

var regexEmail = /^\S+@\S+\.\S+$/;
export const validateEmail = (val: string): boolean => {
   let s = "" + val;
   const result = regexEmail.test(s);
   return result;
}

const phoneFaxRegex = /^[2-9][0-9]*$/;
export const validatePhoneOrFax = (val: string, isValueRequired: boolean = false): boolean => {
   if (isValueRequired) {
      return phoneFaxRegex.test("" + val) && val?.length === 10;
   } else {
      return (val?.length ?? 0) === 0 || (phoneFaxRegex.test(val) && val?.length === 10);
   }
}