import * as React from 'react';
import useSWR, { mutate } from "swr";
import { numberComparer } from '../../functions/comparer.functions';
import { HttpError, TGet, TPost, TPut } from "../../functions/httpClient";
import { IApiResponse } from '../models';
import { useFetchPractice } from "../practice/PracticeFetcher";
import { useFetchUserProfiles, userProfileBaseUrl } from "../practice/UserProfileFetcher";
import { UserMainDetail, UserMainViewModel, UserMainNonAcceptedInvite } from './UserMainModel';
import { useFetchUserMigrations } from './UserMigrationFetcher';

export const userMainBaseUrl = 'api/auth/usermain';
export const userMainCurrentUserUrl = `${userMainBaseUrl}/GetAndUpdateCurrentUser`;
export const userMainSaveUrl = `${userMainBaseUrl}/Save`;
const userMainUpdateAccessRolesUrl = (userMainId: number) => `${userMainBaseUrl}/UpdateUserMainAccessRoles/${userMainId}`;
const userMainUpdateUserProfilesUrl = (userMainId: number) => `${userMainBaseUrl}/UpdateUserMainUserProfiles/${userMainId}`;
export const nonAcceptedUserMainsUrl = `${userMainBaseUrl}/GetNonAcceptedUserInvites`;
export const unmapUserProfileUrl = `${userMainBaseUrl}/UnmapUserProfile`;

const swrOptions = {
   refreshInterval: 0,
   revalidateOnFocus: false,
   dedupingInterval: 60000
};

export const useFetchUserMain = (httpGet: TGet): IApiResponse<UserMainDetail[]> => {
   const { data, isValidating, error } = useSWR<UserMainDetail[], HttpError>(
      userMainBaseUrl,
      httpGet,
      { ...swrOptions }
   );

   return {
      data: data,
      isValidating: isValidating,
      isLoading: !data && isValidating,
      error: error
   };
}

export const useFetchUserMainsForNonAcceptedInvites = (httpGet: TGet): IApiResponse<UserMainNonAcceptedInvite[]> => {
   const { data, isValidating, error } = useSWR<UserMainNonAcceptedInvite[], HttpError>(
      nonAcceptedUserMainsUrl,
      httpGet,
      { ...swrOptions }
   );

   return {
      data: data,
      isValidating: isValidating,
      isLoading: !data && isValidating,
      error: error
   };
}

export const saveUserMain = async (httpPost: TPost, userMain: UserMainDetail): Promise<UserMainDetail> => {
   let returnedUserMain: UserMainDetail;

   await httpPost<UserMainDetail>(userMainSaveUrl, userMain)
      .then((response) => {
         mutate(userMainBaseUrl)
         returnedUserMain = response;
      });

   return returnedUserMain;
}

export const updateUserMainAccessRoles = (httpPut: TPut, userMainId: number, accessRoleIds: number[]) => {
   const result = httpPut(
      userMainUpdateAccessRolesUrl(userMainId),
      { accessRoleIds: accessRoleIds }
   )
      .then(() => mutate(userMainBaseUrl))

   return result;
}

export const updateUserMainUserProfiles = (httpPut: TPut, userMainId: number, userProfileIds: number[]) => {
   const result = httpPut(
      userMainUpdateUserProfilesUrl(userMainId),
      { userProfileIds: userProfileIds }
   )
      .then(() => mutate(userMainBaseUrl));

   return result;
}

export const unmapUserProfile = (httpPost: TPost, userMainId: number, userProfileId: number) => {
   const result = httpPost(
      unmapUserProfileUrl,
      { userMainId: userMainId, userProfileId: userProfileId }
   )
      .then(() => {
         // mutates here
         mutate(userMainBaseUrl);
         mutate(userProfileBaseUrl)
      });

   return result;
}

export const useUserMainViewModels = (httpGet: TGet) => {
   const { data: userMainList, isLoading: isLoading_UserMain, error: error_UserMain } = useFetchUserMain(httpGet);
   const { userProfiles, isLoading: isLoading_UserProfiles, error: error_UserProfiles } = useFetchUserProfiles(httpGet);
   const { practices, isLoading: isLoading_Practices, error: error_Practices } = useFetchPractice(httpGet);
   const { userMigrationList, isLoading: isLoading_Migrations, error: error_Migrations } = useFetchUserMigrations(httpGet);

   const models = React.useMemo(() => {
      if (!isLoading_Practices && !isLoading_UserMain && !isLoading_UserProfiles && !isLoading_Migrations && userMainList) {
         const tempModels = userMainList.map((record): UserMainViewModel => {
            const migrationRecord = userMigrationList.find(y => y.userMainId === record.id);
            const thisUsersProfiles = userProfiles?.filter(y => record.userProfileIds?.includes(y.userProfileId));
            const thisUsersPractices = practices?.filter(y => thisUsersProfiles?.some(p => p.practiceId === y.id));

            const usersPractices = thisUsersProfiles?.filter(y => !y.isDisabled)?.map(y => y.practiceId)?.sort((a, b) => numberComparer(a, b));
            const distinctPracticeIds = [...new Set(usersPractices ?? [])];
           
            return {
               ...record,
               userMigration: migrationRecord,
               practices: thisUsersPractices,
               userProfiles: thisUsersProfiles,
               distinctPracticeIds: distinctPracticeIds
            };
         });
         return tempModels;
      }
   }, [userMainList, userProfiles, practices, userMigrationList, isLoading_Practices, isLoading_UserMain, isLoading_UserProfiles, isLoading_Migrations])

   return {
      userMainViewModels: models,
      isLoading: isLoading_UserMain || isLoading_UserProfiles || isLoading_Practices,
      error: error_UserMain || error_UserProfiles || error_Practices || error_Migrations
   }
}


export const useUserMainViewModel = (httpGet: TGet, userMainId?: number) => {
   const { userMainViewModels, error, isLoading } = useUserMainViewModels(httpGet);

   const model: UserMainViewModel = React.useMemo(() => {
      if (userMainViewModels && userMainId) {
         return userMainViewModels.find(y => y.id === userMainId);
      }
      return undefined;
   }, [userMainViewModels, userMainId]);

   return {
      userMainViewModel: model,
      isLoading: isLoading,
      error: error
   } 
}
