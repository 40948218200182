import * as React from 'react';
import { Link } from 'react-router-dom';
import Card from '../layouts/Card';
import { useLayoutContext } from '../layouts/LayoutContext';

const Settings: React.FC = () => {
   const { menuProps } = useLayoutContext();

   const html = (
      <Card title="Settings" closeFunc={menuProps.showMainMenu}>
         <div className="padded-container">
            <div className="full-height" style={{ paddingLeft: '30px' }}>
               <div className="menu-items">
                  <Link to="/UserProfile">
                     <div className="menu-item">
                        <span className="menu-icon">
                           <img src="https://ap-cdn.azureedge.net/cdn-images/practice/shared/icons/Profile.svg" alt="" />
                        </span>
                        <span className="menu-title">
                           User Profile
                        </span>
                     </div>
                  </Link>
               </div>
            </div>
         </div>
      </Card>
   );
   return html;
};

export default Settings;