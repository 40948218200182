import {  TPut } from '../../functions/httpClient';

export const powerBIAuditBaseUrl = `/api/rpt/PowerBIAudit`;

export const savePowerBIAudit = async (httpPut: TPut,
   workspaceId: string,
   reportId: string,
   reportPageId: string) => {
   const data = { workspaceId, reportId, reportPageId };
   try {
      await httpPut(powerBIAuditBaseUrl, data);
   } catch (err) {
      console.warn("Error savePowerBIAudit", powerBIAuditBaseUrl, data);
      console.warn(err);
   }
   finally {
      //do nothing - audit should never break UI
   }
}

