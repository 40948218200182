export interface Parameter {
   id: number;
   parameter: string;
   parameterClassId: number;
   sequence: number;
   bucket: string;
}

export interface ParameterClass {
   id: number;
   name: string;
   sequence: number;
}

export interface PlanType {
   id: number;
   name: string;
}

export interface PlanParameter {
   id: number;
   planTypeId: number;
   parameterId: number;
}

export interface ParameterClassViewModel extends ParameterClass {
   parameters: Parameter[];
}

export interface ScorecardTemplate {
   planTypeId: number;
   classes: ParameterClassViewModel[];
}

export interface ScorecardResponse {
   id?: number;
   bucket?: string;
   rating?: number;
   comment?: string;
   parameterId?: number;
   parameter: Parameter
}

export interface Scorecard {
   id?: number;
   practiceId?: number;
   patientId?: string;
   userProfileId?: number;
   scorecardDate?: Date;
   createdOn?: Date;
   createdBy?: string;
   responses: ScorecardResponse[];
   planTypeId: number;
}

export const BUCKETVALUE_CRITICAL = "Critical";
export const BUCKETVALUE_TECHNICAL = "Technical";
export const BUCKETVALUE_NA = "N/A";