import { ColumnsType, TableProps } from 'antd/lib/table';
import * as React from 'react';
import Restricted from '../../../auth/Restricted';
import { numberComparer, stringComparer } from '../../../functions/comparer.functions';
import { NdcViewModel } from '../../../store/drug/NdcModel';
import { ndcBaseUrl, useNdcViewModel } from '../../../store/drug/NdcFetcher';
import { KnownSettings } from '../../../store/SettingsModel';
import HighlightSearchText from '../../HighlightSearchText';
import SearchResultsTable from '../../shared/AntComponents/Table/SearchResultsTable';
import { BasicInputField } from '../../shared/BasicInputLibrary';
import { AddButton, EditButton } from '../../shared/Buttons';
import Spinner from '../../Spinner';
import NdcEditor from './NdcEditor';
import { useApiContext } from '../../../store/ApiContext';
import { HttpVerb, KeyWithVerb, useErrorContext } from '../../../store/ErrorContext';
import ApiErrorDisplay from '../../ApiErrorDisplay';

const _keysWithVerb: KeyWithVerb[] = [{ key: ndcBaseUrl, verb: HttpVerb.GET }]
const NdcIndex: React.FC = () => {
   const { httpGet } = useApiContext();
   const { removeErrors } = useErrorContext();
   const { ndcViewModel, isLoading } = useNdcViewModel(httpGet);

   const [currentPage, setCurrentPage] = React.useState(1);
   const [currentPageSize, setCurrentPageSize] = React.useState(25);
   const handleOnChange: TableProps<NdcViewModel>['onChange'] = (pagination, filters, sorter, extra) => setCurrentPage(pagination?.current);

   const [nameFilter, setNameFilter] = React.useState<string>('');
   const [selectedItem, setSelectedItem] = React.useState<NdcViewModel>(undefined);
   const [isEditorOpen, setIsEditorOpen] = React.useState<boolean>(false);

   React.useEffect(() => {
      removeErrors({ keysWithVerb: _keysWithVerb });
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])  // remove Errors on initial render

   const filterName = (m: NdcViewModel): boolean => {
      return !nameFilter || m.ndc11?.toLowerCase().indexOf(nameFilter.toLowerCase()) > -1 ||
         !nameFilter || m.ndc10?.toLowerCase().indexOf(nameFilter.toLowerCase()) > -1 ||
         !nameFilter || m.description?.toLowerCase().indexOf(nameFilter.toLowerCase()) > -1 ||
         !nameFilter || m.drugName?.toLowerCase().indexOf(nameFilter.toLowerCase()) > -1
         ? true : false;
   }

   const filterGridContent = (records: NdcViewModel[]): NdcViewModel[] => {
      if (records && records?.length > 0) {
         return records.filter(y => filterName(y))
      }
      return [];
   }

   const filteredData = React.useMemo(() => {
      if (ndcViewModel?.length > 0) {
         return filterGridContent(ndcViewModel);
      }
      // for "filterGridContent" - we actually need the two in this dependency array
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [ndcViewModel, nameFilter])

   const handleCloseEditorClick = () => {
      setIsEditorOpen(false);
      setSelectedItem(undefined);
   }

   if (!ndcViewModel || isLoading) {
      return <Spinner />
   }
   const tableColumns: ColumnsType<NdcViewModel> = [
      {
         title: 'Id',
         dataIndex: 'id',
         width: 80,
         sorter: (a, b) => numberComparer(a.id, b.id),
         sortDirections: ['ascend', 'descend', 'ascend'],
         defaultSortOrder: 'descend',
      },
      {
         title: 'NDC10',
         width: 100,
         dataIndex: 'manufacturerName',
         sorter: (a, b) => stringComparer(a.ndc10, b.ndc10),
         sortDirections: ['ascend', 'descend', 'ascend'],
         render: (text, record) => <HighlightSearchText searchString={nameFilter} targetString={record.ndc10} />
      },
      {
         title: 'NDC11',
         dataIndex: 'ndc11',
         width: 100,
         sorter: (a, b) => stringComparer(a.ndc11, b.ndc11),
         sortDirections: ['ascend', 'descend', 'ascend'],
         render: (text, record) => <HighlightSearchText searchString={nameFilter} targetString={record.ndc11} />
      },
      {
         title: 'Description',
         dataIndex: 'description',
         sorter: (a, b) => stringComparer(a.description, b.description),
         sortDirections: ['ascend', 'descend', 'ascend'],
         render: (text, record) => <HighlightSearchText searchString={nameFilter} targetString={record.description} />
      },
      {
         title: 'DrugId',
         dataIndex: 'drugId',
         width: 80,
         sorter: (a, b) => numberComparer(a.drugId, b.drugId),
         sortDirections: ['ascend', 'descend', 'ascend'],
      },
      {
         title: 'Drug',
         dataIndex: 'assistanceServiceName',
         sorter: (a, b) => stringComparer(a.drugName, b.drugName),
         sortDirections: ['ascend', 'descend', 'ascend'],
         render: (text, record) => <HighlightSearchText searchString={nameFilter} targetString={record.drugName} />
      },
      {
         title: () =>
            <Restricted requiredRoles={[KnownSettings.ContentAdmin]}>
               <AddButton
                  title='Add New NDC'
                  buttonText='New' onClick={() => {
                     setSelectedItem(undefined);
                     setIsEditorOpen(true)
                  }} />
            </Restricted>,
         dataIndex: 'actions',
         width: 100,
         fixed: 'right',
         render: (text, record) => <Restricted requiredRoles={[KnownSettings.ContentAdmin]}>
            <EditButton
               title='Edit'
               onClick={() => {
                  setIsEditorOpen(true);
                  setSelectedItem(record);
               }}
            />
         </Restricted>
      },
   ];



   const html = (
      <>
         <ApiErrorDisplay
            title={'Error'}
            keysWithVerb={_keysWithVerb}
         />

         <SearchResultsTable
            rowkey={'id'}
            size={'small'}
            onChange={handleOnChange}
            columns={tableColumns}
            data={filteredData}
            currentPage={currentPage}
            currentPageSize={currentPageSize}
            setCurrentPageSize={setCurrentPageSize}
            titleText='NDC'
            fixedHeader={true}
            additionalComponents={[
               <BasicInputField
                  type="text"
                  name="ndcSearchString"
                  label="Search String"
                  value={nameFilter}
                  onChange={(e) => setNameFilter(String(e))} />,
            ]}
         />

         {isEditorOpen &&
            <NdcEditor isOpen={isEditorOpen}
               closeEditor={() => handleCloseEditorClick()}
               id={selectedItem?.id} />
         }
      </>
   )
   return html;
}

export default NdcIndex;
