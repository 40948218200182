import * as React from 'react';
import { useUserContext } from '../auth/authContext';
import ContentCard from '../layouts/ContentCard';
import { KnownSettings } from '../store/SettingsModel';
import CustomIcon, { CustomIconType } from './shared/AntComponents/CustomIcon';
import { NumberedTitle } from './shared/AntComponents/Typography/Title';
import Spinner from './Spinner';
import './UserProfile.css';

const getIcon = (role: string) => {
    switch (role) {
        case KnownSettings.CohortReviewer:
            return CustomIconType.UserOutlined;
        case KnownSettings.ContentAdmin:
            return CustomIconType.CoffeeOutlined;
        case KnownSettings.ReportViewer:
            return CustomIconType.IdcardOutlined;
        case KnownSettings.CrxUser:
            return CustomIconType.UserSwitchOutlined;
       case KnownSettings.ContentViewer:
          return CustomIconType.EyeOutlined;
       case KnownSettings.FSAUser:
          return CustomIconType.AlertOutlined;
       case KnownSettings.CohortAdmin:
          return CustomIconType.GithubFilled;
     default:
            return CustomIconType.Unknown
    }
}

const UserProfile: React.FC = () => {

    const { user, userRoles } = useUserContext();

    if (!user)
        return <Spinner />;

    const html = (
       <ContentCard title="User Profile">
          <div className="padded-container">
             <NumberedTitle level={4} text='Personal Information' />
             <hr style={{ borderColor: 'rgba(0,0,0,0.1)' }} />
             <div className="profile-field">
                <label>Name</label>
                <span className="text">{user.name}</span>
                <span className="md-input-bar"></span>
             </div>
             <div className="profile-field">
                <label>User Name</label>
                <span className="text">{user.username}</span>
                <span className="md-input-bar"></span>
             </div>
             <div className="profile-field">
                <NumberedTitle level={4} text='Personal Information' />
                <hr style={{ borderColor: 'rgba(0,0,0,0.1)' }} />
                {userRoles?.sort().map((role: string, index: number) =>
                   <div key={index}>
                      <span className="text"><CustomIcon style={{ paddingRight: 10 }} type={getIcon(role)} />{role}</span>
                   </div>
                )}
             </div>
          </div>
       </ContentCard>
    )
    return html;
}
export default UserProfile;
