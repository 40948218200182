import { Col, Row, Space, Table } from 'antd';
import { ColumnsType, TableProps } from 'antd/lib/table';
import { SorterResult, TableRowSelection } from 'antd/lib/table/interface';
import * as React from 'react';
import Restricted from '../../../auth/Restricted';
import { formatDateTimeString } from '../../../functions/format.functions';
import { useApiContext } from '../../../store/ApiContext';
import {
   IntrinsiqPayerToAP,
   IntrinsiqPayerToAPViewModel,
   IntrinsiqPayerToAPSearchFilter,
   xrefStatusOptions
} from '../../../store/content-tool/IntrinsiqPayerToAPModel';
import {
    useFetchIntrinsiqAssistancePrograms,
    useFetchIntrinsiqPractices,
   useIntrinsiqPayerToAPSearchViewModel,
} from '../../../store/content-tool/IntrinsiqPayerToAPFetcher';
import { KnownSettings } from '../../../store/SettingsModel';
import HighlightSearchText from '../../HighlightSearchText';
import CustomIcon, { CustomIconType } from '../../shared/AntComponents/CustomIcon';
import SearchResultsTable from '../../shared/AntComponents/Table/SearchResultsTable';
import { BasicDropdownField, DebouncedBasicInputField } from '../../shared/BasicInputLibrary';
import { ActionButton, EditButton } from '../../shared/Buttons';
import Spinner from '../../Spinner';
import IntrinsiqPayerToAPBulkProgramType from './IntrinsiqPayerToAPBulkProgramType';
import IntrinsiqPayerToApXRefBulkEditor from './IntrinsiqPayerToApXRefBulkEditor';
import IntrinsiqPayerToApXRefEditor from './IntrinsiqPayerToApXRefEditor';
import { IOptionItem } from '../../../functions/option.functions';
import { antSortOptions } from '../../shared/AntComponents/Table/table.functions';

const initSearchFilter: IntrinsiqPayerToAPSearchFilter = {
   page: 1,
   pageSize: 25,
   sortDirection: 'desc',
   sortField: 'id',   
}
const IntrinsiqPayerToApXRefList: React.FC = () => {
   const { httpGet, httpPost } = useApiContext();

   const [searchFilter, setSearchFilter] = React.useState<IntrinsiqPayerToAPSearchFilter>(initSearchFilter);
   const [selectedItem, setSelectedItem] = React.useState<IntrinsiqPayerToAP>(undefined);
   const [isEditorOpen, setIsEditorOpen] = React.useState<boolean>(false);
   const [isBulkProgramTypeModalOpen, setIsBulkProgramTypeModalOpen] = React.useState<boolean>(false);
   const [isBulkEditorOpen, setIsBulkEditorOpen] = React.useState<boolean>(false);
   const [bulkItems, setBulkItems] = React.useState<number[]>([]);

   const { intrinsiqPractices } = useFetchIntrinsiqPractices(httpGet);
   const intrinsiqPayerPracticeOptions = React.useMemo(() => {
      return intrinsiqPractices?.map((o): IOptionItem => ({
         label: `[${o.id}]: ${o.name}`,
         value: o.id
      } as IOptionItem))
   }, [intrinsiqPractices]);

   const { intrinsiqAssistancePrograms } = useFetchIntrinsiqAssistancePrograms(httpGet);
   const intrinsiqPayerProgramOptions = React.useMemo(() => {
      return intrinsiqAssistancePrograms?.map((o): IOptionItem => ({
         label: `[${o.id}]: ${o.name}`,
         value: o.id
      } as IOptionItem));
   }, [intrinsiqAssistancePrograms]);

   const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);

   const { pagedResult, isLoading } = useIntrinsiqPayerToAPSearchViewModel(httpGet, httpPost, searchFilter);


   const handleOnChange: TableProps<IntrinsiqPayerToAPViewModel>['onChange'] = (pagination, filters, sorter, extra) => {

      const newSearchFilter = {
         ...searchFilter,
         page: pagination?.current
      }
      const theSorter = Array.isArray(sorter) ? sorter[0] as SorterResult<IntrinsiqPayerToAPViewModel> : sorter as SorterResult<IntrinsiqPayerToAPViewModel>;
      if (sorter) {
         if (sorter.hasOwnProperty('field')) {
            newSearchFilter.sortField = theSorter.field as string;
            newSearchFilter.sortDirection = theSorter.order?.indexOf('desc') > -1 ? 'desc' : 'asc';
         }
      }
      onClearBulkSelection(); //reset bulk items on page\sort
      setSearchFilter(newSearchFilter);
   }

   const handleCloseEditorClick = () => {
      setIsEditorOpen(false);
      setSelectedItem(undefined);
   }

   const handleCloseBulkEditorClick = () => {
      //remove all checkboxes on close
      setBulkItems([]);
      setSelectedRowKeys([]);
      setIsBulkEditorOpen(false);
   }

   const clickBulkEditButton = () => {
      setIsBulkEditorOpen(true);
   }

   const closeBulkProgramTypeModal = () => {
      setIsBulkProgramTypeModalOpen(false);
      setSelectedItem(undefined);
   }

   const tableColumns: ColumnsType<IntrinsiqPayerToAPViewModel> = [
      {
         title: 'Id',
         dataIndex: 'id',
         width: 100,
         sorter: true,
         sortDirections: antSortOptions,
         defaultSortOrder: 'descend',
      },
      {
         title: 'PracticeId',
         dataIndex: 'practiceId',
         width: 100,
         sorter: true,
         sortDirections: antSortOptions,
         render: (text, record) => <HighlightSearchText searchString={`${searchFilter.practiceId}`} targetString={`${text}`} />
      },
      {
         title: 'Practice Name',
         dataIndex: 'practiceName',
         render: (text, record) => {
            if (searchFilter.practiceId && searchFilter.practiceId === record.practiceId) {
               return <HighlightSearchText searchString={text} targetString={text} />
            }
            return text;
         }
      },
      {
         title: 'Payee Name',
         dataIndex: 'payeeName',
         sorter: true,
         sortDirections: antSortOptions,
         width: 200,
         render: (text, record) =>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
               <div style={{ overflow: 'auto' }}>
                  <HighlightSearchText searchString={`${searchFilter.payeeName}`} targetString={`${record.payeeName}`} />
               </div>
               <Restricted requiredRoles={[KnownSettings.ContentAdmin]}>
                  <div>
                     <EditButton
                        onClick={() => {
                           setSelectedItem(record);
                           setIsBulkProgramTypeModalOpen(true);
                        }}
                        icon={<CustomIcon type={CustomIconType.CopyOutlined} />}
                        title='Bulk Map by Program Type'
                     />
                  </div>
               </Restricted>
            </div>
      },
      {
         title: 'Payee PCN',
         dataIndex: 'payeePCN',
         sorter: true,
         sortDirections: antSortOptions,
         render: (text, record) =>
            <HighlightSearchText searchString={`${searchFilter.payeeName}`} targetString={`${record.payeePCN}`} />
      },
      {
         title: 'Payee Bin',
         dataIndex: 'payeeBin',
         sorter: true,
         sortDirections: antSortOptions,
         render: (text, record) =>
            <HighlightSearchText searchString={`${searchFilter.payeeName}`} targetString={`${record.payeeBin}`} />
      },
      Table.SELECTION_COLUMN,
      {
         title: 'Status',
         dataIndex: 'status',
         sorter: true,
         sortDirections: antSortOptions,
         render: (text, record) => <HighlightSearchText searchString={`${searchFilter.status}`} targetString={`${text}`} />
      },
      {
         title: 'APID',
         dataIndex: 'assistanceProgramId',
         width: 80,
         sorter: true,
         sortDirections: antSortOptions,
         render: (text, record) => <HighlightSearchText searchString={`${searchFilter.assistanceProgramId}`} targetString={`${text ?? ''}`} />
      },
      {
         title: 'Program Name',
         dataIndex: 'programName',
         sorter: true,
         sortDirections: antSortOptions,
         render: (text, record) => {
            if (searchFilter.assistanceProgramId && searchFilter.assistanceProgramId === record.assistanceProgramId) {
               return <HighlightSearchText searchString={text} targetString={text} />
            }
            return text;
         }
      },
      {
         title: 'Reviewed On',
         dataIndex: 'reviewedOn',
         sorter: true,
         sortDirections: antSortOptions,
         render: (text, record) => formatDateTimeString(record.reviewedOn)
      },
      {
         dataIndex: 'actions',
         width: 60,
         fixed: 'right',
         render: (text, record) => <Restricted requiredRoles={[KnownSettings.ContentAdmin]}>
            <EditButton
               title='Edit'
               onClick={() => {
                  setSelectedItem(record);
                  setIsEditorOpen(true);
               }} />
         </Restricted>
      },
   ];

   const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
      const arr = newSelectedRowKeys.map((y) => { return Number(y) });
      setBulkItems(arr);
      setSelectedRowKeys(newSelectedRowKeys);
   };

   const rowSelection: TableRowSelection<object> = {
      selectedRowKeys,
      onChange: onSelectChange,
   };

   const onClearBulkSelection = () => {
      setBulkItems([]);
      setSelectedRowKeys([]);
   }

   const html = (
      <>
         <SearchResultsTable
            loading={isLoading}
            rowkey={'id'}
            onChange={handleOnChange}
            columns={tableColumns}
            data={pagedResult?.data}
            serverSideRowCount={pagedResult?.totalRecords}
            currentPage={searchFilter?.page}
            currentPageSize={searchFilter?.pageSize}
            setCurrentPageSize={newPageSize => {
               setSearchFilter({
                  ...searchFilter,
                  page: 1, //reset the page to keep us on the page
                  pageSize: Number(newPageSize)
               })
            }}
            titleText='IntrinsiqPayerToApXRef'
            fixedHeader={true}
            footer={<Row justify="end">
               <Col>
                  <Restricted requiredRoles={[KnownSettings.ContentAdmin]}>
                     <Space>
                        <ActionButton
                           buttonText='Clear Bulk Selection'
                           onClick={onClearBulkSelection}
                           disabled={bulkItems.length === 0}
                           icon={<CustomIcon type={CustomIconType.CloseOutlined} />}
                        />
                        <ActionButton
                           buttonText='Edit Bulk'
                           disabled={bulkItems.length === 0}
                           onClick={() => clickBulkEditButton()}
                           icon={<CustomIcon type={CustomIconType.EditOutlined} />}
                        />
                     </Space>
                  </Restricted>
               </Col>
            </Row>}
            rowSelection={rowSelection}
            size='small'
            scrollY='calc(100vh - 375px)'
            additionalComponents={[
               <BasicDropdownField
                  name='practiceSearchString'
                  label='Practice'
                  placeholder={' - Filter By Practice -'}
                  value={searchFilter.practiceId}
                  options={intrinsiqPayerPracticeOptions}
                  style={{ minWidth: 200 }}
                  search={true}
                  clearable={true}
                  onChange={(e) => setSearchFilter({
                        ...searchFilter,
                        page: 1,
                        practiceId: e ? e as number : undefined
                     })}
               />,
               <DebouncedBasicInputField
                  label='Payee'
                  name="payeeSearchString"
                  value={searchFilter.payeeName}
                  onChange={(e) => {
                     setSearchFilter({
                        ...searchFilter,
                        payeeName: e ? e as string : undefined
                     })
                  }}
               />,
               <BasicDropdownField
                  name='assistanceProgramSearchString'
                  label='Assistance Program'
                  placeholder={' - Filter By Assistance Program -'}
                  value={searchFilter.assistanceProgramId}
                  style={{ minWidth: 200 }}
                  options={intrinsiqPayerProgramOptions}
                  search={true}
                  clearable={true}
                  onChange={(e) => setSearchFilter({
                     ...searchFilter,
                     page: 1,
                     assistanceProgramId: e ? e as number : undefined
                  })}
               />,
               <BasicDropdownField
                  name='statusSearchString'
                  label='Status'
                  placeholder={' - Filter By Status -'}
                  value={searchFilter.status}
                  style={{ minWidth: 150 }}
                  options={xrefStatusOptions}
                  search={true}
                  clearable={true}
                  onChange={(e) => setSearchFilter({
                     ...searchFilter,
                     page: 1,
                     status: e ? e as string : undefined
                  })}
               />,
            ]} />

         {isEditorOpen &&
            <IntrinsiqPayerToApXRefEditor
               isOpen={isEditorOpen}
               closeEditor={handleCloseEditorClick}
               record={selectedItem}
            />
         }
         {isBulkEditorOpen &&
            <IntrinsiqPayerToApXRefBulkEditor
               isOpen={isBulkEditorOpen}
               closeEditor={handleCloseBulkEditorClick}
               bulkSourceItems={pagedResult.data?.filter(y => bulkItems.includes(y.id))}
            />
         }
         {isBulkProgramTypeModalOpen &&
            <IntrinsiqPayerToAPBulkProgramType
               isOpen={isBulkProgramTypeModalOpen}
               record={selectedItem}
               closeEditor={closeBulkProgramTypeModal}
            />
         }
      </>
   )
   return html;
}

export default IntrinsiqPayerToApXRefList;