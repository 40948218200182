import { Space, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import ChangePasswordButton from '../../auth/ChangePasswordButton';
import Restricted from '../../auth/Restricted';
import { boolComparer, numberComparer, stringComparer } from '../../functions/comparer.functions';
import { formatBoolean } from '../../functions/format.functions';
import { yesNoFilter } from '../../functions/option.functions';
import { useApiContext } from '../../store/ApiContext';
import { KnownSettings } from '../../store/SettingsModel';
import { useUserProfileViewModels } from '../../store/practice/UserProfileFetcher';
import { UserProfileViewModel } from '../../store/practice/UserProfileModel';
import { useFetchUserRoles } from '../../store/practice/UserRoleFetcher';
import CustomIcon, { CustomIconType } from '../shared/AntComponents/CustomIcon';
import MinimalistTable from '../shared/AntComponents/Table/MinimalistTable';
import { ActionButton, EditButton } from '../shared/Buttons';
import ExistingUserProfileEditor from '../user/shared/ExistingUserProfileEditor';

interface IProps {
   practiceId: number;
}

export const PracticeUserProfilesEmbeddedList: React.FC<IProps> = (props) => {

   const { practiceId } = props;

   const { httpGet } = useApiContext();
   const { userRoles } = useFetchUserRoles(httpGet);
   const { userProfileViewModels } = useUserProfileViewModels(httpGet);
   const { id: userProfileIdString } = useParams(); // Currently only provided when attempting to edit a User Profile from UserMain (UserIndex.tsx)

   const [userProfilesForThisPractice, setUserProfilesForThisPractice] = React.useState<UserProfileViewModel[]>([]);
   const [emailAddressFilter, setEmailAddressFilter] = React.useState<{ text: string, value: string }[]>([]);
   const [fullNameFilter, setFullNameFilter] = React.useState<{ text: string, value: string }[]>([]);
   const [upnFilter, setUpnFilter] = React.useState<{ text: string, value: string }[]>([]);

   const [isUserEditorOpen, setIsUserEditorOpen] = React.useState<boolean>(false);
   const [isUserEditorReadOnly, setIsUserEditorReadOnly] = React.useState<boolean>(false);
   const [userIdToEdit, setUserIdToEdit] = React.useState<number>(undefined);

   React.useEffect(() => {
      if (!userProfileViewModels || !practiceId) return;

      const practiceUserProfiles = userProfileViewModels.filter(f => f.practiceId === practiceId);
      setUserProfilesForThisPractice(practiceUserProfiles);

      const _setFilters = () => {
         const emailAddressList: string[] = [];
         const fullNameList: string[] = [];
         const upnList: string[] = [];
         practiceUserProfiles.map(y => {
            emailAddressList.push(y.emailAddress?.toLowerCase());
            fullNameList.push(y.fullName?.toLowerCase());
            upnList.push(y.userMain?.upn?.toLowerCase());
         });
         //NOTE:value set to null to allow antd filter checkbox to be selected
         const _emailAddressFilter = [...new Set(emailAddressList)].map(y => ({ text: y, value: y?.toLowerCase() ?? null }));
         setEmailAddressFilter(_emailAddressFilter.sort((a, b) => stringComparer(a.text, b.text)));

         const _fullNameFilter = [...new Set(fullNameList)].map(y => ({ text: y, value: y?.toLowerCase() ?? null }));
         setFullNameFilter(_fullNameFilter.sort((a, b) => stringComparer(a.text, b.text)));

         const _upnFilter = [...new Set(upnList)].map(y => ({ text: y, value: y?.toLowerCase() ?? null }));
         setUpnFilter(_upnFilter.sort((a, b) => stringComparer(a.text, b.text)));
      }
      _setFilters();

   }, [practiceId, userProfileViewModels]);

   React.useEffect(() => {
      if (userProfileIdString) {
         setUserIdToEdit(Number(userProfileIdString));
         setIsUserEditorOpen(true);
      }
   }, [userProfileIdString])

   const onUserEditorClose = () => {
      setIsUserEditorOpen(false);
      setIsUserEditorReadOnly(false);
      setUserIdToEdit(undefined);
   }

   const renderUserRoles = (userModel: UserProfileViewModel): React.ReactNode => {
      if (userModel?.userRoles?.length > 0) {
         const returnArr: JSX.Element[] = [];
         let index = 1;
         userModel.userRoles.forEach(r => {
            returnArr.push(
               /* 'ant-table-cell-ellipsis' - is the className that Ant uses to render ellipsis in its table when 
                *  you provide the "ellipsis: true" value for a column definition */
               <div key={`${userModel.userProfileId}-${index}`} className='ant-table-cell-ellipsis'>
                  {`${userRoles?.find(role => r === role.userRoleId).roleName}${index === userModel.userRoles.length ? '' : ','} `}
               </div>);
            index++;
         })
         return returnArr;
      } else {
         return <></>;
      }
   } // TODO: This is duplicated here and on UserManagementIndex - abstract it out

   const userProfileColumns: ColumnsType<UserProfileViewModel> = [
      {
         dataIndex: 'userProfileId',
         title: 'ProfileId',
         key: 'userProfileId',
         width: 75,
         sorter: (a, b) => numberComparer(a.userProfileId, b.userProfileId),
         sortDirections: ['ascend', 'descend'],
         defaultSortOrder: 'ascend'
      },
      {
         dataIndex: ['userMain', 'upn'],
         title: 'UserMain Upn',
         key: 'userMain.upn',
         sorter: (a, b) => stringComparer(a.userMain?.upn, b.userMain?.upn),
         sortDirections: ['ascend', 'descend'],
         filters: upnFilter,
         //onFilter has to account for nulls and undefined for builtin filters
         onFilter: (value, record) => (value === null && !record.userMain?.upn) || (value !== null && record.userMain?.upn?.toLowerCase().indexOf(value as string) > -1),
         filterSearch: true,
      },
      {
         dataIndex: 'emailAddress',
         title: 'UserProfile EmailAddress',
         key: 'emailAddress',
         sorter: (a, b) => stringComparer(a.emailAddress, b.emailAddress),
         sortDirections: ['ascend', 'descend'],
         filters: emailAddressFilter,
         onFilter: (value, record) => (value === null && !record.emailAddress) || (value !== null && record.emailAddress?.toLowerCase().indexOf(value as string) > -1),
         filterSearch: true,
      },
      {
         dataIndex: 'fullName',
         title: 'Full Name',
         key: 'fullName',
         sorter: (a, b) => stringComparer(a.fullName, b.fullName),
         sortDirections: ['ascend', 'descend'],
         filters: fullNameFilter,
         onFilter: (value, record) => (value === null && !record.fullName) || (value !== null && record.fullName?.toLowerCase().indexOf(value as string) > -1),
         filterSearch: true,
      },
      {
         dataIndex: 'userRoleNamesString',
         title: 'User Role',
         key: 'userRoles',
         sorter: (a, b) => stringComparer(a.userRoleNamesString, b.userRoleNamesString),
         sortDirections: ['ascend', 'descend'],
         render: (text, record) => {
            const content = renderUserRoles(record);
            return <Tooltip placement='top' overlayStyle={{ maxWidth: '350px' }} title={content}>{content}</Tooltip>
         }
      },
      {
         dataIndex: ['insightlyContact', 'fundStatusCom'],
         title: 'FundStatusAlerts',
         key: 'insightlyContact.fundStatusCom',
         sorter: (a, b) => stringComparer(a.insightlyContact?.fundStatusCom, b.insightlyContact?.fundStatusCom),
         sortDirections: ['ascend', 'descend'],
      },
      {
         dataIndex: 'isAdmin',
         title: 'Admin',
         key: 'isAdmin',
         width: 85,
         sorter: (a, b) => boolComparer(a.isAdmin, b.isAdmin),
         sortDirections: ['ascend', 'descend'],
         render: (val) => formatBoolean(val),
      },
      {
         dataIndex: 'isDisabled',
         title: 'Disabled',
         key: 'isDisabled',
         width: 100,
         sorter: (a, b) => boolComparer(a.isDisabled, b.isDisabled),
         sortDirections: ['ascend', 'descend'],
         render: (val) => formatBoolean(val),
         filters: yesNoFilter,
         onFilter: (value, record) => (value === true && record.isDisabled) || (value === false && !record.isDisabled),
         filterMultiple: false
      },
      {
         dataIndex: '',
         title: '',
         key: 7,
         width: 100,
         fixed: 'right',
         render: (text, record) =>
            <Space>
               <Restricted requiredRoles={[KnownSettings.UserAdmin]}>
                  <EditButton
                     title='Edit'
                     onClick={() => {
                        setUserIdToEdit(record.userProfileId);
                        setIsUserEditorOpen(true);
                     }} />
               </Restricted>
               <ActionButton
                  title='Preview'
                  icon={<CustomIcon type={CustomIconType.EyeOutlined} />}
                  onClick={() => {
                     setUserIdToEdit(record.userProfileId);
                     setIsUserEditorReadOnly(true);
                     setIsUserEditorOpen(true);
                  }} />
               {record?.userMain?.auth0Id?.includes('auth0') &&
                  <Restricted requiredRoles={[KnownSettings.UserAdmin]}>
                     <ChangePasswordButton userMainId={record?.userMain.id} showButtonText={false} />
                  </Restricted>}
            </Space>
      }
   ]

   return (
      <>
         <MinimalistTable
            rowKey='userProfileId'
            data={userProfilesForThisPractice}
            columns={userProfileColumns}
            size='small'
            showHeader={true}
            scrollY='calc(100vh - 475px)'
         />

         {isUserEditorOpen &&
            <ExistingUserProfileEditor
               existingUserProfileId={userIdToEdit}
               isEditorOpen={isUserEditorOpen}
               onCloseEditor={onUserEditorClose}
               isEditorReadOnly={isUserEditorReadOnly}
            />
         }
      </>
   );

}

export default PracticeUserProfilesEmbeddedList;