import * as React from 'react'
import { SaveButton, CancelButton } from '../../shared/Buttons';
import { programTypeOptions } from '../../../store/program/AssistanceProgramModel';
import { xrefStatusOptions, IntrinsiqPayerToAP, IntrinsiqPayerToAPBulkMapProgramType } from '../../../store/content-tool/IntrinsiqPayerToAPModel';
import {
   intrinsiqPayerBulkMapProgramTypeUrl,
   bulkMapProgramType
} from '../../../store/content-tool/IntrinsiqPayerToAPFetcher';
import Dialog from '../../Dialog';
import { IOptionItem } from '../../../functions/option.functions';
import { BasicRadioListField, BasicDropdownField } from '../../shared/BasicInputLibrary';
import { useApiContext } from '../../../store/ApiContext';
import ApiErrorDisplay from '../../ApiErrorDisplay';
import { useErrorContext } from '../../../store/ErrorContext';

interface IModalProps {
   record: IntrinsiqPayerToAP;
   isOpen: boolean;
   closeEditor: () => void;
}

const _keys: string[] = [intrinsiqPayerBulkMapProgramTypeUrl];

const IntrinsiqPayerToAPBulkProgramType: React.FC<IModalProps> = (props: IModalProps) => {
   const { record, isOpen, closeEditor } = props;
   const { httpPost } = useApiContext();
   const { removeErrors } = useErrorContext();
   const [programTypeSelection, setProgramTypeSelection] = React.useState<number>(undefined);
   const [statusSelection, setStatusSelection] = React.useState<string>('Mapped'); // Refer to AH-2614 / IntrinsiqPayertoAP -> xrefStatusOptions
   const [isFormSaving, setIsFormSaving] = React.useState<boolean>(false);
   const allActivePrograms: number = -1;

   const handleClose = () => {
      setProgramTypeSelection(undefined);
      setStatusSelection('Mapped');
      setIsFormSaving(false);
      removeErrors({ keys: _keys });
      closeEditor();
   }

   const handleSave = () => {
      setIsFormSaving(true);
      let programTypes: number[] = [];
      if (programTypeSelection === allActivePrograms) {
         programTypes = [0, 1];
      } else {
         programTypes = [programTypeSelection];
      }
    
      const mapRequest: IntrinsiqPayerToAPBulkMapProgramType = {
         record: record,
         programTypes: programTypes,
         status: statusSelection
      };

      bulkMapProgramType(httpPost, mapRequest)
         .then(handleClose)
         .catch(err => console.error('Error calling bulkMapProgramType', err))
         .finally(() => setIsFormSaving(false));
   }

   const getOptions = (): IOptionItem[] => {
      let options: IOptionItem[] = [...programTypeOptions];
      options.unshift({
         key: allActivePrograms,
         label: 'All',
         value: allActivePrograms
      } as IOptionItem);
      return options;
   }

   const html = (
      <Dialog
         scrollingContent={true}
         title={`IntrinsiqPayerToAP Bulk Program Type Selection for ${record?.id}`}
         open={isOpen}
         size='small'
         actionButtons={
            <>
               <CancelButton
                  onClick={() => handleClose()}
                  loading={isFormSaving} />
               <SaveButton
                  onClick={() => handleSave()}
                  disabled={isFormSaving || programTypeSelection === undefined}
                  loading={isFormSaving}
               />
            </>
         }
      >
         <div>
            <ApiErrorDisplay
               title='Bulk Mapping Error'
               keys={_keys}
            />

            <BasicRadioListField
               options={getOptions()}
               label='Please Select the Program Type for bulk mapping:'
               onChange={(e) => setProgramTypeSelection(Number(e))}
               direction='vertical'
               value={programTypeSelection}
            />
            <BasicDropdownField
               name='status'
               label='Status'
               multiple={false}
               options={xrefStatusOptions}
               value={statusSelection}
               onChange={(e) => setStatusSelection(String(e))}
            />
         </div>
      </Dialog>
   );

   return html;
}

export default IntrinsiqPayerToAPBulkProgramType