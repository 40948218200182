import { HttpError, TPost, TGet } from '../../functions/httpClient';
import { IStatus } from '../models';
import useSWR, { mutate } from 'swr';
import { AssistanceServiceToInsuranceClass } from './AssistanceServiceToInsuranceClassModel';

export const assistanceServiceToInsuranceClassBaseUrl = `api/program/AssistanceServiceToInsuranceClass`;
const saveAssistanceServiceToDrugUrl = (assistanceServiceId: number) => `${assistanceServiceToInsuranceClassBaseUrl}/SaveAllByAssistanceServiceId/${assistanceServiceId}`;

const swrOptions = {
   refreshInterval: 0,
   revalidateOnFocus: false,
   dedupingInterval: 60000
};


export function useFetchAssistanceServiceToInsuranceClass(httpGet: TGet): IStatus & { assistanceServiceToInsuranceClasses: AssistanceServiceToInsuranceClass[] } {
   const { data, isLoading, error } = useSWR<AssistanceServiceToInsuranceClass[], HttpError>(
      assistanceServiceToInsuranceClassBaseUrl,
      httpGet,
      { ...swrOptions }
   );

   return {
      assistanceServiceToInsuranceClasses: data,
      isLoading: isLoading,
      error: error?.message
   };
}

export const saveAssistanceServiceToDrug = async (httpPost: TPost, assistanceServiceId: number, insuranceClassIds: number[]) => {
   const result = await httpPost(saveAssistanceServiceToDrugUrl(assistanceServiceId), insuranceClassIds)
      .then(() => mutate(assistanceServiceToInsuranceClassBaseUrl));
   return result;
}