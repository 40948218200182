import { AssistanceServiceLookup } from './LookupModels';
import { IStatus } from '../models';
import { useMemo } from 'react';
import { stringComparer, numberComparer } from '../../functions/comparer.functions';
import { assistanceProgramWithFoundationsUrl, useAssistanceProgramNameOptions } from './AssistanceProgramFetcher';
import { HttpError, TPost, TDelete, TGet } from '../../functions/httpClient';
import { IOptionItem } from '../../functions/option.functions';
import useSWR, { mutate } from 'swr';
import { AssistanceService, AssistanceServiceWithFoundation } from './AssistanceServiceModel';

export const assistanceServiceBaseUrl = `api/program/assistanceService`;
export const assistanceServiceSaveUrl = `${assistanceServiceBaseUrl}/Save`;
const assistanceServiceDeleteUrl = (id: number) => `${assistanceServiceBaseUrl}/Delete/${id}`;
const assistanceServiceWithFoundationsUrl = `${assistanceServiceBaseUrl}/GetAssistanceServiceWithFoundations`;
export const assistanceServiceSaveLookupItemUrl = `${assistanceServiceBaseUrl}/SaveAssistanceServiceLookup`;

const swrOptions = {
   refreshInterval: 0,
   revalidateOnFocus: false,
   dedupingInterval: 60000
};


export function useFetchAssistanceServices(httpGet: TGet): IStatus & { assistanceServices: AssistanceService[] } {
   const { data, isLoading, error } = useSWR<AssistanceService[], HttpError>(
      assistanceServiceBaseUrl,
      httpGet,
      { ...swrOptions }
   );

   return {
      assistanceServices: data,
      isLoading: isLoading,
      error: error?.message
   };
}

export function useFetchAssistanceServicesWithFoundations(httpGet: TGet): IStatus & { assistanceServiceWithFoundations: AssistanceServiceWithFoundation[] } {
   const { data, isLoading, error } = useSWR<AssistanceServiceWithFoundation[], HttpError>(
      assistanceServiceWithFoundationsUrl,
      httpGet,
      { ...swrOptions }
   );

   return {
      assistanceServiceWithFoundations: data,
      isLoading: isLoading,
      error: error?.message
   };
}

export const saveAssistanceService = async (httpPost: TPost, assistanceService: AssistanceService) => {
   const result = await httpPost(assistanceServiceSaveUrl, assistanceService)
      .then(() => mutate(assistanceServiceBaseUrl));
   return result;
}

export const saveAssistanceServiceLookupItem = async (httpPost: TPost, assistanceServiceLookup: AssistanceServiceLookup, foundationId: number) => {
   const result = await httpPost(assistanceServiceSaveLookupItemUrl, assistanceServiceLookup)
      .then(() => mutate(assistanceProgramWithFoundationsUrl(foundationId)))
   return result;
}

export const deleteAssistanceService = async (httpDelete: TDelete, id: number) => {
   const result = await httpDelete(assistanceServiceDeleteUrl(id))
      .then(() => mutate(assistanceServiceBaseUrl));
   return result;
}

// needed a non hook version so that we could use inline within a render statement
export const getAssistanceServiceById = (id: number, assistanceServiceWithFoundations: AssistanceServiceWithFoundation[]): AssistanceServiceWithFoundation => {
   // eslint-disable-next-line no-restricted-globals
   return assistanceServiceWithFoundations?.find((f: AssistanceService) => f.id === id) ?? undefined
};

export function useAssistanceProgramAndServicesOptions(httpGet: TGet): { assistanceProgramAndServicesOptions: IOptionItem[] } {
   const { assistanceServices } = useFetchAssistanceServices(httpGet);
   const { assistanceProgramNameOptions } = useAssistanceProgramNameOptions(httpGet);

   const options = useMemo(() => {
      if (assistanceServices?.length > 0 && assistanceProgramNameOptions?.length > 0) {

         const sortedSvcs = [...assistanceServices];
         sortedSvcs.sort((a: AssistanceService, b: AssistanceService) => numberComparer(a.id, b.id));
         sortedSvcs.sort((a: AssistanceService, b: AssistanceService) => stringComparer(a.assistanceServiceName, b.assistanceServiceName));

         const options: IOptionItem[] = [];
         assistanceProgramNameOptions.forEach(p => {
            const programServices = sortedSvcs.filter(s => s.assistanceProgramId === p.value);
            programServices.forEach(ps => {
               if (!options.some(x => (x.value as number) === ps.id)) {
                  const opt = {
                     label: `${p.label}: [${ps.id}] (${ps.assistanceServiceName})`,
                     value: ps.id
                  } as IOptionItem

                  options.push(opt);
               }               
            })
         });

         return options;
      }
      return []
   }, [assistanceServices, assistanceProgramNameOptions]);

   return {
      assistanceProgramAndServicesOptions: options
   };
}


export const buildAssistanceServiceOptions = (assistanceServices: AssistanceService[]): IOptionItem[] => {
   if (assistanceServices?.length > 0) {
      const sorted = [...assistanceServices];

      sorted.sort((a: AssistanceService, b: AssistanceService) => stringComparer(a.assistanceServiceName, b.assistanceServiceName));

      const tempOptions = sorted.map((opt): IOptionItem => ({
         key: opt.id,
         label: `[${opt.id}] ${opt.assistanceServiceName}`,
         value: opt.id
      } as IOptionItem));

      return tempOptions;
   }
   return [];
}
