import * as React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useCohortContext } from './CohortContext';

type Props = {};

const CohortNav: React.FC<Props> = () => {

   const { pathname } = useLocation();
   const { cohortId } = useCohortContext();


   if (pathname.indexOf('cohortindex') > -1)
      return null;

   const root = (
      <Link to={{
         pathname: `/cohort/cohortindex`
      }}
         title='Program Summary Cohort'>
         &lt; Program Summary Cohort
      </Link>
   )
   if (pathname.indexOf('programsummarydetail') === -1) {
      return (<div className="flex-align-bottom">
         {root}
       </div>)
   }

   return (<div className="flex-align-bottom">
      {root}&nbsp;/&nbsp;
      <Link to={{
         pathname: `/cohort/programsummary/${cohortId}`
      }} >
         &lt; Back
         </Link>
      </div>)
}

export default CohortNav;
