import * as React from 'react';
import useSWR from "swr";
import { HttpError, TGet } from "../../functions/httpClient";
import { IOptionItem } from "../../functions/option.functions";
import { IStatus } from '../models';
import { BenefitPeriodType } from "./BenefitPeriodTypeModel";

const benefitPeriodTypeBaseUrl = 'api/program/BenefitPeriodType';

const swrOptions = {
   refreshInterval: 0,
   revalidateOnFocus: false,
   dedupingInterval: 60000
};

export const useFetchBenefitPeriodType = (httpGet: TGet): IStatus & { benefitPeriodTypes: BenefitPeriodType[] } => {
   const { data, isLoading, error } = useSWR<BenefitPeriodType[], HttpError>(
      benefitPeriodTypeBaseUrl,
      httpGet,
      { ...swrOptions }
   );

   return {
      benefitPeriodTypes: data,
      isLoading: isLoading,
      error: error?.message
   };
}

export const useBenefitPeriodTypeOptions = (httpGet: TGet): { benefitPeriodTypeOptions: IOptionItem[] } => {
   const { benefitPeriodTypes, error } = useFetchBenefitPeriodType(httpGet);

   const options = React.useMemo(() => {
      if (!error && benefitPeriodTypes?.length > 0) {
         const tempOptions = benefitPeriodTypes.map(y => {
            return {
               label: y.benefitPeriodDescription,
               value: y.id,
               key: y.id
            } as IOptionItem
         });
         return tempOptions;
      }
      return [];
   }, [benefitPeriodTypes, error])

   return {
      benefitPeriodTypeOptions: options
   }
}