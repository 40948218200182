import { IOptionItem } from '../../functions/option.functions';

export interface ManualEnrollmentReasonOption {
    id: number;
    optionName: string;
    isFreeText: boolean;
    sequence: number;
    connectivityType: number;
}

export interface ManualEnrollmentReasonOptionViewModel extends ManualEnrollmentReasonOption {
    connectivityTypeDesc: string;
}

export const connTypeList: IOptionItem[] = [
    {
        key: 0,
        label: '[0]: Manual',
        value: 0
    } as IOptionItem
    ,
    {
        key: 1,
        label: '[1]: Outbound Integration',
        value: 1
    } as IOptionItem
    ,
    {
        key: 2,
        label: '[2]: Digital Integration - Calling 1.3 from 1.5',
        value: 2
    } as IOptionItem
    ,
    {
        key: 3,
        label: '[3]: Digital Integration - Native 1.5',
        value: 3
    } as IOptionItem
    ,
    {
        key: 4,
        label: '[4]: Assisted Enrollment (RPA)',
        value: 4
    } as IOptionItem
];
