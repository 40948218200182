import useSWR, { mutate } from 'swr';
import { HttpError, TGet, TPost } from '../../functions/httpClient';
import { Interaction } from './InteractionModel';

export const interactionBaseUrl = `/api/Interaction`;

const swrOptions = {
    refreshInterval: 0,
    revalidateOnFocus: false,
    dedupingInterval: 60000
};

// FYI - this endpoint only returns Interactions with Type 16 ("App Status Note") as those are the only Interactions that can be created/viewed within AdminPortal
export const useInteractionList = (httpGet: TGet) => {
    const { data, isValidating, error } = useSWR<Interaction[], HttpError>(
        interactionBaseUrl,
        httpGet,
        { ...swrOptions }
    );

    return {
        interactionList: data,
        isLoading: !error && !data && isValidating,
        error: error
    };
}

export const saveInteraction = async (httpPost: TPost, interactionToSave: Interaction) => {
    const update = await httpPost<Interaction>(interactionBaseUrl, interactionToSave);
    mutate(interactionBaseUrl);
    return update;
}