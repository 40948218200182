import * as React from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Dialog from '../../Dialog';
import { SaveButton, DeleteButton, CancelButton } from '../../shared/Buttons';
import Spinner from '../../Spinner';
import { useFetchAssistancePrograms } from '../../../store/program/AssistanceProgramFetcher';
import { AssistanceProgramResource } from '../../../store/program/AssistanceProgramResourceModel';
import {
   useFetchAssistanceProgramResources,
   useAssistanceProgramResourceCategoryOptions,
   saveAssistanceProgramResource,
   deleteAssistanceProgramResource,
   assistanceProgramResourceBaseUrl
} from '../../../store/program/AssistanceProgramResourceFetcher';
import ApiErrorDisplay from '../../ApiErrorDisplay';
import { Form } from 'antd';
import { InputField, DropdownField, RadioListField, TextAreaField } from '../../shared/InputLibrary';
import { IOptionItem } from '../../../functions/option.functions';
import { TitleError } from '../../shared/AntComponents/Typography/Title';
import { useApiContext } from '../../../store/ApiContext';
import { useErrorContext } from '../../../store/ErrorContext';

interface IProps {
   isOpen: boolean;
   closeEditor: (programId?: number, resourceId?: number) => void;
   assistanceProgramId: number;
   assistanceProgramResourceId?: number;
};

const dialogContentStyle = {
   height: '580px',
   maxHeight: '95vh',
}

const yupAssistanceProgramResourceSchema = yup.object({
   id: yup.number().notRequired(),
   assistanceProgramId: yup.number()
      .required('Assistance Program Id is required'),
   type: yup.number()
      .required('Type is required'),
   url: yup.string()
      .url('The URL must be a fully-qualified url that begins with "https://" or "https://"')
      .required('Url is required')
      .max(1024, 'Max length is 1024 characters'),
   description: yup.string()
      .required('Description is required')
      .max(1024, 'Max length is 1024 characters'),
   assistanceProgramResourceCategoryId: yup.number().required()
});

interface IAssistanceProgramResourceYup extends yup.Asserts<typeof yupAssistanceProgramResourceSchema> { }
const __formId = "frmAssistanceProgramResource";
const _keysLike: string[] = [assistanceProgramResourceBaseUrl];

const AssistanceProgramResourceEditor: React.FC<IProps> = (props) => {
   const { assistanceProgramId, closeEditor, isOpen, assistanceProgramResourceId} = props;
   const { httpGet, httpPost, httpDelete } = useApiContext();
   const { assistanceProgramResources } = useFetchAssistanceProgramResources(httpGet);
   const { assistancePrograms } = useFetchAssistancePrograms(httpGet);
   const [isFormSaving, setIsFormSaving] = React.useState<boolean>(false);
   const { removeErrors } = useErrorContext();
   const clearErrors = () => {
      removeErrors({ keysLike: _keysLike });
   }

   const {
      control,
      handleSubmit,
      formState: { errors },
      reset
   } = useForm<IAssistanceProgramResourceYup>({
      resolver: yupResolver<yup.AnyObject>(yupAssistanceProgramResourceSchema),
      shouldFocusError: true,
   });

   const assistanceProgramResourceToEdit = React.useMemo(() => {
      return assistanceProgramResources?.find(y => y.id === assistanceProgramResourceId)
   }, [assistanceProgramResourceId, assistanceProgramResources]);

   const assistanceProgram = React.useMemo(() => {
      return assistancePrograms?.find(y => y.id === assistanceProgramId)
   }, [assistanceProgramId, assistancePrograms]);
   
   //assistanceProgramId
   React.useEffect(() => {
      reset(assistanceProgramResourceToEdit ?? {
         assistanceProgramId: assistanceProgramId,
         assistanceProgramResourceCategoryId: null
      } as AssistanceProgramResource);
      setIsFormSaving(false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [assistanceProgramResourceToEdit, assistanceProgramId, isOpen]);

   const { categoryOptions } = useAssistanceProgramResourceCategoryOptions(httpGet);

   const typeOptions = [
      {
         key: 0,
         value: 0,
         label: 'Enrollment Link',
      } as IOptionItem,
      {
         key: 1,
         value: 1,
         label: 'More Information',
      } as IOptionItem
   ];

   const onSubmit = (data: IAssistanceProgramResourceYup): void => {
      //console.log('--------------AssistanceProgramResourceEditor-------------------------')
      //console.log(errors)

      //ts hoops to convert data with unknown props into assistance programs
      setIsFormSaving(true);
      const assistanceProgramResource = data as unknown as AssistanceProgramResource;
      saveAssistanceProgramResource(httpPost, assistanceProgramResource)
         .then(() => {
            handleClose();
         })
         .catch(err => console.log(`Error Saving`, err))
         .finally(() => {
            setIsFormSaving(false);
         });
   }

   const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState<boolean>(false);

   const handleDeleteClick = () => {
      setIsDeleteDialogOpen(false);
      deleteAssistanceProgramResource(httpDelete, assistanceProgramResourceToEdit.id)
         .then(handleClose)
         .catch(err => console.log(`Error Saving`, err));
   }

   const handleClose = () => {
      setIsFormSaving(false);
      setIsDeleteDialogOpen(false);
      clearErrors();
      closeEditor();
   }

   if (isFormSaving) {
      return <Spinner message='Saving Assistance Program Resource...' />
   }

   //console.log('--------------AssistanceServcieEditor-------------------------')
   //console.log(errors)


   const html = (
      <Dialog
         scrollingContent={true}
         title={`Assistance Program Resource Editor for ${assistanceProgram?.programName}`}
         open={isOpen}
         size="large"
         pending={isFormSaving}
         actionButtons={
            <>
               <CancelButton
                  onClick={() => handleClose()}
                  loading={isFormSaving}
               />
               <DeleteButton
                  onClick={() => setIsDeleteDialogOpen(true)}
                  disabled={!assistanceProgramResourceToEdit || !assistanceProgramResourceToEdit?.id || isDeleteDialogOpen}
                  loading={isFormSaving} />
               <SaveButton
                  formId={__formId}
                  disabled={Object.keys(errors).length > 0}
                  loading={isFormSaving} />
            </>
         }>
         <div style={dialogContentStyle}>

            {errors && Object.keys(errors).length > 0 &&
               <>
                  <TitleError text='Please correct the errors below' />
                  {console.log('--------------Form Errors: -------------------------')}
                  {console.log(errors)}
               </>
            }

            <ApiErrorDisplay
               title='Error saving Assistance Program Resource'
               keysLike={_keysLike}
            />

            <Form id={__formId} onFinish={handleSubmit(onSubmit)}>
               <RadioListField
                  control={control}
                  name='type'
                  label='type'
                  error={errors?.type}
                  options={typeOptions}
                  required={true}
               />
               <InputField
                  control={control}
                  name='url'
                  label='url'
                  type='text'
                  error={errors?.url}
                  required={true}
               />
               <TextAreaField
                  control={control}
                  name='description'
                  label='Description'
                  error={errors?.description}
                  required={true}
                  rows={1}
               />
               <DropdownField
                  control={control}
                  name='assistanceProgramResourceCategoryId'
                  label='assistanceProgramResourceCategoryId'
                  error={errors?.assistanceProgramResourceCategoryId}
                  multiple={false}
                  options={categoryOptions}
                  required={true}
               />
            </Form>
         </div>

         <Dialog
            title='CONFIRM DELETE'
            open={isDeleteDialogOpen}
            style={{ maxWidth: '550px' }}
            actionButtons={
               <>
                  <CancelButton
                     onClick={() => setIsDeleteDialogOpen(false)}
                     disabled={isFormSaving}/>
                  <DeleteButton
                     disabled={isFormSaving}
                     onClick={() => handleDeleteClick()} />
               </>
            }
         >
            {`Are you sure you want to Delete the Assistance Program Resource (${assistanceProgramResourceToEdit?.id}) ${assistanceProgramResourceToEdit?.description || ''}?`}
         </Dialog>

      </Dialog>
   )
   return html;
}

export default AssistanceProgramResourceEditor;
