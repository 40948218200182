import { Alert, Divider } from 'antd';
import * as React from 'react';
import { BasicInputField } from '../../shared/BasicInputLibrary';
import FilteredUserList from '../FilteredUserList';
import { AddButton } from '../../shared/Buttons';
import CopyWithPracticeUserProfileEditor from './CopyWithPracticeUserProfileEditor';

interface IProps {
   practiceId?: number;
   setIsUserDetailOpen?: (val: boolean) => void;
   setUserProfileId?: (val: number) => void;
};

const NewUserSearchView: React.FC<IProps> = (props) => {
   const { practiceId } = props;
   const [searchText, setSearchText] = React.useState<string>();
   const [userMainId, setUserMainId] = React.useState<number>();
   const [isUserProfileEditorOpen, setIsUserProfileEditorOpen] = React.useState<boolean>(false);
   const [copyFromUserProfileId, setCopyFromUserProfileId] = React.useState<number>(undefined);
   const onNewUserProfileFromCopy = (userProfileId: number, userMainId: number) => {
      setCopyFromUserProfileId(userProfileId);
      setUserMainId(userMainId);
      setIsUserProfileEditorOpen(true);
   }

   const onNewUserProfileFromEmail = (userMainId: number) => {
      setUserMainId(userMainId);
      setIsUserProfileEditorOpen(true);
      // Just in case...
      setCopyFromUserProfileId(undefined);
   }

   const onNetNewPracticeUser = () => {
      setIsUserProfileEditorOpen(true);
      setCopyFromUserProfileId(undefined);
      setUserMainId(undefined);
   }

   const onUserProfileEditorClose = () => {
      setIsUserProfileEditorOpen(false);
      setCopyFromUserProfileId(undefined);
      setUserMainId(undefined);
   }

   const onNewUserMain = (userProfileId?: number) => {
      props.setIsUserDetailOpen(true);
      if (userProfileId)
         props.setUserProfileId(userProfileId);
   }

   return (
      <div style={{ padding: 10 }}>

         <div style={{ paddingTop: 15, paddingBottom: 15 }}>
            <Alert
               type="info"
               message='Enter a search string to see if the user exists before creating a new account'
            />
         </div>

         <BasicInputField
            autoFocus={true}
            label='Search String'
            type="text"
            name="searchString"
            maxLength={256}
            required={true}
            value={searchText}
            onChange={(e) => setSearchText(String(e))}
         />
         <Divider />
         <FilteredUserList
            searchText={searchText}
            practiceId={practiceId}
            onNewUserProfileFromCopy={onNewUserProfileFromCopy}
            onNewUserProfileFromEmail={onNewUserProfileFromEmail}
            onNewUserMain={onNewUserMain}
         />
         {searchText && // Users must search before they can add net new
            <>
               <Divider />
               <AddButton
                  buttonText={'Create New User'}
                  onClick={() => {
                     if (practiceId) {
                        onNetNewPracticeUser();
                     } else {
                        onNewUserMain();
                     }
                  }}
               />
            </>
         }
         {isUserProfileEditorOpen && <CopyWithPracticeUserProfileEditor
            copyFromUserProfileId={copyFromUserProfileId}
            isEditorOpen={isUserProfileEditorOpen}
            onCloseEditor={onUserProfileEditorClose}
            practiceId={practiceId}
            userMainId={userMainId}
            />
         }

      </div>


   );

}
export default NewUserSearchView;

