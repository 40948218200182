import { useEffect, useMemo, useState } from 'react';
import useSWR, { mutate } from 'swr'
import { groupBy } from '../../functions/common.functions';
import { HttpError, TGet, TPut } from '../../functions/httpClient';
import { getKey } from '../models';
import { powerBIExternalReportBaseUrl } from './PowerBIExternalReportFetcher';
import { PowerBIReport, EmbedReportConfig, PowerBIReportVM } from './ReportModel'
import { View_Browse_Certified, View_Browse_Uncertified } from './ReportFunctions';

export const reportBaseUrl = `/api/rpt/PowerBi`;
const reportListUrl = `${reportBaseUrl}/GetReportList`;
export const embedReportUrl = (workspaceId: string, reportId: string) => `${reportBaseUrl}/EmbedReport/${workspaceId}/${reportId}`;
const updateInternalOrderUrl = `${reportBaseUrl}/UpdateInternalOrder`;
export const updateReportUrl = `${reportBaseUrl}/Update`;

const swrOptions = {
   refreshInterval: 0,
   revalidateOnFocus: false,
   dedupingInterval: 60000     //default 2000
};

export const useFetchReportList = (httpGet: TGet) => {

   const { data, isLoading, error } = useSWR<PowerBIReport[], HttpError>(
      reportListUrl,
      httpGet,
      { ...swrOptions }
   );

   return {
      powerBIReports: data,
      isLoading: isLoading,
      error: error
   };
}

export const useReportList = (httpGet: TGet) => {
   const { powerBIReports, isLoading, error } = useFetchReportList(httpGet);

   const models: PowerBIReportVM[] = useMemo(() => {
      return powerBIReports?.map(r => {
         const certifiedValue = r.isCertified ? View_Browse_Certified : View_Browse_Uncertified;
         return {
            ...r,
            viewHeadingKey: getKey(`${certifiedValue}_${r.heading}`),
            viewHeadingReportPageGroupKey: getKey(`${certifiedValue}_${r.heading}_${r.reportPageGroup}`),
         } as PowerBIReportVM;
      })
   }, [powerBIReports])

   return {
      powerBIReports: models,
      isLoading: isLoading,
      error: error
   };
}

export const useEmbeddedReport = (httpGet: TGet, workspaceId: string, reportId: string) => {
   const shouldFetch = workspaceId && reportId;
   const { data, isLoading, error } = useSWR<EmbedReportConfig, HttpError>(
      shouldFetch ? embedReportUrl(workspaceId, reportId) : null,
      httpGet,
      {
         ...swrOptions,
         refreshInterval: 600000 //10 minutes
      }
   );

   return {
      embeddedReport: data,
      isLoading: isLoading,
      error: error
   };
}

export const updateInternalOrder = async (httpPut: TPut, orderedItems: number[]) => {
   const result = await httpPut(updateInternalOrderUrl, orderedItems)
      .then(() => {
         mutate(reportListUrl);
         mutate(powerBIExternalReportBaseUrl);
      });
   return result;
}

export const savePowerBIReport = async (httpPut: TPut, report: PowerBIReport) => {
   const result = await httpPut(updateReportUrl, report)
      .then(() => {
         mutate(reportListUrl);
         mutate(powerBIExternalReportBaseUrl);
      });
   return result;
}

export const useWorkspaceTree = (httpGet: TGet) => {
   const { powerBIReports, isLoading, error } = useReportList(httpGet);

   const workspaceTree = useMemo(() => {
      if (powerBIReports) {
         const tree = powerBIReports?.length > 0 ?
            groupBy(powerBIReports, y => getKey(y.workspaceName)) :
            undefined;

         return tree;
      }
      return undefined;

   }, [powerBIReports]);

   return {
      workspaceTree,
      isLoading,
      error
   };
}

export const useWorkspacePages = (httpGet: TGet, workspaceKey: string) => {

   const { workspaceTree, isLoading, error } = useWorkspaceTree(httpGet);
   
   const workspacePages = useMemo(() => {
      if (workspaceKey && workspaceTree) {
         const pages = workspaceTree[workspaceKey]
         if (pages?.length > 0)
            return pages;
      }
      return [];
   }, [workspaceKey, workspaceTree]);

   return {
      workspacePages: workspacePages,
      isLoading,
      error
   };
}


export const useHeadingReportNameTree = (httpGet: TGet, certified: boolean) => {
   const { powerBIReports, isLoading, error } = useReportList(httpGet);

   const headingReportNameTree = useMemo(() => {
      if (powerBIReports) {
         const preFilteredReports = powerBIReports?.filter(y =>
            (certified && y.isCertified) ||
            (!certified && !y.isCertified));

         const tree = preFilteredReports?.length > 0 ?
            groupBy(preFilteredReports, y => y.viewHeadingReportPageGroupKey) :
            undefined;

         return tree;
      }
      return undefined;
   }, [powerBIReports, certified]);

   return {
      headingReportNameTree,
      isLoading,
      error
   }
}