import { useEffect, useState } from 'react';
import { HttpError, TGet } from '../../functions/httpClient';
import { IOptionItem } from '../../functions/option.functions';
import { IStatus } from '../models';
import useSWR from 'swr';
import { Diagnosis } from './DiagnosisModel';
import { stringComparer } from '../../functions/comparer.functions';

export const diagnosisBaseUrl = `api/program/Diagnosis`;

const swrOptions = {
   refreshInterval: 0,
   revalidateOnFocus: false,
   dedupingInterval: 60000
};


export function useFetchDiagnosisCodes(httpGet: TGet): IStatus & { diagnosisCodes: Diagnosis[] } {
   const { data, isValidating, error } = useSWR<Diagnosis[], HttpError>(
      diagnosisBaseUrl,
      httpGet,
      { ...swrOptions }
   );

   return {
      diagnosisCodes: data,
      isLoading: !error && !data && isValidating,
      error: error?.message
   };
}

export function useDiagnosisCodeOptions(httpGet: TGet): { diagnosisCodeOptions: IOptionItem[] } {
   const { diagnosisCodes } = useFetchDiagnosisCodes(httpGet);
   const [diagnosisCodeOptions, setDiagnosisCodeOptions] = useState<IOptionItem[]>([]);

   useEffect(() => {
      const buildDiseaseTypeOptions = () => {
         if (diagnosisCodes?.length > 0) {
            const sorted = [...diagnosisCodes];

            sorted.sort((a: Diagnosis, b: Diagnosis) => stringComparer(String(a.code), String(b.code)));
            sorted.sort((a: Diagnosis, b: Diagnosis) => stringComparer(String(a.description), String(b.description)));

            const diagnosisCodeOptions = sorted.map((diagnosisCode): IOptionItem => ({
               key: diagnosisCode.id,
               label: `[${diagnosisCode.code}] ${diagnosisCode.description}`,
               value: `${diagnosisCode.code}`
            } as IOptionItem));
            setDiagnosisCodeOptions(diagnosisCodeOptions);
         }
      }
      buildDiseaseTypeOptions();
   }, [diagnosisCodes]);

   return {
      diagnosisCodeOptions
   };
}