import { useEffect, useState } from 'react';
import useSWR, { mutate } from 'swr';
import { stringComparer } from '../../functions/comparer.functions';
import { getFormattedDateForApi } from '../../functions/format.functions';
import { HttpError, TDelete, TGet, TPost } from '../../functions/httpClient';
import { IOptionItem } from '../../functions/option.functions';
import { IStatus, NameAndId } from '../models';
import { Cohort } from './CohortModel';
import { findCohortSummaryUrl } from './CohortSummaryFetcher';

export const cohortBaseUrl = `api/cohort`;
export const cohortDeleteUrl = (id: number) => `${cohortBaseUrl}/delete/${id}`;
export const cohortRefreshUrl = (id: number) => `${cohortBaseUrl}/refresh/${id}`;
export const cohortPublishUrl = (id: number) => `${cohortBaseUrl}/publish/${id}`;
export const cohortSaveUrl = `${cohortBaseUrl}/save`;
export const cohortRefreshAllUrl = `${cohortBaseUrl}/refreshAll`;
export const getCohortTypesUrl = `${cohortBaseUrl}/getCohortTypes`;

const swrOptions = {
   refreshInterval: 0,
   revalidateOnFocus: false,
   dedupingInterval: 60000
};

export const deleteCohort = async (httpDelete: TDelete, id: number, fromDate: Date) => {
   const result = await httpDelete(cohortDeleteUrl(id));
   mutate([findCohortSummaryUrl, { fromDate: getFormattedDateForApi(fromDate) }]);
   return result;
}

export const refreshActiveCohorts = (httpPost: TPost, fromDate: Date) => {
   const result = httpPost(cohortRefreshAllUrl);
   mutate([findCohortSummaryUrl, { fromDate: getFormattedDateForApi(fromDate) }]);
   return result;
}

export const refreshCohort = async (httpPost: TPost, id: number, fromDate: Date) => {
   const result = await httpPost(cohortRefreshUrl(id));
   mutate([findCohortSummaryUrl, { fromDate: getFormattedDateForApi(fromDate) }]);
   return result;
}

export const publishCohort = async (httpPost: TPost, id: number, fromDate: Date) => {
   const result = await httpPost(cohortPublishUrl(id));
   mutate([findCohortSummaryUrl, { fromDate: getFormattedDateForApi(fromDate) }]);
   return result;
}

export const saveCohort = async (httpPost: TPost, cohort: Cohort, fromDate: Date) => {
   const result = await httpPost(cohortSaveUrl, cohort);
   mutate([findCohortSummaryUrl, { fromDate: getFormattedDateForApi(fromDate) }]);
   return result;
}

export function useFetchCohortTypes(httpGet: TGet): IStatus & { cohortTypes: NameAndId[] } {
   const { data, isValidating, error } = useSWR<NameAndId[], HttpError>(
      getCohortTypesUrl,
      httpGet,
      { ...swrOptions }
   );

   return {
      cohortTypes: data,
      isLoading: !error && !data && isValidating,
      error: error?.message
   };
}

export function useCohortTypesOptions(httpGet: TGet): { cohortTypeOptions: IOptionItem[] } {
   const { cohortTypes } = useFetchCohortTypes(httpGet);
   const [cohortTypeOptions, setCohortTypeOptions] = useState<IOptionItem[]>([]);

   useEffect(() => {
      const buildOptions = () => {
         if (cohortTypes?.length > 0) {

            const options = cohortTypes.map((o): IOptionItem => ({
               label: `[${o.id}]: ${o.name}`,
               value: o.id,
            } as IOptionItem));
            options.sort((a: IOptionItem, b: IOptionItem) => stringComparer(a.label as string, b.label as string));
            setCohortTypeOptions(options);
         }
      }
      buildOptions();
   }, [cohortTypes]);

   return {
      cohortTypeOptions
   };
}
