import * as React from 'react';
import { colorWheel } from '../../Theme/ColorWheel';
import CustomIcon, { CustomIconType } from './AntComponents/CustomIcon';
import { Button } from 'antd';

export const buttonStyles = {
   cancel: { borderColor: colorWheel.mediumGrey, color: colorWheel.black },
   delete: { background: colorWheel.red, borderColor: colorWheel.lightGrey, color: colorWheel.white },
   action: { background: colorWheel.lightGrey, borderColor: colorWheel.tangerineOrange, color: colorWheel.tangerineOrange },
   actionDanger: { background: colorWheel.lightGrey, borderColor: colorWheel.red, color: colorWheel.red },
   edit: { background: colorWheel.lightGrey, borderColor: colorWheel.tangerineOrange, color: colorWheel.tangerineOrange }
};

interface IButtonProps {
   buttonText?: string;
   onClick?: (event?: React.MouseEvent<HTMLAnchorElement, MouseEvent>
      | React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
   disabled?: boolean;
   loading?: boolean;
   hasIcon?: boolean;
   style?: React.CSSProperties;
   title?: string;
   displayNoText?: boolean; // Currently only implemented on buttons with default text (Cancel, Delete, Save)
}

export const IconButton: React.FC<IButtonProps & { icon?: React.ReactNode }> = (props) => {
   const { onClick, disabled, icon, loading, style, title } = props;
   
   const defaultStyle = { maxWidth: '25px', border: 'none' };

   const innerStyle = style ? { ...defaultStyle, ...style } : defaultStyle

   return <Button
      ghost={true}
      type='primary'
      icon={icon}
      loading={loading ?? false}
      disabled={disabled}
      onClick={() => onClick ? onClick() : null}
      style={{ ...innerStyle }}
      title={title}>
      </Button>;
}

export const CancelButton: React.FC<IButtonProps> = (props) => {
   const { onClick, buttonText, disabled, loading, style, title, displayNoText, hasIcon } = props;

   const defaultIcon = <CustomIcon type={CustomIconType.StopOutlined} style={{ color: colorWheel.red }} />

   const btn = React.useRef(null);

   if (displayNoText) {
      return <IconButton {...props} icon={defaultIcon} />;
   } else {
      return <Button
         ref={btn}
         ghost
         type='primary'
         loading={loading ?? false}
         icon={hasIcon || hasIcon === undefined ? defaultIcon : null}
         disabled={disabled}
         onClick={(event?: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => onClick ? onClick(event) : undefined}
         onMouseEnter={() => btn.current.style.opacity = '.5'}
         onMouseLeave={() => btn.current.style.opacity = '1'}
         style={{ ...style, color: colorWheel.textGrey, borderColor: colorWheel.mediumGrey }}
         title={title}
      >
         {!displayNoText &&
            <b>
               <span style={{ paddingRight: 10 }} />
               {buttonText ?? 'Cancel'}
            </b>
         }
      </Button>;
   }
}

export const DeleteButton: React.FC<IButtonProps> = (props) => {
   const { onClick, buttonText, disabled, loading, style, title, displayNoText, hasIcon } = props;

   const defaultIcon = <CustomIcon type={CustomIconType.DeleteFilled} />

   if (displayNoText) {
      return <IconButton {...props} icon={defaultIcon} />;
   } else {
      return <Button
         danger
         htmlType='submit'
         type='primary'
         loading={loading ?? false}
         icon={props?.hasIcon || props?.hasIcon === undefined ? defaultIcon : null}
         disabled={disabled}
         onClick={(event?: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => onClick ? onClick(event) : undefined}
         style={{ ...style }}
         title={title}
      >
         {!displayNoText &&
            <b>
               <span style={{ paddingRight: 10 }} />
               {buttonText ?? 'Delete'}
            </b>
         }
      </Button>
   }
}

export const SaveButton: React.FC<IButtonProps & { formId?: string }> = (props) => {
   const { onClick, buttonText, disabled, loading, style, title, displayNoText, hasIcon, formId } = props;

   const defaultIcon = <CustomIcon type={CustomIconType.SaveFilled} />;

   if (displayNoText) {
      return <IconButton {...props} icon={defaultIcon} />
   } else {

      return <Button
         htmlType='submit'
         type='primary'
         form={formId ?? null}
         loading={loading ?? false}
         icon={hasIcon || hasIcon === undefined ? defaultIcon : null}
         disabled={disabled}
         onClick={(event?: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => onClick ? onClick(event) : undefined}
         style={{ ...style }}
         title={title}
      >
         {!displayNoText &&
            <b>
               <span style={{ paddingRight: 10 }} />
               {buttonText ?? 'Save'}
            </b>
         }

      </Button>;
   }
}

export const ActionButton: React.FC<IButtonProps & { icon?: React.ReactNode, danger?: boolean, filled?: boolean }> = (props) => {

   const { onClick, buttonText, danger, disabled, filled, icon, loading, style, title, displayNoText, hasIcon } = props;

   if (displayNoText || !buttonText) {
      return <IconButton {...props} />
   } else {
      return <Button
         ghost={!filled}
         danger={danger}
         type='primary'
         icon={icon}
         loading={loading ?? false}
         disabled={disabled}
         onClick={(event?: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => onClick ? onClick(event) : undefined}
         style={{ ...style }}
         title={title}
      >
         <b>
            {buttonText && icon &&
               <span style={{ paddingRight: 10 }} />
            }
            {buttonText}
         </b>
      </Button>;
   }
}

export const AddButton: React.FC<IButtonProps> = (props) => {
   const { onClick, buttonText, disabled, loading, style, title, displayNoText } = props;

   const defaultIcon = <CustomIcon type={CustomIconType.PlusOutlined} />;

   if (displayNoText) {
      return <IconButton {...props} icon={defaultIcon} />;
   } else {
      return <Button
         ghost
         type='primary'
         icon={defaultIcon}
         loading={loading ?? false}
         disabled={disabled}
         onClick={(event?: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => onClick ? onClick(event) : undefined}
         style={{ ...style }}
         title={title}
      >
         {buttonText &&
            <b>
               <span style={{ paddingRight: 10 }} />
               {buttonText}
            </b>
         }
      </Button>;
   }
}

export const EditButton: React.FC<IButtonProps & { icon?: React.ReactNode }> = (props) => {
   const { onClick, buttonText, disabled, icon, loading, style, title, displayNoText } = props;

   const localIcon = icon ? icon : <CustomIcon type={CustomIconType.EditOutlined} />;

   if (displayNoText) {
      return <IconButton {...props} icon={localIcon} />;
   } else {
      return <Button
         ghost
         type='primary'
         loading={loading ?? false}
         icon={localIcon}
         disabled={disabled}
         onClick={(event?: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => onClick ? onClick(event) : undefined}
         style={{ ...style, border: 'none' }}
         title={title ?? 'Edit'}
      >
         {buttonText &&
            <b>
               <span style={{ paddingRight: 10 }} />
               {buttonText}
            </b>
         }
      </Button>;
   }
}