import { useMemo } from 'react';
import { useFetchDrug } from './DrugFetcher';
import { HttpError, TPost, TDelete, TGet } from '../../functions/httpClient';
import { IStatus } from '../models';
import useSWR, { mutate } from 'swr';
import { Ndc, NdcViewModel } from './NdcModel';

export const ndcBaseUrl = `api/Ndc`;
export const ndcSaveUrl = `${ndcBaseUrl}/Save`;
export const ndcDeleteUrl = (id: number) => `${ndcBaseUrl}/Delete/${id}`;

const swrOptions = {
   refreshInterval: 0,
   revalidateOnFocus: false,
   dedupingInterval: 60000
};


export function useFetchNdc(httpGet: TGet): IStatus & { ndcList: Ndc[] } {
   const { data, isValidating, error } = useSWR<Ndc[], HttpError>(
      ndcBaseUrl,
      httpGet,
      { ...swrOptions }
   );

   return {
      ndcList: data,
      isLoading: !error && !data && isValidating,
      error: error?.message
   };
}

export const saveNdc = async (httpPost: TPost, ndc: Ndc) => {
   const result = await httpPost(ndcSaveUrl, ndc)
      .then(() => mutate(ndcBaseUrl));
   return result;
}

export const deleteNdc = async (httpDelete: TDelete, id: number) => {
   const result = await httpDelete(ndcDeleteUrl(id))
      .then(() => mutate(ndcBaseUrl));
   return result;
}

export function useNdcViewModel(httpGet: TGet): IStatus & { ndcViewModel: NdcViewModel[] } {
   const { ndcList, isLoading, error, isSaving } = useFetchNdc(httpGet);
   const { drugs, isLoading: isLoading_d, error: error_d } = useFetchDrug(httpGet);

   const viewModel = useMemo(() => {
      if (ndcList?.length > 0 && drugs?.length > 0) {
         let tempList = ndcList.map((item): NdcViewModel => {
            const d = drugs.find(y => y.id === item.drugId);

            return {
               ...item,
               drugName: d?.drugName,
            } as NdcViewModel;
         });
         return tempList;
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [ndcList, drugs]);


   return {
      ndcViewModel: viewModel,
      isLoading: isLoading || isLoading_d,
      error: error ?? error_d,
      isSaving
   };
}
