import * as React from 'react';
import { useReports } from './ReportContext';
import { ReportViewEmbedded } from './ReportViewEmbedded';

export const ReportView: React.FC = () => {
   const [lastReport, setLastReport] = React.useState<number>();

   const { powerBIReportId, currentReport, reportRendered, setReportRendered } = useReports();

   //timer to work around for localhost not firing loaded & rendered events
   const _timeoutRef_Events = React.useRef(null);
   React.useEffect(() => {
      (async () => {
         //on localhost, events do not fire, which will not load report menu
         //here we set to a longer value - don't want to uncover the production too soon
         if (powerBIReportId && powerBIReportId !== lastReport) {
            setLastReport(powerBIReportId);
            _timeoutRef_Events.current = setTimeout(() => {
               _timeoutRef_Events.current = null;
               if (!reportRendered) {
                  setReportRendered(true);
               }
            }, 5000);
         }
      })();      
   },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [powerBIReportId, reportRendered]);

   if (!currentReport) {
      return <div className='reportoverlay' />
   }

   const html = (
      <ReportViewEmbedded
         workspaceId={currentReport?.workspaceId}
         reportId={currentReport?.reportId}
         pageId={currentReport?.reportPageId}
         setReportRendered={setReportRendered}
      />
   )

   return html;
}
