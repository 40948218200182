import { HttpError, TPost, TDelete, TGet } from '../../functions/httpClient';
import { IStatus } from '../models';
import useSWR, { mutate } from 'swr';
import { Cpt, CptClassification } from './CptModel';

export const cptBaseUrl = `api/Cpt`;
export const cptSaveUrl = `${cptBaseUrl}/Save`;
export const cptDeleteUrl = (code: string) => `${cptBaseUrl}/Delete/${code}`;
const cptGetClassificationUrl = `${cptBaseUrl}/GetClassification`;

const swrOptions = {
   refreshInterval: 0,
   revalidateOnFocus: false,
   dedupingInterval: 60000
};


export function useFetchCpt(httpGet: TGet): IStatus & { cptList: Cpt[] } {
   const { data, isValidating, error } = useSWR<Cpt[], HttpError>(
      cptBaseUrl,
      httpGet,
      { ...swrOptions }
   );

   return {
      cptList: data,
      isLoading: !error && !data && isValidating,
      error: error?.message
   };
}

export const saveCpt = async (httpPost: TPost, cpt: Cpt) => {
   const result = await httpPost(cptSaveUrl, cpt)
      .then(() => mutate(cptBaseUrl));
   return result;
}

export const deleteCpt = async (httpDelete: TDelete, code: string) => {
   const result = await httpDelete(cptDeleteUrl(code))
      .then(() => mutate(cptBaseUrl));
   return result;
}


export function useFetchCptClassifications(httpGet: TGet): IStatus & { cptClassifications: CptClassification[] } {
   const { data, isValidating, error } = useSWR<CptClassification[], HttpError>(
      cptGetClassificationUrl,
      httpGet,
      { ...swrOptions }
   );

   return {
      cptClassifications: data,
      isLoading: !error && !data && isValidating,
      error: error?.message
   };
}
