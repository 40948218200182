import { Space, Tooltip } from 'antd';
import { ColumnsType, TableProps } from 'antd/lib/table';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import ChangePasswordButton from '../../../auth/ChangePasswordButton';
import Restricted from '../../../auth/Restricted';
import { boolComparer, dateComparer, numberComparer, stringComparer } from '../../../functions/comparer.functions';
import { validateEmail } from '../../../functions/form.validators';
import { formatBoolean, formatShortDateString } from '../../../functions/format.functions';
import { IOptionItem, sortOptionsByDistinctLabel } from '../../../functions/option.functions';
import { useApiContext  } from '../../../store/ApiContext';
import { usePracticeOptions } from '../../../store/practice/PracticeFetcher';
import { userProfileBaseUrl, useUserProfileViewModels } from '../../../store/practice/UserProfileFetcher';
import { formatPractice, formatUserId, UserProfileViewModel } from '../../../store/practice/UserProfileModel';
import { useFetchUserRoles, useUserRoleOptions } from '../../../store/practice/UserRoleFetcher';
import { KnownSettings } from '../../../store/SettingsModel';
import ApiErrorDisplay from '../../ApiErrorDisplay';
import CustomIcon, { CustomIconType } from '../../shared/AntComponents/CustomIcon';
import ColumnFilter from '../../shared/AntComponents/Filter/ColumnFilter';
import { IFilteredInfo } from '../../shared/AntComponents/Filter/FilteredInfo';
import MultiSelectColumnFilter from '../../shared/AntComponents/Filter/MultiSelectColumnFilter';
import { SearchResultsTableWithFilters } from '../../shared/AntComponents/Table/SearchResultsTableWithFilters';
import { ActionButton, EditButton } from '../../shared/Buttons';
import { isDateAfter } from 'src/functions/time.functions';
import ExistingUserProfileEditor from '../../user/shared/ExistingUserProfileEditor';
import { HttpVerb, KeyWithVerb } from '../../../store/ErrorContext';
import { antSortOptions } from '../../shared/AntComponents/Table/table.functions';

export const insightlyContactsLastCheckedRouteValue = 'fsa-lastchecked'
export const insightlyContactsLastApiActivityRouteValue = 'fsa-lastapiactivity';
export const insightlyContactsEmailAddressRouteValue = 'fsa-bademail';

const USER_PROFILE_ID_TITLE = 'UserId';
const PRACTICE_ID_TITLE = 'PracticeId';
const UPN_TITLE = 'User Upn';
const EXTERNAL_FULL_NAME_TITLE = 'AzureName';
const ADMIN_TITLE = 'Admin';
const DISABLED_TITLE = 'Disabled';
const USER_ROLE_TITLE = 'User Role';
const USER_PROFILE_EMAIL_ADDRESS_TITLE = 'User Profile Email Address';
const FUND_STATUS_ALERT_TITLE = 'FundStatusAlerts';
const INACTIVE_CONTACT_TITLE = 'Inactive Contact';
const INSIGHTLY_CONTACTS_LAST_CHECKED_TITLE = 'Insightly Contacts Last Checked';
const INSIGHTLY_CONTACTS_LAST_API_ACTIVITY_TITLE = 'Insightly Contacts Last API Activity';
const INSIGHTLY_CONTACTS_EMAIL_TITLE = 'Insightly Contacts Email Address';
type searchFilter = 'userId' | 'practiceId' | 'upn' | 'externalFullName' | 'admin' | 'disabled' | 'userRole' | 'fundStatusCom' | 'inactiveContact' | 'insightlyContactsLastChecked' | 'insightlyContactsLastApiActivity' | 'insightlyContactsEmail' | 'emailAddress';
const defaultFilter: Record<searchFilter, IFilteredInfo> = {
   userId: undefined,
   practiceId: undefined,
   upn: undefined,
   externalFullName: undefined,
   admin: undefined,
   disabled: undefined,
   userRole: undefined,
   fundStatusCom: undefined,
   inactiveContact: undefined,
   insightlyContactsLastChecked: undefined,
   insightlyContactsLastApiActivity: undefined,
   insightlyContactsEmail: undefined,
   emailAddress: undefined
};
const insightlyContactsLastCheckedFilter: Record<searchFilter, IFilteredInfo> = {
   userId: undefined,
   practiceId: undefined,
   upn: undefined,
   externalFullName: undefined,
   admin: undefined,
   disabled: undefined,
   userRole: undefined,
   fundStatusCom: {
      title: FUND_STATUS_ALERT_TITLE,
      value: 'yes'
   },
   inactiveContact: {
      title: INACTIVE_CONTACT_TITLE,
      value: 0
   },
   insightlyContactsLastChecked: {
      title: INSIGHTLY_CONTACTS_LAST_CHECKED_TITLE,
      value: new Date(new Date().getTime() - (24 * 60 * 60 * 1000)) // 24 hours ago
   },
   insightlyContactsLastApiActivity: undefined,
   insightlyContactsEmail: undefined,
   emailAddress: undefined
};
const insightlyContactsLastApiActivityFilter: Record<searchFilter, IFilteredInfo> = {
   userId: undefined,
   practiceId: undefined,
   upn: undefined,
   externalFullName: undefined,
   admin: undefined,
   disabled: undefined,
   userRole: undefined,
   fundStatusCom: {
      title: FUND_STATUS_ALERT_TITLE,
      value: 'yes'
   },
   inactiveContact: {
      title: INACTIVE_CONTACT_TITLE,
      value: 0
   },
   insightlyContactsLastChecked: undefined,
   insightlyContactsLastApiActivity: {
      title: INSIGHTLY_CONTACTS_LAST_API_ACTIVITY_TITLE,
      value: new Date(new Date().getTime() - (24 * 60 * 60 * 1000)) // 24 hours ago
   },
   insightlyContactsEmail: undefined,
   emailAddress: undefined
};
const insightlyContactsEmailAddressFilter: Record<searchFilter, IFilteredInfo> = {
   userId: undefined,
   practiceId: undefined,
   upn: undefined,
   externalFullName: undefined,
   admin: undefined,
   disabled: undefined,
   userRole: undefined,
   fundStatusCom: {
      title: FUND_STATUS_ALERT_TITLE,
      value: 'yes'
   },
   inactiveContact: {
      title: INACTIVE_CONTACT_TITLE,
      value: 0
   },
   insightlyContactsLastChecked: undefined,
   insightlyContactsLastApiActivity: undefined,
   insightlyContactsEmail: {
      title: INSIGHTLY_CONTACTS_EMAIL_TITLE,
      value: 'NOT like "@"' // Used purely as a display value, the filtering logic is hard-coded for now (AH-4233)
   },
   emailAddress: undefined
};

const _keys: string[] = [];
const _keysLike: string[] = [];
const _keysWithVerb: KeyWithVerb[] = [{
   key: userProfileBaseUrl, verb: HttpVerb.GET
}];

const UserManagementIndex: React.FC = () => {
   const { cmd } = useParams();
   const { httpGet } = useApiContext();
   const { practiceOptions } = usePracticeOptions(httpGet);
   const { userProfileViewModels, isLoading, error } = useUserProfileViewModels(httpGet);
   const [isEditorOpen, setIsEditorOpen] = React.useState(false);
   const [isEditorReadOnly, setIsEditorReadOnly] = React.useState(false);
   const [selectedItemId, setSelectedItemId] = React.useState<number>(undefined);

   const { userRoleOptions } = useUserRoleOptions(httpGet, true);
   const { userRoles } = useFetchUserRoles(httpGet);

   const [currentPage, setCurrentPage] = React.useState(1);
   const [currentPageSize, setCurrentPageSize] = React.useState(25);
   const handleOnChange: TableProps<UserProfileViewModel>['onChange'] =
      (pagination, filters, sorter, extra) => setCurrentPage(pagination?.current);

   const determineFilter = (): Record<searchFilter, IFilteredInfo> => {
      switch (cmd) {
         case insightlyContactsLastCheckedRouteValue:
            return insightlyContactsLastCheckedFilter;

         case insightlyContactsLastApiActivityRouteValue:
            return insightlyContactsLastApiActivityFilter;

         case insightlyContactsEmailAddressRouteValue:
            return insightlyContactsEmailAddressFilter;

         default:
            return defaultFilter;
      }
   };

   const [filteredInfo, setFilteredInfo] = React.useState<Record<searchFilter, IFilteredInfo>>(determineFilter());

   const booleanOptions = [{ label: 'Yes', value: 1, key: 'Yes' } as IOptionItem, { label: 'No', value: 0, key: 'No' } as IOptionItem]

   const { userIdOptions, upnOptions, externalFullNameOptions, emailOptions } = React.useMemo(() => {

      const userIdOptions: IOptionItem[] = [];
      var upnOptions: IOptionItem[] = [];
      var externalFullNameOptions: IOptionItem[] = [];
      var emailOptions: IOptionItem[] = [];

      if (userProfileViewModels && userProfileViewModels.length > 0) {
         userProfileViewModels.forEach(model => {

            userIdOptions.push({ label: formatUserId(model), value: model.userProfileId, key: model.userProfileId } as IOptionItem);
            upnOptions.push({ label: model.userMain?.upn, value: model.userMain?.upn });
            externalFullNameOptions.push({ label: model.externalFullName?.toLowerCase(), value: model.externalFullName?.toLowerCase() });
            emailOptions.push({ label: model.emailAddress?.toLocaleLowerCase(), value: model.emailAddress?.toLowerCase() });
         });
      }
      // get unique values and sort based on label
      upnOptions = sortOptionsByDistinctLabel(upnOptions);
      externalFullNameOptions = sortOptionsByDistinctLabel(externalFullNameOptions);
      emailOptions = sortOptionsByDistinctLabel(emailOptions);

      return { userIdOptions, upnOptions, externalFullNameOptions, emailOptions };
   }, [userProfileViewModels]);

   const filterGridContent = (records: UserProfileViewModel[]): UserProfileViewModel[] => {
      if (records && records.length > 0) {
         return records.filter(y =>
            filterUserIds(y) &&
            filterPracticeIds(y) &&
            filterUpns(y) &&
            filterExternalFullNames(y) &&
            filterAdmin(y) &&
            filterDisabled(y) &&
            filterUserRoles(y) &&
            filterInsightlyContactFundStatusCom(y) &&
            filterInsightlyContactInactiveContact(y) &&
            filterInsightlyContactLastChecked(y) &&
            filterInsightlyContactLastApiActivity(y) &&
            filterInsightlyContactEmailAddress(y) &&
            filterEmailAddress(y)
         );
      } else {
         return [];
      }
   }

   const filterUserIds = (model: UserProfileViewModel): boolean => {
      return !filteredInfo?.userId?.values || filteredInfo.userId.values.length == 0 ||
         filteredInfo.userId.values.some(y => y.value === model.userProfileId);
   }

   const filterPracticeIds = (model: UserProfileViewModel): boolean => {
      return !filteredInfo?.practiceId?.values || filteredInfo.practiceId.values.length == 0 ||
         filteredInfo.practiceId.values.some(y => y.value === model.practiceId);
   }

   const filterUpns = (model: UserProfileViewModel): boolean => {
      return !filteredInfo?.upn?.values || filteredInfo.upn.values.length == 0 ||
         filteredInfo.upn.values.some(y => y.value === model?.userMain?.upn);
   }

   const filterExternalFullNames = (model: UserProfileViewModel): boolean => {
      return !filteredInfo?.externalFullName?.values || filteredInfo.externalFullName.values.length == 0 ||
         filteredInfo.externalFullName.values.some(y => (y.value as string)?.toLowerCase() === model.externalFullName?.toLowerCase());
   }

   const filterAdmin = (model: UserProfileViewModel): boolean => {
      return !filteredInfo?.admin?.values || filteredInfo.admin.values.length == 0 ||
         filteredInfo.admin.values.some(y => Boolean(y.value) === model.isAdmin);
   }

   const filterDisabled = (model: UserProfileViewModel): boolean => {
      return !filteredInfo?.disabled?.values || filteredInfo.disabled.values.length == 0 ||
         filteredInfo.disabled.values.some(y => Boolean(y.value) === model.isDisabled);
   }

   const filterUserRoles = (model: UserProfileViewModel): boolean => {
      return !filteredInfo?.userRole?.values || filteredInfo.userRole.values.length == 0 ||
         filteredInfo.userRole.values.some(y => model.userRoles?.includes(y.value as number)) ||
         // Special case - y.value: 0 is the "[NULL]" option so in that case we'll accept models with no userRoles
         (filteredInfo.userRole.values.some(y => y.value === 0) && (model.userRoles?.length ?? 0) === 0);
   }

   const filterInsightlyContactFundStatusCom = (model: UserProfileViewModel): boolean => {
      return !filteredInfo?.fundStatusCom?.value ||
         model?.insightlyContact?.fundStatusCom?.toLocaleLowerCase().indexOf(filteredInfo.fundStatusCom.value as string) > -1
   }

   const filterInsightlyContactInactiveContact = (model: UserProfileViewModel): boolean => {
      // This will only work so long as our only requirement is to match on "NULL or 0" (see AH-4233)
      return filteredInfo?.inactiveContact?.value === undefined ||
         (model?.insightlyContact?.inactiveContact ?? 0) === filteredInfo.inactiveContact.value;
   }

   const filterInsightlyContactEmailAddress = (model: UserProfileViewModel): boolean => {
      return !filteredInfo?.insightlyContactsEmail?.value ||
         !validateEmail(model?.insightlyContact?.emailAddress); // Same function used for the math on the dashboard tile
   }

   const filterEmailAddress = (model: UserProfileViewModel): boolean => {
      return !filteredInfo?.emailAddress?.values ||
             filteredInfo?.emailAddress?.values.length === 0 ||
             filteredInfo.emailAddress.values.some(e => (e.value as string)?.toLowerCase() === model?.emailAddress?.toLowerCase());
   }

   const filterInsightlyContactLastChecked = (model: UserProfileViewModel): boolean => {
      // AH-4233
      return !filteredInfo?.insightlyContactsLastChecked?.value ||
         (model?.insightlyContact?.lastChecked &&
            isDateAfter(model.insightlyContact.lastChecked, filteredInfo.insightlyContactsLastChecked.value as Date)
         )
   }

   const filterInsightlyContactLastApiActivity = (model: UserProfileViewModel): boolean => {
      // AH-4233
      return !filteredInfo?.insightlyContactsLastApiActivity?.value ||
         (model?.insightlyContact?.lastApiActivity &&
            isDateAfter(model.insightlyContact.lastApiActivity, filteredInfo.insightlyContactsLastApiActivity.value as Date)
         )
   }

   const filteredData = React.useMemo(() => {
      if (userProfileViewModels?.length > 0) {
         return filterGridContent(userProfileViewModels);
      }
      // for "filterGridContent" - we actually need the two in this dependency array
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [filteredInfo, userProfileViewModels])

   const handleCloseEditor = () => {
      setIsEditorOpen(false);
      setIsEditorReadOnly(false);
      setSelectedItemId(undefined);
   }

   const renderUserRoles = (userModel: UserProfileViewModel): React.ReactNode => {
      if (userModel?.userRoles?.length > 0) {
         const returnArr: JSX.Element[] = [];
         let index = 1;
         userModel.userRoles.forEach(r => {
            returnArr.push(
               /* 'ant-table-cell-ellipsis' - is the className that Ant uses to render ellipsis in its table when 
                *  you provide the "ellipsis: true" value for a column definition */
               <div key={`${userModel.userProfileId}-${index}`} className='ant-table-cell-ellipsis'>
                  {`${userRoles?.find(role => r === role.userRoleId).roleName}${index === userModel.userRoles.length ? '' : ','} `}
               </div>);
            index++;
         })
         return returnArr;
      } else {
         return <></>;
      }
   }

   const columns: ColumnsType<UserProfileViewModel> = [
      {
         dataIndex: 'userProfileId',
         title: <ColumnFilter title={USER_PROFILE_ID_TITLE}
            filteredInfo={filteredInfo?.userId}
            content={<MultiSelectColumnFilter
               options={userIdOptions}
               searchPlaceholder={`Filter by ${USER_PROFILE_ID_TITLE}`}
               allowSelectAll={true}
               searchable={true}
               filteredInfo={filteredInfo}
               setFilteredInfo={setFilteredInfo}
               filteredInfoKey={'userId'}
               filteredInfoTitle={USER_PROFILE_ID_TITLE}
            />}
         />,
         key: 'userProfileId',
         sorter: (a, b) => numberComparer(a.userProfileId, b.userProfileId),
         sortDirections: antSortOptions,
         defaultSortOrder: 'ascend',
         width: 80
      },
      {
         dataIndex: 'practiceId',
         title: <ColumnFilter title={PRACTICE_ID_TITLE}
            filteredInfo={filteredInfo?.practiceId}
            content={<MultiSelectColumnFilter
               options={practiceOptions}
               searchPlaceholder={`Filter by ${PRACTICE_ID_TITLE}`}
               allowSelectAll={true}
               searchable={true}
               filteredInfo={filteredInfo}
               setFilteredInfo={setFilteredInfo}
               filteredInfoKey={'practiceId'}
               filteredInfoTitle={PRACTICE_ID_TITLE}
            />}
         />,
         key: 'practiceId',
         sorter: (a, b) => numberComparer(a.practiceId, b.practiceId),
         sortDirections: antSortOptions,
         render: (text, record) => formatPractice(record),
         width: 125
      },
      {
         dataIndex: ['userMain', 'upn'], 
         title: <ColumnFilter title={UPN_TITLE}
            filteredInfo={filteredInfo?.upn}
            content={<MultiSelectColumnFilter
               options={upnOptions}
               searchPlaceholder={`Filter by ${UPN_TITLE}`}
               allowSelectAll={true}
               searchable={true}
               filteredInfo={filteredInfo}
               setFilteredInfo={setFilteredInfo}
               filteredInfoKey={'upn'}
               filteredInfoTitle={UPN_TITLE}
            />}
         />,
         key: 'userMain.upn',
         sorter: (a, b) => stringComparer(a.userMain?.upn, b.userMain?.upn),
         sortDirections: antSortOptions,
         width: 125
      },
      {
         dataIndex: 'externalFullName',
         title: <ColumnFilter title={EXTERNAL_FULL_NAME_TITLE}
            filteredInfo={filteredInfo?.externalFullName}
            content={<MultiSelectColumnFilter
               options={externalFullNameOptions}
               searchPlaceholder={`Filter by ${EXTERNAL_FULL_NAME_TITLE}`}
               allowSelectAll={true}
               searchable={true}
               filteredInfo={filteredInfo}
               setFilteredInfo={setFilteredInfo}
               filteredInfoKey={'externalFullName'}
               filteredInfoTitle={EXTERNAL_FULL_NAME_TITLE}
            />}
         />,
         key: 'externalFullName',
         sorter: (a, b) => stringComparer(a.externalFullName, b.externalFullName),
         sortDirections: antSortOptions,
         width: 100
      },
      {
         dataIndex: 'userRoleNamesString',
         title: <ColumnFilter title={USER_ROLE_TITLE}
            filteredInfo={filteredInfo?.userRole}
            content={<MultiSelectColumnFilter
               options={userRoleOptions}
               searchPlaceholder={`Filter by ${USER_ROLE_TITLE}`}
               allowSelectAll={true}
               searchable={true}
               filteredInfo={filteredInfo}
               setFilteredInfo={setFilteredInfo}
               filteredInfoKey={'userRole'}
               filteredInfoTitle={USER_ROLE_TITLE}
            />}
         />,
         width: 150,
         key: 'userRoles',
         sorter: (a, b) => stringComparer(a.userRoleNamesString, b.userRoleNamesString),
         sortDirections: antSortOptions,
         render: (text, record) => {
            const content = renderUserRoles(record);
            return <Tooltip placement='top' overlayStyle={{ maxWidth: '350px' }} title={content}>{content}</Tooltip>
         }
      },
      {
         dataIndex: ['insightlyOrganization', 'accountManager'],
         title: 'Account Manager',
         key: 'insightlyOrganization.accountManager',
         sorter: (a, b) => stringComparer(a.insightlyOrganization?.accountManager, b.insightlyOrganization?.accountManager),
         sortDirections: antSortOptions,
         width: 150
      },
      {
         dataIndex: 'emailAddress',
         title: <ColumnFilter title={USER_PROFILE_EMAIL_ADDRESS_TITLE}
            filteredInfo={filteredInfo?.emailAddress}
            content={<MultiSelectColumnFilter
               options={emailOptions}
               searchPlaceholder={`Filter by ${USER_PROFILE_EMAIL_ADDRESS_TITLE}`}
               allowSelectAll={true}
               searchable={true}
               filteredInfo={filteredInfo}
               setFilteredInfo={setFilteredInfo}
               filteredInfoKey={'emailAddress'}
               filteredInfoTitle={USER_PROFILE_EMAIL_ADDRESS_TITLE}
            />}
         />,
         key: 'emailAddress',
         sorter: (a, b) => stringComparer(a.emailAddress, b.emailAddress),
         sortDirections: antSortOptions,
         width: 150
      },
      {
         dataIndex: ['insightlyContact', 'fundStatusCom'],
         title: 'FundStatusAlerts',
         key: 'insightlyContact.fundStatusCom',
         sorter: (a, b) => stringComparer(a.insightlyContact?.fundStatusCom, b.insightlyContact?.fundStatusCom),
         sortDirections: antSortOptions,
         width: 130
      },
      {
         dataIndex: ['insightlyContact', 'lastChecked'],
         title: 'Last Checked',
         key: 'insightlyContact.lastChecked',
         sorter: (a, b) => dateComparer(a.insightlyContact?.lastChecked, b.insightlyContact?.lastChecked),
         sortDirections: antSortOptions,
         render: (text, record) => formatShortDateString(record.insightlyContact?.lastChecked),
         width: 100
      },
      {
         dataIndex: 'isAdmin',
         title: <ColumnFilter title={ADMIN_TITLE}
            filteredInfo={filteredInfo?.admin}
            content={<MultiSelectColumnFilter
               options={booleanOptions}
               searchPlaceholder={`Filter by ${ADMIN_TITLE}`}
               filteredInfo={filteredInfo}
               setFilteredInfo={setFilteredInfo}
               filteredInfoKey={'admin'}
               filteredInfoTitle={ADMIN_TITLE}
            />}
         />,
         key: 'isAdmin',
         sorter: (a, b) => boolComparer(a.isAdmin, b.isAdmin),
         sortDirections: antSortOptions,
         render: (val) => formatBoolean(val),
         width: 70
      },
      {
         dataIndex: 'isDisabled',
         title: <ColumnFilter title={DISABLED_TITLE}
            filteredInfo={filteredInfo?.disabled}
            content={<MultiSelectColumnFilter
               options={booleanOptions}
               searchPlaceholder={`Filter by ${DISABLED_TITLE}`}
               filteredInfo={filteredInfo}
               setFilteredInfo={setFilteredInfo}
               filteredInfoKey={'disabled'}
               filteredInfoTitle={DISABLED_TITLE}
            />}
         />,
         key: 'isDisabled',
         sorter: (a, b) => boolComparer(a.isDisabled, b.isDisabled),
         sortDirections: antSortOptions,
         render: (val) => formatBoolean(val),
         width: 70
      },
      {
         dataIndex: '',
         title: '',
         key: 7,
         width: '7rem',
         fixed: 'right',
         render: (text, record) =>
            <Space>
               <Restricted requiredRoles={[KnownSettings.UserAdmin]}>
                  <EditButton
                     title='Edit User Profile'
                     onClick={() => {
                        setSelectedItemId(record.userProfileId);
                        setIsEditorOpen(true);
                     }} />
               </Restricted>
               <ActionButton
                  title='Preview'
                  icon={<CustomIcon type={CustomIconType.EyeOutlined} />}
                  onClick={() => {
                     setSelectedItemId(record.userProfileId);
                     setIsEditorReadOnly(true);
                     setIsEditorOpen(true);
                  }} />
               <Restricted requiredRoles={[KnownSettings.UserAdmin]}>
                  {record?.userMain?.auth0Id?.includes('auth0') &&
                     <ChangePasswordButton userMainId={record.userMain.id} />
                  }
               </Restricted>
            </Space>
      }
   ]

   const html = (
      <>
         <ApiErrorDisplay
            title='Error'
            keys={_keys}
            keysLike={_keysLike}
            keysWithVerb={_keysWithVerb}
         />

         <SearchResultsTableWithFilters
            rowkey={'userProfileId'}
            titleText='AssistPoint User Management'
            columns={columns}
            data={filteredData}
            currentPage={currentPage}
            currentPageSize={currentPageSize}
            setCurrentPageSize={setCurrentPageSize}
            onChange={handleOnChange}
            fixedHeader={true}
            onFiltersClear={() => setFilteredInfo(defaultFilter)}
            filteredInfo={filteredInfo}
            setFilteredInfo={setFilteredInfo}
            loading={isLoading}
            scrollY={'calc(100vh - 240px)'}
            scrollX="75rem"
         />
         {isEditorOpen &&
            <ExistingUserProfileEditor
               isEditorOpen={isEditorOpen}
               onCloseEditor={() => handleCloseEditor()}
               existingUserProfileId={selectedItemId}
               isEditorReadOnly={isEditorReadOnly} />
         }
      </>
   );
   return html;
}

export default UserManagementIndex;