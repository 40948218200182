import { Space } from 'antd';
import { ColumnsType, TableProps } from 'antd/lib/table';
import * as React from 'react';
import { numberComparer, stringComparer } from '../../../functions/comparer.functions';
import { useFetchAssistanceServices } from '../../../store/program/AssistanceServiceFetcher';
import { AssistanceServiceFoundationDefaultsViewModel } from '../../../store/program/AssistanceServiceFoundationDefaultsModel';
import {
   useFoundationDefaultsAssistanceProgramOptions, useFoundationDefaultsFoundationDiseaseTypeOptions,
   useFoundationDefaultsFoundationOptions, useFoundationDefaultsViewModel
} from '../../../store/program/AssistanceServiceFoundationDefaultsFetcher';
import HighlightSearchText from '../../HighlightSearchText';
import CustomIcon, { CustomIconType } from '../../shared/AntComponents/CustomIcon';
import SearchResultsTable from '../../shared/AntComponents/Table/SearchResultsTable';
import { BasicDropdownField } from '../../shared/BasicInputLibrary';
import { ActionButton} from '../../shared/Buttons';
import Spinner from '../../Spinner';
import AssistanceServiceFoundationDefaultsEditor from './AssistanceServiceFoundationDefaultsEditor';
import { useApiContext } from '../../../store/ApiContext';


const AssistanceServiceFoundationDefaultsIndex: React.FC = () => {
    const { httpGet } = useApiContext();
   const { foundationDefaultsViewModelList, isLoading } = useFoundationDefaultsViewModel(httpGet);
   const { foundationDefaultsFoundationOptions } = useFoundationDefaultsFoundationOptions(httpGet);
   const { foundationDefaultsFoundationDiseaseTypeOptions } = useFoundationDefaultsFoundationDiseaseTypeOptions(httpGet);
   const { foundationDefaultsAssistanceProgramOptions } = useFoundationDefaultsAssistanceProgramOptions(httpGet);
    const { assistanceServices } = useFetchAssistanceServices(httpGet);

    const [currentPage, setCurrentPage] = React.useState(1);
    const [currentPageSize, setCurrentPageSize] = React.useState(25);
   const handleOnChange: TableProps<AssistanceServiceFoundationDefaultsViewModel>['onChange'] = (pagination, filters, sorter, extra) => setCurrentPage(pagination?.current);

    //const [nameFilter, setNameFilter] = React.useState<string>('');
    const [assistanceProgramFilter, setAssistanceProgramFilter] = React.useState<number>();
    const [foundationFilter, setFoundationFilter] = React.useState<number>();
    const [foundationDiseaseTypeFilter, setFoundationDiseaseTypeFilter] = React.useState<number>();
    const [filteredItems, setFilteredItems] = React.useState<AssistanceServiceFoundationDefaultsViewModel[]>();
    const [selectedItem, setSelectedItem] = React.useState<AssistanceServiceFoundationDefaultsViewModel>(undefined);
    const [isEditorOpen, setIsEditorOpen] = React.useState<boolean>(false);
    const [isEditorReadonly, setIsEditorReadonly] = React.useState<boolean>(true);

    React.useEffect(() => {
        createFilteredItems();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [foundationDefaultsViewModelList,
        foundationFilter,
        foundationDiseaseTypeFilter,
        assistanceProgramFilter]);

    const createFilteredItems = (inActivePage: number = 1) => {
        if (!foundationDefaultsViewModelList || foundationDefaultsViewModelList.length <= 0) return;

        let newList = foundationDefaultsViewModelList.filter(model => {
            return filterFoundation(model) &&
                filterFoundationDiseaseType(model) &&
                filterAssistancePrograms(model);
        }) ?? [];

        setFilteredItems(newList);
    }

    const filterFoundation = (m: AssistanceServiceFoundationDefaultsViewModel): boolean => {
        return !foundationFilter || m.foundationId === foundationFilter ? true : false;
    }

    const filterFoundationDiseaseType = (m: AssistanceServiceFoundationDefaultsViewModel): boolean => {
        return !foundationDiseaseTypeFilter || m.foundationDiseaseTypeId === foundationDiseaseTypeFilter ? true : false;
    }

    const filterAssistancePrograms = (m: AssistanceServiceFoundationDefaultsViewModel): boolean => {
        return !assistanceProgramFilter || m.assistanceProgramId === assistanceProgramFilter ? true : false;
    }

    const handleCloseEditorClick = () => {
        setIsEditorOpen(false);
        setSelectedItem(undefined);
    }

    if (isLoading || !foundationDefaultsViewModelList || foundationDefaultsViewModelList.length === 0) {
        return <Spinner />
    }

    const tableColumns: ColumnsType<AssistanceServiceFoundationDefaultsViewModel> = [
        {
            title: 'Id',
            dataIndex: 'id',
            width: 50,
            sorter: (a, b) => numberComparer(a.id, b.id),
            sortDirections: ['ascend', 'descend', 'ascend'],
            defaultSortOrder: 'descend',
        },
        {
            title: 'Foundation Name',
            dataIndex: 'foundationName',
            width: '15%',
            sorter: (a, b) => stringComparer(a.foundationName, b.foundationName),
            sortDirections: ['ascend', 'descend', 'ascend'],
            render: (text, record) => {
                if (foundationFilter && foundationFilter === record.foundationId) {
                    return <HighlightSearchText searchString={record.foundationName} targetString={record.foundationName} />
                }
                return record.foundationName;
            }
        },
        {
            title: 'FDTID',
            dataIndex: 'foundationDiseaseTypeId',
            width: 50,
            sorter: (a, b) => numberComparer(a.foundationDiseaseTypeId, b.foundationDiseaseTypeId),
            sortDirections: ['ascend', 'descend', 'ascend'],
            render: (text, record) => {
                if (foundationDiseaseTypeFilter && record.foundationDiseaseTypeId === foundationDiseaseTypeFilter) {
                    return <HighlightSearchText searchString={`${record.foundationDiseaseTypeId}`} targetString={`${record.foundationDiseaseTypeId}`} />
                }
                return text;
            }
        },
        {
            title: 'Foundation DiseaseTypeName',
            dataIndex: 'diseaseTypeName',
            width: '15%',
            sorter: (a, b) => stringComparer(a.diseaseTypeName, b.diseaseTypeName),
            sortDirections: ['ascend', 'descend', 'ascend'],
            render: (text, record) => {
                if (foundationDiseaseTypeFilter && record.foundationDiseaseTypeId === foundationDiseaseTypeFilter) {
                    return <HighlightSearchText searchString={record.diseaseTypeName} targetString={record.diseaseTypeName} />
                }
                return text;
            }
        },
        {
            title: 'ASID',
            dataIndex: 'assistanceServiceId',
            width: 50,
            sorter: (a, b) => numberComparer(a.assistanceServiceId, b.assistanceServiceId),
            sortDirections: ['ascend', 'descend', 'ascend'],
            render: (text, record) => {
                if (assistanceProgramFilter) {
                    const matches = assistanceServices.filter(y => y.assistanceProgramId === assistanceProgramFilter);
                    if (!matches || matches.some(y => y.id === assistanceProgramFilter)) {
                        return <HighlightSearchText searchString={`${record.assistanceServiceId}`} targetString={`${record.assistanceServiceId}`} />
                    }
                }
                return text;
            }
        },
        {
            title: 'Assistance Service Name',
            dataIndex: 'assistanceServiceName',
            width: '15%',
            sorter: (a, b) => stringComparer(a.assistanceServiceName, b.assistanceServiceName),
            sortDirections: ['ascend', 'descend', 'ascend'],
            render: (text, record) => {
                if (assistanceProgramFilter) {
                    const matches = assistanceServices.filter(y => y.assistanceProgramId === assistanceProgramFilter);
                    if (!matches || matches.some(y => y.id === assistanceProgramFilter)) {
                        return <HighlightSearchText searchString={record.assistanceServiceName} targetString={record.assistanceServiceName} />
                    }
                }
                return text;
            }
        },
        {
            title: 'Program Name',
            dataIndex: 'programName',
            width: '15%',
            sorter: (a, b) => stringComparer(a.programName, b.programName),
            sortDirections: ['ascend', 'descend', 'ascend'],
            render: (text, record) => {
                if (assistanceProgramFilter && assistanceProgramFilter === record.assistanceProgramId) {
                    return <HighlightSearchText searchString={record.programName} targetString={record.programName} />
                }
                return text;
            }
        },
        {
            dataIndex: 'actions',
            width: 60,
            fixed: 'right',
            render: (text, record) => <Space>
                <ActionButton
                    title='Preview'
                    icon={<CustomIcon type={CustomIconType.EyeOutlined} />}
                    onClick={() => {
                        setIsEditorOpen(true);
                        setIsEditorReadonly(true);
                        setSelectedItem(record);
                    }}
                />
            </Space>
        },
    ];

    const html = (
        <>
            <SearchResultsTable
                rowkey={'id'}
                onChange={handleOnChange}
                columns={tableColumns}
                data={filteredItems}
                currentPage={currentPage}
                currentPageSize={currentPageSize}
                setCurrentPageSize={setCurrentPageSize}
                titleText='Assistance Service Foundation Defaults'
                fixedHeader={true}
                size={'small'}
                additionalComponents={[
                    <BasicDropdownField
                        containerStyle={{ maxWidth: 250 }}
                        name='foundationSearchString'
                        label='Foundation'
                        placeholder={' - Filter by Foundation -'}
                        multiple={false}
                        options={foundationDefaultsFoundationOptions}
                        value={foundationFilter}
                        search={true}
                        clearable={true}
                        onChange={(e) => setFoundationFilter(e ? Number(e) : undefined)}
                    />,
                    <BasicDropdownField
                        containerStyle={{ maxWidth: 250 }}
                        name='foundationDiseaseTypeSearchString'
                        label='Foundation Disease Type'
                        placeholder={' - Filter by Foundation Disease Type -'}
                        multiple={false}
                        options={foundationDefaultsFoundationDiseaseTypeOptions}
                        value={foundationDiseaseTypeFilter}
                        search={true}
                        clearable={true}
                        onChange={(e) => setFoundationDiseaseTypeFilter(e ? Number(e) : undefined)}
                    />,
                    <BasicDropdownField
                        containerStyle={{ maxWidth: 250 }}
                        name='assistanceProgramSearchString'
                        label='Assistance Program'
                        placeholder={' - Filter by Assistance Program -'}
                        multiple={false}
                        options={foundationDefaultsAssistanceProgramOptions}
                        value={assistanceProgramFilter}
                        search={true}
                        clearable={true}
                        onChange={(e) => setAssistanceProgramFilter(e ? Number(e) : undefined)}
                    />,
                ]}
            />

          {isEditorOpen &&
             <AssistanceServiceFoundationDefaultsEditor isOpen={isEditorOpen}
                closeEditor={() => handleCloseEditorClick()}
                id={selectedItem?.id}
                readonly={isEditorReadonly}
             />
          }
        </>
    )
    return html;
}

export default AssistanceServiceFoundationDefaultsIndex;

