import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth0Provider, AppState, Auth0ProviderOptions } from '@auth0/auth0-react';
import { useFetchSettings, getSettingValue } from '../store/SettingsFetcher';
import { KnownSettings } from '../store/SettingsModel';
import Spinner from '../components/Spinner';

export type IProps = {
   children: React.ReactNode;
};

const AuthProviderWithHistory = ({ children }: IProps) => {
   const { settings } = useFetchSettings();

   const navigate = useNavigate();
 
   const auth0Settings = settings?.filter(y => y.group === KnownSettings.Auth0);

   //need settings to continue
   if (!settings || settings.length === 0) {
      return <Spinner style={{ height: '100vh' }} message="Configuring your session..." />
   }

   const onRedirectCallback = (appState: AppState) => {
      navigate(appState?.returnTo || window.location.pathname);
   };

   const config: Auth0ProviderOptions = {
      domain: getSettingValue(KnownSettings.Domain, auth0Settings),
      clientId: getSettingValue(KnownSettings.ClientId, auth0Settings),
      authorizationParams: {
         audience: getSettingValue(KnownSettings.Audience, auth0Settings),
         redirect_uri: window.location.origin,
      },
      onRedirectCallback: onRedirectCallback
   }

   return (
      <Auth0Provider {...config}>
         {children}
      </Auth0Provider>
   );
};

export default AuthProviderWithHistory;