import { Checkbox, List, Tag } from "antd";
import { useApiContext } from "../../store/ApiContext";
import { useFetchAccessRole } from "../../store/auth/AccessRoleFetcher";
import colorWheel from "../../Theme/ColorWheel";

interface IProps {
   assignedAccessRoles: number[];
   editing: boolean;
   handleAccessRoleChecked: (accessRoleId: number) => void;
}

const UserAccessRoleList: React.FC<IProps> = (props) => {
   const { assignedAccessRoles, editing, handleAccessRoleChecked } = props;
   const { httpGet } = useApiContext();
   const { accessRoles } = useFetchAccessRole(httpGet);

   const html = (
      <List
         bordered
         header={assignedAccessRoles?.length > 0 ? <b>Admin Portal Roles</b> : <Tag color={colorWheel.red}>No Admin Portal Roles assigned</Tag>}
         size='small'
         style={{  overflowY: 'auto' }}
         pagination={{
            simple: true,
            pageSize: 15,
            hideOnSinglePage: true
         }}
         dataSource={accessRoles}
         renderItem={item => {
            const isMapped = assignedAccessRoles?.some(y => y === item.id);
            if (editing) {
               return <List.Item>
                  <Checkbox checked={isMapped} onChange={() => handleAccessRoleChecked(item.id)}>
                     {item.roleName}
                  </Checkbox>
               </List.Item>
            } else if (isMapped && !editing) {
               return <List.Item>
                  <Tag key='bbb' color={colorWheel.ivyGreen}>
                     {item.roleName}
                  </Tag>
               </List.Item>
            }

         }}
      />
   );
   return html;
}

export default UserAccessRoleList;