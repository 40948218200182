import * as React from 'react';
import Spinner from '../../Spinner';
import { ManualEnrollmentReasonOption, connTypeList } from '../../../store/program/ManualEnrollmentReasonOptionModel';
import {
   useManualEnrollmentReasons,
   saveManualEnrollmentReason,
   deleteManualEnrollmentReason,
   manualEnrollmentReasonBaseUrl
} from '../../../store/program/ManualEnrollmentReasonOptionFetcher';
import { Form } from 'antd';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Dialog from '../../Dialog';
import { InputField, DropdownField, CheckboxField } from '../../shared/InputLibrary';
import { SaveButton, DeleteButton, CancelButton } from '../../shared/Buttons';
import ApiErrorDisplay from '../../ApiErrorDisplay';
import { TitleError } from '../../shared/AntComponents/Typography/Title';
import { useApiContext } from '../../../store/ApiContext';
import { useErrorContext } from '../../../store/ErrorContext';

 interface IProps {
    isOpen: boolean;
    closeEditor: () => void;
    id?: number;
    readonly?: boolean;
};

const dialogContentStyle = {
    height: '580px',
    maxHeight: '95vh',
}

const yupManualEnrollmentReasonOptionSchema = yup.object({
    id: yup.number().notRequired(),
    optionName: yup.string()
        .required('OptionName is required')
        .max(100, 'Max length is 100 characters'),
    connectivityType: yup.number()
        .required('ConnectivityType is required'),
    sequence: yup.number()
        .max(999999999, 'Max length is 9 characters')
        .required('Sequence is required'),
    isFreeText: yup.bool()
});


interface IManualEnrollmenReasonOptionYup extends yup.Asserts<typeof yupManualEnrollmentReasonOptionSchema> { }
const __formId = "frmManualEnrollmentReasonOptions";
const _keysLike: string[] = [manualEnrollmentReasonBaseUrl];

const ManualEnrollmentReasonOptionEditor: React.FC<IProps> = (props) => {

   const { id, isOpen, closeEditor, readonly} = props;
 //  const [itemToEdit, setItemToEdit] = React.useState<ManualEnrollmentReasonOption>(undefined);
   const [isFormSaving, setIsFormSaving] = React.useState<boolean>();

   const { httpPost, httpGet, httpDelete } = useApiContext();
   const { manualEnrollmentReasonOptions } = useManualEnrollmentReasons(httpGet);

   const { removeErrors  } = useErrorContext();
   const clearErrors = () => {
      removeErrors({ keysLike: _keysLike });
   }

   const {
      control,
      handleSubmit,
      formState: { errors },
      reset,
      setValue
   } = useForm<IManualEnrollmenReasonOptionYup>({
      resolver: yupResolver<yup.AnyObject>(yupManualEnrollmentReasonOptionSchema),
      shouldFocusError: true,
   });

   const itemToEdit = React.useMemo(() => {
      return manualEnrollmentReasonOptions?.find(y => y.id === id)
   }, [id, manualEnrollmentReasonOptions]);

   React.useEffect(() => {
      reset(itemToEdit ?? {
         id: id
      } as ManualEnrollmentReasonOption);
      setIsFormSaving(false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [itemToEdit, id, isOpen]);

   const onSubmit = (data: IManualEnrollmenReasonOptionYup): void => {
      setIsFormSaving(true);
      //ts hoops to convert data with unknown props into assistance programs
      const item = data as unknown as ManualEnrollmentReasonOption;

      saveManualEnrollmentReason(httpPost, item)
         .then(() => {
            handleClose();
         })
         .catch (err => console.log(`Error Saving`, err))
         .finally(() => {
            setIsFormSaving(false);
         });

   }

   const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState<boolean>(false);

   const handleDeleteClick = () => { 
      setIsFormSaving(true);
      deleteManualEnrollmentReason(httpDelete, itemToEdit.id)
         .then(() => {
            handleClose();
         })
         .catch(err => console.log(`Error Saving`, err))
         .finally(() => {
            setIsFormSaving(false);
            setIsDeleteDialogOpen(false);
         });
   }

   const handleClose = () => {
      setIsFormSaving(false);
      setIsDeleteDialogOpen(false);
      clearErrors();
      closeEditor();
   }

   if (isFormSaving) {
      return <Spinner message='Saving Manual Enrollment Reason Option...' />
   }

   const html = (
      <Dialog
         scrollingContent={true}
         title={'Manual Enrollment Reasons Editor'}
         open={isOpen}
         size="large"
         actionButtons={
            <>
               <CancelButton buttonText='Cancel' onClick={() => handleClose() } />
               <DeleteButton 
                  onClick={() => setIsDeleteDialogOpen(true)}
                  disabled={!itemToEdit || !itemToEdit?.id || isDeleteDialogOpen}
                  loading={isDeleteDialogOpen} />               
               <SaveButton 
                  formId={__formId}
                  disabled={Object.keys(errors).length > 0}
                  loading={isFormSaving} />               
            </>
         }>
         <div style={dialogContentStyle}>

            {errors && Object.keys(errors).length > 0 &&
               <>
                  <TitleError text='Please correct the errors below' />
                  {console.log('--------------Form Errors: -------------------------')}
                  {console.log(errors)}
               </>
            }

            <ApiErrorDisplay
               title='Error saving ManualEnrollmentReasonOptionState'
               keysLike={_keysLike}
            />

            <Form id={__formId} onFinish={handleSubmit(onSubmit)}>
               <InputField
                  control={control}
                  name='id'
                  label='ReasonId'
                  error={errors?.id}
                  required={false}
                  disabled={true}
                  type='number'
               />
               <InputField
                  control={control}
                  name='optionName'
                  label='OptionName'
                  error={errors?.optionName}
                  required={true}
                  disabled={readonly ? true : false}
                  type='text'
               />
               <InputField
                  control={control}
                  name='sequence'
                  label='Sequence'
                  error={errors?.sequence}
                  required={true}
                  disabled={readonly ? true : false}
                  type='number'
               />
               <CheckboxField
                  control={control}
                  name='isFreeText'
                  label='IsFreeText'
                  error={errors?.isFreeText}
                  toggle={true}
                  setValue={setValue}
                  disabled={readonly ? true : false}
                  required={false}
               />

               <DropdownField
                  control={control}
                  name='connectivityType'
                  label='ConnectivityType'
                  error={errors?.connectivityType}
                  multiple={false}
                  options={connTypeList}
                  setValue={setValue}
                  required={true}
                  search={true}
                  clearable={true}
                  disabled={readonly ? true : false}
               />
            </Form>
         </div>

         <Dialog
            title='CONFIRM DELETE'
            open={isDeleteDialogOpen}
            style={{ maxWidth: '550px' }}
            actionButtons={
               <>
                  <CancelButton
                     disabled={isFormSaving}
                     onClick={() => setIsDeleteDialogOpen(false)} />
                  <DeleteButton
                     disabled={isFormSaving}
                     onClick={() => handleDeleteClick()} />
               </>
            }
         >
            {`Are you sure you want to Delete the Manual Enrollment Reason Option with id: ${itemToEdit?.id}?`}
         </Dialog>

      </Dialog>
   )
   return html;
}

export default ManualEnrollmentReasonOptionEditor;

