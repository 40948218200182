export interface InsuranceClass {
   insuranceClassId: number;
   name: string;
   //status: number;
   sequence: number;
}

export enum InsuranceClassTypes {
   Commercial = 1,
   Medicare = 2,
   Medicaid = 3,
   Uninsured = 5,
   FunctionallyUninsured = 6,
   VaDod = 7
}
