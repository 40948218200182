import { Col, Row } from 'antd';
import * as React from 'react';
import { useUserContext } from '../auth/authContext';
import { isInRole } from '../functions/auth.functions';
import { KnownSettings } from '../store/SettingsModel';
import colorWheel from '../Theme/ColorWheel';
//import InsightlyContactTile from './tiles/InsightlyContactTile';
import NonAcceptedUserInvitesTile from './tiles/NonAcceptedUserInvitesTile';
import FsaSubscriptionChangesTile from './tiles/FsaSubscriptionChangesTile';
import PendingAccessRequestTile from './tiles/PendingAccessRequestTile';
import WelcomeTile from './tiles/WelcomeTile';
import ContentClassificationChangeLogSummaryTile from './tiles/ContentClassificationChangeLogSummaryTile';

const styles = {
   columnWithButtonsInGrid: {
      /*maxWidth: '600px',
       minWidth: '600px',*/
      minHeight: 200,
      maxHeight: 400,
      borderWidth: '3px',
      borderColor: colorWheel.plumPurple,
      borderStyle: 'solid',
      borderRadius: 25,
      backgroundColor: colorWheel.lightGrey,
      margin: 10,
      padding: 10
   } as React.CSSProperties
}

//added allowedroles at this level to avoid the child components making an api call
const dashboardTiles: {
   key: string,
   component: React.ReactNode,
   allowedRoles?: string[]
}[] = [
      {
         key: "NonAcceptedUserInvitesTile",
         component: <NonAcceptedUserInvitesTile styles={styles.columnWithButtonsInGrid} />,
         allowedRoles: [KnownSettings.UserAdmin]
      },
      {
         key: "PendingAccessRequestTile",
         component: <PendingAccessRequestTile styles={styles.columnWithButtonsInGrid} />,
         allowedRoles: [KnownSettings.UserAdmin]
      },
      {
         key: "FsaSubscriptionStatusTile",
         component: <FsaSubscriptionChangesTile styles={styles.columnWithButtonsInGrid} />,
         allowedRoles: [KnownSettings.UserAdmin]
      },
      ,{
         key: "ContentClassificationChangeLogSummaryTile",
         component: <ContentClassificationChangeLogSummaryTile styles={styles.columnWithButtonsInGrid} />,
         allowedRoles: [KnownSettings.ContentAdmin]
      },
   ];

const Home: React.FC = () => {
   const { userRoles } = useUserContext();

   return (
      <div className='homeoverlay'>
         <Row justify="center" align="middle" style={{ height: '100%', paddingLeft: 20 }}>
            <Col>
               <Row gutter={[16, 32]} justify="center" style={{ width: '100%' }}>
                  <>
                     {dashboardTiles.map((tile) => (
                        !tile.allowedRoles || (tile.allowedRoles?.length > 0 && isInRole(userRoles, tile.allowedRoles)) &&
                        <Col xxl={12} lg={12} sm={24} key={tile.key}>
                           {tile.component}
                        </Col>
                     )
                     )}
                  </>
               </Row>
               <Row gutter={[16, 32]} justify="space-around" style={{ paddingTop: 20, paddingBottom: 20 }} >
                  <Col><WelcomeTile /></Col>
               </Row>
            </Col>
         </Row>
      </div>
   );
};

export default Home;
