import * as React from 'react';
import UnhandledError from './UnhandledError';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

interface IProps {
   children: React.ReactNode;
   appInsights: ReactPlugin;
}

interface IState {
   hasError: boolean;
   error?: Error;
   errorInfo?: React.ErrorInfo;
}

export default class AdminPortalErrorBoundary extends React.Component<IProps, IState> {
   state: IState = {
      hasError: false,
      error: {} as Error,
      errorInfo: {} as React.ErrorInfo
   }

   static getDerivedStateFromError(error: Error, errorInfo: React.ErrorInfo): IState {
      // Update state so the next render will show the fallback UI.
      return { hasError: true, error: error, errorInfo: errorInfo };
   }

   componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
      this.setState({ hasError: true, error: error, errorInfo: errorInfo });
      try {
         this.props.appInsights.trackException({
            error: error,
            exception: error,
            severityLevel: SeverityLevel.Error,
            properties: errorInfo
         });
      } catch (appInsightsError) {
         console.error(error);
         console.error(`failed to log appInsights.  ${errorInfo}`);
         console.error(appInsightsError);
      }
   }

   render() {
      if (this.state.hasError) {
         // You can render any custom fallback UI
         return <UnhandledError error={this.state.error} />;
      }

      return this.props.children;
   }
}