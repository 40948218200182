import { InsightlyOrganization } from "./InsightlyOrganizationModel";
import { InsightlyContact } from './InsightlyContactModel';
import UserProfile from "../../components/UserProfile";
import { UserMainDetail } from "../auth/UserMainModel";

// NOTE: see also: UserModel -> CreateNewPracticeUser
/* While this type and that ^ inteface aren't related through the code, they must remain
 * in sync with each other in that CreateNewPracticeUser must be the subset of fields from UserProfile
 * used to create a new UserProfile for the Support workflows.  See <UserProfileEditor />
 * Didn't want to have UserProfile extend CreateNewPracticeUser though since UserProfile represents the DB model
 * */
export type UserProfile = {
   userProfileId: number;
   practiceId: number;
   practiceName?: string;
   externalObjectId: string;
   externalNameIdentifier: string;
   externalUpn: string;
   externalFirstName?: string;
   externalLastName?: string;
   externalFullName?: string;
   externalCity?: string;
   externalState?: string;
   externalDirSyncEnabled?: boolean;
   firstName?: string;
   lastName?: string;
   fullName?: string;
   title?: string;
   emailAddress?: string;
   phone?: string;
   phoneExt?: string;
   fax?: string;
   isAdmin: boolean;
   isDisabled: boolean;
   touVersion?: number;
   lastLogin?: Date;
   created: Date;
   modified: Date;
   timeZoneName: string;
   disableReason: string;
   insightlyContactId: number;
   isFollowedByAllowed: boolean;
   userRoles: number[];
}

export interface UserProfileViewModel extends UserProfile {
   insightlyOrganization?: InsightlyOrganization;
   insightlyContact?: InsightlyContact;
   userRoleNamesString: string;
   userMain: UserMainDetail;
}

export interface BulkUserProfile {
   userMainId: number;
   userProfiles: UserProfile[];
}

export function formatPractice(record: UserProfile): string {
   return `[${record?.practiceId}] ${record?.practiceName}`;
}

export function formatUserId(record: UserProfile): string {
   return `[${record?.userProfileId}] ${record?.externalFullName}`;
}