import {Tag} from 'antd';
import * as React from 'react';
import Restricted from '../../auth/Restricted';
import {useApiContext} from '../../store/ApiContext';
import {deselectAll, IMainMenuItem, MainItemType, MainMenuItems, selectById} from '../../store/menu/MainMenu';
import {useFavoriteReports} from '../../store/report/FavoriteReportFetcher';
import colorWheel from '../../Theme/ColorWheel';
import {useLayoutContext} from '../LayoutContext';
import FlyoutMenu from './FlyoutMenu';
import './MainMenu.css';
import MainMenuItem from './MainMenuItem';
import RecentlyViewedReports from './RecentlyViewedReports';

const MainMenu: React.FC = () => {
   const [flyoutMenuIsExpanded, setFlyoutMenuIsExpanded] = React.useState<boolean>(false);
   const [selectedMenuItem, setSelectedMenuItem] = React.useState<IMainMenuItem>();
   const [menuItems, setMenuItems] = React.useState<IMainMenuItem[]>(MainMenuItems);
   const {httpGet} = useApiContext();
   const {menuProps} = useLayoutContext();
   const {favoritedReportIds} = useFavoriteReports(httpGet);

   const menuRef = React.useRef();

   React.useEffect(() => {
      setMenuItems(deselectAll(menuItems));
      setActiveMenuHighlightFromUrl();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const setParentMenuItemHighlight = (menuItem: IMainMenuItem) => {
      menuItems.map((x, i) => {
         x.isSelected = x.itemType === menuItem.itemType;
         x.childMenuItems?.map((y, i) => y.isSelected = false);
      });
      setMenuItems(menuItems);
   }

   const setChildMenuItemHighlight = (menuItem: IMainMenuItem) => {
      menuItems.map((x, i) => {
         if (x.itemType === menuItem.parentMenuItemType) {
            x.isSelected = true;
            x.childMenuItems?.map((y, i) =>
               y.isSelected = y.itemType === menuItem.itemType);
         } else {
            x.isSelected = false
         }
      });
      setMenuItems(menuItems);
   }

   const setActiveMenuHighlightFromUrl = () => {
      const path = window.location.pathname;
      if (path === '/') {
         menuItems.map((item, index) => item.isSelected = item.id === 'Dashboard');
      } else {
         menuItems.map((item, index) => {
            if (item.itemType !== MainItemType.Dashboard) {
               item.isSelected = path.indexOf(item.itemType) !== -1;
               item.childMenuItems?.map((cItem, cIndex) =>
                  cItem.isSelected = path.indexOf(cItem.itemType) !== -1);
            }
         });
      }

      setMenuItems(menuItems);
   }

   const handleClick = (menuItem: IMainMenuItem) => {
      setFlyoutMenuIsExpanded(flyoutMenuIsExpanded === false || menuItem.id !== selectedMenuItem?.id);
      setSelectedMenuItem(menuItem);
      setMenuItems(selectById(menuItems, menuItem.id));
      setParentMenuItemHighlight(menuItem);
   }

   const handleFlyoutMenuSelect = () => {
      setFlyoutMenuIsExpanded(false);
      setSelectedMenuItem(undefined);
   }

   const handleChildMenuItemClick = (menuItem: IMainMenuItem) => {
      setFlyoutMenuIsExpanded(false);
      setSelectedMenuItem(menuItem);
      setChildMenuItemHighlight(menuItem);
   }

   React.useEffect(() => {
      /* Meant to handle clicking outside of the menu to ensure the submenu closes and it works for the most part.  
       * Most part meaning that if you click beneath the mainMenu options that's outside of this div ref, so it closes.  
       * Additionally, its still not working for Analytics... */
      const handlePageClick = (event: Event) => {
         const target = event?.target as unknown as HTMLDivElement;
         if (menuRef.current &&
            !(menuRef.current as HTMLDivElement).contains(target)) {
            setActiveMenuHighlightFromUrl();
            setFlyoutMenuIsExpanded(false);
            return;
         }
      }

      document.addEventListener('keydown', handlePageClick, true)
      document.addEventListener('click', handlePageClick, true);

      return () => {
         document.removeEventListener('keyup', handlePageClick, true)
         document.removeEventListener('click', handlePageClick, true);
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   return (
      <div ref={menuRef} style={{ overflow: 'auto' }} className="main-menu skinny-main-menu-scroll skinny-scroll">
         <div className="skinny-main-menu-scroll">
         <ul>
            {menuItems.map((menuItem: IMainMenuItem, index: number) => {
                  return (<React.Fragment key={menuItem.id}>
                     <Restricted requiredRoles={menuItem.allowedRoles} key={"MI" + menuItem.id}>
                        <li className={menuItem.isSelected ? 'is-active' : ''}>
                           <MainMenuItem
                              key={menuItem.id}
                              isHidden={menuProps.collapsed}
                              handleClick={() => menuItem?.childMenuItems?.length > 0 ? undefined : handleClick(menuItem)}
                              mainMenuItem={menuItem}
                           />
                        </li>
                        {menuItem?.childMenuItems?.map((childItem: IMainMenuItem, innerIndex: number) => {
                           return <Restricted requiredRoles={childItem.allowedRoles} key={"CMI" + childItem.id}>
                              <li style={{paddingLeft: menuProps.collapsed ? '' : '20px'}}
                                  className={childItem.isSelected ? 'is-active' : ''}>
                                 {childItem.label.toLowerCase() !== 'favorites' &&
                                    <MainMenuItem
                                       key={childItem.id}
                                       isHidden={menuProps.collapsed}
                                       handleClick={() => {
                                          handleChildMenuItemClick(childItem);
                                       }}
                                       mainMenuItem={childItem}
                                    />
                                 }
                                 {childItem.label.toLowerCase() === 'favorites' &&
                                    <span style={{display: 'inline'}}>
                                       <span style={{display: 'inline-block', width: 160}}>
                                          <MainMenuItem
                                             key={childItem.id}
                                             isHidden={menuProps.collapsed}
                                             handleClick={() => {
                                                handleChildMenuItemClick(childItem);
                                             }}
                                             mainMenuItem={childItem}
                                          />
                                       </span>
                                       {favoritedReportIds?.length > 0 && // show the tag when we have favorites
                                          <span style={{ float: 'right'  } }>
                                             <Tag color={colorWheel.plumPurple}>{favoritedReportIds?.length}</Tag>
                                          </span>
                                       }
                                 </span>
                                 }
                              </li>
                           </Restricted>
                        })}
                     </Restricted>
                  </React.Fragment>)
               }
            )}
            {!menuProps.collapsed && <RecentlyViewedReports />}
            </ul>
         </div>
         {flyoutMenuIsExpanded &&
            <FlyoutMenu isMainMenuHidden={menuProps.collapsed}
                        mainMenuItem={selectedMenuItem}
                        flyoutMenuItemSelectFunc={handleFlyoutMenuSelect}
            />
            }
      </div>
   );
}

export default MainMenu;
