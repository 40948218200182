import * as React from 'react';
import { Link } from 'react-router-dom';
import { buildReportUrl } from '../../../store/report/ReportFunctions';
import { PowerBIReport } from '../../../store/report/ReportModel';
import colorWheel from '../../../Theme/ColorWheel';
import { useReports } from '../ReportContext';

interface IProps {
   reports: PowerBIReport[];
}

const selectedStyle = {
   padding: '4px 0 4px 10px',
   background: colorWheel.lightMediumGrey,
   color: colorWheel.plumPurple,
   borderLeft: `solid 2px ${colorWheel.plumPurple}`,
   cursor: 'pointer'
};

const unselectedStyle = {
   padding: '4px 0 4px 12px',
   color: colorWheel.plumPurple,
   borderColor: colorWheel.plumPurple,
   cursor: 'pointer'
}

const ReportMenuCollapsed: React.FC<IProps> = (props: IProps) => {
   const { reports } = props;
   const { powerBIReportId, view, workspaceKey, reportKey } = useReports();

   return (
      <>
         {reports.map((report: PowerBIReport) => {
            return <div
               key={report.id}
               style={report.id === powerBIReportId ? selectedStyle : unselectedStyle}
            >
               <Link title={report.reportPageName} key={report.id}
                  to={buildReportUrl(view, workspaceKey, reportKey, report.id)}>
                  {report.reportPageName?.substring(0, 1)}
               </Link>
            </div>
         })}
      </>
   );
}

export default ReportMenuCollapsed;