import * as React from 'react';
import { useUserContext } from '../auth/authContext';


const EmailDisplay: React.FC = () => {
   const { user } = useUserContext();
   return (
      <>
         {user ? user.username : '...'}
      </>
   )
}

export default EmailDisplay;

