import { ColumnsType } from 'antd/lib/table';
import * as React from 'react';
import { formatBoolean } from '../../functions/format.functions';
import { useApiContext } from '../../store/ApiContext';
import { useFetchFsaSubscriptionChanges } from '../../store/auth/FsaSubscriptionChangesFetcher';
import { FsaSubscriptionChangesModel } from '../../store/auth/FsaSubscriptionChangesModel';
import colorWheel from '../../Theme/ColorWheel';
import MinimalistTable from '../shared/AntComponents/Table/MinimalistTable';
import { NumberedTitle } from '../shared/AntComponents/Typography/Title';
import Spinner from '../Spinner';

const FsaSubscriptionChangesTile: React.FC<{ styles?: React.CSSProperties }> = (props) => {
   const { styles } = props;
   const { httpGet } = useApiContext();
   const { fsaSubscriptionChanges, isLoading } = useFetchFsaSubscriptionChanges(httpGet);

   if (isLoading) return <Spinner message={'Loading FSA Subscription Cahnges...'} />

   const columns: ColumnsType<FsaSubscriptionChangesModel> = [
      {
         title: 'Full name',
         dataIndex: 'lastName',
         key: 'lastName',
         render: (text, record) => `${record.firstName} ${record.lastName}`.trim()
      },
      {
         title: 'Email',
         dataIndex: 'emailAddress',
         key: 'emailAddress',
      },
      {
         title: 'Subscribed',
         dataIndex: 'subscribed',
         key: 'subscribed',
         render: (text, record) => formatBoolean(record.subscribed)
      },
      {
         title: 'Unsubscribe Reason',
         dataIndex: 'unsubscribeReason',
         key: 'unsubscribeReason',
      }
   ];

   return (
      <div style={{ ...styles }}>
         <NumberedTitle level={5} text='FSA Subscription Changes' />
         <div style={{ border: `1px solid ${colorWheel.mediumGrey}`, maxHeight: '25rem', overflowY: 'scroll', overflowX: 'clip' }}>
            <MinimalistTable
               rowKey='emailAddress'
               data={fsaSubscriptionChanges}
               columns={columns}
               size='small'
               showHeader={true}
            />
         </div>
      </div>
   );
}

export default FsaSubscriptionChangesTile;