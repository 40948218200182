import { Space } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React from 'react';
import { PowerBIReportToPractice } from '../../../store/report/PowerBIExtermalReportModel';
import {
   powerBIExternalReportBaseUrl, useFetchPowerBIExternalReportList,
   updateReportOrder
} from '../../../store/report/PowerBIExternalReportFetcher';

import { colorWheel } from '../../../Theme/ColorWheel';
import CustomIcon, { CustomIconType } from '../../shared/AntComponents/CustomIcon';
import SearchResultsTable from '../../shared/AntComponents/Table/SearchResultsTable';
import { ActionButton, EditButton } from '../../shared/Buttons';
import Spinner from '../../Spinner';

import { numberComparer, OrderDirection } from '../../../functions/comparer.functions';
import { useApiContext } from '../../../store/ApiContext';
import ApiErrorDisplay from '../../ApiErrorDisplay';
import PowerBIReportEditor from './PowerBIReportEditor';
import { useErrorContext } from '../../../store/ErrorContext';

const _keysLike: string[] = [powerBIExternalReportBaseUrl];

export const AssistPointReportManagement: React.FC = () => {
   const { httpGet, httpPut } = useApiContext();
   const { removeErrors } = useErrorContext();
   const [selectedItem, setSelectedItem] = React.useState<PowerBIReportToPractice>();
   const [isEditorOpen, setIsEditorOpen] = React.useState<boolean>(false);
   const { powerBIExternalReportList, isLoading, isSaving } = useFetchPowerBIExternalReportList(httpGet);

   const firstOfType = (reportType: string, order: number): boolean => {
      const sameType = powerBIExternalReportList.filter(y => y.reportType === reportType);
      sameType.sort((a, b) => numberComparer(a.order, b.order));
      return order <= sameType[0].order
   }

   const lastOfType = (reportType: string, order: number): boolean => {
      const sameType = powerBIExternalReportList.filter(y => y.reportType === reportType);
      sameType.sort((a, b) => numberComparer(a.order, b.order));
      return order >= sameType[sameType.length - 1].order
   }

   const reorderReport = async (item: PowerBIReportToPractice, direction: OrderDirection) => {
      let index = powerBIExternalReportList.findIndex(i => i.id === item.id);
      let duplicateList = [...powerBIExternalReportList];
      duplicateList.splice(direction === OrderDirection.Up ? index - 1 : index + 1, 0, duplicateList.splice(index, 1)[0]);
      const orderedItems = duplicateList.map(i => i.id);
      // send update request
      await updateReportOrder(httpPut, orderedItems)
         .catch(err => console.error('Error updateReportOrder', err));
   }

   React.useEffect(() => {
      removeErrors({ keysLike: _keysLike });
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []) 

   const handleCloseEditorClick = () => {
      setIsEditorOpen(false);
      setSelectedItem(undefined);
   }

   if (isLoading || powerBIExternalReportList?.length === 0) {
      return <Spinner />
   }

   const tableColumns: ColumnsType<PowerBIReportToPractice> = [
      {
         dataIndex: 'id',
         width: 100,
         render: (text, record) => {
            const isFirstOfType = firstOfType(record.reportType, record.order);
            const isLastOfType = lastOfType(record.reportType, record.order);
            return <Space size={0}>
                  <ActionButton
                     disabled={isFirstOfType || isSaving}
                     style={{
                        borderColor: colorWheel.mediumGrey
                     }}
                     icon={isFirstOfType ? <CustomIcon type={CustomIconType.BorderOutlined}
                        style={{
                           color: colorWheel.graniteGrey,
                        }}
                     /> : <CustomIcon type={CustomIconType.UpSquareOutlined}
                        style={{
                           color: colorWheel.graniteGrey,
                        }} />}
                     onClick={() => reorderReport(record, OrderDirection.Up)}
                  />
                  <ActionButton
                     disabled={isLastOfType || isSaving}
                     style={{
                        borderColor: colorWheel.mediumGrey
                     }}
                     icon={isLastOfType ? <CustomIcon type={CustomIconType.BorderOutlined}
                        style={{
                           color: colorWheel.graniteGrey,
                        }}
                     /> : <CustomIcon type={CustomIconType.DownSquareOutlined}
                        style={{
                           color: colorWheel.graniteGrey,
                        }}
                     />}
                     onClick={() => reorderReport(record, OrderDirection.Down)}
                  />
               </Space>
         }
      },
      {
         title: 'Report Name',
         dataIndex: 'reportName',
      },
      {
         title: 'Report Description',
         dataIndex: 'reportDescription',
         width: '40%',
      },
      {
         title: 'Report Page Name',
         dataIndex: 'reportPageName',
      },
      {
         title: 'Report Type',
         dataIndex: 'reportType',
         width: 120,
      },
      {
         title: 'Only Admin Visible',
         dataIndex: 'isOnlyAdminVisible',
         width: 80,
         render: (text, record) =>
            <CustomIcon
               style={{
                  color: record.isOnlyAdminVisible ? colorWheel.ivyGreen : colorWheel.red,
                  fontSize: 20,
                  fontWeight: 'bold'
               }}
               type={record.isOnlyAdminVisible ? CustomIconType.CheckOutlined : CustomIconType.CloseOutlined}
            />
      },
      {
         title: 'Active',
         dataIndex: 'isActive',
         width: 80,
         render: (text, record) =>
            <CustomIcon
               style={{
                  color: record.isActive ? colorWheel.ivyGreen : colorWheel.red,
                  fontSize: 20,
                  fontWeight: 'bold'
               }}
               type={record.isActive ? CustomIconType.CheckOutlined : CustomIconType.CloseOutlined}
            />
      },
      {
         title: '',
         dataIndex: 'actions',
         width: 80,
         fixed: 'right',
         render: (text, record) => <EditButton
               onClick={() => {
                  setIsEditorOpen(true);
                  setSelectedItem(record);
               }} />
      },
   ];

   return (
      <>
         <ApiErrorDisplay
            title='Error saving/retrieving PowerBIReportManagement'
            keysLike={_keysLike} />

         <SearchResultsTable
            rowkey={'id'}
            columns={tableColumns}
            data={powerBIExternalReportList}
            titleText='AssistPoint Report Management'
            fixedHeader={true}
            scrollHeight={215}
         />

         {isEditorOpen && 
            <PowerBIReportEditor
               isOpen={isEditorOpen}
               closeEditor={() => handleCloseEditorClick()}
               id={selectedItem?.id}
               />
         }
      </>
   );
}
export default AssistPointReportManagement;