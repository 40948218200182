import * as React from 'react';
import Dashboard from './Dashboard';
import IdleSession from './auth/IdleSession';
import { AuthError, PendingAuthentication, SignIn, UnauthorizedUser } from './auth/SignIn';
import { XAuthProvider, useUserContext } from './auth/authContext';
import Spinner from './components/Spinner';
import { NotificationContextProvider } from './components/notification/NotificationContext';
import SignalRNotification from './components/notification/SignalRNotification';
import AlpacaOverlay from './layouts/AlpacaOverlay';
import { LayoutContextProvider } from './layouts/LayoutContext';
import { FlyoutMenuContextProvider } from './layouts/menu/FlyoutMenuContext';
import { ApiContextProvider } from './store/ApiContext';
import { ErrorContextProvider } from './store/ErrorContext';
import { useFetchSettings } from './store/SettingsFetcher';
import { RecentlyViewedReportsContextProvider } from './layouts/menu/RecentlyViewedReportsContext';

export const App: React.FC = () => {
   const { settings, isLoading } = useFetchSettings();
   //console.log('app is rendering'); // being used for monitoring after moving around a bunch of contexts
   if (!settings || settings.length === 0 || isLoading) {
      return (
         <div className="full-height">
            <div id="landing-media" className="overlay square-overlay">
               <Spinner style={{ height: '100vh' }} message="Configuring your session..." />
            </div>
         </div>)
   }
   return (
      <AppConfig />
   );
};

const AppConfig: React.FC = () => {
   return (
      <XAuthProvider>
         <NotificationContextProvider>
            <ErrorContextProvider>
               <ApiContextProvider>
                  <AppRoot />
               </ApiContextProvider>
            </ErrorContextProvider>
         </NotificationContextProvider>
      </XAuthProvider>
   );
};

const AppRoot: React.FC = () => {

   const { user, loading, unauthorizedUser, loginError, signOut } = useUserContext();

   if (loginError) {
      return <AuthError />;
   }

   if (unauthorizedUser) {
      return <UnauthorizedUser />;
   }

   if (loading) {
      return <PendingAuthentication />;
   }

   if (!user) {
      return <SignIn redirectPath={window.location.pathname} />;
   }

   return (
      <>
         <AlpacaOverlay />
         <IdleSession signOut={signOut} />
         <SignalRNotification />

         <LayoutContextProvider>

            <FlyoutMenuContextProvider>
               <RecentlyViewedReportsContextProvider>
                  <Dashboard />
               </RecentlyViewedReportsContextProvider>
            </FlyoutMenuContextProvider>

         </LayoutContextProvider >


      </>
   );
};
