import * as React from 'react';
import { Modal, Spin, Typography, Row, Col, Space } from 'antd';
import colorWheel from '../Theme/ColorWheel';
import { SaveButton, CancelButton } from './shared/Buttons';

export const styles = {
   header: {
      backgroundColor: colorWheel.plumPurple,
      color: colorWheel.white,
      padding: 10
   },
   body: {
      padding: 10
   },
   actions: {
      backgroundColor: 'whitesmoke',
      padding: 7
   }
}

interface Props {
   title?: string;
   closeIcon?: boolean;
   size?: 'mini' | 'tiny' | 'small' | 'large' | 'fullscreen';
   style?: React.CSSProperties;
   contentStyle?: React.CSSProperties;
   scrollingContent?: boolean;
   saveButtonText?: string;
   onSave?: Function;
   onClose?: Function;
   actionButtons?: React.ReactNode;
   open?: boolean;
   pending?: boolean;
   children?: React.ReactNode;
};

const { Title } = Typography;

const getSize = (size?: 'mini' | 'tiny' | 'small' | 'large' | 'fullscreen'): number => {
   switch (size) {
      case 'mini':
         return 360;
      case 'tiny':
         return 540;
      case 'small':
         return 692;
      case 'large':
         return 992;
      case 'fullscreen':
         return 1400;
      default:
         return 416;
   }
}

const Dialog = (props: Props) => {
   const {
      //Optional props
      title,      //What to display in the Modal.Header  (could even be a Component/JSX)
      closeIcon,  //bool: if true, displays X in upper right above modal to indicate the modal can close 
      size,       //enum: determined by Modal API
      style,      //inline css to be applied to the Modal
      contentStyle, //inline css to be applied to the Modal content container
      scrollingContent, //bool: whether modal content should scroll or not
      //   saveButtonText, //Text to pass into save button
      onSave,     //Custom function to execute when the user clicks "Save"
      onClose,    //Custom function to execute when the user clicks "Close" or clicks off of the modal, causing it to close
      //  - important: if making use of the open prop, you'll need to pass in a close function to set the open prop to false 

      actionButtons,  //Custom buttons to render in addition to or in place of the "Save", "Close", or "Okay" buttons
      //  - useful for extra specialized save/close control
      //  - also in conjunction with the open prop
      open,        //bool: allows developer to manually control the dialog opening outside of the trigger
      pending     //bool: when true shows loader inside dimmer to prevent user input
   } = props;

   const [openModal, setOpenModal] = React.useState<boolean>(false);

   let modalProps = {};
   modalProps = {
      ...modalProps,
      centered: true,
      width: getSize(size)
   }
   let bodyStyle: React.CSSProperties = styles.body;

   if (scrollingContent) {
      bodyStyle = {
         ...bodyStyle,
         overflowY: 'auto',
         overflowX: 'hidden',
         maxHeight: 'calc(100vh - 10rem)'
      }
   }
   if (contentStyle) {
      bodyStyle = {
         ...bodyStyle,
         ...contentStyle
      }
   }

   const closeModal = () => {
      if (onClose) onClose();
      setOpenModal(false);
   }

   const saveModal = () => {
      if (onSave) onSave();
      setOpenModal(false);
   }

   return <Modal
      mask={true}
      destroyOnClose={true}
      closable={closeIcon ?? false}
      onCancel={() => closeModal()}
      className={'dialog'}
      title={
         <Row gutter={[32, 32]}>
            <Col span={24}>
               <Title level={4} style={styles.header}>{title}</Title>
            </Col>
         </Row>
      }
      bodyStyle={bodyStyle}
      {...modalProps}
      centered={true}
      style={style}
      //The lines below concerning open and openModal allow the developer to have manual control in when to open/close the dialog
      open={open === undefined ? openModal : open}

      footer={<>
         {actionButtons &&
            <div style={{ padding: '1rem' }}>
               <Space>{actionButtons}</Space>
            </div>
         }
         {onSave &&
            <div style={{ padding: '1rem' }}>
               <Space>
                  <CancelButton onClick={() => closeModal()} />
                  <SaveButton onClick={() => saveModal()} />
               </Space>
            </div>
         }
         {!onSave && !actionButtons &&
            <div style={{ padding: '1rem' }}>
               <CancelButton buttonText='Okay' onClick={() => closeModal()} />
            </div>
         }
      </>}
   >
      {pending && <Row gutter={[32, 32]}>
         <Col span={24}>
            <Space direction="horizontal" style={{ width: '100%', justifyContent: 'center' }}>
               <Spin spinning={true} />
            </Space>
         </Col>
      </Row>
      }
      {!pending && props.children}
   </Modal>
}
export default Dialog;
