import * as React from 'react';
import { ReportNav } from './ReportNav';
import { ReportView } from './ReportView';
import "./Report.css";
import { ReportContextProvider } from './ReportContext';

export const ReportIndex: React.FC = () => {

   return (
      <ReportContextProvider>
         <ReportMain />
      </ReportContextProvider>
   )
}

const ReportMain: React.FC = () => {

   const html = (
      <div style={{ display: 'flex', flexDirection: 'row', height: 'calc(100vh - 50px)' }}>
         <ReportNav />
         <ReportView />
     </div>
   )

   return html;
}