//https://github.com/vercel/swr/issues/110
import { useState, useEffect } from 'react';

export default function useDebounce<T>(value: T, delay?: number) {
   const [debouncedValue, setDebouncedValue] = useState(value);
   // if value is empty, execute immediately
   delay = !value ? 0 : (delay || 800);

   useEffect(
      () => {
         const handler = setTimeout(() => {
            setDebouncedValue(value);
         }, delay);

         return () => {
            clearTimeout(handler);
         };
      },
      [value, delay]
   );

   return debouncedValue;
}
