import { useState, useEffect } from 'react';
import { HttpError, TGet } from '../../functions/httpClient';
import { IOptionItem } from '../../functions/option.functions';
import { IStatus } from '../models';
import useSWR from 'swr';
import { Subscription } from './SubscriptionModel';

export const subscriptionBaseUrl = `api/practice/subscription`;

const swrOptions = {
   refreshInterval: 0,
   revalidateOnFocus: false,
   dedupingInterval: 60000
};


export function useFetchSubscriptions(httpGet: TGet): IStatus & { subscriptions: Subscription[] } {
   const { data, isValidating, error } = useSWR<Subscription[], HttpError>(
      subscriptionBaseUrl,
      httpGet,
      { ...swrOptions }
   );

   return {
      subscriptions: data,
      isLoading: !error && !data && isValidating,
      error: error?.message
   };
}


export function useAllSubscriptionOptions(httpGet: TGet): { subscriptionOptions: IOptionItem[] } {
   const { subscriptions } = useFetchSubscriptions(httpGet);
   const [options, setOptions] = useState<IOptionItem[]>([]);

   useEffect(() => {
      const buildOptions = () => {
         if (subscriptions?.length > 0) {
            const options = subscriptions.map((o): IOptionItem => ({
               key: o.id,
               label: `[${o.id}]: ${o.name}`,
               value: o.id
            } as IOptionItem));
            setOptions(options);
         }
      }
      buildOptions();

   }, [subscriptions]);


   return {
      subscriptionOptions: options
   };
}

export function useActiveSubscriptionOptions(httpGet: TGet): { subscriptionOptions: IOptionItem[] } {
   const { subscriptions } = useFetchSubscriptions(httpGet);
   const [options, setOptions] = useState<IOptionItem[]>([]);

   useEffect(() => {
      const buildOptions = () => {
         if (subscriptions?.length > 0) {
            // Only active Subscriptions
            const options = subscriptions.filter(y => y.isActive).map((o): IOptionItem => ({
               key: o.id,
               label: `[${o.id}]: ${o.name}`,
               value: o.id
            } as IOptionItem));
            setOptions(options);
         }
      }
      buildOptions();

   }, [subscriptions]);


   return {
      subscriptionOptions: options
   };
}