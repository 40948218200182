import { stringComparer } from "../../functions/comparer.functions";
import { CreateUserStatus } from "./UserModel";

export interface IUserMigrationStatus {
   userMainId: number; // key
   migrationStatus?: CreateUserStatus;
   isLoading?: boolean;
}

export interface MigrationState {
   migrationStatusRecords: IUserMigrationStatus[];
}

export const ADD_STATUS = 'ADD_STATUS';
export const REMOVE_STATUS = 'REMOVE_STATUS';

export function MigrationStatusReducer(state: MigrationState, action: any) {
   switch (action.type) {
      case ADD_STATUS:
         return {
            migrationStatusRecords: [
               ...state.migrationStatusRecords?.filter(y => y.userMainId !== action.payload.userMainId),
               action.payload
            ]
         };
      case REMOVE_STATUS:
         return {
            migrationStatusRecords: [
               ...state.migrationStatusRecords?.filter(y => y.userMainId !== action.payload.userMainId)
            ]
         };
      default:
         console.warn('MigrationStatusReducer was called with an unexpected type', action?.type);
         return state;
   }
}

export default MigrationStatusReducer;