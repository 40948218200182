import Modal from 'antd/lib/modal/Modal';
import * as React from 'react';
import ApiErrorDisplay from '../components/ApiErrorDisplay';
import CustomIcon, { CustomIconType } from '../components/shared/AntComponents/CustomIcon';
import { ActionButton } from '../components/shared/Buttons';
import { useApiContext } from '../store/ApiContext';
import { adminPortalChangePasswordUrl, assistpointChangePasswordUrl, requestAdminPortalPasswordChange, requestAssistpointPasswordChange } from '../store/auth/ChangePasswordFetcher';
import { useFetchUserMain, userMainBaseUrl } from '../store/auth/UserMainFetcher';
import { useErrorContext } from '../store/ErrorContext';

interface IProps {
   userMainId: number;
   showButtonText?: boolean;
}

const _keysLike: string[] = [userMainBaseUrl];

const ChangePasswordButton: React.FC<IProps> = (props) => {
   const { userMainId, showButtonText } = props;
   const _keys: string[] = [assistpointChangePasswordUrl(userMainId), adminPortalChangePasswordUrl(userMainId)];
   const { httpPatch, httpGet } = useApiContext();
   const { removeErrors } = useErrorContext();
   const { data: userMainList } = useFetchUserMain(httpGet);

   const [isLoading, setIsLoading] = React.useState<boolean>();
   const [showErrorModal, setShowErrorModal] = React.useState<boolean>(false);

   const handleOk = () => {
      removeErrors({ keys: _keys, keysLike: _keysLike });
      setShowErrorModal(false);
   }

   const handleOnClick = () => {

      // determine if practice or adminportal user by having profiles or not.
      const userMain = userMainList.find(uml => uml.id === userMainId);
      if (userMain?.userProfileIds?.length > 0) {
         (async () => {
            setIsLoading(true);
            requestAssistpointPasswordChange(httpPatch, userMainId)
               .catch(() => setShowErrorModal(true))
               .finally(() => setIsLoading(false));
         })();
      } else {
         (async () => {
            setIsLoading(true);
            await requestAdminPortalPasswordChange(httpPatch, userMainId)
               .catch(() => setShowErrorModal(true))
               .finally(() => setIsLoading(false));
            setIsLoading(false);
         })();
      }
   }

   return (
      <>
         <ActionButton
            icon={<CustomIcon type={CustomIconType.UserSwitchOutlined} />}
            buttonText={showButtonText ? 'Change Password' : null}
            onClick={() => handleOnClick()}
            loading={isLoading}
            title='Change Password' />
         <Modal
            closable={false}
            title='Change Password Error'
            cancelButtonProps={{ style: { display: 'none' } }}
            open={showErrorModal}
            onOk={() => handleOk()}
         >
            <ApiErrorDisplay title='Save Password Change Failed' keys={_keys} keysLike={_keysLike} />
         </Modal>
      </>
   );
}

export default ChangePasswordButton;