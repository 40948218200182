import * as React from "react";
import { Foundation }from '../../store/program/FoundationModel';

interface Props {
    foundation: Foundation;
    scrapingStatusStyle: React.CSSProperties;
}

const FoundationDetail: React.FC<Props> = (props) => {
    return (
        <>
            <div className='view_heading'>
                <div className='content'>
                    <div className='uk-flex'>
                        <div className='default-image'>
                            {props.foundation.logoUrl && <img alt="" src={props.foundation.logoUrl} className='header-funding-img' />}
                        </div>
                        <div className='header-funding-content'>
                            <div className='data-row'>
                                <div className='label'><label>Foundation Name</label></div>
                                <div><span><h2>{props.foundation.foundationName}</h2></span></div>
                            </div>
                            <div className='data-row'>
                                <div><label>Foundation Description</label></div>
                                <div><span><b>{props.foundation.foundationDescription}</b></span></div>
                            </div>
                            <div className='data-row'>
                                <div><label>URL</label></div>
                                <div><span><b>{props.foundation.foundationUrl}</b></span></div>
                            </div>
                        </div>
                    </div>
                    <div className='uk-flex'>
                        <div className='header-funding-bottom-content'>
                            <div className='data-row'>
                                <div className='label'><label>Practice ID</label></div>
                                <div><span><b>{props.foundation.practice}</b></span></div>
                            </div>
                            <div className='data-row'>
                                <div className='label'><label>Scraping Status</label></div>
                                <div><span style={props.scrapingStatusStyle}><b>{props.foundation.scrapingStatus}</b></span></div>
                            </div>
                            <div className='data-row'>
                                <div className='label'><label>Last Scraped</label></div>
                                <div><span><b>00:00:00</b></span></div>
                            </div>
                            <div className='data-row'>
                                <div className='label'><label>Has Wait List?</label></div>
                                <div><span><b>{props.foundation.hasWaitList ? 'Yes' : 'No'}</b></span></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default FoundationDetail;