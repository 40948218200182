import * as React from 'react';
import { Space } from 'antd';
import { Form, Row, Col } from 'antd';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Dialog from '../Dialog';
import { InputField, DropdownField, CheckboxField, DatePickerField } from '../shared/InputLibrary';
import { SaveButton, DeleteButton, CancelButton } from '../shared/Buttons';
import { TitleError } from '../shared/AntComponents/Typography/Title';
import Spinner from '../Spinner';
import ApiErrorDisplay from '../ApiErrorDisplay';
import { useApiContext } from '../../store/ApiContext';
import {
   useFetchPracticeFederation, savePracticeFederation, deletePracticeFederation,
   practiceFederationBaseUrl
} from '../../store/auth/PracticeFederationFetcher';
import { PracticeFederation } from '../../store/auth/PracticeFederationModel';
import { useErrorContext } from '../../store/ErrorContext';

interface IProps {
   isOpen: boolean;
   closeEditor: () => void;
   practiceFederationId?: number;
   practiceIdForNew?: number;
}

const dialogContentStyle = {
}

const yupPracticeFederationSchema = yup.object({
   id: yup.number().notRequired(),
   practiceId: yup.number()
      .required('Practice is required'),
   domain: yup.string()
      .trim()
      .matches(/^[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}$/, 'Must be  valid domain in the format "domain.com"')
      .max(255, 'Max length is 255 characters')
      .required('Domain is required'),
});


interface IPracticeFederationSchemaYup extends yup.Asserts<typeof yupPracticeFederationSchema> { }
const __formId = "frmPracticeFederation";

const _keysLike: string[] = [practiceFederationBaseUrl];

const PracticeFederationEditor: React.FC<IProps> = (props) => {
   const { isOpen, closeEditor, practiceFederationId, practiceIdForNew } = props;
   const { httpGet, httpPost, httpDelete } = useApiContext();
   const { removeErrors } = useErrorContext();
   const { practiceFederations } = useFetchPracticeFederation(httpGet);

   const [isFormSaving, setIsFormSaving] = React.useState<boolean>(false);

   const {
      control,
      handleSubmit,
      formState: { errors },
      reset,
      getValues
   } = useForm<IPracticeFederationSchemaYup>({
      resolver: yupResolver<yup.AnyObject>(yupPracticeFederationSchema),
      shouldFocusError: true
   });

   React.useEffect(() => {
      let resetVal = {};
      if (practiceFederationId && practiceFederations) {
         resetVal = practiceFederations.find(y => y.id === practiceFederationId);
      } else if (practiceIdForNew && !practiceFederationId) {
         resetVal = { practiceId: practiceIdForNew } as PracticeFederation;
      }
      reset(resetVal);
      // for "reset"
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [practiceFederationId, practiceIdForNew, practiceFederations]);

   const [form] = Form.useForm();

   const onSubmit = (data: IPracticeFederationSchemaYup): void => {
      //ts hoops to convert data with unknown props into assistance programs
      const item = data as unknown as PracticeFederation;
      setIsFormSaving(true);
      savePracticeFederation(httpPost, item)
         .then(handleClose)
         .catch(err => console.error('Error Saving Practice Federation', err))
         .finally(() => {
            setIsFormSaving(false);
         });
   }

   const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState<boolean>(false);

   const handleDeleteClick = () => {
      setIsDeleteDialogOpen(false);
      deletePracticeFederation(httpDelete, getValues().id)
         .then(handleClose)
         .catch(err => console.error('Error Deleting Practice Federation', err));
   }

   const handleClose = () => {
      setIsFormSaving(false);
      setIsDeleteDialogOpen(false);
      removeErrors({ keysLike: _keysLike });
      closeEditor();
   }

   if (isFormSaving) {
      return <div><Spinner message='Saving Practice Federation...' /></div>
   }

   const html = (
      <Dialog
         scrollingContent={true}
         title={practiceFederationId ? 'Practice Domain Editor':'Add New Domain'}
         open={isOpen}
         size="small"
         actionButtons={
            <>
               <CancelButton buttonText='Cancel' onClick={() => {
                  handleClose();
               }} />
               {practiceFederationId && 
               <DeleteButton
                  onClick={() => setIsDeleteDialogOpen(true)}
                  disabled={!getValues()?.id || isDeleteDialogOpen}
                  loading={isDeleteDialogOpen} />
               }
               <SaveButton
                  loading={isFormSaving}
                  onClick={() => form.submit()}
                  disabled={Object.keys(errors).length > 0}
                  formId={__formId}
               />
            </>
         }>
         <div style={dialogContentStyle}>

            {errors && Object.keys(errors).length > 0 &&
               <>
                  <TitleError text='Please correct the errors below' />
                  {console.log('--------------Form Errors: -------------------------')}
                  {console.log(errors)}
               </>
            }

            <ApiErrorDisplay
               title='Error saving Practice Domain'
               keysLike={_keysLike}
            />

            <Row gutter={[32, 32]} justify="space-between" align="bottom">
               <Col span={12}>
                  <Form
                     form={form}
                     id={__formId}
                     onFinish={handleSubmit(onSubmit)}>

                     {practiceFederationId &&
                        <InputField
                           control={control}
                           name='id'
                           label='Id'
                           type='text'
                           error={errors?.id}
                           disabled={true}
                           required={true}
                        />
                     }
                     
                     <InputField
                        control={control}
                        name='domain'
                        label='Domain'
                        type='text'
                        error={errors?.domain}
                        required={true}
                        tooltip='Domain should be in the format of "domain.com".'
                     />                     

                  </Form>
               </Col>
            </Row>

         </div>

         <Dialog
            title='CONFIRM DELETE'
            open={isDeleteDialogOpen}
            style={{ maxWidth: '550px' }}
            actionButtons={
               <Space>
                  <CancelButton onClick={() => setIsDeleteDialogOpen(false)} />
                  <DeleteButton
                     disabled={isFormSaving}
                     onClick={() => handleDeleteClick()} />
               </Space>
            }
         >
            {`Are you sure you want to Delete the Practice Domain?`}
         </Dialog>

      </Dialog>
   )
   return html;
}

export default PracticeFederationEditor;

