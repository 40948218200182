import useSWR from 'swr';
import { HttpError, TGet } from '../../functions/httpClient';
import { FundStatusAlert } from './FundStatusAlertModel';

const fundStatusAlertBaseUrl = `/api/fsa/FundStatusAlert`;
export const getFundStatusAlertUrl = (foundationId: number) => `${fundStatusAlertBaseUrl}/${foundationId}`;

const swrOptions = {
   refreshInterval: 0,
   revalidateOnFocus: false,
   dedupingInterval: 60000     //default 2000
};

export const useGetFundStatusAlertFundDetails = (httpGet: TGet, foundationId: number) => {

   const shouldFetch = foundationId ? true : false;
   const { data, isValidating, error } = useSWR<FundStatusAlert[], HttpError>(
      shouldFetch ? getFundStatusAlertUrl(foundationId) : null,
      httpGet,
      { ...swrOptions }
   );

   return {
      fundStatusAlerts: data,
      isLoading: !error && !data && isValidating,
      error: error as HttpError
   };
}
