import useSWR, { mutate } from 'swr';
import { HttpError, TPost, TGet, TDelete } from '../../functions/httpClient';
import {
   ManualEnrollmentReasonOption,
   ManualEnrollmentReasonOptionViewModel,
   connTypeList
} from './ManualEnrollmentReasonOptionModel';
import { useEffect, useState } from 'react';

export const manualEnrollmentReasonBaseUrl = `api/program/ManualEnrollmentReasonOption`;
const manualEnrollmentReasonSaveUrl = `${manualEnrollmentReasonBaseUrl}/Save`;
const manualEnrollmentReasonDeleteUrl = (id: number) => `${manualEnrollmentReasonBaseUrl}/Delete/${id}`;

const swrOptions = {
   refreshInterval: 0,
   revalidateOnFocus: false,
   dedupingInterval: 60000     //default 2000
};


export const useManualEnrollmentReasons = (httpGet: TGet) => {

   const { data, isLoading, error } = useSWR<ManualEnrollmentReasonOption[], HttpError>(
      manualEnrollmentReasonBaseUrl,
      httpGet,
      { ...swrOptions }
   );

   return {
      manualEnrollmentReasonOptions: data,
      isLoading: isLoading,
      error: error
   };
}

export const saveManualEnrollmentReason = async (httpPost: TPost, model: ManualEnrollmentReasonOption) => {
   await httpPost(manualEnrollmentReasonSaveUrl, model);
   mutate(manualEnrollmentReasonBaseUrl);
}

export const deleteManualEnrollmentReason = async (httpDelete: TDelete, id: number) => {
   await httpDelete(manualEnrollmentReasonDeleteUrl(id));
   mutate(manualEnrollmentReasonBaseUrl);
}


export const useManualEnrollmentReasonViewModel = (httpGet: TGet) => {
   const { manualEnrollmentReasonOptions, isLoading, error } = useManualEnrollmentReasons(httpGet);
   const [viewModel, setViewModel] = useState<ManualEnrollmentReasonOptionViewModel[]>([]);

   useEffect(() => {
      const buildViewModel = () => {
         if (manualEnrollmentReasonOptions?.length > 0) {
            let tempList = manualEnrollmentReasonOptions.map((item): ManualEnrollmentReasonOptionViewModel => {

               const p = connTypeList.find(y => y.value === item.connectivityType);

               return {
                  ...item,
                  connectivityTypeDesc: p?.label
               } as ManualEnrollmentReasonOptionViewModel;
            });
            setViewModel(tempList);
         }
      }
      buildViewModel();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [manualEnrollmentReasonOptions, connTypeList]);


   return {
      manualEnrollmentReasonOptionViewModel: viewModel,
      isLoading: isLoading, 
      error: error, 
   };
}
