import * as React from 'react';
import { useApiContext } from '../../../store/ApiContext';
import { useUserMainViewModel } from '../../../store/auth/UserMainFetcher';
import { useFetchPractice } from '../../../store/practice/PracticeFetcher';
import { formatPracticeName } from '../../../store/practice/PracticeModel';
import { useUserProfile } from '../../../store/practice/UserProfileFetcher';
import { UserProfile, UserProfileViewModel } from '../../../store/practice/UserProfileModel';
import UserProfileEditor from './UserProfileEditor';
import { useNavigate } from 'react-router';

interface ICopyWithPracticeUserProfileEditorProps {
   copyFromUserProfileId: number;
   practiceId?: number;
   userMainId: number;
   onCloseEditor: () => void;
   isEditorOpen: boolean;
}

const CopyWithPracticeUserProfileEditor: React.FC<ICopyWithPracticeUserProfileEditorProps> = (props) => {
   const {
      copyFromUserProfileId,
      userMainId,
      practiceId,
      onCloseEditor,
      isEditorOpen
   } = props;

   const { httpGet } = useApiContext();
   const { userProfile } = useUserProfile(httpGet, copyFromUserProfileId);
   const { userMainViewModel } = useUserMainViewModel(httpGet, userMainId);
   const { practices } = useFetchPractice(httpGet);
   const navigate = useNavigate();

   const profileToEdit = React.useMemo(() => {
      let result = {} as UserProfileViewModel;
      if (userMainViewModel && userProfile && userMainId && copyFromUserProfileId) { // Existing UserMain, in Practice, copy from User
         // We're intentionally grabbing a bare UserProfile to feed as a UserProfileViewModel
         // because we don't want the extra data on the ViewModel - just the attributes on UserProfile
         const copiedProfile = JSON.parse(JSON.stringify(userProfile))
         result = {
            ...copiedProfile,
            userProfileId: null,
            practiceId: practiceId,
            externalUpn: userMainViewModel?.upn,
            isFollowedByAllowed: true,
         }
      } else if (userMainViewModel && practiceId) { // Existing UserMain, in Practice, net new user profile (as-in, not copied)
         result.externalUpn = userMainViewModel?.upn;
         result.practiceId = practiceId;
      } else if (!userMainId && practiceId) { // Net New User (no User Main), in Practice
         result.practiceId = practiceId;
      }
      return result;
   }, [practiceId, userMainViewModel, userProfile, userMainId, copyFromUserProfileId]);

   const dialogTitle = React.useMemo(() => {
      let result = 'Create New Practice User';

      if (practiceId && practices) {
         const practice = practices.find(y => y.id === practiceId);
         result = `Create New Practice User at ${formatPracticeName(practice?.name, practice?.id)}`
      }

      return result;
   }, [practiceId, practices])

   const onSubmitSuccess = (user: UserProfile) => {
      navigate(`/practice/${user.practiceId}/user/${user.userProfileId}`);
   }

   return <UserProfileEditor
      closeEditor={onCloseEditor}
      isEditorOpen={isEditorOpen}
      isEditorReadOnly={false}
      profileToEdit={profileToEdit}
      dialogTitle={dialogTitle}
      onSubmitSuccess={onSubmitSuccess}
      userMainId={userMainId}
   />
}

export default CopyWithPracticeUserProfileEditor;