import { Spin, Row, Col } from 'antd';
import React from 'react';

const Spinner: React.FC<{ style?: React.CSSProperties, message?: string, size?: 'small' | 'default' | 'large' }> = (props) => {
   const { style, message, size } = props;
   return (
      <Row justify="center" style={{ display: 'flex', alignItems: 'center', ...style }}>
         <Col >
            {/*style={{ padding: 25 }}*/}
            <Spin size={size??'large'}  />           
            {/* 
               - warning.js:19 Warning: [antd: Spin] `tip` only work in nest pattern.
               caused by adding a tip with no nested component: <Spin tip='Loading...' />

               -CR-99 To resolve the message.

               To remove console warning, tip is removed until CR-99 is implemented.  
               
               - resolution
               <Spin size={size??'large'} tip={message || 'Loading...'} ><div /></Spin> */}
         </Col>
      </Row>
   )
};

export default Spinner;