import { ColumnsType, TableProps } from 'antd/lib/table';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { useUserContext } from '../../../auth/authContext';
import Restricted from '../../../auth/Restricted';
import { isInRole } from '../../../functions/auth.functions';
import { boolComparer, numberComparer, stringComparer } from '../../../functions/comparer.functions';
import { formatBoolean } from '../../../functions/format.functions';
import { useApiContext } from '../../../store/ApiContext';
import { HttpVerb, KeyWithVerb, useErrorContext } from '../../../store/ErrorContext';
import { KnownSettings } from '../../../store/SettingsModel';
import {
   specialtyBaseUrl, useFetchSpecialtyViewModelList,
   useSpecialtyFoundationAndFoundationDiseaseOptions, useSpecialtyOptions
} from '../../../store/specialty/SpecialtyFetcher';
import {
   FoundationDiseaseTypeToSpecialtyDetail, FoundationDiseaseTypeToSpecialtyViewModel, Specialty
} from '../../../store/specialty/SpecialtyModels';
import ApiErrorDisplay from '../../ApiErrorDisplay';
import MinimalistTable from '../../shared/AntComponents/Table/MinimalistTable';
import SearchResultsTable from '../../shared/AntComponents/Table/SearchResultsTable';
import { BasicDropdownField } from '../../shared/BasicInputLibrary';
import { AddButton, EditButton } from '../../shared/Buttons';
import Spinner from '../../Spinner';
import SpecialtyEditor from './SpecialtyEditor';

const _keysWithVerb: KeyWithVerb[] = [{key: specialtyBaseUrl, verb: HttpVerb.GET}];

const SpecialtyList: React.FC = () => {
   const { httpGet } = useApiContext();
   const { removeErrors } = useErrorContext();
   const [currentPage, setCurrentPage] = React.useState(1);
   const [currentPageSize, setCurrentPageSize] = React.useState(25);
   const handleOnChange: TableProps<FoundationDiseaseTypeToSpecialtyViewModel>['onChange'] =
      (pagination, filters, sorter, extra) => setCurrentPage(pagination?.current);
   const { specialtyOptions } = useSpecialtyOptions(httpGet);

   const [specialtyFilter, setSpecialtyFilter] = React.useState<number>(undefined);
   const [foundationFilter, setFoundationFilter] = React.useState<number>(undefined);
   const [foundationDiseaseFilter, setFoundationDiseaseFilter] = React.useState<string>(undefined);
   const [selectedItem, setSelectedItem] = React.useState<Specialty>(undefined);
   const [isEditorOpen, setIsEditorOpen] = React.useState<boolean>(false);

   const { foundationDiseaseTypeToSpecialtyViewModels, isLoading, error } = useFetchSpecialtyViewModelList(httpGet);
   const { specialtyFoundationOptions, specialtyFoundationDiseaseOptions } = useSpecialtyFoundationAndFoundationDiseaseOptions(httpGet);

   React.useEffect(() => {
      removeErrors({ keysWithVerb: _keysWithVerb });
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []) // remove Errors on initial render

   const filterOnSpecialty = (model: FoundationDiseaseTypeToSpecialtyViewModel): boolean => {
      return !specialtyFilter || model.specialty.id === specialtyFilter;
   }

   const filterOnFoundation = (detail: FoundationDiseaseTypeToSpecialtyDetail): boolean => {
      return !foundationFilter || detail.foundationId === foundationFilter;
   }

   const filterOnFoundationDisease = (detail: FoundationDiseaseTypeToSpecialtyDetail): boolean => {
      return !foundationDiseaseFilter || detail.foundationDiseaseTypeName.toLowerCase() === foundationDiseaseFilter?.toLowerCase();
   }

   const filterGridContent = (records: FoundationDiseaseTypeToSpecialtyViewModel[]): FoundationDiseaseTypeToSpecialtyViewModel[] => {
      if (records && records.length > 0) {
         // creating a deep copy of the list
         let workingList: FoundationDiseaseTypeToSpecialtyViewModel[] = JSON.parse(JSON.stringify(foundationDiseaseTypeToSpecialtyViewModels));

         if (specialtyFilter) {
            workingList = workingList.filter(model => {
               return filterOnSpecialty(model);
            }) ?? [];
         }

         if (foundationFilter || foundationDiseaseFilter) {
            for (var idx = 0; idx < workingList.length; idx++) {
               var detailResults = workingList[idx].foundationDiseaseTypeToSpecialtyDetails.filter(detail => {
                  return filterOnFoundation(detail)
                     && filterOnFoundationDisease(detail);
               });

               workingList[idx].foundationDiseaseTypeToSpecialtyDetails = detailResults ?? [];
            }
         }
         return workingList;
      }
      return [];
   }

   const filteredData = React.useMemo(() => {
      if (foundationDiseaseTypeToSpecialtyViewModels?.length > 0) {
         return filterGridContent(foundationDiseaseTypeToSpecialtyViewModels);
      }
      // for "filterGridContent" - we actually need the two in this dependency array
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [foundationDiseaseTypeToSpecialtyViewModels, specialtyFilter, foundationFilter, foundationDiseaseFilter])

   const handleCloseEditorClick = () => {
      setIsEditorOpen(false);
      setSelectedItem(undefined);
   }

   if (!foundationDiseaseTypeToSpecialtyViewModels || foundationDiseaseTypeToSpecialtyViewModels.length <= 0 || isLoading) {
      return <Spinner />
   }

   const tableColumns: ColumnsType<FoundationDiseaseTypeToSpecialtyViewModel> = [
      {
         title: 'Id',
         dataIndex: ['specialty', 'id'],
         key: 1,
         width: 100
      },
      {
         title: 'Specialty',
         dataIndex: ['specialty', 'name'],
         key: 2,
         defaultSortOrder: 'ascend'
      },
      {
         title: 'Foundation Disease Count',
         dataIndex: ['foundationDiseaseTypeToSpecialtyDetails', 'length'],
         key: 3
      },
      {
         title: () =>
            <Restricted requiredRoles={[KnownSettings.ContentAdmin]}>
               <AddButton
                  title='Add New Specialty'
                  buttonText='New'
                  onClick={() => {
                     setSelectedItem(undefined);
                     setIsEditorOpen(true)
                  }} />
            </Restricted>,
         dataIndex: '',
         key: 4,
         fixed: 'right',
         width: 115,
         render: (text, record) =>
            <Restricted requiredRoles={[KnownSettings.ContentAdmin]}>
               <EditButton
                  title='Edit'
                  onClick={() => {
                     setIsEditorOpen(true);
                     setSelectedItem(record.specialty);
                  }} />
            </Restricted>
      }
   ];

   const buildExpandedHtml = (specialtyRecord: FoundationDiseaseTypeToSpecialtyViewModel) => {
      return <RenderSpecialtyDetailTable
         key={`${specialtyRecord.specialty.id}_table`}
         specialty={specialtyRecord.specialty}
         diseaseTypeToFoundations={specialtyRecord.foundationDiseaseTypeToSpecialtyDetails}
      />
   }

   const getRowKey = (specialtyRecord: FoundationDiseaseTypeToSpecialtyViewModel) => {
      return specialtyRecord.specialty.id.toString();
   }

   const html = (
      <>
         <ApiErrorDisplay
            title={'Error'}
            keysWithVerb={_keysWithVerb}
         />

         <SearchResultsTable
            rowkey={(record) => getRowKey(record as FoundationDiseaseTypeToSpecialtyViewModel)}
            onChange={handleOnChange}
            columns={tableColumns}
            data={filteredData}
            currentPage={currentPage}
            currentPageSize={currentPageSize}
            setCurrentPageSize={setCurrentPageSize}
            titleText='Specialty'
            fixedHeader={true}
            expandedRowRender={(record) => buildExpandedHtml(record)}
            additionalComponents={[
               <BasicDropdownField
                  containerStyle={{ maxWidth: 250 }}
                  label='Specialty'
                  placeholder='Filter by Specialty'
                  clearable={true}
                  onChange={(e) => setSpecialtyFilter(e ? Number(e) : undefined)}
                  name='specialtyFilter'
                  search={true}
                  options={specialtyOptions} />,
               <BasicDropdownField
                  containerStyle={{ maxWidth: 250 }}
                  label='Foundation'
                  placeholder='Filter by Foundation'
                  clearable={true}
                  onChange={(e) => setFoundationFilter(e ? Number(e) : undefined)}
                  name='foundationFilter'
                  search={true}
                  options={specialtyFoundationOptions} />,
               <BasicDropdownField
                  containerStyle={{ maxWidth: 250 }}
                  label='Foundation Disease Name'
                  placeholder='Filter by Foundation Disease Name'
                  clearable={true}
                  onChange={(e) => setFoundationDiseaseFilter(e ? String(e) : undefined)}
                  name='foundationDiseaseFilter'
                  search={true}
                  options={specialtyFoundationDiseaseOptions} />
            ]}
         />

         {isEditorOpen &&
            <SpecialtyEditor isOpen={isEditorOpen} closeEditor={() => handleCloseEditorClick()} specialty={selectedItem} />
         }
      </>
   );

   return html;
}

export default SpecialtyList

interface SpecialtyDetailTableProps {
   specialty: Specialty;
   diseaseTypeToFoundations: FoundationDiseaseTypeToSpecialtyDetail[]
}

const RenderSpecialtyDetailTable: React.FC<SpecialtyDetailTableProps> = (props) => {
   const { userRoles } = useUserContext();
   const canEdit = isInRole(userRoles, [KnownSettings.ContentAdmin]);

   const { diseaseTypeToFoundations, specialty } = props;

   const tableColumns: ColumnsType<FoundationDiseaseTypeToSpecialtyDetail> = [
      {
         title: 'foundationId',
         dataIndex: 'foundationId',
         key: 1,
         sorter: (a, b) => numberComparer(a.foundationId, b.foundationId),
         sortDirections: ['ascend', 'descend']
      },
      {
         title: 'foundationName',
         dataIndex: 'foundationName',
         key: 2,
         sorter: (a, b) => stringComparer(a.foundationName, b.foundationName),
         sortDirections: ['ascend', 'descend'],
         render: (text, record) => {
            return canEdit ? 
               <Link
                  to={{ pathname: `/program/Foundation/${record.foundationId}` }}
                  title={record.foundationName}>
                  {record.foundationName}
               </Link> :
               <p>{record.foundationName}</p>
         }
      },
      {
         title: 'foundationDiseaseTypeId',
         dataIndex: 'foundationDiseaseTypeId',
         key: 3,
         sorter: (a, b) => numberComparer(a.foundationDiseaseTypeId, b.foundationDiseaseTypeId),
         sortDirections: ['ascend', 'descend']
      },
      {
         title: 'foundationDiseaseTypeName',
         dataIndex: 'foundationDiseaseTypeName',
         key: 4,
         sorter: (a, b) => stringComparer(a.foundationDiseaseTypeName, b.foundationDiseaseTypeName),
         sortDirections: ['ascend', 'descend'],
         render: (text, record) => {
            return canEdit ?
               <Link
                  to={{ pathname: `/program/foundation/${record.foundationId}/diseaseType/${record.foundationDiseaseTypeId}` }}
                  title={record.foundationDiseaseTypeName}>
                  {record.foundationDiseaseTypeName}
               </Link> :
               <p>{record.foundationDiseaseTypeName}</p>
         }
      },
      {
         title: 'includeAllDiagnosis',
         dataIndex: 'includeAllDiagnosis',
         key: 5,
         sorter: (a, b) => boolComparer(a.includeAllDiagnosis, b.includeAllDiagnosis),
         sortDirections: ['ascend', 'descend'],
         render: (val) => formatBoolean(val)
      }
   ];

   // Based off of the Composite PK on dbo.FoundationDiseaseTypeToSpecialty to ensure a unique key for the table component
   const buildSpecialtyDetailTableRowKey = (record: FoundationDiseaseTypeToSpecialtyDetail, specialty: Specialty): string => {
      return `${record.foundationDiseaseTypeId}${specialty.id}`;
   }

   const html = (
      <>
         <MinimalistTable
            rowKey={(record) => buildSpecialtyDetailTableRowKey(record as FoundationDiseaseTypeToSpecialtyDetail, specialty)}
            columns={tableColumns}
            data={diseaseTypeToFoundations}
            showHeader={true}
         />
      </>
   );
   return html;
}
