import { IOptionItem } from '../../functions/option.functions';
import { PowerBIReport } from './ReportModel';

export interface PowerBIReportToPractice extends PowerBIReport {
   practiceIds: string,
   isActiveOverrideCount: number,
   isActiveMatchCount: number,
   isOnlyAdminVisible: boolean,
}

export const reportTypeOptions: IOptionItem[] = [
   { value: 'Patient', label: 'Patient' },
   { value: 'Summary', label: 'Summary' },
   { value: 'Management', label: 'Management' },
   { value: 'Billing', label: 'Billing' },
   { value: 'LSGeneral', label: 'LSGeneral' },
];