import { useState, useEffect, useMemo } from 'react';
import { HttpError, TPost, TPutFile, TGet } from '../../functions/httpClient';
import { IOptionItem } from '../../functions/option.functions';
import { IStatus, PagedList } from '../models';
import useSWR from 'swr';
import { mutateKeysLike, swrOptions } from '../../functions/swr.functions';
import {
   AssistanceServiceEnrollment, EnrollmentStatus,
   EnrollmentStatusSearchResult, EnrollmentStatusSearchRequest,
   formatApplicationProgramName, BetterEnrollmentUpload
} from './EnrollmentStatusModel';
import { stringComparer } from '../../functions/comparer.functions';

export const enrollmentStatusBaseUrl = `api/program/EnrollmentStatus`;
const enrollmentStatusGetUrl = (applicationId: number) => `${enrollmentStatusBaseUrl}/GetEnrollmentStatus/${applicationId}`
const enrollmentStatusGetServicesUrl = `${enrollmentStatusBaseUrl}/GetServices`
const enrollmentStatusPatientIdsUrl = (excludeIds: boolean) => `${enrollmentStatusBaseUrl}/GetPatientIds/${excludeIds}`;
const enrollmentStatusSaveUrl = `${enrollmentStatusBaseUrl}/SaveEnrollmentStatus`;
export const enrollmentStatusSearchUrl = `${enrollmentStatusBaseUrl}/Search`;
const enrollmentStatusUploadFileUrl = (
   enrollmentId: number,
   fileDescription: string) =>
   `${enrollmentStatusBaseUrl}/${enrollmentId}/?&fileDescription=${encodeURI(fileDescription)}`;

export function useEnrollmentStatusSearch(httpPost: TPost, request: EnrollmentStatusSearchRequest):
   IStatus &
   { pagedResult: PagedList<EnrollmentStatusSearchResult> } {

   const params = useMemo(() => {
      return request
   }, [request]);
   const fetcher = () => httpPost<PagedList<EnrollmentStatusSearchResult>>(enrollmentStatusSearchUrl, params);

   const { data, isValidating, error } = useSWR<PagedList<EnrollmentStatusSearchResult>, HttpError>(
      [enrollmentStatusSearchUrl, params], 
      fetcher,
      swrOptions
   );

   return {
      pagedResult: data,
      isLoading: !error && !data && isValidating,
      error: error?.message
   };
}

export function useFetchSingleEnrollmentStatus(httpGet: TGet, applicationId: number): IStatus & { enrollmentStatus: EnrollmentStatus } {
   const { data, isValidating, error } = useSWR<EnrollmentStatus, HttpError>(
      enrollmentStatusGetUrl(applicationId),
      httpGet,
      { ...swrOptions }
   );

   return {
      enrollmentStatus: data,
      isLoading: !error && !data && isValidating,
      error: error?.message
   };
}

export function useFetchEnrollmentStatusPatientIds(httpGet: TGet, excludeIds: boolean): IStatus & { patientIds: string[] } {
   const { data, isValidating, error } = useSWR<string[], HttpError>(
      enrollmentStatusPatientIdsUrl(excludeIds),
      httpGet,
      swrOptions
   );

   return {
      patientIds: data,
      isLoading: !error && !data && isValidating,
      error: error?.message
   };
}

export function useFetchAssistanceServices(httpGet: TGet): IStatus & { availableServices: AssistanceServiceEnrollment[] } {
   const { data, isValidating, error } = useSWR<AssistanceServiceEnrollment[], HttpError>(
      enrollmentStatusGetServicesUrl,
      httpGet,
      swrOptions
   );

   return {
      availableServices: data,
      isLoading: !error && !data && isValidating,
      error: error?.message
   };
}

export const saveEnrollmentStatus = async (httpPost: TPost, enrollmentStatus: EnrollmentStatus) => {
   const result = await httpPost(enrollmentStatusSaveUrl, enrollmentStatus)
      .then(() => mutateKeysLike(enrollmentStatusSearchUrl));
   return result;
}

export const uploadEnrollmentFile = async (httpPutFile: TPutFile, uploadItem: BetterEnrollmentUpload) => {
   const result = await httpPutFile(
      enrollmentStatusUploadFileUrl(uploadItem.enrollmentId, uploadItem.fileDescription),
      uploadItem.file
   );
   return result;
}

export function useAvailableProgramOptions(httpGet: TGet): { availableProgramOptions: IOptionItem[] } {
   const { availableServices } = useFetchAssistanceServices(httpGet);
   const [options, setOptions] = useState<IOptionItem[]>([]);

   useEffect(() => {
      const buildOptions = () => {
         if (availableServices?.length > 0) {
            const programOptions: IOptionItem[] = [];
            availableServices.forEach((service) => {
               if (!programOptions.some(opt => opt.value === service.assistanceProgramId)) {
                  programOptions.push({
                     key: service.assistanceProgramId,
                     label: formatApplicationProgramName(service.assistanceProgramId, service.programName),
                     value: service.assistanceProgramId
                  } as IOptionItem)
               }
            });
            programOptions.sort((a: IOptionItem, b: IOptionItem) => stringComparer(a.label as string, b.label as string));
            setOptions(programOptions);
         }
      }
      buildOptions();
   }, [availableServices]);

   return {
      availableProgramOptions: options
   };
}
