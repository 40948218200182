import * as React from 'react';
import { useCallback } from 'react';
import { Routes, Route } from "react-router";
import { useParams } from 'react-router-dom';
import ContentCard from '../../layouts/ContentCard';
import FoundationView from '../content-tool/FoundationView';
import FoundationDiseaseTypeDetail from './FoundationDiseaseTypeDetail'

type Props = {};

const FoundationIndex: React.FC<Props> = (props) => {

   const [cardTitle, setCardTitle] = React.useState("Foundation Details");
   const params = useParams();

   const setCardHeader = useCallback((title: string) => {
      if (title !== cardTitle) setCardTitle(title ?? cardTitle);
   }, [cardTitle]);

   const rowhtml = (
      <ContentCard title={cardTitle}>
         <Routes>
            <Route path=':foundationId'
               element={
                  <FoundationView
                     {...params}
                     setCardHeader={setCardHeader}
                  />
               }>
            </Route>
            <Route path=':foundationId/diseaseType/:foundationDiseaseTypeId'
               element={
                  <FoundationDiseaseTypeDetail
                     {...params}
                     setCardHeader={setCardHeader}
                  />
               }>
            </Route>
            {/* sad note: react router 6 dropped support for optional params: :foundationDiseaseTypeId? 
                   * - here we are duplicating the route to work around it
                   */}
            <Route path=':foundationId/diseaseType'
               element={
                  <FoundationDiseaseTypeDetail
                     {...params}
                     setCardHeader={setCardHeader}
                  />
               }>
            </Route>
         </Routes>
      </ ContentCard>
    )
    
   return (
      rowhtml
    )
}

export default FoundationIndex;