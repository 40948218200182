import { Col, Empty, List, Row, Space } from 'antd';
import * as React from 'react';
import { useFetchFoundations } from '../../store/program/FoundationFetcher';
import { fundingStatusExcludedIdsBaseUrl, saveFundingStatusExcludedIds, useFetchFundingStatusExludedIds } from '../../store/program/FundingStatusExcludedIdFetcher';
import { FundingStatusExcludedId } from '../../store/program/FundingStatusExcludedIdModel';
import colorWheel from '../../Theme/ColorWheel';
import Dialog from '../Dialog';
import CustomIcon, { CustomIconType } from '../shared/AntComponents/CustomIcon';
import { NumberedTitle } from '../shared/AntComponents/Typography/Title';
import { BasicInputField } from '../shared/BasicInputLibrary';
import { ActionButton, AddButton, CancelButton, SaveButton } from '../shared/Buttons';
import Spinner from '../Spinner';
import { useApiContext } from '../../store/ApiContext';
import ApiErrorDisplay from '../ApiErrorDisplay';
import { useErrorContext } from '../../store/ErrorContext';

interface IProps {
   foundationId: number;
   isOpen: boolean;
   closeEditor: () => void;
};

const dialogContentStyle = {
   maxHeight: '95vh',

}

interface IExcludedIdToSave {
   isDeleted: boolean;
   excluded: FundingStatusExcludedId;
}

const _keysLike: string[] = [fundingStatusExcludedIdsBaseUrl];

const FundingStatusExcludedIdEditor: React.FC<IProps> = (props) => {
   const { closeEditor, foundationId, isOpen } = props;
   const { httpGet, httpPost } = useApiContext();
   const { removeErrors } = useErrorContext();
   const { fundingStatusExcludedIdList, isLoading } = useFetchFundingStatusExludedIds(httpGet);
   const [excludedIdList, setExcludedIdList] = React.useState<IExcludedIdToSave[]>([]);
   const { foundations } = useFetchFoundations(httpGet);
   const [newExcludedId, setNewExcludedId] = React.useState<string>(undefined);
   const [isFormSaving, setIsFormSaving] = React.useState<boolean>(false);

   const sortFunction = (a: IExcludedIdToSave, b: IExcludedIdToSave) => { return a.excluded.externalId < b.excluded.externalId ? -1 : 1 };

   const foundation = React.useMemo(() => {
      if (foundationId && foundations) {
         return foundations.find(y => y.id === foundationId);
      }
   }, [foundationId, foundations]);

   const excludedIdListOriginal = React.useMemo(() => {
      if (foundationId && fundingStatusExcludedIdList) {
         return fundingStatusExcludedIdList?.filter(y => y.foundationId === foundationId) ?? [];
      }
   }, [foundationId, fundingStatusExcludedIdList]);

   React.useEffect(() => {
      if (excludedIdListOriginal) {
         setExcludedIdList(excludedIdListOriginal.map((item): IExcludedIdToSave => ({
            excluded: item,
            isDeleted: false
         })))
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [excludedIdListOriginal]);

   const handleSelectExcludedIdClick = (excludedId: string) => {
      const matchOriginal = excludedIdListOriginal.find(i => i.externalId === excludedId);
      const match = excludedIdList.find(i => i.excluded.externalId === excludedId);

      //if in original list, then toggle deleted
      if (matchOriginal) {
         match.isDeleted = !match.isDeleted;
         setExcludedIdList(
            [
               ...excludedIdList.filter(y => y.excluded.externalId !== excludedId),
               match
            ]
         )
      } else {
         //not in original list, then just remove
         setExcludedIdList(
            [...excludedIdList.filter(y => y.excluded.externalId !== excludedId)]
         )
      }
   }

   const addNewExcludedIdClick = (excludedId: string) => {
      if (!excludedId) return;

      const match = excludedIdList.find(i => i.excluded.externalId === excludedId) ?? {
         isDeleted: false,
         excluded: {
            externalId: excludedId.trim(),
            foundationId: props.foundationId
         }
      } as IExcludedIdToSave;

      setExcludedIdList(
         [
            ...excludedIdList.filter(y => y.excluded.externalId !== excludedId),
            match
         ]
      );
      setNewExcludedId('');
   }

   

   const handleSaveClick = () => {
      const excludedIdsToSave: FundingStatusExcludedId[] =
         excludedIdList?.filter(y => !y.isDeleted)?.map((y: IExcludedIdToSave) => y.excluded);
      setIsFormSaving(true);
      saveFundingStatusExcludedIds(httpPost, props.foundationId, excludedIdsToSave)
         .then(() => {
            handleClose();
         })
         .catch(err => console.error('Error Saving Funding Status Excluded Ids', err))
         .finally(() => {
            setIsFormSaving(false);
         });
   }

   const handleClose = () => {
      setExcludedIdList([]);
      setIsFormSaving(false);
      removeErrors({ keysLike: _keysLike });
      closeEditor();
   }


   const html = (
      <Dialog
         title={`Funding Status Excluded Ids for ${foundation?.foundationName}`}
         open={isOpen}
         size="small"
         actionButtons={
            <>
               <CancelButton onClick={() => {
                  handleClose();
               }} />
               <SaveButton
                  onClick={() => handleSaveClick()}
                  loading={isFormSaving} />
            </>
         }>

         <div style={dialogContentStyle}>

            <Row gutter={[16, 16]} justify="end" align="middle">
               <Col>
                  <Space>
                     <BasicInputField
                        containerStyle={{ marginBottom: 0 }}
                        placeholder='Enter Excluded Id'
                        type="text"
                        name="newExcludedId"
                        value={newExcludedId}
                        autoFocus={true}
                        onChange={(e) => setNewExcludedId(String(e))} />
                     <AddButton onClick={() => addNewExcludedIdClick(newExcludedId)}
                        buttonText='New'></AddButton>
                  </Space>
               </Col>
            </Row>

            <NumberedTitle level={3} text='Excluded Ids' />

            <ApiErrorDisplay
               title='Error saving Funding Status Excluded Ids'
               keysLike={_keysLike}
            />

            {isLoading && <Spinner />}

            {excludedIdList.length === 0 && <Empty />}

            {excludedIdList.length > 0 &&
               <div style={{ height: '400px', overflowY: 'auto' }}>
                  <List
                     bordered
                     dataSource={excludedIdList.sort(sortFunction)}
                     renderItem={ic => (
                        <List.Item>
                           <span style={{
                              display: 'inline-block',
                              width: '90%',
                              backgroundColor: `${ic.isDeleted ? colorWheel.khakiTan : 'unset'}`,
                              textDecorationLine: `${ic.isDeleted ? 'line-through' : 'unset'}`,
                              textDecorationStyle: 'solid'
                           }}>{ic.excluded.externalId}</span>
                           <ActionButton
                              title='Delete'
                              danger
                              icon={<CustomIcon type={CustomIconType.DeleteOutlined} />}
                              onClick={() => handleSelectExcludedIdClick(ic.excluded.externalId)}
                           />

                        </List.Item>
                     )}
                  />
               </div>
            }
         </div>
      </Dialog>
   )
   return html;
}

export default FundingStatusExcludedIdEditor;
