import { Alert, Row, Col } from 'antd';
import * as React from 'react';
import { useApiContext } from '../../../store/ApiContext';
import { unmapUserProfile, unmapUserProfileUrl } from '../../../store/auth/UserMainFetcher';
import { useErrorContext } from '../../../store/ErrorContext';
import { formatPracticeName } from '../../../store/practice/PracticeModel';
import { UserProfile } from '../../../store/practice/UserProfileModel';
import ApiErrorDisplay from '../../ApiErrorDisplay';
import Dialog from '../../Dialog';
import { BasicFieldWrapper } from '../../shared/BasicInputLibrary';
import { CancelButton, SaveButton } from '../../shared/Buttons';

interface IProps {
   userMainId: number,
   userProfile: UserProfile,
   isDialogOpen: boolean,
   onDialogClose: () => void
}

const _keys: string[] = [unmapUserProfileUrl];

const UnmapUserProfileButton: React.FC<IProps> = (props) => {
   const { userMainId, userProfile, isDialogOpen, onDialogClose } = props;
   const { httpPost } = useApiContext();
   const { removeErrors } = useErrorContext();

   const [isSaving, setIsSaving] = React.useState(false);

   const handleClose = () => {
      removeErrors({ keys: _keys })
      onDialogClose();
   }

   const onConfirm = () => {
      setIsSaving(true);
      unmapUserProfile(httpPost, userMainId, userProfile.userProfileId)
         .then(handleClose)
         .catch(err => console.error('Error Unmapping', err))
         .finally(() => setIsSaving(false));
   }

   const html = (<>
      <Dialog
         title={'Confirm Unmap'}
         open={isDialogOpen}
         size={'small'}
         actionButtons={<>
            <CancelButton disabled={isSaving} onClick={handleClose} />
            <SaveButton
               buttonText={'Confirm'}
               onClick={onConfirm}
               loading={isSaving}
               disabled={isSaving}
            />
         </>}
      >
         <ApiErrorDisplay
            title={'Unmap User failed'}
            keys={_keys}
         />

         <div>
            <Row>
               <Alert type='warning'
                  message='Are you sure you want to Unmap this User Profile?' />
            </Row>
            <br />
            <Row>
               <Col span={8}>
                  <BasicFieldWrapper
                     label={'User Profile Id'}
                     field={userProfile.userProfileId}
                  />
               </Col>
               <Col span={8}>
                  <BasicFieldWrapper
                     label={'UPN'}
                     field={userProfile.externalUpn}
                  />
               </Col>
               <Col span={8}>
                  <BasicFieldWrapper
                     label={'Practice'}
                     field={formatPracticeName(userProfile.practiceName, userProfile.practiceId)}
                  />
               </Col>
            </Row>
            <Row>
               <Col span={8}>
                  <BasicFieldWrapper
                     label={'First Name'}
                     field={userProfile.firstName}
                  />
               </Col>
               <Col span={8}>
                  <BasicFieldWrapper
                     label={'Last Name'}
                     field={userProfile.lastName}
                  />
               </Col>
               <Col span={8}>
                  <BasicFieldWrapper
                     label={'Title'}
                     field={userProfile.title}
                  />
               </Col>
            </Row>
         </div>
      </Dialog>
   </>);

   return html;
}

export default UnmapUserProfileButton;