import { Card, Col, Empty, Row, Tag } from 'antd';
import * as React from 'react';
import colorWheel from '../../Theme/ColorWheel';
import Spinner from '../../components/Spinner';
import { ActionButton } from '../../components/shared/Buttons';
import { dateComparer } from '../../functions/comparer.functions';
import { formatDateTimeString } from '../../functions/format.functions';
import { FundStatusAlert, FundStatusAlertInsuranceDetails, FundStatusFieldChange } from '../../store/fsa/FundStatusAlertModel';
import {
   fundStatusFieldChangeBaseUrl, fundStatusFieldFindUrl, useFundStatusFieldChangeList
} from '../../store/fsa/FundStatusFieldFetcher';
import { FundStatusType } from '../../store/program/FundingStatusModel';
import { insuranceClassBaseUrl, useFetchInsuranceClassesActive } from '../../store/program/InsuranceClassFetcher';
import ApiErrorDisplay from '../ApiErrorDisplay';
import CustomIcon, { CustomIconType } from '../shared/AntComponents/CustomIcon';
import AlertEmailDialog from './AlertEmailDialog';

import { getFromNowDateString } from 'src/functions/time.functions';
import { useApiContext } from '../../store/ApiContext';
import DismissClassDialog from './DismissClassDialog';
import { useFundStatusContext } from './FundStatusContext';

const _keys: string[] = [];
const _keysLike: string[] = [fundStatusFieldChangeBaseUrl, fundStatusFieldFindUrl, insuranceClassBaseUrl];

const FundStatusChangeList: React.FC = () => {
   const { httpGet, httpPost } = useApiContext();
   const { setFieldChangeCount, fieldChangeFromDate } = useFundStatusContext();

   const { insuranceClasses, isLoading: isLoadingInsuranceClass } = useFetchInsuranceClassesActive(httpGet);
   const { fundStatusFieldChangeList, isLoading, lastChecked: fsaLastChecked } = useFundStatusFieldChangeList(httpPost, fieldChangeFromDate);

   const [selectedFundAlerts, setSelectedFundAlerts] = React.useState<FundStatusAlert[]>([]);
   const [isDismissModalOpen, setDismissModalOpen] = React.useState<boolean>(false);
   const [isSendAlertModalOpen, setIsSendAlertModalOpen] = React.useState<boolean>(false);
   const dismissBtnToolTipText = 'Dismiss';

   const filteredItems = fundStatusFieldChangeList?.sort((a: FundStatusFieldChange, b: FundStatusFieldChange) => dateComparer(a.createdOnBatch, b.createdOnBatch)) ?? [];
   React.useEffect(() => setFieldChangeCount(fundStatusFieldChangeList?.length ?? 0), [fundStatusFieldChangeList?.length, setFieldChangeCount]);

   const toggleRow = (sourceFund: FundStatusFieldChange, checked: boolean) => {
      const newList = [...selectedFundAlerts.filter(y => y.rowKey !== sourceFund.rowKey)];
      if (checked) {
         const originalSelectedFund = filteredItems.find(y => y.rowKey === sourceFund.rowKey);

         let selectedFund = selectedFundAlerts.find(y => y.rowKey === sourceFund.rowKey);
         if (!selectedFund) {
            selectedFund = {
               rowKey: sourceFund.rowKey,
               foundationId: sourceFund.foundationId,
               foundationName: sourceFund.foundationName,
               assistanceProgramId: sourceFund.assistanceProgramId,
               programName: sourceFund.programName,
               assistanceServiceId: sourceFund.assistanceServiceId,
               assistanceServiceName: sourceFund.assistanceServiceName,
               foundationDiseaseTypeId: sourceFund.foundationDiseaseTypeId,
               diseaseTypeName: sourceFund.diseaseTypeName,
               status: sourceFund.currentValue,
               statusText: FundStatusType[sourceFund.currentValue],
               alertDetails: []
            };
         }
         //only check those from the original fund -> they have a fundingstatusid and may have a fundingStatusFieldChangeLogId
         originalSelectedFund.changeDetails.filter(y => y.fundingStatusFieldChangeLogId > 0
            && !y.fundStatusEmailId && !y.dismissedOn  //AH-4384: exclude emailed or dismissed
         ).forEach(cd => {
            selectedFund.alertDetails.push({
               fundingStatusFieldChangeLogId: cd.fundingStatusFieldChangeLogId,
               fundingStatusId: cd.fundingStatusId,
               insuranceClassId: cd.insuranceClassId,
               insuranceClass: cd.insuranceClass
            });
         });

         selectedFundAlerts.push(selectedFund);

         setSelectedFundAlerts([
            ...newList,
            selectedFund
         ])
      } else {
         setSelectedFundAlerts([
            ...newList,
         ])
      }
   }

   const toggleChangeDetail = (sourceFund: FundStatusFieldChange,
      fundStatusInsuranceDetails: FundStatusAlertInsuranceDetails,
      checked: boolean) => {

      const newList = [...selectedFundAlerts.filter(y => y.rowKey !== sourceFund.rowKey)];
      let selectedFund = selectedFundAlerts.find(y => y.rowKey === sourceFund.rowKey);

      if (checked) {

         if (!selectedFund) {
            selectedFund = {
               rowKey: sourceFund.rowKey,
               foundationId: sourceFund.foundationId,
               foundationName: sourceFund.foundationName,
               assistanceProgramId: sourceFund.assistanceProgramId,
               programName: sourceFund.programName,
               assistanceServiceId: sourceFund.assistanceServiceId,
               assistanceServiceName: sourceFund.assistanceServiceName,
               foundationDiseaseTypeId: sourceFund.foundationDiseaseTypeId,
               diseaseTypeName: sourceFund.diseaseTypeName,
               status: sourceFund.currentValue,
               statusText: FundStatusType[sourceFund.currentValue],
               alertDetails: []
            };
         }
         selectedFund.alertDetails.push(fundStatusInsuranceDetails);

      } else {

         let selectedFund = selectedFundAlerts.find(y => y.rowKey === sourceFund.rowKey);
         if (selectedFund) {
            selectedFund.alertDetails = selectedFund?.alertDetails.filter(y => y.fundingStatusFieldChangeLogId !== fundStatusInsuranceDetails?.fundingStatusFieldChangeLogId)
         }
      }

      if (selectedFund?.alertDetails?.length > 0) {
         setSelectedFundAlerts([
            ...newList,
            selectedFund
         ])
      } else {
         setSelectedFundAlerts([
            ...newList,
         ])
      }
   }

   const sendMail = async () => {
      setIsSendAlertModalOpen(true);
   }

   const handleCloseSendAlertModalClick = (isFsaSent: boolean) => {
      setIsSendAlertModalOpen(false);
   }

   if (isLoading || isLoadingInsuranceClass)
      return <Spinner />

   return (
      <>
         <div style={{ textAlign: 'center', padding: '10px' }}>
            <p>FSAs last checked: <u><b>{formatDateTimeString(fsaLastChecked)}</b></u></p>
         </div>

         {filteredItems?.length === 0 &&
            <Empty />
         }

         {filteredItems?.length > 0 &&
            <div className='ap-fundstatus-container'
               style={{ width: '100%' }}>
               <div style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  width: '100%'
               }}>
                  <div style={{ margin: 10 }}>
                     <ActionButton
                        title='Send Mail'
                        buttonText='Send Mail'
                        icon={<CustomIcon type={CustomIconType.MailOutlined} />}
                        style={{
                           paddingLeft: 5,
                           paddingRight: 5
                        }}
                        onClick={() => sendMail()}
                        disabled={selectedFundAlerts?.length === 0} //AH-4384: {toggleSendMailBtn()}
                     />
                  </div>
                  <div style={{ margin: 10 }}>
                     <ActionButton
                        title={dismissBtnToolTipText}
                        buttonText='Dismiss'
                        danger
                        icon={<CustomIcon type={CustomIconType.CloseCircleOutlined} />}
                        style={{
                           paddingLeft: 5,
                           paddingRight: 5
                        }}
                        onClick={() => setDismissModalOpen(true) /*dismiss()*/}
                        loading={isDismissModalOpen}
                        disabled={!selectedFundAlerts.some(y => y.alertDetails.some(x => x.fundingStatusFieldChangeLogId))}
                     />
                  </div>
               </div>
               <div style={{ margin: 10 }}>
                  <ApiErrorDisplay
                     title='Api Error'
                     keys={_keys}
                     keysLike={_keysLike} />
               </div>
               {filteredItems?.map((item, i) => {

                  const alertedInsurnaceClasses = item.changeDetails.filter(y => y?.fundingStatusFieldChangeLogId > 0)
                     .map(y => {
                        const alertClass: FundStatusAlertInsuranceDetails = {
                           fundingStatusFieldChangeLogId: y.fundingStatusFieldChangeLogId,
                           fundingStatusId: y.fundingStatusId,
                           insuranceClassId: y.insuranceClassId,
                           insuranceClass: y.insuranceClass,
                           fundStatusEmailId: y.fundStatusEmailId,
                           dismissedOn: y.dismissedOn
                        };
                        return alertClass;
                     });
                  const otherInsuranceClasses = insuranceClasses?.filter(y => !alertedInsurnaceClasses.some(x => x.insuranceClassId === y.insuranceClassId))
                     .map(ic => {
                        const matchFundStatus = item.changeDetails.find(y => y.insuranceClassId === ic.insuranceClassId);
                        const otherClass: FundStatusAlertInsuranceDetails = {
                           insuranceClassId: ic.insuranceClassId,
                           insuranceClass: ic.name,
                           fundingStatusId: matchFundStatus?.fundingStatusId,
                           fundStatusEmailId: matchFundStatus?.fundStatusEmailId,
                           dismissedOn: matchFundStatus?.dismissedOn
                        };
                        return otherClass;
                     });
                  const currentSelectedFundAlert = selectedFundAlerts.find(y => y.rowKey === item.rowKey);
                  const allFundInsuranceClassesChecked = currentSelectedFundAlert?.alertDetails?.length > 0 &&
                     item.changeDetails.filter(y => y.fundingStatusFieldChangeLogId > 0
                        && !y.fundStatusEmailId && !y.dismissedOn  //AH-4384: exclude emailed or dismissed
                     ).every(y => currentSelectedFundAlert.alertDetails
                        .some(x => x.insuranceClassId === y.insuranceClassId));

                  return (<Row gutter={[16, 16]} key={item.rowKey}>
                     <Col style={{ width: '100%' }}>
                        <Card
                           style={{ margin: '0.5rem' }}
                           type='inner'
                           className='ap-fundstatuscard'
                           title={<div style={{ display: 'flex', flexDirection: 'row' }}>
                              <div style={{ paddingRight: 10 }}>
                                 <input type='checkbox'
                                    value={item.rowKey}
                                    checked={allFundInsuranceClassesChecked}
                                    onChange={(e) => toggleRow(item, e.target.checked)}
                                 />
                              </div>
                              <div>{`${item.foundationName} - ${item.programName}`}</div>
                           </div>}
                        >
                           <div style={{ paddingLeft: 30, paddingBottom: 5 }}>
                              <div>
                                 <span style={{ fontStyle: 'italic' }}>{item.assistanceServiceName}</span> - <span style={{ fontStyle: 'italic', fontWeight: 'bold' }}>{item.diseaseTypeName}</span>
                              </div>
                              <div style={{ paddingBottom: 3 }}>
                                 <Tag color={item.currentValue === FundStatusType.Open ? 'success' :
                                    item.currentValue === FundStatusType.Closed ? 'error' :
                                       item.currentValue === FundStatusType.ReenrollmentOnly ? 'warning' : 'info'} >{FundStatusType[item.currentValue]}</Tag>
                                 <span style={{ fontStyle: 'italic', fontSize: 12 }}>{formatDateTimeString(item.createdOnBatch)} ({getFromNowDateString(item.createdOnBatch)})</span>
                              </div>
                              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                 <div style={{ width: '50%', paddingTop: 10 }}>
                                    {alertedInsurnaceClasses.map(aic => {
                                       //AH-4384: exclude emailed or dismissed
                                       const isSelectable = !aic.fundStatusEmailId && !aic.dismissedOn;

                                       const toolTipText = aic?.fundStatusEmailId > 0 ? "This FSA has already been sent." :
                                          aic.dismissedOn ? 'This insurance class has been dismissed.' : '';
                                       return (<div key={`${item.rowKey}_${aic.insuranceClassId}`} style={{ flex: '50%', width: '100%' }}>
                                          <label title={toolTipText} style={{ width: '125px', display: 'flex' }}>
                                             <input type='checkbox'
                                                value={`${item.rowKey}_${aic.insuranceClassId ?? -1}`}
                                                checked={currentSelectedFundAlert?.alertDetails?.some(y => y.insuranceClassId === aic.insuranceClassId) ?? false}
                                                onChange={(e) => toggleChangeDetail(item, aic, e.target.checked)}
                                                disabled={!isSelectable}
                                             />
                                             <span style={{ fontWeight: 'bold' }}>{aic.insuranceClass}</span>
                                             <span style={{ paddingLeft: 7 }}>
                                                {aic.fundStatusEmailId ? <CustomIcon type={CustomIconType.MailOutlined} /> : ''}
                                                {aic.dismissedOn && !aic.fundStatusEmailId ? <CustomIcon style={{ color: colorWheel.red }} type={CustomIconType.CloseCircleOutlined} /> : ''}
                                             </span>
                                          </label>
                                       </div>)
                                    })}
                                 </div>
                                 {otherInsuranceClasses?.length > 0 &&
                                    <div style={{ padding: 10, width: '50%', color: colorWheel.graniteGrey, alignItems: 'center', border: `1px solid ${colorWheel.mediumGrey}` }}>
                                       <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
                                          title='* = no funding status record'>
                                          <label><u>Other Insurance Classes</u></label>
                                       </div>
                                       <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap' }} >
                                          {otherInsuranceClasses.map(oic => {
                                             const hasFsRecord = oic.fundingStatusId > 0;
                                             const toolTipText = oic?.fundStatusEmailId > 0 ? "This FSA has already been sent." :
                                                oic.dismissedOn ? 'This insurance class has been dismissed.' : '';
                                             return (
                                                <Row gutter={[0, 0]} key={`${item.rowKey}_o_${oic.insuranceClassId}`} justify='start'>
                                                   <Col xl={24} xxl={12}>
                                                      <label title={toolTipText} style={{ width: '125px', display: 'flex' }}>
                                                         <input type='checkbox'
                                                            value={`${item.rowKey}_${oic.insuranceClassId ?? -1}`}
                                                            checked={selectedFundAlerts.some(y => y.rowKey === item.rowKey && y.alertDetails.some(x => x.insuranceClassId === oic.insuranceClassId)) ?? false}
                                                            onChange={(e) => toggleChangeDetail(item, oic, e.target.checked)}
                                                         /><span style={{ fontStyle: 'italic' }}>{oic.insuranceClass}{`${hasFsRecord ? '' : '*'}`}</span>
                                                         <span style={{ paddingLeft: 7 }}>
                                                            {oic.fundStatusEmailId ? <CustomIcon type={CustomIconType.MailOutlined} /> : ''}
                                                            {oic.dismissedOn ? <CustomIcon style={{ color: colorWheel.red }} type={CustomIconType.CloseCircleOutlined} /> : ''}
                                                         </span>
                                                      </label>

                                                   </Col>
                                                </Row>
                                             )
                                          })}
                                       </div>
                                    </div>
                                 }
                              </div>
                           </div>
                        </Card>
                     </Col>
                  </Row>)
               })}
            </div>
         }
         {isSendAlertModalOpen &&
            <AlertEmailDialog
               fundAlerts={selectedFundAlerts}
               clearFundAlerts={() => {
                  setSelectedFundAlerts([]);
               }}
               isOpen={isSendAlertModalOpen}
               close={handleCloseSendAlertModalClick} />
         }
         {isDismissModalOpen &&
            <DismissClassDialog
               fundsToDismiss={selectedFundAlerts}
               fieldChangeFromDate={fieldChangeFromDate}
               setSelectedFundAlerts={setSelectedFundAlerts}
               clearFundAlerts={() => {
                  setSelectedFundAlerts([]);
               }}
               isOpen={isDismissModalOpen}
               close={() => setDismissModalOpen(false)} />
         }
      </>
   );

}
export default FundStatusChangeList;
