import * as React from 'react';
import { colorWheel } from '../../../Theme/ColorWheel';


const ReportMenuContainer: React.FC<React.PropsWithChildren> = (props) => {
  
   const html = (
      <div className="ReportNav "
         style={{
            flex: 18,
            overflowY: 'auto',
            overflowX: 'hidden'
         }}>

      <div style={{
         margin: 0, height: '100%',
         maxWidth: '300px', borderRight: '1px solid lightgray',
         backgroundColor: colorWheel.white,
      }}>
         {props.children}
         </div>
      </div>

   )

   return html;
}
export default ReportMenuContainer;
