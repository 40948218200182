import { useAssistanceProgramAndServicesOptions } from "../../../store/program/AssistanceServiceFetcher";
import { BasicDropdownField } from "../../shared/BasicInputLibrary";
import * as React from 'react';
import { CancelButton, SaveButton } from "../../shared/Buttons";
import { IOptionItem } from "../../../functions/option.functions";
import Dialog from "../../Dialog";
import { useApiContext } from "../../../store/ApiContext";

interface IProps {
   saveAssistanceServiceLink: (assistanceServiceId: number) => void;
   cancelAssistanceServiceLink: () => void;
   isOpen: boolean;
}

const AssistanceServiceSelectionDialog: React.FC<IProps> = (props) => {
   const { httpGet } = useApiContext();
   const { assistanceProgramAndServicesOptions } = useAssistanceProgramAndServicesOptions(httpGet);
   const [serviceAndProgramOptions, setServiceAndProgramOptions] = React.useState<IOptionItem[]>([]);
   const [selectedAssistanceService, setSelectedAssistanceService] = React.useState<number>();

   React.useEffect(() => {
      setServiceAndProgramOptions(assistanceProgramAndServicesOptions);
   }, [assistanceProgramAndServicesOptions]);

   const onCancel = () => {
      setSelectedAssistanceService(undefined);
      props.cancelAssistanceServiceLink();
   }

   const onSave = () => {
      props.saveAssistanceServiceLink(selectedAssistanceService);
      setSelectedAssistanceService(undefined);
   }

   const html = (
      <Dialog
         open={props.isOpen}
         title={'Assistance Service Selection'}
         size='small'
         actionButtons={
            <>
               <CancelButton onClick={onCancel} />
               <SaveButton
                  disabled={selectedAssistanceService === undefined}
                  onClick={onSave}
               />
            </>
         }
         >
            <BasicDropdownField
            placeholder={'- Select an Assistance Service -'}
            options={serviceAndProgramOptions}
            search
            value={selectedAssistanceService}
            onChange={(e) => setSelectedAssistanceService(e as number) }
            />
      </Dialog>
   );

   return html;
}

export default AssistanceServiceSelectionDialog;