import * as React from 'react';
import './AlpacaOverlay.css';
import apollo from '../images/apollo.png';


const AlpacaOverlay: React.FC = () => {

 
   const html = (
      <div className="alpaca-overlay-background">
         <div className="alpaca-overlay-content">
            <div className="alpaca">
               <img alt="Apollo the assistPoint Alpaca" title="Apollo the assistPoint Alpaca" src={apollo} className="logo" />
            </div>
            <div className="seprate-line"></div>
            <div className="alpaca-overlay-message">
               <h1>NOTICE !</h1>
               <p className="too-small">
                  Please increase browser width to view your Admin Portal.
               </p>
               <h3>~Apollo</h3>
            </div>
         </div>
      </div>
   );

   return html;
}

export default AlpacaOverlay;

