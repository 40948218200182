import * as React from 'react';
import { IOptionItem } from '../../../functions/option.functions';
import ContentCard from '../../../layouts/ContentCard';
import { Row, Col, Space, Divider } from 'antd';
import { useLayoutContext } from '../../../layouts/LayoutContext';
import { useApiContext } from '../../../store/ApiContext';
import { useAdparoPracticeOptions } from '../../../store/practice/PracticeFetcher';
import { useFetchUserProfiles } from '../../../store/practice/UserProfileFetcher';
import { saveScorecard, scorecardBaseUrl, useScorecardTemplate } from '../../../store/adparo/scorecard/ScorecardFetcher';
import { useParams } from 'react-router';
import { ActionButton, CancelButton } from '../../shared/Buttons';
import { BasicInputField, BasicDatePickerField, BasicDropdownField } from '../../shared/BasicInputLibrary';
import {
   Scorecard, ScorecardResponse,
   BUCKETVALUE_CRITICAL, BUCKETVALUE_TECHNICAL, BUCKETVALUE_NA, ScorecardTemplate
} from '../../../store/adparo/scorecard/ScorecardModel';
import Dialog from '../../Dialog';
import Spinner from '../../Spinner';
import ApiErrorDisplay from '../../ApiErrorDisplay';
import { useNavigate } from 'react-router';
import {
   ADPARO_ANNEXUS_ROLEID,
   ADPARO_BILLING_SPECIALIST_ANNEXUS_ROLEID,
   ADPARO_MBW_ROLEID,
   ADPARO_OMEGA_ROLEID
} from '../../../store/practice/UserRoleModel';
import { useErrorContext } from '../../../store/ErrorContext';

interface IValidationError {
   parameterId?: number;
   propertyName: string;
   error: string;
}

const bucketOptions: IOptionItem[] = [
   {
      key: BUCKETVALUE_TECHNICAL,
      value: BUCKETVALUE_TECHNICAL,
      label: BUCKETVALUE_TECHNICAL
   } as IOptionItem,
   {
      key: BUCKETVALUE_CRITICAL,
      value: BUCKETVALUE_CRITICAL,
      label: BUCKETVALUE_CRITICAL
   } as IOptionItem,
   {
      key: BUCKETVALUE_NA,
      value: BUCKETVALUE_NA,
      label: BUCKETVALUE_NA
   } as IOptionItem,
];

const ratingOptions: IOptionItem[] = [
   {
      key: 0,
      value: 0,
      label: 'No Error'
   } as IOptionItem,
   {
      key: 1,
      value: 1,
      label: 'Error'
   } as IOptionItem,
   {
      key: 2,
      value: 2,
      label: 'N/A'
   } as IOptionItem
]

const validUserRoles = [
   ADPARO_ANNEXUS_ROLEID,
   ADPARO_BILLING_SPECIALIST_ANNEXUS_ROLEID,
   ADPARO_MBW_ROLEID,
   ADPARO_OMEGA_ROLEID
]

const parameterStyle = {
   paddingLeft: '15px',
   width: '450px'
}

const bucketStyle = {
   width: '200px'
}

const ratingStyle = {
   width: '200px'
}

const commentStyle = {
   width: '600px'
}

const _keysLike: string[] = [scorecardBaseUrl];

const ScorecardForm: React.FC = () => {
   const { id } = useParams();
   const scorecardTemplateId: number = Number(id);

   const { httpGet, httpPost } = useApiContext();
   const { removeErrors } = useErrorContext();
   const { adparoPracticeOptions } = useAdparoPracticeOptions(httpGet);
   const { userProfiles } = useFetchUserProfiles(httpGet);
   const { scorecardTemplate: template } = useScorecardTemplate(httpGet, scorecardTemplateId);
   const navigate = useNavigate();
   const { cardName } = useLayoutContext();

   const [editItem, setEditItem] = React.useState<Scorecard>(undefined);
   const [validationErrors, setValidationErrors] = React.useState<IValidationError[]>([]);
   const [isFormSaving, setIsFormSaving] = React.useState(false);
   const [changeToggle, setChangeToggle] = React.useState(false);
   const [resultsModalOpen, setResultsModalOpen] = React.useState(false);
   const [savedSuccessfully, setSavedSuccessfully] = React.useState(false)

   const userOptions = React.useMemo(() => {
      if (!editItem?.practiceId || !userProfiles) return;

      const usersForPractice = userProfiles.filter(y => y.practiceId === editItem?.practiceId && validUserRoles.some(r => y.userRoles?.includes(r)));
      const options = usersForPractice.map((user): IOptionItem => {
         return ({
            value: user.userProfileId,
            label: `${user.firstName} ${user.lastName}`,
            key: user.userProfileId
         } as IOptionItem)
      });
      return options;

   }, [editItem?.practiceId, userProfiles]);

   React.useEffect(() => {
      if (!template) return;
      
      buildDefaultScorecard(template);
      // No, we don't need to add this function to the dependency array
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [template])

   // Build set of inputs/reponses based on the template that was chosen
   const buildDefaultScorecard = (buildFromTemplate: ScorecardTemplate) => {
      const responses: ScorecardResponse[] = [];
      buildFromTemplate.classes.forEach(model => {
         model.parameters.forEach(param => {
            responses.push({ bucket: param.bucket, parameterId: param.id, parameter: param });
         })
      });

      setEditItem({ responses: responses, planTypeId: buildFromTemplate.planTypeId });
   }

   const clearScorecard = () => {
      buildDefaultScorecard(template);
      setValidationErrors([]);
   }

   const onSubmit = () => {
      if (validateScorecard()) {
         setIsFormSaving(true);
         setResultsModalOpen(true);
         saveScorecard(httpPost, editItem)
            .then(() => {
               setSavedSuccessfully(true);
            })
            .catch(err => console.error('Error Saving Scorecard', err))
            .finally(() => {
               setIsFormSaving(false);
            })
      }
   }

   const updateObjProp = (obj: any, propertyName: string, value: string | number | Date) => {
      obj[propertyName] = value;
      setChangeToggle(!changeToggle);
   }

   const validateScorecard = (): boolean => {
      let scorecardValidationErrors: IValidationError[] = [];

      if (!editItem.practiceId) scorecardValidationErrors.push({ propertyName: 'practiceId', error: 'Practice is required' });
      if (!editItem.userProfileId) scorecardValidationErrors.push({ propertyName: 'userProfileId', error: 'Adparo FC is required' });
      if (!editItem.patientId) scorecardValidationErrors.push({ propertyName: 'patientId', error: 'MRN is required' });
      if (!editItem.scorecardDate) scorecardValidationErrors.push({ propertyName: 'scorecardDate', error: 'Date is required' });

      editItem.responses.forEach(response => {
         if (response.bucket === undefined) {
            // Buckets have a default value and no "[null]" option so this should never happen... however, its part of our requirements so
            scorecardValidationErrors.push({ parameterId: response.parameter.id, propertyName: 'bucket', error: 'Bucket is required' });
         }

         if (response.rating === undefined) {
            scorecardValidationErrors.push({ parameterId: response.parameter.id, propertyName: 'rating', error: 'Rating is required' });
         }
      });

      setValidationErrors(scorecardValidationErrors);
      return scorecardValidationErrors.length === 0;
   }

   const getValidationError = (propertyName: string, parameterId?: number): string => {
      if (validationErrors) {
         if (parameterId) {
            return validationErrors.find(y => y.parameterId === parameterId && y.propertyName === propertyName)?.error;
         }
         else {
            return validationErrors.find(y => y.propertyName === propertyName)?.error;
         }
      }
      return null;
   }

   const html = (
      <ContentCard title={cardName} style={{ width: '100%', minHeight: '50vh', overflow: 'hidden' }}>
         {(!template || !(template?.classes?.length > 0)) && <Spinner message={'Loading Template...'} />}
         {template && template?.classes?.length > 0 && <>
            <Row gutter={[16, 16]}>
               <Col span={6}>
                  <BasicDropdownField
                     name='practiceId'
                     label='Practice'
                     multiple={false}
                     options={adparoPracticeOptions}
                     required={true}
                     search={true}
                     value={editItem?.practiceId}
                     onChange={(val) => {
                        updateObjProp(editItem, 'practiceId', val as number);
                     }}
                  />
                  {getValidationError('practiceId') &&
                     <span className='error-item'>{getValidationError('practiceId')}</span>
                  }
               </Col>
               <Col span={6}>
                  <BasicDropdownField
                     name='userProfileId'
                     label='Adparo FC'
                     multiple={false}
                     options={userOptions}
                     required={true}
                     search={true}
                     disabled={editItem?.practiceId === undefined}
                     value={editItem?.userProfileId}
                     onChange={(val) => {
                        updateObjProp(editItem, 'userProfileId', val as number)
                     }}
                  />
                  {getValidationError('userProfileId') &&
                     <span className='error-item'>{getValidationError('userProfileId')}</span>
                  }
               </Col>
               <Col span={6}>
                  <BasicInputField
                     name='patientId'
                     label='MRN/Ticket #'
                     required={true}
                     value={editItem?.patientId}
                     onChange={(val) => {
                        updateObjProp(editItem, 'patientId', val as string)
                     }}
                  />
                  {getValidationError('patientId') &&
                     <span className='error-item'>{getValidationError('patientId')}</span>
                  }
               </Col>
               <Col span={6}>
                  <BasicDatePickerField
                     name={'scorecardDate'}
                     label='Date'
                     required={true}
                     value={editItem?.scorecardDate}
                     onChange={(val) => {
                        updateObjProp(editItem, 'scorecardDate', val as unknown as Date)
                        setEditItem({
                           scorecardDate: val as unknown as Date,
                           ...editItem
                        })
                     }}
                  />
                  {getValidationError('scorecardDate') &&
                     <span className='error-item'>{getValidationError('scorecardDate')}</span>
                  }
               </Col>
            </Row>
            <Divider />
            {getValidationError('form') &&
               <>
                  <span className='error-item'>{getValidationError('form')}</span>
                  <br />
               </>
            }
            <>
               <table style={{ border: 'solid' }}>
                  <thead>
                     <tr>
                        <th>Class</th>
                        <th>Parameter</th>
                        <th>Bucket</th>
                        <th>Rating</th>
                        <th>Comment</th>
                     </tr>
                  </thead>
                  <tbody>
                     {template.classes.map((paramClass, index) => {
                        return (
                           <React.Fragment key={index}>
                              <tr key={paramClass.parameters[0]?.id} style={{ border: 'solid', padding: '5px' }}>
                                 <td style={{ width: '150px', border: 'solid' }} rowSpan={paramClass.parameters.length}>
                                    {paramClass.name}
                                 </td>
                                 <td style={parameterStyle}>
                                    {paramClass.parameters[0]?.parameter}
                                 </td>
                                 <td style={bucketStyle}>
                                    <BasicDropdownField style={{ padding: '5px' }}
                                       key={`${paramClass.parameters[0]?.id}_bucket`}
                                       options={bucketOptions}
                                       value={editItem?.responses?.find(y => y.parameter.id === paramClass.parameters[0]?.id)?.bucket}
                                       onChange={(val) => updateObjProp(editItem?.responses?.find(y => y.parameter.id === paramClass.parameters[0]?.id), 'bucket', val as string)}
                                    />
                                    {getValidationError('bucket', paramClass.parameters[0]?.id) &&
                                       <span className='error-item'>{getValidationError('bucket', paramClass.parameters[0]?.id)}</span>
                                    }
                                 </td>
                                 <td style={ratingStyle}>
                                    <BasicDropdownField style={{ padding: '5px' }}
                                       key={`${paramClass.parameters[0]?.id}_rating`}
                                       options={ratingOptions}
                                       value={editItem?.responses?.find(y => y.parameter.id === paramClass.parameters[0]?.id)?.rating}
                                       onChange={(val) => updateObjProp(editItem?.responses?.find(y => y.parameter.id === paramClass.parameters[0]?.id), 'rating', val as number)}
                                    />
                                    {getValidationError('rating', paramClass.parameters[0]?.id) &&
                                       <span className='error-item'>{getValidationError('rating', paramClass.parameters[0]?.id)}</span>
                                    }
                                 </td>
                                 <td style={commentStyle}>
                                    <BasicInputField
                                       key={`${paramClass.parameters[0]?.id}_comment`}
                                       value={editItem?.responses?.find(y => y.parameter.id === paramClass.parameters[0]?.id)?.comment}
                                       onChange={(val) => {
                                          val = (val as string).length >= 200 ? (val as string).substring(0, 199) : val;
                                          updateObjProp(editItem?.responses?.find(y => y.parameter.id === paramClass.parameters[0]?.id), 'comment', val as string);
                                       }}
                                    />
                                 </td>
                              </tr>

                              {paramClass.parameters && paramClass.parameters.length > 1 && paramClass.parameters.map((param, paramIndex) => {
                                 {/*We've displayed the first parameter already so we need to skip it, but we still need to iterate over it to keep the index correct*/ }
                                 if (paramIndex == 0) return;

                                 return <tr key={param.id} style={{ border: 'solid', padding: '5px' }}>
                                    <td style={parameterStyle}>
                                       {param.parameter}
                                    </td>
                                    <td style={bucketStyle}>
                                       <BasicDropdownField style={{ padding: '5px' }}
                                          key={`${param.id}_bucket`}
                                          options={bucketOptions}
                                          value={editItem?.responses?.find(y => y.parameter.id === paramClass.parameters[paramIndex]?.id)?.bucket}
                                          onChange={(val) => updateObjProp(editItem?.responses?.find(y => y.parameter.id === paramClass.parameters[paramIndex]?.id), 'bucket', val as string)}
                                       />
                                       {getValidationError('bucket', paramClass.parameters[paramIndex]?.id) &&
                                          <span className='error-item'>{getValidationError('bucket', paramClass.parameters[paramIndex]?.id)}</span>
                                       }
                                    </td>
                                    <td style={ratingStyle}>
                                       <BasicDropdownField style={{ padding: '5px' }}
                                          key={`${param.id}_rating`}
                                          options={ratingOptions}
                                          value={editItem?.responses?.find(y => y.parameter.id === paramClass.parameters[paramIndex]?.id)?.rating}
                                          onChange={(val) => updateObjProp(editItem?.responses?.find(y => y.parameter.id === paramClass.parameters[paramIndex]?.id), 'rating', val as number)}
                                       />
                                       {getValidationError('rating', paramClass.parameters[paramIndex]?.id) &&
                                          <span className='error-item'>{getValidationError('rating', paramClass.parameters[paramIndex]?.id)}</span>
                                       }
                                    </td>
                                    <td style={commentStyle}>
                                       <BasicInputField style={{ padding: '5px' }}
                                          key={`${param.id}_comment`}
                                          value={editItem?.responses?.find(y => y.parameter.id === paramClass.parameters[paramIndex]?.id)?.comment}
                                          onChange={(val) => updateObjProp(editItem?.responses?.find(y => y.parameter.id === paramClass.parameters[paramIndex]?.id), 'comment', val as string)}
                                       />
                                    </td>
                                 </tr>
                              })}
                           </React.Fragment>
                        )
                     })}
                  </tbody>
               </table>
               <br />
               <Row gutter={[16, 16]}>
                  <Col span={6} />
                  <Col span={6} />
                  <Col span={6} />
                  <Col span={6}>
                     <Space>
                        <CancelButton
                           buttonText={'Clear Scorecard'}
                           onClick={clearScorecard}
                        />
                        <ActionButton
                           title={'Save Scorecard'}
                           buttonText={'Save'}
                           onClick={onSubmit}
                           disabled={isFormSaving}
                           loading={isFormSaving} />
                     </Space>
                  </Col>
               </Row>
               <br />
            </>

            <Dialog
               title={'Confirmation Dialog'}
               open={resultsModalOpen}
               size={'tiny'}
               actionButtons={
                  <ActionButton
                     disabled={isFormSaving}
                     title={'Confirm'}
                     buttonText={'Confirm'}
                     onClick={() => {
                        setResultsModalOpen(false)
                        removeErrors({ keysLike: _keysLike });
                        if (savedSuccessfully) {
                           navigate('/adparo');
                        }
                     }}
                  />
               }
            >
               {isFormSaving &&
                  <Spinner message={'Saving...'} />
               }
               {!isFormSaving &&
                  <>
                     <ApiErrorDisplay
                     title='Error saving Scorecard'
                     keysLike={_keysLike}
                  />

                     {savedSuccessfully &&
                        <>
                           <h3>Scorecard saved successfully!</h3>
                           <p>Click Confirm below to close this dialog and navigate back to Adparo Scorecard</p>
                        </>
                     }
                  </>
               }
            </Dialog>
         </>}
      </ContentCard>
      
   )

   return html;
}

export default ScorecardForm
