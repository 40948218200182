import { Tabs } from 'antd';
import { ColumnsType, TableProps } from 'antd/lib/table';
import * as React from 'react';
import Restricted from '../../../auth/Restricted';
import { numberComparer, stringComparer } from '../../../functions/comparer.functions';
import { AssistanceProgram, ProgramType, programTypeOptions } from '../../../store/program/AssistanceProgramModel';
import { AssistanceProgramResource } from '../../../store/program/AssistanceProgramResourceModel';
import { useFetchAssistanceProgramResources } from '../../../store/program/AssistanceProgramResourceFetcher';
import { AssistanceService } from '../../../store/program/AssistanceServiceModel';
import { useFetchAssistanceServices } from '../../../store/program/AssistanceServiceFetcher';
import { KnownSettings } from '../../../store/SettingsModel';
import HighlightSearchText from '../../HighlightSearchText';
import SearchResultsTable from '../../shared/AntComponents/Table/SearchResultsTable';
import { BasicDropdownField, BasicInputField } from '../../shared/BasicInputLibrary';
import { AddButton, EditButton } from '../../shared/Buttons';
import Spinner from '../../Spinner';
import { useFetchAssistancePrograms } from '../../../store/program/AssistanceProgramFetcher';
import AssistanceProgramEditor from './AssistanceProgramEditor';
import AssistanceProgramResourceRow from './AssistanceProgramResourceRow';
import AssistanceServiceRow from './AssistanceServiceRow';
import { useApiContext } from '../../../store/ApiContext';
import { useServiceCategories } from '../../../store/program/ServiceCategoryFetcher';


const AssistanceProgramIndex: React.FC = () => {
   const { httpGet } = useApiContext();

   const [currentPage, setCurrentPage] = React.useState(1);
   const [currentPageSize, setCurrentPageSize] = React.useState(25);
   const handleOnChange: TableProps<AssistanceProgram>['onChange'] = (pagination, filters, sorter, extra) => setCurrentPage(pagination?.current);
   const { serviceCategories } = useServiceCategories(httpGet);
   const { assistancePrograms, isLoading } = useFetchAssistancePrograms(httpGet);
   const { assistanceServices, isLoading: isLoading_AssistanceServices } = useFetchAssistanceServices(httpGet);
   const { assistanceProgramResources, isLoading: isLoading_AssistanceProgramResources } = useFetchAssistanceProgramResources(httpGet);
   const [programNameFilter, setProgramNameFilter] = React.useState<string>('');
   const [programTypeFilter, setProgramTypeFilter] = React.useState<number>();
   const [filteredItems, setFilteredItems] = React.useState<AssistanceProgram[]>([]);

   const [selectedAssistanceProgramId, setSelectedAssistanceProgramId] = React.useState<number>();
   const [isAssistanceProgramEditorOpen, setIsAssistanceProgramEditorOpen] = React.useState<boolean>(false);

   const [selectedAssistanceServiceId, setSelectedAssistanceServiceId] = React.useState<number>();
   const [selectedAssistanceProgramResourceId, setSelectedAssistanceProgramResourceId] = React.useState<number>();

   React.useEffect(() => {
      createFilteredItems();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [assistancePrograms,
      programNameFilter,
      programTypeFilter]);

   const createFilteredItems = () => {
      if (!assistancePrograms) return;

      let newList = assistancePrograms.filter(model => {
         return filterProgramName(model) && filterProgramType(model);
      }) ?? [];

      setFilteredItems(newList);
   }

   const filterProgramName = (m: AssistanceProgram): boolean => {
      return !programNameFilter || m.programName.toLowerCase().indexOf(programNameFilter.toLowerCase()) > -1 ? true : false;
   }

   const filterProgramType = (m: AssistanceProgram): boolean => {
      if (programTypeFilter === undefined)
         return true;

      if (m.programType === programTypeFilter)
         return true;

      return false;
   }

   const handleCloseAssistanceProgramEditorClick = () => {
      setIsAssistanceProgramEditorOpen(false);
      setSelectedAssistanceProgramId(undefined);
   }

   const handleCloseAssistanceServiceEditorClick = () => {
      setSelectedAssistanceServiceId(undefined);
      setSelectedAssistanceProgramId(undefined);
   }

   const handleCloseAssistanceProgramResourceEditorClick = () => {
      setSelectedAssistanceProgramResourceId(undefined);
      setSelectedAssistanceProgramId(undefined);
   }

   const sortAssistanceServices = (inList: AssistanceService[]) => {
      if (!inList || inList.length <= 0) return;
      return inList.sort((a: AssistanceService, b: AssistanceService) => numberComparer(a.id, b.id));
   }

   const sortAssistanceProgramResource = (inList: AssistanceProgramResource[]) => {
      if (!inList || inList.length <= 0) return;
      return inList.sort((a: AssistanceProgramResource, b: AssistanceProgramResource) => numberComparer(a.id, b.id));
   }


   if (isLoading || isLoading_AssistanceServices || isLoading_AssistanceProgramResources) {
      return <Spinner />
   }

   const programGridColumns: ColumnsType<AssistanceProgram> = [
      {
         title: 'Id',
         dataIndex: 'id',
         key: 1,
         sorter: (a, b) => numberComparer(a.id, b.id),
         sortDirections: ['ascend', 'descend'],
         width: '7%'
      },
      {
         title: 'Program Name',
         dataIndex: 'programName',
         key: 2,
         sorter: (a, b) => stringComparer(a.programName, b.programName),
         sortDirections: ['ascend', 'descend'],
         defaultSortOrder: 'ascend',
         width: '10%',
         render: (text, record) =>
            <HighlightSearchText searchString={programNameFilter} targetString={record?.programName} />
      },
      {
         title: 'Program Type',
         dataIndex: 'programType',
         key: 3,
         width: '13%',
         sorter: (a, b) => stringComparer(a.programType.toString(), b.programType.toString()),
         sortDirections: ['ascend', 'descend'],
         render: (text, record) => `${ProgramType[record.programType]} [${record.programType}]`
      },
      {
         title: 'Program Description',
         dataIndex: 'programDescription',
         key: 4,
         width: '20%',
         sorter: (a, b) => stringComparer(a.programDescription, b.programDescription),
         sortDirections: ['ascend', 'descend']
      },
      {
         title: 'Logo',
         dataIndex: '',
         key: 5,
         width: '20%',
         render: (text, record) =>
            <div className='grid-logo-bg'>{record.logoUrl && <img src={record.logoUrl} className="grid-logo" alt="logo" />}</div>
      },
      // ***** These were commented out / removed for now until we can sort out some scrollbar issues with the <Table>s.
      // ***** Keeping the definitions though for when we fix that issue
      //{
      //    title: 'IsDemo',
      //    dataIndex: 'isDemo',
      //    key: 6,
      //    sorter: (a, b) => boolComparer(a.isDemo, b.isDemo),
      //    sortDirections: ['ascend', 'descend'],
      //    render: ((isDemo) => formatBoolean(isDemo))
      //},
      //{
      //    title: 'CanSelectAllServices',
      //    dataIndex: 'canSelectAllServices',
      //    key: 7,
      //    sorter: (a, b) => boolComparer(a.canSelectAllServices, b.canSelectAllServices),
      //    sortDirections: ['ascend', 'descend'],
      //    render: ((canSelectAllServices) => formatBoolean(canSelectAllServices))
      //},
      {
         title: 'practiceId',
         dataIndex: 'practiceId',
         key: 8,
         sorter: (a, b) => numberComparer(a.practiceId, b.practiceId),
         sortDirections: ['ascend', 'descend'],
         render: (text, record) => <>{record.practiceId && record.practiceId > 0 ? `${record.practiceId}` : 'All'}</>
      },
      {
         title: 'connectivityType',
         dataIndex: 'connectivityType',
         key: 9,
         sorter: (a, b) => numberComparer(a.connectivityType, b.connectivityType),
         sortDirections: ['ascend', 'descend']
      },
      // ***** These were commented out / removed for now until we can sort out some scrollbar issues with the <Table>s.
      // ***** Keeping the definitions though for when we fix that issue
      //{
      //    title: 'interfaceId',
      //    dataIndex: 'interfaceId',
      //    key: 10,
      //    sorter: (a, b) => numberComparer(a.interfaceId, b.interfaceId),
      //    sortDirections: ['ascend', 'descend'],
      //    render: (text, record) => <>{record.interfaceId && record.interfaceId > 0 ? `${record.interfaceId}` : 'All'}</>
      //},
      {
         title: () =>
            <Restricted requiredRoles={[KnownSettings.ContentAdmin]}>
               <AddButton title='Add New Program' buttonText='New' onClick={() => {
                  setSelectedAssistanceProgramId(undefined);
                  setIsAssistanceProgramEditorOpen(true)
               }} />
            </Restricted>,
         dataIndex: 'actions',
         key: 11,
         fixed: 'right',
         render: (text, record) => <Restricted requiredRoles={[KnownSettings.ContentAdmin]}>
            <EditButton
               title='Edit'
               onClick={() => {
                  setSelectedAssistanceProgramId(record.id);
                  setIsAssistanceProgramEditorOpen(true);
               }} />
         </Restricted>
      }
   ]

   const buildExpandedHtml = (programExpanding: AssistanceProgram) => {
      const assistanceServicesForProgram = sortAssistanceServices(assistanceServices?.filter(y => y.assistanceProgramId === programExpanding.id)) ?? [];
      const assistanceProgramResourcesForProgram = sortAssistanceProgramResource(assistanceProgramResources.filter(y => y.assistanceProgramId === programExpanding.id)) ?? [];
      const expandedHtml = (
         <>
            <Tabs defaultActiveKey='1' items={[
               {
                  label: 'Services',
                  key: '1',
                  children: <AssistanceServiceRow
                     serviceCategories={serviceCategories}
                     assistanceProgram={programExpanding}
                     assistanceServicesForProgram={assistanceServicesForProgram}
                     setSelectedAssistanceProgramId={setSelectedAssistanceProgramId}
                     selectedAssistanceServiceId={selectedAssistanceServiceId}
                     setSelectedAssistanceServiceId={setSelectedAssistanceServiceId}
                     closeEditor={handleCloseAssistanceServiceEditorClick} />
               },
               {
                  label: 'Resources',
                  key: '2',
                  children: <AssistanceProgramResourceRow
                     assistanceProgram={programExpanding}
                     setSelectedAssistanceProgramId={setSelectedAssistanceProgramId}
                     assistanceProgramResourcesForProgram={assistanceProgramResourcesForProgram}
                     selectedAssistanceProgramResourceId={selectedAssistanceProgramResourceId}
                     setSelectedAssistanceProgramResourceId={setSelectedAssistanceProgramResourceId}
                     closeEditor={handleCloseAssistanceProgramResourceEditorClick}
                  />
               }
            ]} />
         </>
      );

      return expandedHtml;
   }

   const html = (
      <>
         <SearchResultsTable
            rowkey={'id'}
            size={'small'}
            onChange={handleOnChange}
            columns={programGridColumns}
            data={filteredItems}
            currentPage={currentPage}
            currentPageSize={currentPageSize}
            setCurrentPageSize={setCurrentPageSize}
            titleText='Assistance Program'
            fixedHeader={true}
            expandedRowRender={(record) => buildExpandedHtml(record)}
            additionalComponents={[
               <BasicInputField
                  label='Assistance Program:'
                  placeholder='Search by Program Name'
                  type="text"
                  name="programNameSearch"
                  value={programNameFilter}
                  onChange={(e) => setProgramNameFilter(String(e))} />,
               <BasicDropdownField
                  label='Program Type:'
                  placeholder='Filter by Program Type'
                  mode='multiple'
                  clearable={true}
                  onChange={(e) => setProgramTypeFilter(e != undefined ? Number(e) : undefined)}
                  value={programTypeFilter}
                  name='programTypeFilter'
                  options={programTypeOptions} />,
            ]}
         />

         {isAssistanceProgramEditorOpen && 
            <AssistanceProgramEditor
               assistanceProgramId={selectedAssistanceProgramId}
               isOpen={isAssistanceProgramEditorOpen}
               closeEditor={() => handleCloseAssistanceProgramEditorClick()} />
         }
      </>
   )

   return html;
}

export default AssistanceProgramIndex;