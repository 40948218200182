import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import { unregister } from './registerServiceWorker'; //AX-1148: removed registerServiceWorker added { unregister }
import { App } from './App';
import AuthProviderWithHistory from './auth/AuthProviderWithHistory';
import { ConfigProvider } from 'antd';
import './custom.css'
import './Theme/adminportal.css'
import 'antd/dist/reset.css';
import './Theme/antdesignOverrides.css';
import { defaultTheme } from './Theme/ThemeConfig';
import { InsightsContextProvider } from './InsightsContext';

const container = document.getElementById('root')!;
const root = createRoot(container!);
root.render(
   <React.StrictMode>
      <InsightsContextProvider>
         <Router>
            <ConfigProvider
               componentSize='middle'               
               theme={defaultTheme}>
               <AuthProviderWithHistory>
                  <App />
               </AuthProviderWithHistory>
            </ConfigProvider>
         </Router>
      </InsightsContextProvider>
   </React.StrictMode>
);
//AX-1148: disabling service worker - it is preventing the call to server - which prevents server from adding client security http headers.
unregister();
//registerServiceWorker();
