import * as React from 'react';
import { KnownSettings } from '../../store/SettingsModel';
import { useFetchSetting } from '../../store/SettingsFetcher';
import './WelcomeTile.css';

const WelcomeTile: React.FC = () => {
   const emailString: string = useFetchSetting(KnownSettings.SupportEmail);

   return (
      <div className="att-container-wrapper">

         <div className="att-container">

            <div className="att-top">
               <div className="toprow-left">
                  <div className="toprow-top">
                  </div>
                  <div className="toprow-bottom-left">
                  </div>
               </div>
               <div className="toprow-center">
                  <div className="img-center" />
               </div>
               <div className="toprow-right">
                  <div className="toprow-top">
                  </div>
                  <div className="toprow-bottom-right">
                  </div>
               </div>
            </div>

            <div className="att-bottom">
               <div className="att-body">
                  <div className="title" style={{ paddingBottom: 10 }}>
                     <h2>Welcome to Your Admin Portal</h2>
                  </div>

                  <p className="title-paragraph">
                     Within this portal, you will be able to edit content. <br />A number of planned features will be added over time.
                  </p>

                  <div className="title">
                     <h2>For Questions</h2>
                     <div style={{ fontSize: 'smaller' }}>
                        <a href="mailto:{emailString}">{emailString}</a>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
}

export default WelcomeTile;

