import { Breadcrumb, Space } from 'antd';
import * as React from 'react';
import { Link, useParams } from 'react-router-dom';
import { stringComparer } from '../../functions/comparer.functions';
import { IOptionItem } from '../../functions/option.functions';
import { useApiContext } from '../../store/ApiContext';
import { useFoundationDiseaseTypesByFoundationId, getFoundationDiseaseTypesUrl } from '../../store/program/FoundationDiseaseType';
import { foundationBaseUrl, useFetchFoundationSummary } from '../../store/program/FoundationFetcher';
import FoundationDetail from '../foundation/FoundationDetail';
import FoundationDiseaseType from '../foundation/FoundationDiseaseType';
import FundingStatusExcludedIdEditor from '../foundation/FundingStatusExcludedIdEditor';
import { EditButton } from '../shared/Buttons';
import Spinner from '../Spinner';
import FoundationEditor from './FoundationEditor';
import ApiErrorDisplay from '../ApiErrorDisplay';
import { useErrorContext } from '../../store/ErrorContext';
import { Foundation } from '../../store/program/FoundationModel';

interface IProps { setCardHeader?: (title: string) => void }
const _keys: string[] = [getFoundationDiseaseTypesUrl];
const _keysLike: string[] = [foundationBaseUrl];

const FoundationView: React.FC<IProps> = (props) => {
   const { setCardHeader } = props;

   const { foundationId } = useParams();
   const { httpGet } = useApiContext();
   const { removeErrors } = useErrorContext();
   const foundationIdNumber = parseInt(foundationId);
   const { foundation: editItem, isLoading: isLoadingFs } = useFetchFoundationSummary(httpGet, foundationIdNumber);
   const { foundationDiseaseTypes: selectedFoundationDiseaseTypes, isLoading: isLoadingFdt } = useFoundationDiseaseTypesByFoundationId(httpGet, foundationIdNumber);
   const [isFoundationEditorOpen, setIsFoundationEditorOpen] = React.useState<boolean>(false);
   const [isFundingStatusExcludedIdEditorOpen, setIsFundingStatusExcludedIdEditorOpen] = React.useState<boolean>(false);

   const diseaseTypeSelect = React.useMemo(() => {
      const diseaseType: IOptionItem[] = [];
      if (selectedFoundationDiseaseTypes?.length > 0) {         
         selectedFoundationDiseaseTypes.forEach(disease => {
            diseaseType.push({ value: disease.id, label: disease.diseaseTypeName });
         })
         diseaseType.sort((a, b) => stringComparer(a.label as string, b.label as string))
      }
      return diseaseType;
   }, [selectedFoundationDiseaseTypes]); 

   const handleCloseEditorClick = () => {
      setIsFoundationEditorOpen(false);
      clearErrors();
   }

   const scrapingStatusStyle = editItem
      && { color: (["off", "paused", "error"].some(val => val === (editItem.scrapingStatus !== undefined ? editItem.scrapingStatus.toLowerCase() : "")) ? "red" : "green") };

   const _setCardHeader = React.useCallback((foundation: Foundation) => {
      if (setCardHeader) {
         setCardHeader(foundation ? `${foundation.foundationName} (${foundation.id})` : '');
      }
   }, [setCardHeader]);

   React.useEffect(() => {
      _setCardHeader(editItem);
   }, [editItem, _setCardHeader]);

   const clearErrors = () => removeErrors({ keys: _keys, keysLike: _keysLike });

   return (
      <div key={1111}>
         <ApiErrorDisplay
            title='Foundation Error'
            keys={_keys}
            keysLike={_keysLike}
         />

         {isLoadingFdt || isLoadingFs || !editItem &&
            <Spinner />
         }

         {!isLoadingFdt &&  !isLoadingFs && editItem &&
            <div style={{ padding: 10 }}>
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                     <Breadcrumb
                        style={{ paddingTop: 5 }}
                        separator='>'
                        items={[
                           {
                              title: <Link
                                 to={{ pathname: `/content-tool/FOUNDATION` }}
                                 title='Return to All Foundations'>
                                 Return to All Foundations
                              </Link>
                           },
                        ]}
                     />                  
                  <Space>
                     <EditButton
                        title='Edit'
                        buttonText='Funding Status Excluded Ids'
                        onClick={() => setIsFundingStatusExcludedIdEditorOpen(true)}
                     />
                     <EditButton
                        title='Edit'
                        buttonText='Edit Foundation Details'
                        onClick={() => setIsFoundationEditorOpen(true)}
                     />
                  </Space>
               </div>
               <FoundationDetail
                  foundation={editItem}
                  scrapingStatusStyle={scrapingStatusStyle}
               />
               <div>
                  <FoundationDiseaseType
                     foundationId={foundationIdNumber}
                     diseaseTypeSelect={diseaseTypeSelect}
                  />
               </div>
            </div>
         }
         {isFoundationEditorOpen &&
            <FoundationEditor
               isOpen={isFoundationEditorOpen}
               closeEditor={() => handleCloseEditorClick()}
               id={editItem?.id} />
         }

         {isFundingStatusExcludedIdEditorOpen &&
            <FundingStatusExcludedIdEditor
               foundationId={foundationIdNumber}
               isOpen={isFundingStatusExcludedIdEditorOpen}
               closeEditor={() => setIsFundingStatusExcludedIdEditorOpen(false)}
            />
         }
      </div>
   );
}

export default FoundationView;