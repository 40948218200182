import useSWR from 'swr';
import { HttpError, TGet } from '../../functions/httpClient';
import { InsightlyOrganization } from './InsightlyOrganizationModel';

const ctrlBaseUrl = `/api/InsightlyOrganization`;

const swrOptions = {
    refreshInterval: 0,
    revalidateOnFocus: false,
    dedupingInterval: 60000
};

export const useInsightlyOrganizationList = (httpGet: TGet) => {
    const { data, isValidating, error } = useSWR<InsightlyOrganization[], HttpError>(
        ctrlBaseUrl,
        httpGet,
        { ...swrOptions }
    );

    return {
        insightlyOrganizations: data,
        isLoading: !error && !data && isValidating,
        error: error
    };
}