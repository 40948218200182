import useSWR from 'swr';
import { HttpError, httpGet, TGet } from '../functions/httpClient';
import { Setting, KnownSettings } from './SettingsModel';

declare global {
   interface Window {
      //define global variables to allow pulling settings from window
      isSimulation: boolean;
   }
}
const _environmentsToEvaluate = [ 'local', 'spike', 'development', 'azuredev' ];

const settingBaseUrl = `/api/settings`;
export const settingGetNewGuidUrl = `${settingBaseUrl}/GetNewGuid`;

const swrOptions = {
   refreshInterval: 0,
   revalidateOnFocus: false,
   dedupingInterval: 60000     //default 2000
};

export const useFetchSettings = () => {

   const { data, isValidating, error } = useSWR<Setting[], HttpError>(
      settingBaseUrl, //token not required for settings, but if we have it, send it
      httpGet,
      { ...swrOptions }
   );
   if (data) {
      const env = getSettingValue(KnownSettings.Environment, data);
      window.isSimulation = _environmentsToEvaluate.some(y => y === env.toLowerCase());
   }
   return {
      settings: data,
      isLoading: !error && !data && isValidating,
      error,
   };
}

export const getNewGuid = async (httpGet: TGet): Promise<string> => {
   const result = await httpGet<string>(settingGetNewGuidUrl);
   return result;
}

export function useFetchSetting(key: string): string {
   const { settings } = useFetchSettings();

   return getSettingValue(key, settings ?? []);
}

export function useFetchGroupSetting(group: string, key: string): string {
   const { settings } = useFetchSettings();
   const setting = settings?.find(e => e.group === group && e.key === key);
   return setting?.value;
}

export const getSettingValue = (key: string, currentSettings: Setting[]) => {
   if (!currentSettings || currentSettings.length === 0) return null;
   const setting = currentSettings.length > 0 ? currentSettings.find(e => e.key === key) : undefined;
   //const setting = currentSettings.find(e => e.key === key);
   if (!setting) {
      console.warn(`"${key}" not found in settings.`);
      return null;
   }
   return setting.value;
};

export const isFeatureEnabled = (feature: string, featureFlags: Setting[]): boolean => {
   //default to false if null -> should only be null before state is loaded
   if (featureFlags === null)
      return false;

   //not specified - return true
   if (!feature)
      return true;

   const featureFlag: Setting = featureFlags.find(y => y.group === KnownSettings.FeatureManagement && y.key === feature);
   //not found - return true
   if (!featureFlag || !featureFlag.value)
      return true;

   const isEnabled: boolean = featureFlag.value.toLowerCase() === "true";
   return isEnabled;
}

