import { yupResolver } from '@hookform/resolvers/yup';
import { Form } from 'antd';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Cpt } from '../../../store/drug/CptModel';
import { useFetchCpt, deleteCpt, saveCpt, useFetchCptClassifications, cptSaveUrl, cptDeleteUrl } from '../../../store/drug/CptFetcher';
import ApiErrorDisplay from '../../ApiErrorDisplay';
import Dialog from '../../Dialog';
import { Col, Row } from 'antd';
import { DropdownField, InputField } from '../../shared/InputLibrary';
import { CancelButton, DeleteButton, SaveButton } from '../../shared/Buttons';
import { IOptionItem } from '../../../functions/option.functions';
import { TitleError } from '../../shared/AntComponents/Typography/Title';
import { useApiContext } from '../../../store/ApiContext';
import Spinner from '../../Spinner';
import { useErrorContext } from '../../../store/ErrorContext';

interface IProps {
   isOpen: boolean;
   closeEditor: () => void;
   code?: string;
};

const dialogContentStyle = {
   height: '580px',
   maxHeight: '95vh',
}

const yupCptSchema = yup.object({
   code: yup.string()
      .required('Code is required')
      .max(5, 'Max length is 5 characters'),
   description: yup.string()
      .required('Description is required'),
   classificationId: yup.number().notRequired(),
});

interface ICptYup extends yup.Asserts<typeof yupCptSchema> { }
const __formId = "frmNdc";

const NdcEditor: React.FC<IProps> = (props) => {
   const { isOpen, closeEditor, code} = props;
   const _keys: string[] = code ? [cptSaveUrl, cptDeleteUrl(code)] : [cptSaveUrl];
   const { httpGet, httpPost, httpDelete, apiErrors, removeError } = useApiContext();
   const { removeErrors } = useErrorContext();
   const { cptClassifications: cptClassificationsFromStore } = useFetchCptClassifications(httpGet);
   const { cptList } = useFetchCpt(httpGet);

   const [isFormSaving, setIsFormSaving] = React.useState<boolean>(false);


   const cptClassifications = React.useMemo(() => {
      if (cptClassificationsFromStore?.length > 0) {
         let tempList = cptClassificationsFromStore.map((d): IOptionItem => ({
            key: d.id,
            label: `${d.classification}`,
            value: d.id
         } as IOptionItem));
         return tempList;
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [cptClassificationsFromStore, isOpen]);

   const {
      control,
      handleSubmit,
      formState: { errors },
      reset,
      setValue,
      getValues
   } = useForm<ICptYup>({
      resolver: yupResolver<yup.AnyObject>(yupCptSchema),
      shouldFocusError: true,
   });

   React.useEffect(() => {
      let resetVal = {};
      if (code && cptList?.length > 0) {
         resetVal = cptList.find(y => y.code === code);
      }
      reset(resetVal);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [cptList, isOpen, code]);

   const onSubmit = (data: ICptYup) => {
      //console.log('--------------Editor-------------------------')
      //console.log(errors)

      //ts hoops to convert data with unknown props into assistance programs
      const item = data as unknown as Cpt;

      setIsFormSaving(true);
      saveCpt(httpPost, item)
         .then(handleClose)
         .catch(err => console.error('Error Saving CPT Code', err))
         .finally(() => {
            setIsFormSaving(false);
         });    
   }

   const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState<boolean>(false);

   const handleDeleteClick = () => {
      setIsDeleteDialogOpen(false);
      deleteCpt(httpDelete, getValues().code)
         .then(handleClose)
         .catch(err => console.error('Error Deleting CPT Code', err))
   }

   const handleClose = () => {
      setIsFormSaving(false);
      setIsDeleteDialogOpen(false);
      removeErrors({ keys: _keys });
      closeEditor();
   }

   if (isFormSaving) return <Spinner message='Saving Cpt...' />

   //console.log('--------------CptEditor-------------------------')
   //console.log(errors)


   const html = (
      <Dialog
         scrollingContent={true}
         title={'Cpt Editor'}
         open={isOpen}
         size="large"
         actionButtons={
            <>
               <CancelButton onClick={() => {
                  handleClose();
               }} />
               <DeleteButton
                  onClick={() => setIsDeleteDialogOpen(true)}
                  disabled={!getValues()?.code || isDeleteDialogOpen}
                  loading={isDeleteDialogOpen} />
               <SaveButton
                  formId={__formId}
                  onClick={() => null}
                  disabled={Object.keys(errors).length > 0}
                  loading={isFormSaving} />
            </>
         }>
         <div style={dialogContentStyle}>

            {errors && Object.keys(errors).length > 0 && <>
               <TitleError
                  text='Please correct the errors below' />
               {console.log('--------------Form Errors: -------------------------')}
               {console.log(errors)}
            </>
            }

            <ApiErrorDisplay
               title='Error saving Cpt'
               keys={_keys}
            />

            <Row>
               <Col span={24}>
                  <Form id={__formId} onFinish={handleSubmit(onSubmit)}>

                     <InputField
                        control={control}
                        name='code'
                        label='Code'
                        type='text'
                        error={errors?.code}
                        disabled={props.code ? true : false}
                        required={true}
                     />
                     <InputField
                        control={control}
                        name='description'
                        label='Description'
                        type='text'
                        error={errors?.description}
                        required={true}
                     />
                     <DropdownField
                        control={control}
                        name='classificationId'
                        label='Classification'
                        error={errors?.classificationId}
                        multiple={false}
                        options={cptClassifications}
                        setValue={setValue}
                        required={false}
                        search={true}
                        clearable={true}
                     />
                  </Form>
               </Col>
            </Row>
         </div>

         <Dialog
            title='CONFIRM DELETE'
            open={isDeleteDialogOpen}
            style={{ maxWidth: '550px' }}
            actionButtons={
               <>
                  <CancelButton onClick={() => setIsDeleteDialogOpen(false)} />
                  <DeleteButton
                     disabled={isFormSaving}
                     onClick={() => handleDeleteClick()} />
               </>
            }
         >
            {`Are you sure you want to Delete the Cpt (${getValues()?.code}) ${getValues()?.description || ''}?`}
         </Dialog>

      </Dialog>
   )
   return html;
}

export default NdcEditor;
