import { ColumnsType } from 'antd/lib/table';
import React from 'react';
import { Link } from 'react-router-dom';
import { stringComparer } from '../../functions/comparer.functions';
import { Foundation, ScrapingStatus } from '../../store/program/FoundationModel';
import { foundationBaseUrl, useFetchFoundations, useFoundationNameOptions } from '../../store/program/FoundationFetcher';
import '../grid/Grid.css';
import { BasicDropdownField } from '../shared/BasicInputLibrary';
import { AddButton, EditButton } from '../shared/Buttons';
import FoundationEditor from './FoundationEditor';
import { SearchResultsTableWithFilters } from '../shared/AntComponents/Table/SearchResultsTableWithFilters';
import ColumnFilter from '../shared/AntComponents/Filter/ColumnFilter';
import { IFilteredInfo } from '../shared/AntComponents/Filter/FilteredInfo';
import { IOptionItem } from '../../functions/option.functions';
import Restricted from '../../auth/Restricted';
import { KnownSettings } from '../../store/SettingsModel';
import { useApiContext } from '../../store/ApiContext';
import Spinner from '../Spinner';
import { HttpVerb, KeyWithVerb, useErrorContext } from '../../store/ErrorContext';
import ApiErrorDisplay from '../ApiErrorDisplay';

type searchFilters = 'foundationName' | 'foundationScraperStatus';

const _keysWithVerb: KeyWithVerb[] = [{ key: foundationBaseUrl, verb: HttpVerb.GET }]

export default (): React.ReactElement => {
   const { httpGet } = useApiContext();
   const { removeErrors } = useErrorContext();
   const [filteredInfo, setFilteredInfo] = React.useState<Record<searchFilters, IFilteredInfo>>(undefined);
   const { foundationOptions } = useFoundationNameOptions(httpGet);
   const { foundations: foundationsFromStore } = useFetchFoundations(httpGet);

   const [selectedItem, setSelectedItem] = React.useState<Foundation>(undefined);
   const [isEditorOpen, setIsEditorOpen] = React.useState<boolean>(false);

   React.useEffect(() => {
      removeErrors({ keysWithVerb: _keysWithVerb });
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []) // remove Errors on initial render

   const foundationScraperOptions: IOptionItem[] = [
      {
         key: ScrapingStatus.Off,
         label: ScrapingStatus.Off,
         value: ScrapingStatus.Off
      } as IOptionItem,
      {
         key: ScrapingStatus.On,
         label: ScrapingStatus.On,
         value: ScrapingStatus.On
      } as IOptionItem
   ];

   const filterByScraperStatus = (foundation: Foundation, search: ScrapingStatus): boolean => {
      return !search || search === ScrapingStatus.All || foundation.scrapingStatus === search;
   }

   const filterByFoundationName = (foundation: Foundation, search: string[]): boolean => {
      return !search || search.length == 0 || search.includes(foundation.foundationName);
   }

   const filterGridContent = (records: Foundation[]): Foundation[] => {
      if (records && records.length > 0) {
         return records.filter(v =>
            filterByScraperStatus(v, filteredInfo?.foundationScraperStatus?.value as ScrapingStatus) &&
            filterByFoundationName(v, filteredInfo?.foundationName?.values.map(m => m.value as string)));
      }
      return [];
   }

   const filteredData = React.useMemo(() => {
      if (foundationsFromStore?.length > 0) {
         return filterGridContent(foundationsFromStore)
      }
      // for "filterGridContent" - we actually need the two in this dependency array
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [foundationsFromStore, filteredInfo]);

   if (!filteredData) {
      return <Spinner />
   }

   const tableColumns: ColumnsType<Foundation> = [
      {
         title: <ColumnFilter title='Foundation Name'
            filteredInfo={filteredInfo?.foundationName}
            content={<BasicDropdownField
               label='Filter by Foundation Name:'
               placeholder='Filter by Foundation Name'
               clearable={true}
               multiple={true}
               search={true}
               value={filteredInfo?.foundationName?.values.map(y => y.value as string)}
               onChangeOption={(val, opt) => {
                  setFilteredInfo({
                     ...filteredInfo,
                     foundationName: {
                        title: 'Foundation Name',
                        values: opt as IOptionItem[]
                     }
                  })
               }}
               name='foundationNameFilter'
               options={foundationOptions}
            />}
         />,
         dataIndex: 'foundationName',
         key: 1,
         sorter: (a, b) => stringComparer(a.foundationName, b.foundationName),
         sortDirections: ['ascend', 'descend'],
         defaultSortOrder: 'ascend'
      },
      {
         title: 'Description',
         dataIndex: 'foundationDescription',
         key: 2,
         sorter: (a, b) => stringComparer(a.foundationDescription, b.foundationDescription),
         sortDirections: ['ascend', 'descend']
      },
      {
         title: 'Url',
         dataIndex: 'foundationUrl',
         key: 3
      },
      {
         title: 'Logo',
         dataIndex: 'logoUrl',
         key: 4,
         render: (text, record) =>
            <div className='grid-logo-bg'>{record.logoUrl && <img src={record.logoUrl} className="grid-logo" alt="logo" />}</div>
      },
      {
         title: 'Practice',
         dataIndex: 'practice',
         sorter: (a, b) => stringComparer(a.practice, b.practice),
         sortDirections: ['ascend','descend'],
         key: 5
      },
      {
         title: <ColumnFilter title='Scraper'
            filteredInfo={filteredInfo?.foundationScraperStatus}
            content={<BasicDropdownField
               label='Filter by Scraper Status:'
               placeholder='Filter by Scraper Status'
               clearable={true}
               value={filteredInfo?.foundationScraperStatus?.value as string}
               onChangeOption={(val, opt) => {
                  setFilteredInfo({
                     ...filteredInfo,
                     foundationScraperStatus: {
                        title: 'Scraper',
                        value: val as string
                     }
                  })
               }}
               name='foundationScraperFilter'
               options={foundationScraperOptions}
            />}
         />,
         dataIndex: 'scrapingStatus',
         key: 6,
         sorter: (a, b) => stringComparer(a.scrapingStatus, b.scrapingStatus),
         sortDirections: ['ascend', 'descend']
      },
      {
         title: () =>
            <Restricted requiredRoles={[KnownSettings.ContentAdmin]}>
               <AddButton
                  title='Add New Foundation'
                  buttonText='New'
                  onClick={() => {
                     setSelectedItem(undefined);
                     setIsEditorOpen(true);
                  }} />
            </Restricted>,
         dataIndex: 'actions',
         fixed: 'right',
         key: 7,
         width: 105,
         align: 'center',
         render: (text, record) =>
            <Restricted requiredRoles={[KnownSettings.ContentAdmin]}>
               <Link to={{ pathname: `/program/Foundation/${record.id}` }}>
                  <EditButton onClick={() => { }} />
               </Link>
            </Restricted>
      }
   ];

   const html = (
      <>
         <ApiErrorDisplay
            title={'Error'}
            keysWithVerb={_keysWithVerb}
         />

         <SearchResultsTableWithFilters
            rowkey={'id'}
            columns={tableColumns}
            data={filteredData}
            titleText='Foundation'
            fixedHeader={true}
            filteredInfo={filteredInfo}
            setFilteredInfo={setFilteredInfo}
            onFiltersClear={() => setFilteredInfo(undefined)}
            scrollY={'calc(100vh - 275px)'}
         />

         {isEditorOpen && 
            <FoundationEditor isOpen={isEditorOpen} closeEditor={() => setIsEditorOpen(false)} id={selectedItem?.id} />
         }
      </>
   );

   return html;
}
