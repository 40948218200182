import { useEffect, useState } from 'react';
import useSWR, { mutate } from 'swr';
import { numberComparer, stringComparer } from '../../functions/comparer.functions';
import { HttpError, TGet, TPost } from '../../functions/httpClient';
import { IOptionItem } from '../../functions/option.functions';
import { IStatus } from '../models';
import { FoundationDiseaseTypeToSpecialtyViewModel, Specialty } from './SpecialtyModels';

export const specialtyBaseUrl = `api/program/Specialty`;
export const specialtySaveUrl = `${specialtyBaseUrl}/Save`;
export const specialtyGetFoundationDiseaseTypeToSpecialty = `${specialtyBaseUrl}/GetFoundationDiseaseTypeToSpecialty`;

const swrOptions = {
   refreshInterval: 0,
   revalidateOnFocus: false,
   dedupingInterval: 60000
};

export const saveSpecialty = async (httpPost: TPost, billingCodes: Specialty) => {
   const result = await httpPost(specialtySaveUrl, billingCodes);
   mutate(specialtyBaseUrl);
   mutate(specialtyGetFoundationDiseaseTypeToSpecialty);
   return result;
}

export function useFetchSpecialtyList(httpGet: TGet): IStatus & { specialtyList: Specialty[] } {
   const { data, isValidating, error } = useSWR<Specialty[], HttpError>(
      specialtyBaseUrl,
      httpGet,
      { ...swrOptions }
   );

   return {
      specialtyList: data,
      isLoading: !error && !data && isValidating,
      error: error?.message
   };
}

export function useFetchSpecialtyViewModelList(httpGet: TGet): IStatus & { foundationDiseaseTypeToSpecialtyViewModels: FoundationDiseaseTypeToSpecialtyViewModel[] } {
   const { data, isValidating, error } = useSWR<FoundationDiseaseTypeToSpecialtyViewModel[], HttpError>(
      specialtyGetFoundationDiseaseTypeToSpecialty,
      httpGet,
      { ...swrOptions }
   );

   return {
      foundationDiseaseTypeToSpecialtyViewModels: data,
      isLoading: !error && !data && isValidating,
      error: error?.message
   };
}

export function useSpecialtyFoundationAndFoundationDiseaseOptions(httpGet: TGet): { specialtyFoundationOptions: IOptionItem[], specialtyFoundationDiseaseOptions: IOptionItem[] } {
   const { foundationDiseaseTypeToSpecialtyViewModels } = useFetchSpecialtyViewModelList(httpGet);
   const [specialtyFoundationOptions, setSpecialtyFoundationOptions] = useState<IOptionItem[]>([]);
   const [specialtyFoundationDiseaseOptions, setSpecialtyFoundationDiseaseOptions] = useState<IOptionItem[]>([]);

   useEffect(() => {
      const foundationList: IOptionItem[] = [];
      const foundationDiseaseList: IOptionItem[] = [];

      const buildSpecialtyFoundationOptions = () => {
         if (foundationDiseaseTypeToSpecialtyViewModels?.length > 0) {
            foundationDiseaseTypeToSpecialtyViewModels.forEach(parentModel => {
               parentModel.foundationDiseaseTypeToSpecialtyDetails.reduce((acc: IOptionItem[], { foundationId, foundationName, foundationDiseaseTypeId, foundationDiseaseTypeName }) => {
                  if (!foundationList.some((y: IOptionItem) => y.value === foundationId)) {
                     foundationList.push({
                        key: foundationId,
                        label: foundationName,
                        value: foundationId
                     } as IOptionItem);
                  }

                  //note: using name string as key - there are duplicate names with different id values
                  if (!foundationDiseaseList.some((y: IOptionItem) => String(y.value).toLowerCase() === foundationDiseaseTypeName.toLowerCase())) {
                     foundationDiseaseList.push({
                        key: foundationDiseaseTypeName,
                        label: foundationDiseaseTypeName,
                        value: foundationDiseaseTypeName
                     } as IOptionItem);
                  }
                  return acc;
               }, []);
            });
            foundationList.sort((a: IOptionItem, b: IOptionItem) => stringComparer(String(a.label), String(b.label)));
            foundationDiseaseList.sort((a: IOptionItem, b: IOptionItem) => stringComparer(String(a.label), String(b.label)));

            setSpecialtyFoundationOptions(foundationList);
            setSpecialtyFoundationDiseaseOptions(foundationDiseaseList);
         }
      };
      buildSpecialtyFoundationOptions();
   }, [foundationDiseaseTypeToSpecialtyViewModels]);

   return {
      specialtyFoundationOptions,
      specialtyFoundationDiseaseOptions
   };
}

export function useSpecialtyOptions(httpGet: TGet): { specialtyOptions: IOptionItem[] } {
   const { specialtyList } = useFetchSpecialtyList(httpGet);
   const [specialtyOptions, setSpecialtyOptions] = useState<IOptionItem[]>([]);

   useEffect(() => {
      const buildSpecialtyOptions = () => {
         if (specialtyList?.length > 0) {
            const sorted = [...specialtyList];

            sorted.sort((a: Specialty, b: Specialty) => numberComparer(Number(a.id), Number(b.id)));
            sorted.sort((a: Specialty, b: Specialty) => stringComparer(String(a.name), String(b.name)));

            const specialtyOptions = sorted.map((specialty): IOptionItem => ({
               key: specialty.id,
               label: `[${specialty.id}] ${specialty.name}`,
               value: specialty.id
            } as IOptionItem));
            setSpecialtyOptions(specialtyOptions);
         }
      }
      buildSpecialtyOptions();
   }, [specialtyList]);

   return {
      specialtyOptions
   };
}
