import Empty from 'antd/lib/empty';
import * as React from 'react';
import { stringComparer } from '../../../functions/comparer.functions';
import { PowerBIReport } from '../../../store/report/ReportModel';
import { BasicInputField } from '../../shared/BasicInputLibrary';
import { useReports } from '../ReportContext';
import ReportCollectionMenu from './ReportCollectionMenu';


const SearchMenu: React.FC = () => {
   const { powerBIReports, menuIsCollapsed } = useReports()
   const [searchText, setSearchText] = React.useState<string>(undefined);
   const [foundReports, setFoundReports] = React.useState<PowerBIReport[]>([]);

   React.useEffect(() => {
      if (searchText && powerBIReports?.length > 0) {
         setFoundReports(
            powerBIReports.filter(y => y.reportPageName.toLocaleLowerCase().indexOf(searchText.toLocaleLowerCase()) > -1)
               .sort((a, b) => stringComparer(a.reportPageName, b.reportPageName))
         )
      }
   }, [searchText, powerBIReports])

   const html = (<>
      <div style={{ paddingLeft: '5px', paddingRight: '5px' }}>
         <BasicInputField
            placeholder='Report Name'
            value={searchText}
            onChange={(e) => {
               setSearchText(e as string)
            }}
         />
      </div>
      {searchText && foundReports?.length === 0 && <Empty />}
      {searchText && foundReports?.length > 0 &&
         <ReportCollectionMenu
            searchText={searchText}
            reports={foundReports}
            isCollapsed={menuIsCollapsed}
            showBreadcrumb
            showCertifiedAttribute
         />
      }
   </>)

   return html;
}
export default SearchMenu;
