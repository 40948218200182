import { yupResolver } from '@hookform/resolvers/yup';
import { Form, Row } from 'antd';
import Col from 'antd/es/grid/col';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useApiContext } from '../../store/ApiContext';
import { useErrorContext } from '../../store/ErrorContext';
import { FundStatusAlert } from '../../store/fsa/FundStatusAlertModel';
import { dismissFundStatusFieldChanges, fundStatusFieldDismissUrl } from '../../store/fsa/FundStatusFieldFetcher';
import ApiErrorDisplay from '../ApiErrorDisplay';
import Dialog from '../Dialog';
import { CancelButton, SaveButton, buttonStyles } from '../shared/Buttons';
import { DropdownField } from '../shared/InputLibrary';

interface IProps {
   fundsToDismiss: FundStatusAlert[],
   fieldChangeFromDate: Date;
   setSelectedFundAlerts: ([]) => void;
   clearFundAlerts: () => void;
   isOpen: boolean;
   close: () => void;
};

const dialogContentStyle = {
   minHeight: '30vh',
   overflow: 'auto'
}

const yupDismissClassDialogSchema = yup.object({
   reason: yup.string().required()
});

interface IDismissClassDialogYup extends yup.Asserts<typeof yupDismissClassDialogSchema> { }
const __formId = "frmDismissClassDialog";

const _keysLike: string[] = [fundStatusFieldDismissUrl];

// place holder for now
const reasonsOptions = [
   {
      label: 'Closed Notice, Low Priority Fund',
      value: 'Closed Notice, Low Priority Fund',
      isSelectOption: false
   },
   {
      label: 'Incorrect Status Reported',
      value: 'Incorrect Status Reported',
      isSelectOption: false
   },
   {
      label: 'Duplicate Notice',
      value: 'Duplicate Notice',
      isSelectOption: false
   },
   {
      label: 'Admin Portal Error - Sent Manually',
      value: 'Admin Portal Error - Sent Manually',
      isSelectOption: false
   }
];

const DismissClassDialog: React.FC<IProps> = (props) => {
   const { httpPut } = useApiContext();
   const { isOpen, close, fundsToDismiss, fieldChangeFromDate, setSelectedFundAlerts } = props;
   const [isFormSaving, setIsFormSaving] = React.useState<boolean>(false);
   const { removeErrors } = useErrorContext();

   const clearErrors = () => {
      removeErrors({ keysLike: _keysLike });
   }

   const classesToDismiss: number[] = [];
   fundsToDismiss.forEach(y => {
      classesToDismiss.push(...y.alertDetails.map(m => m.fundingStatusFieldChangeLogId));
   });

   const {
      control,
      handleSubmit,
      formState: { errors },
      reset,
      setValue
   } = useForm<IDismissClassDialogYup>({
      resolver: yupResolver<yup.AnyObject>(yupDismissClassDialogSchema),
      shouldFocusError: true,
   });

   const handleClose = () => {
      clearErrors();
      setIsFormSaving(false);
      reset();
      close();
   }

   const onSubmit = async (data: IDismissClassDialogYup) => {
      setIsFormSaving(true);

      const _fundsToDismiss: number[] = [];
      await fundsToDismiss.forEach(y => {
         _fundsToDismiss.push(...y.alertDetails.map(m => m.fundingStatusFieldChangeLogId));
      });
      await dismissFundStatusFieldChanges(httpPut, _fundsToDismiss, fieldChangeFromDate, data.reason)
         .then(() => {
            setSelectedFundAlerts([]);
            clearErrors();
         })
         .finally(() => {
            handleClose();
         });
   }

   const html = (
      <Dialog
         title={`Dismiss Reason`}
         open={isOpen}
         size="large"
         actionButtons={
            <>
               <CancelButton onClick={() => handleClose()} />
               <SaveButton
                  formId={__formId}
                  buttonText='Dismiss'
                  style={buttonStyles.actionDanger}
                  onClick={() => null}
                  disabled={!classesToDismiss || classesToDismiss.length === 0}
                  loading={isFormSaving} />
            </>
         }>
         <div style={dialogContentStyle}>
            <ApiErrorDisplay
               title='Error(s) Occurred:'
               keysLike={_keysLike} />
            <Form id={__formId} onFinish={handleSubmit(onSubmit)}>
               <Row>
                  <Col span={12}>
                     <p><b>Please indicate why you are dismissing this Funding Status Alert.</b></p>
                     <DropdownField
                        control={control}
                        name='reason'
                        label=''
                        error={errors?.reason}
                        multiple={false}
                        options={reasonsOptions}
                        required={true}
                        clearable={true}
                     />
                  </Col>
                  <Col span={2} />
                  <Col span={10} style={{ maxHeight: '60vh', overflow: 'auto' }}>
                     <h3>FSA Alerts to Dismiss</h3>
                     <hr />
                     {fundsToDismiss.map((fund, key) => {
                        return (
                           <div style={{ marginBottom: '1rem' }} key={key}>
                              <b>{fund.foundationName} - {fund.programName}</b>
                              <br />
                              <small><b><i>{fund.assistanceServiceName}</i> - {fund.diseaseTypeName}</b></small>
                           </div>
                        );
                     })}
                  </Col>
               </Row>
            </Form>
         </div>
      </Dialog>
   )

   return html;
}
export default DismissClassDialog;
