import * as React from 'react';
import { Space } from 'antd';
import { UserProfile } from '../../store/practice/UserProfileModel';
import { useApiContext } from '../../store/ApiContext';
import { CancelButton, SaveButton } from '../shared/Buttons';
import { patchUserProfileEmail, userProfilePatchEmailUrl } from '../../store/practice/UserProfileFetcher';
import Dialog from '../Dialog';
import ApiErrorDisplay from '../ApiErrorDisplay';
import { BasicInputField } from '../shared/BasicInputLibrary';
import { validateEmail } from '../../functions/form.validators';
import { useErrorContext } from '../../store/ErrorContext';

interface IProps {
   userProfileToEdit: UserProfile;
   isEditEmailAddressDialogOpen: boolean;
   onClose: () => void;
}

export const PracticeUserEditEmailAddressDialog: React.FC<IProps> = (props) => {
   const { userProfileToEdit, isEditEmailAddressDialogOpen, onClose: parentOnClose } = props;
   const _keys = [userProfilePatchEmailUrl(userProfileToEdit?.userProfileId)];
   const { httpPatch } = useApiContext();
   const { removeErrors } = useErrorContext();

   const [isSaving, setIsSaving] = React.useState(false);
   const [localEmailAddress, setLocalEmailAddress] = React.useState<string>(undefined);
   const [emailValidationMessage, setEmailValidationMessage] = React.useState<string>(undefined);

   React.useEffect(() => {
      if (!userProfileToEdit) return;

      setLocalEmailAddress(userProfileToEdit.emailAddress);
   }, [userProfileToEdit]);

   const onClose = () => {
      setLocalEmailAddress(undefined);
      removeErrors({ keys: _keys });
      setEmailValidationMessage(undefined);
      parentOnClose();
   }

   const onSave = () => {
      if (doesEmailHaveValue()) {
         setIsSaving(true);
         patchUserProfileEmail(httpPatch, userProfileToEdit.userProfileId, localEmailAddress)
            .then(onClose)
            .catch(err => console.error('Error Saving Email Address', err))
            .finally(() => setIsSaving(false));
      }
   }

   const doesEmailHaveValue = (): boolean => {
      const isEmailValid = validateEmail(localEmailAddress);
      if (!isEmailValid) {
         setEmailValidationMessage('A valid Email Address is required');
      }
      return isEmailValid;
   }

   const html = (
      <Dialog
         scrollingContent={true}
         title={'Edit Email Address'}
         open={isEditEmailAddressDialogOpen}
         size={'tiny'}
         actionButtons={
            <Space>
               <CancelButton buttonText={'Cancel'} onClick={onClose} />
               <SaveButton buttonText={'Save'} disabled={isSaving} onClick={onSave} />
            </Space>
         }
      >
         <ApiErrorDisplay
            title='Error Updating Email'
            keys={_keys}
         />

         <BasicInputField
            label={'Email Address'}
            value={localEmailAddress}
            onChange={(e) => {
               setLocalEmailAddress(e as string);
            }}
            required={true}
            disabled={isSaving}
            error={emailValidationMessage}
         />

      </Dialog>
   );

   return html;
}