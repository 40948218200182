export enum DashboardSection {
   Search = 1,
   Enroll = 2,
   Track = 3,
   Analyze = 4,
   ComingSoon = 5,
   CurrentIntegration = 6
}

export enum ContentType {
   Image,
   Video,
}

export interface DashboardContent {
   id: number;
   name: string;
   dashboardSection: DashboardSection;
   url: string;
   thumbnailURL: string;
   description: string;
   isNew: boolean;
   contentType: string;
   sortOrder: number;
   duration: string;
   isDeleted: boolean;
   createdBy: string;
   createdOn: Date;
   modifiedBy: string;
   modifiedOn: Date;
}

export const GetDashboardSectionName = (content: number): string => {
   switch (content) {
      case 1:
         return "Search";
      case 2:
         return "Enroll";
      case 3:
         return "Track";
      case 4:
         return "Analyze";
      case 5:
         return "Coming Soon";
      case 6:
         return "Current Integration";
      default:
         return '';
   }
}