import * as React from 'react';
import { Form, Input, Select, Radio, Space, Switch, DatePicker } from 'antd';
import { minSqlDate, maxSqlDate } from '../../functions/form.validators';

import {
   Controller, Control, FieldValue, FieldValues, FieldError, UseFormSetValue
   , DeepMap, DeepPartial
} from 'react-hook-form';
import { ErrorDisplay } from './ErrorDisplay';
import { IOptionItem } from '../../functions/option.functions';
import { formatDate, getDayjs, getEndOfDay, getStartOfDay } from 'src/functions/time.functions';

const { Option } = Select;

export interface IErrorProps {
   error: DeepMap<DeepPartial<FieldValue<FieldValues>>, FieldError[] | FieldError | undefined>;
}

interface IProps extends IErrorProps {
   //from react-form-hook
   control: Control<FieldValue<FieldValues>>;
   setValue?: UseFormSetValue<FieldValue<FieldValues>>;
   name: string;
   onChange?: (value: string | number | boolean | (string | number | boolean)[]) => void;
   onBlur?: () => void;
   //for us
   label?: string;
   tooltip?: React.ReactNode;
   required?: boolean;
   disabled?: boolean;
   style?: React.CSSProperties;
   containerStyle?: React.CSSProperties;
}

interface IInputProps {
   type?: string;
   maxLength?: number;
   placeholder?: string;
}

interface ITextAreaProps {
   rows?: number;
   maxLength?: number;
   placeholder?: string;
}

interface IDropDownProps {
   multiple?: boolean;
   options: IOptionItem[];
   placeholder?: string;
   search?: boolean;
   clearable?: boolean;
}

interface IRadioListProps {
   options: IOptionItem[];
   direction?: 'horizontal' | 'vertical';
}

interface ICheckboxProps {
   toggle?: boolean;
   size?: 'small' | 'default';
}

interface IDateProps {
   dateFormat?: string;
   minDate?: Date;
   maxDate?: Date;
   placeholder?: string;
}

const labelStyles = (containerStyle: React.CSSProperties) => ({ fontWeight: 600, opacity: 0.8, ...containerStyle } as React.CSSProperties);

export const InputField: React.FC<IProps & IInputProps> = (props) => {
   const { error, control, name: parentName, onChange: parentOnChange, //setValue,
      onBlur: parentOnBlur, label, required, disabled, placeholder,
      type, maxLength, style, containerStyle, tooltip } = props;
   const length = maxLength ? maxLength : 524288;
   const styleProp = style ? { style: style } : {};

   return (
      <Controller
         control={control as unknown as Control<FieldValue<FieldValues>>}
         name={parentName}
         render={({ field: { onChange, onBlur, value, name } }) =>
            <Form.Item
               style={labelStyles(containerStyle)}
               validateStatus={error ? 'error' : 'success'}
               required={required}
               label={label}
               colon={false}
               labelCol={{ span: 24 }}
               tooltip={tooltip}
            >
               <Input
                  onChange={(changeEvent: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                     onChange && onChange(changeEvent);
                     parentOnChange && parentOnChange(value);
                  }}
                  onBlur={() => {
                     onBlur && onBlur();
                     parentOnBlur && parentOnBlur();
                  }}
                  value={value ?? ''}
                  name={name}
                  placeholder={placeholder}
                  disabled={disabled}
                  type={type ?? 'text'}
                  maxLength={length}
                  {...styleProp}
               />
               <ErrorDisplay error={error} />
            </Form.Item>
         }
      />
   )
}

export const TextAreaField: React.FC<IProps & ITextAreaProps> = (props) => {
   const { error, control, name: parentName, onChange: parentOnChange, //setValue,
      onBlur: parentOnBlur, label, required, disabled, placeholder,
      rows, maxLength, style, containerStyle, tooltip } = props;
   const length = maxLength ? maxLength : 524288;

   const widthStyle = { width: '100%' };
   const styleProp = { style: { ...widthStyle, ...style } };

   const { TextArea } = Input;
   return (
      <Controller
         control={control as unknown as Control<FieldValue<FieldValues>>}
         name={parentName}
         render={({ field: { onChange, onBlur, value, name } }) =>
            <Form.Item
               style={labelStyles(containerStyle)}
               validateStatus={error ? 'error' : 'success'}
               required={required}
               label={label}
               colon={false}
               labelCol={{ span: 24 }}
               tooltip={tooltip}
            >
               <TextArea
                  onChange={(changeEvent: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                     onChange && onChange(changeEvent);
                     parentOnChange && parentOnChange(value);
                  }}
                  onBlur={() => {
                     onBlur && onBlur();
                     parentOnBlur && parentOnBlur();
                  }}
                  value={value ?? ''}
                  name={name}
                  placeholder={placeholder}
                  disabled={disabled}
                  maxLength={length}
                  rows={rows ? rows : 3}
                  {...styleProp}
               />

               <ErrorDisplay error={error} />
            </Form.Item>
         }
      />
   )
}

export const DropdownField: React.FC<IProps & IDropDownProps> = (props) => {
   const { error, control, name, onChange: parentOnChange, //setValue, 
      onBlur: parentOnBlur, label, required, disabled, placeholder,
      multiple, options, search, clearable, style, containerStyle, tooltip } = props;
   const _placeHolder = placeholder ?? ((label) ? ` - Select a ${(placeholder ?? label)} -` : undefined);
   const styleProp = style ? { style: style } : {};

   const antOptions = options as unknown as Array<typeof Option>;

   const multipleProp: { mode?: 'multiple' | 'tags' } | undefined = multiple ? { mode: 'multiple' } : undefined;

   return (<Controller
      control={control as unknown as Control<FieldValue<FieldValues>>}
      name={name}
      render={({ field: { onChange, onBlur, value } }) =>
         <Form.Item
            style={labelStyles(containerStyle)}
            validateStatus={error ? 'error' : 'success'}
            required={required}
            label={label}
            colon={false}
            labelCol={{ span: 24 }}
            tooltip={tooltip}
         >
            <Select
               placeholder={_placeHolder}
               options={antOptions}
               {...multipleProp}
               value={multiple ? (value ?? []) as [] : value}
               showSearch={search !== undefined ? search : true} // default to true
               optionFilterProp='label'
               optionLabelProp='label'
               allowClear={clearable ?? false}
               onBlur={() => {
                  onBlur && onBlur();
                  parentOnBlur && parentOnBlur();
               }}
               onChange={(value, option: typeof Option | Array<typeof Option>) => {
                  onChange && onChange(value);
                  parentOnChange && parentOnChange(value);
               }}
               disabled={disabled}
               {...styleProp}
            />
            <ErrorDisplay error={error} />
         </Form.Item>
      } />
   )
}

export const RadioListField: React.FC<IProps & IRadioListProps> = (props) => {
   const { error, control, name, onChange: parentOnChange, //setValue, 
      label, required, disabled,
      options, style, containerStyle, direction, tooltip } = props;
   const styleProp = style ? { style: style } : {};

   return (<Controller
      control={control as unknown as Control<FieldValue<FieldValues>>}
      name={name}
      render={({ field: { onChange, onBlur, value }, }) =>
         <Form.Item
            style={labelStyles(containerStyle)}
            validateStatus={error ? 'error' : 'success'}
            required={required}
            label={label}
            colon={false}
            labelCol={{ span: 24 }}
            tooltip={tooltip}
         >
            <div style={{ paddingTop: 10, paddingLeft: 10 }}>
               <Radio.Group
                  onChange={(changeEvent) => {
                     onChange && onChange(changeEvent.target.value);
                     parentOnChange && parentOnChange(changeEvent.target.value);
                  }}
                  value={value}
                  {...styleProp}
               >
                  <Space direction={direction ?? 'horizontal'} size={[0, 0]}>
                     {options && options.map((option, idx) =>
                        <Radio key={option.value ?? idx}
                           value={option.value as number | string}
                           checked={option.value === value}
                           disabled={disabled}
                        >
                           {option.label}
                        </Radio>
                     )}
                  </Space>
               </Radio.Group>
            </div>
            <ErrorDisplay error={error} />
         </Form.Item>
      } />
   )
}

export const CheckboxField: React.FC<IProps & ICheckboxProps> = (props) => {
   const { error, control, name, onChange: parentOnChange, //setValue, 
      label, required, disabled,
      style, containerStyle, size, tooltip } = props;

   const styleProp = style ? { style: style } : {};

   return (<Controller
      control={control as unknown as Control<FieldValue<FieldValues>>}
      name={name}
      render={({ field: { onChange, value }, }) =>
         <Form.Item
            style={labelStyles(containerStyle)}
            validateStatus={error ? 'error' : 'success'}
            required={required}
            label={label}
            colon={false}
            labelCol={{ span: 24 }}
            tooltip={tooltip}
         >
            <div style={{ paddingTop: 10, paddingLeft: 10 }}>
               <Switch
                  checked={value}
                  onChange={(checked, changeEvent) => {
                     onChange && onChange(checked);
                     parentOnChange && parentOnChange(checked);
                  }}
                  disabled={disabled}
                  size={size ?? 'default'}
                  {...styleProp}
               />
            </div>
            <ErrorDisplay error={error} />
         </Form.Item>
      } />
   )
}

export const DatePickerField: React.FC<IProps & IDateProps> = (props) => {
   const { error, control, name, onChange: parentOnChange, //setValue, 
      onBlur: parentOnBlur, label, required, disabled,
      dateFormat, minDate, maxDate, placeholder, containerStyle, tooltip } = props;

   //'YYYY-MM-DD' is api format, 'MM/DD/YYYY' is display format - 'YYYY-MM-DD' is the api format
   const format = dateFormat ? dateFormat : 'MM/DD/YYYY';
   const minimumDate = minDate ? minDate : minSqlDate;
   const maximumDate = maxDate ? maxDate : maxSqlDate;

   return (<Controller
      control={control as unknown as Control<FieldValue<FieldValues>>}
      name={name}
      render={({ field: { onChange, onBlur, value } }) =>
         <Form.Item
            style={labelStyles(containerStyle)}
            validateStatus={error ? 'error' : 'success'}
            required={required}
            label={label}
            colon={false}
            labelCol={{ span: 24 }}
            tooltip={tooltip}
         >
            <div className='datewrapper'>
               <DatePicker
                  className='fullWidthDatePicker'
                  disabled={disabled}
                  name={name}
                  format={format}
                  value={value && getDayjs(formatDate(value))}
                  disabledDate={d => !d || d.isAfter(getEndOfDay(maximumDate)) || d.isBefore(getStartOfDay(minimumDate))}
                  onChange={(date, dateString) => {
                     onChange && onChange(dateString);
                     parentOnChange && parentOnChange(dateString);
                  }}
                  onBlur={() => {
                     onBlur && onBlur();
                     parentOnBlur && parentOnBlur();
                  }}
                  placeholder={placeholder}
               />
            </div>
            <ErrorDisplay error={error} />
         </Form.Item>
      } />
   )
}

export const FieldWrapper: React.FC<IProps & { field: React.ReactNode}> = (props) => {
   const { error, control, name, onChange: parentOnChange, //setValue, 
      label, required, disabled, style, containerStyle, tooltip,
      field } = props;

   const styleProp = style ? { style: style } : {  };

   return (<Controller
      control={control as unknown as Control<FieldValue<FieldValues>>}
      name={name}
      render={({ field: { onChange, value }, }) =>
         <Form.Item
            style={labelStyles(containerStyle)}
            validateStatus={error ? 'error' : 'success'}
            required={required}
            label={label}
            colon={false}
            labelCol={{ span: 24 }}
            tooltip={tooltip}
         >
            <div {...styleProp} >
               {field}
            </div>
            <ErrorDisplay error={error} />
         </Form.Item>
      } />
   )
}