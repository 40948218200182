import { Space } from 'antd';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { IOptionItem } from '../../functions/option.functions';
import {  useFetchAssistanceProgramsByFoundation } from '../../store/program/AssistanceProgramFetcher'
import { EditorAction } from '../../store/models';
import { AssistanceProgramLookup, AssistanceServiceLookup } from '../../store/program/LookupModels';
import AssistanceProgramLookupEditor from '../content-tool/program/AssistanceProgramLookupEditor';
import AssistanceServiceLookupEditor from '../content-tool/program/AssistanceServiceLookupEditor';
import FoundationAssistanceProgramList from '../foundation/FoundationAssistanceProgramList';
import { BasicDropdownField } from '../shared/BasicInputLibrary';
import { AddButton } from '../shared/Buttons';
import { useApiContext } from '../../store/ApiContext';

interface Props {
    foundationId: number;
    diseaseTypeSelect: IOptionItem[];
}

interface IModalEditor {
    programopened: boolean;
    serviceopened: boolean;
    defaultItemAssistanceProgram?: AssistanceProgramLookup,
    defaultItemAssistanceService?: AssistanceServiceLookup,
    editorAction: EditorAction,
}

const FoundationDiseaseType: React.FC<Props> = (props) => {
   const { foundationId, diseaseTypeSelect } = props;

   const navigate = useNavigate();
   const { httpGet } = useApiContext();
   const [foundationDiseaseTypeId, setFoundationDiseaseTypeId] = React.useState(0);
   const [isFoundationDiseaseTypeSelected, setIsFoundationDiseaseTypeSelected] = React.useState(false);
   const { assistanceProgramLookups } = useFetchAssistanceProgramsByFoundation(httpGet, foundationId);

   const foundationDiseaseTypeBaseRoute = `/program/foundation/${foundationId}/diseaseType/`;

   const handleChange = (id: number) => {
      setFoundationDiseaseTypeId(id);
      setIsFoundationDiseaseTypeSelected(true);
   };

   React.useEffect(() => {
      if (isFoundationDiseaseTypeSelected) {
         const redirectPath: string = foundationDiseaseTypeBaseRoute + `${foundationDiseaseTypeId}`;
         navigate(redirectPath);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [isFoundationDiseaseTypeSelected, foundationDiseaseTypeId, foundationDiseaseTypeBaseRoute]);

   const [modalEditor, setModalEditor] = React.useState<IModalEditor>({
      programopened: false,
      serviceopened: false,
      editorAction: undefined,
   });

   const handleAddNewAssistanceProgram = () => {

      const strItem = {
         ...modalEditor,
         programopened: true,
         serviceopened: false,
         editorAction: EditorAction.Add,
         defaultItemAssistanceProgram: {
            id: 0,
            programName: '',
            programDescription: '',
            practiceId: -1,
            foundationId: foundationId,
         }
      };
      setModalEditor(strItem);

   };

   const handleEditRowAssistanceProgram = (assistanceProgram: AssistanceProgramLookup) => {
      const strItem = {
         ...modalEditor,
         programopened: true,
         serviceopened: false,
         editorAction: EditorAction.Edit,
         defaultItemAssistanceProgram: assistanceProgram
      };
      setModalEditor(strItem);
   };

   const handleAddNewAssistanceService = (assistanceProgramId: number) => {
      const strItem = {
         modalEditor,
         programopened: false,
         serviceopened: true,
         editorAction: EditorAction.Add,
         defaultItemAssistanceService: {
            assistanceProgramId: assistanceProgramId,
            assistanceServiceId: 0,
            assistanceServiceName: '',
            assistanceType: 0,
            description: ''
         }
      };
      setModalEditor(strItem);
   };

   const handleEditRowAssistanceService = (assistanceService: AssistanceServiceLookup) => {
      const strItem = {
         ...modalEditor,
         programopened: false,
         serviceopened: true,
         editorAction: EditorAction.Edit,
         defaultItemAssistanceService: assistanceService
      };
      setModalEditor(strItem);
   };

   const handleCloseModal = () => {
      const strItem = {
         ...modalEditor,
         programopened: false,
         serviceopened: false,
         editorAction: EditorAction.Add,
      };
      setModalEditor(strItem);
   }

   const renderAddNewProgramModal = (
      <AssistanceProgramLookupEditor
         key={33334}
         item={modalEditor.defaultItemAssistanceProgram}
         closeEditor={handleCloseModal}
         foundationId={foundationId}
         isOpen={modalEditor.programopened} />
   );

   const renderAddNewServiceModal = (
      <AssistanceServiceLookupEditor
         key={33335}
         foundationId={foundationId }
         item={modalEditor.defaultItemAssistanceService}
         closeEditor={handleCloseModal}
         isOpen={modalEditor.serviceopened }
      />);

   const body = (
      <div key={33336}>
         <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <BasicDropdownField
               search={true}
               className="content-width"
               placeholder="Search for a foundation disease name"
               onChange={e => handleChange(Number(e))}
               options={diseaseTypeSelect}
            />
            <Space>
               <AddButton
                  title='Add'
                  buttonText='Add Program'
                  onClick={() => handleAddNewAssistanceProgram()}
               />

               <AddButton
                  title='Add'
                  buttonText='Add Foundation Disease Name'
                  onClick={() => navigate(foundationDiseaseTypeBaseRoute)}
               />
            </Space>
         </div>
         <div className='flex-table'>
            <FoundationAssistanceProgramList
               assistanceProgramLookups={assistanceProgramLookups}
               foundationId={foundationId}
               onEditProgram={handleEditRowAssistanceProgram}
               onAddService={handleAddNewAssistanceService}
               onEditService={handleEditRowAssistanceService} />
         </div>
      </div>
   )

   return (
      <>
         {[body, renderAddNewProgramModal, renderAddNewServiceModal]}
      </>
   );
}

export default FoundationDiseaseType;