import * as React from 'react';
import { Alert } from 'antd';
import Dialog from '../../Dialog';
import { CancelButton } from '../../shared/Buttons';
import { useFetchAssistanceServices } from '../../../store/program/AssistanceServiceFetcher';
import { AssistanceService } from '../../../store/program/AssistanceServiceModel';
import { useApiContext } from '../../../store/ApiContext';

interface IProps {
   isOpen: boolean;
   closeEditor: () => void;
   assistanceProgramId: number;
   assistanceServiceId: number;
};

const AssistanceServiceActionDialog: React.FC<IProps> = (props) => {
   const { httpGet } = useApiContext();
   const [selectedAssistanceService, setSelectedAssistanceService] = React.useState<AssistanceService>();
   const { assistanceServices } = useFetchAssistanceServices(httpGet);
   React.useEffect(() => {
      const item = assistanceServices?.find(y => y.id === props.assistanceServiceId)
      setSelectedAssistanceService(item);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [props.assistanceServiceId, props.isOpen]);

   const html = (
      <Dialog
         scrollingContent={true}
         title={`Assistance Service Editor for ${selectedAssistanceService?.assistanceServiceName}`}
         open={props.isOpen}
         size="small"
         actionButtons={
            <CancelButton onClick={() => {
               props.closeEditor();
            }} />
         }>

         <Alert
            message="Not Implemented"
            description="Future feature"
            type="warning"
            showIcon
            closable
         />

      </Dialog>
   )
   return html;
}

export default AssistanceServiceActionDialog;

