import * as React from 'react';
import { Navigate, Route, Routes } from "react-router";
import { useUserContext } from '../../auth/authContext';
import { isInRole } from '../../functions/auth.functions';
import ContentCard from '../../layouts/ContentCard';
import { KnownSettings } from '../../store/SettingsModel';
import { CohortContextProvider, useCohortContext } from './CohortContext';
import CohortNav from './CohortNav';
import CohortSummaryList from './CohortSummaryList';
import ProgramSummaryDetailList from './programSummary/ProgramSummaryDetailList';
import ProgramSummaryList from './programSummary/ProgramSummaryList';

const CohortIndex: React.FC = () => {

   return <CohortContextProvider>
      <CohortMain />
   </CohortContextProvider>
}

export default CohortIndex;

const CohortMain: React.FC = () => {

   const { cardTitle, cardSubText } = useCohortContext();
   const { userRoles } = useUserContext();

   const accessGranted: boolean = isInRole(userRoles, [KnownSettings.CohortAdmin, KnownSettings.CohortReviewer]);
   if (!accessGranted) {
      console.error('User does not have Cohort permissions.');
      return <Navigate to={{ pathname: '/' }} />;
   }

   const rowhtml = (
      <div>
         <CohortNav />
         <ContentCard title={cardTitle} subTitle={cardSubText}>
            <Routes>
               <Route path='programsummarydetail/:cohortId'
                  element={
                     <ProgramSummaryDetailList />
                  }>
               </Route>
               <Route path='programsummary/:cohortId'
                  element={
                     <ProgramSummaryList />
                  }>
               </Route>
               <Route path='*'
                  element={
                     <CohortSummaryList />
                  }>
               </Route>
            </Routes>
         </ ContentCard>
      </div>
   )

   return (<>
      {rowhtml}
   </>
   )
}