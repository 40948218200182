import * as React from 'react';
import { useFetchSettings, isFeatureEnabled } from '../store/SettingsFetcher';

interface IProps {
   children?: React.ReactNode;
   feature: string;
}

/* Wrap this control around role restricted compoments.
   <FeatureFlag feature="HelloWorld">
      <div>
         Some beta feature
      </div>
   </FeatureFlag>
 */

const FeatureFlag: React.FC<IProps> = (props) => {

   const { settings } = useFetchSettings();
   const isEnabled: boolean = isFeatureEnabled(props.feature, settings);

   const html = (
      <React.Fragment>
         {isEnabled &&
            props.children}
      </React.Fragment>
   )
   return html;
}

export default FeatureFlag;
