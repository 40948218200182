import { IMainMenuItem } from '../store/menu/MainMenu';
import { ISubmenu } from '../store/menu/SubMenu';


export const isInRole = (userRoles: string[], requiredRoles: string[]): boolean => {
   const accessGranted: boolean = requiredRoles.length === 0 || userRoles.some((val: string) => requiredRoles.indexOf(val) !== -1);
   return accessGranted;
}

export const getAllowedRoles = (mainMenuItems: IMainMenuItem[], subMenuItems: ISubmenu[], path: string): string[] => {
   let loweredPath = path?.toLowerCase();
   let allowedRoles: string[] = getAllowedRolesMainMenu(mainMenuItems, loweredPath);
   if (allowedRoles.length === 0) {
      allowedRoles = getAllowedRolesSubMenu(subMenuItems, loweredPath);
   }
   return allowedRoles;
}

const getAllowedRolesMainMenu = (menuItems: IMainMenuItem[], path: string): string[] => {
   for (let idx = 0; idx < menuItems.length; idx++) {
      if (menuItems[idx].route?.toLowerCase() === path) {
         if (menuItems[idx].allowedRoles.length > 0)
            return menuItems[idx].allowedRoles;
      }
      for (let sidx = 0; sidx < menuItems[idx].flyoutItems.length; sidx++) {
         if (menuItems[idx].flyoutItems[sidx].route?.toLowerCase() === path) {
            if (menuItems[idx].flyoutItems[sidx].allowedRoles.length > 0)
               return menuItems[idx].flyoutItems[sidx].allowedRoles;
         }
      }
   }
   return [];
}

const getAllowedRolesSubMenu = (menuItems: ISubmenu[], path: string): string[] => {
   for (let idx = 0; idx < menuItems.length; idx++) {
      for (let catx = 0; catx < menuItems[idx].categories.length; catx++) {
         for (let itmx = 0; itmx < menuItems[idx].categories[catx].items.length; itmx++) {
            if (menuItems[idx].categories[catx].items[itmx].route?.toLowerCase() === path) {
               if (menuItems[idx].categories[catx].items[itmx].allowedRoles.length > 0)
                  return menuItems[idx].categories[catx].items[itmx].allowedRoles;
            }
         }
      }
   }
   return [];
}


export const extractLocation = (path: string): string => {

   if (!path || path.indexOf('?') === -1)
      return path;

   return path.substring(0, path.indexOf('?'));
}

export const getDomainFromEmail = (email: string): string => {
   if (!email || email.indexOf('@') === -1)
      return email;
   const parts = email.split('@');
   return parts.length > 0 ? parts[1] : undefined;
}