import * as React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Col, Form, Row } from 'antd';
import {AssisanceTypeList, IOptionItem } from '../../../functions/option.functions';
import { AssistanceServiceLookup } from '../../../store/program/LookupModels';
import { CancelButton, SaveButton } from '../../shared/Buttons';
import { useApiContext } from '../../../store/ApiContext';
import { useServiceCategoryOptionsVerbose } from '../../../store/program/ServiceCategoryFetcher';
import { assistanceServiceSaveLookupItemUrl, saveAssistanceServiceLookupItem,  } from '../../../store/program/AssistanceServiceFetcher';
import ApiErrorDisplay from '../../ApiErrorDisplay';
import Spinner from '../../Spinner';
import Dialog from '../../Dialog';
import { TitleError } from '../../shared/AntComponents/Typography/Title';
import { DropdownField, InputField, TextAreaField } from '../../shared/InputLibrary';
import { useErrorContext } from '../../../store/ErrorContext';

type Props = {
   foundationId: number,
   item: AssistanceServiceLookup,
   closeEditor: () => void;
   isOpen: boolean;
}

const dialogContentStyle = {
   maxHeight: '95vh',
}

const yupAssistanceServiceLookupSchema = yup.object({
   assistanceServiceName: yup.string().max(1024).required(),
   description: yup.string().max(1024).notRequired(),
   assistanceType: yup.number().required(),
   serviceCategoryId: yup.number().notRequired(),
   assistanceServiceId: yup.number().notRequired(),
   assistanceProgramId: yup.number().required(),
});

interface IAssistanceServiceLookupSchema extends yup.Asserts<typeof yupAssistanceServiceLookupSchema> { }
const __formId = "frmAssistanceServiceLookupSchema";
const _keysLike: string[] = [assistanceServiceSaveLookupItemUrl];

//NOTE: this editor should be replaced with AssistanceServiceEditor -> but
//    with ties to foundation, leaving it for future foundation related dev work. 
const AssistanceServiceLookupEditor: React.FC<Props> = (props) => {
   const { item, closeEditor, isOpen, foundationId } = props;

   const { httpGet, httpPost } = useApiContext();
   const { serviceCategoryOptions } = useServiceCategoryOptionsVerbose(httpGet);
   const [isFormSaving, setIsFormSaving] = React.useState(false);
   const { removeErrors } = useErrorContext();
  
   const assistanceTypeOptions = AssisanceTypeList?.map((at): IOptionItem => ({
         label: at.name,
         value: at.id
      } as IOptionItem));

   const {
      control,
      handleSubmit,
      formState: { errors },
      reset,
   } = useForm<IAssistanceServiceLookupSchema>({
      resolver: yupResolver<yup.AnyObject>(yupAssistanceServiceLookupSchema),
      shouldFocusError: true,
   });

   const itemToEdit = React.useMemo(() => {
      return {
         ...item,
         assistanceType: item?.assistanceType === 0 ? undefined : item?.assistanceType
      }
   }, [item]);


      React.useEffect(() => {
         reset(itemToEdit); 
         setIsFormSaving(false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [itemToEdit, isOpen]);


   const onSubmit = (data: IAssistanceServiceLookupSchema) => {
      setIsFormSaving(true);
      const _item = data as AssistanceServiceLookup;

      saveAssistanceServiceLookupItem(httpPost, _item, foundationId)
         .then(handleClose)
         .catch(err => console.log(`Error Saving`, err))
         .finally(() => setIsFormSaving(false));
   }

   const handleClose = () => {
      setIsFormSaving(false);
      removeErrors({ keysLike: _keysLike });
      closeEditor();
   }

   if (isFormSaving) {
      return <Spinner message='Saving Practice...' />
   }

   const html = (
      <Dialog
         scrollingContent={true}
         title={item && item.assistanceServiceId ?
            `Edit Assistance Service: ${item.assistanceServiceId}` :
            `Add New Assistance Service`}
         open={isOpen}
         size="large"
         actionButtons={
            <>
               <CancelButton
                  onClick={() => handleClose()}
                  loading={isFormSaving}
               />
               <SaveButton
                  formId={__formId}
                  onClick={() => null}
                  loading={isFormSaving}
               />
            </>
         }>

         <div style={dialogContentStyle}>

            <ApiErrorDisplay
               title='Error saving Assistance Service Lookup'
               keysLike={_keysLike}
            />

            {errors && Object.keys(errors).length > 0 &&
               <TitleError text='Please correct the errors below' key={'errorKey'} />
            }

            <Form 
               id={__formId}
               onFinish={handleSubmit(onSubmit)}>

               <Row gutter={[32, 32]} justify='space-between' align='top'>
                  <Col span={12}>
                     <Row>
                        <Col span={24}>
                           <InputField
                              control={control}
                              name='assistanceServiceName'
                              label='Assistance Service Name'
                              type='text'
                              error={errors?.assistanceServiceName}
                              required={true}
                           />
                        </Col>
                     </Row>
                     <Row>
                        <Col span={24}>
                           <TextAreaField
                              control={control}
                              name='description'
                              label='Service Description'
                              error={errors?.description}
                           />
                        </Col>
                     </Row>
                  </Col>
                  <Col span={12}>
                     <Row>
                        <Col span={24}>
                           <DropdownField
                              control={control}
                              name='assistanceType'
                              label='Assistance Type'
                              error={errors?.assistanceType}
                              options={assistanceTypeOptions}
                              required={true}
                           />
                        </Col>
                     </Row>
                     <Row>
                        <Col span={24}>
                           <DropdownField
                              control={control}
                              name='serviceCategoryId'
                              label='Service Category'
                              error={errors?.serviceCategoryId}
                              options={serviceCategoryOptions}
                           />
                        </Col>
                     </Row>
                  </Col>
               </Row>           
            </Form>
         </div >

      </Dialog>
   );
   return html
}

export default AssistanceServiceLookupEditor;