import * as React from 'react';
import { IFormError, IValidationResult } from '../../../functions/form.contracts';
import { validateField } from '../../../functions/form.validators';
import { useApiContext } from '../../../store/ApiContext';
import { useErrorContext } from '../../../store/ErrorContext';
import {
   cohortItemSaveAnswersUrl,
   saveCohortAnswers
} from '../../../store/cohort/CohortItemFetcher';
import {
   CohortItem,
   CohortItemAnswer,
   CohortItemAnswerRequest
} from '../../../store/cohort/CohortItemModel';
import { CohortSummary } from '../../../store/cohort/CohortSummaryModel';
import {
   ProgramSummaryDataGroup, countCohortItems
} from '../../../store/cohort/ProgramSummaryDetailModel';
import ApiErrorDisplay from '../../ApiErrorDisplay';
import Dialog from '../../Dialog';
import '../../grid/Grid.css';
import { CancelButton, SaveButton } from '../../shared/Buttons';
import { useCohortContext } from '../CohortContext';
import CohortItemList from './CohortItemList';
import BenefitPeriodSelector from "./BenefitPeriodSelector";
import { deepCopy } from '../../../functions/common.functions';

interface IProps {
   cohort: CohortSummary,
   dataGroups: ProgramSummaryDataGroup[],
   onClose: () => void;
   isOpen: boolean;
}

const errorKeys = {keys: [cohortItemSaveAnswersUrl]};

const DataGroupEditor: React.FC<IProps> = (props) => {
   const {cohort, dataGroups, isOpen, onClose} = props;
   const [isFormSaving, setIsFormSaving] = React.useState<boolean>(false);
   const [cohortAnswers, setCohortAnswers] = React.useState<CohortItemAnswer[]>([]);
   const [localDataGroups, setLocalDataGroups] = React.useState<ProgramSummaryDataGroup[]>([]);

   const {httpPost} = useApiContext();
   const {cohortSummaryFromDate, setFormErrors, formErrors} = useCohortContext();

   const {removeErrors} = useErrorContext();

   const clearErrors = () => {
      removeErrors(errorKeys);
      setFormErrors([]);
   }

   const resetLocalDataGroups = (source: ProgramSummaryDataGroup[]) => {
      const copy = source ? deepCopy(source) : [];
      setLocalDataGroups(copy);
   }

   React.useEffect(() => {
      if (isOpen) resetLocalDataGroups(dataGroups);
   }, [isOpen]);

   React.useEffect(() => {
      const resetEditor = () => {
         if (!isOpen)
            setFormErrors([]);
      }
      resetEditor();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [isOpen]);

   const handleSave = (): void => {
      setIsFormSaving(true);
      let anyErrors = false;

      //build answer list that contains all items in this datagroup.  
      let cohortAnswersTmp: CohortItemAnswer[] = [];
      for (let groupIdx = 0; groupIdx < localDataGroups.length; groupIdx++) {
         for (let itemIdx = 0; itemIdx < localDataGroups[groupIdx].cohortItems.length; itemIdx++) {

            const cohortItem: CohortItem = localDataGroups[groupIdx].cohortItems[itemIdx];
            //make sure validation is run against all the form items
            const validationResult = validateField(cohortItem.value, cohortItem);
            if (!validationResult.isValid) {
               anyErrors = true;
            }

            //check if item exists in current answers -> user changed the value
            let answer: CohortItemAnswer =
               cohortAnswers.find(y => y.id === cohortItem.cohortItemId);

            if (answer)
               cohortAnswersTmp.push(answer); // only want to add new answers for more accurate counts - AH-5545
         }
      }

      if (formErrors.length === 0 && !anyErrors) {

         const answerRequest: CohortItemAnswerRequest = {
            cohortId: cohort.cohortId,
            cohortItemAnswers: cohortAnswersTmp
         };

         saveCohortAnswers(httpPost, answerRequest, cohortSummaryFromDate)
            .then(() => {
               handleClose();
            })
            .catch((err) => {
               console.error(`error: ${err?.message.substring(0, 41)} ... [check logs for more details]`);
            })
            .finally(() => {
               setIsFormSaving(false);
            });
      }
   }

   const handleCancel = (): void => {
      resetLocalDataGroups(dataGroups);
      setCohortAnswers([]);
      handleClose();
   }

   const handleClose = () => {
      setIsFormSaving(false);
      clearErrors();
      if (onClose)
         onClose();
   }

   const handleAnswerChange = (cohortFieldId: number, val: any): void => {
      const cohortAnswersCopy = [...cohortAnswers];
      const dataGroupsCopy = deepCopy(localDataGroups)

      const result = internalAnswerChange(cohortFieldId, val, cohortAnswersCopy, dataGroupsCopy);
      if (result.isValid === false) {
         setFormErrors([
            ...formErrors.filter(y => y.DataName !== cohortFieldId),
            result.formError
         ]);
      } else {
         if (formErrors.some(y => y.DataName === cohortFieldId)) {
            setFormErrors([...formErrors.filter(y => y.DataName !== cohortFieldId)]);
         }
      }
      setCohortAnswers(cohortAnswersCopy);
      setLocalDataGroups(dataGroupsCopy);
   }

   const handleAnswerChanges = (changes: { cohortFieldId: number, val: any }[]): void => {
      const cohortAnswersCopy = [...cohortAnswers];
      const dataGroupsCopy = deepCopy(localDataGroups)
      const result: IValidationResult[] = [];
      changes && changes.map((change, index) => {
         var _valResult = internalAnswerChange(change.cohortFieldId, change.val, cohortAnswersCopy, dataGroupsCopy);
         result.push(_valResult);
      });
      const formErrors = result.filter(err => !err.isValid);
      setFormErrors(formErrors.map(fe => fe.formError));
      setCohortAnswers(cohortAnswersCopy);
      setLocalDataGroups(dataGroupsCopy);
   }

   const internalAnswerChange = (
      cohortFieldId: number,
      val: any,
      cohortAnswersCopy: CohortItemAnswer[],
      dataGroupsCopy: ProgramSummaryDataGroup[]): IValidationResult => {
      let validationResult: IValidationResult;

      for (let groupIdx = 0; groupIdx < dataGroupsCopy.length; groupIdx++) {
         //dataGroups will have distinct cohortFieldId's per row
         let itemIdx = dataGroupsCopy[groupIdx].cohortItems.findIndex(y => y.cohortFieldId === cohortFieldId);
         let cohortItem: CohortItem = dataGroupsCopy[groupIdx].cohortItems[itemIdx];
         let answerVal = val;
         if (cohortItem) {
            dataGroupsCopy[groupIdx].cohortItems[itemIdx].value = answerVal;

            validationResult = validateField(answerVal ,cohortItem);

            if (!validationResult?.isValid) {
               console.warn(`cohortItem with id: '${cohortItem.cohortItemId}' and value: '${answerVal}' is not valid.`);
            } else {
               //update or push answer
               const answerIdx = cohortAnswersCopy.findIndex(y => y.id === cohortItem.cohortItemId);
               if (answerIdx > -1) {
                  cohortAnswersCopy[answerIdx].answer = answerVal;
               } else {
                  cohortAnswersCopy.push({id: cohortItem.cohortItemId, answer: answerVal});
               }
            }

            continue;
         } else {
            //this statement will execute for those empty cells.  A disease type \ brand does not have
            // the datagroup or cohort field in question.
            console.debug(`cohortItem with cohortFieldId: '${cohortFieldId}' not found.`);
         }
      }
      return validationResult;
   }
   if (!localDataGroups || localDataGroups.length === 0) return null;

   const firstRowWithItemsIndex = localDataGroups.findIndex(y => y.cohortItems?.length > 0);

   return (
      <Dialog
         scrollingContent={true}
         title={`Edit ${localDataGroups[firstRowWithItemsIndex]?.dataGroupName}: ${countCohortItems(localDataGroups)} Items`}
         open={isOpen}
         size="small"
         actionButtons={
            <>
               <CancelButton onClick={() => handleCancel()}/>
               <SaveButton
                  onClick={() => handleSave()}
                  loading={isFormSaving}
                  disabled={formErrors?.length > 0 || (cohortAnswers?.length ?? 0) === 0}
               />
            </>
         }>

         <ApiErrorDisplay
            title='Error saving Data Group'
            keys={errorKeys.keys}/>

         {localDataGroups.length > 0 &&
            <div className="appform-section-container">
               {localDataGroups[firstRowWithItemsIndex]?.dataGroupId === 34 &&
                  <BenefitPeriodSelector
                     cohortItems={localDataGroups[firstRowWithItemsIndex]?.cohortItems}
                     onInputChanges={handleAnswerChanges}/>
               }
               {localDataGroups[firstRowWithItemsIndex]?.dataGroupId !== 34 &&
                  <CohortItemList
                     cohortItems={localDataGroups[firstRowWithItemsIndex]?.cohortItems}
                     onInputChange={handleAnswerChange}
                     errors={formErrors}/>
               }
            </div>
         }
         {!localDataGroups || localDataGroups.length === 0 &&
            <div>No DataGroups Found</div>
         }
      </Dialog>
   );
}

export default DataGroupEditor;