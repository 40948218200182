import * as React from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { InputField, DropdownField, CheckboxField, DatePickerField } from '../../shared/InputLibrary';
import { Form } from 'antd';
import Dialog from '../../Dialog';
import { SaveButton, DeleteButton, CancelButton } from '../../shared/Buttons';
import Spinner from '../../Spinner';
import { useDrugOptions } from '../../../store/drug/DrugFetcher';
import {
   saveBillingCode,
   useFetchBillingCode,
   deleteBillingCode,
   billingCodeSaveUrl,
   billingCodeDeleteUrl
} from '../../../store/drug/BillingCodeFetcher';
import { BillingCode } from '../../../store/drug/BillingCodeModel';
import ApiErrorDisplay from '../../ApiErrorDisplay';
import { TitleError } from '../../shared/AntComponents/Typography/Title';
import { useApiContext } from '../../../store/ApiContext';
import { useErrorContext } from '../../../store/ErrorContext';

interface IProps {
    isOpen: boolean;
    closeEditor: () => void;
    id?: number;
};

const dialogContentStyle = {
    height: '580px',
    maxHeight: '95vh',
}

const yupBillingCodeSchema = yup.object({
    id: yup.number().notRequired(),
    code: yup.string()
        .required('Code is required')
        .max(10, 'Max length is 10 characters'),
    drugId: yup.number().required(),
    description: yup.string()
        .max(1024, 'Max length is 1024 characters')
        .required(),
    inactive: yup.boolean()
        .notRequired(),
    inactiveStartDate: yup.date()
      .notRequired()
       .nullable()
       .default(undefined),
});

interface IBillingCodeYup extends yup.Asserts<typeof yupBillingCodeSchema> { }
const __formId = "frmBillingCode";

const BillingCodeEditor: React.FC<IProps> = (props) => {
   const { closeEditor, isOpen, id } = props;
   const _keys: string[] = id ? [billingCodeSaveUrl, billingCodeDeleteUrl(id)] : [billingCodeSaveUrl];

   const { httpGet, httpPost, httpDelete } = useApiContext();
   const { removeErrors } = useErrorContext();
   const { drugOptions } = useDrugOptions(httpGet);
   const { billingCodes } = useFetchBillingCode(httpGet);

   const [isFormSaving, setIsFormSaving] = React.useState<boolean>(false);

   const {
      control,
      handleSubmit,
      formState: { errors },
      reset,
      getValues
   } = useForm<IBillingCodeYup>(      
      {      
         resolver: yupResolver<yup.AnyObject>(yupBillingCodeSchema),
      shouldFocusError: true,
   });

   React.useEffect(() => {
      let resetVal = {};
      if (billingCodes && id && isOpen) {
         resetVal = billingCodes.find(y => y.id === id);
      }
      reset(resetVal);
      // for "reset(...)"
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [billingCodes, id, isOpen]);

   const onSubmit = async (data: IBillingCodeYup) => {
      //console.log('--------------Editor-------------------------')
      //console.log(errors)

      //ts hoops to convert data 
      const item = data as unknown as BillingCode;
      const itemsToSave: BillingCode[] = [item];
      setIsFormSaving(true);
      await saveBillingCode(httpPost, itemsToSave)
         .then(handleClose)
         .catch(err => console.error('Error Saving Billing Code', err))
         .finally(() => {
            setIsFormSaving(false);
         })
   }

   const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState<boolean>(false);

   const handleDeleteClick = async () => {
      await deleteBillingCode(httpDelete, getValues().id)
         .then(handleClose)
         .catch(err => console.error('Error Deleting Billing Code', err))
   }

   const handleClose = () => {
      setIsFormSaving(false);
      removeErrors({ keys: _keys });
      setIsDeleteDialogOpen(false);
      closeEditor();
   }

   if (isFormSaving) {
      return <div><Spinner message='Saving Billing Code...' /></div>
   }

   //console.log('--------------AssistanceServcieEditor-------------------------')
   //console.log(errors)


   const html = (
      <Dialog
         scrollingContent={true}
         title={'Billing Code Editor'}
         open={props.isOpen}
         size="large"
         actionButtons={
            <>
               <CancelButton onClick={() => {
                  handleClose();
               }} />
               <DeleteButton
                  onClick={() => setIsDeleteDialogOpen(true)}
                  disabled={!getValues()?.id || isDeleteDialogOpen}
                  loading={isDeleteDialogOpen} />
               <SaveButton
                  formId={__formId}
                  onClick={() => null}
                  disabled={Object.keys(errors).length > 0}
                  loading={isFormSaving} />
            </>
         }>
         <div style={dialogContentStyle}>

            {errors && Object.keys(errors).length > 0 && <>
               <TitleError
                  text='Please correct the errors below'  />
               {console.log('--------------Form Errors: -------------------------')}
               {console.log(errors)}
               </>
            }

            <ApiErrorDisplay
               title='Error saving Billing Code'
               keys={_keys}
            />

            <Form id={__formId} onFinish={handleSubmit(onSubmit)}>

               <InputField
                  control={control}
                  name='code'
                  label='Code'
                  type='text'
                  error={errors?.code}
                  required={true}
               />
               <DropdownField
                  control={control}
                  name='drugId'
                  label='Drug Id'
                  error={errors?.drugId}
                  multiple={false}
                  options={drugOptions}
                  required={true}
                  search={true}
                  clearable={true}
               />
               <InputField
                  control={control}
                  name='description'
                  label='Description'
                  type='text'
                  error={errors?.description}
                  required={true}
               />
               <CheckboxField
                  control={control}
                  name='inactive'
                  label='Inactive'
                  error={errors?.inactive}
                  toggle={true}
                  required={false}
               />
               <DatePickerField
                 name='inactiveStartDate'
                  label='Inactive Start Date'
                  control={control}
                  error={errors?.inactiveStartDate}
               />
            </Form>
         </div>

         <Dialog
            title='CONFIRM DELETE'
            open={isDeleteDialogOpen}
            style={{ maxWidth: '550px' }}
            actionButtons={
               <>
                  <CancelButton onClick={() => setIsDeleteDialogOpen(false)} />
                  <DeleteButton
                     disabled={isFormSaving}
                     onClick={() => handleDeleteClick()} />
               </>
            }
         >
            {`Are you sure you want to Delete the Billing Code (${getValues()?.code}) ${getValues()?.description || ''}?`}
         </Dialog>

      </Dialog>
   )
   return html;
}

export default BillingCodeEditor;

