import { getKey, KeyValue, KeyValueItem } from '../models';
import { PowerBIReport, PowerBIReportVM } from './ReportModel'

export const WorkspaceParam: string = 'ws';
export const ReportParam: string = 'r';
export const View_Browse: string = 'browse';  // each "view" must match the end of the corresponding route in MainMenu.ts
export const View_Browse_Certified: string = 'browsecertified'; // potential TODO ^ one set of consts
export const View_Browse_Uncertified: string = 'browseuncertified';
export const View_Favorite: string = 'favorite';
export const View_Search: string = 'search';

//get case insenstive list of distict workspaces
export const getWorkspaceSet = (reports: PowerBIReport[]): KeyValue[] => {
   const workspaceSet = [...new Map(reports?.map(r => [getKey(r.workspaceName),
   {
      key: getKey(r.workspaceName),
      value: r.workspaceName,
   } as KeyValue])).values()];

   return workspaceSet;
}

//get case insenstive list of distinct headings
export const getHeadingSet = (reports: PowerBIReportVM[]): KeyValue[] => {
   const headingSet = [...new Map(reports?.map(r => [r.viewHeadingKey,
   {
      key: r.viewHeadingKey,
      value: r.heading,
   } as KeyValue])).values()];

   return headingSet;
}


//in list should already be filtered on workspace
export const getReportSet = (workspaceReports: PowerBIReport[]): KeyValue[] => {

   const workspaceSet = [...new Map(workspaceReports?.map(r => [getKey(r.reportName),
   {
      key: getKey(r.reportName),
      value: r.reportName,
   } as KeyValue])).values()];

   return workspaceSet;
}

// in list should already be filtered by heading
export const getHeadingReportPageGroupSet = (headingPages: PowerBIReportVM[]): KeyValue[] => {

   const headingReportNameSet = [...new Map(headingPages?.map(r => [r.viewHeadingReportPageGroupKey,
   {
      key: r.viewHeadingReportPageGroupKey,
      value: r.reportPageGroup,
   } as KeyValue])).values()];

   return headingReportNameSet;
}

//in list should already be filtered on workspace
export const getReportPageGroupSet = (workspaceReports: PowerBIReport[], item: KeyValue): KeyValueItem<KeyValue>[] => {
   const filteredReports = workspaceReports?.filter(y => getKey(y.reportName) === item.key);
   const reportPageGroupSet = [...new Map(filteredReports.map(r => [getKey(r.reportPageGroup),
   {
      key: getKey(r.reportPageGroup),
      value: r.reportPageGroup,
      item: item
   } as KeyValueItem<KeyValue>])).values()];

   return reportPageGroupSet;
}

export const getReportGroupKey = (reportKey: string, reportPageGroupKey: string) =>
   `${reportKey}_${reportPageGroupKey}`;


export const buildReportUrl = (view: string, workspaceKey?: string, reportKey?: string, powerBIReportId?: number): string => {

   let url = `/report/${view}`

   url += powerBIReportId ? `/${powerBIReportId}` : '/0'

   if ((view === View_Browse_Certified || view === View_Browse_Uncertified)
       && workspaceKey) {
      url += `?${WorkspaceParam}=${encodeURI(workspaceKey)}`;
      if (reportKey) {
         url += `&${ReportParam}=${encodeURI(reportKey)}`
      }
   }

   return url;
}