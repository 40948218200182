import * as React from 'react';
import { Form, Row, Col } from 'antd';
import CustomIcon, { CustomIconType } from '../../shared/AntComponents/CustomIcon'
import { useForm } from 'react-hook-form';
import { DropdownField, InputField, TextAreaField } from '../../shared/InputLibrary';
import { BasicFieldWrapper } from '../../shared/BasicInputLibrary';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Dialog from '../../Dialog';
import { SaveButton, CancelButton } from '../../shared/Buttons';
import { TitleError } from '../../shared/AntComponents/Typography/Title';
import Spinner from '../../Spinner';

import { PowerBIReport } from '../../../store/report/ReportModel';
import {
   useReportList, savePowerBIReport,
   updateReportUrl
} from '../../../store/report/ReportFetcher';
import ApiErrorDisplay from '../../ApiErrorDisplay';
import colorWheel from '../../../Theme/ColorWheel';
import { formatShortDateString } from '../../../functions/format.functions';
import { useApiContext } from '../../../store/ApiContext';
import { reportTypeOptions } from '../../../store/report/PowerBIExtermalReportModel';
import { IOptionItem } from '../../../functions/option.functions';
import { useErrorContext } from '../../../store/ErrorContext';

interface IProps {
   isOpen: boolean;
   closeEditor: () => void;
   id?: number;
};

const dialogContentStyle = {
   //height: '580px',
   maxHeight: '95vh',
}

const yupPowerBIReportEditorSchema = yup.object({
   id: yup.number().required(),
   reportDescription: yup.string()
      .max(4000, 'Max length is 4000 characters')
      .notRequired(),
   reportType: yup.string()
      .max(256, 'Max length is 256 characters')
      .required(),
   reportPageGroup: yup.string()
      .max(256, 'Max length is 256 characters')
      .required(),
   reportName: yup.string()
      .max(256, 'Max length is 256 characters')
      .required(),
   reportPageName: yup.string()
      .max(256, 'Max length is 256 characters')
      .required(),
   infoUrl: yup.string()
      .max(1023, 'Max length is 1023 characters')
      .notRequired(),
   createdOn: yup.date().notRequired(),
   modifiedOn: yup.date().notRequired(),
   order: yup.number().notRequired(),
});

interface IPowerBIReportEditorYup extends yup.Asserts<typeof yupPowerBIReportEditorSchema> { }

const __formId = "frmPowerBIReportEditor";
const _keys: string[] = [updateReportUrl];

const PowerBIReportEditor: React.FC<IProps> = (props) => {

   const { id, isOpen, closeEditor } = props;

   const { httpGet, httpPut } = useApiContext();
   const { powerBIReports } = useReportList(httpGet);
   const { removeErrors } = useErrorContext();
   const [isFormSaving, setIsFormSaving] = React.useState<boolean>(false);

   const {
      control,
      handleSubmit,
      formState: { errors },
      reset,
      setValue
   } = useForm<IPowerBIReportEditorYup>({
      resolver: yupResolver<yup.AnyObject>(yupPowerBIReportEditorSchema),
      shouldFocusError: true,
   });

   const itemToEdit = React.useMemo(() => {
      return powerBIReports?.find(y => y.id === id);
   }, [id, powerBIReports]);

   const reportPageGroups = React.useMemo(() => {
      const _groups: IOptionItem[] = [];
      powerBIReports?.reduce((acc: IOptionItem[], item) => {
         if (!_groups.some(y => y.value === item.reportPageGroup)) {
            const option = {
               label: item.reportPageGroup ?? '',
               value: item.reportPageGroup ?? ''
            } as IOptionItem

            _groups.push(option);
         }
         return acc;
      }, []);
      return _groups;
   }, [powerBIReports]);
   
   React.useEffect(() => {
      // console.log(assistanceProgramToEdit);
      reset(itemToEdit);
      setIsFormSaving(false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [itemToEdit, isOpen]);

   const onSubmit = (data: IPowerBIReportEditorYup): void => {
      //console.log('--------------Editor-------------------------')
      //console.log(errors)

      //ts hoops to convert data with unknown props into assistance programs
      setIsFormSaving(true);
      const item = data as unknown as PowerBIReport;
      const itemToEditUpdated: PowerBIReport = {
         ...itemToEdit,
         reportDescription: item.reportDescription,
         reportType: item.reportType,
         reportPageGroup: item.reportPageGroup,
         reportPageName: item.reportPageName,
         infoUrl: item.infoUrl
      }

      savePowerBIReport(httpPut, itemToEditUpdated)
         .then(handleClose)
         .catch(err => console.error('Error calling savePowerBIReport', err))
         .finally(() => setIsFormSaving(false));
   }

   const handleClose = () => {
      removeErrors({ keys: _keys });
      closeEditor();
   }

   if (!itemToEdit) {
      return null;
   }

   if (isFormSaving) {
      return <Spinner message='Saving PowerBIReports...' />
   }

   //console.log('--------------PowerBIReportManagementEditor-------------------------')
   //console.log(errors)

   const html = (
      <Dialog
         scrollingContent={true}
         title={'External Report Editor'}
         open={isOpen}
         size="large"
         actionButtons={
            <>
               <CancelButton onClick={() => {
                  handleClose();
               }} />
               <SaveButton
                  formId={__formId}
                  onClick={() => null}
                  disabled={Object.keys(errors).length > 0}
                  loading={isFormSaving} />
            </>
         }>
         <div style={dialogContentStyle}>

            {errors && Object.keys(errors).length > 0 && <>
               <TitleError
                  text='Please correct the errors below' />
               {console.log('--------------Form Errors: -------------------------')}
               {console.log(errors)}
            </>
            }

            <ApiErrorDisplay
               title='Error saving PowerBIReportManagement'
               keys={_keys}
            />

            <Form
               id={__formId}
               onFinish={handleSubmit(onSubmit)}>

               <Row gutter={[16, 16]}>
                  <Col span={8}>
                     <InputField
                        control={control}
                        name='reportName'
                        label='Report Name'
                        disabled={true}
                        error={errors?.reportName}
                     />
                  </Col>
                  <Col span={8}>
                     <InputField
                        control={control}
                        name='reportPageName'
                        label='Report Page Name'
                        disabled={false}
                        error={errors?.reportPageName}
                     />
                  </Col>
                  <Col span={8}>
                     <DropdownField
                        control={control}
                        name='reportType'
                        label='Report Type'
                        error={errors?.reportType}
                        multiple={false}
                        options={reportTypeOptions}
                        setValue={setValue}
                        required={true}
                     />
                  </Col>
                  <Col span={8}>
                     <DropdownField
                        control={control}
                        name='reportPageGroup'
                        label='Report Page Group'
                        error={errors?.reportPageGroup}
                        multiple={false}
                        options={reportPageGroups}
                        setValue={setValue}
                        required={true}
                     />
                  </Col>
                  <Col span={16}>
                     <InputField
                        control={control}
                        name='infoUrl'
                        label='Info URL'
                        error={errors?.infoUrl}
                     />
                  </Col>
               </Row>
               <Row gutter={[16, 16]}>
                  <Col span={24}>
                     <TextAreaField
                        control={control}
                        name='reportDescription'
                        label='Report Description'
                        error={errors?.reportDescription}
                        required={false}
                     />
                  </Col>
               </Row>
               <Row gutter={[16, 16]}>
                  <Col span={3}>
                     <BasicFieldWrapper label='Active'
                        field={
                           <CustomIcon
                              style={{
                                 color: itemToEdit.isActive ? colorWheel.ivyGreen : colorWheel.red,
                                 fontSize: 20,
                                 fontWeight: 'bold'
                              }}
                              type={itemToEdit.isActive ? CustomIconType.CheckOutlined : CustomIconType.CloseOutlined}
                           />
                        } />
                  </Col>
                  <Col span={6}>
                     <BasicFieldWrapper label='Created On' field={formatShortDateString(itemToEdit?.createdOn)} />
                  </Col>
                  <Col span={6}>
                     <BasicFieldWrapper label='Modified On' field={formatShortDateString(itemToEdit?.modifiedOn)} />
                  </Col>
               </Row>
            </Form>

         </div>

      </Dialog>
   )
   return html;
}

export default PowerBIReportEditor;
