import * as React from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { InputField, DropdownField } from '../../shared/InputLibrary';
import ApiErrorDisplay from '../../ApiErrorDisplay';
import Dialog from '../../Dialog';
import { SaveButton, DeleteButton, CancelButton } from '../../shared/Buttons';
import { Form, Divider, Row, Col } from 'antd';
import { useUnitOfMeasureOptions } from '../../../store/form/FormOptionItemFetcher';
import { useBrandOptions } from '../../../store/drug/BrandFetcher';
import { useFetchAssistancePrograms } from '../../../store/program/AssistanceProgramFetcher';
import { AssistanceServiceBrandDefaults } from '../../../store/program/AssistanceServiceBrandDefaultsModel';
import { IOptionItem } from '../../../functions/option.functions';
import { TitleError } from '../../shared/AntComponents/Typography/Title';
import { AssistanceService } from '../../../store/program/AssistanceServiceModel';
import { useBenefitPeriodTypeOptions } from '../../../store/program/BenefitPeriodTypeFetcher';
import {
   CALENDAR_BENEFIT_TYPE_ID, ENROLLMENT_BENEFIT_TYPE_ID,
   monthOptions, dayOptions
} from '../../../store/program/BenefitPeriodTypeModel';
import { deleteBrandDefault, saveBrandDefaults, useFetchBrandDefaults, assistanceServiceBrandDefaultsBaseUrl } from '../../../store/program/AssistanceServiceBrandDefaultsFetcher';
import { useApiContext } from '../../../store/ApiContext';
import { useFetchAssistanceServices } from '../../../store/program/AssistanceServiceFetcher';
import { useCohortContext } from '../../cohort/CohortContext';
import { leftPadDayOrMonth } from '../../../functions/common.functions';
import { isDateValid } from 'src/functions/time.functions';
import { useErrorContext } from '../../../store/ErrorContext';


interface IProps {
   isOpen: boolean;
   closeEditor: () => void;
   id?: number;
   readonly?: boolean;
   shouldBrandBeDisabled?: boolean; // should be set to true when editing from a manufacturer program
   shouldAssistanceServiceBeDisabled?: boolean; // should be set to true when editing/"adding new" as a User is viewing a specific service when doing so
   shouldUseProvidedAssistanceService?: boolean; // should be set to true when "adding new" as a User is viewing a specific service when they click "add"
   assistanceService?: AssistanceService;
   brandIdsMappedForDrugs?: number[];
};

const dialogContentStyle = {
   height: '580px',
   maxHeight: '95vh',
}

const yupBrandDefaultsSchema = yup.object({
   id: yup.number().notRequired(),
   assistanceServiceId: yup.number()
      .required('Assistance Service is required'),
   brandId: yup.number()
      .required('Brand is required'),
   copayAmountDose1: yup.number()
      .nullable()
      //transform from empty string to null ¯\_(ツ)_/¯
      .transform((_, val) => val === '' ? null : Number(val)),

   copayAmountDose1Units: yup.string()
      .max(50, 'Max length is 50 characters')
      .notRequired(),
   copayAmountDose1Notes: yup.string()
      .max(512, 'Max length is 512 characters')
      .notRequired(),
   copayAmountDose1Url: yup.string()
      .url('The Resource URL must be a fully-qualified url that begins with "https://" or "https://"')
      .max(1024, 'Max length is 1024 characters')
      .notRequired(),

   copayAmountDoseN: yup.number()
      .nullable()
      .transform((_, val) => val === '' ? null : Number(val)),

   copayAmountDoseNUnits: yup.string()
      .max(50, 'Max length is 50 characters')
      .notRequired(),
   copayAmountDoseNNotes: yup.string()
      .max(512, 'Max length is 512 characters')
      .notRequired(),
   copayAmountDoseNUrl: yup.string()
      .url('The Resource URL must be a fully-qualified url that begins with "https://" or "https://"')
      .max(1024, 'Max length is 1024 characters')
      .notRequired(),

   approvedAwardAmount: yup.number()
      .nullable()
      .transform((_, val) => val === '' ? null : Number(val)),

   approvedAwardAmountUnits: yup.string()
      .max(50, 'Max length is 50 characters')
      .notRequired(),
   approvedAwardAmountNotes: yup.string()
      .max(512, 'Max length is 512 characters')
      .notRequired(),
   approvedAwardAmountUrl: yup.string()
      .url('The Resource URL must be a fully-qualified url that begins with "https://" or "https://"')
      .max(1024, 'Max length is 1024 characters')
      .notRequired(),

   backDateDays: yup.number()
      .nullable()
      .transform((_, val) => val === '' ? null : Number(val)),

   backDateDaysUnits: yup.string()
      .max(50, 'Max length is 50 characters')
      .notRequired(),
   backDateDaysNotes: yup.string()
      .max(512, 'Max length is 512 characters')
      .notRequired(),
   backDateDaysUrl: yup.string()
      .url('The Resource URL must be a fully-qualified url that begins with "https://" or "https://"')
      .max(1024, 'Max length is 1024 characters')
      .notRequired(),

   timelyFilingDays: yup.number().notRequired(),
   timelyFilingDaysUnits: yup.string()
      .max(50, 'Max length is 50 characters')
      .notRequired(),
   timelyFilingDaysNotes: yup.string()
      .max(512, 'Max length is 512 characters')
      .notRequired(),
   timelyFilingDaysUrl: yup.string()
      .url('The Resource URL must be a fully-qualified url that begins with "https://" or "https://"')
      .max(1024, 'Max length is 1024 characters')
      .notRequired(),

   url: yup.string().notRequired(),
   isHcpcsCovered: yup.number()
      .nullable()
      .transform((_, val) => val === '' ? null : Number(val)),

   isChemotherapyDrugsCovered: yup.number().notRequired(),
   isConcurrentDrugsOnChemoDaysCovered: yup.number().notRequired(),
   isAdministrativeServicesOnChemoDaysCovered: yup.number().notRequired(),
   isOfficeVisitsCovered: yup.number().notRequired(),
   isRadiationTherapyCovered: yup.number().notRequired(),
   benefitPeriodTypeId: yup.number().required('Benefit Period Type is required'),
   benefitPeriodStartMonth: yup.number()
      .min(1, 'Benefit Period Start Month must be a valid month')
      .max(12, 'Benefit Period Start Month must be a valid month')
      .when('benefitPeriodTypeId', {
         is: CALENDAR_BENEFIT_TYPE_ID,
         then: () => yup.number()
            .test('benefitPeriodStartMonthDateCheck', 'Benefit Period Start Month is required and/or day must exist in selected month.', (value, ctx) => {
               try {
                  const dateString = `${new Date().getFullYear()}-${leftPadDayOrMonth(ctx.parent?.benefitPeriodStartMonth)}-${leftPadDayOrMonth(value)}`;
                  return  isDateValid(dateString);
               } catch {
                  return false;
               }
            })
      }),
   benefitPeriodStartDay: yup.number()
      .min(1, 'Benefit Period Start Day must be a valid day')
      .max(31, 'Benefit Period Start Day must be a valid day')
      .when('benefitPeriodTypeId', {
         is: CALENDAR_BENEFIT_TYPE_ID,
         then: () => yup.number()
            .test('benefitPeriodStartDayDateCheck', 'Benefit Period Start Day is required and/or must exist in selected month.', (value, ctx) => {
               try {
                  const dateString = `${new Date().getFullYear()}-${leftPadDayOrMonth(ctx.parent?.benefitPeriodStartMonth)}-${leftPadDayOrMonth(value)}`;
                  return isDateValid(dateString);
               } catch {
                  return false;
               }
            })
      })
});

export interface IBrandDefaultsYup extends yup.Asserts<typeof yupBrandDefaultsSchema> { }
const __formId = "frmBrandDefaults";
const _keysLike: string[] = [assistanceServiceBrandDefaultsBaseUrl];

const AssistanceServiceBrandDefaultsEditor: React.FC<IProps> = (props) => {
   const { closeEditor, isOpen, assistanceService, brandIdsMappedForDrugs,
      id, readonly, shouldAssistanceServiceBeDisabled, shouldBrandBeDisabled,
      shouldUseProvidedAssistanceService   } = props;

   const { httpGet, httpPost, httpDelete } = useApiContext();
   const { benefitPeriodTypeOptions } = useBenefitPeriodTypeOptions(httpGet);
   const { assistanceServices } = useFetchAssistanceServices(httpGet);
   const { brandDefaults } = useFetchBrandDefaults(httpGet);
   const { unitOfMeasureOptions } = useUnitOfMeasureOptions(httpGet);
   const { brandOptions } = useBrandOptions(httpGet);
   const { assistancePrograms } = useFetchAssistancePrograms(httpGet);

   const [isFormSaving, setIsFormSaving] = React.useState<boolean>(false);
   const { removeErrors } = useErrorContext();
   const clearErrors = () => {
      removeErrors({ keysLike: _keysLike });
   }
   
   const { cohortSummaryFromDate } = useCohortContext();

   const {
      control,
      handleSubmit,
      formState: { errors },
      reset,
      watch,
      setValue,
      trigger
   } = useForm<IBrandDefaultsYup>({
      resolver: yupResolver<yup.AnyObject>(yupBrandDefaultsSchema),
      shouldFocusError: true,
   });

   const watchBenefitType = watch('benefitPeriodTypeId');


   const itemToEdit = React.useMemo(() => {
      return brandDefaults?.find(y => y.id === id);
   }, [id, brandDefaults]);

   const filteredBrandOptions = React.useMemo(() => {
      if (brandIdsMappedForDrugs?.length > 0) {
         // IOptionItem.value here holds the brandId
         return brandOptions.filter(bo => brandIdsMappedForDrugs?.indexOf(Number(bo.value)) > -1);
      } else {
         return brandOptions;
      }

   }, [brandIdsMappedForDrugs, brandOptions]);

   const assistanceProgramAndServiceList = React.useMemo(() => {
      if (assistancePrograms?.length > 0 &&
         assistanceServices?.length > 0) {

         const tempList: IOptionItem[] = [];
         for (let pidx = 0; pidx < assistancePrograms.length; pidx++) {
            const p = assistancePrograms[pidx];
            const svcs = assistanceServices?.filter(y => y.assistanceProgramId === p.id);
            for (let sidx = 0; sidx < svcs.length; sidx++) {
               if (!tempList.some(y => y.value === svcs[sidx].id)) {
                  tempList.push({
                     key: `${p.id}_${svcs[sidx].id}`,
                     label: `${p.id} (${p.programName}) ${svcs[sidx].id} (${svcs[sidx].assistanceServiceName})`,
                     value: svcs[sidx].id
                  } as IOptionItem);
               }
            }
         }

         return tempList;
      }
      return [];
   }, [assistancePrograms, assistanceServices]);

   React.useEffect(() => {
      let resetValue: AssistanceServiceBrandDefaults;

      if (shouldUseProvidedAssistanceService) {
         resetValue = {
            ...itemToEdit,
            assistanceServiceId: assistanceService?.id,
            benefitPeriodTypeId: ENROLLMENT_BENEFIT_TYPE_ID //default value
         } as AssistanceServiceBrandDefaults;
      } else {
         resetValue = itemToEdit as AssistanceServiceBrandDefaults;
      }
      resetValue = resetValue ?? { id: id, benefitPeriodTypeId: ENROLLMENT_BENEFIT_TYPE_ID } as AssistanceServiceBrandDefaults;

      reset(resetValue);
      setIsFormSaving(false);

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [itemToEdit, id, isOpen]);



   const [form] = Form.useForm();

   const onSubmit = async (data: IBrandDefaultsYup) => {
      //console.log('--------------AssistanceServiceEditor-------------------------')
      //console.log(errors)

      //ts hoops to convert data with unknown props into assistance programs
      const item = [data as unknown as AssistanceServiceBrandDefaults];

      setIsFormSaving(true);
      await saveBrandDefaults(httpPost, item, cohortSummaryFromDate)
         .then(() => {
            handleClose();
         })
         .catch(err => console.log(`Error Saving`, err))
         .finally(() => {
            setIsFormSaving(false);
         })

   }

   const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState<boolean>(false);

   const handleDeleteClick = () => {
      setIsDeleteDialogOpen(false);
      deleteBrandDefault(httpDelete, itemToEdit.id)
         .then(handleClose)
         .catch(err => console.log(`Error Saving`, err));
   }

   const handleClose = () => {
      setIsFormSaving(false);
      setIsDeleteDialogOpen(false);
      clearErrors();
      closeEditor();
   }

   //console.log('--------------AssistanceServiceBrandDefaultsEditor-------------------------')
   //console.log(errors)


   const html = (
      <Dialog
         scrollingContent={true}
         title={'Assistance Service Brand Default Editor'}
         open={isOpen}
         size="large"
         actionButtons={
            <>
               <CancelButton onClick={() => handleClose()}
                  loading={isFormSaving}
               />
               {!readonly && <DeleteButton
                  onClick={() => setIsDeleteDialogOpen(true)}
                  disabled={!itemToEdit || !itemToEdit?.id || isDeleteDialogOpen}
                  loading={isFormSaving} />
               }
               {!readonly && <SaveButton
                  formId={__formId}
                  disabled={Object.keys(errors).length > 0}
                  loading={isFormSaving} />
               }
            </>
         }>
         <div style={dialogContentStyle}>

            {errors && Object.keys(errors).length > 0 && <>
               <TitleError
                  text='Please correct the errors below' />
               {console.log('--------------Form Errors: -------------------------')}
               {console.log(errors)}
            </>
            }

            <ApiErrorDisplay
               title='Error saving assistanceServiceBrandDefaultsState'
               keysLike={_keysLike}
            />

            <Form form={form}
               id={__formId}
               onFinish={handleSubmit(onSubmit)}>
               <DropdownField
                  control={control}
                  name='brandId'
                  label='Brand'
                  error={errors?.brandId}
                  multiple={false}
                  options={filteredBrandOptions}
                  setValue={setValue}
                  required={true}
                  search={true}
                  clearable={true}
                  disabled={readonly ? true : false || shouldBrandBeDisabled}
               />

               <DropdownField
                  control={control}
                  name='assistanceServiceId'
                  label='Assistance Service'
                  error={errors?.assistanceServiceId}
                  multiple={false}
                  options={assistanceProgramAndServiceList}
                  setValue={setValue}
                  required={true}
                  search={true}
                  clearable={true}
                  disabled={readonly ? true : false || shouldAssistanceServiceBeDisabled}
               />

               <Divider>Copay Dose 1</Divider>

               <InputField
                  control={control}
                  name='copayAmountDose1'
                  label='copayAmountDose1'
                  type='number'
                  error={errors?.copayAmountDose1}
                  required={false}
                  disabled={readonly ? true : false}
               />
               <DropdownField
                  control={control}
                  name='copayAmountDose1Units'
                  label='copayAmountDose1Units'
                  error={errors?.copayAmountDose1Units}
                  multiple={false}
                  options={unitOfMeasureOptions}
                  setValue={setValue}
                  required={false}
                  search={true}
                  clearable={true}
                  disabled={readonly ? true : false}
               />
               <InputField
                  control={control}
                  name='copayAmountDose1Notes'
                  label='copayAmountDose1Notes'
                  type='text'
                  error={errors?.copayAmountDose1Notes}
                  required={false}
                  disabled={readonly ? true : false}
               />
               <InputField
                  control={control}
                  name='copayAmountDose1Url'
                  label='copayAmountDose1Url'
                  type='text'
                  error={errors?.copayAmountDose1Url}
                  required={false}
                  disabled={readonly ? true : false}
               />
               <Divider>Copay Dose N</Divider>
               <InputField
                  control={control}
                  name='copayAmountDoseN'
                  label='copayAmountDoseN'
                  type='number'
                  error={errors?.copayAmountDoseN}
                  required={false}
                  disabled={readonly ? true : false}
               />
               <DropdownField
                  control={control}
                  name='copayAmountDoseNUnits'
                  label='copayAmountDoseNUnits'
                  error={errors?.copayAmountDoseNUnits}
                  multiple={false}
                  options={unitOfMeasureOptions}
                  setValue={setValue}
                  required={false}
                  search={true}
                  clearable={true}
                  disabled={readonly ? true : false}
               />
               <InputField
                  control={control}
                  name='copayAmountDoseNNotes'
                  label='copayAmountDoseNNotes'
                  type='text'
                  error={errors?.copayAmountDoseNNotes}
                  required={false}
                  disabled={readonly ? true : false}
               />
               <InputField
                  control={control}
                  name='copayAmountDoseNUrl'
                  label='copayAmountDoseNUrl'
                  type='text'
                  error={errors?.copayAmountDoseNUrl}
                  required={false}
                  disabled={readonly ? true : false}
               />
               <Divider>Approved Award Amount</Divider>
               <InputField
                  control={control}
                  name='approvedAwardAmount'
                  label='approvedAwardAmount'
                  type='number'
                  error={errors?.approvedAwardAmount}
                  required={false}
                  disabled={readonly ? true : false}
               />
               <DropdownField
                  control={control}
                  name='approvedAwardAmountUnits'
                  label='approvedAwardAmountUnits'
                  error={errors?.approvedAwardAmountUnits}
                  multiple={false}
                  options={unitOfMeasureOptions}
                  setValue={setValue}
                  required={false}
                  search={true}
                  clearable={true}
                  disabled={readonly ? true : false}
               />
               <InputField
                  control={control}
                  name='approvedAwardAmountNotes'
                  label='approvedAwardAmountNotes'
                  type='text'
                  error={errors?.approvedAwardAmountNotes}
                  required={false}
                  disabled={readonly ? true : false}
               />
               <InputField
                  control={control}
                  name='approvedAwardAmountUrl'
                  label='approvedAwardAmountUrl'
                  type='text'
                  error={errors?.approvedAwardAmountUrl}
                  required={false}
                  disabled={readonly ? true : false}
               />
               <Row gutter={[32, 32]} justify="space-between" align="top" >
                  <Col span={8}>
                     <DropdownField
                        control={control}
                        name='benefitPeriodTypeId'
                        label='Benefit Period Type'
                        error={errors?.benefitPeriodTypeId}
                        multiple={false}
                        options={benefitPeriodTypeOptions}
                        setValue={setValue}
                        required={true}
                        search={true}
                        clearable={true}
                        disabled={readonly ? true : false}
                        onChange={(value) => trigger('benefitPeriodTypeId')}
                     />
                  </Col>
                  <Col span={8}>
                     <DropdownField
                        control={control}
                        name='benefitPeriodStartMonth'
                        label='Benefit Period Start Month'
                        error={errors?.benefitPeriodStartMonth}
                        multiple={false}
                        options={monthOptions}
                        setValue={setValue}
                        required={watchBenefitType === CALENDAR_BENEFIT_TYPE_ID}
                        search={true}
                        clearable={true}
                        disabled={readonly || watchBenefitType == ENROLLMENT_BENEFIT_TYPE_ID ? true : false}
                        onChange={(value) => { trigger('benefitPeriodStartDay'); trigger('benefitPeriodStartMonth') }}
                     />
                  </Col>
                  <Col span={8}>
                     <DropdownField
                        control={control}
                        name='benefitPeriodStartDay'
                        label='Benefit Period Start Day'
                        error={errors?.benefitPeriodStartDay}
                        multiple={false}
                        options={dayOptions()}
                        setValue={setValue}
                        required={watchBenefitType === CALENDAR_BENEFIT_TYPE_ID}
                        search={true}
                        clearable={true}
                        disabled={readonly || watchBenefitType == ENROLLMENT_BENEFIT_TYPE_ID ? true : false}
                        onChange={(value) => { trigger('benefitPeriodStartDay'); trigger('benefitPeriodStartMonth') }}
                     />
                  </Col>
               </Row>
               <Divider>Back Date Days</Divider>
               <InputField
                  control={control}
                  name='backDateDays'
                  label='backDateDays'
                  type='number'
                  error={errors?.backDateDays}
                  required={false}
                  disabled={readonly ? true : false}
               />
               <DropdownField
                  control={control}
                  name='backDateDaysUnits'
                  label='backDateDaysUnits'
                  error={errors?.backDateDaysUnits}
                  multiple={false}
                  options={unitOfMeasureOptions}
                  setValue={setValue}
                  required={false}
                  search={true}
                  clearable={true}
                  disabled={readonly ? true : false}
               />
               <InputField
                  control={control}
                  name='backDateDaysNotes'
                  label='backDateDaysNotes'
                  type='text'
                  error={errors?.backDateDaysNotes}
                  required={false}
                  disabled={readonly ? true : false}
               />
               <InputField
                  control={control}
                  name='backDateDaysUrl'
                  label='backDateDaysUrl'
                  type='text'
                  error={errors?.backDateDaysUrl}
                  required={false}
                  disabled={readonly ? true : false}
               />
               <Divider>Timely Filing Days</Divider>
               <InputField
                  control={control}
                  name='timelyFilingDays'
                  label='timelyFilingDays'
                  type='number'
                  error={errors?.timelyFilingDays}
                  required={false}
                  disabled={readonly ? true : false}
               />
               <DropdownField
                  control={control}
                  name='timelyFilingDaysUnits'
                  label='timelyFilingDaysUnits'
                  error={errors?.timelyFilingDaysUnits}
                  multiple={false}
                  options={unitOfMeasureOptions}
                  setValue={setValue}
                  required={false}
                  search={true}
                  clearable={true}
                  disabled={readonly ? true : false}
               />
               <InputField
                  control={control}
                  name='timelyFilingDaysNotes'
                  label='timelyFilingDaysNotes'
                  type='text'
                  error={errors?.timelyFilingDaysNotes}
                  required={false}
                  disabled={readonly ? true : false}
               />
               <InputField
                  control={control}
                  name='timelyFilingDaysUrl'
                  label='timelyFilingDaysUrl'
                  type='text'
                  error={errors?.timelyFilingDaysUrl}
                  required={false}
                  disabled={readonly ? true : false}
               />
               <Divider>Legacy Fields</Divider>
               <InputField
                  control={control}
                  name='url'
                  label='url'
                  type='string'
                  error={errors?.url}
                  required={false}
                  disabled={readonly ? true : false}
               />
               <InputField
                  control={control}
                  name='isHcpcsCovered'
                  label='isHcpcsCovered'
                  type='number'
                  error={errors?.isHcpcsCovered}
                  required={false}
                  disabled={readonly ? true : false}
               />
               <InputField
                  control={control}
                  name='isChemotherapyDrugsCovered'
                  label='isChemotherapyDrugsCovered'
                  type='number'
                  error={errors?.isChemotherapyDrugsCovered}
                  required={false}
                  disabled={readonly ? true : false}
               />
               <InputField
                  control={control}
                  name='isConcurrentDrugsOnChemoDaysCovered'
                  label='isConcurrentDrugsOnChemoDaysCovered'
                  type='number'
                  error={errors?.isConcurrentDrugsOnChemoDaysCovered}
                  required={false}
                  disabled={readonly ? true : false}
               />
               <InputField
                  control={control}
                  name='isAdministrativeServicesOnChemoDaysCovered'
                  label='isAdministrativeServicesOnChemoDaysCovered'
                  type='number'
                  error={errors?.isAdministrativeServicesOnChemoDaysCovered}
                  required={false}
                  disabled={readonly ? true : false}
               />
               <InputField
                  control={control}
                  name='isOfficeVisitsCovered'
                  label='isOfficeVisitsCovered'
                  type='number'
                  error={errors?.isOfficeVisitsCovered}
                  required={false}
                  disabled={readonly ? true : false}
               />
               <InputField
                  control={control}
                  name='isRadiationTherapyCovered'
                  label='isRadiationTherapyCovered'
                  type='number'
                  error={errors?.isRadiationTherapyCovered}
                  required={false}
                  disabled={readonly ? true : false}
               />
            </Form>
         </div>

         <Dialog
            title='CONFIRM DELETE'
            open={isDeleteDialogOpen}
            style={{ maxWidth: '550px' }}
            actionButtons={
               <>
                  <CancelButton onClick={() => setIsDeleteDialogOpen(false)} />
                  <DeleteButton
                     disabled={isFormSaving}
                     onClick={() => handleDeleteClick()} />
               </>
            }
         >
            {`Are you sure you want to Delete the AssistanceServiceBrandDefaults with id: ${itemToEdit?.id}?`}
         </Dialog>

      </Dialog>
   )
   return html;
}

export default AssistanceServiceBrandDefaultsEditor;

