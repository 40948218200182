import { ColumnsType, TableProps } from 'antd/lib/table';
import * as React from 'react';
import Restricted from '../../../auth/Restricted';
import { numberComparer, stringComparer } from '../../../functions/comparer.functions';
import { useApiContext } from '../../../store/ApiContext';
import { cptBaseUrl, useFetchCpt } from '../../../store/drug/CptFetcher';
import { Cpt } from '../../../store/drug/CptModel';
import { HttpVerb, KeyWithVerb, useErrorContext } from '../../../store/ErrorContext';
import { KnownSettings } from '../../../store/SettingsModel';
import ApiErrorDisplay from '../../ApiErrorDisplay';
import HighlightSearchText from '../../HighlightSearchText';
import SearchResultsTable from '../../shared/AntComponents/Table/SearchResultsTable';
import { BasicInputField } from '../../shared/BasicInputLibrary';
import { AddButton, EditButton } from '../../shared/Buttons';
import Spinner from '../../Spinner';
import CptEditor from './CptEditor';

const _keysWithVerb: KeyWithVerb[] = [{ key: cptBaseUrl, verb: HttpVerb.GET }]

const CptIndex: React.FC = () => {
   const { httpGet } = useApiContext();
   const { removeErrors } = useErrorContext();
   const { cptList, isLoading } = useFetchCpt(httpGet);
   const [nameFilter, setNameFilter] = React.useState<string>('');
   const [selectedItem, setSelectedItem] = React.useState<Cpt>(undefined);
   const [isEditorOpen, setIsEditorOpen] = React.useState<boolean>(false);
   const [currentPage, setCurrentPage] = React.useState(1);
   const [currentPageSize, setCurrentPageSize] = React.useState(25);

   React.useEffect(() => {
      removeErrors({ keysWithVerb: _keysWithVerb });
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []) // remove errors on initial render

   const filterName = (m: Cpt): boolean => {
      return !nameFilter || m.code?.toLowerCase().indexOf(nameFilter.toLowerCase()) > -1
         || m.description?.toLowerCase().indexOf(nameFilter.toLowerCase()) > -1;
   }

   const filterGridContent = (records: Cpt[]): Cpt[] => {
      if (records && records?.length > 0) {
         return records.filter(y => filterName(y)).sort((a, b) => a.code.localeCompare(b.code, undefined, { numeric: true }));
      }
      return [];
   }

   const filteredData = React.useMemo(() => {
      if (cptList?.length > 0) {
         return filterGridContent(cptList);
      }
      // for "filterGridContent" - we actually need the two in this dependency array
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [cptList, nameFilter])

   const handleOnChange: TableProps<Cpt>['onChange'] = (pagination, filters, sorter, extra) => setCurrentPage(pagination?.current);
   const handleFilterChange = (filter: string) => setNameFilter(filter);

   const handleCloseEditorClick = () => {
      setIsEditorOpen(false);
      setSelectedItem(undefined);
   }

   if (isLoading) <div><Spinner /></div>

   const tableColumns: ColumnsType<Cpt> = [
      {
         title: 'Id',
         dataIndex: 'code',
         width: '10%',
         sortDirections: ['ascend', 'descend', 'ascend'],
         sorter: (a, b) => stringComparer(a.code, b.code),
         defaultSortOrder: 'descend',
         render: (text, record) =>
            <HighlightSearchText searchString={nameFilter} targetString={record?.code.toString()} />
      },
      {
         title: 'Description',
         dataIndex: 'description',
         sortDirections: ['ascend', 'descend', 'ascend'],
         sorter: (a, b) => stringComparer(a.description, b.description),
         width: '70%',
         render: (text, record) =>
            <HighlightSearchText searchString={nameFilter} targetString={record?.description} />
      },
      {
         title: 'Classification',
         dataIndex: 'classificationId',
         sortDirections: ['ascend', 'descend', 'ascend'],
         sorter: (a, b) => numberComparer(a.classificationId, b.classificationId),
         width: '10%',
      },
      {
         title: () =>
            <Restricted requiredRoles={[KnownSettings.ContentAdmin]}>
               <AddButton
                  title='Add New CPT'
                  buttonText='New'
                  onClick={() => {
                     setSelectedItem(undefined);
                     setIsEditorOpen(true);
                  }} />
            </Restricted>,
         dataIndex: 'actionButtons',
         width: 60,
         fixed: 'right',
         render: (text, record) => <Restricted requiredRoles={[KnownSettings.ContentAdmin]}>
            <EditButton
               buttonText=''
               onClick={() => {
                  setSelectedItem(record);
                  setIsEditorOpen(true);
               }} />
         </Restricted>
      }
   ];

   const html =
      <>
         <ApiErrorDisplay
            title={'Error'}
            keysWithVerb={_keysWithVerb}
         />

         <SearchResultsTable
            bordered={true}
            rowkey={'code'}
            onChange={handleOnChange}
            columns={tableColumns}
            data={filteredData}
            currentPage={currentPage}
            currentPageSize={currentPageSize}
            setCurrentPageSize={setCurrentPageSize}
            titleText='CPT'
            fixedHeader={true}
            size={'small'}
            additionalComponents={[
               <BasicInputField
                  label='Search String'
                  placeholder='Search by Id or Description'
                  value={nameFilter}
                  onChange={(e) => handleFilterChange(String(e))} />,
            ]} />

         {isEditorOpen &&
            <CptEditor isOpen={isEditorOpen}
               closeEditor={() => handleCloseEditorClick()}
               code={selectedItem?.code} />
         }
      </>

   return html;
}
export default CptIndex;
