import React from 'react';
import { colorWheel } from '../../../Theme/ColorWheel';
import { ActionButton, EditButton } from '../../shared/Buttons';
import Spinner from '../../Spinner';
import { ColumnsType } from 'antd/lib/table';
import CustomIcon, { CustomIconType } from '../../shared/AntComponents/CustomIcon'
import { useApiContext } from '../../../store/ApiContext';
import ApiErrorDisplay from '../../ApiErrorDisplay';
import {
   updateInternalOrder,
   reportBaseUrl,
   useWorkspacePages,
} from '../../../store/report/ReportFetcher';
import { PowerBIReport } from '../../../store/report/ReportModel';
import { useLocation } from 'react-router-dom';
import MinimalistTable from '../../shared/AntComponents/Table/MinimalistTable';
import { Space } from 'antd';
import { OrderDirection } from '../../../functions/comparer.functions';
import { groupBy } from '../../../functions/common.functions';
import PowerBIReportEditor from './PowerBIReportEditor';
import { getKey, KeyValue, KeyValueItem } from '../../../store/models';
import { getReportPageGroupSet, getReportSet } from '../../../store/report/ReportFunctions';
import { useErrorContext } from '../../../store/ErrorContext';

const _keysLike: string[] = [reportBaseUrl];

export const PowerBIReportIndex: React.FC = () => {

   const { search } = useLocation();
   const params = new URLSearchParams(search);
   const workspaceKey = params.get("ws");

   return (
      <>
         <ApiErrorDisplay
            title='Error saving/retrieving PowerBIReportManagement'
            keysLike={_keysLike} />

         <WorkspaceTable
            workspaceKey={workspaceKey}
         />
      </>
   );
}
export default PowerBIReportIndex;

type WorkspaceTableProps = {
   workspaceKey: string
};

const reportGroupKey = (reportName: string, reportPageGroup: string) =>
   `${getKey(reportName)}_${getKey(reportPageGroup)}`;

const WorkspaceTable: React.FC<WorkspaceTableProps> = (props) => {
   const { workspaceKey } = props;

   const { httpPut, httpGet } = useApiContext();
   const { removeErrors } = useErrorContext();
  //TODO: replace with workspaceTree
   const { workspacePages, isLoading } = useWorkspacePages(httpGet, workspaceKey);

   const [selectedItem, setSelectedItem] = React.useState<PowerBIReport>();
   const [isEditorOpen, setIsEditorOpen] = React.useState<boolean>(false);
   const [isGridUpdating, setIsGridUpdating] = React.useState<boolean>(false);
   //const [reportGroups, setReportGroups] = React.useState<Record<string, PowerBIReport[]>>();
   //field to keep track of when the selected workspace has completed loading
   const [loadedReportGroupWorkspaceKey, setSelectedReportGroupWorkspaceKey] = React.useState<string>();


   React.useEffect(() => {
      removeErrors({ keysLike: _keysLike });
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []) 

   const reportGroups = React.useMemo(() => {
      if (workspacePages?.length > 0 && !isLoading) {
         const _reportGroups = groupBy(workspacePages, y => reportGroupKey(y.reportName, y.reportPageGroup));
         setSelectedReportGroupWorkspaceKey(workspaceKey);
         return _reportGroups;
      }
      return undefined;
   }, [workspacePages, isLoading, workspaceKey]);

   //console.log('isLoading', isLoading, 'reportTree', reportTree, '_workspaceReports', _workspaceReports, 'reportGroups', reportGroups)

   if (isLoading || workspacePages?.length === 0 || !reportGroups || loadedReportGroupWorkspaceKey !== workspaceKey) {
      return <Spinner />
   }

   // populate the filter options
   const reportDataSet = getReportSet(workspacePages);
   const reportFilter = () => reportDataSet.map(item => ({ text: item.value, value: item.key }));

   const reportColumns: ColumnsType<KeyValue> = [
      {
         title: 'Report Name',
         dataIndex: 'value',
         width: '15%',
         key: 1,
         filterSearch: true,
         filters: reportFilter(),
         onFilter: (value, record) => (value && record.key === (value as string))
      },
      {
         title: 'Report Page Group',
         dataIndex: 'key',
         width: '85%',
         key: 2,
         render: (text, record) => {
            const reportPages = workspacePages.filter(y => getKey(y.reportName) === record.key);
            const rows = getReportPageGroupSet(reportPages, record);

            return <MinimalistTable
               rowKey='key'
               columns={reportGroupColumns}
               data={rows}
               bordered={true}
               hidePaging
            />
         }
      }
   ];

   const reportGroupColumns: ColumnsType<KeyValueItem<KeyValue>> = [
      {
         dataIndex: 'value',
         width: '15%',
         key: 21,
      },
      {
         dataIndex: 'key',
         width: '85%',
         key: 22,
         render: (text, record) => {
            const rows = workspacePages.filter(y =>
               getKey(y.reportName) === record.item.key &&
               getKey(y.reportPageGroup) === record.key)
               ;

            //console.log('record.key', record.key, 'record.item.key', record.item.key)
            return <MinimalistTable
               showHeader={true}
               rowKey='id'
               columns={pageColumns}
               data={rows}
               hidePaging
            />
         }
      }
   ];

   const pageColumns: ColumnsType<PowerBIReport> = [
      {
         title: 'Report Page Name',
         dataIndex: 'reportPageName',
         width: '15%',
         key: 31,
      },
      {
         title: 'Description',
         dataIndex: 'reportDescription',
         width: '60%',
         key: 32,
      },
      {
         title: 'Edit',
         fixed: 'right',
         width: '5%',
         key: 'id',
         render: (text, record) => {
            return <EditButton
               title='Edit'
               onClick={() => {
                  setSelectedItem(record);
                  setIsEditorOpen(true);
               }} />
         }
      },
      {
         title: 'Sort',
         fixed: 'right',
         width: '5%',
         key: 34,
         render: (text, record, index) => {

            const recordKey = reportGroupKey(record.reportName, record.reportPageGroup);
            const group = reportGroups[recordKey];            
            const isFirstOfType = index === 0;
            const isLastOfType = index === group.length - 1;
            return <Space size={0}>
               <ActionButton
                  disabled={index === 0 || isGridUpdating}  //|| isSaving
                  style={{
                     borderColor: colorWheel.mediumGrey
                  }}
                  icon={isFirstOfType ?
                     <CustomIcon type={CustomIconType.BorderOutlined} style={{ color: colorWheel.graniteGrey }} /> :
                     <CustomIcon type={CustomIconType.UpSquareOutlined} style={{ color: colorWheel.graniteGrey }} />}
                  onClick={() => reorderGridContent(record, OrderDirection.Up)}
               />
               <ActionButton
                  title={`isFirstOfType: ${isFirstOfType}, isLastOfType: ${isLastOfType}, record.sectionItemCount: ${group.length}`}
                  disabled={isLastOfType || isGridUpdating} //|| isSaving
                  style={{
                     borderColor: colorWheel.mediumGrey
                  }}
                  icon={isLastOfType ?
                     <CustomIcon type={CustomIconType.BorderOutlined} style={{ color: colorWheel.graniteGrey }} /> :
                     <CustomIcon type={CustomIconType.DownSquareOutlined} style={{ color: colorWheel.graniteGrey }} />}
                  onClick={() => reorderGridContent(record, OrderDirection.Down)}
               />
            </Space>
         }
      }];

   const reorderGridContent = async (item: PowerBIReport, direction: OrderDirection) => {
      setIsGridUpdating(true);

      const recordKey = reportGroupKey(item.reportName, item.reportPageGroup);
      const group = reportGroups[recordKey];
      const index = group.findIndex(i => i.id === item.id);
      // Disconnected copy of filtered reports to determine which changed after reorder
      let duplicateList = [...group];
      duplicateList.splice(direction === OrderDirection.Up ? index - 1 : index + 1, 0, duplicateList.splice(index, 1)[0]);
      const orderedItems = duplicateList.map(i => i.id);
      await updateInternalOrder(httpPut, orderedItems)
         .catch(err => console.error('Error updateInternalOrder', err))
         .finally(() => setIsGridUpdating(false));
   }

   const handleCloseEditorClick = () => {
      setIsEditorOpen(false);
      setSelectedItem(undefined);
   }

   return (<>
      <MinimalistTable
         rowKey='key'
         className='child-tables'
         columns={reportColumns}
         data={reportDataSet}
         showHeader={true}
         size='small'
         bordered={true}
         hidePaging
         />

      {isEditorOpen && 
         <PowerBIReportEditor
            isOpen={isEditorOpen}
            closeEditor={() => handleCloseEditorClick()}
            id={selectedItem?.id}
            />
      }
   </>)
}
