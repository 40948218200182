import { useEffect, useState } from "react";
import useSWR, { mutate } from "swr";
import { HttpError, TDelete, TGet, TPost, TPut } from "../../functions/httpClient";
import { IStatus } from "../models";
import { PracticeFederation } from './PracticeFederationModel';

export const practiceFederationBaseUrl = 'api/auth/practiceFederation';
const practiceFederationSaveUrl = `${practiceFederationBaseUrl}`;
const practiceFederationDeleteUrl = (id: number) => `${practiceFederationBaseUrl}/${id}`;

const swrOptions = {
   refreshInterval: 0,
   revalidateOnFocus: false,
   dedupingInterval: 60000
};

export const useFetchPracticeFederation = (httpGet: TGet) => {
   const { data, isValidating, error } = useSWR<PracticeFederation[], HttpError>(
      practiceFederationBaseUrl,
      httpGet,
      { ...swrOptions }
   );

   return {
      practiceFederations: data,
      isLoading: isValidating,
      error: error
   };
}

export function usePracticeFederationByPracticeId(httpGet: TGet, practiceId: number): IStatus & { practiceFederations: PracticeFederation[] } {
   const { practiceFederations, isLoading, error } = useFetchPracticeFederation(httpGet);
   const [viewModel, setViewModel] = useState<PracticeFederation[]>([]);

   useEffect(() => {
      const buildViewModel = () => {
         if (practiceFederations?.length > 0) {
            const data = practiceFederations.filter(y => y.practiceId === practiceId);            
            setViewModel(data);
         }
      }
      buildViewModel();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [practiceFederations]);


   return {
      practiceFederations: viewModel,
      isLoading: isLoading,
      error: error?.message,
   };
}

export const savePracticeFederation = async (httpPost: TPost, practiceFederation: PracticeFederation) => {
   const result = await httpPost(practiceFederationSaveUrl, practiceFederation);
   mutate(practiceFederationBaseUrl);
   return result;
}

export const deletePracticeFederation = async (httpDelete: TDelete, id: number) => {
   const result = await httpDelete(practiceFederationDeleteUrl(id));
   mutate(practiceFederationBaseUrl);
   return result;
}