import * as React from 'react';
import { Route, Routes, useLocation } from "react-router";
import AdminPortalErrorBoundary from './AdminPortalErrorBoundary';
import { useInsightsContext } from './InsightsContext';
import ProtectedRoute from './auth/ProtectedRoute';
import SignOut from './auth/SignOut';
import { useUserContext } from './auth/authContext';
import AntStyling from './components/AntStyling';
import ErrorTest from './components/ErrorTest';
import Help from './components/Help';
import Home from './components/Home';
import Settings from './components/Settings';
import UserProfile from './components/UserProfile';
import Scorecard from './components/adparo/scorecard/Scorecard';
import ScorecardIndex from './components/adparo/scorecard/ScorecardIndex';
import CohortIndex from './components/cohort/CohortIndex';
import ContentTool from './components/content-tool/ContentTool';
import FoundationIndex from './components/foundation/FoundationIndex';
import FundStatusAlertIndex from './components/fsa/FundStatusAlertIndex';
import PracticeUserIndex from './components/practice/PracticeUserIndex';
import PracticeView from './components/practice/PracticeView';
import { ReportIndex } from './components/report/ReportIndex';
import UserIndex from './components/user/UserIndex';
import UserMainIndex from './components/user/UserMainIndex';
import { CrxIndex } from './external/CrxIndex';
import { getAllowedRoles } from './functions/auth.functions';
import SiteLayout from './layouts/SiteLayout';
import { useFlyoutMenu } from './layouts/menu/FlyoutMenuContext';
import { useApiContext } from './store/ApiContext';
import { KnownSettings } from './store/SettingsModel';
import { MainMenuItems } from './store/menu/MainMenu';
import { SubMenus } from './store/menu/SubMenu';
import { useReportList } from './store/report/ReportFetcher';
import RingCallQueueIndex from "./components/practice/RingCallQueueIndex";
import PageNotFound from './PageNotFound';

export const Dashboard: React.FC = () => {
   const { httpGet } = useApiContext();
   const { reactPlugin } = useInsightsContext();
   const { userRoles } = useUserContext();
   const { loadReportFlyoutMenu } = useFlyoutMenu();

   const location = useLocation();

   const { powerBIReports, isLoading: isLoadingMenuItems } = useReportList(httpGet);

   React.useEffect(() => {
      //check if we need to load the reports menu.  We are dispatching this action now to preload reports
      //before user clicks the link.  
      //if (accessToReports && !isLoadingMenuItems && reportMenuItems?.length > 0) {
      if (!isLoadingMenuItems && powerBIReports?.length > 0) {
         loadReportFlyoutMenu(powerBIReports);
      }
   },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [powerBIReports, isLoadingMenuItems]);

   //inspect target path to see if the user has access
   let allowedRoles: string[] = [];//['unsupported'];
   if (location && location.pathname) {
      allowedRoles = getAllowedRoles(MainMenuItems, SubMenus, location.pathname);
   }
   const defaultProtectedRouteProps = {
      userRoles: userRoles,
      allowedRoles: allowedRoles
   }

   const html = (
      <SiteLayout>
         <AdminPortalErrorBoundary appInsights={reactPlugin}>
            <Routes>
               <Route path='/ErrorTest'
                  element={<ErrorTest />}>
               </Route>
               <Route path='/AntStyling'
                  element={<AntStyling />}>
               </Route>
               <Route path='/content-tool/:tableName'>
                  <Route index element={<ProtectedRoute
                     {...defaultProtectedRouteProps}
                     outlet={<ContentTool />}
                  />} />
                  <Route path=':cmd'
                     element={<ProtectedRoute
                        {...defaultProtectedRouteProps}
                        outlet={<ContentTool />}
                     />}>
                  </Route>
               </Route>
               <Route path='/cohort/*'
                  element={
                     <ProtectedRoute
                        {...defaultProtectedRouteProps}
                        outlet={<CohortIndex />}
                     />
                  }>
               </Route>
               <Route path='/report'>
                  <Route index element={<ProtectedRoute
                     {...defaultProtectedRouteProps}
                     outlet={<ReportIndex />}
                  />} />
                  {/* <Route path=':view/:workspaceKey/:reportKey/:pageId'
                  element={
                     <ProtectedRoute
                        {...defaultProtectedRouteProps}
                        outlet={<ReportIndex />}
                     />
                  } />*/}
                  <Route path='notfound'
                     element={<PageNotFound title={'Report not found'} />}
                  />
                  <Route path=':view/:id?'
                     element={
                        <ProtectedRoute
                           {...defaultProtectedRouteProps}
                           outlet={<ReportIndex />}
                        />
                     } />
               </Route>
               <Route path='/practice/:practiceId'>
                  <Route index element={
                     <ProtectedRoute {...defaultProtectedRouteProps} outlet={<PracticeView />} />
                  } />

                  <Route path='user' element={
                     <ProtectedRoute {...defaultProtectedRouteProps} allowedRoles={[KnownSettings.UserAdmin]} outlet={<PracticeUserIndex />} />
                  } />

                  <Route path='user/:id' element={
                     <ProtectedRoute {...defaultProtectedRouteProps} allowedRoles={[KnownSettings.UserAdmin]} outlet={<PracticeView />} />
                  } />
               </Route>
               <Route path='/program/foundation/*'
                  element={
                     <ProtectedRoute
                        {...defaultProtectedRouteProps}
                        outlet={<FoundationIndex />}
                     />
                  }>
               </Route>
               <Route path='/Crx/:cmd?'>
                  <Route index element={<ProtectedRoute
                     {...defaultProtectedRouteProps}
                     outlet={<CrxIndex />}
                  />} />
                  {/*  Optional Params [:cmd?] are once again supported:)
                     <Route path=':cmd'
                     element={
                        <ProtectedRoute
                           {...defaultProtectedRouteProps}
                           outlet={<CrxIndex />}
                        />
                     } />*/}
               </Route>
               <Route path='/fsa/*'
                  element={
                     <ProtectedRoute
                        {...defaultProtectedRouteProps}
                        outlet={<FundStatusAlertIndex />}
                     />
                  }>
               </Route>

               <Route path='/user'>
                  <Route index element={<ProtectedRoute allowedRoles={[KnownSettings.UserAdmin]}
                     {...defaultProtectedRouteProps}
                     outlet={<UserIndex />}
                  />} />

                  <Route path='newuser' element={
                     <ProtectedRoute {...defaultProtectedRouteProps} allowedRoles={[KnownSettings.UserAdmin]} outlet={<UserMainIndex />} />
                  } />

                  <Route path='userdetail/' element={
                     <ProtectedRoute {...defaultProtectedRouteProps} allowedRoles={[KnownSettings.UserAdmin]} outlet={<UserMainIndex />} />
                  } />

                  <Route path='userdetail/:id' element={
                     <ProtectedRoute {...defaultProtectedRouteProps} allowedRoles={[KnownSettings.UserAdmin]} outlet={<UserMainIndex />} />
                  } />
               </Route>

               <Route path='/adparo'>
                  <Route index element={<ProtectedRoute
                     {...defaultProtectedRouteProps}
                     outlet={<ScorecardIndex />}
                  />} />

                  <Route path='scorecard' element={
                     <ProtectedRoute {...defaultProtectedRouteProps} outlet={<Scorecard />} />
                  } />

                  <Route path='scorecard/type/:id' element={
                     <ProtectedRoute {...defaultProtectedRouteProps} outlet={<Scorecard />} />
                  } />

                  {/*If we ever decide to let you see Scorecards after the fact...
               <Route path='scorecard/:id' element={
                  <ProtectedRoute {...defaultProtectedRouteProps} outlet={<Scorecard />} />
               } />
               */}
               </Route>

               <Route path='/UserProfile'
                  element={
                     <UserProfile />
                  }>
               </Route>
               <Route path='/Help'
                  element={
                     <Help />
                  }>
               </Route>
               <Route path='/SignOut'
                  element={
                     <SignOut />
                  }>
               </Route>
               <Route path='/SettingsModel' element={
                  <Settings />
               }>
               </Route>
               <Route path="/"
                  element={<Home />}>
               </Route>
               <Route path='*'
                  element={
                     <PageNotFound title={'Page not found'} />
                  }>
               </Route>
            </Routes>
         </AdminPortalErrorBoundary>
      </SiteLayout>
   );
   return html;
}
export default Dashboard;