export const PATIENT_SSN_ENABLED_NAME = 'PatientSSNEnabled';
export const PATIENT_SSN_ENABLED_VALUE_TRUE = 'True';
export const PATIENT_SSN_ENABLED_VALUE_FALSE = 'False';
export const ADPARO_DISCLAIMER_NAME = 'Adparo.Disclaimer';

export interface PracticeSettings {
   id: number;
   practiceId?: number;
   userId?: number;
   name: string;
   value?: string;
   createdDate?: Date;
   updatedDate: Date;
}

export interface PracticeSettingsViewModel extends PracticeSettings {
   valueAsBoolean?: boolean;
}