import * as React from 'react';
import { getItemStatus } from '../../store/cohort/CohortItemStatus';

interface Props {
   itemStatusArray: number[];
}

const ItemStatus_1_New: string = '&nbsp;';
const ItemStatus_3_Recommended_Change: string = '&#x26A0;&#xFE0F;'; //⚠️  '&#9940;' 
const ItemStatus_2_Audited_NoChange: string = '&#x2714;&#xFE0F;'; //✔️  '&#10004;' //✔ '&#9989;' // ✅
const ItemStatus_4_Accepted_Change: string = '&#9989;' // ✅ '&#128312;';
const ItemStatus_5_Rejected_Change: string = '&#9940;';  //⛔
const ItemStatus_6_Locked: string = '&#128274;';

const ItemStatusIndicator: React.FC<Props> = (props) => {

   let character = '&nbsp;';
   let statusId: number = Math.max(...props.itemStatusArray);

   switch (statusId) {
      case 0:
      case 1:
         character = ItemStatus_1_New;
         break;
      case 2:
         character = ItemStatus_2_Audited_NoChange;
         break;
      case 3:
         character = ItemStatus_3_Recommended_Change;
         break;
      case 4:
         character = ItemStatus_4_Accepted_Change;
         break;
      case 5:
         character = ItemStatus_5_Rejected_Change;
         break;
      case 6:
         character = ItemStatus_6_Locked;
         break;
      //default:
      //   character = ItemStatus_Locked;
   }

   const html = (
      <div className="flex-align-bottom icon-view" title={`${getItemStatus(statusId)}`}>
         <span dangerouslySetInnerHTML={{ __html: character }} />
      </div>
   )
   return html;
}

export default ItemStatusIndicator;
