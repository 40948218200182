import { useMemo } from 'react';
import useSWR from 'swr';
import { HttpError, TGet } from '../../functions/httpClient';
import { InsightlyContact } from './InsightlyContactModel';

const ctrlBaseUrl = `api/InsightlyContact`;

const swrOptions = {
   refreshInterval: 0,
   revalidateOnFocus: false,
   dedupingInterval: 60000
};

export const useInsightlyContactList = (httpGet: TGet) => {
   const { data, isValidating, error } = useSWR<InsightlyContact[], HttpError>(
      [ctrlBaseUrl],
      httpGet,
      { ...swrOptions }
   );

   return {
      insightlyContacts: data,
      isLoading: !error && !data && isValidating,
      error: error
   };
}

export const useInsightlyFSAContacts = (httpGet: TGet) => {
   const { insightlyContacts, isLoading } = useInsightlyContactList(httpGet);

   const fsaContacts = useMemo(() => {
      if (insightlyContacts?.length > 0) {
         const temp = insightlyContacts?.filter(y =>
            !y.inactiveContact &&
            y.fundStatusCom?.toLocaleLowerCase()?.indexOf('yes') > -1
         );

         return temp;
      }
   }, [insightlyContacts]);

   return {
      insightlyFSAContacts: fsaContacts,
      isLoading: isLoading ? true : false
   }
}