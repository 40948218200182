import { Col, Row, Space, Tabs } from 'antd';
import { Tab } from 'rc-tabs/lib/interface'; // This is the type that the Antd Tabs expect for the tabItems
import * as React from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import ContentCard from '../../layouts/ContentCard';
import { useApiContext } from '../../store/ApiContext';
import { usePracticeViewModel } from '../../store/practice/PracticeFetcher';
import {PracticeViewModel } from '../../store/practice/PracticeModel';
import { AddButton, EditButton } from '../shared/Buttons';
import PracticeEditor from './PracticeEditor';
import PracticeUserProfilesEmbeddedList from './PracticeUserProfilesEmbeddedList';
import PracticeSubscriptionEmbeddedList from './PracticeSubscriptionEmbeddedList';
import PracticeSettingsEmbeddedList from './PracticeSettingsEmbeddedList';
import PracticeFederationEmbeddedList from './PracticeFederationEmbeddedList';
import { useUserContext } from '../../auth/authContext';
import { isInRole } from '../../functions/auth.functions';
import { KnownSettings } from '../../store/SettingsModel';
import PracticeUserMigrationEmbeddedList from './PracticeUserMigrationEmbeddedList';
import PracticeLogo from './PracticeLogo';

export const PracticeView: React.FC = () => {
   const { httpGet } = useApiContext();
   const { practiceViewModels } = usePracticeViewModel(httpGet);
   const location = useLocation(); //TODO: Create a real type for this for the "backTo" functionality 
   const { practiceId } = useParams();

   const navigate = useNavigate();

   const { userRoles } = useUserContext();
   const isContentAdmin = isInRole(userRoles, [KnownSettings.ContentAdmin]);
   const isUserAdmin = isInRole(userRoles, [KnownSettings.UserAdmin]);

   const [practice, setPractice] = React.useState<PracticeViewModel>(undefined);
   const [isPracticeEditorOpen, setIsPracticeEditorOpen] = React.useState<boolean>(false);

   React.useEffect(() => {
      if (!practiceViewModels) return;

      setPractice(practiceViewModels.find(f => f.id === Number(practiceId)));
   }, [practiceId, practiceViewModels]);


   const onPracticeEditorClose = () => {
      setIsPracticeEditorOpen(false);
   }

   if (!practice) return

   const renderTabs = (): Tab[] => {
      const scrollYValue = 'calc(100vh - 510px)';
      const tabItems: Tab[] = [
         {
            label: 'User Profiles',
            key: 'User Profiles',
            children:
               <PracticeUserProfilesEmbeddedList
                  practiceId={practice.id} />
         },
         {
            label: 'Subscriptions',
            key: 'Subscriptions',
            children:
               <PracticeSubscriptionEmbeddedList
                  practiceId={practice.id}
                  scrollYValue={scrollYValue} />
         },
         {
            label: 'Settings',
            key: 'Settings',
            children:
               <PracticeSettingsEmbeddedList
                  practiceId={practice.id}
                  scrollYValue={scrollYValue} />
         },
         {
            label: 'Domains',
            key: 'Domains',
            children:
               <PracticeFederationEmbeddedList
                  practiceId={practice.id}
                  scrollYValue={scrollYValue} />
         }
      ];

      if (isUserAdmin) {
         tabItems.push({
            label: 'User Migration',
            key: 'User Migration',
            children:
               <PracticeUserMigrationEmbeddedList
                  practiceId={practice.id}
               /> 
         }) 
      }

      return tabItems;
   }

   const html = (
      <>
         <ContentCard title={`${practice?.name} (${practice?.id})`}>
            {practice &&
               <>
               <Row align="top" style={{ padding: 10, paddingBottom: 0 }}>
                  <Col span={18}>
                     <Row>
                     {!location?.state?.backTo && 
                           <Col>
                              <Link
                                 to={{ pathname: `/content-tool/PRACTICE` }}
                                 title='Return to All Practices'>
                                 &lt; Return to All Practices
                              </Link>
                           </Col>
                        }
                     {location?.state?.backTo && 
                        <Col>
                           <Link
                              to={{ pathname: `/user` }}
                              title='Return to Manage Users'>
                              &lt; Return to Manage Users
                           </Link>
                        </Col>
                     }
                     </Row>
                  </Col>
                  <Col span={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                     <EditButton
                        title='Edit'
                        buttonText='Edit Practice Details'
                        onClick={() => setIsPracticeEditorOpen(true)}
                        disabled={!isContentAdmin}
                     />
                  </Col>
               </Row>

               <Row align="top" style={{ padding: 10, paddingBottom: 0 }}>
                  <Col flex="auto">
                     <table style={{ width: '100%' }}>
                        <tbody>
                           <tr>
                              <td style={{ width: '310px' }} valign='top' align='center'>
                                 <PracticeLogo practiceId={practice.id} />
                              </td>
                              <td>
                                  <div>
                                    <div className='label'><label>Practice Name</label></div>
                                    <div><span><h2>{practice.name}</h2></span></div>
                                 </div>
                                 <div style={{ paddingTop: 10 }}>
                                    <div className='label'><label>Azure Tenant Id</label></div>
                                    <div><span><b>{practice.externalTenantId}</b></span></div>
                                 </div>
                                 <div style={{ paddingTop: 10 }}>
                                    <div className='label'><label>Account Manager</label></div>
                                    <div><span><b>{practice.insightlyOrganization?.accountManager}</b></span></div>
                                 </div>
                              </td>
                              <td style={{ width: '200px' }} align='right' valign='bottom'>
                                 <AddButton
                                    title='Add'
                                    buttonText='Add New User'
                                    onClick={() => navigate(`/practice/${practiceId}/user`)}
                                    disabled={!isUserAdmin}
                                 />
                              </td>
                           </tr>
                        </tbody>
                     </table>

                  </Col>
               </Row>

               <Row gutter={[16, 16]} align="middle" style={{ padding: 10 }}>
                  <Col>
                     <Tabs defaultActiveKey='User Profiles' items={renderTabs()} />
                  </Col>
               </Row>
               </>
            }
         </ ContentCard>

         {isPracticeEditorOpen &&
            <PracticeEditor
               isOpen={isPracticeEditorOpen}
               closeEditor={onPracticeEditorClose}
               isReadOnly={false} // Per AH-5142, the edit button is disabled if you don't have permissions.  Should that change, this will need updated
               id={practice?.id}
               key={practice?.id}
            />
         }
      </>
   );

   return html;
}

export default PracticeView;