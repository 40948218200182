import * as React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useApiContext } from '../../store/ApiContext';
import { getKey } from '../../store/models';
import { useFavoriteReports } from '../../store/report/FavoriteReportFetcher';
import {
   useReportList,
   useWorkspaceTree
} from '../../store/report/ReportFetcher';
import {
   ReportParam,
   WorkspaceParam,
   buildReportUrl,
   View_Browse_Certified,
   View_Browse_Uncertified
} from '../../store/report/ReportFunctions';
import { PowerBIReport, PowerBIReportVM } from '../../store/report/ReportModel';
import { groupBy } from '../../functions/common.functions';
import { useRecentlyViewedReports } from '../../layouts/menu/RecentlyViewedReportsContext';
import { validateBrowseRoute } from "./ReportRouteHelper";

interface IReportContextProps {
   workspaceKey: string;
   reportKey: string;
   view: string; //browse, search, favorite

   currentReport: PowerBIReportVM;
   powerBIReportId?: number;
   setReportRendered: (isReportRendered: boolean) => void;
   reportRendered: boolean;

   // pages based on current view
   viewReports: PowerBIReportVM[];

   //all pages
   powerBIReports: PowerBIReport[];
   workspaceTree: Record<string, PowerBIReport[]>;
   headingTree: Record<string, PowerBIReportVM[]>;
   headingReportPageGroupTree: Record<string, PowerBIReportVM[]>;
   //all pages for specific workspace
   workspacePages: PowerBIReport[];
   //all pages for specific report
   reportPages: PowerBIReport[];

   favoritedReportIds: number[];
   menuIsCollapsed: boolean;
   setMenuIsCollapsed: (isCollapsed: boolean) => void;

   areReportsLoading: boolean;
}

export const ReportContext = React.createContext<IReportContextProps>({} as IReportContextProps);
export const useReports = () => React.useContext(ReportContext);

interface IReportContextProviderProps {
   children: React.ReactNode;
}

export const ReportContextProvider: React.FC<IReportContextProviderProps> = (props) => {
   const { httpGet } = useApiContext();
   const { addReport: addRecentlyViewedReport } = useRecentlyViewedReports();

   const [menuIsCollapsed, setMenuIsCollapsed] = React.useState<boolean>(false);

   const [reportRendered, setReportRendered] = React.useState<boolean>(false);
   const [lastReport, setLastReport] = React.useState<PowerBIReport>();

   const { view, id: _powerBIReportIdString } = useParams();

   const powerBIReportId: number = Number(_powerBIReportIdString);
   const { search } = useLocation();

   const params = new URLSearchParams(search);
   const workspaceKey = params.get(WorkspaceParam);
   const reportKey = params.get(ReportParam);

   const { powerBIReports, isLoading: isLoading_reports } = useReportList(httpGet);


   const { workspaceTree, isLoading: isLoading_workspaces } = useWorkspaceTree(httpGet);

   const { favoritedReportIds, isLoading: isLoading_favorites } = useFavoriteReports(httpGet);
   const navigate = useNavigate();

   const {
      headingTree,
      headingReportPageGroupTree,
      certifiedReports,
      uncertifiedReports
   } = React.useMemo(() => {

      if (powerBIReports) {
         const headingTree = powerBIReports?.length > 0 ?
            groupBy(powerBIReports, y => y.viewHeadingKey) :
            undefined;

         const certifiedReports = powerBIReports.filter(y => y.isCertified);
         const uncertifiedReports = powerBIReports.filter(y => !y.isCertified);

         const headingReportPageGroupTree = powerBIReports.length > 0 ?
            groupBy(powerBIReports, y => y.viewHeadingReportPageGroupKey) :
            undefined;

         return { headingTree, headingReportPageGroupTree, certifiedReports, uncertifiedReports };
      }
      return {
         headingTree: undefined,
         headingReportPageGroupTree: undefined,
         certifiedReports: undefined,
         uncertifiedReports: undefined
      };

   }, [powerBIReports]);


   const viewReports = React.useMemo(() => {

      switch (view) {
         case View_Browse_Certified:
            return certifiedReports

         case View_Browse_Uncertified:
            return uncertifiedReports

         default:
            return powerBIReports;
      }
   }, [view, powerBIReports])


   const {
      workspacePages,
      reportPages
   } = React.useMemo(() => {
      let _ws: PowerBIReport[] = [];
      if (workspaceKey && powerBIReports?.length > 0) {
         _ws = powerBIReports.filter(y => getKey(y.workspaceName) === workspaceKey);
      }

      let _r: PowerBIReport[] = [];
      if (workspaceKey && reportKey && _ws) {
         _r = _ws.filter(y => getKey(y.reportName) === reportKey);
      }

      return { workspacePages: _ws, reportPages: _r }
   },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [workspaceKey, reportKey, powerBIReports]);


   // useEffect to browse to first report
   React.useEffect(() => {
      if (workspaceKey && reportKey && powerBIReports?.length > 0 && !powerBIReportId) {
         const firstReport = workspaceTree[workspaceKey]?.find(y => getKey(y.reportName) === reportKey);
         const url = buildReportUrl(view, workspaceKey, reportKey, firstReport.id);
         navigate(url);
      }
   }, [workspaceKey, reportKey, powerBIReportId, powerBIReports])

   const {
      currentReport
   } = React.useMemo(() => {
      let currentReport: PowerBIReportVM;
      if (powerBIReports && powerBIReportId) {
         const found = powerBIReports.find(y => y.id === powerBIReportId);

         if (!lastReport ||
            lastReport.workspaceId !== found?.workspaceId && lastReport.reportId !== found?.reportId) {
            setReportRendered(false);
            setLastReport(found);
         }

         if (found) {
            currentReport = found;
            // update url when certified/uncertified doesn't match with current report to avoid "broken" links
            validateBrowseRoute(currentReport, window.location.pathname);
         }

         // if the ReportId from the URL isn't a report then send them to the custom report/notfound route to provide feedback
         if (!found && powerBIReportId) {
            window.location.assign(`${window.location.origin}/report/notfound`);
         }
      }
      return { currentReport };
   },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [powerBIReportId, powerBIReports]);

   /* Originally had addRecentlyViewedReport in the above useMemo but was getting React errors for attempting to update
      a component while another component was rendering.  <- That's a side-effect I suppose, so: */
   React.useEffect(() => {
      currentReport && addRecentlyViewedReport(currentReport);
   }, [currentReport])

   return (
      <ReportContext.Provider
         value={{
            workspaceKey,
            reportKey,
            view,

            viewReports,

            currentReport,
            powerBIReportId,
            setReportRendered,
            reportRendered,

            powerBIReports,
            workspaceTree,
            headingTree,
            headingReportPageGroupTree,
            workspacePages,
            reportPages,

            favoritedReportIds,
            menuIsCollapsed,
            setMenuIsCollapsed,

            areReportsLoading: isLoading_reports || isLoading_workspaces
         }}
      >
         {props.children}
      </ReportContext.Provider>
   );
};
