import * as React from 'react';
import { Link } from 'react-router-dom';
import * as MainMenuStore from '../../store/menu/MainMenu';
import CustomIcon from '../../components/shared/AntComponents/CustomIcon';
import colorWheel from "../../Theme/ColorWheel";

interface IProps {
   isHidden: boolean;
   mainMenuItem: MainMenuStore.IMainMenuItem
   handleClick: (selectedId: string) => void;
}

const MainMenuItem: React.FC<IProps> = (props) => {
   const {isHidden, mainMenuItem, handleClick} = props;
   return (
      <div className="menu-item">
         {mainMenuItem.route &&
            <Link
               to={mainMenuItem.route ?? ''}
               onClick={() => handleClick(mainMenuItem.id)}
               title={mainMenuItem.label}>
               <div className="menu-item-inner">
                  {mainMenuItem.labelIconName &&
                     <span title={mainMenuItem.label}>
                        {mainMenuItem.isSelected &&
                           <CustomIcon style={{fontSize: 16, color: colorWheel.graniteGrey}}
                                       type={mainMenuItem.labelIconName} />}
                        {!mainMenuItem.isSelected &&
                           <CustomIcon style={{fontSize: 16, color: colorWheel.neutral3}}
                                       type={mainMenuItem.labelIconName} />}
                     </span>
                  }
                  {isHidden === false &&
                     <span className="menu-title">
                  {mainMenuItem.label}
               </span>
                  }
               </div>
            </Link>
         }
         {!mainMenuItem.route &&
            <div className="menu-item-inner" onClick={() => handleClick(mainMenuItem.id)}>
             <span title={mainMenuItem.label}>
                {mainMenuItem.isSelected &&
                   <CustomIcon style={{fontSize: 16, color: colorWheel.graniteGrey}}
                               type={mainMenuItem.labelIconName}/>}
                {!mainMenuItem.isSelected &&
                   <CustomIcon style={{fontSize: 16, color: colorWheel.neutral3}}
                               type={mainMenuItem.labelIconName}/>}
             </span>
               {isHidden === false &&
                  <span className="menu-title">
                  {mainMenuItem.label}
               </span>
               }
            </div>
         }
      </div>
   );
}

export default MainMenuItem;
