import * as React from 'react';
import { useUserContext } from '../auth/authContext';

//Created this component and registered the route '/SignOut' to allow react-router-dom
// Prompt to properly cancel when users click the signout link
const SignOut = () => {
   const { signOut } = useUserContext();
   React.useEffect(() => {

      const effectFunc = async () => {
         await signOut();
         window.location.href = '/';
      }      

      effectFunc();
   },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []);

   return <></>;
}

export default SignOut;