import useSWR, { mutate } from 'swr';
import { useMemo, useEffect, useState } from 'react';
import { HttpError, TPost, TPut } from '../../functions/httpClient';
import { FundStatusFieldChange } from './FundStatusAlertModel';
import { getFormattedDateForApi } from '../../functions/format.functions';

export const fundStatusFieldChangeBaseUrl = `/api/fsa/FundStatusFieldChange`;
export const fundStatusFieldFindUrl = `${fundStatusFieldChangeBaseUrl}/Find`;
export const fundStatusFieldDismissUrl = `${fundStatusFieldChangeBaseUrl}/Dismiss`;

const swrOptions = {
   refreshInterval: 0,
   revalidateOnFocus: false,
   dedupingInterval: 60000     //default 2000
};

export const useFundStatusFieldChangeList = (httpPost: TPost, fromDate: Date) => {
   const [lastChecked, setLastChecked] = useState<Date>(new Date());

   const params = useMemo(() => {
      return { fromDate: getFormattedDateForApi(fromDate) }
   }, [fromDate]);

   const fetcher = () => httpPost<FundStatusFieldChange[]>(fundStatusFieldFindUrl, params);

   const { data, isValidating, isLoading, error } = useSWR<FundStatusFieldChange[], HttpError>(
      [fundStatusFieldFindUrl, params],
      fetcher,
      {
         ...swrOptions,
         refreshInterval: 60000, //refresh once per minute
         dedupingInterval: 0     //disable deduping
      }
   );

   useEffect(() => {
      setLastChecked(new Date());
   }, [isValidating]);

   return {
      fundStatusFieldChangeList: data,
      isLoading: isLoading,
      error: error as HttpError,
      lastChecked: lastChecked
   };
}

export const dismissFundStatusFieldChanges = async (httpPut: TPut, fundingStatusFieldChangeLogIds: number[], fromDate: Date, reason: string) => {
  const findParams = { fromDate: getFormattedDateForApi(fromDate) };

   await httpPut(fundStatusFieldDismissUrl, { 'fundingStatusFieldChangeLogIds': fundingStatusFieldChangeLogIds, 'reason': reason });
   mutate([fundStatusFieldFindUrl, findParams]);
}