// NOTE: These are the exact type names from these .net classes and they MUST match 
export enum NotificationType {
   FSAProcessingMessage = 'FSAProcessingMessage',
   SignalRTestConnectivityMessage = 'SignalRTestConnectivityMessage'
}

// These enum values also exist in INotificationModel.cs
export enum NotificationStatusType {
   Starting,
   Running,
   Success,
   Error
}

export interface INotificationModel {
   key: string;
   title: string;
   timestamp: Date;
   message?: string;
   notificationType: string;
   status: NotificationStatusType;
}

export interface IAcknowledgeable {
   acknowledged: boolean;
}

export interface IAcknowledgeableNotification extends INotificationModel, IAcknowledgeable { }


export interface IFSAProcessingMessage extends IAcknowledgeableNotification {
   uId: string;
}

export interface SignalRTestConnectivityMessage extends IAcknowledgeableNotification { }
