import * as React from 'react';
import { IFormError } from '../../../functions/form.contracts';
import FormRow from './FormRow';
import { CohortItem } from '../../../store/cohort/CohortItemModel';

interface Props {
   cohortItems: CohortItem[];
   onInputChange: (cohortFieldId: number, val: any) => void;
   errors: IFormError[];
}

//component to manage creating the correct component based on the supplied question.FieldType.
const CohortItemList: React.FC<Props> = (props) => {
   const {cohortItems, errors, onInputChange} = props;
   return (
      <>
         {
            cohortItems.map((item: CohortItem, index: number) => {
               return (
                  <div key={item.cohortFieldId}>
                     <FormRow
                        dataName={String(item.cohortFieldId)}
                        //NOTE: changing number to string to try to use same
                        // DataName pattern used on main Digital Enrollment site.
                        cohortItem={item}
                        onInputChange={onInputChange}
                        errors={errors}
                        inputIndex={index}
                     />
                  </div>
               )
            })
         }
      </>
   );
}

export default CohortItemList;
