import { yupResolver } from '@hookform/resolvers/yup';
import { Breadcrumb, Col, Divider, Form, Row, Space } from 'antd';
import * as React from 'react';
import { FieldError, useForm } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { IOptionItem } from '../../functions/option.functions';
import { useApiContext } from '../../store/ApiContext';
import * as Models from '../../store/models';
import { useFetchDiseaseTypes } from '../../store/program/DiseaseTypeFetcher';
import {
    baseFoundationDiseaseTypeUrl,
   deleteFoundationDiseaseType,
   getFoundationDiseaseTypesUrl,
   saveNewFoundationDiseaseType, useFetchFoundationDiseaseTypes,
   useFoundationDiseaseTypesById, useRequestFoundationDiseaseTypeToSpecialty
} from '../../store/program/FoundationDiseaseType';
import { FoundationDiseaseTypeToSpecialty } from '../../store/program/FoundationDiseaseTypeModel';
import { foundationBaseUrl, useFoundationById } from '../../store/program/FoundationFetcher';
import { Foundation } from '../../store/program/FoundationModel';
import { findStatusEntry, useFoundationFundingStatusListById } from '../../store/program/FundingStatusFetcher';
import { FundingStatus } from '../../store/program/FundingStatusModel';
import ApiErrorDisplay from '../ApiErrorDisplay';
import Dialog from '../Dialog';
import Spinner from '../Spinner';
import SpecialtySelector from '../content-tool/specialty/SpecialtySelector';
import { TitleError } from '../shared/AntComponents/Typography/Title';
import { BasicFieldWrapper } from '../shared/BasicInputLibrary';
import { CancelButton, DeleteButton, SaveButton } from '../shared/Buttons';
import { ErrorDisplay } from '../shared/ErrorDisplay';
import { FieldWrapper, InputField } from '../shared/InputLibrary';
import FundingStatusCtrl from './FundingStatus';
import { FormMultiSelectInput } from '../shared/MultiSelect/FormMultiSelectInput';
import { useErrorContext } from '../../store/ErrorContext';

interface IProps {
   setCardHeader: (title: string) => void;
};

export interface FoundationDiseaseTypeFormObject {
   foundationId: number;
   foundationDiseaseTypeId: number;
   foundationDiseaseTypeName: string;
   fundingStatuses: FundingStatus[];
   diseaseTypeIds: number[];
   foundationDiseaseTypeToSpecialties: FoundationDiseaseTypeToSpecialty[]
}

const yupFoundationDiseaseTypeSchema = yup.object({
   foundationId: yup.number().notRequired(),
   foundationDiseaseTypeName: yup.string()
      .required('Foundation Disease Type Name is required')
      .max(256, 'Max length is 256 characters'),
   fundingStatuses: yup.array<FundingStatus>()
      .test('fundingStatuses', 'Must be at least one funding status record to create a new Disease Type Name.',
         (value, ctx) => {
            try {
               return !ctx.parent?.foundationDiseaseTypeId && (value?.length ?? 0) === 0 ? false : true;
            } catch {
               return false;
            }
         }),
   diseaseTypeIds: yup.array().min(1, "Disease Type Id must have at least one selected value.").required("required"),
   foundationDiseaseTypeToSpecialties: yup.array<FoundationDiseaseTypeToSpecialty>().min(1, "At least one specialty must be selected").required("required"),
   foundationDiseaseTypeId: yup.number().notRequired(),
})

interface IFoundationDiseaseTypeYup extends yup.Asserts<typeof yupFoundationDiseaseTypeSchema> { }
const __formId = "frmFoundationDiseaseType";

const _keysLike: string[] = [foundationBaseUrl, baseFoundationDiseaseTypeUrl];


const FoundationDiseaseTypeDetail: React.FC<IProps> = (props) => {
   const { foundationId: foundationIdString, foundationDiseaseTypeId: foundationDiseaseTypeIdString } = useParams();
   const foundationDiseaseTypeId = foundationDiseaseTypeIdString ? Number(foundationDiseaseTypeIdString) : undefined;
   const foundationId: number = Number(foundationIdString);
   const previousUrl = `/program/Foundation/${foundationIdString}`;

   const { httpGet, httpDelete, httpPost } = useApiContext();
   const { removeErrors } = useErrorContext();
   const foundationDiseaseTypes = useFetchFoundationDiseaseTypes(httpGet);
   const navigate = useNavigate();

   // contains diseaseTypes for the particular foundation
   const foundationDiseaseType = useFoundationDiseaseTypesById(httpGet, foundationDiseaseTypeId);

   //get funding status records to test if we can delete
   const { fundingStatusList: fundingStatuses } = useFoundationFundingStatusListById(httpGet, foundationDiseaseTypeId);
   const foundationToSpecialty = useRequestFoundationDiseaseTypeToSpecialty(httpGet, foundationDiseaseTypeId);

   // all diseaseTypes
   const { diseaseTypes } = useFetchDiseaseTypes(httpGet);

   const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState<boolean>(false);
   const [isDeleting, setIsDeleting] = React.useState<boolean>(false);
   const [isFormSaving, setIsFormSaving] = React.useState<boolean>(false);

   const existingFoundation: Foundation & Models.IStatus = useFoundationById(httpGet, foundationId);

   const diseaseTypeOptions = React.useMemo(() => {
      if (diseaseTypes?.length > 0) {
         return diseaseTypes.map(disease => {
            return {
               value: disease.diseaseTypeId,
               label: disease.diseaseTypeName + " (" + disease.diseaseTypeId + ")",
               key: disease.diseaseTypeId
            } as IOptionItem;
         })
      }
      return [];
   }, [diseaseTypes]);

   const clearErrors = () => {
      removeErrors({ keysLike: _keysLike });
   }

   const { control, handleSubmit, setValue, getValues, trigger, setError, formState: { errors, isDirty }, reset } = useForm<IFoundationDiseaseTypeYup>({
      resolver: yupResolver<yup.AnyObject>(yupFoundationDiseaseTypeSchema),
      shouldFocusError: true,
   });


   /* Most of our forms don't need this "itemToEdit" construct, and we did try to remove this one.
    * However, the moment you even try to swap "itemToEdit" into a useMemo or skip it and construct this object directly in 
    * the reset useEffect, you end up with infinite re-renders.  Morever, it seems related to calling "reset" because if 
    * you go that far (swap itemToEdit into a useMemo), you'll avoid the re-renders if you don't call reset.
    * So if you want to try, be my guest, but beware. */
   const [itemToEdit, setItemToEdit] = React.useState<FoundationDiseaseTypeFormObject>();
   React.useEffect(() => {
      const loadItemToEdit = () => {

         if (foundationDiseaseType && fundingStatuses && (foundationToSpecialty && foundationToSpecialty.isLoading === false) && !itemToEdit) {
            const editItem = {
               foundationId: foundationId,
               foundationDiseaseTypeId: foundationDiseaseTypeId,
               diseaseTypeIds: foundationDiseaseType.diseaseTypeIds,
               foundationDiseaseTypeName: foundationDiseaseType.diseaseTypeName,
               foundationDiseaseTypeToSpecialties: foundationToSpecialty?.SpecialitiesList,
               fundingStatuses: fundingStatuses //newFundingStatuses
            } as FoundationDiseaseTypeFormObject;
            setItemToEdit(editItem);
            setIsFormSaving(false);
         }
      }

      loadItemToEdit();

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [foundationDiseaseTypeId, foundationDiseaseType, foundationToSpecialty, fundingStatuses]);

   React.useEffect(() => {
      reset(itemToEdit ?? {
         ...{
            foundationId: foundationId
         } as FoundationDiseaseTypeFormObject,
      });

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [itemToEdit, foundationId, foundationDiseaseTypeId]);

   const handleFundingStatusChanges = (entry: FundingStatus) => {
      const finalState = [
         ...getValues()?.fundingStatuses?.filter((e) => !findStatusEntry(e, entry)) ?? [],
         ...[entry]
      ]
      setValue('fundingStatuses', finalState, { shouldValidate: true, shouldDirty: true });
      trigger('fundingStatuses');
   };

   const onSubmit = async (data: IFoundationDiseaseTypeYup) => {

      const formObject = data as FoundationDiseaseTypeFormObject;
      const isEditing = getValues().foundationDiseaseTypeId > 0;
      const alreadyExists = foundationDiseaseTypes
         .foundationDiseaseTypes
         .some(y => y.foundationId === formObject.foundationId &&
            y.diseaseTypeName.toLocaleLowerCase() === formObject.foundationDiseaseTypeName.toLocaleLowerCase());

      if (!isEditing && alreadyExists) {
         setError('foundationDiseaseTypeName', { type: 'custom', message: 'Foundation Disease Type Name already exists.' })
         return;
      }
      setIsFormSaving(true);
      try {
         await saveNewFoundationDiseaseType(httpPost,
            formObject.fundingStatuses,
            formObject.foundationId,
            formObject.foundationDiseaseTypeName,
            formObject.diseaseTypeIds,
            formObject.foundationDiseaseTypeToSpecialties,
            formObject.foundationDiseaseTypeId)
            .then((response) => {
               const newItem = formObject.foundationDiseaseTypeId ? { ...formObject } : { ...{ foundationDiseaseTypeId: response?.id } }
               reset(newItem);
               if (foundationDiseaseTypeId === undefined && response?.id) {
                  navigate(previousUrl)
               }
            });
         clearErrors();
      } catch (e) {
         console.error('Error Saving', e);
      } finally {
         setIsFormSaving(false);
      }
   }

   React.useEffect(() => {
      if (props.setCardHeader) {
         const header = `${existingFoundation?.foundationName} Foundation Disease Details (${foundationDiseaseTypeId || "NEW"})`;
         props.setCardHeader(header);
      }
   }, [props, foundationDiseaseTypeId, existingFoundation]);

   const handleDeleteClick = async () => {
      try {
         await deleteFoundationDiseaseType(httpDelete, foundationDiseaseTypeId)
            .catch(err => console.error('Error Deleting Foundation Disease Type', err))
         navigate(previousUrl);
      } catch (e) {
         console.log('error:', e);
      } finally {
         setIsDeleting(true);
      }
   }

   React.useEffect(() => {
      if (isDeleting &&
         isFormSaving) {
         setIsDeleteDialogOpen(false);
         setIsDeleting(false);
         if (!foundationDiseaseTypes?.error) {
            navigate(-1);
         }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [isDeleting, foundationDiseaseTypes.error, isFormSaving]);

   if (!foundationDiseaseTypes || foundationDiseaseTypes.isLoading) {
      return <div className='loadercontainer' key={4342}><Spinner /></div>
   }

   return (
      <div style={{ padding: 10 }}>
         <Breadcrumb
            style={{ paddingTop: 5 }}
            separator='>'
            items={[
               {
                  title: <Link
                     to={{ pathname: `/content-tool/FOUNDATION` }}
                     title='Return to All Foundations'
                     className='content'>
                     Return to All Foundations
                  </Link>,
               },
               {
                  title: <Link
                     to={{ pathname: previousUrl }}
                     title='Return to Foundation Details'
                     className='content'>
                     Return to Foundation Details
                  </Link>
               },
            ]}
         />

         {errors && Object.keys(errors).length > 0 && <>
            <TitleError
               text='Please correct the errors below' />
            {console.log('--------------Form Errors: -------------------------')}
            {console.log(errors)}
         </>
         }

         <ApiErrorDisplay
            title='Error saving Foundation Disease Type'
            keysLike={_keysLike}
         />

         <Form id={__formId} onFinish={handleSubmit(onSubmit)}>
            <Divider style={{ marginTop: 10, marginBottom: 10 }} />
            <Row gutter={[16, 16]} justify="space-between" align="top">
               <Col span={10}>
                  <InputField
                     label='Foundation Disease Name'
                     control={control}
                     name="foundationDiseaseTypeName"
                     maxLength={256}
                     required={true}
                     error={errors?.foundationDiseaseTypeName}
                  />
               </Col>
               <Col span={8}>
                  <FieldWrapper
                     label='Specialties'
                     required={true}
                     field={<SpecialtySelector
                        foundationDiseaseTypeId={foundationDiseaseTypeId}
                        selectedSpecialties={getValues()?.foundationDiseaseTypeToSpecialties ?? []}
                        onChange={(dataset: FoundationDiseaseTypeToSpecialty[]) => {
                           setValue('foundationDiseaseTypeToSpecialties', dataset, { shouldValidate: true, shouldDirty: true });
                           trigger('foundationDiseaseTypeToSpecialties');
                        }} />}
                     error={errors?.foundationDiseaseTypeToSpecialties}
                     control={control}
                     name={'foundationDiseaseTypeToSpecialties'} />
               </Col>
               <Col span={6}>
                  <BasicFieldWrapper
                     field={<Space style={{ width: '100%', justifyContent: 'end' }}>
                        <CancelButton onClick={() => navigate(previousUrl)} />
                        <SaveButton
                           formId={__formId}
                           onClick={() => null}
                           disabled={Object.keys(errors).length > 0 || !isDirty}
                           loading={isFormSaving} />
                        {foundationDiseaseTypeId &&
                           <DeleteButton onClick={() => setIsDeleteDialogOpen(true)}
                              // todo evaluate how this should work with generic delete button
                              title={fundingStatuses?.length > 0 ? 'All Fund Availability Statuses need to be updated to "NoRecord" before this foundation disease type can be deleted' : 'Delete'}
                              disabled={fundingStatuses?.length > 0 || isFormSaving}
                              loading={isDeleteDialogOpen} />
                        }
                     </Space>}
                  />

               </Col>
            </Row>
            <Row>
               <Col span={24}>
                  <FormMultiSelectInput
                     style={{ minWidth: 400 }}
                     options={diseaseTypeOptions}
                     searchPlaceholder={`Search for Disease Types`}
                     placeholder='Select Disease Type Names'
                     allowSelectAll
                     searchable
                     required
                     label={'Disease Type Names'}
                     popoverTitle={'Disease Type Names'}
                     control={control}
                     name={'diseaseTypeIds'}
                     displayListStartingLength={300}
                     trigger={trigger}
                     error={errors?.diseaseTypeIds}
                     showOkButton
                  />
               </Col>
            </Row>

            <h2>{existingFoundation?.foundationName + " Fund Availability"}</h2>
            <ErrorDisplay error={errors?.fundingStatuses as FieldError} />

            <FundingStatusCtrl diseaseTypeId={foundationDiseaseTypeId}
               foundationId={foundationId}
               changes={getValues()?.fundingStatuses ?? []}
               updateChanges={handleFundingStatusChanges} />
         </Form>

         {isDeleteDialogOpen &&
            <Dialog
               title='CONFIRM DELETE'
               open={isDeleteDialogOpen}
               style={{ maxWidth: '550px' }}
               actionButtons={
                  <Space>
                     <CancelButton onClick={() => {
                        setIsDeleting(false);
                        setIsDeleteDialogOpen(false)
                     }} />
                     <DeleteButton
                        disabled={isFormSaving}
                        onClick={() => handleDeleteClick()} />
                  </Space>
               }
            >
               <div>
                  {`Are you sure you want to Delete the Foundation Disease Type (${foundationDiseaseTypeId}) ${foundationDiseaseType?.diseaseTypeName || ''} for ${existingFoundation?.foundationName}?`}
               </div>
            </Dialog>
         }
      </div>
   );
}

export default FoundationDiseaseTypeDetail;