import useSWR, { mutate } from 'swr';
import { HttpError, TGet, TPost, TPut } from '../../functions/httpClient';
import { useMemo } from 'react';
import { IOptionItem } from '../../functions/option.functions';
import { IStatus } from '../models';
import { useInsightlyOrganizationList } from './InsightlyOrganizationFetcher';
import { boolComparer, stringComparer } from '../../functions/comparer.functions';
import { Practice, PracticeViewModel } from './PracticeModel';
import { ImageMetadata } from '../ImageMetadataModel';
import { useUserMainViewModel } from '../auth/UserMainFetcher';

export const practiceBaseUrl = `api/practice`;
const adparoPracticesUrl = `${practiceBaseUrl}/GetAdparoPractices`
export const practiceSaveUrl = `${practiceBaseUrl}/Save`;
export const practiceDeactivateUrl = (id: number) => `${practiceBaseUrl}/Deactivate/${id}`;
export const getPracticeUrl = (id: number) => `${practiceBaseUrl}/Get/${id}`;
const practiceGetLogoUrl = (id: number) => `${practiceBaseUrl}/GetLogo/${id}`;

const swrOptions = {
   refreshInterval: 0,
   revalidateOnFocus: false,
   dedupingInterval: 60000
};


export function useFetchPractice(httpGet: TGet): IStatus & { practices: Practice[] } {
   const { data, isLoading, error } = useSWR<Practice[], HttpError>(
      practiceBaseUrl,
      httpGet,
      { ...swrOptions }
   );

   return {
      practices: data,
      isLoading: isLoading,
      error: error?.message
   };
}

export function useFetchAdparoPractices(httpGet: TGet): IStatus & { adparoPractices: Practice[] } {
   const { data, isLoading, error } = useSWR<Practice[], HttpError>(
      adparoPracticesUrl,
      httpGet,
      { ...swrOptions }
   );

   return {
      adparoPractices: data,
      isLoading: isLoading,
      error: error?.message
   };
}

export function useFetchPracticeLogo(httpGet: TGet, practiceId: number): IStatus & { logoData: ImageMetadata } {

   const shouldFetch = practiceId ? true : false;

   const { data, isLoading, error } = useSWR<ImageMetadata, HttpError>(
      shouldFetch ? practiceGetLogoUrl(practiceId) : null,
      httpGet,
      { ...swrOptions }
   );

   return {
      logoData: data,
      isLoading: isLoading,
      error: error?.message
   };
}

export const savePractice = async (httpPost: TPost, practice: Practice) => {
   const result = await httpPost(practiceSaveUrl, practice)
      .then(() => mutate(practiceBaseUrl));
   return result;
}

export const deactivatePractice = async (httpPut: TPut, id: number) => {
   const result = await httpPut(practiceDeactivateUrl(id))
      .then(() => mutate(practiceBaseUrl));
   return result;
}

export function usePracticeOptions(httpGet: TGet,
   includeAllPracticesOptions: boolean = false,
   includeNullOption: boolean = false,
   activePracticesOnly: boolean = false): { practiceOptions: IOptionItem[] } {
   const { practices, isLoading, error } = useFetchPractice(httpGet);

   const model: IOptionItem[] = useMemo(() => {
      if (!isLoading && practices) {
         let sortedPractices = [...practices];

         if (activePracticesOnly) {
            sortedPractices = sortedPractices.filter(y => !y.deactivated);
         }

         // RE: the 'zzzz' - AH-3122 wants practices with NULL names sorted at the bottom.  As you can see below though, we then use externalTenantId for display so...
         sortedPractices.sort((a, b) =>
            stringComparer(a.name ?? ('zzzz' + a.externalTenantId), b.name ?? ('zzzz' + b.externalTenantId)));
         sortedPractices.sort((a, b) => boolComparer(a.deactivated, b.deactivated));

         let tempModel = sortedPractices.map((practice: Practice): IOptionItem => ({
            key: practice.id,
            label: `[${practice.id}]: ${practice.name ?? practice.externalTenantId ?? 'Not In System'}`,
            value: practice.id
         } as IOptionItem));

         if (includeAllPracticesOptions) {
            tempModel.unshift({
               key: -1,
               label: `[-1]: All Practices`,
               value: -1
            } as IOptionItem)
         }

         if (includeNullOption) {
            tempModel.push({
               key: 0,
               label: '[NULL]',
               value: 0
            } as IOptionItem);
         }

         return tempModel;
      }
      return undefined;
   }, [practices, activePracticesOnly, includeNullOption, includeAllPracticesOptions, isLoading]);

   return {
      practiceOptions: model
   };
}


export function useUserProfilePracticeOptions(httpGet: TGet,
   userMainId?: number): { practiceOptions: IOptionItem[] } {
   const { userMainViewModel } = useUserMainViewModel(httpGet, userMainId);
   const { practiceOptions } = usePracticeOptions(httpGet, false, false, true);

   const betterModels = useMemo(() => {
      if (practiceOptions) {
         if (userMainId) {
            if (userMainViewModel?.userProfiles) {
               const practiceIds = userMainViewModel.userProfiles.map(y => y.practiceId);
               const userPractices = practiceOptions.filter(y => !practiceIds.includes(y.value as number));
               return userPractices;
            } else {
               return undefined;
            }
         } else {
            return practiceOptions;
         }
      }
      return undefined;
   }, [userMainId, practiceOptions, userMainViewModel])

   return {
      practiceOptions: betterModels
   };
}

export function useAdparoPracticeOptions(httpGet: TGet,
   includeAllPracticesOptions: boolean = false,
   includeNullOption: boolean = false): { adparoPracticeOptions: IOptionItem[] } {
   const { adparoPractices, isLoading, error } = useFetchAdparoPractices(httpGet);

   const model: IOptionItem[] = useMemo(() => {
      if (!isLoading && adparoPractices) {
         const sortedPractices = [...adparoPractices];
         // RE: the 'zzzz' - AH-3122 wants practices with NULL names sorted at the bottom.  As you can see below though, we then use externalTenantId for display so...
         sortedPractices.sort((a, b) =>
            stringComparer(a.name ?? ('zzzz' + a.externalTenantId), b.name ?? ('zzzz' + b.externalTenantId)));
         sortedPractices.sort((a, b) => boolComparer(a.deactivated, b.deactivated));

         let tempModel = sortedPractices.map((practice: Practice): IOptionItem => ({
            key: practice.id,
            label: `[${practice.id}]: ${practice.name ?? practice.externalTenantId ?? 'Not In System'}`,
            value: practice.id
         } as IOptionItem));

         if (includeAllPracticesOptions) {
            tempModel.unshift({
               key: -1,
               label: `[-1]: All Practices`,
               value: -1
            } as IOptionItem)
         }

         if (includeNullOption) {
            tempModel.push({
               key: 0,
               label: '[NULL]',
               value: 0
            } as IOptionItem);
         }
         return tempModel;
      }
      return undefined;
   }, [adparoPractices, includeAllPracticesOptions, includeNullOption, isLoading]);

   return {
      adparoPracticeOptions: model
   };
}

export function usePracticeViewModel(httpGet: TGet): IStatus & { practiceViewModels: PracticeViewModel[] } {
   const { practices, isLoading, isSaving, error } = useFetchPractice(httpGet);
   const { insightlyOrganizations, isLoading: isLoading_orgs } = useInsightlyOrganizationList(httpGet);

   const model: PracticeViewModel[] = useMemo(() => {
      if (practices?.length > 0) {
         let tempModel = practices.map((practice): PracticeViewModel => {
            return {
               ...practice,
               insightlyOrganization: insightlyOrganizations?.find(o => o.practiceId === practice.id)
            } as PracticeViewModel;
         });
         return tempModel;
      }
      return undefined;
   }, [practices, insightlyOrganizations]);

   return {
      practiceViewModels: model,
      isLoading: isLoading || isLoading_orgs,
      isSaving: isSaving,
      error: error
   }
}