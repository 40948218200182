import * as React from 'react';
import Sidebar from './Sidebar';
import MainFooter from './MainFooter';
import { Layout } from 'antd';
const { Sider, Content, Footer } = Layout;
import colorWheel from '../Theme/ColorWheel';
import { useLayoutContext } from './LayoutContext';

interface IProps {
   children?: React.ReactNode
}

const SiteLayout: React.FC<IProps> = (props) => {
   const { menuProps } = useLayoutContext();

   return (
      <Layout hasSider={true}>
         <Sider trigger={null} collapsible
            collapsed={menuProps.collapsed}
            collapsedWidth={50}
            width={250}
            style={{ minHeight: '100vh' }}
            theme='light'
            color={colorWheel.mediumGrey}

         >
            <div style={{ minHeight: '100vh' }}>
               <Sidebar />
            </div>
         </Sider>
         <Content>
            <div style={{ minHeight: '100vh' }}>
            {props.children}
               <MainFooter />
            </div>
         </Content>
      </Layout>
   );
}
         {/*<Content style={{ paddingTop: 5, paddingRight: 10, paddingBottom: 0, paddingLeft: 15 }}>*/}

export default SiteLayout;
