import { IOption, ISelectOption } from '../store/models';
import { NameAndId } from '../store/models';
//import { AssistanceProgram } from '../store/program/AssistanceProgram';
//import { AssistanceServiceLookup, DiseaseOrBrandLookup } from '../store/cohort/ProgramSummaryDetail';
import { ServiceCategory } from '../store/program/ServiceCategoryModel';
//import { Practice } from '../store/practice/PracticeModel';
import { numberComparer, stringComparer } from './comparer.functions';

//export const mapOptionsStringArr = (srcArr: string[], defaultOption: string): IOption[] => {
//   let options: IOption[];

//   if (!srcArr || srcArr.length === 0) {
//      //console.warn(`No options found in mapOptionsFromStringArr.`);
//      options = [];
//      return options;
//   }

//   options = srcArr.map(option => {
//      return {
//         Name: option,
//         Value: option,
//         IsDefault: false
//      } as IOption;
//   });

//   if (defaultOption && options[0].Name !== defaultOption) {
//      options.unshift({
//         Name: defaultOption,
//         Value: '',
//         IsDefault: true
//      } as IOption);
//   }
//   return options;
//}

export const mapOptionsNameAndId = (srcArr: NameAndId[], defaultOption: string, selectedOption: number): IOption[] => {
   let options: IOption[];

   if (!srcArr || srcArr.length === 0) {
      //console.warn(`No options found in srcArr.`);
      options = [];
      return options;
   }

   let isDefaultSet: boolean = false;
   options = srcArr.map((option: NameAndId) => {
      isDefaultSet = option.id === selectedOption;
      return {
         Name: option.name,
         Value: option.id, //String(option.id),
         IsDefault: isDefaultSet
      } as IOption
   });

   if (defaultOption && options[0].Name !== defaultOption) {
      options.unshift({
         Name: defaultOption,
         Value: '',
         IsDefault: !isDefaultSet
      } as IOption)
   }
   return options;
}

//export const mapOptionsPractice = (srcArr: Practice[], defaultOption: string, selectedOption: number): IOption[] => {
//    let options: IOption[];

//    if (!srcArr || srcArr.length === 0) {
//        //console.warn(`No options found in srcArr.`);
//        options = [];
//        return options;
//    }

//    let isDefaultSet: boolean = false;
//    options = srcArr.map((option: Practice) => {
//        isDefaultSet = option.id === selectedOption;
//        return {
//            Name: `[${option.id}] ${option.name}`,
//            Value: option.id,
//            IsDefault: isDefaultSet
//        } as IOption
//    });

//    if (defaultOption && options[0].Name !== defaultOption) {
//        options.unshift({
//            Name: '[-1] All',
//            Value: -1,
//            IsDefault: !isDefaultSet
//        } as IOption)
//    }
//    return options;
//}

export const mapOptionsServiceCategory = (srcArr: ServiceCategory[], defaultOption: string, selectedOption: number): IOption[] => {
    let options: IOption[];

    if (!srcArr || srcArr.length === 0) {
        //console.warn(`No options found in mapOptionsAssistancePrograms.`);
        options = [];
        return options;
    }

    let isDefaultSet: boolean = false;
    options = srcArr.map((option: ServiceCategory) => {
        isDefaultSet = option.id === selectedOption;
        return {
            Name: `${option.serviceDescription}`,
            Value: String(option.id),
            IsDefault: isDefaultSet
        } as IOption;
    });

    if (defaultOption && options[0].Name !== defaultOption) {
        options.unshift({
            Name: defaultOption,
            Value: '',
            IsDefault: !isDefaultSet
        } as IOption);
    }
    return options;
}

//export const mapOptionsAssistanceProgram = (srcArr: AssistanceProgram[], defaultOption: string, selectedOption: number): IOption[] => {
//   let options: IOption[];

//   if (!srcArr || srcArr.length === 0) {
//      //console.warn(`No options found in mapOptionsAssistancePrograms.`);
//      options = [];
//      return options;
//   }

//   let isDefaultSet: boolean = false;
//   options = srcArr.map((option: AssistanceProgram) => {
//      isDefaultSet = option.id === selectedOption;
//      return {
//         Name: `${option.id} : ${option.programName}`,
//         Value: String(option.id),
//         IsDefault: isDefaultSet
//      } as IOption;
//   });

//   if (defaultOption && options[0].Name !== defaultOption) {
//      options.unshift({
//         Name: defaultOption,
//         Value: '',
//         IsDefault: !isDefaultSet
//      } as IOption);
//   }
//   return options;
//}

//export const mapOptionsAssistanceServiceLookup = (srcArr: AssistanceServiceLookup[], defaultOption: string,
//   selectedOption: number, assistanceProgramId?: number): IOption[] => {
//   let options: IOption[] = [];

//   if (!srcArr || srcArr.length === 0) {
//      //console.warn(`No options found in mapOptionsAssistanceServiceLookup.`);
//      return options;
//   }

//   let isDefaultSet: boolean = false;
//   for (let idx = 0; idx < srcArr.length; idx++) {
//      //make sure program matches
//      if (((assistanceProgramId ?? 0) > 0 ? assistanceProgramId : srcArr[idx].assistanceProgramId) === srcArr[idx].assistanceProgramId) {
//         //make sure not already in options
//         if (!options.some(y => y.Value === String(srcArr[idx].assistanceServiceId))) {
//            isDefaultSet = srcArr[idx].assistanceServiceId === selectedOption;
//            const val = String(srcArr[idx].assistanceServiceId);
//            const option: IOption = {
//              // Name: `[${val}] ${srcArr[idx].assistanceServiceName}`,
//               Name: `${srcArr[idx].assistanceServiceName}`,
//               Value: val,
//               IsDefault: isDefaultSet
//            }
//            options.push(option);
//         }
//      }
//   }

//   if (options.length > 1 && defaultOption && options[0].Name !== defaultOption) {
//      options.unshift({
//         Name: defaultOption,
//         Value: '',
//         IsDefault: !isDefaultSet
//      } as IOption);
//   }
//   return options;
//}

//export const mapOptionsDiseaseOrBrandLookup = (srcArr: DiseaseOrBrandLookup[], defaultOption: string,
//   selectedOption: number, assistanceProgramId?: number, assistanceServiceId?: number): IOption[] => {
//   let options: IOption[] = [];

//   if (!srcArr || srcArr.length === 0) {
//      //console.warn(`No options found in mapOptionsAssistanceServiceLookup.`);
//      return options;
//   }
//   let isDefaultSet: boolean = false;
//   for (let idx = 0; idx < srcArr.length; idx++) {
//      //make sure program & service matches
//      if (((assistanceProgramId ?? 0) > 0? assistanceProgramId : srcArr[idx].assistanceProgramId) === srcArr[idx].assistanceProgramId &&
//         ((assistanceServiceId ?? 0) > 0 ? assistanceServiceId : srcArr[idx].assistanceServiceId) === srcArr[idx].assistanceServiceId) {
//         //make sure not already in options
//         if (!options.some(y => y.Value > 0 && y.Value === String(srcArr[idx].diseaseOrBrandId))) {
//            isDefaultSet = srcArr[idx].diseaseOrBrandId === selectedOption;
//            const val = String(srcArr[idx].diseaseOrBrandId);
//            const option: IOption = {
//               //Name: `[${val}] ${srcArr[idx].diseaseOrBrand}`,
//               Name: `${srcArr[idx].diseaseOrBrand}`,
//               Value: val,
//               IsDefault: isDefaultSet
//            }
//            options.push(option);
//         }
//      }
//   }
  
//   if (options.length > 1 && defaultOption && options[0].Name !== defaultOption) {
//      options.unshift({
//         Name: defaultOption,
//         Value: '',
//         IsDefault: !isDefaultSet
//      } as IOption);
//   }
//   return options;
//}

//export const getProgramTypeDisplay = (programType: number): string => {

//   const programDetail: NameAndId = ProgramTypeList.find(y => y.id === programType);
//   if (programDetail) {
//      return `${programDetail.name}`;
//   }

//   return `${programType} `;
//}

export const yesNo: IOptionItem[] = [
   { value: 'Yes', label: 'Yes' },
   { value: 'No', label: 'No' }
]

export const yesNoFilter = [{ text: 'Yes', value: true }, { text: 'No', value: false }];

/**
 * function used to provide an array formated as ISelectOption needed for
 * Semantic-UI Select tag option property
 * @param e enum type that has a text name and a number or text index
 * @returns ISelectOption[] 
**/
export const enumToSelectOptions = (e: Record<string, string | number>): ISelectOption[] => {
   return Object.keys(e)            // keys will have duplicate entries, one for number index and one for string index
   .filter((v) => isNaN(Number(v))) // the string names of the enums
   .map((key: string) => {          // format in the way that ISelectOption needs it
         return {
            text: key,
            value: e[key]
         }
   });
}

export const ProgramTypeList: NameAndId[] = [{ id: 0, name: 'Manufacturer' }, { id: 1, name: 'Foundation' }];
export const AssisanceTypeList: NameAndId[] = [{ id: 1, name: 'Co-Pay Assistance' }, { id: 2, name: 'Drug Assistance' }, { id: 3, name: 'Additional Service' }];
export const CohortStatusList: string[] = ['Queued', 'Open', 'Published'];
export const CohortStatusOptions: IOptionItem[] = CohortStatusList.map(o =>
({
   label: o, value: o
}));

export const XRefStatusList: string[] = ['New', 'Deleted', 'Mapped', 'Not Required'];
export const getOptionDefaultText = (label: string): string => {
   return `-- Select ${label} --`;
}

export const States = [
   { key: 1, value: 'AL', text: 'Alabama' },
   { key: 2, value: 'AK', text: 'Alaska' },
   { key: 3, value: 'AZ', text: 'Arizona' },
   { key: 4, value: 'AR', text: 'Arkansas' },
   { key: 5, value: 'CA', text: 'California' },
   { key: 6, value: 'CO', text: 'Colorado' },
   { key: 7, value: 'CT', text: 'Connecticut' },
   { key: 8, value: 'DE', text: 'Delaware' },
   { key: 9, value: 'DC', text: 'District Of Columbia' },
   { key: 10, value: 'FL', text: 'Florida' },
   { key: 11, value: 'GA', text: 'Georgia' },
   { key: 12, value: 'HI', text: 'Hawaii' },
   { key: 13, value: 'ID', text: 'Idaho' },
   { key: 14, value: 'IL', text: 'Illinois' },
   { key: 15, value: 'IN', text: 'Indiana' },
   { key: 16, value: 'IA', text: 'Iowa' },
   { key: 17, value: 'KS', text: 'Kansas' },
   { key: 18, value: 'KY', text: 'Kentucky' },
   { key: 19, value: 'LA', text: 'Louisiana' },
   { key: 20, value: 'ME', text: 'Maine' },
   { key: 21, value: 'MD', text: 'Maryland' },
   { key: 22, value: 'MA', text: 'Massachusetts' },
   { key: 23, value: 'MI', text: 'Michigan' },
   { key: 24, value: 'MN', text: 'Minnesota' },
   { key: 25, value: 'MS', text: 'Mississippi' },
   { key: 26, value: 'MO', text: 'Missouri' },
   { key: 27, value: 'MT', text: 'Montana' },
   { key: 28, value: 'NE', text: 'Nebraska' },
   { key: 29, value: 'NV', text: 'Nevada' },
   { key: 30, value: 'NH', text: 'New Hampshire' },
   { key: 31, value: 'NJ', text: 'New Jersey' },
   { key: 32, value: 'NM', text: 'New Mexico' },
   { key: 33, value: 'NY', text: 'New York' },
   { key: 34, value: 'NC', text: 'North Carolina' },
   { key: 35, value: 'ND', text: 'North Dakota' },
   { key: 36, value: 'OH', text: 'Ohio' },
   { key: 37, value: 'OK', text: 'Oklahoma' },
   { key: 38, value: 'OR', text: 'Oregon' },
   { key: 39, value: 'PA', text: 'Pennsylvania' },
   { key: 40, value: 'RI', text: 'Rhode Island' },
   { key: 41, value: 'SC', text: 'South Carolina' },
   { key: 42, value: 'SD', text: 'South Dakota' },
   { key: 43, value: 'TN', text: 'Tennessee' },
   { key: 44, value: 'TX', text: 'Texas' },
   { key: 45, value: 'UT', text: 'Utah' },
   { key: 46, value: 'VT', text: 'Vermont' },
   { key: 47, value: 'VA', text: 'Virginia' },
   { key: 48, value: 'WA', text: 'Washington' },
   { key: 49, value: 'WV', text: 'West Virginia' },
   { key: 50, value: 'WI', text: 'Wisconsin' },
   { key: 51, value: 'WY', text: 'Wyoming' }
];

export const StateOptionItems: IOptionItem[] = States.map(state =>
({
    key: state.key, label: state.text, value: state.value
}));

export interface IOptionItem {
   label: string | React.ReactNode;
   value?: string | number | null;
   isSelectOption?: boolean;
}

export const sortOptionsByDistinctId = (options: IOptionItem[]): IOptionItem[] => {
   const unique = [...new Map(options.map(item => [item['value'], item])).values()];
   unique.sort((a: IOptionItem, b: IOptionItem) => numberComparer(Number(a.value), Number(b.value)));
   return unique;
}

export const removeOptionDuplicates = (optionArray: IOptionItem[]): IOptionItem[] => {
   let returnArr: IOptionItem[] = [];
   optionArray?.forEach(duplicate => { if (duplicate.value && !returnArr.some(r => r.value === duplicate.value)) { returnArr.push(duplicate); } });

   return returnArr;
}

export const sortOptionsByDistinctLabel = (options: IOptionItem[]): IOptionItem[] => {
   const unique = [...new Map(options.map(item => [item['value'], item])).values()];
   unique.sort((a: IOptionItem, b: IOptionItem) => stringComparer(a.label as string, b.label as string));
   return unique;
}
