import * as React from 'react';
import { truncateStringEnd } from '../../functions/format.functions';

type Props = {
    fullText: string,
    maxLength: number,
    symbol?: string // its optional because an ellipsis is the default in truncateStringEnd
}

const TruncateText = (text: Props) => {
    // no need for a tooltip if it's not long enough to tuncate or exists
    if (!text.fullText || text.fullText?.length <= text.maxLength)
        return <>{text.fullText}</>;

    return (
        <>
            <span title={text.fullText}>
                {text.symbol ? truncateStringEnd(text.fullText, text.maxLength, text.symbol) : truncateStringEnd(text.fullText, text.maxLength)}
            </span>
        </>
    );
}

export default TruncateText;