import * as React from 'react';
import { TValue, TValues } from '../../BasicInputLibrary';
import { IBasicMultiSelectProps, BasicMultiSelect } from '../../MultiSelect/BasicMultiSelectInput';
import { IFilteredInfo } from './FilteredInfo';


interface IProps extends IBasicMultiSelectProps {
   filteredInfo?: Record<string, IFilteredInfo | null>;
   setFilteredInfo?: (filter: Record<string, IFilteredInfo | null>) => void;
   filteredInfoKey: string; // <- Should be the matching value from your "searchFilter" part of <Record<searchFilter, IFilteredInfo> from your filteredInfo/setFilteredInfo state definition
   filteredInfoTitle: string;
   displayListStartingLength?: number;
   isOpenFilter?: boolean; 
   setIsOpenFilter?: (isOpen: boolean) => void; 
}

const MultiSelectColumnFilter: React.FC<IProps> = (props) => {
   const { filteredInfo, setFilteredInfo, filteredInfoKey, filteredInfoTitle,
      options, ...rest } = props;
   
   const [internalFilteredInfoKey, setInternalFilteredInfoKey] = React.useState<string>();

   // ^ \/ I like this one... so you can pass in the exact key that you need but React throws an error unless you do the below
   // and retrieve the key via Object.keys... but they're the same value... okay :) 
   React.useEffect(() => {
      const keys = Object.keys(filteredInfo);
      keys?.forEach(key => {
         if (key === filteredInfoKey) setInternalFilteredInfoKey(key);
      });
      // we specifically don't want this running when filteredInfo changes
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [filteredInfoKey])

   const otherHtml = (
      <BasicMultiSelect
         options={options}
         value={filteredInfo[internalFilteredInfoKey]?.values?.map(y => y.value)}
         onChange={(e) => {
            const newValue = Array.isArray(e) ?
               options.filter(y => (e as TValues[]).some(t => y.value === t))
               : options.filter(y => (e as TValue) === y.value);

            const newFilteredInfo = { ...filteredInfo };
            if (!newFilteredInfo[internalFilteredInfoKey]?.values) { // Undefined on the first filter selection
               newFilteredInfo[internalFilteredInfoKey] = {
                  title: filteredInfoTitle,
                  values: [...newValue],
                  searchValue: filteredInfo[internalFilteredInfoKey]?.searchValue
               } as IFilteredInfo;
            } else {
               newFilteredInfo[internalFilteredInfoKey].values = [...newValue];
            }
            setFilteredInfo(newFilteredInfo);
         }}
         multiSelectOnly
         {...rest}
      />
   )

   return otherHtml;
}

export default MultiSelectColumnFilter;
