import * as React from 'react';
import { Image } from 'antd';

interface IProps {
    iconName?: string;
    imageSrc?: string;
    className?: string;
    styles?: object;
    title?: string;
}

const ImageContainer: React.FC<IProps> = (props) => {
    // why aren't we using Ant's icons that come baked-in? ╭( ๐_๐)╮
    const fontawesomeSrc = `https://ap-cdn.azureedge.net/cdn-images/practice/shared/fontawesome/${props.iconName}.svg`

    return <Image title={props.title} src={props.imageSrc || fontawesomeSrc} style={props.styles || { 'width': 25 }} className={props.className}
        preview={false} data-image={`${props.iconName ? props.iconName : ''}imagecontainer`} />
}

export default ImageContainer;
