import { yupResolver } from '@hookform/resolvers/yup';
import { Col, Divider, Form, Row, Space } from 'antd';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { useUserContext } from '../../auth/authContext';
import ChangePasswordButton from '../../auth/ChangePasswordButton';
import Restricted from '../../auth/Restricted';
import { validateEmail } from '../../functions/form.validators';
import { formatShortDateString } from '../../functions/format.functions';
import { useApiContext } from '../../store/ApiContext';
import { useFetchAccessRole } from '../../store/auth/AccessRoleFetcher';
import { provisionUser, provisionUserUrl } from '../../store/auth/UserFetcher';
import { saveUserMain, userMainSaveUrl, useUserMainViewModels } from '../../store/auth/UserMainFetcher';
import { UserMainViewModel } from '../../store/auth/UserMainModel';
import { CreateUserMain } from '../../store/auth/UserModel';
import { saveUserFundStatusAlert, saveUserFundStatusAlertUrl, useFetchUserFundStatusAlert } from '../../store/fsa/UserFundStatusFetcher';
import { UserFundStatusAlert } from '../../store/fsa/UserFundStatusModels';
import { useInsightlyContactList } from '../../store/practice/InsightlyContactFetcher';
import { useFetchUserProfiles } from '../../store/practice/UserProfileFetcher';
import { KnownSettings } from '../../store/SettingsModel';
import ApiErrorDisplay from '../ApiErrorDisplay';
import { BasicFieldWrapper } from '../shared/BasicInputLibrary';
import { ActionButton, CancelButton, EditButton, SaveButton } from '../shared/Buttons';
import { CheckboxField, InputField } from '../shared/InputLibrary';
import UserAccessRoleList from './UserAccessRolesList';
import CustomIcon, { CustomIconType } from '../shared/AntComponents/CustomIcon';
import UpnEditor from './shared/UpnEditor';
import { UserMigrationContextProvider } from '../../store/auth/UserMigrationContext';
import UserProfileRow from './UserProfileRow';
import { useErrorContext } from '../../store/ErrorContext';

const userMainYupSchema = yup.object({
   id: yup.number().notRequired(),
   uId: yup.string().notRequired(), // Required by DB but we'll assign/generate server-side
   upn: yup.string().required().test('upn', 'A valid Email Address is required as a UPN', value => validateEmail(value)),
   emailAddress: yup.string().required().test('emailAddress', 'A valid Email Address is required', value => validateEmail(value)),
   createdOn: yup.date().notRequired(), // Required by DB but we'll assign server-side
   inviteSentOn: yup.date().notRequired(),
   auth0Id: yup.string().notRequired(),
   auth0CreatedOn: yup.date().notRequired(),
   isDisabled: yup.boolean().notRequired(), // Required by DB but we'll assume null is false
   delayInvite: yup.boolean().notRequired(),
   userFundStatusSubscribed: yup.boolean().notRequired()
});

interface IUserMainYup extends yup.Asserts<typeof userMainYupSchema> { }

interface UserDetail extends UserMainViewModel {
   userFundStatusSubscribed: boolean;
}

const __formid = 'formUserMainEditor';
const _keys: string[] = [saveUserFundStatusAlertUrl, provisionUserUrl, userMainSaveUrl];


const UserDetail: React.FC<{ userMainId: number, userProfileId?: number }> = (props) => {
   const { userMainId, userProfileId } = props;
   const navigate = useNavigate();
   const { removeErrors } = useErrorContext();
   const { currentUserMain } = useUserContext();
   const { httpGet, httpPost } = useApiContext();
   
   const { userMainViewModels } = useUserMainViewModels(httpGet);
   const { userFundStatusAlerts } = useFetchUserFundStatusAlert(httpGet);
   const { insightlyContacts } = useInsightlyContactList(httpGet);
   const { accessRoles } = useFetchAccessRole(httpGet);
   const { userProfiles } = useFetchUserProfiles(httpGet);

   const [editItem, setEditItem] = React.useState<UserDetail>(undefined);
   const [isFormSaving, setIsFormSaving] = React.useState<boolean>(false);
   const [editingUserMain, setEditingUserMain] = React.useState(userMainId === undefined);
   const [selectedAccessRoles, setSelectedAccessRoles] = React.useState<number[]>([]);
   const [resetToggle, setResetToggle] = React.useState(false);
   const [isUpnEditorOpen, setIsUpnEditorOpen] = React.useState<boolean>(false);

   const { control, handleSubmit, formState: { errors }, reset, watch } = useForm<IUserMainYup>({
      resolver: yupResolver<yup.AnyObject>(userMainYupSchema),
      shouldFocusError: true,
   });

   const watchUserFundStatusSubscribed = watch('userFundStatusSubscribed');

   React.useEffect(() => {
      if (userMainId && userMainViewModels && userProfiles && insightlyContacts) {
         const userMain = userMainViewModels.find(y => y.id === userMainId);
         if (!userMain) return;

         setEditItem(getCompositeUserDetail(userMain));
         setSelectedAccessRoles(userMain.accessRoleIds);
      }
      // for "getCompositeUserDetail"
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [userMainId, userMainViewModels, userFundStatusAlerts, userProfiles, insightlyContacts]);

   React.useEffect(() => {
      removeErrors({ keys: _keys });
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []) // clear errors on intial render of the page

   React.useEffect(() => {
      let resetValue: UserDetail;

      if (editItem) {
         resetValue = editItem;
      } else if (!editItem && userProfileId) {
         // get the profile info here
         const currentProfile = userProfiles.find((up) => up.userProfileId === userProfileId);
         // set reset object upn, email, etc
         resetValue = {
            upn: currentProfile.emailAddress,
            emailAddress: currentProfile.emailAddress,
            userProfileIds: [currentProfile.userProfileId],
         } as UserDetail;
      } else {
         resetValue = {} as UserDetail;
      }


      reset(resetValue);
      // otherwise it complains about "reset" needing included when it isn't going to change
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [userMainId, editItem, resetToggle]);

   const getCompositeUserDetail = (userMain: UserMainViewModel): UserDetail => {
      // If we don't have a UserFundStatusAlert then we'll defer to insightlyContact for the subscribed value
      let subscribed = false;
      //const userMain = userMainList.find(y => y.id === userMainId);
      const userFundStatusAlert = userFundStatusAlerts?.find(y => y.userMainId === userMainId);
      if (!userFundStatusAlert) {
         const insightlyContact = insightlyContacts?.find(y => y.emailAddress?.toLowerCase().trim() === userMain.emailAddress.toLowerCase().trim());
         subscribed = insightlyContact?.fundStatusCom?.toLowerCase()?.indexOf('yes') > -1;
      } else {
         subscribed = userFundStatusAlert.subscribed;
      }

      return {
         ...userMain,
         userFundStatusSubscribed: subscribed
      } as UserDetail
   }

   const onSubmit = (data: IUserMainYup): void => {
      setIsFormSaving(true);
      const item = data as unknown as CreateUserMain;
      item.accessRoleIds = selectedAccessRoles;

      if (item.id) {
         handleUpdateUserFundStatus();
         callApiToUpdateExisting(item);
      } else {
         callApiToAddNew(item);
      }
   }

   const onCancel = () => {
      setEditingUserMain(false);
      if (userMainId) {
          const userMainFromStore = userMainViewModels.find(y => y.id === userMainId)
         setEditItem(getCompositeUserDetail(userMainFromStore));
         setSelectedAccessRoles(userMainFromStore.accessRoleIds);
         setResetToggle(!resetToggle);
      } else {
         // no userMainId means "Add New", means that cancelling takes you back to the Manage Users grid
         setEditItem(undefined);
         setSelectedAccessRoles([]);
         navigate('/user');
      }
   }

   const callApiToUpdateExisting = (item: UserMainViewModel) => {
      saveUserMain(httpPost, item)
         .then(() => {
            setEditingUserMain(false);
         })
         .catch(err => console.error('Error Saving', err))
         .finally(() => {
            setIsFormSaving(false);
         })
   }

   const callApiToAddNew = (item: CreateUserMain) => {
      provisionUser(httpPost, item)
         .then((returnedUserMain) => {
            navigate(`/user/userdetail/${returnedUserMain.id}`)
         })
         .catch(err => console.error('Error Provisioning User', err))
         .finally(() => {
            setIsFormSaving(false);
         })
   }

   const handleUpdateUserFundStatus = () => {
      let data = userFundStatusAlerts.find(y => y.userMainId === editItem.id);// ?? { userMainId: editItem.id } as UserFundStatusAlert;

      //jumping through hoops to determine if we should make the call.
      let subscribed = false;
      if (!data) {
         const insightlyContact = insightlyContacts?.find(y => y.emailAddress?.toLowerCase().trim() === editItem.emailAddress.toLowerCase().trim());
         subscribed = insightlyContact?.fundStatusCom?.toLowerCase()?.indexOf('yes') > -1;
      } else {
         subscribed = data.subscribed;
      }
      if (subscribed !== watchUserFundStatusSubscribed) {
         if (!data) {
            data = { userMainId: editItem.id } as UserFundStatusAlert;
         }
         if (!watchUserFundStatusSubscribed) {
            data.unsubscribeReason = `${currentUserMain?.upn} unsubscribed in admin portal`
         }
         data.subscribed = watchUserFundStatusSubscribed;
         saveUserFundStatusAlert(httpPost, data);
      }
   }

   const handleAccessRoleChecked = (roleId: number) => {
      if (selectedAccessRoles?.includes(roleId)) {
         // unchecked...
         setSelectedAccessRoles(selectedAccessRoles?.filter(y => y !== roleId))
      } else {
         // checked
         const accessRoleIds = selectedAccessRoles?.length > 0 ? [...selectedAccessRoles, roleId] : [roleId];
         setSelectedAccessRoles(accessRoleIds);
      }
   }

   const html = (
      <>
         <div style={{ width: '100%' }}>

            <ApiErrorDisplay
               title='Error saving User'
               keys={_keys}
            />

            <Form id={__formid} onFinish={handleSubmit(onSubmit)}>
               <br /> {/*To get it away from the breadcrumb...*/}
               <Row>
                  <Col span={16}>
                     <Row gutter={[16, 16]}>
                        <Col span={12}>
                           {!editItem &&
                              <InputField
                              control={control}
                              error={errors?.upn}
                              name='upn'
                              label='UPN'
                              disabled={!editingUserMain}
                              required={true}
                              />
                           }
                           {editItem &&
                              <BasicFieldWrapper
                                 label='UPN'
                                 field={editItem?.upn}
                              />}
                        </Col>
                        <Col span={12}>
                           <InputField
                              control={control}
                              error={errors?.emailAddress}
                              name='emailAddress'
                              label='Email Address'
                              disabled={!editingUserMain}
                              required={true}
                           />
                        </Col>
                     </Row>
                     <Row gutter={[16, 16]}>
                        <Col span={12}>
                           <CheckboxField
                              control={control}
                              error={errors?.isDisabled}
                              name={'isDisabled'}
                              label={'Disabled'}
                              disabled={!editingUserMain}
                           />
                        </Col>
                        {userMainId &&
                           <Col span={12}>
                              <CheckboxField
                                 control={control}
                                 error={errors?.userFundStatusSubscribed}
                                 name={'userFundStatusSubscribed'}
                                 label={'User Fund Status Subscribed'}
                                 disabled={!editingUserMain}
                              />
                           </Col>
                        }
                     </Row>
                     {userMainId && <>
                        <Row gutter={[16, 16]}>
                           <Col span={6}>
                              <BasicFieldWrapper label='Invite Sent' field={formatShortDateString(editItem?.inviteSentOn)} />
                           </Col>
                           <Col span={6}>
                              <BasicFieldWrapper label='Created On' field={formatShortDateString(editItem?.createdOn)} />
                           </Col>
                           <Col span={6}>
                              <BasicFieldWrapper label='Auth0 ID' field={editItem?.auth0Id} />
                           </Col>
                           <br />
                           <br />
                           <br />
                        </Row>
                        <Row gutter={[16, 16]}>
                           <Col span={12}>
                              <BasicFieldWrapper label='UID' field={editItem?.uId} />
                           </Col>
                        </Row>
                     </>}
                  </Col>
                  <Col span={8}>
                     <div className='content'>
                        {accessRoles &&
                           <UserAccessRoleList
                              editing={editingUserMain}
                              assignedAccessRoles={selectedAccessRoles}
                              handleAccessRoleChecked={handleAccessRoleChecked} />
                        }
                     </div>
                  </Col>
               </Row>
            </Form>
            <div>
               <div style={{ float: 'left', padding: '10px' }}>
                  <Space>
                     {editItem?.auth0Id?.includes('auth0') &&
                        <Restricted requiredRoles={[KnownSettings.UserAdmin]}>
                           <ChangePasswordButton userMainId={editItem.id} showButtonText={true} />
                        </Restricted>}
                     {editItem && <>
                        <Restricted requiredRoles={[KnownSettings.UserAdmin]}>
                           <ActionButton
                              buttonText='Migrate'
                              disabled={editingUserMain}
                              icon={<CustomIcon type={CustomIconType.SwapOutlined} />}
                              onClick={() => {
                                 setIsUpnEditorOpen(true)
                              }} />
                        </Restricted>
                     </>}
                  </Space>
               </div>
               <div style={{ float: 'right', padding: '10px' }}>
                  <Space>
                     {editingUserMain && <>
                        <CancelButton onClick={onCancel} />
                        <SaveButton formId={__formid} disabled={Object.keys(errors).length > 0} loading={isFormSaving} onClick={() => null} />
                     </>}
                     {!editingUserMain &&
                        <EditButton buttonText={'Edit User Main'} title={'Edit User Main'} onClick={() => setEditingUserMain(true)} />
                     }
                  </Space>
               </div>
            </div>
         </div>
         <Divider />
         {editItem && <UserProfileRow displayAddNew displayCopyButton userMainId={editItem.id} gridTitle={'Practice Access'} userProfiles={editItem.userProfiles } /> }

         {isUpnEditorOpen &&
            <UserMigrationContextProvider>
               <UpnEditor
                  isOpen={isUpnEditorOpen}
                  closeEditor={() => setIsUpnEditorOpen(false)}
                  userMainId={userMainId}
                  />
            </UserMigrationContextProvider>
         }
      </>
   );

   return html;

}
export default UserDetail;
