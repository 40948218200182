export type Interaction = {
    id: number;
    practiceId: number;
    interactionType: number;
    title: string;
    content: string;
    createdBy: number;
    createdOn: Date;
    modifiedBy?: number;
    modifiedOn?: Date;
    deleted?: boolean;
    deletedBy?: number;
    deletedOn?: Date;
    diagnosisId?: number;
    insuranceClassId?: number;
    applicationId: number;
}

export const ADMIN_PORTAL_INTERACTION_TYPE = 16; // 16 = "App Status Note" and its the only InteractionType we deal with in AdminPortal