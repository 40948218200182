import { ColumnsType, TableProps } from 'antd/lib/table';
import { SorterResult } from 'antd/lib/table/interface';
import React from 'react';
import { IOptionItem } from '../../../functions/option.functions';
import { useFetchAPInsurancePayerType,
   useFetchContentClassificationXRefPractices
} from '../../../store/xref/ContentClassificationXRefFetcher';
import {
   useContentClassificationXrefChangeLogSearch,
   contentClassificationRefChangeLogBaseUrl
} from '../../../store/xref/ContentClassificationXRefChangeLogFetcher';
import ApiErrorDisplay from '../../ApiErrorDisplay';
import HighlightSearchText from '../../HighlightSearchText';
import { BasicDateRangePickerField, DebouncedBasicInputField } from '../../shared/BasicInputLibrary';
import { useApiContext } from '../../../store/ApiContext';
import { antSortOptions } from '../../shared/AntComponents/Table/table.functions';
import { useErrorContext } from '../../../store/ErrorContext';
import { formatPracticeName } from '../../../store/practice/PracticeModel';
import ColumnFilter from '../../shared/AntComponents/Filter/ColumnFilter';
import { IFilteredInfo } from '../../shared/AntComponents/Filter/FilteredInfo';
import MultiSelectColumnFilter from '../../shared/AntComponents/Filter/MultiSelectColumnFilter';
import { formatDateTimeString } from '../../../functions/format.functions';
import { ContentClassificationXRefChangeLog, ContentClassificationXRefChangeLogSearchFilter, modifiedRanges } from '../../../store/xref/ContentClassificationXRefChangeLogModel';
import { getDateRangeString, getDateRange } from '../../../functions/time.functions';
import SearchResultsTable from '../../shared/AntComponents/Table/SearchResultsTable';
import { useParams } from 'react-router';

type searchFilter = 'insuranceGroup' | 'payer' | 'practiceId' | 'practiceName' | 'newInsuranceClasses' | 'oldInsuranceClasses' | 'facilityGrouping' | 'modifiedOn' | 'modifiedBy';

///Sorry for the js date math - this method should set start and end dates to 
///   align with the dashboard tile.  The code here is modeled after tsql used 
///   to get the dashboard tile numbers - keeping in mind, tsql 
///   datepart(dw, @now) monday is 1 whereas js sunday is 1.
const getModifiedRanges = (modifiedRange?: string): { from: Date, to: Date } => {
   const now = new Date();
   const fromDate = new Date();
   const toDate = new Date();
   
   switch (modifiedRange) {
      case modifiedRanges.thisWeek:
         //latest monday - dateadd(dd,2-datepart(dw,@now),@now)
         fromDate.setDate(now.getDate() + 1 - now.getDay());
         //future date
         toDate.setDate(now.getDate() + 1);
         break;
      case modifiedRanges.lastWeek:
         //previous (before ↑latest) monday - dateadd(dd,2-7-datepart(dw,@now),@now)
         fromDate.setDate(now.getDate() + 1 - 7 - now.getDay());
         //latest sunday
         toDate.setDate(now.getDate() - now.getDay());
         break;
      case modifiedRanges.lastMonth:
         //30 days ago - dateadd(dd,-30,@now);
         fromDate.setDate(now.getDate() - 30);
         //previous (before ↑latest) sunday
         toDate.setDate(now.getDate() - 7 - now.getDay());
         break;
      default:
         //sunday before last
         fromDate.setDate(now.getDate() - now.getDay() - 6);
         //future date
         toDate.setDate(now.getDate() + 1);
         break;
   }
   //console.log(modifiedRange ?? 'defaultRange', fromDate, toDate);
   fromDate.setHours(0, 0, 0, 0);
   toDate.setHours(0, 0, 0, 0);

   return { from: fromDate, to: toDate };
}

const _keysLike: string[] = [contentClassificationRefChangeLogBaseUrl];

export const ContentClassificationXRefChangeLogList: React.FC = () => {
   const { httpGet, httpPost } = useApiContext();
   const { removeErrors } = useErrorContext();
   const { cmd } = useParams<{ cmd: string }>();

   var _modifiedRanges = React.useMemo(() => getModifiedRanges(cmd), [cmd]);
   //in place here to make debugging javascript date ranges slightly less painful
   //console.log('_modifiedRanges', _modifiedRanges)
   //var dates = getModifiedRanges();
   //var dthisWeek = getModifiedRanges(modifiedRanges.thisWeek);
   //var dlastWeek = getModifiedRanges(modifiedRanges.lastWeek);
   //var dlastMonth = getModifiedRanges(modifiedRanges.lastMonth);

   const initSearchFilter: ContentClassificationXRefChangeLogSearchFilter = {
      page: 1,
      pageSize: 25,
      sortDirection: 'desc',
      sortField: 'modifiedOn',
      modifiedFrom: _modifiedRanges.from,
      modifiedTo: _modifiedRanges.to,
   }

   const defaultFilterValue: Record<searchFilter, IFilteredInfo> = {
      insuranceGroup: undefined,
      payer: undefined,
      practiceId: undefined,
      practiceName: undefined,
      newInsuranceClasses: undefined,
      oldInsuranceClasses: undefined,
      facilityGrouping: undefined,
      modifiedOn: {
         title: 'Modified On',
         value: getDateRangeString([_modifiedRanges.from, _modifiedRanges.to])
      },
      modifiedBy: undefined,
   }

   const [searchFilter, setSearchFilter] = React.useState<ContentClassificationXRefChangeLogSearchFilter>(initSearchFilter);
   const [filteredInfo, setFilteredInfo] = React.useState<Record<searchFilter, IFilteredInfo>>(defaultFilterValue);

   const { apInsurancePayerTypes } = useFetchAPInsurancePayerType(httpGet);
   const { practices } = useFetchContentClassificationXRefPractices(httpGet);
   const { pagedResult, isLoading } = useContentClassificationXrefChangeLogSearch(httpPost, searchFilter);

   React.useEffect(() => {
      removeErrors({ keysLike: _keysLike });
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   React.useEffect(() => {
      const dateRange = getDateRange(filteredInfo.modifiedOn?.value as string);
      const newSearchFilter: ContentClassificationXRefChangeLogSearchFilter = {
         ...searchFilter,
         practiceIds: filteredInfo.practiceId?.values?.map(y => y.value as number),
         practiceName: filteredInfo.practiceName?.value as string,
         payer: filteredInfo.payer?.value as string,
         insuranceGroup: filteredInfo.insuranceGroup?.value as string,
         newInsuranceClasses: filteredInfo.newInsuranceClasses?.values?.map(y => y.value as string),
         oldInsuranceClasses: filteredInfo.oldInsuranceClasses?.values?.map(y => y.value as string),
         facilityGrouping: filteredInfo.facilityGrouping?.value as string,
         modifiedFrom: dateRange?.length > 0 ? dateRange[0] : undefined,
         modifiedTo: dateRange?.length > 1 ? dateRange[1] : undefined,
         modifiedBy: filteredInfo.modifiedBy?.value as string,
      };
      setSearchFilter(newSearchFilter);
      // No, we don't want this to execute when searchFilter changes
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [filteredInfo])

   const handleOnChange: TableProps<ContentClassificationXRefChangeLog>['onChange'] = (pagination, filters, sorter, extra) => {
      const newSearchFilter = {
         ...searchFilter,
         page: pagination?.current
      }
      const theSorter = Array.isArray(sorter) ? sorter[0] as SorterResult<ContentClassificationXRefChangeLog> : sorter as SorterResult<ContentClassificationXRefChangeLog>;
      if (sorter) {

         if (sorter.hasOwnProperty('field')) {
            newSearchFilter.sortField = theSorter.field as string;
            newSearchFilter.sortDirection = theSorter.order?.indexOf('desc') > -1 ? 'desc' : 'asc';
         }
      }
      setSearchFilter(newSearchFilter);
   }

   const apInsurancePayerTypeOptions = React.useMemo(() => {
      return apInsurancePayerTypes?.map(o => {
         return {
            label: o.name,
            value: String(o.name).toUpperCase()
         } as IOptionItem;
      });
   }, [apInsurancePayerTypes]);

   const practiceOptions = React.useMemo(() => {
      return practices?.map(y => {
         return {
            value: y.id,
            label: formatPracticeName(y.name, y.id)
         } as IOptionItem;
      });
   }, [practices]);

   const tableColumns: ColumnsType<ContentClassificationXRefChangeLog> = [
      {
         title: <ColumnFilter
            title='Practice ID'
            filteredInfo={filteredInfo?.practiceId}
            content={<MultiSelectColumnFilter
               options={practiceOptions}
               searchPlaceholder={'Filter by Practice ID'}
               searchable
               allowSelectAll
               setFilteredInfo={setFilteredInfo}
               filteredInfo={filteredInfo}
               filteredInfoKey={'practiceId'}
               filteredInfoTitle='Practice ID'
            />}
         />,
         dataIndex: 'practiceId',
         sorter: true,
         sortDirections: antSortOptions,
         render: (text, record) =>
            <HighlightSearchText
               searchString={filteredInfo?.practiceId?.values.some(y => y.value === record.practiceId) ? String(text) : ''}
               targetString={record.practiceId?.toString()} />
      },
      {
         title: <ColumnFilter
            title='Practice Name'
            filteredInfo={filteredInfo?.practiceName}
            content={<DebouncedBasicInputField
               label={'Filter by Practice Name'}
               placeholder={'Filter by Practice Name'}
               value={filteredInfo.practiceName?.value as string}
               onChange={(e) => {
                  setFilteredInfo({
                     ...filteredInfo,
                     practiceName: {
                        title: 'Practice Name',
                        value: e ? e as string : undefined
                     }
                  })
               }}
            />}
         />,
         dataIndex: 'practiceName',
         sorter: true,
         sortDirections: antSortOptions,
         render: (text, record) =>
            <HighlightSearchText searchString={searchFilter.practiceName} targetString={record.practiceName} />
      },
      {
         title: <ColumnFilter
            title='Facility Grouping'
            filteredInfo={filteredInfo?.facilityGrouping}
            content={<DebouncedBasicInputField
               label='Filter by Facility Grouping'
               placeholder='Filter by Facility Grouping'
               value={filteredInfo.facilityGrouping?.value as string}
               onChange={(e) => {
                  setFilteredInfo({
                     ...filteredInfo,
                     facilityGrouping: {
                        title: 'Facility Grouping',
                        value: e ? e as string : undefined
                     }
                  })
               }}
            />}
         />,
         dataIndex: 'facilityGrouping',
         sorter: true,
         sortDirections: antSortOptions,
         render: (text, record) =>
            <HighlightSearchText searchString={searchFilter.facilityGrouping} targetString={record.facilityGrouping} />
      },
      {
         title: <ColumnFilter
            title='Insurance Group'
            filteredInfo={filteredInfo?.insuranceGroup}
            content={<DebouncedBasicInputField
               label='Filter by Insurance Group'
               placeholder='Filter by Insurance Group'
               value={filteredInfo.insuranceGroup?.value as string}
               onChange={(e) => {
                  setFilteredInfo({
                     ...filteredInfo,
                     insuranceGroup: {
                        title: 'Insurance Group',
                        value: e ? e as string : undefined
                     }
                  })
               }}
            />}
         />,
         dataIndex: 'insuranceGroup',
         sorter: true,
         sortDirections: antSortOptions,
         render: (text, record) =>
            <HighlightSearchText searchString={searchFilter.insuranceGroup} targetString={record.insuranceGroup} />
      },
      {
         title: <ColumnFilter
            title='Payer'
            filteredInfo={filteredInfo?.payer}
            content={<DebouncedBasicInputField
               label='Filter by Payer'
               placeholder='Filter by Payer'
               value={filteredInfo.payer?.value as string}
               onChange={(e) => {
                  setFilteredInfo({
                     ...filteredInfo,
                     payer: {
                        title: 'Payer',
                        value: e ? e as string : undefined
                     }
                  })
               }}
            />}
         />,
         dataIndex: 'payerName',
         sorter: true,
         sortDirections: antSortOptions,
         render: (text, record) =>
            <HighlightSearchText searchString={searchFilter.payer} targetString={`${text}`} />
      },
      {
         title: <ColumnFilter
            title='Old Classification'
            filteredInfo={filteredInfo?.oldInsuranceClasses}
            content={<MultiSelectColumnFilter
               options={apInsurancePayerTypeOptions}
               searchPlaceholder={'Filter by Old Classification'}
               searchable
               allowSelectAll
               setFilteredInfo={setFilteredInfo}
               filteredInfo={filteredInfo}
               filteredInfoKey={'oldInsuranceClasses'}
               filteredInfoTitle='Old Classification'
            />}
         />,
         dataIndex: 'insuranceClassOld',
         sorter: true,
         sortDirections: antSortOptions,
         render: (text, record) =>
            <HighlightSearchText searchString={filteredInfo?.oldInsuranceClasses?.values.some(y => y.value === record.insuranceClassOld) ? String(text) : ''} targetString={record.insuranceClassOld} />
      },
      {
         title: <ColumnFilter
            title='New Classification'
            filteredInfo={filteredInfo?.newInsuranceClasses}
            content={<MultiSelectColumnFilter
               options={apInsurancePayerTypeOptions}
               searchPlaceholder={'Filter by New Classification'}
               searchable
               allowSelectAll
               setFilteredInfo={setFilteredInfo}
               filteredInfo={filteredInfo}
               filteredInfoKey={'newInsuranceClasses'}
               filteredInfoTitle='New Classification'
            />}
         />,
         dataIndex: 'insuranceClassNew',
         sorter: true,
         sortDirections: antSortOptions,
         render: (text, record) =>
            <HighlightSearchText searchString={filteredInfo?.newInsuranceClasses?.values.some(y => String(y.value) === record.insuranceClassNew) ? String(text) : ''} targetString={record.insuranceClassNew} />
      },
      {
         title: <ColumnFilter
            title='Modified By'
            filteredInfo={filteredInfo?.modifiedBy}
            content={<DebouncedBasicInputField
               label='Filter by Modified By'
               placeholder='Filter by Modified By'
               value={filteredInfo.modifiedBy?.value as string}
               onChange={(e) => {
                  setFilteredInfo({
                     ...filteredInfo,
                     modifiedBy: {
                        title: 'Modified By',
                        value: e ? e as string : undefined
                     }
                  })
               }}
            />}
         />,
         dataIndex: 'modifiedBy',
         sorter: true,
         sortDirections: antSortOptions,
         render: (text, record) =>
            <HighlightSearchText searchString={searchFilter.modifiedBy} targetString={`${text}`} />
      },
      {
         title: <ColumnFilter
            title='Modified On'
            filteredInfo={filteredInfo?.modifiedOn}
            placement='bottomLeft'
            content={<BasicDateRangePickerField
               label='Filter by Modified Date'
               containerStyle={{ maxWidth: 300 }}
               defaultFromDate={_modifiedRanges.from }
               defaultToDate={_modifiedRanges.to}
               clearable={true }
               onChangeDateRange={(e) => {
                  setFilteredInfo({
                     ...filteredInfo,
                     modifiedOn: {
                        title: 'Modified On',
                        value: getDateRangeString(e)
                     }
                  })
               }}              
            />}
         />,
         dataIndex: 'modifiedOn',
         defaultSortOrder: 'descend',
         sorter: true,
         sortDirections: antSortOptions,
         render: (text, record) => formatDateTimeString(record.modifiedOn)
      },
   ];

   return (
      <>
         <ApiErrorDisplay
            title='Api Error'
            keysLike={_keysLike}
         />

         <SearchResultsTable
            loading={isLoading}
            rowkey={'id'}
            onChange={handleOnChange}
            columns={tableColumns}
            data={pagedResult?.data}
            serverSideRowCount={pagedResult?.totalRecords}
            currentPage={searchFilter.page}
            currentPageSize={searchFilter.pageSize}
            setCurrentPageSize={newPageSize => {
               setSearchFilter({
                  ...searchFilter,
                  page: 1, //reset the page to keep us on the page
                  pageSize: Number(newPageSize)
               })
            }}
            titleText='Practice Payer Mapping Log'
            fixedHeader={true}
            scrollY={'calc(100vh - 350px)'}
            size='small'
         />
      </>
   );
}
export default ContentClassificationXRefChangeLogList;

