import useSWR, { mutate } from 'swr';
import { Brand, BrandViewModel } from './BrandModel';
import { IStatus } from '../models';
import { HttpError, TGet, TPost, TDelete } from '../../functions/httpClient';
import { useMemo } from 'react';
import { stringComparer } from '../../functions/comparer.functions';
import { IOptionItem } from '../../functions/option.functions';
import { useFetchManufacturer } from '../program/ManufacturerFetcher';
import { useFetchDrug } from './DrugFetcher';
import { useFetchBrandResource } from './BrandResourceFetcher';

export const brandBaseUrl = `api/brand`;
export const brandSaveUrl = `${brandBaseUrl}/Save`;
export const brandDeleteUrl = (id: number) => `${brandBaseUrl}/Delete/${id}`;

const swrOptions = {
   refreshInterval: 0,
   revalidateOnFocus: false,
   dedupingInterval: 60000     //default 2000
};

export function useFetchBrand(httpGet: TGet): IStatus & { brands: Brand[] } {
   const { data, isLoading, error } = useSWR<Brand[], HttpError>(
      brandBaseUrl,
      httpGet,
      { ...swrOptions }
   );

   return {
      brands: data,
      isLoading: isLoading,
      error: error?.message
   };
}

export const saveBrand = async (httpPost: TPost, brand: Brand) => {
   const result = await httpPost(brandSaveUrl, brand);
   mutate(brandBaseUrl);
   return result;
}

export const deleteBrand = async (httpDelete: TDelete, id: number) => {
   const result = await httpDelete(brandDeleteUrl(id));
   mutate(brandBaseUrl);
   return result;
}

export function useBrandOptions(httpGet: TGet): { brandOptions: IOptionItem[] } {
   const { brands } = useFetchBrand(httpGet);

   const options = useMemo(() => {
      if (brands?.length > 0) {

         const sorted = [...brands];
         sorted.sort((a: Brand, b: Brand) => stringComparer(String(a.brandName), String(b.brandName)));

         const options = sorted.map((o): IOptionItem => ({
            key: o.id,
            label: `[${o.id}]: ${o.brandName ?? 'not specified'}`,
            value: o.id
         } as IOptionItem));
         return options;
      }
      return [];
   }, [brands]);

   return {
      brandOptions: options
   };
}

export function useBrandWithManufacturerOptions(httpGet: TGet): { brandWithManufacturerOptions: IOptionItem[] } {
   const { brands } = useFetchBrand(httpGet);
   const { drugs } = useFetchDrug(httpGet);
   const { manufacturers } = useFetchManufacturer(httpGet);

   const options = useMemo(() => {
      if (brands?.length > 0 && manufacturers?.length > 0 && drugs?.length > 0) {
         const sorted = [...brands];
         sorted.sort((a: Brand, b: Brand) => stringComparer(String(a.brandName), String(b.brandName)));

         const options = sorted.map((b): IOptionItem => {
            const d = drugs.find(y => y.brandId === b.id);
            const m = d ? manufacturers.find(y => y.id === d.manufacturerId) : undefined;

            return {
               key: b.id,
               label: `${b.brandName} (${b.id}) (${m?.manufacturerName ?? " - not found - "})`,
               value: b.id
            } as IOptionItem
         });
         return options;
      }
      return [];
   }, [brands, manufacturers, drugs]);

   return {
      brandWithManufacturerOptions: options
   };
}

export function useBrandViewModel(httpGet: TGet): IStatus & { brandViewModels: BrandViewModel[] } {
   const { brands, isLoading: b_isLoading, error: b_error, isSaving } = useFetchBrand(httpGet);
   const { brandResources, isLoading: br_isLoading, error: br_error } = useFetchBrandResource(httpGet);

   const viewModel = useMemo(() => {
      if (brands?.length > 0 && brandResources?.length > 0) {
         let tempList = brands.map((brand): BrandViewModel => {
            const resourcesList = brandResources.filter(br => br.brandId === brand.id);
            return {
               ...brand,
               resources: resourcesList
            } as BrandViewModel;
         });
         return tempList;
      }
      return [];
   }, [brands, brandResources]);

   return {
      brandViewModels: viewModel,
      isLoading: b_isLoading || br_isLoading,
      error: b_error ?? br_error,
      isSaving
   };
}