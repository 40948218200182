import * as React from "react";

import { User, useAuth0, RedirectLoginOptions } from "@auth0/auth0-react";
import { useInsightsContext } from "../InsightsContext";
import { httpPut as put } from "../functions/httpClient";
import { getSettingValue, useFetchSettings } from '../store/SettingsFetcher';
import { KnownSettings } from "../store/SettingsModel";
import { userMainCurrentUserUrl } from '../store/auth/UserMainFetcher';
import { UserMainDetail } from "../store/auth/UserMainModel";

interface LocalUser extends User {
   username: string;
}

interface IAuthContextProps {
   user: LocalUser;
   currentUserMain: UserMainDetail;
   userRoles: string[];
   loading: boolean;
   unauthorizedUser: boolean;
   loginError: string;
   signIn: (redirectPath: string) => Promise<void>;
   signOut: () => Promise<void>;
   getToken: (force?: boolean) => Promise<string>;
}

const defaultAuthContextProps: IAuthContextProps = {
   user: undefined,
   currentUserMain: undefined,
   userRoles: [],
   loading: false,
   unauthorizedUser: false,
   loginError: undefined,
   signIn: undefined,
   signOut: undefined,
   getToken: undefined
}
export const UserContext = React.createContext<IAuthContextProps>(defaultAuthContextProps);
export const useUserContext = () => React.useContext(UserContext);

interface IAuthProviderProps {
   children: React.ReactNode;
}

export const XAuthProvider: React.FC<IAuthProviderProps> = (props) => {
   const { children } = props;

   const [localUser, setLocalUser] = React.useState<LocalUser>();
   const [currentUserMain, setCurrentUserMain] = React.useState<UserMainDetail>();
   const [userRoles, setUserRoles] = React.useState<string[]>();
   const [loading, setLoading] = React.useState<boolean>(false);
   const [loginError, setLoginError] = React.useState<string>();
   const [unauthorizedUser, setUnauthorizedUser] = React.useState<boolean>();

   const { settings } = useFetchSettings();
   const { setAuthenticatedUserContext, logAppInsightsError, logAppInsightsErrorMessage } = useInsightsContext();

   const { logout, error, loginWithRedirect, user, getAccessTokenSilently } = useAuth0();

   React.useEffect(() => {
      const handleLogin = async () => {
         if (user && !localUser) {
            await setUserInContext();
         }
      };

      handleLogin();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [user]);

   interface IUserFromApi {
      localUser: LocalUser;
      currentUserMain: UserMainDetail;
      userRoles: string[];
      unauthorizedUser: boolean;
      loginError: string;
   }

   const setUserInContext = async () => {
      const _userFromApi: IUserFromApi = {
         localUser: undefined,
         currentUserMain: undefined,
         userRoles: undefined,
         unauthorizedUser: true,
         loginError: undefined
      }

      try {
         setLoading(true);

         _userFromApi.currentUserMain = await put<UserMainDetail>(userMainCurrentUserUrl, getToken);
         if (!_userFromApi.currentUserMain) {
            // don't need to break execution, but do want to track lack of usermain
            logAppInsightsErrorMessage('User Main not found');
         }

         if (_userFromApi.currentUserMain) {

            if (_userFromApi?.currentUserMain?.accessRoleIds?.length > 0) {

               _userFromApi.userRoles = settings.filter(y => y.group === "Roles")
                  .filter(x => _userFromApi.currentUserMain.accessRoleIds.includes(Number(x.value)))
                  .map(r => r.key);

               _userFromApi.unauthorizedUser = false;
               _userFromApi.localUser = {
                  ...user,
                  username: user.email ?? user.upn
               };
            } else {
               logAppInsightsErrorMessage('User Not assigned to any roles');
            }
         }
         setAuthenticatedUserContext(user?.upn);

      } catch (err) {
         const e = err as Error;
         _userFromApi.loginError = e?.message ?? 'Error Attempting to set user in context';
         logAppInsightsError(e, "Error setUserInContext:");
         //NOTE: this is something main ap does, but admin portal does not throw a 403
         ////Handle when user is able to log in through AD but there is no corresponding UserProfile record
         ////and one cannot be created automatically
         //if (err.response?.status === 403) {
         //setUnauthorizedUser(true);
         //}

         throw err;
      } finally {
         setLocalUser(_userFromApi.localUser);
         setCurrentUserMain(_userFromApi.currentUserMain);
         setUserRoles(_userFromApi.userRoles);
         setUnauthorizedUser(_userFromApi.unauthorizedUser);
         setLoginError(_userFromApi.loginError);

         setLoading(false);
      }
   }

   const signIn = async (redirectPath: string) => {
      setLoading(true);
      await loginWithRedirect({ appState: { returnTo: redirectPath } });
   }

   const signOut = async () => {
      logout({
         logoutParams: { returnTo: window.location.origin, federated: true },
         clientId: getSettingValue(KnownSettings.ClientId, settings),
      });
   }

   //See httpClient.getToken for this method
   const getToken = async (force: boolean = false) => {
      try {
         const response = force ? await getAccessTokenSilently({ cacheMode: "off" }) : await getAccessTokenSilently();
         return response;
      } catch (e) {
         logAppInsightsError(e as Error, "Error getAccessTokenSilently:");
         await loginWithRedirect();
      }
   }

   return (
      <UserContext.Provider
         value={{
            user: localUser,
            currentUserMain: currentUserMain,
            userRoles,
            loading,
            unauthorizedUser,
            loginError,
            signIn,
            signOut,
            getToken
         }}
      >
         {children}
      </UserContext.Provider>
   );
};
