import * as React from 'react';
import { IMainMenuItem } from '../../store/menu/MainMenu';
import { ISubmenuCategory, ISubmenuCategoryItem } from '../../store/menu/SubMenu';
import FlyoutMenuItem from './FlyoutMenuItem';
import Restricted from '../../auth/Restricted';
import FeatureFlag from '../../auth/FeatureFlag';
import './FlyoutMenu.css';
import CustomIcon from '../../components/shared/AntComponents/CustomIcon';
import { useFlyoutMenu } from './FlyoutMenuContext';

type IProps = {
   isMainMenuHidden: boolean;
   mainMenuItem: IMainMenuItem;
   flyoutMenuItemSelectFunc?: () => void;
}

const FlyoutMenu: React.FC<IProps> = (props) => {
   const { flyoutMenus: submenus } = useFlyoutMenu();

   const index = submenus.findIndex(e => e.itemType === props.mainMenuItem.itemType);
   if (index === -1)
      return null;

   const categories: ISubmenuCategory[] = submenus[index].categories;

   return (
      <div className={`flyout-menu ${props.isMainMenuHidden ? 'hidden-main' : ''} `}>
         <div className="flyout-menu-type">
            <h3 className="heading_a uk-text-muted">{props.mainMenuItem.label}</h3>
         </div>
         <div className="flyout-menu-items">
            <ul>
               {categories && categories.map((subMenu: ISubmenuCategory, index: number) => {
                  return (
                     <FeatureFlag feature={subMenu.featureFlag} key={"SMCIFF" + index}>
                        <Restricted requiredRoles={subMenu.allowedRoles} key={"SM" + index}>
                           <>
                              <li>
                                 <div className="submenu-category">
                                    {subMenu.labelIconName &&
                                       <span className="flyout-menu-icon">
                                          <CustomIcon type={subMenu.labelIconName} />
                                       </span>
                                    }
                                    <span className="flyout-menu-title">
                                       {subMenu.label}
                                    </span>
                                 </div>
                              </li>
                              {
                                 subMenu.items.map((categoryItem: ISubmenuCategoryItem, subindex: number) => {
                                    return (
                                       <FeatureFlag feature={categoryItem.featureFlag} key={"SMIFF" + subindex}>
                                          <Restricted requiredRoles={categoryItem.allowedRoles} key={"SMI" + subindex}>
                                             <li>
                                                <FlyoutMenuItem handleClick={props.flyoutMenuItemSelectFunc} flyoutMenuItem={categoryItem} />
                                             </li>
                                          </Restricted>
                                       </FeatureFlag>)
                                 })
                              }
                           </>
                        </Restricted>
                     </FeatureFlag>
                  )
               }
               )}
            </ul>
         </div>
      </div>
   );
}

export default FlyoutMenu;
