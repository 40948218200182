import { Typography } from 'antd';
import * as React from 'react';
import colorWheel from '../../../../Theme/ColorWheel';
const { Title } = Typography;

interface NumberedTitleProps {
    level: 1 | 2 | 3 | 4 | 5;
    text?: string;
    style?: React.CSSProperties;
    children?: React.ReactNode;
}

const defaultStyle = {
    color: colorWheel.textGrey,
    fontWeight: 500,
    margin: 0,
    padding: 0
};

export const TitleError: React.FC<{ text: string }> = ({ text }) => <Title level={5} type='danger'>{text}</Title>

export const NumberedTitle: React.FC<NumberedTitleProps> = (props) =>
    <Title level={props.level} style={props?.style ?? defaultStyle}>{props.text}{props.children}</Title>
