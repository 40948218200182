import * as React from 'react';
import {
   checkFileExists,
   useFetchDashboardContent,
   saveDashboardContent,
   deleteDashboardContent,
   dashboardContentBaseUrl,
   uploadDashboardContentFile,
   dashboardContentUploadFileUrl,
   dashboardContentDeleteUrl
} from '../../store/dashboard/DashboardContentFetcher';
import { DeleteButton, SaveButton, CancelButton } from '../shared/Buttons';
import { Row, Col, Form } from 'antd';
import Dialog from '../Dialog';
import { BasicFieldWrapper } from '../shared/BasicInputLibrary';
import { DashboardSection, DashboardContent } from '../../store/dashboard/DashboardContentModel';
import { CustomIconType } from '../shared/AntComponents/CustomIcon';
import { TitleError } from '../shared/AntComponents/Typography/Title';
import ApiErrorDisplay from '../ApiErrorDisplay';
import { useApiContext } from '../../store/ApiContext';
import Spinner from '../Spinner';
import { FormFileUploadDragger, MAX_UPLOAD_FILE_SIZE_MB } from '../shared/AntComponents/FileUploadDragger';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { CheckboxField, DatePickerField, InputField } from '../shared/InputLibrary';
import { HttpVerb, KeyWithVerb, useErrorContext } from '../../store/ErrorContext';

interface IProps {
   selectedItemId: number;
   onCloseEditor?: () => void;
   isEditorOpen: boolean;
}

const dialogContentStyle = {
   height: '400px',
   maxHeight: '95vh',
}

const dashboardContentYup = yup.object({
   id: yup.number().notRequired(),
   name: yup.string().required('Name is required'),
   /* So, a file is required to save - but when editing an existing record we don't want to go grab the file, though we will have the URL value from the DB
    * Hence having "url" required while the "file" isn't.  However, we don't have a "url" field anywhere, just the picker for the file (so that's why you'll see "setValue('url'...)" in the onchange for the picker)
    * So we feed the picker a composite set of errors built from "urlFile" and "url" as we assign custom errors to "urlFile" while yup
    * assigns this "is required" to "url".  If we try to make "urlFile" required, then we need to go retrieve the file... */
   urlFile: yup.mixed().notRequired(),
   url: yup.string().required('A File for Url is required'),
   thumbnailUrlFile: yup.mixed().notRequired(),
   thumbnailURL: yup.string().notRequired(),
   description: yup.string().notRequired(),
   isNew: yup.boolean().notRequired(),
   contentType: yup.string().notRequired(),
   sortOrder: yup.number().notRequired(),
   duration: yup.string().notRequired(),
   isDeleted: yup.boolean().notRequired(),
   createdBy: yup.string().notRequired(),
   createdOn: yup.date().notRequired(),
   modifiedBy: yup.string().notRequired(),
   modifiedOn: yup.date().notRequired()
})

interface IDashboardContentYup extends yup.Asserts<typeof dashboardContentYup> { }


const __formId = 'frmDashboardContentEditor';
const defaultDashboardContent: DashboardContent = { dashboardSection: DashboardSection.CurrentIntegration } as DashboardContent;

const _keysWithVerb: KeyWithVerb[] = [{key: dashboardContentBaseUrl, verb: HttpVerb.POST}];

const DashboardContentEditor: React.FC<IProps> = (props) => {
   const { isEditorOpen, selectedItemId, onCloseEditor } = props;
   const _keys = selectedItemId ? [dashboardContentUploadFileUrl, dashboardContentDeleteUrl(selectedItemId)] : [dashboardContentUploadFileUrl];
   const { httpGet, httpPutFile, httpPost, httpDelete } = useApiContext();
   const { removeErrors } = useErrorContext();
   const [isFormSaving, setIsFormSaving] = React.useState<boolean>(false);
   const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState<boolean>(false);
   
   
   const { dashboardContentList } = useFetchDashboardContent(httpGet);

   const {
      control,
      handleSubmit,
      reset,
      setError,
      clearErrors,
      setValue,
      getValues,
      trigger,
      formState: { errors }
   } = useForm<IDashboardContentYup>({
      resolver: yupResolver<yup.AnyObject>(dashboardContentYup),
      shouldFocusError: true,
   });

   React.useEffect(() => {
      if (selectedItemId && dashboardContentList) {
         reset(dashboardContentList.find(dc => dc.id === selectedItemId) ?? defaultDashboardContent);
      } else {
         reset(defaultDashboardContent);
      }
      // for "reset(...)"
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [selectedItemId, dashboardContentList])

   const handleSave = (data: IDashboardContentYup) => {
      let anyErrors = false;
      setIsFormSaving(true);

      const dashboardContent = data as DashboardContent;

      // The file objects are part of the form, but aren't part of the DashboardContent shipped to save
      if (data.urlFile) {
         const converted = data.urlFile as File;
         dashboardContent.url = converted?.name;

         uploadDashboardContentFile(httpPutFile, converted)
            .catch((err) => {
               console.error('Error Uploading File', err);
               anyErrors = true
            })
      }

      if (data.thumbnailUrlFile) {
         const converted = data.thumbnailUrlFile as File;
         dashboardContent.thumbnailURL = converted?.name;

         uploadDashboardContentFile(httpPutFile, converted)
            .catch((err) => {
               console.error('Error Uploading File', err);
               anyErrors = true
            })
      }

      // dashboard section property will unexpectedly go missing
      dashboardContent.dashboardSection = dashboardContent.dashboardSection ?? DashboardSection.CurrentIntegration;

      saveDashboardContent(httpPost, dashboardContent)
         .then(() => {
            if (!anyErrors) handleClose();
         })
         .catch(err => console.error('Error Saving Dashbord Content'))
         .finally(() => {
            setIsFormSaving(false);
         });
   };

   const handleDeleteConfirmation = () => {
      setValue('isDeleted', true);
      deleteDashboardContent(httpDelete, getValues().id)
         .then(handleClose)
         .catch(err => console.error('Error Deleting Dashboard Content'))
         .finally(() => {
            setIsDeleteDialogOpen(false);
         });
   }

   const handleClose = () => {
      removeErrors({keys: _keys, keysWithVerb: _keysWithVerb})
      onCloseEditor();
   }

   const setUrlMetaData = (file: File) => {
      let mime = file.type;
      if (mime.startsWith("video")) {
         // Find duration
         let rd = new FileReader();
         let duration: string;
         rd.onload = function (e) {

            var blob = new Blob([e.target.result], { type: mime }),
               url = (URL || webkitURL).createObjectURL(blob),
               video = document.createElement("video");

            video.preload = "metadata";
            video.addEventListener("loadedmetadata", function () {
               if (video.duration && !isNaN(video.duration)) {
                  var minutes = parseInt((video.duration / 60).toString(), 10);
                  var seconds = parseInt((video.duration % 60).toString(), 10);
                  duration = minutes.toString() + ':' + seconds;
                  setValue('duration', duration);
                  setValue('contentType', 'Video');
               }
               (URL || webkitURL).revokeObjectURL(url);

            });
            video.src = url;
         };
         rd.readAsArrayBuffer(file);
      }
      else if (mime.startsWith("image")) {
         setValue('duration', null);
         setValue('contentType', 'Image');
      }
   };

   const checkFileName = (fileName: string) => {
      checkFileExists(httpPost, fileName, false)
         .then(fileExists => {
            if (fileExists) {
               setError('url', { type: 'Customer', message: 'File with the same name already exist for url'})
            } else {
               clearErrors('url')
            }
         });
   }

   if (isFormSaving) {
      return <Spinner message='Saving Dashboard Content...' />
   }

   const preventDefaultRequest = () => {
      /* for this component, we want the upload to occur on the upload button click event.
      This dummy request is passed into the customRequest prop of the dragger
      preventing the antd default behavior of trying to upload a documnt on file selection.
      */
   };

   // See above in the yup schema around "url" & "urlFile" for why this was done
   const getErrorsForUrlUpload = () => {
      if (errors) {
         if (errors?.url && errors?.urlFile) {
            return [errors.url, errors.urlFile]
         }
         if (errors?.url && !errors?.urlFile) {
            return errors.url;
         }
         if (!errors?.url && errors?.urlFile) {
            return errors.urlFile;
         }
      }
      return undefined;
   }

   const html = (
      <Dialog
         scrollingContent={true}
         title={'Dashboard Content Editor'}
         open={isEditorOpen}
         size='large'
         actionButtons={
            <>
               <CancelButton onClick={handleClose} />
               {!getValues()?.isDeleted && getValues()?.id > 0 &&
                  <DeleteButton onClick={() => setIsDeleteDialogOpen(true)} />
               }
               {!getValues().isDeleted &&
                  <SaveButton
                  loading={isFormSaving}
                  disabled={Object.keys(errors).length > 0}
                  onClick={() => null}
                  formId={__formId}
                  />
               }
            </>
         }
      >
         <div style={dialogContentStyle}>
            {errors && Object.keys(errors).length > 0 &&
               <>
                  <TitleError
                     text='Please correct the errors below'
                  />
                  {console.log('--------------Form Errors: -------------------------')}
                  {console.log(errors)}
               </>
            }

            <ApiErrorDisplay
               title='Error saving Dashboard Content'
               keys={_keys}
               keysWithVerb={_keysWithVerb}
               
            />

            <Form id={__formId} onFinish={handleSubmit(handleSave)}>
               <Row gutter={[16, 16]}>
                  <Col span={8}>
                     <InputField
                        control={control}
                        error={errors?.name}
                        name='name'
                        label='Name'
                        disabled={getValues()?.isDeleted}
                     />
                  </Col>
                  <Col span={16}>
                     <InputField
                        control={control}
                        error={errors?.description}
                        name='description'
                        label='Description'
                        disabled={getValues()?.isDeleted}
                     />
                  </Col>
               </Row>
               <Row gutter={[16, 16]}>
                  <Col span={4}>
                     <CheckboxField
                        control={control}
                        error={errors?.isNew}
                        name='isNew'
                        label='Is New?'
                        disabled={getValues()?.isDeleted}
                     />
                  </Col>
               </Row>
               <Row gutter={[16, 16]}>
                  <Col span={12} >
                     <BasicFieldWrapper label={'URL'} field={
                        <FormFileUploadDragger
                           control={control}
                           name={'urlFile'}
                           error={getErrorsForUrlUpload()} // See above in the yup schema around "url" & "urlFile" for why this was done
                           trigger={trigger}
                           setError={setError}
                           clearErrors={clearErrors}
                           accept=".jpg,.jpeg,.gif,.bmp,.png,.avi,.mp4,.3gp,.mpg,.mov,.mkv,.wmv,.flv"
                           icon={CustomIconType.AntCloudOutlined}
                           maximumFileSizeMB={MAX_UPLOAD_FILE_SIZE_MB}
                           onChange={(e) => {
                              if (e) {
                                 checkFileName(e.name);
                                 setValue('url', e.name, { shouldValidate: true }); // See above in the yup schema around "url" & "urlFile" for why this was done
                                 setUrlMetaData(e);
                              }
                           }}
                           containerWidth={400}
                           fileNameOverride={getValues()?.url}
                           customRequest={preventDefaultRequest}
                        />}
                     />
                  </Col>
                  <Col span={12}>
                     <BasicFieldWrapper label={'Thumbnail Url'} field={
                        <FormFileUploadDragger
                           control={control}
                           name={'thumbnailUrlFile'}
                           error={errors?.thumbnailUrlFile}
                           trigger={trigger}
                           setError={setError}
                           clearErrors={clearErrors}
                           accept=".jpg,.jpeg,.gif,.bmp,.png,.avi,.mp4,.3gp,.mpg,.mov,.mkv,.wmv,.flv"
                           icon={CustomIconType.AntCloudOutlined}
                           maximumFileSizeMB={MAX_UPLOAD_FILE_SIZE_MB}
                           onChange={(e) => {
                              if (e) {
                                 setValue('thumbnailURL', e.name, { shouldValidate: true });
                              }
                           } }
                           containerWidth={400}
                           fileNameOverride={getValues()?.thumbnailURL}
                           customRequest={preventDefaultRequest}
                        />}
                     />
                  </Col>
               </Row>
               <Row gutter={[16, 16]}>
                  <Col span={6}>
                     <InputField
                        control={control}
                        error={errors?.contentType}
                        name='contentType'
                        label='Content Type'
                        disabled={true}
                     />
                  </Col>
                  <Col span={4}>
                     <InputField
                        control={control}
                        error={errors?.duration}
                        name='duration'
                        label='Duration'
                        disabled={true}
                     />
                  </Col>
               </Row>
               <Row gutter={[16, 16]}>
                  <Col span={6}>
                     <InputField
                        control={control}
                        error={errors?.createdBy}
                        name='createdBy'
                        label='Created By'
                        disabled={true}
                     />
                  </Col>
                  <Col span={6}>
                     <DatePickerField
                        control={control}
                        error={errors?.createdOn}
                        name='createdOn'
                        label='Created On'
                        disabled={true}
                     />
                  </Col>
                  <Col span={6}>
                     <InputField
                        control={control}
                        error={errors?.modifiedBy}
                        name='modifiedBy'
                        label='Modified By'
                        disabled={true}
                     />
                  </Col>
                  <Col span={6}>
                     <DatePickerField
                        control={control}
                        error={errors?.modifiedOn}
                        name='modifiedOn'
                        label='Modified On'
                        disabled={true}
                     />
                  </Col>
               </Row>
            </Form>
         </div>

         <Dialog
            title='CONFIRM DELETE'
            open={isDeleteDialogOpen}
            style={{ maxWidth: '500px' }}
            actionButtons={
               <>
                  <CancelButton onClick={() => setIsDeleteDialogOpen(false)} />
                  <DeleteButton
                     hasIcon={false}
                     buttonText='Delete Dashboard Content'
                     onClick={() => handleDeleteConfirmation()} />

               </>
            }
         >
            {`Are you sure you want to Delete Dashboard Content (${getValues()?.id}) ${getValues()?.name || ''}?`}
         </Dialog>
      </Dialog>
   );

   return html;
}

export default DashboardContentEditor;