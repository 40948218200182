import useSWR from 'swr';
import { HttpError, TPost } from '../../functions/httpClient';
import { getFormattedDateForApi } from '../../functions/format.functions';
import { FundStatusRecentlySentEmail } from './FundingStatusEmailSentModel';
import { addDate } from '../../functions/common.functions';

export const recentlySentSessionKey = 'RECENTLY_SENT_MUTATE_VALUE';
const recentlySentBaseUrl = `/api/fsa/RecentlySent`;
export const getRecentlySentEmailUrl = `${recentlySentBaseUrl}/GetRecentlySentEmails`;

const swrOptions = {
   refreshInterval: 0,
   revalidateOnFocus: false,
   dedupingInterval: 60000     //default 2000
};


export const useGetRecentFundEmails = (httpPost: TPost) => {
   const { data, isValidating, error, mutate } = useSWR<FundStatusRecentlySentEmail[], HttpError>(
      getRecentlySentEmailUrl,
      httpPost,
      { ...swrOptions }
   );

   return {
      recentEmails: data,
      isLoading: !error && !data && isValidating,
      error: error as HttpError,
      recentEmailsMutator: mutate
   };
}

export const GetRecentlySentDate = (): string => {
   return getFormattedDateForApi(addDate(new Date(), -3));
}