import { ColumnsType } from 'antd/lib/table';
import * as React from 'react';
import Restricted from '../../auth/Restricted';
import { stringComparer } from '../../functions/comparer.functions';
import { useApiContext } from '../../store/ApiContext';
import { KnownSettings } from '../../store/SettingsModel';
import { useFetchPracticeSettings } from '../../store/practice/PracticeSettingsFetcher';
import { PracticeSettings } from '../../store/practice/PracticeSettingsModel';
import MinimalistTable from '../shared/AntComponents/Table/MinimalistTable';
import { EditButton } from '../shared/Buttons';
import PracticeSettingsEditor from './PracticeSettingsEditor';

interface IProps {
   practiceId: number;
   scrollYValue: string;
}

export const PracticeSettingsEmbeddedList: React.FC<IProps> = (props) => {
   const { httpGet } = useApiContext();
   const { practiceSettings: practiceSettingsFromStore } = useFetchPracticeSettings(httpGet);

   const [practiceSettings, setPracticeSettings] = React.useState<PracticeSettings[]>([]);
   const [practiceSettingsToEdit, setPracticeSettingsToEdit] = React.useState<PracticeSettings>(undefined);
   const [isPracticeSettingsEditorOpen, setIsPracticeSettingsEditorOpen] = React.useState<boolean>(false);

   React.useEffect(() => {
      if (!practiceSettingsFromStore || !props.practiceId) return;

      const models = practiceSettingsFromStore.filter(y => y.practiceId === props.practiceId);

      /* AH-4188 wants all available Settings displayed so now that we have the ones for this Practice, we need to grab any that haven't been assigned to it yet
       * We can skip the ones we've already grabbed though */
      practiceSettingsFromStore.filter(y => y.practiceId !== props.practiceId)
         .forEach(ps => {
            if (!models.some(y => y.name === ps.name)) {
               models.push({ name: ps.name } as PracticeSettings)
            }
         });
      setPracticeSettings(models.sort((a, b) => stringComparer(a.name, b.name)));
   }, [practiceSettingsFromStore, props.practiceId])

   const onPracticeSettingsEditorClose = () => {
      setPracticeSettingsToEdit(undefined);
      setIsPracticeSettingsEditorOpen(false);
   }

   const formatPracticeSettingValueDisplay = (value: string): string => {
      switch (value?.toLowerCase()) {
         case 'true':
            return 'Enabled';
         case 'false':
            return 'Disabled';
         default:
            return value;
      }
   }

   const settingsColumns: ColumnsType<PracticeSettings> = [
      {
         title: 'Name',
         dataIndex: 'name',
         key: 'name',
         width: 225
      },
      {
         title: 'Value',
         dataIndex: 'value',
         key: 'value',
         render: (val: string) => formatPracticeSettingValueDisplay(val)
      },
      {
         width: 75,
         render: (text, record) =>
            <Restricted requiredRoles={[KnownSettings.ContentAdmin]}>
               <EditButton
                  onClick={() => {
                     setPracticeSettingsToEdit(record);
                     setIsPracticeSettingsEditorOpen(true);
                  }}
                  title='Edit' />
            </Restricted>
      }
   ];

   return (
      <>
         <MinimalistTable
            rowKey='id'
            data={practiceSettings}
            columns={settingsColumns}
            size='small'
            showHeader={true}
            scrollY={props.scrollYValue}
         />

         {isPracticeSettingsEditorOpen &&
            <PracticeSettingsEditor
               practiceId={props.practiceId}
               closeEditor={onPracticeSettingsEditorClose}
               isOpen={isPracticeSettingsEditorOpen}
               settingsName={practiceSettingsToEdit?.name}
               practiceSettingsId={practiceSettingsToEdit?.id}
            />
         }
      </>
   );

}

export default PracticeSettingsEmbeddedList