import * as React from 'react';
import { AssistanceProgramResource } from '../../../store/program/AssistanceProgramResourceModel';
import { useFetchAssistanceProgramResourceCategories } from '../../../store/program/AssistanceProgramResourceFetcher';
import { AddButton, EditButton } from '../../shared/Buttons';
import { AssistanceProgram } from '../../../store/program/AssistanceProgramModel';
import AssistanceProgramResourceEditor from './AssistanceProgramResourceEditor';
import { ColumnsType } from 'antd/lib/table';
import MinimalistTable from '../../shared/AntComponents/Table/MinimalistTable';
import Restricted from '../../../auth/Restricted';
import { KnownSettings } from '../../../store/SettingsModel';
import { useApiContext } from '../../../store/ApiContext';

interface IExpandedResourceProps {
   assistanceProgram: AssistanceProgram;
   assistanceProgramResourcesForProgram: AssistanceProgramResource[];
   setSelectedAssistanceProgramId: (id: number) => void;

   selectedAssistanceProgramResourceId: number;
   setSelectedAssistanceProgramResourceId: (id: number) => void;
   closeEditor: () => void;
}

const AssistanceProgramResourceRow: React.FC<IExpandedResourceProps> = (props) => {
   const { httpGet } = useApiContext();
   const [isAssistanceProgramResourceEditorOpen, setIsAssistanceProgramResourceEditorOpen] = React.useState<boolean>(false);
   const { assistanceProgramResourceCategories } = useFetchAssistanceProgramResourceCategories(httpGet);

   const { assistanceProgram,
      assistanceProgramResourcesForProgram, setSelectedAssistanceProgramId, 
      selectedAssistanceProgramResourceId, setSelectedAssistanceProgramResourceId,
      closeEditor
   } = props;

    const handleCloseClick = (assistanceProgramId?: number,
        assistanceResourceId?: number) => {
        setIsAssistanceProgramResourceEditorOpen(false);
        closeEditor();
    }

    const columns: ColumnsType<AssistanceProgramResource> = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 1
        },
        {
            title: 'Assistance Program',
            dataIndex: '',
            key: 2,
            render: () => {
                return <> {`[${assistanceProgram.id}] ${assistanceProgram.programName}`} </>
            }
        },
        {
            title: 'Type',
            dataIndex: '',
            key: 3,
            render: (text, record) => {
                const type = record?.type === 0 ? 'Enrollment Link' :
                    (record?.type === 1 ? 'More Information' : '');                
                return <> {`[${record.type}] ${type}`} </>
            }
        },
        {
            title: 'URL',
            dataIndex: '',
            key: 4,
            render: (text, record) => {
                return <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
                    {record.url && <a href={record.url} target='_blank' rel='noopener noreferrer'>{record.url}</a>}
                </div>
            }
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 5
        },
        {
            title: 'Category',
            dataIndex: '',
            key: 6,
            render: (text, record) => {
                const category = assistanceProgramResourceCategories?.find(y => y.id === record.assistanceProgramResourceCategoryId)?.category
                return <> {category} </>
            }
        },
        {
           title: <Restricted requiredRoles={[KnownSettings.ContentAdmin]}>
              <AddButton title='Add' buttonText='New Resource' onClick={() => {
                    setSelectedAssistanceProgramId(assistanceProgram.id);
                    setSelectedAssistanceProgramResourceId(undefined);
                    setIsAssistanceProgramResourceEditorOpen(true);
              }} />
            </Restricted>,
            dataIndex: '',
            key: 7,
            render: (text, record) =>
               <Restricted requiredRoles={[KnownSettings.ContentAdmin]}>
                  <EditButton title='Edit' onClick={() => {
                    setSelectedAssistanceProgramId(assistanceProgram.id);
                    setSelectedAssistanceProgramResourceId(record.id);
                    setIsAssistanceProgramResourceEditorOpen(true);
                  }} />
               </Restricted>
        }
    ]

    const html = (
        <>
            <MinimalistTable
                rowKey='id'
                columns={columns}
                data={assistanceProgramResourcesForProgram}
                showHeader={true}
            />

            <AssistanceProgramResourceEditor
                assistanceProgramId={assistanceProgram.id}
                assistanceProgramResourceId={selectedAssistanceProgramResourceId}
                isOpen={isAssistanceProgramResourceEditorOpen}
                closeEditor={handleCloseClick} />
        </>
    );

    return html;
}

export default AssistanceProgramResourceRow;
