import * as React from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { Space } from 'antd';
import { ActionButton } from '../components/shared/Buttons';
import Dialog from '../components/Dialog';
import { useUserContext } from './authContext';

const TIMEOUT_LIMIT = 1000 * 60 * 30; //30 minutes
const EXTEND_WINDOW_LIMIT = 1000 * 60 * 10; //10 minutes

interface Props {
   signOut: () => void;
}

const IdleSession = (props: Props) => {
   const { signOut } = props;
   const [isUserIdle, setIsUserIdle] = React.useState<boolean>(false);
   const [sessionExpirationDate, setSessionExpirationDate] = React.useState<number>(undefined);
   const [sessionExpirationTimer, setSessionExpirationTimer] = React.useState<NodeJS.Timeout>(undefined);
   const [sessionExpirationTimespan, setSessionExpirationTimespan] = React.useState<number>(undefined);
   const { getToken } = useUserContext();

   //When user puts computer to sleep onIdle event is not fired - track time user was last active 
   //so we know whether to skip 10 minute window where user can extend session
   const [lastActiveDate, setLastActiveDate] = React.useState<number>(Date.now());

   //React.useEffect(() => {
   //   console.log(`IdleSession mounted`);
   //}, []);

   React.useEffect(() => {
      //If user is no longer idle clear countdown timer
      if (!isUserIdle) {
         setSessionExpirationDate(null);
         clearInterval(sessionExpirationTimer);
         return;
      }
      //console.log(`IdleSession: ${isUserIdle}`);
      const countdownTimer = setInterval(() => {
         setSessionRemainingTime(undefined);
      }, 1000);
      setSessionExpirationTimer(countdownTimer);
   },
      // Suppressing this warning as only useState values are affected
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [isUserIdle]);

   const handleOnIdle = () => {
      const now = Date.now();
      //Check if user is already outside window where session could be extended
      const absoluteExpirationDate = lastActiveDate + TIMEOUT_LIMIT + EXTEND_WINDOW_LIMIT;
      if (absoluteExpirationDate < now) {
         //console.log(`IdleSession: handleOnIdle-Signout`);
         if (signOut) signOut();
      } else {
         const expirationDate = now + EXTEND_WINDOW_LIMIT; //Window where user can continue session
         //console.log(`IdleSession: expirationDate: ${expirationDate}`);
         setSessionExpirationDate(expirationDate);
         setSessionRemainingTime(expirationDate);
         setIsUserIdle(true);
      }

   }

   const setSessionRemainingTime = (expirationDate: number) => {
      const startDate = sessionExpirationDate || expirationDate;
      const now = Date.now();
      const remainingTime = startDate - now;

      if (remainingTime < 1000) {
         //console.log(`IdleSession: setSessionRemainingTime-Signout`);
         if (signOut) signOut();
      }
      //A2-1003: ensure negative numbers are not displayed
      if (remainingTime >= 0) {
         setSessionExpirationTimespan(remainingTime);
      }
   }

   const handleOnAction = () => {
      // console.log(`IdleSession: handleOnAction`);
      setLastActiveDate(Date.now());
   }

   const handleSessionActivity = async () => {
      const freshAccessToken = await getToken(true);
   };

   //events specified as the power bi reports were not timing out as expected with default settings
   useIdleTimer({
      timeout: TIMEOUT_LIMIT,
      onIdle: handleOnIdle,
      onAction: handleOnAction,
      debounce: 500,
      //events: ['blur', 'click', 'change', 'keydown', 'mousedown', 'mousewheel', 'scroll', 'visibilitychange', 'wheel']
      events: ['click', 'keydown', 'mousedown', 'mousewheel', 'scroll', 'touchmove', 'touchstart', 'visibilitychange', 'wheel']
   });

   if (!isUserIdle) {
      return null;
   }

   const timeVerbiage = getRemainingTimeVerbiage(sessionExpirationTimespan);

   return <Dialog
      title="Session Expiration Warning"
      open={isUserIdle}
      actionButtons={
         <Space>
            <ActionButton buttonText='End Session' onClick={() => signOut()} danger={true} />
            <ActionButton buttonText='Yes' onClick={() => {
               setIsUserIdle(false);
               handleSessionActivity();
            }} />
         </Space>
      }>
      <div>
         <span style={{ fontSize: '16', fontWeight: '600' }}>{`Due to inactivity on this site your session will expire in ${timeVerbiage}.  Do you want to keep this session alive?`}</span>
      </div>
   </Dialog>
}

const getRemainingTimeVerbiage = (remainingTime: number) => {
   const expireTime = new Date(remainingTime);
   const minutes = expireTime.getMinutes();
   const seconds = expireTime.getSeconds();

   let verbiage = '';

   if (minutes > 0) {
      verbiage += minutes;
      if (minutes === 1) {
         verbiage += ' minute ';
      } else {
         verbiage += ' minutes '
      }

      verbiage += 'and ';
   }

   verbiage += seconds;
   if (seconds === 1) {
      verbiage += ' second'
   } else {
      verbiage += ' seconds'
   }

   return verbiage;
}

export default IdleSession;
