import * as React from "react";
import {
   useFoundationFundingStatusListById
   , fundStatusAsSelectOptions
   , findStatusEntry
   , compareFoundationFundingStatusWithNames
} from '../../store/program/FundingStatusFetcher';
import { FundingStatus, FundingStatusWithNames } from '../../store/program/FundingStatusModel';
import { getInsuranceClassById, useFetchInsuranceClassesActive, } from '../../store/program/InsuranceClassFetcher';
import { InsuranceClass } from '../../store/program/InsuranceClassModel';
import { useFetchAssistancePrograms, getAssistanceProgramById } from '../../store/program/AssistanceProgramFetcher';
import TruncateText from '../shared/TruncateText';
import { ColumnsType } from 'antd/lib/table';
import { Select } from 'antd';
import { formatDateTimeString } from "../../functions/format.functions";
import MinimalistTable from '../shared/AntComponents/Table/MinimalistTable';
import { useFetchAssistanceServicesWithFoundations, getAssistanceServiceById } from "../../store/program/AssistanceServiceFetcher";
import { useApiContext } from "../../store/ApiContext";
import { AssistanceServiceWithFoundation } from "../../store/program/AssistanceServiceModel";

type Props = {
   changes: FundingStatus[],
   updateChanges: (entry: FundingStatus) => void,
   diseaseTypeId: number,
   foundationId: number,
};

const FundingStatusCtrl: React.FunctionComponent<Props> = (props: Props) => {
   const { httpGet } = useApiContext();
   const { fundingStatusList: fundingStatuses } = useFoundationFundingStatusListById(httpGet, props.diseaseTypeId);
   const { assistancePrograms } = useFetchAssistancePrograms(httpGet);
   const { assistanceServiceWithFoundations: assistanceServiceWithFoundationFromStore } = useFetchAssistanceServicesWithFoundations(httpGet);
   const [assistanceServiceWithFoundations, setAssistanceServiceWithFoundations] = React.useState<AssistanceServiceWithFoundation[]>([]);

   React.useEffect(() => {
      setAssistanceServiceWithFoundations(assistanceServiceWithFoundationFromStore?.filter(y => y.foundationId === props.foundationId));
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [assistanceServiceWithFoundationFromStore]);


   const { insuranceClasses } = useFetchInsuranceClassesActive(httpGet);

   let fundingStatusOptions: FundingStatusWithNames[] = [];
   if (assistanceServiceWithFoundations && insuranceClasses && fundingStatuses) {

      assistanceServiceWithFoundations?.forEach((as: AssistanceServiceWithFoundation) => (
         insuranceClasses?.forEach((i: InsuranceClass) => {
            let statusRecord: FundingStatusWithNames = {
               ...fundingStatuses.find((fs) =>
                  fs.assistanceProgramId === as.assistanceProgramId
                  && fs.assistanceServiceId === as.id
                  && fs.insuranceClassId === i.insuranceClassId
               ) as FundingStatusWithNames
            };

            // set service description for status with an id
            statusRecord.serviceDescription = as.description;

            if (!statusRecord.id) {
               statusRecord = {
                  id: -1,
                  assistanceProgramId: as.assistanceProgramId,
                  assistanceServiceId: as.id,
                  foundationDiseaseTypeId: props.diseaseTypeId,
                  insuranceClassId: i.insuranceClassId,
                  status: -1,
                  modifiedOn: undefined,
                  modifiedBy: undefined,
                  lastChecked: undefined,
                  externalId: undefined,
                  serviceDescription: as.description
               } as FundingStatusWithNames;
            }

            statusRecord.assistanceProgramName = getAssistanceProgramById(statusRecord.assistanceProgramId, assistancePrograms)?.programName;
            statusRecord.assistanceServiceName = getAssistanceServiceById(statusRecord.assistanceServiceId, assistanceServiceWithFoundations)?.assistanceServiceName;
            statusRecord.insuranceClassName = getInsuranceClassById(statusRecord.insuranceClassId, insuranceClasses)?.name;

            fundingStatusOptions.push(statusRecord);
         })
      ))
   }

   return <FundingStatusRows fundingStatuses={fundingStatusOptions} changes={props.changes} updateParentChanges={props.updateChanges} />
}
export default FundingStatusCtrl;


type FundingRowProps = {
   changes: FundingStatus[],
   fundingStatuses: FundingStatusWithNames[],
   updateParentChanges: (entry: FundingStatus) => void
};

interface ProgramTable {
   assistanceProgramId: number;
   assistanceProgramName: string;
}

interface ServiceTable {
   assistanceProgramId: number;
   assistanceServiceId: number;
   assistanceServiceName: string;
}

export const FundingStatusRows: React.FunctionComponent<FundingRowProps> = (props: FundingRowProps) => {
   const orderedFundingStatuses: FundingStatusWithNames[] =
      props.fundingStatuses.sort(compareFoundationFundingStatusWithNames);

   // keygen functions to consolidate logic in one place
   const getProgramKey = (id: number): string => "" + id;
   const getServiceKey = (programId: number | string, serviceId: number | string): string => "" + programId + "|" + serviceId;


   let programRowSpans: any = {};
   let prevProgramKey: string = getProgramKey(orderedFundingStatuses[0]?.assistanceProgramId); // need to initialize for 1st pass in map below
   let programCount: number = 0;

   let serviceRowSpans: any = {};
   let serviceCount: number = 0;
   let prevServiceKey: string = getServiceKey(prevProgramKey, orderedFundingStatuses[0]?.assistanceServiceId); // need to initialize for 1st pass in map below

   orderedFundingStatuses.forEach((fs, index, arr) => {
      // arr.length === index+1 added for the last pass
      if (prevProgramKey !== fs.assistanceProgramId.toString() || arr.length === index + 1) {

         programRowSpans[prevProgramKey] = (arr.length !== index + 1) ? programCount : programCount + 1;
         programCount = 0;
      }

      if (prevServiceKey !== getServiceKey(fs.assistanceProgramId, fs.assistanceServiceId) || arr.length === index + 1) {
         serviceRowSpans[prevServiceKey] = (arr.length !== index + 1) ? serviceCount : serviceCount + 1;
         serviceCount = 0;
      }
      prevProgramKey = getProgramKey(fs.assistanceProgramId);
      programCount++;

      prevServiceKey = getServiceKey(prevProgramKey, fs.assistanceServiceId);
      serviceCount++;
   })

   const getStatusValue = (serviceId: number, insuranceClassId: number) => {
      //eslint-disable-next-line no-restricted-globals
      return props.changes.find((e) => findStatusEntry(e, { assistanceServiceId: serviceId, insuranceClassId, status: undefined } as FundingStatus))?.status
   }

   const handleStatusChange = (newStatusId: number, assistanceServiceId: number, insuranceClassId: number, id?: number) => {


      let currentStatusWithNames: FundingStatusWithNames = orderedFundingStatuses.find((e) =>
         findStatusEntry(e, { assistanceServiceId: assistanceServiceId, insuranceClassId, status: undefined } as FundingStatus)
      );
      // here we are removing the "extra" fields so we can submit it to the api and it will match the class tehre
      const { assistanceProgramName, assistanceServiceName, insuranceClassName, ...currentStatus } = currentStatusWithNames;

      let changedEntry: FundingStatus = {
         ...currentStatus,
         id: id,
         assistanceServiceId: assistanceServiceId,
         insuranceClassId: insuranceClassId,
         status: newStatusId
      }
      props.updateParentChanges(changedEntry);
   }

   const getRowSpan = (programId: number, serviceId?: number, col: 'program' | 'service' = 'program'): number => {
      let returnVal: number = undefined;
      let programKey = getProgramKey(programId)
      if (col === 'program') {
         returnVal = programRowSpans[programKey];
         delete programRowSpans[programKey];
      } else {
         let serviceKey = getServiceKey(programId, serviceId)
         returnVal = serviceRowSpans[serviceKey];
         delete serviceRowSpans[serviceKey];
      }
      return returnVal
   }

   const programDataSet = [...new Map(orderedFundingStatuses.map(fs => [fs.assistanceProgramId,
   {
      assistanceProgramId: fs.assistanceProgramId,
      assistanceProgramName: fs.assistanceProgramName
   } as ProgramTable])).values()];

   const serviceDataSet = [...new Map(orderedFundingStatuses.map(fs => [fs.assistanceServiceId,
   {
      assistanceProgramId: fs.assistanceProgramId,
      assistanceServiceId: fs.assistanceServiceId,
      assistanceServiceName: fs.assistanceServiceName
   } as ServiceTable])).values()];

   const programColumns: ColumnsType<ProgramTable> = [
      {
         title: 'Program Name',
         dataIndex: 'assistanceProgramName',
         width: '15%',
      },
      {
         title: 'Assistance Service',
         dataIndex: 'assistanceProgramId',
         width: '85%',
         render: (text, record) => {
            const rows = serviceDataSet.filter(y => y.assistanceProgramId === record.assistanceProgramId);

            return <MinimalistTable
               rowKey='assistanceServiceId'
               columns={serviceColumns}
               data={rows}
               bordered={true}
               hidePaging={true}
            />
         }
      }
   ];

   const serviceColumns: ColumnsType<ServiceTable> = [
      {
         dataIndex: 'assistanceServiceName',
         width: '15%',
         render: (text, record) => <TruncateText fullText={text} maxLength={200} />,
      },
      {
         dataIndex: 'assistanceServiceId',
         width: '85%',
         render: (text, record) => {
            const rows = orderedFundingStatuses.filter(y => y.assistanceServiceId === record.assistanceServiceId);

            return <MinimalistTable
               showHeader={true}
               hidePaging={true}
               rowKey='insuranceClassId'
               columns={insuranceClassColumns}
               data={rows}
            />
         }
      }
   ];

   const insuranceClassColumns: ColumnsType<FundingStatusWithNames> = [
      {
         title: 'Insurance Class',
         dataIndex: 'insuranceClassName',
         width: 100,
      },
      {
         title: 'Status',
         dataIndex: 'currentStatus',
         width: 150,
         render: (text, record) => {
            let curStatus = getStatusValue(record.assistanceServiceId, record.insuranceClassId);
            if (curStatus === undefined) curStatus = record.status;

            return <Select
               style={{ width: 120 }}
               popupMatchSelectWidth={false}
               value={curStatus}
               options={fundStatusAsSelectOptions()}
               onChange={(e) => handleStatusChange(Number(e), record.assistanceServiceId, record.insuranceClassId, record.id)}
            />
         }
      },
      {
         title: 'Last Checked',
         dataIndex: 'lastChecked',
         width: 100,
         render: (text, record) => formatDateTimeString(record.lastChecked)
      },
      {
         title: 'External ID',
         dataIndex: 'externalId',
         width: 150,
      },
   ];

   return (
      <>
         <MinimalistTable
            className='child-tables'
            rowKey='assistanceProgramId'
            columns={programColumns}
            data={programDataSet}
            showHeader={true}
            size='small'
            bordered={true}
         />
      </>
   );
}