export interface Foundation {
   id: number;
   foundationName: string;
   foundationDescription: string;
   foundationUrl: string;
   logoUrl: string;
   backgroundUrl: string;
   practiceId?: number;
   scrapingStatus: string;
   hasWaitList: boolean;
   practice: string;
}

export enum FoundationModule {
   FUNDSTATUSES = 'FUND STATUSES',
   DISEASES = 'DISEASES',
   PROGRAMSERVICES = 'PROGRAM & SERVICES',
   SCRAPERCHANGES = 'SCRAPER CHANGES'
}

export enum ScrapingStatus {
   All = "All",
   On = "On",
   Off = "Off"
}