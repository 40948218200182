import type { ColumnsType, TableProps } from 'antd/lib/table';
import * as React from 'react';
import Restricted from '../../../auth/Restricted';
import { boolComparer, numberComparer, stringComparer } from '../../../functions/comparer.functions';
import { formatBoolean } from '../../../functions/format.functions';
import { connTypeList, ManualEnrollmentReasonOptionViewModel } from '../../../store/program/ManualEnrollmentReasonOptionModel';
import { useManualEnrollmentReasonViewModel } from '../../../store/program/ManualEnrollmentReasonOptionFetcher';
import { KnownSettings } from '../../../store/SettingsModel';
import SearchResultsTable from '../../shared/AntComponents/Table/SearchResultsTable';
import { BasicDropdownField } from '../../shared/BasicInputLibrary';
import { EditButton, AddButton } from '../../shared/Buttons';
import Spinner from '../../Spinner';
import ManualEnrollmentReasonOptionEditor from './ManualEnrollmentReasonOptionEditor';
import { useApiContext } from '../../../store/ApiContext';

const ManualEnrollmentReasonOptionIndex: React.FC = () => {
   const { httpGet } = useApiContext();

   const { manualEnrollmentReasonOptionViewModel, isLoading } = useManualEnrollmentReasonViewModel(httpGet);

   const [currentPage, setCurrentPage] = React.useState(1);
   const [currentPageSize, setCurrentPageSize] = React.useState(25);
   const handleOnChange: TableProps<ManualEnrollmentReasonOptionViewModel>['onChange'] = (pagination, filters, sorter, extra) => setCurrentPage(pagination?.current);

   const [connectivityTypeFilter, setConnectivityTypeFilter] = React.useState<number>();

   const [filteredItems, setFilteredItems] = React.useState<ManualEnrollmentReasonOptionViewModel[]>();
   const [selectedItem, setSelectedItem] = React.useState<ManualEnrollmentReasonOptionViewModel>(undefined);
   const [isEditorOpen, setIsEditorOpen] = React.useState<boolean>(false);

   React.useEffect(() => {
      createFilteredItems();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [manualEnrollmentReasonOptionViewModel,
      connectivityTypeFilter
   ]);

   const createFilteredItems = (inActivePage: number = 1) => {
      if (!manualEnrollmentReasonOptionViewModel || manualEnrollmentReasonOptionViewModel.length <= 0) return;

      let newList = manualEnrollmentReasonOptionViewModel.filter(model => {
         return filterConnectivityType(model);
      }) ?? [];

      setFilteredItems(newList);
   }

   const filterConnectivityType = (m: ManualEnrollmentReasonOptionViewModel): boolean => {
      if (connectivityTypeFilter === null || connectivityTypeFilter === undefined) return true;
      return connectivityTypeFilter === null || m.connectivityType === connectivityTypeFilter ? true : false;
   }

   const handleCloseEditorClick = () => {
      setIsEditorOpen(false);
      setSelectedItem(undefined);
   }

   const columns: ColumnsType<ManualEnrollmentReasonOptionViewModel> = [
      {
         title: 'Id',
         dataIndex: 'id',
         sorter: (a, b) => numberComparer(a.id, b.id),
         sortDirections: ['ascend', 'descend', 'ascend'],
      },
      {
         title: 'OptionName',
         dataIndex: 'optionName',
         sorter: (a, b) => stringComparer(a.optionName, b.optionName),
         sortDirections: ['ascend', 'descend', 'ascend'],
      },
      {
         title: 'IsFreeText',
         dataIndex: 'isFreeText',
         defaultSortOrder: 'descend',
         sorter: (a, b) => boolComparer(a.isFreeText, b.isFreeText),
         sortDirections: ['ascend', 'descend', 'ascend'],
         render: ((bval: boolean) => formatBoolean(bval))
      },
      {
         title: 'Sequence',
         dataIndex: 'sequence',
         sorter: (a, b) => numberComparer(a.sequence, b.sequence),
         sortDirections: ['ascend', 'descend', 'ascend'],
      },
      //{
      //    title: 'ConnectivityType',
      //    dataIndex: 'connectivityType',
      //    sorter: (a, b) => numberComparer(a.connectivityType, b.connectivityType),
      //    sortDirections: ['ascend', 'descend'],
      //},
      {
         title: 'ConnectivityType',
         dataIndex: 'connectivityTypeDesc',
         sorter: (a, b) => stringComparer(a.connectivityTypeDesc, b.connectivityTypeDesc),
         sortDirections: ['ascend', 'descend', 'ascend'],

      },
      {
         title: () =>
            <Restricted requiredRoles={[KnownSettings.ContentAdmin]}>
               <AddButton
                  title='Add New Reason'
                  buttonText='New'
                  onClick={() => {
                     setSelectedItem(undefined);
                     setIsEditorOpen(true)
                  }}
               />
            </Restricted>,
         dataIndex: '',
         key: 'x',
         fixed: 'right',
         render: (text, record) =>
            <Restricted requiredRoles={[KnownSettings.ContentAdmin]}>
               <EditButton
                  onClick={() => {
                     setSelectedItem(record);
                     setIsEditorOpen(true);
                  }} />
            </Restricted>,
      },
   ];


   if (!manualEnrollmentReasonOptionViewModel || manualEnrollmentReasonOptionViewModel.length === 0 || isLoading) {
      return <div><Spinner message='spinny thing' /></div>
   }

   const html = (
      <>
         <SearchResultsTable
            rowkey={'id'}
            onChange={handleOnChange}
            columns={columns}
            data={filteredItems}
            currentPage={currentPage}
            currentPageSize={currentPageSize}
            setCurrentPageSize={setCurrentPageSize}
            titleText='Manual Enrollment Reasons'
            fixedHeader={true}
            size='small'
            additionalComponents={[
               <BasicDropdownField
                  name='ConnectivityTypeSearchString'
                  value={connectivityTypeFilter}
                  label='Select Connectivity Type'
                  multiple={false}
                  options={connTypeList}
                  required={false}
                  search={true}
                  clearable={true}
                  onChange={(val) => setConnectivityTypeFilter(val ? Number(val) : undefined)}

               />,
            ]}
         />

         <ManualEnrollmentReasonOptionEditor isOpen={isEditorOpen}
            closeEditor={() => handleCloseEditorClick()}
            id={selectedItem?.id} />
      </>
   )

   return html;
}

export default ManualEnrollmentReasonOptionIndex;