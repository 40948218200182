import { useAssistanceProgramNameOptions, useFetchAssistancePrograms } from './AssistanceProgramFetcher';
import { useFetchAssistanceServices } from './AssistanceServiceFetcher';
import { useFetchFoundationDiseaseTypes } from './FoundationDiseaseType';
import { useFetchBrand } from '../drug/BrandFetcher';
import { numberComparer, stringComparer } from '../../functions/comparer.functions';
import { useMemo } from 'react';
import { HttpError, TPost, TDelete, TGet } from '../../functions/httpClient';
import { IOptionItem } from '../../functions/option.functions';
import { IStatus } from '../models';
import useSWR, { mutate } from 'swr';
import {
   AssistanceServiceEligibilityCriteria,
   AssistanceServiceEligibilityCriteriaCategory,
   AssistanceServiceEligibilityCriteriaViewModel
} from './AssistanceServiceEligibilityCriteriaModel';
import { refreshActiveCohorts } from '../cohort/CohortFetcher';

export const assistanceServiceToEligibilityCriteriaBaseUrl = `api/program/AssistanceServiceEligibilityCriteria`;
const assistanceServiceEligibilityCategoriesGetUrl = `${assistanceServiceToEligibilityCriteriaBaseUrl}/GetEligibilityCategories`;
const assistanceServiceToEligibilityCriteriaSaveUrl = `${assistanceServiceToEligibilityCriteriaBaseUrl}/Save`;
const assistanceServiceToEligibilityCriteriaDeleteUrl = (id: number) => `${assistanceServiceToEligibilityCriteriaBaseUrl}/Delete/${id}`;

const swrOptions = {
   refreshInterval: 0,
   revalidateOnFocus: false,
   dedupingInterval: 60000
};


export function useFetchEligibilityCriteria(httpGet: TGet): IStatus & { eligibilityCriteria: AssistanceServiceEligibilityCriteria[] } {
   const { data, isLoading, error } = useSWR<AssistanceServiceEligibilityCriteria[], HttpError>(
      assistanceServiceToEligibilityCriteriaBaseUrl,
      httpGet,
      { ...swrOptions }
   );

   return {
      eligibilityCriteria: data,
      isLoading: isLoading,
      error: error?.message
   };
}

export function useFetchEligibilityCategories(httpGet: TGet): IStatus & { eligibilityCategories: AssistanceServiceEligibilityCriteriaCategory[] } {
   const { data, isLoading, error } = useSWR<AssistanceServiceEligibilityCriteriaCategory[], HttpError>(
      assistanceServiceEligibilityCategoriesGetUrl,
      httpGet,
      { ...swrOptions }
   );

   return {
      eligibilityCategories: data,
      isLoading: isLoading,
      error: error?.message
   };
}

export const saveAssistanceServiceEligibilityCriterias = async (httpPost: TPost, eligibilityCriterias: AssistanceServiceEligibilityCriteria[], cohortFromDate: Date) => {
   const result = await httpPost(assistanceServiceToEligibilityCriteriaSaveUrl, eligibilityCriterias)
      .then(() => mutate(assistanceServiceToEligibilityCriteriaBaseUrl));
   refreshActiveCohorts(httpPost, cohortFromDate);
   return result;
}

export const deleteAssistanceServiceEligibilityCriteria = async (httpDelete: TDelete, id: number) => {
   const result = await httpDelete(assistanceServiceToEligibilityCriteriaDeleteUrl(id))
      .then(() => mutate(assistanceServiceToEligibilityCriteriaBaseUrl));
   return result;
}

export function useEligibilityCriteriaViewModel(httpGet: TGet): IStatus & { eligibilityCriteriaViewModel: AssistanceServiceEligibilityCriteriaViewModel[] } {
   const { eligibilityCriteria, isLoading, error, isSaving } = useFetchEligibilityCriteria(httpGet);
   const { assistancePrograms, isLoading: isLoading_p, error: error_p } = useFetchAssistancePrograms(httpGet);
   const { assistanceServices, isLoading: isLoading_s, error: error_s } = useFetchAssistanceServices(httpGet);
   const { eligibilityCategories } = useFetchEligibilityCategories(httpGet);
   const { foundationDiseaseTypes, isLoading: isLoading_fdt, error: error_fdt } = useFetchFoundationDiseaseTypes(httpGet);
   const { brands, isLoading: isLoading_b, error: error_b } = useFetchBrand(httpGet);

   const viewModel = useMemo(() => {
      if (eligibilityCriteria?.length > 0 &&
         assistancePrograms?.length > 0 &&
         assistanceServices?.length > 0 &&
         eligibilityCategories?.length > 0 &&
         foundationDiseaseTypes?.length > 0 &&
         brands?.length > 0) {
         let tempList = eligibilityCriteria.map((item): AssistanceServiceEligibilityCriteriaViewModel => {

            const s = assistanceServices.find(y => y.id === item.assistanceServiceId);
            const p = s ? assistancePrograms.find(y => y.id === s?.assistanceProgramId) : undefined;
            const c = eligibilityCategories.find(y => y.id === item.assistanceServiceEligibilityCriteriaCategoryId);
            const fdt = item.foundationDiseaseTypeId ? foundationDiseaseTypes.find(y => y.id === item.foundationDiseaseTypeId) : undefined;
            const b = item.brandId ? brands.find(y => y.id === item.brandId) : undefined;

            return {
               ...item,
               brandName: b?.brandName,
               diseaseTypeName: fdt?.diseaseTypeName,
               categoryName: c?.categoryName,
               assistanceServiceName: s?.assistanceServiceName,
               assistanceProgramId: p?.id,
               programName: p?.programName
            } as AssistanceServiceEligibilityCriteriaViewModel

         });
         return tempList;
      }
      return [];
   }, [eligibilityCriteria, assistancePrograms, assistanceServices, eligibilityCategories, foundationDiseaseTypes, brands]);

   return {
      eligibilityCriteriaViewModel: viewModel,
      isLoading: isLoading || !viewModel || viewModel.length === 0,
      error: error,
      isSaving
   };
}

export function useEligibilityCategoryOptions(httpGet: TGet): { eligibilityCategoryOptions: IOptionItem[] } {
   const { eligibilityCategories } = useFetchEligibilityCategories(httpGet);

   const options = useMemo(() => {
      if (eligibilityCategories?.length > 0) {
         const options = eligibilityCategories.map((o): IOptionItem => ({
            label: `[${o.id}]: ${o.categoryName}`,
            value: o.id
         } as IOptionItem));
         return options;
      }
      return [];
   }, [eligibilityCategories]);


   return {
      eligibilityCategoryOptions: options
   };
}

export function useEligibilityAssistanceProgramOptions(httpGet: TGet): { eligibilityAssistanceProgramOptions: IOptionItem[] } {
   const { eligibilityCriteriaViewModel } = useEligibilityCriteriaViewModel(httpGet);
   const { assistanceProgramNameOptions } = useAssistanceProgramNameOptions(httpGet);

   const options = useMemo(() => {
      if (eligibilityCriteriaViewModel?.length > 0 && assistanceProgramNameOptions?.length > 0) {

         const options: IOptionItem[] = [];
         assistanceProgramNameOptions.reduce((acc: IOptionItem[], option) => {
            if (eligibilityCriteriaViewModel.some(y => y.assistanceProgramId === option.value)) {
               options.push(option);
            }
            return acc;
         }, []);

         return options;
      }
      return [];

   }, [eligibilityCriteriaViewModel, assistanceProgramNameOptions]);

   return {
      eligibilityAssistanceProgramOptions: options
   };
}

export function useEligibilityBrandOptions(httpGet: TGet): { eligibilityBrandOptions: IOptionItem[] } {
   const { eligibilityCriteriaViewModel } = useEligibilityCriteriaViewModel(httpGet);

   const options = useMemo(() => {
      if (eligibilityCriteriaViewModel?.length > 0) {

         const sorted = [...eligibilityCriteriaViewModel];
         sorted.sort((a: AssistanceServiceEligibilityCriteriaViewModel, b: AssistanceServiceEligibilityCriteriaViewModel) => numberComparer(Number(a.brandId), Number(b.brandId)));
         sorted.sort((a: AssistanceServiceEligibilityCriteriaViewModel, b: AssistanceServiceEligibilityCriteriaViewModel) => stringComparer(String(a.brandName), String(b.brandName)));

         const options: IOptionItem[] = [];
         sorted.reduce((acc, { brandId, brandName }) => {
            if (!options.some(y => y.value === brandId)) {
               options.push({
                  label: `[${brandId}]: ${brandName}`,
                  value: brandId
               } as IOptionItem);
            }
            return acc;
         }, []);

         return options;
      }
      return [];

   }, [eligibilityCriteriaViewModel]);

   return {
      eligibilityBrandOptions: options
   };
}

export function useEligibilityFoundationDiseaseTypeOptions(httpGet: TGet): { eligibilityFoundationDiseaseTypeOptions: IOptionItem[] } {
   const { eligibilityCriteriaViewModel } = useEligibilityCriteriaViewModel(httpGet);

   const options = useMemo(() => {
      if (eligibilityCriteriaViewModel?.length > 0) {

         const sorted = [...eligibilityCriteriaViewModel];
         sorted.sort((a: AssistanceServiceEligibilityCriteriaViewModel, b: AssistanceServiceEligibilityCriteriaViewModel) => numberComparer(Number(a.foundationDiseaseTypeId), Number(b.foundationDiseaseTypeId)));
         sorted.sort((a: AssistanceServiceEligibilityCriteriaViewModel, b: AssistanceServiceEligibilityCriteriaViewModel) => stringComparer(String(a.diseaseTypeName), String(b.diseaseTypeName)));

         const options: IOptionItem[] = [];
         sorted.reduce((acc, { foundationDiseaseTypeId, diseaseTypeName }) => {
            if (foundationDiseaseTypeId && !options.some(y => y.value === foundationDiseaseTypeId)) {
               options.push({
                  label: `[${foundationDiseaseTypeId}]: ${diseaseTypeName ?? 'Not found'}`,
                  value: foundationDiseaseTypeId
               } as IOptionItem);
            }
            return acc;
         }, []);

         return options;
      }
      return [];
   }, [eligibilityCriteriaViewModel]);

   return {
      eligibilityFoundationDiseaseTypeOptions: options
   };
}