import * as React from 'react';
import { usePracticeOptions } from '../../store/practice/PracticeFetcher';
import { useActiveSubscriptionOptions } from '../../store/practice/SubscriptionFetcher';
import {
   useFetchPracticeSubscription,
   savePracticeSubscription,
   deletePracticeSubscription,
   practiceSubscriptionBaseUrl
} from '../../store/practice/PracticeSubscriptionFetcher';
import { PracticeSubscription } from '../../store/practice/PracticeSubscriptionModel';
import { Space } from 'antd';
import { Form, Row, Col } from 'antd';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Dialog from '../Dialog';
import { InputField, DropdownField, CheckboxField, DatePickerField } from '../shared/InputLibrary';
import { SaveButton, DeleteButton, CancelButton } from '../shared/Buttons';
import { TitleError } from '../shared/AntComponents/Typography/Title';
import Spinner from '../Spinner';
import ApiErrorDisplay from '../ApiErrorDisplay';
import { useApiContext } from '../../store/ApiContext';
import { isSameDayOrBefore } from 'src/functions/time.functions';
import { useErrorContext } from '../../store/ErrorContext';

interface IProps {
   isOpenFromPractice: boolean;
   isOpen: boolean;
   closeEditor: () => void;
   practiceSubscriptionId?: number;
   subscriptionId?: number;
   practiceIdForNew?: number;
};

const dialogContentStyle = {
   height: '580px',
   maxHeight: '95vh',
}

const yupPracticeSubscriptionSchema = yup.object({
   id: yup.number().notRequired(),
   uid: yup.string()
      .notRequired()
      .max(64, 'Max length is 64 characters'),
   practiceId: yup.number()
      .required('Practice is required'),
   subscriptionId: yup.number()
      .required('Subscription is required'),
   startDate: yup.date().nullable().notRequired(),
   endDate: yup.date()
      .nullable()
      .test({
         name: 'enddate',
         message: 'End Date cannot be before Start Date',
         test: function (value) {
            if (this.parent.startDate && value) {
               const test = isSameDayOrBefore(this.parent.startDate, value);
               return test;
            }
            return true;
         },
      }),
   active: yup.boolean().required(),
   createdByName: yup.string().notRequired(),
   createdOn: yup.date().notRequired(),
   modifiedByName: yup.string().notRequired(),
   modifiedOn: yup.date().notRequired(),
});


interface IPracticeSubscriptionSchemaYup extends yup.Asserts<typeof yupPracticeSubscriptionSchema> { }
const __formId = "frmPracticeSubscription";

const _keysLike: string[] = [practiceSubscriptionBaseUrl];

const PracticeSubscriptionEditor: React.FC<IProps> = (props) => {
   const { isOpenFromPractice, isOpen, closeEditor, practiceSubscriptionId, subscriptionId, practiceIdForNew } = props;
   const { httpGet, httpPost, httpDelete } = useApiContext();
   const { removeErrors } = useErrorContext();
   const { practiceOptions } = usePracticeOptions(httpGet);
   //CR-89 - only active subscriptions
   const { subscriptionOptions: subscriptionOptionsFromStore } = useActiveSubscriptionOptions(httpGet);
   const { practiceSubscriptions } = useFetchPracticeSubscription(httpGet);
   const [isFormSaving, setIsFormSaving] = React.useState<boolean>(false);

   const {
      control,
      handleSubmit,
      formState: { errors },
      reset,
      setValue,
      getValues,
      watch,
      trigger
   } = useForm<IPracticeSubscriptionSchemaYup>({
      resolver: yupResolver<yup.AnyObject>(yupPracticeSubscriptionSchema),
      shouldFocusError: true
   });

   const watchPracticeId = watch('practiceId');
   const watchSubscriptionId = watch('subscriptionId');

   React.useEffect(() => {
      let resetVal = {} as PracticeSubscription;
      if (practiceSubscriptionId && practiceSubscriptions?.length > 0) {
         resetVal = practiceSubscriptions.find(y => y.id === practiceSubscriptionId);
      } else if (practiceIdForNew && isOpenFromPractice && subscriptionId && !practiceSubscriptionId) {
         resetVal.practiceId = practiceIdForNew;
         resetVal.subscriptionId = subscriptionId;
         resetVal.active = true;
      }
      reset(resetVal);
      // for "reset"
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [practiceSubscriptions, practiceSubscriptionId, subscriptionId, practiceIdForNew, isOpenFromPractice])

   const subscriptionOptions = React.useMemo(() => {
      if (subscriptionOptionsFromStore && (practiceIdForNew || watchPracticeId) && practiceSubscriptions) {
         const practiceId = (practiceIdForNew !== undefined ?
            practiceIdForNew :
            watchPracticeId);

         const currentSubscriptionsForPractice = practiceSubscriptions.filter(y => y.practiceId === practiceId);

         return subscriptionOptionsFromStore.filter(opt =>
            (!currentSubscriptionsForPractice?.map(y => y.subscriptionId).includes(opt.value as number)) ||
            opt.value === watchSubscriptionId
         )
      }
   }, [watchSubscriptionId, subscriptionOptionsFromStore, practiceSubscriptions, practiceIdForNew, watchPracticeId])

   const [form] = Form.useForm();

   const onSubmit = (data: IPracticeSubscriptionSchemaYup): void => {
      //ts hoops to convert data with unknown props into assistance programs
      const item = data as unknown as PracticeSubscription;
      setIsFormSaving(true);
      savePracticeSubscription(httpPost, item)
         .then(handleClose)
         .catch(err => console.error('Error Saving Practice Subscription'))
         .finally(() => {
            setIsFormSaving(false);
         });
   }

   const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState<boolean>(false);

   const handleDeleteClick = () => {
      setIsDeleteDialogOpen(false);
      deletePracticeSubscription(httpDelete, getValues().id)
         .then(handleClose)
         .catch(err => console.error('Error Deleting Practice Subscription', err));
   }

   const handleClose = () => {
      setIsFormSaving(false);
      setIsDeleteDialogOpen(false);
      removeErrors({ keysLike: _keysLike });
      closeEditor();
   }

   if (isFormSaving) {
      return <div><Spinner message='Saving Practice Subscription...' /></div>
   }

   //console.log('--------------AssistanceServcieEditor-------------------------')
   //console.log(errors)


   const html = (
      <Dialog
         scrollingContent={true}
         title={'Practice Subscription Editor'}
         open={isOpen}
         size="large"
         actionButtons={
            <Space>
               <CancelButton buttonText='Cancel' onClick={() => {
                  handleClose();
               }} />
               {!isOpenFromPractice &&
                  <DeleteButton
                     onClick={() => setIsDeleteDialogOpen(true)}
                     disabled={!getValues()?.id || isDeleteDialogOpen}
                     loading={isDeleteDialogOpen} />
               }
               <SaveButton
                  loading={isFormSaving}
                  onClick={() => form.submit()}
                  disabled={Object.keys(errors).length > 0}
                  formId={__formId}
               />
            </Space>
         }>
         <div style={dialogContentStyle}>

            {errors && Object.keys(errors).length > 0 &&
               <>
                  <TitleError text='Please correct the errors below' />
                  {console.log('--------------Form Errors: -------------------------')}
                  {console.log(errors)}
               </>
            }

            <ApiErrorDisplay
               title='Error saving Practice Subscription'
               keysLike={_keysLike}
            />

            <Row gutter={[32, 32]} justify="space-between" align="bottom">
               <Col span={12}>
                  <Form
                     form={form}
                     id={__formId}
                     onFinish={handleSubmit(onSubmit)}>

                     {practiceSubscriptionId &&
                        <InputField
                           control={control}
                           name='id'
                           label='Id'
                           type='text'
                           error={errors?.id}
                           disabled={true}
                           required={true}
                        />
                     }
                     {practiceSubscriptionId &&
                        <InputField
                           control={control}
                           name='uid'
                           label='UID'
                           type='text'
                           error={errors?.uid}
                           disabled={true}
                           required={true}
                        />
                     }
                     <DropdownField
                        control={control}
                        name='practiceId'
                        label='Practice'
                        error={errors?.practiceId}
                        multiple={false}
                        options={practiceOptions}
                        setValue={setValue}
                        required={true}
                        search={true}
                        clearable={true}
                        disabled={isOpenFromPractice}
                     />
                     <DropdownField
                        control={control}
                        name='subscriptionId'
                        label='Subscription'
                        error={errors?.subscriptionId}
                        multiple={false}
                        options={subscriptionOptions}
                        setValue={setValue}
                        required={true}
                        search={true}
                        clearable={true}
                        /* When accessed from outside of the Practice Detail page, we need to only display Subscriptions that haven't been selected for this Practice yet,
                         * so we shouldn't allow access to this dropdown until after a Practice is associated with this record
                         * OR we have accessed this from Practice Detail, in which case the Subscription should've been provided due to the way that UI functions
                        */
                        disabled={(watchPracticeId === undefined || isOpenFromPractice)} 
                     />
                     <DatePickerField
                        control={control}
                        name='startDate'
                        label='Start Date'
                        error={errors?.startDate}
                        onChange={() => {
                           if (errors.startDate || errors.endDate) {
                              trigger('startDate');
                              trigger('endDate');
                           }
                        }}
                     />
                     <DatePickerField
                        control={control}
                        name='endDate'
                        label='End Date'
                        error={errors?.endDate}
                        onChange={() => {
                           if (errors.startDate || errors.endDate) {
                              trigger('startDate');
                              trigger('endDate');
                           }
                        }}
                     />
                     <CheckboxField
                        control={control}
                        name='active'
                        label='Active'
                        error={errors?.active}
                        setValue={setValue}
                        required={false}
                     />
                     {practiceSubscriptionId &&
                        <InputField
                           control={control}
                           name='createdByName'
                           label='Created By'
                           type='text'
                           error={errors?.createdByName}
                           required={false}
                           disabled={true}
                        />}
                     {practiceSubscriptionId &&
                        <DatePickerField
                           control={control}
                           name='createdOn'
                           label='Created On'
                           error={errors?.createdOn}
                           disabled={true}
                        />}
                     {practiceSubscriptionId &&
                        <InputField
                           control={control}
                           name='modifiedByName'
                           label='Modified By'
                           type='text'
                           error={errors?.modifiedByName}
                           required={false}
                           disabled={true}
                        />}
                     {practiceSubscriptionId &&
                        <DatePickerField
                           control={control}
                           name='modifiedOn'
                           label='Modified On'
                           error={errors?.modifiedOn}
                           disabled={true}
                        />}

                  </Form>
               </Col>
            </Row>

         </div>

         <Dialog
            title='CONFIRM DELETE'
            open={isDeleteDialogOpen}
            style={{ maxWidth: '550px' }}
            actionButtons={
               <Space>
                  <CancelButton onClick={() => setIsDeleteDialogOpen(false)} />
                  <DeleteButton
                     disabled={isFormSaving}
                     onClick={() => handleDeleteClick()} />
               </Space>
            }
         >
            {`Are you sure you want to Delete the Practice Subscription ${subscriptionOptions?.find(y => y.value === getValues()?.subscriptionId)?.label}?`}
         </Dialog>

      </Dialog>
   )
   return html;
}

export default PracticeSubscriptionEditor;

