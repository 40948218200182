import * as React from 'react';
import './SidebarFooter.css';
import { useUserContext } from '../auth/authContext';
import { useLayoutContext } from './LayoutContext';
import { ReactComponent as LogoutSvg } from "../images/icons/logout.svg";
import { ReactComponent as SettingsSvg } from "../images/icons/settings.svg";
import Icon from "@ant-design/icons";
import { Badge } from 'antd';
import CustomIcon, { CustomIconType } from '../components/shared/AntComponents/CustomIcon';
import colorWheel from '../Theme/ColorWheel';
import NotificationIndex from '../components/notification/NotificationIndex';
import { useNotificationContext } from '../components/notification/NotificationContext';



const SidebarFooter: React.FC = () => {

   const { signOut } = useUserContext();
   const { menuProps } = useLayoutContext();
   const { setNotificationDrawerOpen, notifications } = useNotificationContext();

   const html = (
      <div className={"ap-footer-menu " + (menuProps.collapsed ? 'collapsed' : '')}>
         {menuProps.collapsed === false &&
            <div className="item">
               <div onClick={() => setNotificationDrawerOpen(true)} className="tab" title="Notifications">
                  <Badge
                     showZero={false}
                     count={notifications?.filter(y => !y.acknowledged)?.length ?
                     notifications?.filter(y => !y.acknowledged)?.length
                        : 0}
                  >
                     <CustomIcon
                        type={CustomIconType.BellOutlined}
                        style={{
                           fontSize: 24,
                           color: colorWheel.white
                        }}
                     />
                  </Badge>
               </div>
            </div>
         }
         {menuProps.collapsed === false &&
            <div className="item">
               <div onClick={() => menuProps.setDisplayIndex(2)} className="tab" title="SETTINGS">
                  <Icon style={{ fontSize: 24 }} component={SettingsSvg} />
               </div>
            </div>
         }
         <div className="item">
            <div onClick={() => signOut()} className="tab" title="LOGOUT">
               <Icon style={{ fontSize: 24 }} component={LogoutSvg} />
            </div>
         </div>

         <NotificationIndex />
      </div>
   )
   return html;
}

export default SidebarFooter;
